import { useEffect, useState } from 'react';
import { toaster } from 'evergreen-ui';
import { NotebookService } from 'services/note';
import { Dialog } from 'components/Dialog';
import { useHistory } from 'react-router-dom';

import { NOTE_URL } from 'utils/constants';
import { NotebookSchema } from 'schemas/notebook';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  notebook: NotebookSchema;
}

const notebookService = new NotebookService();

const DeleteNotebookModal = ({ isShown, setIsShown, notebook }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentNotebook, setCurrentNotebook] = useState(notebook);
  const history = useHistory();

  useEffect(() => {
    setCurrentNotebook(notebook);
  }, [notebook]);

  const handleConfirm = async () => {
    try {
      if (currentNotebook?.id) {
        setIsLoading(true);
        await notebookService.softDelete(currentNotebook?.id);
        setIsShown(false);
        history.push(NOTE_URL);
      }
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      width={400}
      isShown={isShown}
      title={`Delete Notebook`}
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Delete"
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
    >
       Are you sure to delete this notebook? This action cannot be undone.
    </Dialog>
  );
}

export default DeleteNotebookModal;
