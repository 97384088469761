import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, majorScale, Paragraph, TextInput } from 'evergreen-ui';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { AuthService } from 'services/authService';
import { DOCUMENT_URL, EVENT_CATALOG, SIGN_UP_SUCCESS, TERM_OF_USE, TRACKING_CATALOG } from 'utils/constants';
import google from 'assets/img/google.svg';
import styles from './SignUp.module.scss';

export const SignUpForm = ({ onError }: { onError: Function }) => {
  const history = useHistory();
  const location = useLocation();
  const authService = new AuthService();
  const locationQuery = new URLSearchParams(location.search);

  const handleSignInWithGoogle = async () => {
    try {
      await authService.signInWithGoogle();
      history.push(DOCUMENT_URL);
    } catch (e: any) {
      onError(e.message);
    }
  }

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: locationQuery.get('email') || '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email()
        .required(),
      password: Yup
        .string()
        .required(),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        await authService.signUpWithEmail(email, password);
        ReactGA.event({category: TRACKING_CATALOG[SIGN_UP_SUCCESS], action: SIGN_UP_SUCCESS});
        ReactPixel.track(EVENT_CATALOG[SIGN_UP_SUCCESS])
        history.push(DOCUMENT_URL);
      } catch (e: any) {
        onError(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="mt-45" onSubmit={handleSubmit}>
      <TextInput
        type="email"
        placeholder="Email Address"
        className="w-100"
        isInvalid={!!(touched.email && errors.email)}
        {...getFieldProps('email')}
      />
      <TextInput
        type="password"
        placeholder="Password"
        className="mt-3 w-100"
        isInvalid={!!(touched.password && errors.password)}
        {...getFieldProps('password')}
      />
      <Button
        appearance="primary"
        type="submit"
        className="w-100 mt-3"
        height={majorScale(5)}
        isLoading={isSubmitting}
      >
        Sign Up
      </Button>
      <hr className="mt-3 mb-3" style={{ border: '0.5px solid #D8DAE5' }} />
      <Button className="w-100" onClick={handleSignInWithGoogle}>
        <img className="mr-2" src={google} alt="google"/>Sign Up with Google
      </Button>
      <Paragraph className={`mt-3 ${styles.terms}`}>
        By signing up, you agree to the <a href={TERM_OF_USE} target="_blank" rel="noreferrer">Terms of Use</a>
      </Paragraph>
    </form>
  )
}
