import boardStyles from "../Board.module.scss";
import {
  Card,
  ControlIcon,
  DocumentIcon,
  Heading,
  IconButton,
  Link,
  Pane,
  PeopleIcon,
  PlusIcon,
  Position,
  Tooltip,
  TrashIcon,
  EnvelopeIcon
} from "evergreen-ui";
import { getUser } from "../../../utils/store";
import clsx from "clsx";
import { Link as ReactRouterLink, useHistory, useLocation } from "react-router-dom";
import TreeList from "../../../components/Tree/TreeList";
import ShareFolders from "../../../components/ShareFolder/ShareFolders";
import CreateFolderModal from "../../../components/Folder/CreateFolderModal";
import { SendFeedbackModal } from "../../../components/Modals";
import UpgradeSuccessModal from "../../../components/Modals/UpgradeToProModal/UpgradeSuccessModal";
import React, { useState } from "react";
import { SIGN_IN_URL } from "../../../utils/constants";
import { useDrop } from "react-dnd";
import { DocumentService } from "../../../services/document";
import { FolderService } from "../../../services/folder";
import { CustomDragLayer } from "../../../components/DragLayer/CustomDragLayer";
import AppFooterBoard from "./Footer";

const documentService = new DocumentService();
const folderService = new FolderService();

type Props = {
  header: any;
}

const AppBoard = ({ header }: Props) => {
  const [isShowCreate, setShowCreate] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const history = useHistory();
  const location = useLocation();

  if (!getUser()) {
    history.push(SIGN_IN_URL);
  }

  const isActivePath = (path: string) => {
    return location.pathname.includes(path);
  }

  const isActiveRoute = (path: string) => {
    if (isActivePath(path)) {
      return {
        isActive: true
      };
    }
    return {
      isActive: false
    };
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: ['DOCUMENT', 'FOLDER'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item: any, monitor) => {
      if (item.type === 'DOCUMENT') {
        if (item.doc?.folder === '') {
          return false;
        }
        return true;
      } else if (item.type === 'FOLDER') {
        if (item.folder === '' || item.folder?.children?.length > 0) {
          return false;
        }
        return true;
      }
      return false;
    },
    drop: async (item: any, monitor) => {
      if (item.type === 'DOCUMENT') {
        if (item.doc) {
          await documentService.updateDocumentFolder(item.doc.id, '');
        }
      } else if (item.type === 'FOLDER') {
        if (item.folder) {
          await folderService.updateParentFolder(item.folder.id, '');
        }
      }
    }
  }))

  return (
    <>
      <Card className={boardStyles.asideContainer}>
        {header}
        <Pane className={clsx(boardStyles.folderContainer)}>
          <Heading
            className={clsx(
              boardStyles.sidebarItem,
              boardStyles.allDocumentHeading,
              isActiveRoute('/app')
            )}
          >
            <span ref={dropRef} className={clsx(isOver && boardStyles.canDrop)}>
              <Link is={ReactRouterLink} to="/app">
                <DocumentIcon />
                <span className={boardStyles.sidebarLabel}>My Documents</span>
              </Link>
            </span>
            <Tooltip
              content={<div className={boardStyles.toolTipLabel}>New Folder</div>}
              position={Position.RIGHT}
            >
              <IconButton
                width={20}
                className={boardStyles.plusIconBtn}
                icon={<PlusIcon size={9} />}
                onClick={() => setShowCreate(true)}
              />
            </Tooltip>
          </Heading>
          <TreeList />
        </Pane>

        <Heading
          className={clsx(boardStyles.sidebarItem, isActiveRoute('/shared-with-me'))}
        >
          <Link is={ReactRouterLink} to="/shared-with-me">
            <PeopleIcon />
            <span className={boardStyles.sidebarLabel}>Shared with me</span>
          </Link>
        </Heading>
        <ShareFolders />
        <Heading className={clsx(boardStyles.sidebarItem, isActiveRoute('/templates'))}>
          <Link is={ReactRouterLink} to="/templates">
            <ControlIcon />
            <span className={boardStyles.sidebarLabel}>Templates</span>
          </Link>
        </Heading>
        <Heading className={clsx(boardStyles.sidebarItem, isActiveRoute('/trash'))}>
          <Link is={ReactRouterLink} to="/trash">
            <TrashIcon />
            <span className={boardStyles.sidebarLabel}>Trash</span>
          </Link>
        </Heading>
        <Heading className={boardStyles.sidebarItem}>
          <Link onClick={() => setModalFeedback(true)}>
            <EnvelopeIcon/>
            <span className={boardStyles.sidebarLabel}>Send Feedback</span>
          </Link>
        </Heading>

        <Pane className={boardStyles.bottomSidebar}>
          <AppFooterBoard />
        </Pane>
      </Card>

      <CreateFolderModal isShown={isShowCreate} setIsShown={setShowCreate} />
      <SendFeedbackModal isShown={modalFeedback} setIsShown={setModalFeedback} />
      <UpgradeSuccessModal isShown={modalSuccess} setIsShown={setModalSuccess} />
      <CustomDragLayer snapToGrid={false} />
    </>
  )
}

export default AppBoard;
