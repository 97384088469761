import KeyResultInput from "./KeyResultInput";
import {useEffect, useRef, useState} from "react";
import {DocumentIcon, majorScale, minorScale, Pane, Text} from "evergreen-ui";
import clsx from "clsx";
import styles from "./KeyResult.module.scss";
import _ from "lodash";
import AssigneeAvatar from "../Task/AssigneeAvatar";
import {isToday} from "../../utils/datetime";
import dayjs from "dayjs";
import Expand from "react-expand-animated";
import {useDrag, useDrop} from "react-dnd";

type Props = {
  result: {
    id?: string,
    title?: string,
    description?: string,
    dueDate?: any,
    progress?: number,
    assignees?: any,
  },
  objective: any,
  members?: any,
  saveResult?: Function,
  setResultInfo?: Function,
  onSelect?: Function,
  isSelected?: boolean,
  isAutoFocus: boolean,
  onDropResult: Function,
  setFocusResult: Function,
  onDeleteResult: Function,
}

const KeyResult = (
  {
    result,
    members,
    onSelect,
    saveResult,
    setResultInfo,
    isSelected,
    isAutoFocus,
    setFocusResult,
    onDropResult,
    objective,
    onDeleteResult
  }: Props
) => {
  const [isExpanded, setExpanded] = useState(!!isAutoFocus);
  const [today] = useState(new Date());
  const latestData = useRef({});
  const expandPaneRef = useRef(null);
  const resultRef = useRef(null as any);

  const displayDate = (date: any) => {
    if(isToday(date, today)) return "Today";

    return  dayjs(new Date(date)).format('MMM DD, YYYY');
  }

  const isClickOnEl = (selectors: string, event: any) => {
    const el = document.querySelector(selectors);
    return el && el.contains(event.target);
  }

  const saveAndCollapse = () => {
    setExpanded(false);
    saveResult && saveResult();
  }

  useEffect(() => {
    latestData.current = {...latestData.current, isSelected};
  }, [isSelected]);

  const handleClickOutTaskItem = (event: any) => {
    const isTaskSelected = _.get(latestData.current, "isSelected", false);
    const isExpanded = expandPaneRef.current;
    if(isExpanded){
      if(isClickOnEl('.ant-picker-panel-container', event)) return;
      if(isClickOnEl('.assignees-panel', event)) return;

      // @ts-ignore
      const isClickInsideExpandPanel = expandPaneRef.current.contains(event.target);
      if(isClickInsideExpandPanel) return;

      saveAndCollapse();
      isTaskSelected && onSelect && onSelect(0);
      isAutoFocus && setFocusResult(0);
    }
    else if(isTaskSelected && !isExpanded){
      const resultEl = resultRef?.current as any;
      if (resultEl?.contains(event.target)) return;

      onSelect && onSelect(0);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutTaskItem, true);
    return () => {
      document.removeEventListener('click', handleClickOutTaskItem, true);
    };
  }, []);

  const onSaveResult = () => {
    saveResult && saveResult();
    setExpanded(false);
  };

  const [{ isDragging }, dragResultRef] = useDrag(() => ({
    type: 'RESULT',
    item: {
      type: 'RESULT',
      result: {...result, objective: objective.id}
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }), [result]);

  const [{ isDropResultOver }, dropResultRef] = useDrop(() => ({
    accept: ["RESULT"],
    collect: (monitor) => ({
      isDropResultOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "RESULT"
        && _.get(monitor.getItem(), "result.objective") === objective.id
    }),
    canDrop: (item: any, monitor) => {
      return item.result.objective === objective.id;
    },
    drop: async (item: any, monitor)  => {
      onDropResult(item.result, result);
    }
  }), [result]);

  const onResultRef = (ref: any) => {
    dropResultRef(ref);
    dragResultRef(ref);
  }

  const onDoubleClick = () => {
    onSelect && onSelect(0);
    setExpanded(true);
  }

  return (
    <>
      <Pane paddingY={minorScale(1)} display={isDragging ? "none": "block"}>
        {!isExpanded &&
          <Pane ref={onResultRef}>
            <Pane
              display={"flex"}
              justifyContent={"space-between"}
              alignContent={"center"}
              cursor={"pointer"}
              onClick={() => onSelect && onSelect(result.id)}
              className={clsx(styles.keyResultInfo, isSelected && !isExpanded && styles.keyResultSelected)}
              onDoubleClick={() => onDoubleClick()}
              ref={resultRef}
            >
              <Pane
                justifyContent={"space-between"}
                display={"flex"}
                alignSelf={"center"}
                width="100%"
              >
                <Pane display={"flex"}>
                  <Pane
                    display={"flex"}
                    alignContent={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      alignSelf={"center"}
                      fontSize={14}
                      color={"#101840"}
                      className={clsx(styles.noSelectAble)}
                    >{result.title || "New Key Result"}</Text>
                    {!_.isEmpty(result.description) &&
                      <DocumentIcon
                        color="#8F95B2"
                        size={12}
                        justifySelf={"center"}
                        marginTop={"auto"}
                        marginBottom={"auto"}
                        marginLeft={majorScale(1)}
                      />
                    }
                  </Pane>
                </Pane>
                <Pane display={"flex"} minWidth={"max-content"}>
                  {!_.isEmpty(result.assignees) &&
                    <Pane
                      display={"flex"}
                    >
                      {Object.values(result.assignees).map((assignee: any) => (
                        <Pane marginLeft={majorScale(1)} display={"grid"}>
                          <AssigneeAvatar assignee={assignee} members={members} size={majorScale(3)}/>
                        </Pane>
                      ))
                      }
                    </Pane>
                  }
                  {result.dueDate &&
                    <Pane marginLeft={majorScale(2)} display={"grid"}>
                      <Text fontSize={12} color="#696F8C" alignSelf={"center"}>
                        {displayDate(result.dueDate)}
                      </Text>
                    </Pane>
                  }
                  <Pane paddingLeft={majorScale(2)} display={"flex"}>
                    <Text
                      fontSize={11}
                      height={18}
                      width={36}
                      backgroundColor={"gray"}
                      paddingX={6}
                      borderRadius={4}
                      alignSelf={"center"}
                      background={(result.progress || 0) < 40 ? "#F9DADA" : (result?.progress || 0) < 70 ? "#FFEFD2" : "#DCF2EA"}
                      color={(result.progress || 0) < 40 ? "#7D2828" : (result?.progress || 0) < 70 ? "#66460D" : "#317159"}
                    >{(result.progress || 0) + "%"}</Text>
                  </Pane>
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        }
        <Expand
          duration={200}
          open={isExpanded}
          transitions={["height", "opacity"]}
        >
          {isExpanded &&
            <Pane ref={expandPaneRef}>
              <KeyResultInput
                key={result.id}
                result={result}
                members={members}
                saveResult={onSaveResult}
                setResultInfo={setResultInfo}
                onSelect={onSelect}
                isSelected={isSelected}
                onDeleteResult={onDeleteResult}
              />
            </Pane>
          }
        </Expand>
      </Pane>
      <Expand
        duration={200}
        open={isDropResultOver}
        transitions={["height", "opacity"]}
      >
        <Pane className={clsx(isDropResultOver && styles.dropResultOver)}/>
      </Expand>
    </>
  )
}

export default KeyResult;
