import { CardExpiryElement } from '@stripe/react-stripe-js';
import { CalendarIcon } from 'evergreen-ui';
import styles from './CardPayment.module.scss';
import CardPayment from './CardPayment';
import { useOptions } from './useOptions';

const CardExpiry = () => {
  const options = useOptions();
  return (
    <CardPayment className={styles.cardExpiry} icon={CalendarIcon}>
      <CardExpiryElement options={options} />
    </CardPayment>
  )
}

export default CardExpiry;
