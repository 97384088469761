import { useState } from 'react';
import { TextInput } from 'evergreen-ui';
import { Dialog } from 'components/Dialog';
import { useHistory } from 'react-router-dom';

import { NotebookService } from 'services/note';
import { NOTEBOOK_URL } from 'utils/constants';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  folder?: string;
}

const notebookService = new NotebookService();

export default function CreateNotebookModal({ isShown, setIsShown }: Props) {
  const [notebookName, setNotebookName] = useState('');
  const history = useHistory();

  const handleCreateNotebook = async () => {
    try {
      setIsShown(false);
      notebookService.create({
        name: notebookName,
      }).then(resp => {
        history.push(`${NOTEBOOK_URL}/${resp.id}`);
      });
    } finally {
      setNotebookName('');
    }
  };

  const onKeyPress = async (evt: any) => {
    if (evt.code === "Enter" || evt.code === "NumpadEnter") {
      await handleCreateNotebook();
    }
  }

  return (
    <Dialog
      isShown={isShown}
      title="New Notebook"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Create"
      onConfirm={handleCreateNotebook}
    >
      <TextInput
        width="100%"
        required
        name="name"
        autoFocus={true}
        value={notebookName}
        onChange={(e: any) => setNotebookName(e.target.value)}
        placeholder="Notebook name"
        onKeyPress={onKeyPress}
      />
    </Dialog>
  );
}
