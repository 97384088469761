import { Heading, Pane } from 'evergreen-ui';
import styles from './Box.module.scss';

export const Box = ({ title, children, isDivider }) => (
  <Pane className={styles.content}>
    {title && (
      <Heading size={400}>
        {title}
      </Heading>
    )}
    {children}
    {isDivider && <hr className={styles.divider}/>}
  </Pane>
);
