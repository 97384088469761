import {DocumentSchema, NoteSchema} from 'schemas';
import {PermissonType, NotePermissonType, Role, RoleSchema, NoteRoleSchema} from 'schemas/role';
import _ from "lodash"
import {getTeams, getUser} from "../../utils/store";
import { NotebookSchema } from 'schemas/notebook';

const ROLE_PERMISSIONS = {
  [Role.None]: [],
  [Role.Viewer]: [PermissonType.ViewDoc],
  [Role.Commenter]: [PermissonType.ViewDoc, PermissonType.CommentDoc],
  [Role.Editor]: [
    PermissonType.ViewDoc,
    PermissonType.CommentDoc,
    PermissonType.RenameDoc,
    PermissonType.EditDoc,
    PermissonType.InviteUser,
    PermissonType.ExportDoc,
  ],
  [Role.Owner]: [
    PermissonType.ViewDoc,
    PermissonType.CommentDoc,
    PermissonType.RenameDoc,
    PermissonType.EditDoc,
    PermissonType.InviteUser,
    PermissonType.ExportDoc,
    PermissonType.DeleteDoc,
  ],
};

type PermissionKey = keyof typeof PermissonType;

const NOTE_ROLE_PERMISSIONS = {
  [Role.Viewer]: [NotePermissonType.ViewNote],
  [Role.Editor]: [
    NotePermissonType.EditNote,
    NotePermissonType.ViewNote,
    NotePermissonType.InviteUser
  ],
  [Role.Owner]: [
    NotePermissonType.EditNote,
    NotePermissonType.ViewNote,
    NotePermissonType.InviteUser,
    NotePermissonType.DeleteNote
  ],
};

type NotePermissionKey = keyof typeof NotePermissonType;

export function isHavePermission(
  user: any,
  document: DocumentSchema,
  permission: PermissionKey,
  folder?: any
): boolean {
  if (!document) {
    return false;
  }
  const roles = document.roles;
  const teams = getTeams();

  const { uid } = user || {uid: ""};
  const eid = 'anyone'
  if (roles && (roles[uid] || roles[eid])) {
    if (roles[uid] === Role.Owner) {
      return true;
    }

    const shareToUserRole = roles[uid] as RoleSchema;
    if (shareToUserRole && shareToUserRole.type && ROLE_PERMISSIONS[shareToUserRole.type]) {
      const permissionList = ROLE_PERMISSIONS[shareToUserRole.type] as PermissionKey[];
      return permissionList.includes(permission);
    }

    const shareToAnyoneRole = roles[eid] as RoleSchema;
    if (shareToAnyoneRole && shareToAnyoneRole.type && ROLE_PERMISSIONS[shareToAnyoneRole.type]) {
      const permissionList = ROLE_PERMISSIONS[shareToAnyoneRole.type] as PermissionKey[];
      return permissionList.includes(permission);
    }
  }

  if (teams) {
    const teamRoles = (document.teamRoles || {});

    const priorityPermissions = ["editor", "commenter", "viewer"];
    const roles = (teams || []).map((team: any) => {
      const role = _.get(teamRoles, team.id, {});
      return role.type;
    })
      .filter((role: string) => role)
      .sort((first: string, second: string) => {
        return priorityPermissions.indexOf(first) > priorityPermissions.indexOf(second) ? 1 : -1;
      });
    const highestRole = _.get(roles, 0);
    if (highestRole) {
      const permissionList = _.get(ROLE_PERMISSIONS, highestRole, ROLE_PERMISSIONS[Role.Viewer]) as PermissionKey[];
      return permissionList.includes(permission);
    }
  }

  if (folder) {
    const currentFolderShareRole = folder.shareRole as Role;
    const parentFolderShareRole = (folder.parentFolder || {shareRole: null}).shareRole;

    if (currentFolderShareRole){
      const permissionList = _.get(ROLE_PERMISSIONS, currentFolderShareRole, ROLE_PERMISSIONS[Role.Viewer]) as PermissionKey[];
      return permissionList.includes(permission);
    }

    if (parentFolderShareRole){
      const permissionList = _.get(ROLE_PERMISSIONS, parentFolderShareRole, ROLE_PERMISSIONS[Role.Viewer]) as PermissionKey[];
      return permissionList.includes(permission);
    }
  }

  return false;
}


export function isHaveNotePermission(
  note: NoteSchema,
  permission: NotePermissionKey,
): boolean {
  const user = getUser();
  if (!note || (!user && permission !== NotePermissonType.ViewNote)) {
    return false;
  }
  const roles = note.roles;
  const teams = getTeams();

  const { uid } = user || { uid: '' };
  const eid = 'anyone';
  if (roles && (roles[uid] || roles[eid])) {
    if (roles[uid] === Role.Owner) {
      return true;
    }
    const shareToUserRole = roles[uid] as NoteRoleSchema;
    if (
      shareToUserRole &&
      shareToUserRole.type &&
      NOTE_ROLE_PERMISSIONS[shareToUserRole.type]
    ) {
      const permissionList = NOTE_ROLE_PERMISSIONS[
        shareToUserRole.type
      ] as NotePermissionKey[];
      return permissionList.includes(permission);
    }

    const shareToAnyoneRole = roles[eid] as NoteRoleSchema;
    if (
      shareToAnyoneRole &&
      shareToAnyoneRole.type &&
      NOTE_ROLE_PERMISSIONS[shareToAnyoneRole.type]
    ) {
      const permissionList = NOTE_ROLE_PERMISSIONS[
        shareToAnyoneRole.type
      ] as NotePermissionKey[];
      return permissionList.includes(permission);
    }
  }

  if (teams) {
    const teamRoles = note.teamRoles || {};

    const priorityPermissions = ['editor', 'viewer'];
    const roles = (teams || [])
      .map((team: any) => {
        const role = _.get(teamRoles, team.id, {});
        return role.type;
      })
      .filter((role: string) => role)
      .sort((first: string, second: string) => {
        return priorityPermissions.indexOf(first) >
          priorityPermissions.indexOf(second)
          ? 1
          : -1;
      });
    const highestRole = _.get(roles, 0);
    if (highestRole) {
      const permissionList = _.get(
        NOTE_ROLE_PERMISSIONS,
        highestRole,
        NOTE_ROLE_PERMISSIONS[Role.Viewer],
      ) as NotePermissionKey[];
      return permissionList.includes(permission);
    }
  }

  return false;
}

export function isHaveNotebookPermission(
  note: NotebookSchema,
  permission: NotePermissionKey,
): boolean {
  const user = getUser();
  if (!note || (!user && permission !== NotePermissonType.ViewNote)) {
    return false;
  }
  const roles = note.roles;
  const teams = getTeams();

  const { uid } = user || { uid: '' };
  const eid = 'anyone';
  if (roles && (roles[uid] || roles[eid])) {
    if (roles[uid] === Role.Owner) {
      return true;
    }
    const shareToUserRole = roles[uid] as NoteRoleSchema;
    if (
      shareToUserRole &&
      shareToUserRole.type &&
      NOTE_ROLE_PERMISSIONS[shareToUserRole.type]
    ) {
      const permissionList = NOTE_ROLE_PERMISSIONS[
        shareToUserRole.type
      ] as NotePermissionKey[];
      return permissionList.includes(permission);
    }

    const shareToAnyoneRole = roles[eid] as NoteRoleSchema;
    if (
      shareToAnyoneRole &&
      shareToAnyoneRole.type &&
      NOTE_ROLE_PERMISSIONS[shareToAnyoneRole.type]
    ) {
      const permissionList = NOTE_ROLE_PERMISSIONS[
        shareToAnyoneRole.type
      ] as NotePermissionKey[];
      return permissionList.includes(permission);
    }
  }

  if (teams) {
    const teamRoles = note.teamRoles || {};

    const priorityPermissions = ['editor', 'viewer'];
    const roles = (teams || [])
      .map((team: any) => {
        const role = _.get(teamRoles, team.id, {});
        return role.type;
      })
      .filter((role: string) => role)
      .sort((first: string, second: string) => {
        return priorityPermissions.indexOf(first) >
          priorityPermissions.indexOf(second)
          ? 1
          : -1;
      });
    const highestRole = _.get(roles, 0);
    if (highestRole) {
      const permissionList = _.get(
        NOTE_ROLE_PERMISSIONS,
        highestRole,
        NOTE_ROLE_PERMISSIONS[Role.Viewer],
      ) as NotePermissionKey[];
      return permissionList.includes(permission);
    }
  }

  return false;
}
