import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { getSortedNote, handleSortNote, useSearchNotes, useShareNotes } from 'services/note';
import styles from './NoteList.module.scss';
import { NoteListType, NoteSchema } from 'schemas';
import { getTeams, getUserSort } from 'utils/store';
import ShareNoteHeader from './ShareNoteHeader';
import SearchBox from './NoteSearchBox';
import NoteList from './NoteList';
import { NOTE_SORT } from 'utils/constants';

export type NoteListProps = {
  type: NoteListType;
};

export default function NoteShareList() {
  const activateTeams = getTeams() || [];
  const { notes, setNotes } = useShareNotes(activateTeams);
  const [keyword, setKeyword] = useState('');
  const { resultNotes } = useSearchNotes(keyword, notes);
  const userSort = getUserSort(NOTE_SORT);
  const [selectedOrder, setSelectedOrder] = useState(`${userSort?.field}:${userSort?.direction}`);
  const [sortedNotes, setSortedNotes] = useState([] as NoteSchema[]);

  useEffect(() => {
    setSortedNotes(getSortedNote(resultNotes));
  }, [resultNotes]);

  const handleSort = (input: string) => {
    handleSortNote(input, notes, setSelectedOrder, setNotes);
  };

  return (
    <div className={clsx(styles.noteList)}>
      <div className={styles.headerContainer}>
        <ShareNoteHeader title={`Shared with me`} />
        <hr />
        <SearchBox
          keyword={keyword}
          setKeyword={setKeyword}
          sortBy={selectedOrder}
          handleSort={handleSort}
        />
        <hr />
      </div>

      <NoteList notes={sortedNotes} type={'share'} />
    </div>
  );
}
