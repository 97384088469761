/* eslint-disable */

import mx from '../mx';

import { mxConstants } from '../';

const mxCodec = mx.mxCodec;


mxCodec.encodeCell

/**
 * Function: encode
 *
 * Encodes the specified object and returns the resulting
 * XML node.
 *
 * Parameters:
 *
 * obj - Object to be encoded.
 */
mxCodec.prototype.encode = function (obj) {
  var node = null;

  if (obj != null && obj.constructor != null) {
    var enc = mxCodecRegistry.getCodec(obj.constructor);

    if (enc != null) {
      node = enc.encode(this, obj);
    } else {
      if (mxUtils.isNode(obj)) {
        node = mxUtils.importNode(this.document, obj, true);
      } else {
        mxLog.warn(
          'mxCodec.encode: No codec for ' +
            mxUtils.getFunctionName(obj.constructor),
        );
      }
    }
  }

  return node;
};

/**
 * Function: encodeCell
 *
 * Encoding of cell hierarchies is built-into the core, but
 * is a higher-level function that needs to be explicitely
 * used by the respective object encoders (eg. <mxModelCodec>,
 * <mxChildChangeCodec> and <mxRootChangeCodec>). This
 * implementation writes the given cell and its children as a
 * (flat) sequence into the given node. The children are not
 * encoded if the optional includeChildren is false. The
 * function is in charge of adding the result into the
 * given node and has no return value.
 *
 * Parameters:
 *
 * cell - <mxCell> to be encoded.
 * node - Parent XML node to add the encoded cell into.
 * includeChildren - Optional boolean indicating if the
 * function should include all descendents. Default is true. 
 */
 mxCodec.prototype.encodeCell = function(cell, node, includeChildren)
 {
   if (cell && (!cell.hasOwnProperty('visible') || cell.visible)) {
    node.appendChild(this.encode(cell));
   
    if (includeChildren == null || includeChildren)
    {
      var childCount = cell.getChildCount();
      
      for (var i = 0; i < childCount; i++)
      {
        this.encodeCell(cell.getChildAt(i), node);
      }
    }
   }
 };

/**
 * Function: decode
 *
 * Decodes the given XML node. The optional "into"
 * argument specifies an existing object to be
 * used. If no object is given, then a new instance
 * is created using the constructor from the codec.
 *
 * The function returns the passed in object or
 * the new instance if no object was given.
 *
 * Parameters:
 *
 * node - XML node to be decoded.
 * into - Optional object to be decodec into.
 */
mxCodec.prototype.decode = function (node, into) {
  this.updateElements();
  var obj = null;

  if (node != null && node.nodeType == mxConstants.NODETYPE_ELEMENT) {
    var ctor = null;

    try {
      ctor = window[node.nodeName];
    } catch (err) {
      // ignore
    }

    var dec = mx.mxCodecRegistry.getCodec(ctor);

    if (dec != null) {
      obj = dec.decode(this, node, into);
    } else {
      obj = node.cloneNode(true);
      obj.removeAttribute('as');
    }
  }

  return obj;
};

/**
 * Function: updateElements
 *
 * Returns the element with the given ID from <document>.
 *
 * Parameters:
 *
 * id - String that contains the ID.
 */
mxCodec.prototype.updateElements = function () {
  if (this.elements == null) {
    this.elements = new Object();

    if (this.document.documentElement != null) {
      this.addElement(this.document.documentElement);
    }
  }
};

/**
 * Function: addElement
 *
 * Adds the given element to <elements> if it has an ID.
 */
mxCodec.prototype.addElement = function (node) {
  if (node.nodeType == mxConstants.NODETYPE_ELEMENT) {
    var id = node.getAttribute('id');

    if (id != null) {
      if (this.elements[id] == null) {
        this.elements[id] = node;
      } else if (this.elements[id] != node) {
        // throw new Error(id + ': Duplicate ID');
      }
    }
  }

  node = node.firstChild;

  while (node != null) {
    this.addElement(node);
    node = node.nextSibling;
  }
};

export { mxCodec };
