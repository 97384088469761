import { ColorPaletteContainer } from '../../ColorPalette/ColorPaletteContainer';
import { ColorPalette } from '../../ColorPalette/ColorPalette';
import { Box } from '../../Box/Box';
import { updateSelectionColor } from "../../../../utils/Actions";
import { SketchPicker } from 'react-color';
import { Pane, Popover, Position } from "evergreen-ui";
import clsx from "clsx";
import styles from "../../ColorPalette/ColorPalette.module.scss";
import _ from "lodash"

import { mxConstants, mxUtils } from 'libs/mxgraph';
import {ZwfConstants} from "../../../../utils/ZwfConstants";

const topColors = [
  '#101840',
  '#D8DAE5',
  '#D6E0FF',
  '#DCF2EA',
  '#FFFFFF'
]
const bottomColors = [
  '#3366FF',
  '#FFB020',
  '#D14343',
  '#52BD94',
]

const ColorPanel = ({ graph, editorUi, graphState, dispatchGraphState, panelLabel }) => {

  const onSelectColor = (color) => {
    const cell = graph.getSelectionCell();
    const style = graph.getCellStyle(cell);
    const hasIcon = mxUtils.getValue(style, mxConstants.STYLE_IMAGE, null);
    const componentType = _.get(graphState, "zwfType");

    graph.getModel().beginUpdate();
    dispatchGraphState({fillColor: color});
    try {

      let isWhite = false;
      const isLine = _.get(graphState, ZwfConstants.TYPE_LINE);
      if(!isLine){
        if (color === '#FFFFFF') {
          graph.setCellStyles(mxConstants.STYLE_FONTCOLOR, '#474D66', [cell]);
        } else if (color === '#D8DAE5' || color === '#D6E0FF' || color === '#DCF2EA')  {
          graph.setCellStyles(mxConstants.STYLE_FONTCOLOR, '#474D66', [cell]);
        } else {
          isWhite = true;
          graph.setCellStyles(mxConstants.STYLE_FONTCOLOR, '#FFFFFF', [cell]);
        }
      }

      const isBackgroundWhite = color === '#FFFFFF';
      const isShape3D = componentType === "shape3D";
      updateSelectionColor(editorUi, color, isBackgroundWhite ? "#000000" : isShape3D ? "#FFFFFF" : color );
      if (hasIcon) {
        if (isWhite) {
          graph.setCellStyles(mxConstants.STYLE_IMAGE, hasIcon.replace('/icons', '/white-icons'));
        } else {
          graph.setCellStyles(mxConstants.STYLE_IMAGE, hasIcon.replace('/white-icons', '/icons'));
        }
      }
    } finally {
      graph.getModel().endUpdate();
    }
  }

  return (
    <Box title={panelLabel} isDivider>
      <ColorPaletteContainer>
        {topColors.map(item => (
          <ColorPalette
            key={item}
            isActive={item === graphState.fillColor}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
      </ColorPaletteContainer>
      <ColorPaletteContainer>
        {bottomColors.map(item => (
          <ColorPalette
            key={item}
            isActive={item === graphState.fillColor}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
        <Popover
          content={
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <SketchPicker
                color={graphState.fillColor}
                onChangeComplete={ val => onSelectColor(val.hex) }
              />
            </Pane>
          }
          position={Position.TOP_RIGHT}
        >
          <Pane
            className={clsx(styles.palette)}
            background={"conic-gradient(from -44.17deg at 50% 50%, #D14343 -23.74deg, #3366FF 43.37deg, #52BD94 142.51deg, #FFB020 236.25deg, #D14343 336.26deg, #3366FF 403.37deg)"}
          />
        </Popover>
      </ColorPaletteContainer>
    </Box>
  )
}

export default ColorPanel;
