import { useState } from 'react';
import { Avatar, Button, majorScale, MediaIcon } from 'evergreen-ui';
import { getUser } from 'utils/store';
import { getUserName } from 'utils/helpers';
import ChangeAvatarModal from '../../../components/Modals/ChangeAvatarModal/ChangeAvatarModal';

const ChangeAvatarForm = () => {
  const [modal, setModal] = useState(false);
  const user = getUser() || {};
  const name = getUserName();
  return (
    <>
      <Avatar
        marginTop="1rem"
        name={name}
        src={user.photoUrl}
        size={majorScale(6)}
      />
      <Button
        display="flex"
        marginTop="1rem"
        iconBefore={<MediaIcon color="#8F95B2"/>}
        onClick={() => setModal(true)}
      >
        Select an image
      </Button>
      <ChangeAvatarModal modal={modal} setModal={setModal}/>
    </>
  )
};

export default ChangeAvatarForm;
