import { TagIcon } from 'evergreen-ui';
import useStores from 'hooks/useStores';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Observer } from 'mobx-react-lite';

import { useNoteList, useShareNotes } from 'services/note';
import styles from './TagSidebar.module.scss';
import { getTeams } from 'utils/store';
import clsx from 'clsx';
import { NOTE_TAG_URL } from 'utils/constants';

export default function TagSidebar() {
  const activateTeams = getTeams() || [];
  const { notes: shareNotes } = useShareNotes(activateTeams);
  const { notes } = useNoteList();
  const { noteStore } = useStores();

  const params = useParams() as any;
  const tagId = params.id;

  const isActiveRoute = (path: string) => {
    if (tagId === path) {
      return {
        isActive: true,
      };
    }
    return {
      isActive: false,
    };
  };

  useEffect(() => {
    if (notes.length) {
      noteStore.setNotes(notes);
    }
    if (shareNotes.length) {
      noteStore.setSharedNotes(shareNotes);
    }
  }, [notes, shareNotes]);

  return (
    <div className={styles.tagSidebar}>
      <Observer>
        {() => (
          <>
            {noteStore.tagList?.map((tag: string) => (
              <div
                key={tag}
                className={clsx(styles.tagItem, isActiveRoute(tag))}
              >
                <TagIcon className={styles.tagIcon} />

                <Link to={`${NOTE_TAG_URL}/${tag}`}>{tag}</Link>
              </div>
            ))}
          </>
        )}
      </Observer>
    </div>
  );
}
