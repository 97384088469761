import firebase from "firebase";
import {PROJECT_COLLECTION} from "../../utils/constants";
import {db} from 'utils/firebase';
import {ProjectInput} from "../../schemas/project";
import {getOwnerProjects, getUser} from "../../utils/store";
import _ from "lodash";
import {UserService} from "../user";
import {TeamService} from "../team/team.service";
import {TaskService} from "../task/task.service";

export class ProjectService {
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private readonly userService: UserService;
  private readonly teamService: TeamService;
  private readonly taskService: TaskService;

  constructor() {
    this.db = db.collection(PROJECT_COLLECTION);
    this.userService = new UserService();
    this.teamService = new TeamService();
    this.taskService = new TaskService();
  }

  async create({name}: ProjectInput){
    const { uid, email, firstName, lastName } = getUser();
    const getNextOrdinal = () => {
      const ownerProjects = getOwnerProjects();
      const lastProject = _.last(ownerProjects);
      const currentOrdinal = _.get(lastProject, "ordinal");

      return !currentOrdinal ? 1 : currentOrdinal + 1;
    }

    const data = {
      name,
      owner: uid,
      members: {
        [uid]: {
          id: uid,
          email,
          firstName,
          lastName,
          isOwner: true,
          addedAt: (new Date()).getTime()
        }
      },
      isDelete: false,
      ordinal: getNextOrdinal(),
      createdAt: new Date()
    }

    await this.db.add(data);
  }

  async updatePartial(id: string, payload: ProjectInput){
    const validData = {};
    Object.keys(payload).forEach(key => {
      const value = _.get(payload, key);
      if(value === null) return;

      _.set(validData, key, value);
    })
    const { uid } = getUser();
    const data = {
      ...payload,
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  async deleteProject(id: string){
    this.db.doc(id).delete().then(() => {
      this.taskService.deleteByProject(id);
    });
  }

  async removeMembers(id: string, removeMemberIds: string[]){
    const projectDoc = await this.db.doc(id).get();
    const project = projectDoc.exists ? projectDoc.data() : {};

    const savedMembers = _.get(project, "members", {});
    const owner = _.get(project, "owner");

    (removeMemberIds || []).forEach(memberId => {
      const isOwner = owner === memberId;
      if(isOwner) return;

      const isExisting = Object.keys(savedMembers).includes(memberId);
      if(!isExisting) return;

      delete savedMembers[memberId];
    })

    this.db.doc(id).update({
      members: savedMembers
    }).catch(ex => {
      console.log("add member throw ex ", ex.message);
    })
  }

  async addMembers(id: string, addMembers: string[]){
    const loginUser = getUser() || {};
    let users = [] as any[];
    let project = {};
    const usersPromise = this.userService
      .getManyByEmails(addMembers)
      .then(resp => {
        users = resp;
      });
    const projectPromise = this.db.doc(id).get()
      .then(resp => {
        project = resp?.data() || [] as any;
      });

    await Promise.all([usersPromise, projectPromise]);

    if(!users || !project) return;

    const savedMembers = _.get(project, "members", {});
    const owner = _.get(project, "owner");

    (users || []).forEach(user => {
      const isOwner = user.id === owner;
      if(isOwner) return;
      savedMembers[user.id] = {
        ...user,
        updatedByEmail: loginUser.email || '',
        addedAt: (new Date()).getTime()
      }
    })

    this.db.doc(id).update({
      members: savedMembers
    }).catch(ex => {
      console.log("add member throw ex ", ex.message);
    })
  }

  async getProjectList(ids: string[]){
    const validIds = (ids || []).filter(id => id);
    return this.db.where(firebase.firestore.FieldPath.documentId(), 'in', validIds).get();
  }

  async updateTeam(id: string, teamId: string, isAdd = false){
    let team = [] as any[];
    let project = {};
    const teamPromise = this.teamService.getTeamById(teamId)
      .then(resp => {
        team = resp?.data() || [] as any;
      });
    const projectPromise = this.db.doc(id).get()
      .then(resp => {
        project = resp?.data() || [] as any;
      });

    await Promise.all([teamPromise, projectPromise]);

    if(!team || !project) return;

    const savedTeams = _.get(project, "teams", {});
    if(isAdd){
      savedTeams[teamId] = {
        id: teamId
      }
    }
    else {
      const teamIds = Object.keys(savedTeams);
      const isExistingTeamId = teamIds.includes(teamId);
      if(isExistingTeamId){
        delete savedTeams[teamId];
      }
    }

    this.db.doc(id).update({
      teams: savedTeams
    }).catch(ex => {
      console.log("add member throw ex ", ex.message);
    })
  }

  updateOrdinalProjects(projects: any[]) {
    (projects || []).forEach((project: any) => {
      this.db.doc(project.id).update({
        ordinal: project.ordinal
      })
        .catch(ex => {
          console.log("update ordinal throw ex ", ex.message)
        })
    });
  }
}
