import { useState } from 'react';
import { Button, Card, majorScale, Pane, TextInput, toaster } from 'evergreen-ui';
import { AuthService } from 'services/authService';
import { AdminService } from 'services/admin';

const Support = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const adminService = new AdminService();
  const authService = new AuthService();

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  }

  const handleClick = async () => {
    try {
      setLoading(true);
      const { data } = await adminService.createCustomToken({ email });
      await authService.signInWithToken(data.token);
      window.location.href = '/app';
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Pane padding={majorScale(4)}>
      <Card padding={majorScale(4)} background={"white"} elevation={1}>
        <Pane color="#101840" fontWeight={600} fontSize={16}>Login user</Pane>
        <Pane paddingY={majorScale(3)}>
          <TextInput
            onChange={handleChangeEmail}
            className="mr-2"
            placeholder={"Email address"}
          />
          <Button
            isLoading={loading}
            appearance="primary"
            onClick={handleClick}
          >
            Login
          </Button>
        </Pane>
      </Card>
    </Pane>
  )
}

export default Support;
