/* eslint-disable no-undef */
import { makeAutoObservable } from 'mobx';
import { ConvergenceDomain, Convergence, RealTimeObject } from '@convergence/convergence';
import { configure } from "mobx"

import { ActivityColorManager, Serializer } from 'libs/mxgraph-adapter';
import { CVG_COLLECTION_ID } from 'utils/constants';
import { RootStore } from 'store';
import { getCollaborationToken, getUser } from 'utils/store';


configure({
  enforceActions: "never",
})

function patchReconnect(domain: Convergence.ConvergenceDomain) {
  // Patch Convergence Connection class to reconnect with the original JWT instead of a reconnect token.
  // Reconnecting with a reconnect token appears to be broken atm:
  // https://github.com/convergencelabs/convergence-project/issues/256
  const connection = (domain as any)._connection;
  connection.connectWithReconnectToken = connectWithReconnectToken;
}

function connectWithReconnectToken(this: any /* ConvergenceConnection */): Promise<void> {
  const gen = this._connectionRequestGenerator;
  return (gen
    ? this._connect(gen).catch((e: any) => this._handleReconnectFailure(e))
    : Promise.reject(new Error('No connectionRequestGenerator!  Have you connected even once before calling reconnect()?'))
  );
}

export default class ConvergenceStore {
  rootStore: RootStore;

  model: any = null;
  graph: any = null;

  domain: ConvergenceDomain | null = null;
  activity: any = null;
  activityColorManager: any = null;

  modelAdapter: any = null;
  pointerManager: any = null;
  selectionManager: any = null;

  sessions: any = {};

  rtGraph: RealTimeObject;
  connectionFailed: boolean = false;
  isConnected: boolean = false;
  connectionFailedType: string = '';

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async connect(domainUrl: string, token?: string): Promise<any> {
    const user = getUser();
    if (user) {
      const jwtToken = token || getCollaborationToken();
      return Convergence.connectWithJwt(domainUrl, jwtToken, {
        connection: {
          timeout: 90000,
          connectionRequestTimeout: 90000,
        },
        reconnect: {
          autoReconnect: true,
        },
        // offline: {
        //   storage: new IdbStorageAdapter()
        // }
      })
        .then(domain => {
          patchReconnect(domain);
          this.setDomain(domain);
          return domain;
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      return Convergence.connectAnonymously(domainUrl, '')
        .then(domain => {
          patchReconnect(domain);
          this.setDomain(domain);
          return;
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  disconnect(): void {
    if (this.domain !== null) {
      if (this.domain.session().isConnected()) {
        this.domain.dispose();
      }
    }
    this.domain = null;
  }

  async openModel(modelId: any, dataModel: any, mxGraphModel?: any) {
    if (this.domain) {
      return this.domain
        .models()
        .openAutoCreate({
          id: modelId,
          collection: CVG_COLLECTION_ID,
          data: Serializer.serializeMxGraphModel(new mxGraphModel()),
        })
        .then(model => {
          this.model = model;
        });
    }
  }

  async joinActivity(modelId: any) {
    const options = {
      autoCreate: {
        // ephemeral: true,
        worldPermissions: ['join', 'view_state', 'set_state'],
      },
    };
    if (this.domain) {
      return this.domain
        .activities()
        .join('mxgraph.project', modelId, options as any)
        .then(activity => {
          this.activity = activity;
          this.activityColorManager = new ActivityColorManager(activity);
        });
    }
  }

  setModelAdapter(modelAdapter: any) {
    this.modelAdapter = modelAdapter;
  }

  setPointerManager(pointerManager: any) {
    this.pointerManager = pointerManager;
  }

  setSelectionManager(selectionManager: any) {
    this.selectionManager = selectionManager;
  }

  initPresenceList() {
    const participants = this.activity
      .participants()
      .sort((a: any, b: any) => (a.local ? -1 : 1));
    participants.forEach((participant: any) => {
      this.addSession(participant);
    });

    this.activity.on('session_joined', (e: any) => {
      this.addSession(e.participant);
    });

    this.activity.on('session_left', (e: any) => {
      this.removeSession(e.user?.email);
    });
  }

  addSession(participant: any) {
    if (participant.user.email) {
      const color = this.activityColorManager.color(participant.sessionId);
      const displayName = participant.user.displayName || participant.user.email;
      const session = {
        local: participant.local,
        email: participant.user.email,
        username: participant.user.username,
        displayName: displayName,
        sessionId: participant.sessionId,
        color: color,
      };
      this.sessions[session.email] = session;
    }
  }

  removeSession(email: any) {
    if (email && this.sessions[email]) {
      delete this.sessions[email];
    }
  }

  setDomain(domain: ConvergenceDomain): void {
    this.domain = domain;
    this.addListenerDomain();
  }

  setGraph(graph: any): void {
    this.graph = graph;
  }

  setRtGraph(rtGraph: RealTimeObject): void {
    this.rtGraph = rtGraph;
  }

  addListenerDomain() {
    if (this.domain) {
      this.domain.on('connection_failed', (e: any) => {
        console.log('connection_failed set', e);
        this.isConnected = false;
        this.connectionFailed = true;
        this.connectionFailedType = e.code;

        // this.rootStore.graphStore.editorUi.editor.graph.setEnabled(true);
        if (e.code === 'domain_unavailable') {
          window.location.reload();
        }
      });

      this.domain.on('connected', (e: any) => {
        this.connectionFailed = false;
        // console.log('connected set', e);
        this.isConnected = true;
      });
    }
  }

  setConnected(connected: boolean) {
    this.isConnected = connected;
  }
}
