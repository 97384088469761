import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { getSortedNote, handleSortNote, useSearchNotes } from 'services/note';
import styles from './NoteList.module.scss';
import { NoteListType, NoteSchema } from 'schemas';
import useStores from 'hooks/useStores';
import TagNoteHeader from './TagNoteHeader';
import SearchBox from './NoteSearchBox';
import NoteList from './NoteList';
import { getUserSort } from 'utils/store';
import { NOTE_SORT } from 'utils/constants';

export type NoteListProps = {
  type?: NoteListType;
  tagId: string;
  noteList: NoteSchema[];
};

export default function NoteTagList({ tagId, noteList }: NoteListProps) {
  const { noteStore } = useStores();
  const [notes, setNotes] = useState(noteList);
  const [keyword, setKeyword] = useState('');
  const [sortedNotes, setSortedNotes] = useState([] as NoteSchema[]);
  const { resultNotes } = useSearchNotes(keyword, notes, tagId);
  const userSort = getUserSort(NOTE_SORT);
  const [selectedOrder, setSelectedOrder] = useState(`${userSort?.field}:${userSort?.direction}`);

  useEffect(() => {
    setNotes(noteStore.noteList);
  }, [noteStore.noteList])

  useEffect(() => {
    setSortedNotes(getSortedNote(resultNotes, noteStore.tag));
  }, [resultNotes]);

  const handleSort = (input: string) => {
    handleSortNote(input, notes, setSelectedOrder, setNotes);
  };
  
  return (
    <div className={clsx(styles.noteList)}>
      <div className={styles.headerContainer}>
        <TagNoteHeader tagId={tagId} />
        <hr />
        <SearchBox
          keyword={keyword}
          setKeyword={setKeyword}
          sortBy={selectedOrder}
          handleSort={handleSort}
        />
        <hr />
      </div>
      <NoteList
        notes={sortedNotes}
        type={`tag:${tagId}`}
      />
    </div>
  );
}
