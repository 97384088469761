import React from 'react';
import styled from "@emotion/styled";
import {
    Pane,
    Dialog,
    TickIcon
} from "evergreen-ui";
import styles from "./ChangeBgModal.module.scss";
import { LISTBG } from "./listColorBg";
import { useRecoilState } from 'recoil';
import { activeColorBG } from '../../../recoil/atom';

const ButtonColor = styled.div`
    background-color: ${({ color }) => color};
    width: 32px;
    height: 32px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
`;

type Props = {
    isShowModalChangeBg: boolean,
    setIsShowModalChangeBg: any
}

export default function ChangeBgModal({ isShowModalChangeBg, setIsShowModalChangeBg }: Props) {
    const [activeBg, setActiveBg] = useRecoilState(activeColorBG)

    return (
        <Dialog
            width={350}
            isShown={isShowModalChangeBg}
            title="Board Background"
            hasFooter={false}
            onCloseComplete={() => setIsShowModalChangeBg(false)}
        >
            <Pane className={styles.formChangeBg}>
                {
                    LISTBG.map((val) => {
                        return (
                            <ButtonColor color={val.color} onClick={() => {
                                localStorage.setItem('colorBg', val.color)
                                setActiveBg(val.color)
                            }}>
                                {
                                    activeBg === val.color
                                    &&
                                    <TickIcon />
                                }
                            </ButtonColor>
                        )
                    })
                }
            </Pane>
        </Dialog>
    )
}
