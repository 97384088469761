/* eslint-disable */
import {
    Activity,
    ActivityParticipant,
    ActivitySessionJoinedEvent,
    ActivitySessionLeftEvent,
    ActivityStateRemovedEvent,
    ActivityStateSetEvent
  } from "@convergence/convergence";
  import {mxGraph} from "mxgraph";
  import {ActivityColorManager} from "./ActivityColorManager";

  export class PointerManager {

    private static _SVG_NS = "http://www.w3.org/2000/svg";
    private static _CURSOR_PATH = "M 0,0 L 0,0 11.6,11.6 6.7,11.6 9.6,18.3 6.0,20.2 3.1,13.3 0,16z";
    private static _POINTER_KEY = "pointer";

    private readonly _mxGraph: mxGraph;
    private readonly _activity: Activity;
    private readonly _colorManager: ActivityColorManager;
    private readonly _remotePointers: Map<string, HTMLElement>;
    private readonly _root: SVGElement;

    private _active: boolean;

    constructor(graph: mxGraph, activity: Activity, colorManager: ActivityColorManager) {
      this._mxGraph = graph;
      this._activity = activity;
      this._colorManager = colorManager;
      this._remotePointers = new Map();
      this._root = this._mxGraph.view?.getOverlayPane() as any as SVGElement;

      this._active = true;

      this._listenToGraph();
      this._listenToActivity();

      this._activity.participants().forEach((participant: ActivityParticipant) => {
        this._addRemotePointer(participant);
      });
    }

    private _listenToGraph(): void {
      this._root.ownerSVGElement?.addEventListener("mouseleave", () => {
        this._activity.removeState(PointerManager._POINTER_KEY);
        this._active = false;
      });

      this._root.ownerSVGElement?.addEventListener("mouseenter", () => {
        this._active = true;
      });

      this._mxGraph.addMouseListener({
        mouseDown: () => {
          // No-Op
        },
        mouseMove: (_: any, evt: any) => {
          if (this._active) {
            const {graphX, graphY} = evt;
            const scale = this._mxGraph.view.scale;
            const translate = this._mxGraph.view.translate;
            const tX = Math.round((graphX - translate.x * scale) / scale);
            const tY = Math.round((graphY - translate.y * scale) / scale);
            const pointerState = {x: tX, y: tY};
            this._activity.setState(PointerManager._POINTER_KEY, pointerState);
          }
        },
        mouseUp: () => {
          // Click animation.
        }
      });
    }

    private _listenToActivity(): void {
      this._activity.on("session_joined", (e: any) => {
        this._addRemotePointer(e.participant);
      });

      this._activity.on("session_left", (e: ActivitySessionLeftEvent) => {
        const remotePointer = this._remotePointers.get(e.sessionId);
        remotePointer?.parentElement?.removeChild(remotePointer);
        this._remotePointers.delete(e.sessionId);
      });

      this._activity.on("state_set", (e: any) => {
        const {key, value, sessionId, local} = e;
        if (!local && key === PointerManager._POINTER_KEY) {
          const remotePointer = this._remotePointers.get(sessionId);
          const scale = this._mxGraph.view.scale;
          const translate = this._mxGraph.view.translate;
          const graphX = Math.round((value.x + translate.x) * scale);
          const graphY = Math.round((value.y + translate.y) * scale);
          remotePointer?.setAttributeNS(null, "x", `${graphX}`);
          remotePointer?.setAttributeNS(null, "y", `${graphY}`);
          remotePointer?.setAttributeNS(null, "visibility", "visible");

          const textPointer = this._remotePointers.get(sessionId + '_text');
          remotePointer?.setAttributeNS(null, "transform", `translate(${graphX},${graphY})`);

          textPointer?.setAttributeNS(null, "x", `${graphX + 15}`);
          textPointer?.setAttributeNS(null, "y", `${graphY + 25}`);
          textPointer?.setAttributeNS(null, "visibility", "visible");
        }
      });

      this._activity.on("state_removed", (e: any) => {
        const {key, sessionId, local} = e;
        if (!local && key === "pointer") {
          const remotePointer = this._remotePointers.get(sessionId);
          const textPointer = this._remotePointers.get(sessionId + '_text');
          remotePointer?.setAttributeNS(null, "visibility", "hidden");
          textPointer?.setAttributeNS(null, "visibility", "hidden");
        }
      });
    }

    hexToRGB(hex: string, alpha?: number) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
    }

    private _addRemotePointer(participant: ActivityParticipant): void {
      if (!participant.local) {
        const pointer = participant.state.get(PointerManager._POINTER_KEY) || {x: 0, y: 0};
        const color = this._colorManager.color(participant.sessionId);

        const remotePointer = document.createElementNS(PointerManager._SVG_NS, "svg");
        remotePointer.setAttributeNS(null, "x", `${pointer.x}`);
        remotePointer.setAttributeNS(null, "y", `${pointer.y}`);
        remotePointer.setAttributeNS(null, "width", '28');
        remotePointer.setAttributeNS(null, "height", '28');
        remotePointer.setAttributeNS(null, "viewBox", '0 0 28 28');
        remotePointer.setAttributeNS(null, "fill", 'none');

        const pathItems = [
          {
            d: "M8.2002 20.8999V4.8999L19.8002 16.4999H13.0002L12.6002 16.5999L8.2002 20.8999Z",
            fill: "white"
          },
          {
            d: "M17.3 21.6L13.7 23.1L9 12L12.7 10.5L17.3 21.6Z",
            fill: "white"
          },
          {
            d: "M12.8745 13.5185L11.0303 14.2927L14.1271 21.6695L15.9713 20.8953L12.8745 13.5185Z",
            fill: "black"
          },
          {
            d: "M9.2002 7.30005V18.5L12.2002 15.6L12.6002 15.5H17.4002L9.2002 7.30005Z",
            fill: "black"
          }
        ];

        pathItems.forEach(pathItem => {
          let path = document.createElementNS(PointerManager._SVG_NS, "path");
          path.setAttribute("fill", pathItem.fill);
          path.setAttribute("d", pathItem.d);
          remotePointer.appendChild(path);
        });

        const textPointer = document.createElementNS(PointerManager._SVG_NS, "foreignObject");
        textPointer.setAttributeNS(null, "style", "overflow: visible;");
        textPointer.setAttributeNS(null, "x", pointer.x);
        textPointer.setAttributeNS(null, "y", pointer.y);
        textPointer.setAttribute('width', '100%');
        textPointer.setAttribute('height','16');

        const newDiv = document.createElement('div');
        const text = document.createTextNode(participant?.user.username || participant?.user.email || 'Anonymous');
        const backgroundColor = this.hexToRGB(color, 0.2);
        newDiv.style.cssText = `
          display: inline-block;
          color: ${color};
          background-color: ${backgroundColor};
          opacity: 0.5;
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.2px;
          text-transform: uppercase;
          border-radius: 4px;
          padding: 0 6px;
        `;
        newDiv.appendChild(text);
        textPointer.appendChild(newDiv);

        this._remotePointers.set(participant.sessionId, remotePointer as HTMLElement);
        this._remotePointers.set(participant.sessionId + '_text', textPointer as HTMLElement);
        this._root.appendChild(remotePointer);
        this._root.appendChild(textPointer);

        if (!participant.state.has(PointerManager._POINTER_KEY)) {
          remotePointer.setAttributeNS(null, "visibility", "hidden");
          textPointer.setAttributeNS(null, "visibility", "hidden");
        }
      }
    }
  }
