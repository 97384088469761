import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { Button, majorScale, TextInput, toaster } from 'evergreen-ui';
import { SIGN_IN_URL } from 'utils/constants';
import { ResetService } from 'services/reset';

export const SetPasswordForm = ({ onError }: { onError: Function }) => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const resetService = new ResetService();

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup
        .string()
        .required(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'New Password must match')
    }),
    onSubmit: async ({ password }) => {
      try {
        await resetService.updatePassword(token, password);
        toaster.success('Your password has been updated successfully');
        history.push(SIGN_IN_URL);
      } catch (e: any) {
        onError(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="mt-45" onSubmit={handleSubmit}>
      <TextInput
        type="password"
        placeholder="New password"
        className="w-100"
        isInvalid={!!(touched.password && errors.password)}
        {...getFieldProps('password')}
      />
      <TextInput
        type="password"
        placeholder="Confirm new password"
        className="w-100 mt-3"
        isInvalid={!!(touched.confirmPassword && errors.confirmPassword)}
        {...getFieldProps('confirmPassword')}
      />
      <Button
        appearance="primary"
        type="submit"
        className="w-100 mt-3"
        height={majorScale(5)}
        isLoading={isSubmitting}
      >
        Save Password
      </Button>
    </form>
  )
}
