import { useHistory } from 'react-router-dom';
import { Button, DuplicateIcon, Menu, Popover, Position, Spinner, TrashIcon } from 'evergreen-ui';
import { TableCell, TableFirstCell, TableLastCell, TableRow } from 'components/Documents/Table/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { truncate } from '../../utils/helpers';
import { useEffect, useState } from 'react';

import styles from './Documents.module.scss';
import { timeFromNow } from 'utils/datetime';
import { DocumentService } from 'services/document';
import EditIcon from 'components/Icon/EditIcon';
import { DocumentSchema, DocumentTheme, DocumentType, DocumentTypeDisplay, isMindMap } from 'schemas/document';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { getUser, isFreeUser } from '../../utils/store';
import { FREE_USER_LIMIT_DOCS } from '../../utils/constants';
import { useCheckFolder } from '../../services/folder';
import _ from 'lodash';

type Props = {
  doc: DocumentSchema;
  showRenameModal: Function;
  showShareModal: Function;
  setShowProFeature: Function;
  createdDocs: Number,
  folder?: any
};

const documentService = new DocumentService();

export default function DocumentRow(
  { doc,
    showRenameModal,
    showShareModal,
    createdDocs,
    setShowProFeature,
    folder
  }: Props) {
  const [showLast, setShowLast] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const history = useHistory();
  const {isShareFolder, folderRole} = useCheckFolder(folder);
  const user = getUser();
  const isOwner = _.get(doc, `roles.${user?.uid}`) === "owner";

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [{ isDragging }, dragRef, preview] = useDrag(() => ({
    type: 'DOCUMENT',
    item: {
      type: 'DOCUMENT',
      doc: doc,
    },

    end: (item, monitor) => {
      monitor.getDropResult();
    },

    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => {
      if (monitor.isDragging()) {
        document.body.classList.add('dragging');
      } else {
        document.body.classList.remove('dragging');
      }
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  const handleDuplicateDocument = async (doc: DocumentSchema, close: () => void) => {
    const isLimitDocs = isFreeUser() && createdDocs >= FREE_USER_LIMIT_DOCS;
    setShowProFeature(isLimitDocs);
    if(isLimitDocs) return;
    setIsDuplicating(true);
    await documentService.duplicate(doc.id);
    setIsDuplicating(false);
    close();
  };

  const handleDeleteDocument = async (doc: DocumentSchema) => {
    await documentService.softDelete(doc.id);
  };

  const openDocument = (id: string, type: DocumentType, theme: DocumentTheme) => {
    if (isMindMap(type)) {
      localStorage.setItem('theme', theme)
    }
    history.push(`/${type || DocumentType.Document}/${id}`)
  }

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return (
    <TableRow key={doc.id}>
      <TableFirstCell>
        <span ref={dragRef} onClick={() => openDocument(doc.id, doc.type, (doc.theme || DocumentTheme.Rainbow))}>
          {truncate(doc.name)}
        </span>
      </TableFirstCell>
      <TableCell className={styles.typeColumn}>
        {_.get(DocumentTypeDisplay, doc.type, DocumentTypeDisplay[DocumentType.Flowchart])}
      </TableCell>
      <TableCell className={clsx('d-mobile-none', styles.dateCreated)}>
        {dayjs(doc.createdAt?.toDate()).format('MMM DD, YYYY')}
      </TableCell>
      <TableCell className="d-mobile-none">
        {timeFromNow(doc.updatedAt?.toDate(), doc.createdAt?.toDate())}
      </TableCell>
      <TableLastCell
        className="d-mobile-none"
        style={{ 'visibility': showLast ? 'visible' : 'hidden'}}
      >
        {(!isShareFolder || (isShareFolder && folderRole === "editor")) &&
          <Button
            className={clsx(styles.actionBtn, styles.shareBtn)}
            onClick={() => showShareModal(doc)}
          >
            Share
          </Button>
        }
        {(!isShareFolder || (isShareFolder && folderRole === "editor" && isOwner)) &&
          <Popover
            position={Position.BOTTOM_RIGHT}
            minWidth="208px"
            onOpen={() => setShowLast(true)}
            onCloseComplete={() => setShowLast(false)}
            onBodyClick={() => {
              setShowLast(false)
            }}
            content={({close}) => (
              <Menu>
                <Menu.Group>
                  {(!isShareFolder) &&
                    <>
                      <Menu.Item className={styles.menuItem} icon={EditIcon} onClick={() => showRenameModal(doc)}>
                        Rename
                      </Menu.Item>
                      <Menu.Item
                        className={styles.menuItem}
                        icon={isDuplicating ? <Spinner color="#8F95B2"/> : <DuplicateIcon color="#8F95B2"/>}
                        onClick={() => handleDuplicateDocument(doc, close)}
                        disabled={isDuplicating}
                      >
                        Duplicate
                      </Menu.Item>
                    </>
                  }
                  {(!isShareFolder || (isShareFolder && folderRole === "editor" && isOwner)) &&
                    <Menu.Item
                      className={styles.menuItem}
                      icon={TrashIcon}
                      intent="danger"
                      onClick={() => handleDeleteDocument(doc)}
                    >
                      Move to Trash
                    </Menu.Item>
                  }
                </Menu.Group>
              </Menu>
            )}
          >
            <Button className={clsx(styles.actionBtn, styles.moreBtn)}>More</Button>
          </Popover>
        }
      </TableLastCell>
    </TableRow>
  );
}
