import { Pane, Avatar, Badge } from "evergreen-ui";
import React, { useState } from "react";
import CardModal from "../../../components/Kanban/Card/CardModal"
import styles from "../Kanban.module.scss";

type Props = {
  quote: any;
  isDragging: boolean;
  isGroupedOver: boolean;
  provided: any;
}

export default function QuoteItem({ quote, isDragging, isGroupedOver, provided }: Props) {
  const [isShowCardModal, setIsShowCardModal] = useState(false)

  return (
    <Pane
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={styles.containerItem}
      onClick={() => setIsShowCardModal(true)}
    >
      <Pane className={styles.contentItem}>
        <Pane>
          <Badge color="neutral" marginRight={8}>
            neutral
            </Badge>
          <Badge color="green" marginRight={8}>
            green
            </Badge>
          <Badge color="blue" marginRight={8}>
            blue
            </Badge>
        </Pane>
        <Pane>
          {quote.content}
        </Pane>
        <Pane className={styles.listAvatar}>
          <Avatar className={styles.avatar} src={quote.author.avatarUrl} alt={quote.author.name} name={quote.author.name} size={30} />
          <Avatar className={styles.avatar} src={quote.author.avatarUrl} alt={quote.author.name} name={quote.author.name} size={30} />
          <Avatar className={styles.avatar} src={quote.author.avatarUrl} alt={quote.author.name} name={quote.author.name} size={30} />

        </Pane>
      </Pane>
      <CardModal isShown={isShowCardModal} data={quote} setIsShown={setIsShowCardModal} />
    </Pane>
  );
}
