import React, { useState } from 'react';
import {
  Pane,
  Position,
  Button,
  FilterListIcon,
  Avatar,
  TickIcon,
  Popover,
  CrossIcon
} from "evergreen-ui";
import styles from "./FilterCard.module.scss";

type Props = {
  isShowModalFilterCard: boolean,
  setIsShowModalFilterCard: any
}

const listLabels = [
  {
    id: "1",
    name: "High priority",
    color: "#D14343",
    checked: true
  },
  {
    id: "2",
    name: "MEDIUM priority",
    color: "#9DB5FF",
    checked: false
  },
  {
    id: "3",
    name: "LOW priority",
    color: "#52BD94",
    checked: false
  },
  {
    id: "4",
    name: "info",
    color: "#FFB020",
    checked: false
  },
  {
    id: "5",
    name: "bug",
    color: "#DE7548",
    checked: false
  },
  {
    id: "6",
    name: "feature",
    color: "#897AE3",
    checked: false
  }
]

const listMembers = [
  {
    id: "1",
    name: "Dan Smith",
    checked: true
  },
  {
    id: "2",
    name: "Newton Hua",
    checked: false
  },
  {
    id: "3",
    name: "Sam Smith",
    checked: false
  },
  {
    id: "4",
    name: "Hieu tran",
    checked: false
  }
]

export default function ChangeBgModal({ isShowModalFilterCard, setIsShowModalFilterCard }: Props) {
  const [members, setMembers] = useState(listMembers)
  const [labels, setLabels] = useState(listLabels)

  const onChangeCheckedMember = (value: any, index: number) => {
    let mb = [...members]
    mb[index].checked = !value.checked
    setMembers(mb)
  }

  const onChangeCheckedLabel = (value: any, index: number) => {
    let lb = [...labels]
    lb[index].checked = !value.checked
    setLabels(lb)
  }

  return (
    <Popover
      content={({ close }) => (
        <Pane className={styles.formFilterCard}>
          <Pane className={styles.formHeader}>
            <Pane className={styles.title}>Filter Cards</Pane>
            <Pane className={styles.closeIcon}>
              <CrossIcon className={styles.icon} onClick={close} />
            </Pane>
          </Pane>
          <Pane className={styles.members}>
            <Pane className={styles.title}>
              Members
            </Pane>
            <Pane className={styles.listMember}>
              {
                members.map((val, index) => {
                  return (
                    <Pane className={styles.item} onClick={() => onChangeCheckedMember(val, index)}>
                      <Pane className={styles.itemInfo}>
                        <Avatar name={val.name} size={32} marginRight={10} />
                        <Pane className={styles.titleItem}>
                          {
                            val.name
                          }
                        </Pane>
                      </Pane>
                      {
                        val.checked
                        &&
                        <Pane className={styles.iconCheck}>
                          <TickIcon />
                        </Pane>
                      }
                    </Pane>
                  )
                })
              }
            </Pane>
          </Pane>
          <Pane className={styles.labels}>
            <Pane className={styles.title}>
              Labels
            </Pane>
            <Pane className={styles.listLabel}>
              {
                labels.map((val, index) => {
                  return (
                    <Pane backgroundColor={val.color} className={styles.item} onClick={() => onChangeCheckedLabel(val, index)}>
                      <Pane className={styles.titleItem}>
                        {val.name}
                      </Pane>
                      {
                        val.checked
                        &&
                        <Pane className={styles.iconCheck}>
                          <TickIcon />
                        </Pane>
                      }
                    </Pane>
                  )
                })
              }
            </Pane>
          </Pane>
        </Pane>
      )}
      position={Position.BOTTOM_RIGHT}
    >
      <Button marginY={8} marginRight={12} iconBefore={FilterListIcon} appearance="minimal">
        Filter cards
      </Button>
    </Popover>
  )
}
