import clsx from "clsx";
import styles from "./AddProjectMembersModal.module.scss";
import {Button, Dialog, Heading, majorScale, Pane, TextInput} from "evergreen-ui";
import Combobox from "../../components/Elements/Combobox";
import React, {useEffect, useState} from "react";
import {ProjectService} from "../../services/project/project.service";
import {useActivateTeam} from "../../services/team/team.helper";
import {useProjectInfo} from "../../services/project/projects.helper";

const projectService = new ProjectService();

type Props = {
  projectId: string,
  isShown: boolean,
  setIsShown: Function,
}

const AddProjectMembersModal = ({projectId, isShown, setIsShown}: Props) => {

  const [inviteList, setEmailList] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { activateTeams } = useActivateTeam();
  const {members, teams} = useProjectInfo(projectId);
  const [memberOrderByOwner, setMemberOrderByOwner] = useState([] as any[]);

  const options = [
    { label: 'None', value: 'none' },
    { label: 'Member', value: 'member' },
  ];

  useEffect(() => {
    const sortedMembers = Object.values(members).length > 1 ? Object.values(members).sort((first: any, second: any) => {
      return first.addedAt > second.addedAt ? 1 : -1;
    }) : Object.values(members);

    setMemberOrderByOwner(sortedMembers);
  }, [members]);

  const handleInvite = async () => {
    setLoading(true);
    let emailList = inviteList.split(',');
    emailList = emailList.map(item => item.trim().toLowerCase());

    await projectService.addMembers(projectId, emailList);
    setLoading(false);
  }

  const handleTeamOption = async (teamId: string, value: string) => {
    const isAdd = value === "member";
    await projectService.updateTeam(projectId, teamId, isAdd);
  }

  const handleMemberOption = async (memberId: string, value: string) => {
    if(value === "none"){
       await projectService.removeMembers(projectId, [memberId]);
    }
  }

  const initialMemberOption = (teamId: string) => {
    const teamIds = Object.keys(teams);
    return teamIds.includes(teamId) ? { label: 'Member', value: 'member' } : { label: 'None', value: 'none' }
  }

  const displayNameOrEmail = (member: any) => {
    const names = [member.firstName, member.lastName].filter(name => name);
    return names.length > 0 ? names.join(" ") : member.email;
  }

  return (
    <Dialog
      isShown={isShown}
      title="Invite Members"
      preventBodyScrolling
      confirmLabel="Save"
      containerProps={{
        className: clsx(styles.modal),
      }}
      contentContainerProps={{
        className: styles.content,
      }}
      hasFooter={false}
      onCloseComplete={() => setIsShown && setIsShown(false)}
    >
      <Pane display={"flex"} justifyContent={"space-between"}>
        <TextInput
          width="100%"
          required
          name="name"
          value={inviteList}
          onChange={(e: any) => setEmailList(e.target.value)}
          placeholder="Email, comma separated."
        />

        <Button
          marginLeft={majorScale(1)}
          appearance="primary"
          className={clsx(styles.inviteBtn)}
          onClick={handleInvite}
          isLoading={isLoading}
        >
          Invite
        </Button>
      </Pane>
      <hr className={styles.separate}/>
      <Pane>
        <Heading is="h4" className={clsx(styles.permissionHeading)}>
          Permissions
        </Heading>
      </Pane>
      <Pane paddingTop={majorScale(3)}>
        {activateTeams?.map((team, index) => (
          <div key={index} className={clsx(styles.sharedItem, styles.ownerItem)}>
            <div className={styles.shareTarget}>
              {team.name}
            </div>
            <div className={styles.shareAction}>
              <Combobox
                selectedItem={initialMemberOption(team.id)}
                items={options}
                itemToString={(item) => (item ? item.label : '')}
                buttonProps={{ className: styles.roleListBtn }}
                onChange={(selected: any) => handleTeamOption(team.id, selected.value)}
              />
            </div>
          </div>
        ))
        }

        {memberOrderByOwner.map((member: any, index) => (
          <div key={index} className={clsx(styles.sharedItem, styles.ownerItem)}>
            <Pane>{displayNameOrEmail(member)}</Pane>
            <div className={styles.shareAction}>
              <Combobox
                initialSelectedItem={member.isOwner ? { label: 'Owner', value: 'owner' } : { label: 'Member', value: 'member' }}
                items={options}
                itemToString={(item) => (item ? item.label : '')}
                buttonProps={{ className: styles.roleListBtn }}
                disabled={member.isOwner}
                onChange={(selected: any) => handleMemberOption(member.id, selected.value)}
              />
            </div>
          </div>
        ))}
      </Pane>
    </Dialog>
  )
}

export default AddProjectMembersModal;
