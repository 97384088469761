import React, {useEffect, useState} from "react";
import VersionsInDate from "./VersionsInDate";
import _ from "lodash"

function ListDates({histories, currentVersion, setCurrentVersion}) {
  const [historiesState, setHistory] = useState({});

  useEffect(() => {
    if(_.isEmpty(histories)) return {};

    const groups = {};
    const groupDateFormat = {
      year: 'numeric', month: 'long', day: 'numeric'
    };

    histories.forEach(history => {
      const date = new Date(history.timeCreated).toLocaleDateString('en-US', groupDateFormat);
      const group = groups[date] || [];
      group.push(history);
      groups[date] = group;
    })

    setHistory(groups);
  }, [histories])

  return(
    <div>
      {
        Object.keys(historiesState).map(group => (
          <VersionsInDate
            key={group}
            histories={_.get(historiesState, group)}
            label={group}
            setCurrentVersion={setCurrentVersion}
            currentVersion={currentVersion}
          />
        ))
      }
    </div>
  )
}

export default ListDates;
