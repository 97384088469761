import _ from "lodash"

import { mxConstants, mxUtils } from 'libs/mxgraph';
import { ZwfConstants } from "./ZwfConstants";

export const resizeSelection = (editorUi, width, height) => {
  try {
    const graphResizeSelection = editorUi.actions.get('resizeSelection').funct;
    graphResizeSelection(_.toNumber(width), _.toNumber(height));
  }
  catch (ex){
    console.log("Action resizeSelection throw", ex, editorUi, width, height);
  }
};

export const updateQuickSizeSelection = (editorUi, quickSize) => {
  const fontSizeDefinition = {};
  fontSizeDefinition[ZwfConstants.TEXT_SIZE_SMALL] = 12;
  fontSizeDefinition[ZwfConstants.TEXT_SIZE_MEDIUM] = 14;
  fontSizeDefinition[ZwfConstants.TEXT_SIZE_LARGE] = 16;

  updateSelectionStyle(editorUi, ZwfConstants.SIZE_SELECTION, quickSize);
  updateSelectionStyle(editorUi, "fontSize", fontSizeDefinition[quickSize]);
};
export const updatePosition = (editorUi, position) => {
  updateSelectionStyle(editorUi, ZwfConstants.POSITION_STATE, position);
};

export const resizeHeadingSelection = (editorUi, fontSize) => {
  updateSelectionStyle(editorUi,'fontSize',fontSize)
};

export const changeStrokeWidth = (editorUi, strokeWidth) => {
  updateSelectionStyle(editorUi,'strokeWidth', strokeWidth)
};

export const setCellLink = (editorUi, link) => {
  const graph = editorUi.current.editor.graph;
  graph.setLinkForCell(graph.getSelectionCell(), link)
};

export const changePositionAlignSelection = (editorUi, alignment) => {
  updateSelectionStyle(editorUi,'align', alignment)
};

export const changePositionVerticalAlignSelection = (editorUi, alignment) => {
  updateSelectionStyle(editorUi,'verticalAlign', alignment)
};

export const updateStyles = (editorUi, styles) => {
  Object.keys(styles).forEach(style => {
    updateSelectionStyle(editorUi,style, _.get(styles, style))
  });
};

export const changeFontFormatTextSelection = (editorUi,format) => {
  try {
    const graphChangeFontBold = editorUi.actions.get(format).funct;
    graphChangeFontBold(editorUi);
  }
  catch (ex){
    console.log("Action changeFontBoldSelection throw", ex, editorUi);
  }
}

export const updateSelectionStyle = (editorUi, style, value, force) => {
    try {
      const graph = editorUi.editor.graph;
      graph.updateCellStyles(style, value, graph.getSelectionCells(), force);
    }
    catch (ex){
      console.log("Action updateSelection throw", ex, style, value);
    }
}

export const updateSelectionColor = (editorUi, color, strokeColor) => {
  updateSelectionStyle(editorUi, "fillColor", color, true);
  updateSelectionStyle(editorUi, "strokeColor", strokeColor, true);
}

export const updateSelectionTextColor = (editorUi, color) => {
  updateSelectionStyle(editorUi, "fontColor", color);
}

export const updateSelectionStrokeColor = (editorUi, color) => {
  updateSelectionStyle(editorUi, "strokeColor", color);
}

export const updateLineLabelBackground = (editorUi, color) => {
  const graph = editorUi.editor.graph;
  const cell = graph.getSelectionCell();
  const isLabelOnEdge = (cell.parent || {edge: false}).edge;
  if(isLabelOnEdge){
    updateSelectionStyle(editorUi, "labelBackgroundColor", "#F3F6FA", true);
  }
}

export const activeKeyboard = (editorUi, status) => {
  updateSelectionStyle(editorUi, ZwfConstants.STYLE_KEYBOARD_STATE, status);
}

export const changeKeyboardType = (editorUi, type) => {
  updateSelectionStyle(editorUi, ZwfConstants.STYLE_KEYBOARD_TYPE, type);
}

export const changeOrientation = ({editorUi, orientation, graphState, dispatchGraphState}) => {
  updateSelectionStyle(editorUi, ZwfConstants.STYLE_FRAME_ORIENTATION, orientation);
  const isPortrait = orientation === ZwfConstants.FRAME_ORIENTATION_PORTRAIT;
  const defaultSize = _.get(graphState, isPortrait ? ZwfConstants.PORTRAIT_SIZE : ZwfConstants.LANDSCAPE_SIZE);
  if(defaultSize){
    const size = defaultSize.split("x");
    const width = _.get(size, 0);
    const height = _.get(size, 1);

    resizeSelection(editorUi, width, height);
    dispatchGraphState({size: {width, height}});
  }
  console.log("changeOrientation", defaultSize);
}

export const changeToggle = (editorUi, toggle) => {
  updateSelectionStyle(editorUi, ZwfConstants.TOGGLE_STATE, toggle);
}

export const updateButtonSize = ({ editor, dispatchGraphState, graph, val }) => {
  const cell = graph.getSelectionCell();
  const style = graph.getCellStyle(cell);
  const hasIcon = mxUtils.getValue(style, mxConstants.STYLE_IMAGE, null);
  const fillColor = mxUtils.getValue(style, mxConstants.STYLE_FILLCOLOR, null);
  const brightColors = ['#FFFFFF', '#D8DAE5', '#EDEFF5'];
  if (val === ZwfConstants.TEXT_SIZE_SMALL) {
    // Small: 12px font size, 10px left-right padding, 24px button height. Icon 12px
    graph.setCellStyles(mxConstants.STYLE_FONTSIZE, 12, [cell]);
    graph.setCellStyles(ZwfConstants.STYLE_TEXT_SIZE, ZwfConstants.TEXT_SIZE_SMALL, [cell]);
    if (hasIcon) {
      graph.setCellStyles(mxConstants.STYLE_IMAGE_SPACING_RIGHT, 26, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_WIDTH, 12, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_HEIGHT, 12, [cell]);
      dispatchGraphState({ quickSize: { [ZwfConstants.TEXT_SIZE_SMALL]: '75x24' }, size: { width: 75, height: 24 } });
      editor.actions.get('resizeSelection').funct(75, 24);
    } else {
      dispatchGraphState({ quickSize: { [ZwfConstants.TEXT_SIZE_SMALL]: '59x24' }, size: { width: 59, height: 24 } });
      editor.actions.get('resizeSelection').funct(59, 24);
    }
  } else if (val === ZwfConstants.TEXT_SIZE_MEDIUM) {
    // Medium: 14px font size, 20px left-right padding, 32px button height. Icon 14px.
    graph.setCellStyles(mxConstants.STYLE_FONTSIZE, 14, [cell]);
    graph.setCellStyles(ZwfConstants.STYLE_TEXT_SIZE, ZwfConstants.TEXT_SIZE_MEDIUM, [cell]);
    if (hasIcon) {
      graph.setCellStyles(mxConstants.STYLE_SPACING_LEFT, 8, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_SPACING_RIGHT, 32, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_WIDTH, 14, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_HEIGHT, 14, [cell]);
      dispatchGraphState({ quickSize: { [ZwfConstants.TEXT_SIZE_MEDIUM]: '99x32' }, size: { width: 99, height: 32 } });
      editor.actions.get('resizeSelection').funct(99, 32);
    } else {
      dispatchGraphState({ quickSize: { [ZwfConstants.TEXT_SIZE_MEDIUM]: '85x32' }, size: { width: 85, height: 32 } });
      editor.actions.get('resizeSelection').funct(85, 32);
    }
  } else if (val === ZwfConstants.TEXT_SIZE_LARGE) {
    // Large: 16px font size, 20px left-right padding, 40px button height. Icon 16px
    graph.setCellStyles(mxConstants.STYLE_FONTSIZE, 16, [cell]);
    graph.setCellStyles(ZwfConstants.STYLE_TEXT_SIZE, ZwfConstants.TEXT_SIZE_LARGE, [cell]);
    if (hasIcon) {
      graph.setCellStyles(mxConstants.STYLE_IMAGE_SPACING_RIGHT, 40, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_WIDTH, 16, [cell]);
      graph.setCellStyles(mxConstants.STYLE_IMAGE_HEIGHT, 16, [cell]);
      dispatchGraphState({ quickSize: { [ZwfConstants.TEXT_SIZE_LARGE]: '115x40' }, size: { width: 115, height: 40 } });
      editor.actions.get('resizeSelection').funct(115, 40);
    } else {
      dispatchGraphState({ quickSize: { [ZwfConstants.TEXT_SIZE_LARGE]: '91x40' }, size: { width: 91, height: 40 } });
      editor.actions.get('resizeSelection').funct(91, 40);
    }
  }
  console.log(fillColor);
  if (hasIcon) {
    if (brightColors.includes(fillColor)) {
      graph.setCellStyles(mxConstants.STYLE_IMAGE, hasIcon.replace('/icons', '/white-icons'));
    } else {
      graph.setCellStyles(mxConstants.STYLE_IMAGE, hasIcon.replace('/white-icons', '/icons'));
    }
  } else {
    graph.setCellStyles(mxConstants.STYLE_SPACING_LEFT, 0, [cell]);
    graph.setCellStyles(mxConstants.STYLE_SPACING, 0, [cell]);
  }
}

export const updateCircleButtonSize = ({ editor, val }) => {
  const bounds = { width: 40, height: 40 };
  if (val === ZwfConstants.TEXT_SIZE_MEDIUM) {
    bounds.width = 48;
    bounds.height = 48;
  } else if (val === ZwfConstants.TEXT_SIZE_LARGE) {
    bounds.width = 64;
    bounds.height = 64;
  }
  editor.actions.get('resizeSelection').funct(bounds.width, bounds.height);
}

export const updateAnnotation = ({ editorUi, position, graphState, dispatchGraphState}) => {
  try {
    const graph = editorUi.editor.graph;
    const cell = graph.getSelectionCell();
    const style = graph.getCellStyle(cell);
    const type = _.get(style, ZwfConstants.STYLE_TYPE);

    if (type !== ZwfConstants.TYPE_ANNOTATION) return ;

    const overrideStyles = {};
    overrideStyles[ZwfConstants.POSITION_RIGHT] = {
      align: "left",
      direction: "east",
      labelPosition: "right",
      verticalLabelPosition: "",
      verticalAlign: "",
    };
    overrideStyles[ZwfConstants.POSITION_LEFT] = {
      align: "right",
      direction: "west",
      labelPosition: "left",
      verticalLabelPosition: "",
      verticalAlign: "",
    };
    overrideStyles[ZwfConstants.POSITION_TOP] = {
      align: "center",
      direction: "north",
      labelPosition: "center",
      verticalLabelPosition: "top",
      verticalAlign: "bottom",
    };
    overrideStyles[ZwfConstants.POSITION_BOTTOM] = {
      align: "center",
      direction: "south",
      labelPosition: "center",
      verticalLabelPosition: "bottom",
      verticalAlign: "top",
    };

    const we = [ZwfConstants.POSITION_TOP, ZwfConstants.POSITION_BOTTOM];
    const sn = [ZwfConstants.POSITION_LEFT, ZwfConstants.POSITION_RIGHT];
    const pos = [_.get(graphState, ZwfConstants.POSITION_STATE), position];

    const needRotate = pos.some(p => we.includes(p)) && pos.some(p => sn.includes(p));
    if (needRotate) {
      _.get(graphState, ZwfConstants.POSITION_STATE);
      const width = _.get(graphState, "size.width", 1);
      const height = _.get(graphState, "size.height", 1);
      dispatchGraphState({size: {width: height, height: width}});
      resizeSelection(editorUi, height, width);
    }

    updateStyles(editorUi, _.get(overrideStyles, position, {}));
  }
  catch (ex) {
    console.log("updateAnnotation throw ex");
  }
}
