import { useState } from 'react';
import { auth } from 'utils/firebase';
import { SIGN_IN_URL } from 'utils/constants';
import { deleteUser, getUser } from 'utils/store';
import { toaster } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
import { Modal } from '../Modal/Modal';
import { UserService } from '../../../services/user';

interface Props {
  isShown: boolean;
  setIsShown: Function;
}

export default function DeleteAccountModal({ isShown, setIsShown }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const userService = new UserService();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      const user = getUser();
      const { uid } = user;
      await userService.deleteUser(uid);
      await auth.currentUser?.delete();
      await auth.signOut();
      deleteUser();
      history.push(SIGN_IN_URL);
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
      setIsShown(false);
    }
  };

  return (
    <Modal
      width={400}
      isShown={isShown}
      title="Delete My Account"
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Delete"
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
    >
      Are you sure to delete your account? All your <br/>
      documents and data will be permanently removed.
    </Modal>
  );
}
