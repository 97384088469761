import { useState } from 'react';
import { Button, InlineAlert, IntentTypes, TextInput } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auth } from 'utils/firebase';
import { getUser } from 'utils/store';
import styles from '../UserSettings/UserSettings.module.scss';

const ChangePasswordForm = () => {
  const [intent, setIntent] = useState<IntentTypes>('success');
  const [message, setMessage] = useState('');
  const {
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password required'),
      newPassword: Yup.string().required('New Password required'),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'New Password must match')
    }),
    onSubmit: async ({ password, newPassword }) => {
      try {
        const user = getUser() || {};
        await auth.signInWithEmailAndPassword(user.email, password);
        await auth.currentUser?.updatePassword(newPassword);
        setIntent('success');
        setMessage('Password changed.');
      } catch (e: any) {
        setIntent('danger');
        setMessage(e.message)
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <TextInput
        type="password"
        className="mt-3"
        placeholder="Current Password"
        isInvalid={!!(touched.password && errors.password)}
        {...getFieldProps('password')}
      />
      <div className={styles.formGroup}>
        <TextInput
          type="password"
          className="mr-3"
          placeholder="New Password"
          isInvalid={!!(touched.newPassword && errors.newPassword)}
          {...getFieldProps('newPassword')}
        />
        <TextInput
          type="password"
          placeholder="Confirm New Password"
          isInvalid={!!(touched.confirmNewPassword && errors.confirmNewPassword)}
          {...getFieldProps('confirmNewPassword')}
        />
      </div>
      <div className="d-flex mt-4">
        <Button
          appearance="primary"
          type="submit"
          className="mr-4"
          isLoading={isSubmitting}
        >
          Save New Password
        </Button>
        {message && (
          <InlineAlert intent={intent}>
            {message}
          </InlineAlert>
        )}
      </div>
    </form>
  )
};

export default ChangePasswordForm;
