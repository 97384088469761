import { useState } from 'react';
import { toaster } from 'evergreen-ui';
import { NoteService } from 'services/note';
import { Dialog } from 'components/Dialog';
import { useHistory } from 'react-router-dom';

import useStores from 'hooks/useStores';
import { NOTE_TRASH_URL } from 'utils/constants';

interface Props {
  isShown: boolean;
  setIsShown: Function;
}

const noteService = new NoteService();

const DeleteNoteModal = ({ isShown, setIsShown }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { noteStore } = useStores();

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await noteService.deleteOne(noteStore.note?.id);
      setIsShown(false);
      history.push(NOTE_TRASH_URL)
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      width={400}
      isShown={isShown}
      title={`Delete Note`}
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Delete"
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
    >
      Are you sure to delete this note? This action cannot be undone.
    </Dialog>
  );
}

export default DeleteNoteModal;
