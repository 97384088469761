export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortField {
  Name = 'name',
  Type = 'type',
  SharedBy = 'sharedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Title = 'title',
}
