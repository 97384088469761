import { useState } from 'react';
import { Pane, SearchIcon, CrossIcon, TextInput } from 'evergreen-ui';
import styles from './NumberInput.module.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  onChange?: any;
  onClear?: any;
  [props:string]: any;
}

const SearchInput = ({ className, onChange, onClear, ...props } : Props) => {
  const [input, setInput] = useState('');

  const handleChange = (event: any) => {
    setInput(event.target.value);
    onChange(event);
  }

  const handleClear = () => {
    setInput('');
    onClear();
  }

  return (
    <Pane
      width="100%"
      height={32}
      position="relative"
      display="inline-flex"
      className={clsx(
        className && className
      )}
    >
      <Pane
        width={32}
        height={32}
        pointerEvents="none"
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SearchIcon zIndex={3} size={12} color="#8F95B2"/>
      </Pane>
      <TextInput
        className={styles.input}
        onChange={handleChange}
        value={input}
        {...props}
      />
      <Pane
        width={28}
        height={32}
        right={0}
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        cursor="pointer"
        onClick={handleClear}
      >
        <CrossIcon size={12} zIndex={3} color="#8F95B2" />
      </Pane>
    </Pane>
  )
};

export default SearchInput;
