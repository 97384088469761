/* eslint-disable */
import {ColorAssigner} from "libs/color-assigner";
import {
  Activity,
  ActivitySessionJoinedEvent,
  ActivitySessionLeftEvent,
  IConvergenceEvent
} from "@convergence/convergence";
import {filter} from "rxjs/operators";

import { ONLINE_COLORS, BG_ONLINE_COLORS } from 'utils/constants';

export class ActivityColorManager {

  private readonly _colorAssigner: ColorAssigner;

  constructor(activity: Activity, palette?: string[]) {
    const COLORS = ONLINE_COLORS;
    this._colorAssigner = new ColorAssigner(COLORS);

    // @ts-nocheck
    activity.events()
      .pipe(filter((e: IConvergenceEvent) => e.name === "session_joined"))
      .subscribe((e: any) => {
        this._addSession(e.sessionId);
      });

    activity.events()
      .pipe(filter((e: IConvergenceEvent) => e.name === "session_left"))
      .subscribe((e: any) => {
        this._removeSession(e.sessionId);
      });
  }

  public color(sessionId: string): string {
    return this._colorAssigner.getColorAsHex(sessionId);
  }

  public getBackgroundColor(sessionId: string): string {
    const color = this._colorAssigner.getColorAsHex(sessionId);
    if (BG_ONLINE_COLORS[color]) {
      return this.hexToRGB(BG_ONLINE_COLORS[color]);
    } else {
      return this.hexToRGB(color, 0.2);
    }
  }

  public hexToRGB(hex: string, alpha?: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
  }

  private _addSession(sessionId: string): void {
    this._colorAssigner.getColor(sessionId);
  }

  private _removeSession(sessionId: string): void {
    try {
      this._colorAssigner.releaseColor(sessionId);
    } catch (e: any) {
      // console.log('Error when releaseColor: ', e);
    }
  }
}
