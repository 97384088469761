import { useState } from 'react';
import { Button, InlineAlert, IntentTypes, TextInput } from 'evergreen-ui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ResetService, ResetType } from 'services/reset';
import styles from '../UserSettings/UserSettings.module.scss';

const resetService = new ResetService();

const ChangeEmailForm = () => {
  const [intent, setIntent] = useState<IntentTypes>('success');
  const [message, setMessage] = useState('');
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email('Invalid email address')
        .required('Email address required'),
    }),
    onSubmit: async ({ email }) => {
      try {
        await resetService.sendResetEmail(ResetType.ResetEmail, email);
        setIntent('success');
        setMessage('Email sent. Please check your inbox.');
      } catch (e: any) {
        setIntent('danger');
        setMessage(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <TextInput
        type="text"
        className="mt-3"
        placeholder="New Email Address"
        isInvalid={!!(touched.email && errors.email)}
        {...getFieldProps('email')}
      />
      <div className="d-flex mt-4">
        <Button
          appearance="primary"
          type="submit"
          className="mr-4"
          isLoading={isSubmitting}
        >
          Send Confirmation Email
        </Button>
        {message && (
          <InlineAlert intent={intent}>
            {message}
          </InlineAlert>
        )}
      </div>
    </form>
  )
};

export default ChangeEmailForm;
