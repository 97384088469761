import { Pane } from 'evergreen-ui';
import clsx from 'clsx';
import styles from './ColorPalette.module.scss';

export const ColorPalette = ({ color, onClick, isActive }) => (
  <Pane
    className={clsx(
      isActive && styles.paletteActive,
      styles.palette,
      color === '#FFFFFF' && styles.paletteBorder
    )}
    style={{ background: color }}
    onClick={() => onClick(color)}
  />
)

ColorPalette.defaultProps = {
  isActive: false,
  onClick: () => {},
}
