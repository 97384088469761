import { useCallback, useEffect, useReducer, useState } from 'react';
import { fuego, useCollection } from '@nandorojo/swr-firestore';
import _ from 'lodash';

import { NOTEBOOK_COLLECTION, USER_COLLECTION } from 'utils/constants';
import { getUser } from 'utils/store';
import { ShareUserSchema } from 'schemas/share';
import { db } from 'utils/firebase';
import { NotebookSchema } from 'schemas/notebook';

/**
 * Use note detail
 *
 * @returns
 */
export const useNotebookDetail = (notebookId: string) => {
  const [notebook, setNotebook] = useState({} as NotebookSchema);
  const [isLoading, setIsLoading] = useState(true);
  let unsubscribe: any = false;

  useEffect(() => {
    if (notebookId) {
      const ref = fuego.db.doc(`${NOTEBOOK_COLLECTION}/${notebookId}`);
      let query = ref;

      unsubscribe = query.onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (doc: any) => {
          const docData = doc.data();
          setNotebook({
            id: doc.id,
            ...docData,
          });
          setIsLoading(false);
        },
        (error: any) => {
          setIsLoading(false);
        },
      );

      return () => unsubscribe && unsubscribe();
    }
  }, [notebookId]);

  return {
    notebook,
    isLoading,
    setNotebook,
  };
};

/**
 * Use note collection
 *
 * @returns
 */
export const useNotebookCollection = () => {
  const ref = fuego.db.collection(NOTEBOOK_COLLECTION);
  const [notebooks, setNotebooks] = useState([] as NotebookSchema[]);
  const [isLoading, setIsLoading] = useState(true);
  const user = getUser();
  let unsubscribe: any = false;

  useEffect(() => {
    if (user) {
      const { uid } = user;
      let query: any;

      setIsLoading(true);
      query = ref.where(`user`, '==', uid).where('isDeleted', '==', false);
      unsubscribe = query.onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (querySnapshot: any) => {
          const docs: any[] = [];
          querySnapshot.forEach((change: any) => {
            docs.push({
              id: change.id,
              ...change.data(),
            });
          });
          setIsLoading(false);
          setNotebooks(docs);
        },
      );
    }
  }, [user?.uid]);

  return {
    notebooks,
    isLoading,
    setNotebooks,
    unsubscribe,
  };
};

/**
 * Use share notebook
 *
 * @returns
 */
export const useShareNotebook = () => {
  const [document, setDocuments] = useState({} as NotebookSchema);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([] as any[]);
  const [teamRoles, setTeamRoles] = useState([] as any[]);
  const [invites, setInvites] = useState([] as any[]);
  const [shareUsers, setShareUsers] = useState([] as ShareUserSchema[]);
  const [shareTeams, setShareTeams] = useState([] as any[]);
  const user = getUser();
  let unsubscribe: any = false;

  useEffect(() => {
    const getShareDetail = async () => {
      const resShare: ShareUserSchema[] = [];
      const userRef = fuego.db.collection(USER_COLLECTION);
      if (roles) {
        for (const [uid, role] of Object.entries(roles)) {
          if (uid !== 'anyone') {
            const user = await userRef.doc(uid).get();
            resShare.push({
              id: user.id,
              ...user.data(),
              role,
            });
          }
        }
      }

      if (invites) {
        for (const [uid, invite] of Object.entries(invites)) {
          if (uid !== 'anyone') {
            resShare.push({
              id: user.id,
              email: invite.inviteEmail,
              role: invite,
            });
          }
        }
      }

      const teamRolesMap = Object.keys(teamRoles || {}).map(teamId => ({
        role: _.get(teamRoles, teamId, {}).type,
        id: teamId,
      }));

      setShareUsers(resShare);
      setShareTeams(teamRolesMap);
    };
    getShareDetail();
  }, [roles, invites, user?.uid, teamRoles]);

  const getShareDocument = useCallback(
    async (docId: string) => {
      const ref = fuego.db.collection(NOTEBOOK_COLLECTION);
      if (!user) {
        return false;
      }
      let query = ref.doc(docId);

      unsubscribe = query.onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (doc: any) => {
          setIsLoading(false);
          const resDoc = {
            id: doc.id,
            ...doc.data(),
          };
          const roles = resDoc.roles;
          const invites = resDoc.invites;
          const teamRoles = resDoc.teamRoles;
          setDocuments(resDoc);
          setRoles(roles);
          setInvites(invites);
          setTeamRoles(teamRoles);
        },
      );
      return unsubscribe;
    },
    [user?.uid],
  );

  return {
    document,
    shareUsers,
    roles,
    isLoading,
    getShareDocument,
    shareTeams,
    unsubscribe,
  };
};

/**
 * Use share list notebook
 *
 * @returns
 */
export const useShareNotebooks = (
  activateTeams: any[],
  defaultRoles?: string[],
) => {
  const roles = defaultRoles ? defaultRoles : ['editor', 'viewer'];
  const [shareDocs, setShareDocs] = useState([] as NotebookSchema[]);
  const user = getUser() || { uid: '' };
  const [teamShareDocsMap, dispatchTeamShareDocsMap] = useReducer(
    (state: any, teamShareDocs: any) => {
      return { ...state, ...teamShareDocs };
    },
    {},
  );

  const shareToUsersSubQuery = [
    [`roles.${user.uid}.type`, 'in', roles],
    ['isDeleted', '==', false],
  ] as any[];

  const { data: rawDocuments } = useCollection<NotebookSchema>(
    NOTEBOOK_COLLECTION,
    {
      where: [...shareToUsersSubQuery],
      listen: true,
    },
  );

  useEffect(() => {
    if ((activateTeams || []).length <= 0) return;
    
    activateTeams.forEach(team => {
      db.collection(NOTEBOOK_COLLECTION)
        .where(`teamRoles.${team.id}.type`, 'in', roles)
        .where('isDeleted', '==', false)
        .onSnapshot(resp => {
          dispatchTeamShareDocsMap({
            [team.id]: resp.docs.map(doc => ({ ...doc.data(), id: doc.id })),
          });
        });
    });
  }, [activateTeams?.length]);

  useEffect(() => {
    let totalShareDocs = [] as any[];
    Object.values(teamShareDocsMap).forEach((docs: any) => {
      const removeDuplicateDocs = (docs || [])
        .filter((doc: any) => _.get(doc.roles, user.uid) !== 'owner')
        .filter(
          (doc: any) =>
            !totalShareDocs.find(shareDoc => shareDoc.id === doc.id),
        );
      totalShareDocs = [...totalShareDocs, ...removeDuplicateDocs];
    });

    setShareDocs(totalShareDocs);
  }, [teamShareDocsMap]);

  useEffect(() => {
    dispatchTeamShareDocsMap({
      none_team: rawDocuments as NotebookSchema[],
    });
  }, [rawDocuments]);

  return {
    notebooks: shareDocs,
    setNotebooks: setShareDocs,
  };
};
