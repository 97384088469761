/* eslint-disable no-undef */
import './GraphEditor.css';
import './utils/ZwfConstants';
import { useLayoutEffect, useReducer, useRef, useState } from 'react';
import clsx from 'clsx';
import useLoad from './hooks/useLoad';
import Toolbar from './modules/Toolbar/Toolbar';
import Menubar from './modules/Menubar/Menubar';
import { PopupMenuRender } from './modules/PopupMenu/RenderPopupMenu';
import Sidebar from './modules/Sidebar/Sidebar';
import { Spinner } from '../Spinner/Spinner';
import styles from './EditorApp.module.scss';
import { Format } from './modules/Format/Format';

import { mxUtils } from 'libs/mxgraph';
import useStores from 'hooks/useStores';
import {Pane} from "evergreen-ui";

function EditorApp(props) {
	const graphContainer = useRef();
	const editorUi = useRef();
	const isMxScriptReady = useLoad();
	const [isEditorInitReady, setEditorInitReady] = useState(false);
	const [panelState, dispatchPanelState] = useReducer((state, panel) => {
    return {...state, ...panel};
  }, {optionPanel: true, sidePanel: true});
  const { graphStore } = useStores();

	const handleClick = (sender, mxEventObj) => {
		console.log(sender, mxEventObj)
	}

	useLayoutEffect(() => {
		if (isMxScriptReady) {
			var editorUiInit = EditorUi.prototype.init;

			EditorUi.prototype.init = function () {
				editorUiInit.apply(this, arguments);
				this.actions.get("export").setEnabled(false);

				// Updates action states which require a backend
				if (!Editor.useLocalStorage) {
					mxUtils.post(
						OPEN_URL,
						"",
						mxUtils.bind(this, function (req) {
							var enabled = req.getStatus() !== 404;
							this.actions.get("open").setEnabled(enabled || Graph.fileSupport);
							this.actions.get("import").setEnabled(enabled || Graph.fileSupport);
							this.actions.get("save").setEnabled(enabled);
							this.actions.get("saveAs").setEnabled(enabled);
							this.actions.get("export").setEnabled(enabled);
						})
					);
				}
			};

			mxResources.loadDefaultBundle = false;
			var bundle =
				mxResources.getDefaultBundle(RESOURCE_BASE, mxLanguage) ||
				mxResources.getSpecialBundle(RESOURCE_BASE, mxLanguage);

			mxUtils.getAll(
				[bundle, STYLE_PATH + "/default.xml"],
				function (xhr) {
					mxResources.parse(xhr[0].getText());

					var themes = {};
					themes[Graph.prototype.defaultThemeName] = xhr[1].getDocumentElement();

					editorUi.current = new EditorUi(
						new Editor(urlParams["chrome"] === "0", themes),
						graphContainer.current
					);
          graphStore.setEditorUi(editorUi.current);

					const editor = editorUi.current.editor;
					const graph = editor.graph

					/**
					 * Event Mapping
					 */
          graph.addListener(mxEvent.CLICK, function (graph, mxEventObject) {
            if(mxPopupMenu.prototype.checkShowPopupMenu) {
              PopupMenuRender(editorUi, graphStore.document);
              mxPopupMenu.prototype.checkShowPopupMenu = false;
            }
						handleClick(graph, mxEventObject);
					});
          setEditorInitReady(true);
          console.log(editorUi, editorUi.current.editor);
				},
				function () {
					document.body.innerHTML =
						'<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
				}
			);

			return () => {
				editorUi.current.destroy();
			};
		}
	}, [isMxScriptReady, graphStore]);

	if (!isEditorInitReady) {
	  return <Spinner />;
  }

	return (
		<div className={styles.container}>
      <div className={styles.menubarContainer}>
        <Menubar editorUi={editorUi}/>
      </div>

      <Pane className="zwfToolbar">
        <Toolbar
          editorUi={editorUi}
          dispatchPanelState={dispatchPanelState}
          panelState={panelState}
        />
      </Pane>

      <div className={clsx(
        'zwfSidebarContainer',
        styles.sidebarContainer
      )}>
        <Sidebar
          editorUi={editorUi.current}
          dispatchPanelState={dispatchPanelState}
        />
      </div>

      <div
        className={clsx(
          'zwfFormatContainer',
          styles.formatContainer
        )}
      >
        <Format
          editorUi={editorUi.current}
          dispatchPanelState={dispatchPanelState}
        />
      </div>

			<div ref={graphContainer}/>
		</div>
	);
}

export default EditorApp;
