import { Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  Pane,
  TextInput,
  Button,
  Combobox
} from 'evergreen-ui';
import { Col, Row } from 'react-grid-system';

import styles from './Invite.module.scss';

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>
}

const InviteModal = ({ isShown, setIsShown }: Props) => {


  return (
    <>
      <Dialog
        width={528}
        isShown={isShown}
        hasFooter={false}
        title="Invite Members"
        onCloseComplete={() => setIsShown(false)}
        containerProps={{ className: styles.modal }}
        contentContainerProps={{ className: styles.modalContent }}
      >
        <Row>
          <Col className="pr-0">
            <TextInput name="text-input-name" placeholder="Emails, comma separated...." className={styles.inputInvite} />
          </Col>
          <Col md={3}>
            <Button className={styles.btnInvite} appearance="primary">
              Intive
            </Button>
          </Col>
        </Row>
        <hr className={styles.hr} />
        <Pane className={styles.formPermiss}>
          <Pane className={styles.title}>
            Permissions
          </Pane>
          <Pane marginTop={22} marginBottom={22}>
            <Row className="mb-2">
              <Col className={styles.titlePer}>
                Members of Company XYZ
              </Col>
              <Col md={4}>
                <Combobox
                  className={styles.btnInvite}
                  items={['Member', 'Remove']}
                  placeholder="Type"
                  openOnFocus={false}
                  initialSelectedItem={'Member'}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className={styles.titlePer}>
                Nick Hoang
              </Col>
              <Col md={4}>
                <Combobox
                  className={styles.btnInvite}
                  items={['Owner']}
                  placeholder="Type"
                  openOnFocus={false}
                  disabled
                  initialSelectedItem={'Owner'}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col className={styles.titlePer}>
                John Williams
              </Col>
              <Col md={4}>
                <Combobox
                  className={styles.btnInvite}
                  items={['Member', 'Remove']}
                  placeholder="Type"
                  openOnFocus={false}
                  initialSelectedItem={'Member'}
                />
              </Col>
            </Row>
          </Pane>
        </Pane>
      </Dialog>
    </>
  );
}

export default InviteModal;
