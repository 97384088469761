export const ActionState = {
  delete: {},
  copy: {},
  cut: {},
  pasteHere: {},
  group: {},
  ungroup:{},
  lockUnlock:{},
  toFront:{},
  toBack:{},
  toFrontForward: {},
  toBackForward: {},
  insertRow: {},
  removeRow: {},
  insertColumn: {},
  deleteColumn: {},
  deleteRow: {},
}
