import { useContext, useEffect, useState } from 'react';
import {HistoryService} from "../../../services/history"
import {useLocation} from "react-router";
import _ from "lodash";
import {HISTORY_CAPTURE_CONTENT_TYPE, HISTORY_EXPORT_INTERVAL} from "../../../utils/constants";
import axios from "axios";
import { getUser } from "utils/store";
import { EditorContext } from '../../../context/EditorContext';

function useHistoryVersion({
  editorUi = null,
  histories,
  setHistories
}){
  const [latestXml, setLatestXml] = useState("");
  const [exportVersion, setExportVersion] = useState({});
  const { documentNode, document, designer, mindplot, core } = useContext(EditorContext)
  const location = useLocation();
  const historyService = new HistoryService();
  const EDIT_PAGE_ROUTE = "/docs/";

  useEffect(() => {
    if (editorUi || designer) {
      setInterval(() => exportHistory(), HISTORY_EXPORT_INTERVAL);
    }
  }, [editorUi, designer]);

  const exportHistory = () => {
    if (editorUi) {
      getAction("captureEditor")();
    } else {
      const documentContent = documentNode.querySelector('svg').cloneNode(true);
      mindplot.util.Export.toPNG(document.name, documentContent, false, function(rawImage) {
        const mindmap = designer.getMindmap();
        const serializer = mindplot.persistence.XMLSerializerFactory.getSerializerFromMindmap(mindmap);
        const domMap = serializer.toXML(mindmap);
        const profile = core.Utils.innerXML(domMap);
        const image = rawImage.replace("data:image/png;base64,", '');
        setExportVersion({ profile, image });
        setTimeout(() => fetchHistory(), 30000);
      })
    }
  }

  useEffect(() => {
    const lastedHistory = _.get(histories, 0, {metadata: {}});
    const xmlUrl = (lastedHistory || { mediaLink: '' }).mediaLink;

    if (xmlUrl) {
      const { data } = axios.get(xmlUrl);
      setLatestXml(data);
    }

  }, [histories]);

  useEffect(async () => {
    const isInvalidProfile = _.isEmpty(exportVersion.profile);
    const isNotChange = exportVersion.profile === latestXml;
    if (isInvalidProfile || isNotChange) return;

    try {
      const isNoComponentRendered = () => {
        if (editorUi) {
          const graph = editorUi.current.editor.graph;
          const graphRootParent = graph.getDefaultParent();
          return graphRootParent?.getChildCount() <= 0;
        }
        return false;
      }

      if (isNoComponentRendered()) {
        console.log("isNoComponentRendered trigger to skip upload");
        return;
      }

      console.log("history uploading");

      setLatestXml(exportVersion.profile);

      await historyService.upload({
        contentType: HISTORY_CAPTURE_CONTENT_TYPE,
        documentId: documentId(),
        chart: exportVersion.profile,
        screenshot: exportVersion.image
      });
    } catch (ex) {
      console.log("upload history version failed throw", ex);
    }
  }, [exportVersion]);

  useEffect(() => {
    fetchHistory();
    if (editorUi) {
      const graph = editorUi.current.editor.graph;
      graph.addListener("onExportCanvasSuccess", onExportCanvasSuccess);
    }
  }, []);

  const onExportCanvasSuccess = async (evt, param) => {
    const profile = (param.properties || [])["xml"] || "";
    const image = (param.properties || [])["capture"];
    setExportVersion({ profile, image });
    setTimeout(() => fetchHistory(), 30000); // fetch after 30s
  }

  const getAction = (key) => {
    try {
      const actions = editorUi.current.actions;
      return actions.get(key).funct || function (){};
    } catch (ex) {
      console.log("Menu bar getAction throw ex", ex);
    }
  }

  const documentId = () => {
    return location.pathname.substring(location.pathname.indexOf(EDIT_PAGE_ROUTE) + EDIT_PAGE_ROUTE.length);
  }

  const fetchHistory = () => {
    const user = getUser();
    if (user) {
      historyService.list({documentId: documentId()})
      .then((response) => {
        const fileVersions = (response.data || {}).files || [];

        console.log("fileVersions", fileVersions);

        // No need to sort
        if (fileVersions.length < 2) {
          setHistories(fileVersions);
          return ;
        }
        const historiesSorted = fileVersions
          .sort((first, second) => first.timeCreated > second.timeCreated ? -1 : 1);

        setHistories(historiesSorted);
      });
    }
  }
  return true;
}

export default useHistoryVersion;
