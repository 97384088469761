import { defaultTheme } from 'evergreen-ui'


const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    Table: {
      baseStyle: {

      }
    },
    TableCell: {
      baseStyle: {
        // fontSize: '1rem',
      }
    },
    Link: {
      baseStyle: {
        color: 'unset',
        textDecoration: 'none',
        fontSize: 'inherit',
      }
    }
  },
}

export default theme;
