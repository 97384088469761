import {Button, Heading, majorScale, Pane, Paragraph, Tab, Tablist} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import UserSettings from "./UserSettings/UserSettings";
import styles from "./UserSettings/UserSettings.module.scss";
import {Col, Container, Row} from "react-grid-system";
import {DeleteAccountModal} from "../../components/Modals";
import {getUser} from "../../utils/store";
import Team from "./Team/Team";
import Subscription from "./Subscription/Subscription";

const SettingPage = () => {
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs, setTabs] = useState(["User Settings", "Subscription & Billing"]);
  const user = getUser() || {};

  useEffect(() => {
    const tabsByRole = [
      "User Settings",
      user.isAdminTeam === true ? "Team" : null,
      user.subscriptionPeriodUntil ? "Subscription & Billing" : null
    ].filter(tab => tab) as string[];
    setTabs(tabsByRole);
  }, [])

  return (
    <Pane>
      <Container fluid className={styles.container}>
        <Row>
          <Col>
            <Heading size={700}>Settings</Heading>
          </Col>
        </Row>
        <Row className={styles.subtitle} justify="between" align="center">
          <Col md={6}>
            <Paragraph>{user.email}</Paragraph>
          </Col>
          <Col md={6} className="text-right">
            <Button
              appearance="minimal"
              className={styles.deleteAccount}
              onClick={() => setModalDelete(true)}
            >
              Delete My Account
            </Button>
          </Col>
        </Row>
      </Container>
      <Tablist marginBottom={majorScale(3)} marginTop={majorScale(3)}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            id={tab}
            onSelect={() => setSelectedIndex(index)}
            isSelected={index === selectedIndex}
            aria-controls={`panel-${tab}`}
          >
            {tab}
          </Tab>
        ))}
      </Tablist>
      <hr className={styles.divider} />
      <Pane flex="1">
        {tabs.map((tab, index) => (
          <Pane
            key={tab}
            id={`panel-${tab}`}
            role="tabpanel"
            aria-labelledby={tab}
            aria-hidden={index !== selectedIndex}
            display={index === selectedIndex ? 'block' : 'none'}
          >
            {tab === "User Settings" && <UserSettings/>}
            {tab === "Team" && <Team/>}
            {tab === "Subscription & Billing" && <Subscription/>}
          </Pane>
        ))}
      </Pane>
      <DeleteAccountModal
        isShown={modalDelete}
        setIsShown={setModalDelete}
      />
    </Pane>
  )
}

export default SettingPage;
