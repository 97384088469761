import styles from './TaskMenubar.module.scss';
import TagInput from './TagInput';

export default function TaskMenubar() {
  return (
    <div className={styles.container}>
      <TagInput />
    </div>
  )
}
