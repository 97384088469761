import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from "@emotion/styled";
import {
  Pane,
  Button,
  Popover,
  CrossIcon,
  TickIcon,
  TextInput,
  Position,
  ChevronLeftIcon
}
  from "evergreen-ui";

import { LISTCOLOR } from "./listColorLabel"

import styles from "./Label.module.scss";

const ButtonColor = styled.div`
    background-color: ${({ color }) => color};
    width: 28px;
    height: 28px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
`;

type Props = {
  onClose: any;
  setIsView: any
}

export default function Edit({ onClose, setIsView }: Props) {
  const [activeBg, setActiveBg] = useState("")
  const [title, setTitle] = useState("");
  const [colorSketch, setColorSketch] = useState("#fff");

  const onSelectColor = (color: any) => {
    setColorSketch(color)
  }


  return (
    <>
      <Pane className={styles.formHeader}>
        <Pane className={styles.closeIcon}>
          <ChevronLeftIcon className={styles.icon} onClick={() => setIsView('view')} />
        </Pane>
        <Pane className={styles.title}>Edit Label</Pane>
        <Pane className={styles.closeIcon}>
          <CrossIcon className={styles.icon} onClick={onClose} />
        </Pane>
      </Pane>
      <Pane className={styles.formBodyAdd}>
        <Pane className={styles.formTitleAdd}>
          <Pane className={styles.title}>Title</Pane>
          <TextInput name="title" value={title} className={styles.inputTitle} onChange={(event: any) => setTitle(event.target.value)} placeholder="Label Titles" />
        </Pane>
        <Pane className={styles.formColorAdd}>
          <Pane className={styles.title}>Color</Pane>
          <Pane className={styles.formChangeBg}>
            {
              LISTCOLOR.map((val) => {
                return (
                  <ButtonColor color={val.color} onClick={() => {
                    setActiveBg(val.color)
                  }}>
                    {
                      activeBg === val.color
                      &&
                      <TickIcon />
                    }
                  </ButtonColor>
                )
              })
            }
            <Popover
              content={
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <SketchPicker
                    color={colorSketch}
                    onChangeComplete={val => onSelectColor(val.hex)}
                  />
                </Pane>
              }
              position={Position.TOP_RIGHT}
            >
              <Pane
                className={styles.palette}
                background={"conic-gradient(from -44.17deg at 50% 50%, #D14343 -23.74deg, #3366FF 43.37deg, #52BD94 142.51deg, #FFB020 236.25deg, #D14343 336.26deg, #3366FF 403.37deg)"}
              />
            </Popover>
          </Pane>
        </Pane>
      </Pane>
      <Pane className={styles.formFooter}>
        <Button className={styles.buttonAddLabel} onClick={() => setIsView('view')}>
          Save
        </Button>
      </Pane>
    </>
  )
}
