import clsx from "clsx";
import styles from "./ShareFolderItem.module.scss";
import {DotIcon, FolderSharedIcon, Menu, Pane, ShareIcon} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useDrag, useDrop} from "react-dnd";
import {FolderService} from "../../services/folder";
import ShareFolderModal from "../Folder/ShareFolderModal";
import {getUser, isPROTeam} from "../../utils/store";
import {DocumentService} from "../../services/document";
import _ from "lodash";

type Props = {
  folder: any,
  parentFolder: any,
}

const ShareChildFolder = ({parentFolder ,folder}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const folderService = new FolderService();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
  const [isShowShare, setShowShare] = useState(false);
  const hasEditRole = folder.roleOfCurrentUser === "editor" || (
    !folder.roleOfCurrentUser && parentFolder.roleOfCurrentUser === "editor"
  );
  const documentService = new DocumentService();

  const openFolder = (folderId: string) => {
    history.push({
      pathname: '/folders/' + folderId,
      state: { folder: folderId }
    });
  }

  const isSelectFolder = (folderId: string) => {
    const isLoadingFolder = location.pathname.includes(folderId);
    return {
      isActive: isLoadingFolder
    }
  }

  // eslint-disable-next-line
  const [{ isDragging }, dragFolderRef] = useDrag(() => ({
    type: 'FOLDER',
    item: {
      type: 'FOLDER',
      folder: folder
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },

    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => {
      return true;
    }
  }));

  const isDocumentOwner = (doc: any) => {
    const user = getUser();
    const isOwner = _.get(doc.roles, user.uid) === "owner";
    return isOwner;
  }

  const [{ isOver: isOverOrdinal }, dropOrdinalRef] = useDrop(() => ({
    accept: ['FOLDER', 'DOCUMENT'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item: any, monitor) => {
      if(item.type === 'DOCUMENT'){
        return item.type === 'DOCUMENT' && hasEditRole && isDocumentOwner(item.doc);
      }
      else if(item.type === 'FOLDER'){
        return item.folder?.id !== folder.id
      }

      return true;
    },
    drop: async (item: any, monitor)  => {
      if (item.type === 'DOCUMENT' && item.doc) {
        await documentService.updateDocumentFolder(item.doc.id, folder.id);
      }
      else if (item.folder) {
        if(!hasEditRole) return;
        await folderService.updateShareFolderOrdinal(item.folder.id, folder);
      }
    }
  }));

  const onShareClick = () => {
    setShowShare(true);
  }

  const onClickToCloseRightMenu = (e: any) => {
    setShowContextMenu(false);
    document.removeEventListener("click", onClickToCloseRightMenu);
    document.removeEventListener("contextmenu", onClickToCloseRightMenu);
  }

  const onRightMenu = (e: any) => {
    if(!hasEditRole) return;
    if(!isPROTeam()) return;

    setAnchorPoint({x: e.pageX, y: e.pageY});
    setShowContextMenu(true);
  }

  useEffect(() => {
    if(showContextMenu){
      document.addEventListener("click", onClickToCloseRightMenu);
      document.addEventListener("contextmenu", onClickToCloseRightMenu);
    }
  }, [showContextMenu])

  return (
    <Pane ref={dropOrdinalRef} className={clsx('canDrop')}>
      <Pane
        className={clsx(styles.folder, styles.folderName, styles.folder, styles.childFolder, isSelectFolder(folder.id))}
        minWidth={"max-content"}
        onClick={() => openFolder(folder.id)}
        ref={dragFolderRef}
        onContextMenu={(e: any) => onRightMenu(e)}
      >
        <span className={clsx(styles.folderIcon, styles.preIcon)}>
          <DotIcon size={12} color={"#D8DAE5"}/>
        </span>
        <span className={clsx(styles.folderIcon, styles.shareFolderIcon)}>
          <FolderSharedIcon size={16}/>
        </span>
        <span className={clsx(styles.folderName, styles.folderNameLabel)}>{folder.name}</span>
      </Pane>
      {hasEditRole &&
        <Pane className={clsx(styles.afterFolderItem, isOverOrdinal && 'canDrop')} ref={dropOrdinalRef}></Pane>
      }
      {showContextMenu && folder?.id && (
        <Menu>
          <div
            className={styles.shareMenu}
            style={{ top: anchorPoint.y, left: anchorPoint.x }}
          >
            <Menu.Group title={folder.name}>
              <Menu.Item
                className={styles.menuItem}
                icon={<ShareIcon color={"#8F95B2"}/>}
                onClick={() => onShareClick()}
              >
                <span className={styles.contextItemLabel}>Share Folder</span>
              </Menu.Item>
            </Menu.Group>
          </div>
        </Menu>
      )}
      <ShareFolderModal isShown={isShowShare} setIsShown={setShowShare} folder={folder}/>
    </Pane>
  )
}

export default ShareChildFolder;
