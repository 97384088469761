import TaskItemInput from "./TaskItemInput";
import React, {useEffect, useRef, useState} from "react";
import {majorScale, Pane} from "evergreen-ui";
import {TaskService} from "../../services/task/task.service";
import {TaskInput} from "../../schemas";
import _ from "lodash"
import {TASK_TYPE, VIEW_MODE} from "../../services/task/tasks.helper";
import HeadingInput from "./HeadingInput";
import styles from "./TaskItem.module.scss"
import clsx from "clsx";

const tasksService = new TaskService();

type Props = {
  onCreated: Function,
  project: string,
  members: any,
  dateAble?: boolean
  viewMode: VIEW_MODE,
  taskType?: TASK_TYPE
}

const TaskCreate = ({onCreated, project, members, dateAble, viewMode, taskType}: Props) => {
  const taskInputRef = useRef(null);
  const [submitInfo, setSubmitInfo] = useState({});
  const stateRef = useRef({});

  const createTask = () => {
    tasksService.create({...stateRef.current, viewMode: viewMode} as TaskInput)
      .catch(ex => {
        console.log("create task fail message = ", ex.message);
      });
  }

  const createHeading = () => {
    tasksService.create({...stateRef.current, viewMode: viewMode, type: TASK_TYPE.HEADING} as TaskInput)
      .catch(ex => {
        console.log("create task fail message = ", ex.message);
      });
  }

  const setTaskInfo = (data: any) => {
    stateRef.current = data;
    setSubmitInfo(data);
  }

  const setHeadingInfo = (data: any) => {
    stateRef.current = data;
    setSubmitInfo(data);
  }

  const isClickOnEl = (selectors: string, event: any) => {
    const el = document.querySelector(selectors);
    return el && el.contains(event.target);
  }

  const handleClickOutside = (event: any) => {
    const isNoRef = !taskInputRef || !taskInputRef.current;
    if(isNoRef) return;

    if(isClickOnEl('.ant-picker-panel-container', event)) return;
    if(isClickOnEl('.assignees-panel', event)) return;

    // @ts-ignore
    const isClickInsideExpandPanel = taskInputRef.current.contains(event.target);
    if(isClickInsideExpandPanel) return;

    if(taskType === TASK_TYPE.HEADING) saveHeading();
    else saveTask();
  };

  useEffect(() => {
    const clickFunction = handleClickOutside.bind(this);
    document.addEventListener('click', clickFunction, true);
    return () => {
      document.removeEventListener('click', clickFunction, true);
    };
  }, []);

  const saveTask = () => {
    createTask();
    onCreated && onCreated();
  }

  const saveHeading = () => {
    createHeading();
    onCreated && onCreated();
  }

  return (
    <Pane ref={taskInputRef} paddingTop={majorScale(2)}>
      {taskType === TASK_TYPE.HEADING &&
        <Pane className={clsx(styles.headingSelected, styles.headingPanel)}>
          <HeadingInput
            setHeadingInfo={setHeadingInfo}
            saveHeading={saveHeading}
            project={project}
          />
        </Pane>
      }
      {taskType !== TASK_TYPE.HEADING &&
        <TaskItemInput
          setTaskInfo={setTaskInfo}
          project={project}
          members={members}
          assignees={_.get(submitInfo, "assignees", {})}
          dateAble={dateAble}
          saveTask={saveTask}
        />
      }
    </Pane>
  )
}

export default TaskCreate;
