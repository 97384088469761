import React from 'react';
import { RenderLeafProps } from 'slate-react';

import Caret from './Caret';

export function Leaf({ attributes, children, leaf: tLeaf }: RenderLeafProps) {
  const leaf = tLeaf as any;
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  if(leaf.superscript){
    children = <sup>{children}</sup>
  }
  if(leaf.subscript){
      children = <sub>{children}</sub>
  }
  if(leaf.color){
      children = <span style={{color:leaf.color}}>{children}</span>
  }
  if(leaf.bgColor){
      children = <span style={{backgroundColor:leaf.bgColor}}>{children}</span>
  }
  // if(leaf.fontSize){
  //     const size = sizeMap[leaf.fontSize]
  //     children = <span style={{fontSize:size}}>{children}</span>
  // }
  // if(leaf.fontFamily){
  //     const family = fontFamilyMap[leaf.fontFamily]
  //     children = <span style={{fontFamily:family}}>{children}</span>
  // }

  const data = leaf.data as any;
  return <span {...attributes}
  style={
    {
      position: "relative",
      backgroundColor: data?.alphaColor,
      zIndex: '10'
    } as any
  } 
  >
    {leaf.isCaret ? <Caret {...(leaf as any)} /> : null}
    {children}
  </span>;
}
