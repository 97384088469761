import {
  AutoformatRule,
  ELEMENT_LI,
  ELEMENT_OL,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  setNodes,
  TElement,
  TodoListItemNodeData,
  getParent,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from '@udecode/plate';
import { Editor } from 'slate';
import { clearBlockFormat, formatList } from './autoformatUtils';

const isHeadingFormat = (editor: any) => {
  if (editor.selection) {
    const parentEntry = getParent(editor, editor.selection);
    if (!parentEntry) return;
    const [node] = parentEntry;
    console.log('node', node);
    if (
      node?.type === ELEMENT_H1 ||
      node?.type === ELEMENT_H2 ||
      node?.type === ELEMENT_H3 ||
      node?.type === ELEMENT_H4 ||
      node?.type === ELEMENT_H5 ||
      node?.type === ELEMENT_H6
    ) {
      return true;
    }
  }
  return false;
};

export const autoformatLists: AutoformatRule[] = [
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['* ', '- '],
    triggerAtBlockStart: false,
    allowSameTypeAbove: false,
    preFormat: (editor: any) => {
      if (!isHeadingFormat(editor)) {
        return clearBlockFormat && clearBlockFormat(editor);
      }
    },
    format: editor => {
      if (isHeadingFormat(editor)) {
        if (editor?.operations) {
          const operation = editor?.operations[0] as any;
          const text = operation?.text ? `${operation?.text} ` : '- ';
          editor.insertText(text);
          return;
        }
      } else {
        return formatList(editor, ELEMENT_UL);
      }
    },
  },
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['1. ', '1) '],
    preFormat: (editor: any) => {
      if (!isHeadingFormat(editor)) {
        return clearBlockFormat && clearBlockFormat(editor);
      }
    },
    format: editor => {
      if (isHeadingFormat(editor)) {
        if (editor?.operations) {
          const operation = editor?.operations[0] as any;
          const text = operation?.text ? `${operation?.text} ` : '1. ';
          editor.insertText(text);
          return;
        }
      } else {
        return formatList(editor, ELEMENT_OL);
      }
    },
  },
  {
    mode: 'block',
    type: ELEMENT_TODO_LI,
    match: '[] ',
  },
  {
    mode: 'block',
    type: ELEMENT_TODO_LI,
    match: '[x] ',
    format: editor =>
      setNodes<TElement<TodoListItemNodeData>>(
        editor,
        { type: ELEMENT_TODO_LI, checked: true } as any,
        {
          match: n => Editor.isBlock(editor, n),
        },
      ),
  },
];
