import { AuthProvider } from './authProvider';

export enum UpgradeTier {
  Plus = 'plus',
  Pro = 'pro',
  Team = 'team',
}

export interface UserSchema {
  id?: string;
  uid?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  subscriptionPeriodUntil?: string;
  photoUrl?: string;
  isAdmin?: boolean;
  tier?: string;
  provider?: AuthProvider;
  isAdminTeam?: boolean;
  isPROTeam?: boolean;
  upgradeTier?: UpgradeTier;
  googleAvatar?: string;
}

export interface UserInterface {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  subscriptionPeriodUntil?: string;
  isInvited?: boolean;
  provider: AuthProvider;
}
