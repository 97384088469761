import {
  Button,
  Card,
  ChevronLeftIcon,
  Image,
  majorScale,
  Pane,
  Position,
  SideSheet,
  Text,
  Alert,
} from 'evergreen-ui';
import { useContext, useState } from 'react';
import './VersionModal.scss';
import styles from './VersionModal.module.scss';
import ListDates from './ListDates';
import _ from 'lodash';
import axios from 'axios';
import { EditorContext } from 'context/EditorContext';
import { DocumentService } from 'services/document';

const documentService = new DocumentService();

function VersionModal({
  editorUi,
  setModalHistoryVersion,
  histories,
  currentVersion,
  setCurrentVersion,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  const { document } = useContext(EditorContext);

  const handleClickRestoreVersion = async () => {
    try {
      setLoading(true);
      const xmlUrl = (currentVersion || { mediaLink: '' }).mediaLink;
      const { data } = await axios.get(xmlUrl);
      if (editorUi) {
        editorUi.current.editor.setGraphXml(data);
      } else {
        await documentService.updateOne(document.id, { xmlData: data, updatedAt: new Date() });
        window.location.reload();
      }
    } catch (ex) {
      console.log('restore history throw', ex);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Pane>
      <SideSheet
        {...props}
        width="100%"
        position={Position.RIGHT}
        onCloseComplete={() => setCurrentVersion(false)}
        containerProps={{
          className: 'version-sheet',
        }}
      >
        <Pane className="main-content">
          <Pane
            display="flex"
            paddingX={majorScale(3)}
            paddingY={majorScale(2)}
          >
            <Button
              color="#696F8C"
              onClick={() => {
                setModalHistoryVersion(false);
              }}
            >
              <ChevronLeftIcon color="#8F95B2" />
              <Text paddingLeft={majorScale(1)}>Back</Text>
            </Button>

            {showAlert && histories.length === 0 && (
              <Alert
                maxWidth="488px"
                className={styles.versionAlert}
                intent="none"
                isRemoveable={true}
                onRemove={() => {
                  setShowAlert(false);
                }}
              >
                <Pane className={styles.versionAlertText}>
                  New versions are automatically saved every 5 minutes. If you
                  don’t see any version yet in Version History, continue editing
                  for at least 5 minutes.
                </Pane>
              </Alert>
            )}

            {!_.isEmpty(currentVersion) && (
              <Button
                appearance="primary"
                marginLeft="auto"
                onClick={handleClickRestoreVersion}
                isLoading={loading}
              >
                <Text color="white">Restore This Version</Text>
              </Button>
            )}
          </Pane>

          <Pane
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
          >
            <Pane display="flex" marginBottom={majorScale(18)}>
              <Image
                src={(currentVersion || { screenshot: '' }).screenshot}
                maxWidth="80%"
                maxHeight="80%"
                margin="auto"
                display="block"
              />
            </Pane>
          </Pane>
        </Pane>
        <Pane className="left-panel">
          <Card>
            <Pane paddingX={majorScale(4)} paddingY={majorScale(3)}>
              <Text fontSize={16} fontWeight={600} color="#101840">
                Version History
              </Text>
            </Pane>
            <Pane overflowY="auto" height="80vh">
              <ListDates
                histories={histories}
                currentVersion={currentVersion}
                setCurrentVersion={setCurrentVersion}
              />
            </Pane>
          </Card>
        </Pane>
      </SideSheet>
    </Pane>
  );
}

export default VersionModal;
