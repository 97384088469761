import firebase from 'firebase/app';
import { Fuego } from './fuego';
import { FIREBASE_CONFIG } from './constants';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

firebase.initializeApp(FIREBASE_CONFIG);

if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS === 'true') {
  firebase.functions().useEmulator('localhost', 5001);
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const fuego = new Fuego(FIREBASE_CONFIG);
export const provider = new firebase.auth.GoogleAuthProvider();
