import {majorScale, minorScale, Pane, Text} from "evergreen-ui";
import styles from "./Notification.module.scss"
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import clsx from "clsx";
import {useCollection} from "@nandorojo/swr-firestore";
import {TASK_COLLECTION} from "../../../utils/constants";
import {useDrop} from "react-dnd";

type Props = {
  icon: any;
  label: any;
  color: any;
  backgroundColor?: any;
  hideAmount?: boolean;
  viewMode: string,
  whereClause: any,
  onDropTask?: Function
}

const TaskNotification = ({icon, label, color, backgroundColor, hideAmount, viewMode, whereClause, onDropTask}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const locationQuery = new URLSearchParams(location.search);
  const [todoTasks, setTodoTasks] = useState(0);

  const { data: tasks } = useCollection<any>(TASK_COLLECTION, {
    where: whereClause,
    listen: true,
  });

  useEffect(() => {
    setTodoTasks(tasks?.length || 0);
  }, [tasks]);

  const onSelectViewMode = () => {
    history.push({
      pathname: '/task',
      search: `view=${viewMode}`
    });
  }

  const [{ isOver }, dropItemRef] = useDrop(() => ({
    accept: ["TASK"],
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: async (item: any, monitor)  => {
      onDropTask && onDropTask(viewMode, item);
    }
  }));

  return (
    <>
      <Pane
        display={"flex"}
        justifyContent={"space-between"}
        padding={minorScale(1)}
        paddingLeft={majorScale(2)}
        className={clsx(styles.notificationItem, locationQuery.get("view") === viewMode && styles.selected, isOver && styles.dropOver)}
        onClick={onSelectViewMode}
        ref={dropItemRef}
      >
        <Pane display={"flex"}>
          <Pane color={color} paddingRight={majorScale(2)} alignSelf={"center"} display={"flex"}>{icon}</Pane>
          <Text color="#101840" fontWeight={500} alignSelf={"center"} className="label">{label}</Text>
        </Pane>
        {!hideAmount && todoTasks > 0 &&
          <Pane
            minWidth="20px"
            height={majorScale(2)}
            background={backgroundColor}
            paddingX={minorScale(1)}
            borderRadius="8px"
            display="flex"
            justifyContent={'center'}
            alignSelf={"center"}
          >
            <Pane
              alignSelf={"center"}
              fontSize={11}
              color={color}
              fontWeight={600}
            >{todoTasks}</Pane>
          </Pane>
        }
      </Pane>
    </>
  )
}

export default TaskNotification;
