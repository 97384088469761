import { SegmentedControl, Pane } from 'evergreen-ui';
import styles from './Control.module.scss';

export const Control = ({className, ...props }) => {
  return (
    <Pane className={className}>
      <SegmentedControl
        {...props}
      />
    </Pane>
  )
}

Control.defaultProps = {
  className: styles.control
}
