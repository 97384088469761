import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  Button,
  ItalicIcon,
  Pane, Popover, Position,
  SelectField,
  Text,
  UnderlineIcon,
} from 'evergreen-ui';
import styles from './TextPanel.module.scss';
import { Box } from '../../Box/Box';
import {
  changeFontFormatTextSelection,
  changePositionAlignSelection,
  resizeHeadingSelection,
} from 'components/Editor/utils/Actions';
import { ZwfConstants } from '../../../../utils/ZwfConstants';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {ColorPalette} from "../../ColorPalette/ColorPalette";
import {SketchPicker} from "react-color";
import clsx from "clsx";
import {ColorPaletteContainer} from "../../ColorPalette/ColorPaletteContainer";
import {updateLineLabelBackground, updateSelectionTextColor} from "../../../../utils/Actions";

const selectionColors = [
  '#101840',
  '#FFFFFF',
  '#D14343',
  '#52BD94',
]

const TextPanel = ({
  editorUi,
  graphState,
  dispatchGraphState,
  accessorPanel,
}) => {
  const [activeAlign, setActiveAlign] = useState('');
  const [activeBold,setActiveBold] = useState(false);
  const [activeItalic,setActiveItalic] = useState(false);
  const [activeUnderline,setActiveUnderline] = useState(false);

  useEffect(() => {
    let checkAlign = _.get(graphState, 'align', 'center');
    let checkFormat = _.get(graphState, 'fontStyle', 'center');
    checkFontFormat(checkFormat);
    if (checkAlign) {
      setActiveAlign(_.get(graphState, 'align', 'center'));
    } else {
      setActiveAlign('left');
    }

    console.log("graphState ", graphState);
  }, [graphState]);

  const optionSize = () => {
    var options = [];
    for (let i = 0; i <= 50; i += 2) {
      options.push(<option value={i}>{i}</option>);
    }
    return options;
  };
  // resize font option select
  const handleResizeFont = val => {
    const fontSizeHeading = _.toString(val);
    if (fontSizeHeading === '') return;
    dispatchGraphState({ fontSize: fontSizeHeading });
    resizeHeadingSelection(editorUi, fontSizeHeading);
  };
  // change position align
  const onAlignChange = val => {
    const positionAlign = _.toString(val);
    if (positionAlign === '') return;
    dispatchGraphState({ align: positionAlign });
    changePositionAlignSelection(editorUi, positionAlign);
  };
  //
  const onFontChange = val => {
    const fontFormat = _.toString(val);
    if (fontFormat === '') return;
    changeFontFormatTextSelection(editorUi, fontFormat);

    let cellsStyle = editorUi.editor.graph.getSelectionCells()[0].style;
    let findFontStyle = cellsStyle.indexOf('fontStyle=') + 10;
    let indexFontStyle = cellsStyle.slice(findFontStyle,findFontStyle + 1);
    checkFontFormat(indexFontStyle);
  };

  const checkFontFormat = (indexFontStyle) => {
    switch(indexFontStyle){
      case "1" :
        // bold
        setActiveFormat(true,false,false)
        break;
      case "2" :
        // italic
        setActiveFormat(false,true,false)
        break;
      case "3" :
        // bold + italic
        setActiveFormat(true,true,false)
        break;
      case "4" :
        // underline
        setActiveFormat(false,false,true)
        break;
      case "5" :
        // bold + underline
        setActiveFormat(true,false,true)
        break;
      case "6" :
        // italic + underline
        setActiveFormat(false,true,true)
        break;
      case "7" :
        // all
        setActiveFormat(true,true,true)
        break;
      default:
        setActiveBold(false);
        setActiveItalic(false);
        setActiveUnderline(false);
        break;
    }
  }

  const setActiveFormat = (actBold,actItalic,actUnder) => {
    setActiveBold(actBold);
    setActiveItalic(actItalic);
    setActiveUnderline(actUnder);
  }

  const onSelectColor = (color) => {
    dispatchGraphState({fontColor: color});
    updateSelectionTextColor(editorUi, color);
    updateLineLabelBackground(editorUi)
  }

  return (
    <Box title="Text" isDivider>
      <Pane className="mt-3 d-flex align-items-center">
        <Text>Font Size</Text>
        <Pane className={styles.fontSize}>
          <SelectField
            value={_.get(graphState, 'fontSize', '12')}
            width={93}
            onChange={e => handleResizeFont(e.target.value)}
          >
            {optionSize()}
          </SelectField>
        </Pane>
      </Pane>
      <Pane className={styles.textGroup}>
        {(accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_ALIGNMENT) || _.get(graphState, ZwfConstants.TYPE_LINE_LABEL)) && (
          <Pane className={styles.align}>
            <Button
              className={activeAlign === 'left' ? styles.activeAlign : ''}
              onClick={() => onAlignChange('left')}
            >
              <AlignLeftIcon />
            </Button>
            <Button
              className={activeAlign === 'center' ? styles.activeAlign : ''}
              onClick={() => onAlignChange('center')}
              disabled={
                !(accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_ALIGNMENT)) &&
                !_.get(graphState, ZwfConstants.TYPE_LINE_LABEL)
              }
            >
              <AlignCenterIcon />
            </Button>
            <Button
              className={activeAlign === 'right' ? styles.activeAlign : ''}
              onClick={() => onAlignChange('right')}
              disabled={
                !(accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_ALIGNMENT)) &&
                !_.get(graphState, ZwfConstants.TYPE_LINE_LABEL)
              }
            >
              <AlignRightIcon />
            </Button>
          </Pane>
        )}
        {(accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_STYLE) || _.get(graphState, ZwfConstants.TYPE_LINE_LABEL)) && (
          <Pane className={styles.fontFormat}>
            <Button
              className={activeBold ? styles.activeFormat : ""}
              onClick={() => onFontChange('bold')}
            >
              <BoldIcon color="#8F95B2" />
            </Button>
            <Button
              className={activeItalic ? styles.activeFormat : ""}
              onClick={() => onFontChange('italic')}
              disabled={
                !(accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_STYLE)) &&
                !_.get(graphState, ZwfConstants.TYPE_LINE_LABEL)
              }
            >
              <ItalicIcon />
            </Button>
            <Button
              className={activeUnderline ? styles.activeFormat : ""}
              onClick={() => onFontChange('underline')}
              disabled={
                !(accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_STYLE)) &&
                !_.get(graphState, ZwfConstants.TYPE_LINE_LABEL)
              }
            >
              <UnderlineIcon />
            </Button>
          </Pane>
        )}
      </Pane>
      {accessorPanel.isActionVisible(ZwfConstants.ACTION_TEXT_COLOR) &&
        <Pane>
          <ColorPaletteContainer>
            {selectionColors.map(item => (
              <ColorPalette
                isActive={item === graphState.fontColor}
                color={item}
                onClick={element => onSelectColor(element)}
              />
            ))}
            <Popover
              content={
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <SketchPicker
                    color={graphState.fontColor}
                    onChangeComplete={ val => onSelectColor(val.hex) }
                  />
                </Pane>
              }
              position={Position.TOP_RIGHT}
            >
              <Pane
                className={clsx(styles.palette, "mr-0")}
                background={"conic-gradient(from -44.17deg at 50% 50%, #D14343 -23.74deg, #3366FF 43.37deg, #52BD94 142.51deg, #FFB020 236.25deg, #D14343 336.26deg, #3366FF 403.37deg)"}
              />
            </Popover>
          </ColorPaletteContainer>
        </Pane>
      }
    </Box>
  );
};

export default TextPanel;
