/* eslint-disable */
import {
  ObjectRemoveEvent,
  ObjectSetEvent,
  RealTimeObject,
} from "@convergence/convergence";
import { mxCell, mxGraph } from "mxgraph";

import { Deserializer, Serializer } from "./";
import { MxCellAdapter } from "./MxCellAdapter";
import * as cmx from 'libs/mxgraph';
import { ZenRealTimeObject } from "./DataType";
import { db } from 'utils/firebase';

interface IMxCellsAdded {
  properties: { cells: mxCell[] };
}

interface IMxCellsRemoved {
  properties: { cells: mxCell[] };
}

interface IMxCellsOrdered {
  properties: { back: boolean; cells: mxCell[] };
}

export class MxGraphAdapter {
  private static readonly _CHARS =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  private static readonly _ID_LENGTH = 32;

  private static _generateId() {
    let text = "";
    for (let i = 0; i < MxGraphAdapter._ID_LENGTH; i++) {
      text += MxGraphAdapter._CHARS.charAt(
        Math.floor(Math.random() * MxGraphAdapter._CHARS.length)
      );
    }
    return text;
  }

  private readonly _mxGraph: mxGraph;
  // private readonly _rtCells: RealTimeObject;
  private readonly _cellCollection: any;
  private readonly _session: any;
  protected _batch: any;
  private readonly _listeners: any[];
  private readonly _cellAdapters: Map<mxCell, MxCellAdapter>;

  constructor(graph: mxGraph, cellCollection: any, session: any) {
    this._mxGraph = graph;
    // this._rtCells = rtGraph.get("cells") as RealTimeObject;

    this._cellCollection = cellCollection;
    this._session = session;

    this._listeners = [];

    this._cellAdapters = new Map();

    // Listen for local changes
    this._mxGraph.addListener(
      cmx.mxEvent.CELLS_ADDED,
      (_: any, evt: IMxCellsAdded) => {
        this._handleLocalCellsAdded(evt)
      }
    );

    this._mxGraph.addListener(
      cmx.mxEvent.UNGROUP_CELLS,
      (_: any, evt: IMxCellsRemoved) => {
        this._handleUnGroupCellsRemoved(evt)
      }
    );

    this._mxGraph.addListener(
      cmx.mxEvent.CELLS_REMOVED,
      (_: any, evt: IMxCellsRemoved) => {
        this._handleLocalCellsRemoved(evt)
      }
    );
    
    this._mxGraph.addListener(
      cmx.mxEvent.CELLS_ORDERED,
      (_: any, evt: IMxCellsOrdered) => {
        const { properties } = evt;
        const { cells } = properties;

        try {
          for (var i = 0; i < cells.length; i++) {
            const cell = cells[i];
            const parent = cell.parent;
            const childCells = parent?.children as mxCell[];
            childCells.forEach((childCell: mxCell, index: number) => {
              const adapter = this._cellAdapters.get(childCell);
              if (adapter) {
                adapter.setIndex(index);
              }
            });
            if (childCells) {
              this._fireEvent("onCellsOrdered", { cells: cells });
            }
          }
        } finally {
        }

      }
    );
    
    this._mxGraph.model.addListener(cmx.mxEvent.CHANGE, (_: any, evt: any) => {
      const edit = evt.getProperty("edit");
      // this._batch = db.batch();

      // Sort changes
      let changeList = [] as any[];

      // Set base order
      edit.changes.forEach((change: any, index: number) => {
        change.sort = index;
        changeList[index] = change;
      });

      // For edge
      changeList = changeList.map((element: any) => {
        if (element.child?.edge) {
          element.sort = 2000;
        }
        return element;
      });
      
      // const tableChanges = changeList.filter((element: any) => element.child?.style?.includes('shape=table'));

      // changeList = changeList.map((element: any) => {
      //   if (element.child?.style?.includes('shape=table')) {
      //     element.sort = -100;
      //   }
      //   return element;
      // });

      let sortedChanges = changeList.sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));
      
      // debugger
      sortedChanges.forEach((change: any) => this._processLocalChange(change));

      // this._batch.commit();      
    });

    Object.keys(this._mxGraph.model.cells).forEach((id: string) => {
      const cell = this._mxGraph.model.cells[id];
      this._bindMxCellAdapter(cell);
    });
  }

  public addListener(listener: any): void {
    this._listeners.push(listener);
  }

  private _bindMxCellAdapter(
    mxGraphCell: mxCell
  ): void {
    const adapter = new MxCellAdapter(
      mxGraphCell,
      new ZenRealTimeObject(this._cellCollection.doc(mxGraphCell.id), mxGraphCell, this._session),
      this._mxGraph,
      this._fireEvent.bind(this) as any
    );
    this._cellAdapters.set(mxGraphCell, adapter);
  }

  private _handleLocalCellsAdded(evt: IMxCellsAdded): void {
    const { properties } = evt;
    const cells = properties.cells;

    cells.forEach((cell: mxCell) => {
      this._handlePotentiallyNewCell(cell);
    });
  }

  private _handlePotentiallyNewCell(cell: mxCell): void {
    if (!cell.id) {
      const id = MxGraphAdapter._generateId();
      this._mxGraph.model.cells[id] = cell;
      cell.id = id;
      const cellJson = Serializer.serializeMxCell(cell);
      
      // const rtCell = this._rtCells.set(cell.id, cellJson) as RealTimeObject;
      cellJson.session = this._session;
      this._handleChange(() => this._cellCollection.doc(cell.id).set(cellJson));
      this._bindMxCellAdapter(cell);
      
      if(cell.children){
        cell.children.forEach((c) => {
          this._handlePotentiallyNewCell(c)
       })
      }
    }
  }

  // private async _setData(doc: any, data: any) {
  //   try {
  //     const cell = await doc.get();
  //     if (cell.exists) {
  //       doc.set(data);
  //     } else if (this._cell) {
  //       const cellJson = Serializer.serializeMxCell(this._cell);
  //       console.log('unmap json', cellJson);
  //     }
  //   } catch (e: any) {
  //     console.log('Cant update firestore: ', e);
  //   }
  // }

  private _handleChange(funt: Function) {
    funt();
    
    // if (this._batch && typeof this._batch === 'function') {
    //   this._batch(funt());
    // } else {
    //   funt();
    // }
  }

  public async set(doc: any, key: string, value: any) {
    try {
      const cell = await doc.get();
      if (cell.exists) {
        doc.update({
          [key]: value,
          session: this._session,
        });
      }
    } catch (e: any) {
      console.log('Cant update firestore: ', e);
    }
  }

  private _handleLocalCellsRemoved(evt: IMxCellsRemoved): void {
    const { properties } = evt;
    const cells = properties.cells;

    cells.forEach((cell: mxCell) => {
      const cellId = cell.id;
      
      // this._rtCells.remove(cellId);
      this._cellAdapters.delete(cell);
      
      // // Fix for 0 0 geo
      cell.setVisible(false);

      if (cell.style?.includes('group;') || cell.style === 'group') {
        const childrenCells = cell?.children || [];

        if (childrenCells.length === 0) {
          this.set(this._cellCollection.doc(cellId), 'visible', false);
          // this._handleChange(() => this._cellCollection.doc(cellId).delete());
        } else {
          this._handleChange(() => this._cellCollection.doc(cellId).delete());
        }

        // cell.setVisible(false);
        childrenCells.forEach((childCell: mxCell) => {
          childCell.setVisible(false);
          // this._rtCells.remove(childCell.id);
          // this._cellAdapters.delete(childCell);
          // this._mxGraph.view.clear(cell, true, true);
        })
      } else {
        this._handleChange(() => this._cellCollection.doc(cellId).delete());
      }
      // this._mxGraph.view.clear(cell, true, true);
    });
    // this._fireEvent("onCellsRemoved", { cells });
    // this._mxGraph.view.refresh();
  }
  
  private _handleUnGroupCellsRemoved(evt: IMxCellsRemoved): void {
    const { properties } = evt;
    const cells = properties.cells;
    // this.set(this._cellCollection.doc(cellId), 'visible', false);
  }

  private _handleCellAddedGraph(cell: mxCell) {
    if (cell && !this._mxGraph.model.cells[cell.id]) {
      this._mxGraph.model.cellAdded(cell);
    }
  }

  public async handleRemoteCellAdded(cellData: any) {
    const cellId = cellData.id;
    const cell = Deserializer.deserializeMxCell(cellId, cellData);
    cell.setVisible(true);

    Deserializer.resolveCellRelationships(cell, cellData, this._mxGraph.model);
    this._handleCellAddedGraph(cell);
  
    // const rtCells = this._rtCells.value();
    if (this._isParentAdded(cell?.style)) {
      const childCellJson = await this._cellCollection.where('parent', '==', cell.id).get();
      // debugger
      // const childCellJson = Object.values(rtCells).filter(c => c.parent == cell.id);
      if (this._isGroupAdded(cell?.style)) {
        await this._handleUndoGroupRemoteAdded(childCellJson.docs || []);
      } else {
        // await this._handleUndoRemoteAdded(childCellJson.docs || []);
      }
      this._mxGraph.view.clear(cell);
    }

    // Update terminal
    // this._handleUndoTerminalAdded(cell);

    this._mxGraph.view.refresh();
    this._bindMxCellAdapter(cell);
  }

  /**
   * Check is parent shape
   * 
   * @param cellStyle 
   * @returns 
   */
  private _isParentAdded(cellStyle?: string) {
    if (cellStyle) {
      return cellStyle === 'group' || cellStyle.includes('group;') || cellStyle.includes('shape=partialRectangle')
        || cellStyle.includes('shape=table') || cellStyle.includes('shape=image')
        || cellStyle.includes('shape=windowShape') || cellStyle.includes('shape=iPhoneShape')
        || cellStyle.includes('shape=iPadShape');
    }
    return false;
  }

  /**
   * Check is parent shape
   * 
   * @param cellStyle 
   * @returns 
   */
   private _isGroupAdded(cellStyle?: string) {
    if (cellStyle) {
      return cellStyle === 'group' || cellStyle.includes('group;');
    }
    return false;
  }

  private async _handleUndoGroupRemoteAdded(childCellJson: any[]) {
    childCellJson.forEach(async (c: any) => {
      if (c.exists) {
        const cellData = c.data();
        const tCell = Deserializer.deserializeMxCell(cellData.id, cellData);
        tCell.setVisible(true);
        
        // const rCellDoc = await this._cellCollection.doc(cellData.id).get();
        // const rCell = rCellDoc.data();
        const parentId = cellData.parent;
        const parent = this._mxGraph.model.cells[parentId];
        const cellAdded = this._mxGraph.model.cells[cellData.id];
        
        if (parent && cellAdded) {
          cellAdded.setParent(parent);
          // this._mxGraph.model.cellAdded(cellAdded);
          // this._mxGraph.view.clear(cellAdded);
        } else {
          // this._mxGraph.model.cellAdded(tCell);
          this._handleCellAddedGraph(tCell);
        }
        Deserializer.resolveCellRelationships(tCell, cellData, this._mxGraph.model);
        
        // childCells.push(tCell);
        // const childTCells = Object.values(rtCells).filter(c => c.parent == tCell.id);

        const childTCells = await this._cellCollection.where('parent', '==', tCell.id).get();
        if (childTCells?.docs?.length > 0) {
          await this._handleUndoGroupRemoteAdded(childTCells.docs || []);
        }
      }
    });
    // cell.children = childCells;
  }
  
  private async _handleUndoRemoteAdded(childCellJson: any[]) {
    const childCells = [] as any;
    childCellJson.forEach(async (c: any) => {
      if (c.exists) {
        const cellData = c.data();
        const tCell = Deserializer.deserializeMxCell(cellData.id, cellData);
        tCell.setVisible(true);
        
        Deserializer.resolveCellRelationships(tCell, cellData, this._mxGraph.model);
  
        this._handleCellAddedGraph(tCell);
        
        childCells.push(tCell);
        const childTCells = await this._cellCollection.where('parent', '==', tCell.id).get();
        if (childTCells?.docs?.length > 0) {
          await this._handleUndoRemoteAdded(childTCells.docs || []);
        }
      }
      
    });
    // cell.children = childCells;
  }

  private async _handleUndoTerminalAdded(cell: mxCell) {
    const targetEdgeCells = await this._cellCollection.where('target', '==', cell.id).get();
    targetEdgeCells.docs.forEach((edgeCellDoc: any) => {
      if (edgeCellDoc.exists) {
        const edgeCell = edgeCellDoc.data();
        const edge = this._mxGraph.model.getCell(edgeCell.id);
        const source = this._mxGraph.model.getCell(edgeCell.source);
        if (edge && source) {
          this._mxGraph.model.setTerminals(edge, source, cell);
        }
      }
    });

    const sourceEdgeCells = await this._cellCollection.where('source', '==', cell.id).get();
    sourceEdgeCells.docs.forEach((edgeCellDoc: any) => {
      if (edgeCellDoc.exists) {
        const edgeCell = edgeCellDoc.data();
        const edge = this._mxGraph.model.getCell(edgeCell.id);
        const target = this._mxGraph.model.getCell(edgeCell.target);
        if (edge && target) {
          this._mxGraph.model.setTerminals(edge, cell, target);
        }
      }
    });
  }

  private setAttributeForCell (cell: mxCell, attributeName: string, attributeValue: string) {
    console.log('li');
    var value = null;

    if (cell.value != null && typeof(cell.value) == 'object')
    {
      value = cell.value.cloneNode(true);
    }
    else
    {
      var doc = cmx.mxUtils.createXmlDocument();

      value = doc.createElement('UserObject');
      value.setAttribute('label', cell.value || '');
    } 

    if (attributeValue != null)
    {
      value.setAttribute(attributeName, attributeValue);
    }
    else
    {
      value.removeAttribute(attributeName);
    }

    this._mxGraph.model.setValue(cell, value);
  };

  public handleRemoteCellRemoved(cellData: any): void {
    const cellId = cellData.id;
    const cell = this._mxGraph.model.cells[cellId];

    if (cell) {
      const cells = [cell];
      cell.remote = true;
      const childrenCells = cell?.children || [];

      
        
      // Fix for 0 0 geo
      cell.setVisible(false);

      // Handle for group
      if (cell.style?.includes('group;') || childrenCells.length > 0) {
        childrenCells.forEach((childCell: mxCell) => {
          childCell.setVisible(false);
        });
      }
      
      // Handle for table
      if (cell.parent && (cell.parent.style?.includes('shape=table') || cell.parent.style?.includes('shape=partialRectangle'))) {
        const parentCell = cell.parent;
        const geo = parentCell.geometry.clone();
        geo.height = geo.height - cell.height;
        geo.width = geo.width - cell.width;
        this._mxGraph.model.setGeometry(parentCell, geo);
      }

      // this._fireEvent("onCellsRemoved", { cells: cells });
      this._cellAdapters.delete(cell);
      this._mxGraph.model.remove(cell);
      // this._mxGraph.model.cellRemoved(cell);
      this._mxGraph.view.clear(cell, true, true);
    }
  }

  private _processLocalChange(change: any) {
    try {
      const changeType = change.constructor.name;
      if (change instanceof cmx.mxRootChange || changeType === 'mxRootChange') {
        // if (change.root === this._mxGraph.model.root) {
        //   // todo
        //   console.warn("unhandled root change");
        // }
      } else if (change instanceof cmx.mxChildChange || changeType === 'mxChildChange') {
        this._processLocalChildChange(change);
      } else if (change.cell != null && change.cell.id != null) {
        const adapter = this._cellAdapters.get(change.cell);
        adapter?.processChange(change);
      }
    } catch (e: any) {
      console.log('_processLocalChange error: ', e);
    }
  }

  private _processLocalChildChange(change: any) {
    const { child } = change;
    if (!child.id) {
      this._handlePotentiallyNewCell(child);
    } else {
      const adapter = this._cellAdapters.get(child);
      // Apply add event
      if (change.previous === null && change.parent) {
        const cell = child;
        // Handle for group ungroup
        cell?.setVisible(true);
        if(cell.children){
          cell.children.forEach((c: mxCell) => {
            c.setVisible(true);
         })
        }
        
        const cellJson = Serializer.serializeMxCell(cell);
        
        // const rtCell = this._rtCells.set(cell.id, cellJson) as RealTimeObject;
        // this._bindMxCellAdapter(cell, rtCell);
        cellJson.session = this._session;
        this._handleChange(() => this._cellCollection.doc(cell.id).set(cellJson));
        this._bindMxCellAdapter(cell);

        if(cell.children){
          cell.children.forEach((c: mxCell) => {
            this._handlePotentiallyNewCell(c);
         })
        }
        this._mxGraph.view.refresh();
      } else if (adapter) {
        // Apply remove event
        if (change.parent === null && change.previous) {          
          const cell = child;  
          const cellId = cell.id;
          
          // this._rtCells.remove(cellId);
          this._cellAdapters.delete(cell);
          this._handleChange(() => this._cellCollection.doc(cellId).delete());
          // this._fireEvent("onCellsRemoved", { cells: [cell] });
        } else {
          adapter.processChange(change);
        }
      }
    }
  }

  private _fireEvent(name: string, evt: any) {
    this._listeners.forEach((listener: any) => {
      try {
        const callback = listener[name];
        if (callback) {
          callback(evt);
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  public handleRemoteUpdateIndex(cell: mxCell, cellData: any) {
    const parentCell = cell.getParent();
    const childCells = parentCell?.children || [];
    if (childCells) {
      const oldIndex = childCells.findIndex(item => item?.id == cell.id);
      const newIndex = cellData.index;
      if (oldIndex !== -1 && newIndex !== oldIndex) {
        childCells[oldIndex] = childCells[newIndex];
        childCells[newIndex] = cell;
      }
    }
  }

  public handleRemoteUpdateParent(cell: mxCell, cellData: any) {
    if (this._isGroupAdded(cell.style) && cell.visible === false) {
      return;
    }

    const parentId = cellData.parent;
    const parent = parentId === null ? null : this._mxGraph.model.cells[parentId];
    if (parent) {
      const children = parent.children || [];
      const childIndex = children.findIndex((item : any) => item?.id == cell.id);
      if (childIndex !== -1) {
        children[childIndex] = cell;
      } else {
        if (cell) {
          children.push(cell); // Check for group
        }
      }
      this._handleCellAddedGraph(cell);
      parent.children = children;
      cell.setVisible(true);
      cell.setParent(parent);
    }
  }

}
