import { useState, useEffect, useRef } from 'react';
import { Pane } from 'evergreen-ui';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import {
  DOCUMENT_COLLECTION,
  COMMENT_COLLECTION,
  USER_COLLECTION,
  DESIGNER_OPTIONS,
  DESIGNER_PUBLISH_OPTIONS,
  MAX_NODE_LIMIT
} from '../../utils/constants';
import { useDocumentDetail, DocumentService } from 'services/document';
import { EditorContextProvider } from 'context/EditorContext';
import { Spinner } from '../Spinner/Spinner';
import styles from '../Editor/EditorApp.module.scss';
import Menubar from '../Editor/modules/Menubar/Menubar';
import { db } from '../../utils/firebase';
import { getUser } from '../../utils/store';
import { PermissonType } from '../../schemas';
import { isHavePermission } from '../../services/user';
import useStores from '../../hooks/useStores';
import { CommentBar } from '../Editor/modules/CommentBar/CommentBar';
import Toolbar from '../Editor/modules/Toolbar/ToolbarMindMap';
import useLoad from '../Editor/hooks/useLoadMindMap';
import TipModal from '../Modals/TipModal/TipModal';
import AddLinkModal from '../Modals/AddLinkModal/AddLinkModal';
import './MindMapEditor.css';
import MenubarViewerMindMap from '../Editor/modules/Menubar/MenubarViewerMindMap';
import AddNoteModal from '../Modals/AddNoteModal/AddNoteModal';

const documentService = new DocumentService();

export const MindMapEditor = () => {
  const isMindplotReady = useLoad();
  const { document: docData, isLoading: isLoadingDocument, getDocument, folder } = useDocumentDetail();
  const [xmlData, setXmlData] = useState(null);
  const [isDocumentReady, setDocumentReady] = useState(false);
  const [comments, setComments] = useState([]);
  const [undoQueue, setUndoQueue] = useState([]);
  const [redoQueue, setRedoQueue] = useState([]);
  const [haveEditPermisson, setHaveEditPermisson] = useState(false);
  const [isCommentBarVisible, setIsCommentBarVisible] = useState(false);
  const [isLoadedChart, setIsLoadedChart] = useState(true);
  const [tipModal, setTipModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [mindplot, setMindplot] = useState(null);
  const [designer, setDesigner] = useState(null);
  const [core, setCore] = useState(null);
  const documentRef = useRef(null);
  const history = useHistory();
  const params = useParams();
  const { graphStore } = useStores();

  useEffect(() => {
    document.documentElement.style.cssText = 'height:100%;overflow:hidden';
    document.body.style.cssText = 'min-height:100%;overflow:hidden';
    document.addEventListener('gesturestart', (e) => e.preventDefault())
    document.addEventListener('gesturechange', (e) => e.preventDefault())
    return () => {
      document.documentElement.style.cssText = '';
      document.body.style.cssText = '';
      document.removeEventListener('gesturestart', (e) => e.preventDefault())
      document.removeEventListener('gesturechange', (e) => e.preventDefault())
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    let unsubscribe;
    let unsubcribeDocument;
    if (params.id) {
      getDocument(params.id);
      unsubcribeDocument = db
        .collection(DOCUMENT_COLLECTION)
        .doc(params.id)
        .onSnapshot(async querySnapshot => {
          setXmlData(querySnapshot.data().xmlData);
          setDocumentReady(true);
        })
      unsubscribe = db
        .collection(DOCUMENT_COLLECTION)
        .doc(params.id)
        .collection(COMMENT_COLLECTION)
        .onSnapshot(async querySnapshot => {
          const comments = [];
          for (const doc of querySnapshot.docs) {
            const comment = { id: doc.id, ...doc.data() };

            // Populate user
            if (comment.user) {
              const user = await db
                .collection(USER_COLLECTION)
                .doc(comment.user.toString())
                .get();
              if (user.exists) {
                const userData = user.data();
                userData.fullName = [userData.firstName, userData.lastName]
                  .filter(item => !!item)
                  .join(' ');
                comment.user = { id: user.id, ...userData };
              }
            }
            comments.push(comment);
          }

          // Update comments
          setComments(comments);
        });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (unsubcribeDocument) {
        unsubcribeDocument();
      }
    };
  }, [params.id]);

  useEffect(() => {
    if (!isLoadedChart) {
      const topics = designer.getModel().getTopics();
      if (topics.length < MAX_NODE_LIMIT) {
        const focusedTopic = topics.find(topic => topic._onFocus);
        designer.deleteAllEntities();
        const persistence = mindplot.PersistenceManager.getInstance();
        const mindmap = persistence.load(docData.id, xmlData);
        designer.createActionRunner(undoQueue, redoQueue);
        designer.refreshMap(mindmap);
        if (focusedTopic) {
          const topic = designer.getModel().findTopicById(focusedTopic.getId());
          designer.goToNode(topic);
        }
      }
    }
  }, [xmlData])

  useEffect(() => {
    const isFolderLoaded = (folder && docData.folder);
    const isDocumentHasNotFolder = (docData && !docData.folder);
    if (!isLoadingDocument && (isDocumentHasNotFolder || isFolderLoaded)) {
      graphStore.setDocument(docData);
      const user = getUser();
      if (!isHavePermission(user, docData, PermissonType.ViewDoc, folder)) {
        history.push('/');
      }
      if (isHavePermission(user, docData, PermissonType.EditDoc, folder)) {
        setHaveEditPermisson(true);
      } else {
        setHaveEditPermisson(false);
      }
    }
  }, [isLoadingDocument, folder]);

  useEffect(() => {
    if (!isLoadingDocument && isMindplotReady && isDocumentReady) {
      const { core, mindplot, buildDesigner } = window;
      const designer = buildDesigner(
        haveEditPermisson ? DESIGNER_OPTIONS : DESIGNER_PUBLISH_OPTIONS,
        documentRef.current
      );

      // Load map from XML file persisted on disk...
      const persistence = mindplot.PersistenceManager.getInstance();
      const mindmap = persistence.load(docData.id, xmlData);
      designer.loadMap(mindmap);
      setDesigner(designer);
      setMindplot(mindplot);
      setCore(core);
      setIsLoadedChart(false);
    }
  }, [isLoadingDocument, isMindplotReady, isDocumentReady]);

  // Auto save on a fixed period of time ...
  useEffect(() => {
    if (designer) {
      designer.addEvent('modelUpdate', async function() {
        window.setTimeout(async function () {
          await handleAutoSave();
        }, 10)
      })
    }
  }, [designer]);

  const handleAutoSave = async () => {
    const mindmap = designer.getMindmap();
    const serializer = mindplot.persistence.XMLSerializerFactory.getSerializerFromMindmap(mindmap);
    const domMap = serializer.toXML(mindmap);
    const mapXml = core.Utils.innerXML(domMap);
    try {
      graphStore.setIsSaving(true);
      await documentService.updateOne(docData.id, {
        xmlData: mapXml,
        updatedAt: new Date()
      });
    } finally {
      graphStore.setIsSaving(false);
    }
  }

  const handleChangeDocumentName = async name => {
    await documentService.updateOne(docData.id, { name });
  };

  const toggleCommentBar = () => setIsCommentBarVisible(!isCommentBarVisible);

  return (
    <EditorContextProvider
      value={{
        documentNode: documentRef.current,
        documentId: params.id,
        document: docData,
        comments: comments,
        isMindMap: true,
        toggleCommentBar,
        isCommentBarVisible,
        designer,
        mindplot,
        core,
        tipModal,
        setTipModal,
        linkModal,
        setLinkModal,
        noteModal,
        setNoteModal,
        setUndoQueue,
        setRedoQueue,
      }}
    >
      <Pane className={clsx(styles.container, styles.mindMapBar)}>
        {!isLoadedChart && (
          <Pane className={styles.menubarContainer}>
            {haveEditPermisson ? (
              <Menubar
                documentName={docData.name}
                unreadComments={comments.length}
                folder={folder}
                onChangeDocumentName={handleChangeDocumentName}
              />
            ) : (
              <MenubarViewerMindMap
                documentName={docData.name}
                unreadComments={comments.length}
                onChangeDocumentName={handleChangeDocumentName}
                folder={folder}
              />
            )}
          </Pane>
        )}
        {(!isLoadedChart && haveEditPermisson) && <Toolbar />}
        <Pane
          className={
            clsx(
              styles.commentBarContainer,
              styles.commentBarMindMap,
              !haveEditPermisson && styles.commentBarView,
              isCommentBarVisible && styles.commentBarVisible,
            )}
        >
          <CommentBar />
        </Pane>
      </Pane>
      <TipModal />
      <AddLinkModal />
      <AddNoteModal />
      {(isLoadingDocument || isLoadedChart || !isDocumentReady) && <Spinner />}
      <div id="mindplot" ref={documentRef}/>
    </EditorContextProvider>
  );
}
