import { useEffect } from 'react';
import { TextInput, Dialog, Button, Heading, Pane } from 'evergreen-ui';
import { useState } from 'react';
import clsx from 'clsx';

import styles from './ShareNotebookModal.module.scss';
import Combobox from 'components/Elements/Combobox';
import { NotebookService, useShareNotebook } from 'services/note';
import { NotebookSchema } from 'schemas/notebook';
import { Role } from 'schemas/role';
import { capitalize } from 'utils/string';
import { isFreeUser } from 'utils/store';
import ProFeatureModal from 'components/Modals/ProFeatureModal/ProFeatureModal';
import { UpgradeToProModal } from 'components/Modals';
import { useActivateTeam } from 'services/team/team.helper';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  notebook: NotebookSchema;
}

const notebookService = new NotebookService();

export default function ShareNotebookModal({
  isShown,
  setIsShown,
  notebook,
}: Props) {
  const [inviteList, setEmailList] = useState('');
  const [currentNotebook, setCurrentNotebook] = useState(notebook);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const [selected, setSelected] = useState({
    label: 'Editor',
    value: 'editor',
  } as any);

  const { shareUsers, getShareDocument, shareTeams } = useShareNotebook();

  const { activateTeams } = useActivateTeam();

  const roleList: any[] = [];
  const inviteRoleList: any[] = [];
  Object.entries(Role).forEach(([label, value]) => {
    roleList.push({
      label,
      value,
    });
    if (value !== 'owner') {
      inviteRoleList.push({
        label,
        value,
      });
    }
  });

  const inviteRoles = [
    { label: 'Editor', value: 'editor' },
    { label: 'Viewer', value: 'viewer' },
  ];

  const userRoles = [
    { label: 'Viewer', value: 'viewer' },
    { label: 'Editor', value: 'editor' },
    { label: 'Remove', value: 'remove' },
  ];

  const teamRoles = [
    { label: 'None', value: 'none' },
    { label: 'Can View', value: 'viewer' },
    { label: 'Can edit', value: 'editor' },
  ];

  useEffect(() => {
    if (notebook && notebook.id) {
      setCurrentNotebook(notebook);
      getShareDocument(notebook.id);
    }
  }, [notebook]);

  const handleInvite = async () => {
    try {
      const freeUserInvites = ['viewer'];
      const isLimitedByFreeUser =
        isFreeUser() && !freeUserInvites.includes(selected.value);
      setShowProFeature(isLimitedByFreeUser);

      if (isLimitedByFreeUser) return;

      let emailList = inviteList.split(',');
      emailList = emailList.map(item => item.trim().toLowerCase());
      if (inviteList && emailList && selected) {
        setIsLoading(true);
        await notebookService.addUserRole(
          currentNotebook.id,
          emailList,
          selected.value,
        );
      }
    } finally {
      setIsLoading(false);
      setEmailList('');
    }
  };

  const handleChangeInviteRole = async (email?: string, newRole?: string) => {
    if (email && newRole) {
      await notebookService.updateUserRole(currentNotebook.id, email, newRole);
    }
  };

  const handleChangeTeamRole = async (teamId: string, newRole: string) => {
    if (teamId && newRole) {
      await notebookService.updateTeamRole(currentNotebook.id, teamId, newRole);
    }
  };

  const initialTeamRole = (teamId: string) => {
    const shareRole = shareTeams.find(t => t.id === teamId);
    const noneRole = teamRoles.find(role => role.value === 'none');

    if (!shareRole) return noneRole;

    return teamRoles.find(role => role.value === shareRole.role);
  };

  return (
    <Dialog
      isShown={isShown}
      title="Share & Collaborate"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Save"
      containerProps={{
        className: clsx(styles.modal),
      }}
      contentContainerProps={{
        className: styles.content,
      }}
      hasFooter={false}
    >
      <div>
        <TextInput
          width="263px"
          required
          name="name"
          value={inviteList}
          onChange={(e: any) => setEmailList(e.target.value)}
          placeholder="Email, comma separated."
        />

        <Combobox
          initialSelectedItem={{ label: selected?.label || '' }}
          items={inviteRoles}
          itemToString={item => (item ? item.label : '')}
          onChange={(selected: any) => setSelected(selected)}
          buttonProps={{ className: 'roleListIcon' }}
        />

        <Button
          appearance="primary"
          className={clsx(styles.inviteBtn)}
          onClick={handleInvite}
          isLoading={isLoading}
        >
          Invite
        </Button>
      </div>

      <hr />

      <div>
        <Heading is="h4" className={clsx(styles.permissionHeading)}>
          Permissions
        </Heading>

        <div className={styles.sharedList}>
          {activateTeams?.map(team => (
            <div
              key={team.id}
              className={clsx(styles.sharedItem, styles.ownerItem)}
            >
              <div className={styles.shareTarget}>{team.name}</div>
              <div className={styles.shareAction}>
                <Combobox
                  selectedItem={initialTeamRole(team.id)}
                  items={teamRoles}
                  itemToString={item => (item ? item.label : '')}
                  buttonProps={{ className: styles.roleListBtn }}
                  onChange={(selected: any) =>
                    handleChangeTeamRole(team.id, selected.value)
                  }
                />
              </div>
            </div>
          ))}
          {shareUsers?.map(shareUser => (
            <div
              key={shareUser.id}
              className={clsx(styles.sharedItem, styles.ownerItem)}
            >
              <div className={styles.shareTarget}>
                {shareUser?.firstName && shareUser?.lastName
                  ? `${shareUser.firstName} ${shareUser.lastName}`
                  : shareUser?.email}
              </div>
              <div className={styles.shareAction}>
                <Combobox
                  initialSelectedItem={{
                    label: capitalize(shareUser.role?.type) || 'Owner',
                  }}
                  disabled={(shareUser.role?.type || 'Owner') === 'Owner'}
                  items={userRoles}
                  itemToString={item => (item ? item.label : '')}
                  onChange={(selected: any) =>
                    handleChangeInviteRole(shareUser.email, selected.value)
                  }
                  buttonProps={{ className: styles.roleListBtn }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={'PRO Feature'}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={
          <Pane>
            This feature is not available to Free users. Upgrade to a
            <br />
            paid plan for unlimited access and more!
          </Pane>
        }
      />
      <UpgradeToProModal
        isShown={isShowUpgradeModal}
        setIsShown={setShowUpgradeModal}
      />
    </Dialog>
  );
}
