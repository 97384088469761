import {ApplicationIcon, Menu, MobilePhoneIcon, Pane, Text, WidgetIcon} from "evergreen-ui";
import TabletIcon from "../../../Icon/TabletIcon";
import React, {useEffect} from "react";


const AddFrameMenu = ({editorUi, close}) => {
  let refs = {};
  const updateRef = (key, value) => {
    refs[key] = value;
  }

  // Binding create icon event
  useEffect(() => {
    Object.keys(refs).forEach(key => {
      editorUi.current.sidebar.addDragComponent(refs[key], key);
    })
  }, []);

  return (
    <Menu>
      <Pane onClick={close}>
        <Menu.Item paddingX={0} ref={(el) => updateRef('frames.free', el)}>
          <WidgetIcon size={12} color="#8F95B2" className="mr-2"/><Text fontSize={12}>Free Size</Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item paddingX={0} ref={(el) => updateRef('frames.window', el)}>
          <ApplicationIcon size={12} color="#8F95B2" className="mr-2"/><Text fontSize={12}>Window / Browser</Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item paddingX={0} ref={(el) => updateRef('frames.iphone', el)}>
          <MobilePhoneIcon size={12} color="#8F95B2" className="mr-2 my-auto"/><Text fontSize={12}>Smartphone</Text>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item paddingX={0} ref={(el) => updateRef('frames.ipad', el)}>
          <TabletIcon size={12} color="#8F95B2" className="mr-2"/><Text fontSize={12}>Tablet</Text>
        </Menu.Item>
      </Pane>
    </Menu>
  );
}

export default AddFrameMenu;
