import TaskItem from "./TaskItem";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {majorScale, Pane} from "evergreen-ui";
import clsx from "clsx";
import styles from "./TasksView.module.scss";
import Expand from "react-expand-animated";
import {useDrop} from "react-dnd";
import {useLocation} from "react-router-dom";
import {TaskService} from "../../services/task/task.service";
import {TASK_TYPE} from "../../services/task/tasks.helper";
import HeadingItem from "./HeadingItem";

type Props = {
  tasks: any[],
  taskSelected?: boolean,
  assignees?: any,
  onSelect?: Function
  dateAble: boolean
}

const taskService = new TaskService();

const TaskTodo = ({tasks, onSelect, assignees, taskSelected, dateAble}: Props) => {

  const [draggableTasks, setDraggableTasks] = useState(tasks);

  const location = useLocation();
  const locationQuery = new URLSearchParams(location.search);
  const viewMode = locationQuery.get("view") || "";

  useEffect(() => {
    setDraggableTasks(tasks);
  }, [tasks]);

  const canArrange = () => {
    return  !["tome", "today"].includes(viewMode);
  }

  const [{ isDropTaskOver, isDropHeadingOver }, dropTaskRef] = useDrop(() => ({
    accept: ["TASK"],
    collect: (monitor) => ({
      isDropTaskOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "TASK"
        && canArrange()
        && _.get(monitor.getItem(), "task.type") !== TASK_TYPE.HEADING
      ,
      isDropHeadingOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "TASK"
        && canArrange()
        && _.get(monitor.getItem(), "task.type") === TASK_TYPE.HEADING
    }),
    canDrop: (item: any, monitor) => {
      const isTask = _.get(item, "type") === "TASK";
      const isTodoTask = !_.get(item.task, "isComplete");

      return isTask && isTodoTask&& canArrange();
    },
    drop: async (item: any, monitor)  => {
      const updateDraggableTask = (id: string, newOrdinal: number) => {
        const taskInList = draggableTasks?.find(task => task.id === id);
        if(taskInList){
          taskInList.ordinal = newOrdinal;
          const sortByOrdinalTask = (draggableTasks?.filter(task => !task.isComplete) || [])
            .sort((first, second) => {
              return first.ordinal > second.ordinal ? -1 : 1;
            })
          setDraggableTasks(sortByOrdinalTask);
        }
      }

      const newOrdinal = (new Date()).getTime();
      taskService.partialUpdate(
        {
          id: item.task.id,
          ordinal: newOrdinal
        }
      );

      updateDraggableTask(item.task.id, newOrdinal);
    }
  }), []);

  return (
    <>
      <Pane width="100%" height={majorScale(2)} ref={dropTaskRef}/>
      <Pane paddingX={majorScale(0)}>
        <Expand
          duration={200}
          open={isDropHeadingOver}
          transitions={["height", "opacity"]}
        >
          <Pane className={clsx(isDropHeadingOver && styles.taskDropOver)}/>
        </Expand>
      </Pane>
      <Pane paddingLeft={majorScale(4)} marginLeft={2}>
        <Expand
          duration={200}
          open={isDropTaskOver}
          transitions={["height", "opacity"]}
        >
          <Pane className={clsx(isDropTaskOver && styles.taskDropOver)}/>
        </Expand>
      </Pane>
      {
        draggableTasks.map(
          (task, index) => (
            <Pane key={_.get(task, "id", index)} className="wrapper">
              {task.type !== TASK_TYPE.HEADING &&
                <TaskItem
                  {...task}
                  onSelect={onSelect}
                  isSelected={_.get(task, "id") === taskSelected}
                  members={assignees}
                  dateAble={dateAble}
                  draggableTasks={draggableTasks}
                  updateOrderTasks={setDraggableTasks}
                />
              }
              {task.type === TASK_TYPE.HEADING &&
                <HeadingItem
                  task={task}
                  isSelected={_.get(task, "id") === taskSelected}
                  onSelected={onSelect}
                  draggableTasks={draggableTasks}
                  updateOrderTasks={setDraggableTasks}
                />
              }
            </Pane>
          )
        )
      }
    </>
  )
}

export default TaskTodo;
