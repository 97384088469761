import {Dialog} from "../../../components/Dialog";
import {Pane} from "evergreen-ui";

type Props = {
  isShown: boolean,
  setIsShown: Function,
  onConfirm: Function,
}

const EmptyTrashTaskModal = ({isShown, setIsShown, onConfirm}: Props ) => {

  const onConfirmDelete = () => {
    onConfirm && onConfirm();
    setIsShown(false);
  }

  return (
    <Dialog
      isShown={isShown}
      title="Empty Trash"
      onCloseComplete={() => setIsShown && setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Empty Trash"
      intent="danger"
      onConfirm={onConfirmDelete}
    >
      <Pane>Are you sure to empty trash?</Pane>
    </Dialog>
  )
}

export default EmptyTrashTaskModal;
