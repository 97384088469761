import ConvergenceStore from './ConvergenceStore';
import GraphStore from './GraphStore';
import NoteStore from './NoteStore';

export class RootStore {
  convergenceStore: ConvergenceStore;
  graphStore: GraphStore;
  noteStore: NoteStore;
  isConnected: boolean;

  constructor() {
    this.convergenceStore = new ConvergenceStore(this);
    this.graphStore = new GraphStore(this);
    this.noteStore = new NoteStore(this);
    this.isConnected = false;
  }
}
