import firebase from 'firebase';
import { functions } from 'utils/firebase';

/**
 * List of available firebase functions
 */
export enum ResetFunction {
  SendResetEmail = 'sendResetEmail',
  ValidateResetToken = 'validateResetToken',
}

/**
 * List of reset type
 */
export enum ResetType {
  ResetPassword = 'ResetPassword',
  ResetEmail = 'ResetEmail',
}

/**
 * Stripe service
 */
export class ResetService {
  /**
   * Firebase functions
   * @private
   */
  private readonly functions: firebase.functions.Functions;

  /**
   * Initialize publish and secret key
   */
  constructor() {
    this.functions = functions
  }

  /**
   * Check it is reset email or not
   *
   * @param type
   */
  isResetEmail(type: ResetType) {
    return type === ResetType.ResetEmail;
  }

  /**
   * Send Reset
   *
   */
  async sendResetEmail(type: ResetType, email: string): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(ResetFunction.SendResetEmail);
    return callable({ type: type, email: email, siteUrl: window.location.origin });
  }

  /**
   * Validate reset token
   *
   */
  async validateResetToken(type: ResetType, token: string): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(ResetFunction.ValidateResetToken)
    return callable({ type: type, token, siteUrl: window.location.origin });
  }

  /**
   * Update password
   *
   */
  async updatePassword(token: string, password: string): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(ResetFunction.ValidateResetToken)
    return callable({ type: ResetType.ResetPassword, token, password, siteUrl: window.location.origin });
  }
}
