import {Pane} from 'evergreen-ui';
import { Control } from '../../Control/Control';
import { Box } from '../../Box/Box';
import React from "react";
import _ from "lodash"
import { ZwfConstants } from '../../../../utils/ZwfConstants';
import {changeToggle} from "../../../../utils/Actions";

const ButtonStatePanel = ({editorUi, graphState, dispatchGraphState}) => {

  const onToggleChange = (orientation) => {
    dispatchGraphState({zwfToggleState: orientation});
    changeToggle(editorUi, orientation);
  }

  return (
    <Box title="Button State" isDivider>
      <Pane className="mt-3">
        <Control
          value={_.get(graphState, ZwfConstants.TOGGLE_STATE)}
          options={[
            { label: 'Unselected', value: ZwfConstants.TOGGLE_STATE_OFF },
            { label: 'Selected', value: ZwfConstants.TOGGLE_STATE_ON },
          ]}
          onChange={val => onToggleChange(val)}
        />
      </Pane>
    </Box>
  )
}

export default ButtonStatePanel;
