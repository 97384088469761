import { atom } from 'recoil';

const colorBgStorage = localStorage.getItem('colorBg')

export const activeCategorySound = atom({
    key: 'category-sound', // unique ID (with respect to other atoms/selectors)
    default: 'All', // default value (aka initial value)
});

export const actionSound = atom({
    key: 'sound', // unique ID (with respect to other atoms/selectors)
    default: {
        id: null,
        name: '',
        image: '',
        sound: '',
        playing: false,
        volume: 0.8
    }, // default value (aka initial value)
});

export const activeColorBG = atom({
    key: 'color-bg', // unique ID (with respect to other atoms/selectors)
    default: colorBgStorage ? colorBgStorage : '#696F8C', // default value (aka initial value)
});