import React from 'react';
import { ColorPaletteContainer } from '../../ColorPalette/ColorPaletteContainer';
import { ColorPalette } from '../../ColorPalette/ColorPalette';
import { Box } from '../../Box/Box';
import {updateLineLabelBackground, updateSelectionTextColor} from "../../../../utils/Actions";
import {Pane, Popover, Position} from "evergreen-ui";
import {SketchPicker} from "react-color";
import clsx from "clsx";
import styles from "../../ColorPalette/ColorPalette.module.scss";

const topColors = [
  '#101840',
  '#D8DAE5',
  '#D6E0FF',
  '#DCF2EA',
  '#FFFFFF'
]
const bottomColors = [
  '#3366FF',
  '#FFB020',
  '#D14343',
  '#52BD94',
]

const ColorTextPanel = ({editorUi, graphState, dispatchGraphState}) => {

  const onSelectColor = (color) => {
    dispatchGraphState({fontColor: color});
    updateSelectionTextColor(editorUi, color);
    updateLineLabelBackground(editorUi)
  }

  return (
    <Box title="Color" isDivider>
      <ColorPaletteContainer>
        {topColors.map(item => (
          <ColorPalette
            isActive={item === graphState.fillColor}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
      </ColorPaletteContainer>
      <ColorPaletteContainer>
        {bottomColors.map(item => (
          <ColorPalette
            isActive={item === graphState.fillColor}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
        <Popover
          content={
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <SketchPicker
                color={graphState.fillColor}
                onChangeComplete={ val => onSelectColor(val.hex) }
              />
            </Pane>
          }
          position={Position.TOP_RIGHT}
        >
          <Pane
            className={clsx(styles.palette)}
            background={"conic-gradient(from -44.17deg at 50% 50%, #D14343 -23.74deg, #3366FF 43.37deg, #52BD94 142.51deg, #FFB020 236.25deg, #D14343 336.26deg, #3366FF 403.37deg)"}
          />
        </Popover>
      </ColorPaletteContainer>
    </Box>
  )
}

export default ColorTextPanel;
