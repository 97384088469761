import {DOCUMENT_COLLECTION, USER_COLLECTION} from 'utils/constants';
import {fuego, useCollection} from '@nandorojo/swr-firestore';
import {useCallback, useEffect, useReducer, useState} from 'react';
import {DocumentSchema} from 'schemas';
import {getUser} from 'utils/store';
import {ShareUserSchema} from 'schemas/share';
import {useFolderDetail} from "../folder";
import { DocumentType } from 'schemas';
import _ from 'lodash';
import {db} from "../../utils/firebase";

type Props = {
  folder?: string | null;
}

/**
 * Use document detail
 *
 * @returns
 */
export const useDocumentDetail = () => {
  const [document, setDocument] = useState({} as DocumentSchema);
  const [folderId, setFolderId] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const {folder} = useFolderDetail(folderId);

  const getDocument = useCallback(async (id: string) => {
    const ref = fuego.db.doc(`${DOCUMENT_COLLECTION}/${id}`);
    let query = ref;

    const unsubscribe = query.onSnapshot(
      {
        includeMetadataChanges: true,
      },
      (doc: any) => {
        const docData = doc.data();
        setDocument({
          id: doc.id,
          ...docData
        });
        if (docData?.folder) {
          setFolderId(docData?.folder);
        }
        setIsLoading(false);
      },
      (error: any) => {
        setIsLoading(false);
      }
    );
    return unsubscribe;
  }, []);

  return {
    document,
    isLoading,
    getDocument,
    setDocument,
    folder: folder,
  };
};

export const useDocumentCollection = ({ folder }: Props) => {
  const ref = fuego.db.collection(DOCUMENT_COLLECTION);
  const [documents, setDocuments] = useState([] as DocumentSchema[]);
  const [createdDocs, setCreatedDocs] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);

  const user = getUser();
  const getDocuments = useCallback(async (folder?: string) => {
    if (!user) {
      return false;
    }
    const { uid } = user;
    const currentFolder = folder || '';
    let query = ref
      .where(`roles.${uid}`, '==', 'owner')
      .where('isDeleted', '==', false)
      .where('folder', '==', currentFolder);

    if(folder){
      query = ref
        .where('isDeleted', '==', false)
        .where('folder', '==', currentFolder);
    }

    const unsubscribe = query.onSnapshot(
      {
        includeMetadataChanges: true,
      },
      (querySnapshot: any) => {
        const docs: any[] = [];
        querySnapshot.forEach((change: any) => {
          docs.push({
            id: change.id,
            ...change.data(),
          });
        });
        getCreatedDocs(user, ref);
        setIsLoading(false);
        setDocuments(docs);
      }
    );
    return unsubscribe;
  }, [user, ref]);

  const getCreatedDocs = (user: any, ref: any) => {
    if (!user) {
      return false;
    }
    const { uid } = user;

    return ref
      .where(`roles.${uid}`, '==', 'owner').get().then((resp: any) => {
        const docs = resp.docs
          .map((item: { data: () => any; }) => {
            const data = item.data();
            if (!data.type) {
              data.type = DocumentType.Flowchart;
            }
            return data;
          });
        setCreatedDocs((docs || []).length)
      });
  };

  return {
    documents,
    isLoading,
    getDocuments,
    setDocuments,
    createdDocs,
    setCreatedDocs
  };
};

export const useCountDocOfFreeUser = () => {
  const user = getUser();
  const [docCount, setDocCount] = useState(-1);

  fuego.db.collection(DOCUMENT_COLLECTION)
    .where(`roles.${user.uid}`, '==', 'owner')
    .onSnapshot((resp: any) => {
      console.log("resp = ", resp);
      setDocCount(resp.docs?.length || 0);
    });

  return {docCount}
}

export const useShareDocument = () => {
  const [document, setDocuments] = useState({} as DocumentSchema);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([] as any[]);
  const [teamRoles, setTeamRoles] = useState([] as any[]);
  const [invites, setInvites] = useState([] as any[]);
  const [shareUsers, setShareUsers] = useState([] as ShareUserSchema[]);
  const [shareTeams, setShareTeams] = useState([] as any[]);
  const user = getUser();

  useEffect(() => {
    const getShareDetail = async() => {
      const resShare: ShareUserSchema[] = [];
      const userRef = fuego.db.collection(USER_COLLECTION);
      if (roles) {
        for (const [uid, role] of Object.entries(roles)) {
          if (uid !== 'anyone') {
            const user = await userRef.doc(uid).get();
            resShare.push({
              id: user.id,
              ...user.data(),
              role,
            });
          }
        }
      }

      if (invites) {
        for (const [uid, invite] of Object.entries(invites)) {
          if (uid !== 'anyone') {
            resShare.push({
              id: user.id,
              email: invite.inviteEmail,
              role: invite,
            });
          }
        }
      }

      const teamRolesMap = Object.keys((teamRoles || {})).map((teamId) => ({
        role: _.get(teamRoles, teamId, {}).type,
        id: teamId,
      }));

      setShareUsers(resShare);
      setShareTeams(teamRolesMap);
    };
    getShareDetail();
  }, [roles, invites, user?.id, teamRoles]);

  const getShareDocument = useCallback(async (docId: string) => {
    const ref = fuego.db.collection(DOCUMENT_COLLECTION);
    if (!user) {
      return false;
    }
    let query = ref.doc(docId);

    const unsubscribe = query.onSnapshot(
      {
        includeMetadataChanges: true,
      },
      (doc: any) => {
        setIsLoading(false);
        const resDoc = {
          id: doc.id,
          ...doc.data(),
        };
        const roles = resDoc.roles;
        const invites = resDoc.invites;
        const teamRoles = resDoc.teamRoles;
        setDocuments(resDoc);
        setRoles(roles);
        setInvites(invites);
        setTeamRoles(teamRoles);
      }
    );
    return unsubscribe;
  }, [user?.uid]);

  return {
    document,
    shareUsers,
    roles,
    isLoading,
    getShareDocument,
    shareTeams
  };
};

export const useShareDocuments = (activateTeams: any[]) => {
  const [shareDocs, setShareDocs] = useState([] as DocumentSchema[]);
  const user = getUser();
  const [teamShareDocsMap, dispatchTeamShareDocsMap] = useReducer((state: any, teamShareDocs: any) => {
    return {...state, ...teamShareDocs};
  }, {});

  const shareToUsersSubQuery = [
    [`roles.${user.uid}.type`, 'in', ['editor', 'commenter', 'viewer']],
    ['isDeleted', '==', false],
  ] as any[];

  const { data: rawDocuments } = useCollection<DocumentSchema>(DOCUMENT_COLLECTION, {
    where: [
      ...shareToUsersSubQuery,
    ],
    listen: true,
  });


  useEffect(() => {
    if((activateTeams || []).length <= 0) return;

    activateTeams.forEach(team => {
      db.collection(DOCUMENT_COLLECTION)
        .where(`teamRoles.${team.id}.type`, 'in', ['editor', 'commenter', 'viewer'])
        .where('isDeleted', '==', false)
        .onSnapshot(resp => {
          dispatchTeamShareDocsMap({
            [team.id]: resp.docs.map(doc => ({...doc.data(), id: doc.id}))
          })
        })
    })
  }, [activateTeams?.length]);

  useEffect(() => {
    let totalShareDocs = [] as any[];
    Object.values(teamShareDocsMap).forEach((docs: any) => {
      const removeDuplicateDocs = (docs || [])
        .filter((doc: any) => _.get(doc.roles, user.uid) !== "owner")
        .filter(
        (doc: any) => !totalShareDocs.find(shareDoc => shareDoc.id === doc.id)
      )
      totalShareDocs = [...totalShareDocs, ...removeDuplicateDocs];
    })

    setShareDocs(totalShareDocs);
  }, [teamShareDocsMap])

  useEffect(() => {
    dispatchTeamShareDocsMap({
      "none_team": rawDocuments as DocumentSchema[]
    })
  }, [rawDocuments]);

  return {
    shareDocs,
  }
}
