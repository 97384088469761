import React, { useState } from 'react';
import {
  Pane,
  AnnotationIcon,
  CrossIcon,
  TickIcon,
  PlusIcon,
  Button
}
  from "evergreen-ui";
import styles from "./Label.module.scss";

const listLabels = [
  {
    id: "1",
    name: "High priority",
    color: "#D14343",
    checked: true
  },
  {
    id: "2",
    name: "MEDIUM priority",
    color: "#9DB5FF",
    checked: false
  },
  {
    id: "3",
    name: "LOW priority",
    color: "#52BD94",
    checked: false
  },
  {
    id: "4",
    name: "info",
    color: "#FFB020",
    checked: false
  },
  {
    id: "5",
    name: "bug",
    color: "#DE7548",
    checked: false
  },
  {
    id: "6",
    name: "feature",
    color: "#897AE3",
    checked: false
  }
]

type Props = {
  onClose: any;
  setIsView: any
}

export default function View({ onClose, setIsView }: Props) {
  const [labels, setLabels] = useState(listLabels)

  const onChangeCheckedLabel = (value: any, index: number) => {
    let lb = [...labels]
    lb[index].checked = !value.checked
    setLabels(lb)
  }

  return (
    <>
      <Pane className={styles.formHeader}>
        <Pane className={styles.title}>Labels</Pane>
        <Pane className={styles.closeIcon}>
          <CrossIcon className={styles.icon} onClick={onClose} />
        </Pane>
      </Pane>
      <Pane className={styles.listLabel}>
        {
          labels.map((val, index) => {
            return (
              <Pane className={styles.itemLabel}>
                <Pane backgroundColor={val.color} className={styles.item} onClick={() => onChangeCheckedLabel(val, index)}>
                  <Pane className={styles.titleItem}>
                    {val.name}
                  </Pane>
                  {
                    val.checked
                    &&
                    <Pane className={styles.iconCheck}>
                      <TickIcon />
                    </Pane>
                  }
                </Pane>
                <AnnotationIcon className={styles.iconEdit} onClick={() => setIsView('edit')} />
              </Pane>
            )
          })
        }
      </Pane>
      <Pane className={styles.formFooter}>
        <Button iconBefore={PlusIcon} className={styles.buttonAddLabel} onClick={() => setIsView('add')}>
          New Label
        </Button>
      </Pane>
    </>
  )
}
