/* eslint-disable */
import { mxCell, mxGraph } from "mxgraph";

import { Deserializer, Serializer } from "./";
import {
  mxCollapseChange,
  mxStyleChange,
  mxTerminalChange,
  mxValueChange,
  mxVisibleChange,
  mxGeometryChange,
  mxChildChange,
} from "./mxAdapter";

import * as cmx from 'libs/mxgraph';
import { ZenRealTimeObject } from "./DataType";

export class MxCellAdapter {
  private readonly _mxCell: mxCell;
  private readonly _mxGraph: mxGraph;
  private readonly _rtCell: ZenRealTimeObject;
  private readonly _eventCallback: (event: string, value: any) => void;

  constructor(
    cell: mxCell,
    rtCell: ZenRealTimeObject,
    graph: mxGraph,
    eventEmitter: () => void
  ) {
    this._mxCell = cell;
    this._rtCell = rtCell;
    this._mxGraph = graph;
    this._eventCallback = eventEmitter;
  }

  public processChange(change: any): void {
    try {
      const changeType = change.constructor.name;
      if (change instanceof cmx.mxTerminalChange || changeType == 'mxTerminalChange') {
        this._localTerminalChanged(change);
      } else if (change instanceof cmx.mxGeometryChange || changeType == 'mxGeometryChange') {
        this._localGeometryChanged(change);
      } else if (change instanceof cmx.mxStyleChange || changeType == 'mxStyleChange') {
        this._localStyleChanged(change);
      } else if (change instanceof cmx.mxValueChange || changeType == 'mxValueChange') {
        this._localValueChanged(change);
      } else if (change instanceof cmx.mxCollapseChange || changeType == 'mxCollapseChange') {
        this._localCollapsedChanged(change);
      } else if (change instanceof cmx.mxVisibleChange || changeType == 'mxVisibleChange') {
        this._localVisibleChanged(change);
      } else if (change instanceof cmx.mxChildChange || changeType === 'mxChildChange') {
        this._localChildChange(change);
      }
    } catch(e: any) {
      console.log('processChange exception: ', e);
    }
  }

  private _localGeometryChanged(change: mxGeometryChange): void {
    const { geometry } = change;
    const geometryJson = Serializer.serializeGeometry(geometry);
    this._rtCell.set('geometry', geometryJson);
  }

  private _localCollapsedChanged(change: mxCollapseChange): void {
    const { collapsed } = change;
    this._rtCell.set("collapsed", collapsed);
    this._eventCallback("onCellChanged", { cell: this._mxCell });
  }

  private _localVisibleChanged(change: mxVisibleChange): void {
    const { visible } = change;
    this._rtCell.set("visible", visible);
    this._eventCallback("onCellChanged", { cell: this._mxCell });
  }

  private _localValueChanged(change: mxValueChange): void {
    const { value, previous } = change;
    if (value?.attributes?.link) {
      if (value.attributes?.link.nodeValue !== previous?.attributes?.link.nodeValue) {
        this._rtCell.set("link", value.attributes.link.nodeValue);
      } else {
        if (value.attributes?.label.nodeValue !== previous?.attributes?.label.nodeValue) {
          this._rtCell.set("value", value.attributes?.label.nodeValue || '');
          this._rtCell.set("link", value.attributes.link.nodeValue);
        }
      }
    } else {
      this._rtCell.set("value", value);
      this._eventCallback("onCellChanged", { cell: this._mxCell });
    }
  }

  private _localTerminalChanged(change: mxTerminalChange): void {
    const { source, terminal, previous } = change;
    const prop = source ? "source" : "target";

    if (terminal !== previous) {
      const value = terminal !== null ? terminal.id : null;
      this._rtCell.set(prop, value);
    }

    // this._eventCallback("onCellChanged", { cell: this._mxCell });
  }

  private _localStyleChanged(change: mxStyleChange): void {
    const { previous, cell } = change;
    if (cell.style !== previous) {
      this._rtCell.set("style", Serializer.serializeStyle(cell.style));
    }
  }

  public setIndex(index: number): void {
    this._rtCell.set("index", index);
  }

  private _localChildChange(change: mxChildChange): void {
    const { parent, previous } = change;

    if (previous && previous.id === '1' && !previous.geometry) {
      const { geometry } = this._mxCell;
      const geometryJson = Serializer.serializeGeometry(geometry);
      this._rtCell.set('geometry', geometryJson);
    }
    
    this._rtCell.set("parent", parent === null ? null : parent.id);
    // this._eventCallback("onCellChanged", { cell: this._mxCell });
  }

  private _initRtGeometry() {
    if (this._rtCell?.hasKey("geometry")) {
      const rtGeometry = this._rtCell.get("geometry");
      const geometry = Deserializer.deserializeGeometry(rtGeometry);
      if (geometry) {
        this._mxCell.setGeometry(geometry);
        const cell = this._mxCell;
        if (cell.parent && (cell.parent.style?.includes('shape=table'))) {
          const parentCell = cell.parent;
          this._mxGraph.view.clear(parentCell);
          this._mxGraph.view.refresh();
        }
      }
      return rtGeometry;
    } else {
      return null;
    }
  }

}
