import { useCallback, useEffect, useRef } from 'react';

export default function useKeyPress(keys: any, onPress: Function) {
  keys = keys.split(' ').map((key: string) => key.toLowerCase());
  const isSingleKey = keys.length === 1;
  const pressedKeys = useRef([] as string[]);

  const keyIsRequested = useCallback(
    (key: string) => {
      key = key.toLowerCase();
      return keys.includes(key);
    },
    [keys],
  );

  const addPressedKey = (key: string) => {
    key = key.toLowerCase();
    const update = pressedKeys.current.slice() as string[];
    update.push(key);
    pressedKeys.current = update;
  };

  const removePressedKey = (key: string) => {
    key = key.toLowerCase();
    let update = pressedKeys.current.slice();
    const index = update.findIndex(sKey => sKey === key);
    update = update.slice(0, index);
    pressedKeys.current = update;
  };

  const downHandler = useCallback(
    ({ key }: { key: string }) => {
      const isKeyRequested = keyIsRequested(key);
      if (isKeyRequested) {
        addPressedKey(key);
      }
    },
    [keyIsRequested],
  );

  const upHandler = useCallback(
    ({ key }: { key: string }) => {
      const isKeyRequested = keyIsRequested(key);
      if (isKeyRequested) {
        if (isSingleKey) {
          pressedKeys.current = [];
          onPress();
        } else {
          const containsAll = keys.every((i: string) =>
            pressedKeys.current.includes(i),
          );
          removePressedKey(key);
          if (containsAll) {
            onPress();
          }
        }
      }
    },
    [keyIsRequested, isSingleKey, keys, onPress],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);
}
