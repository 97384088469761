import { useState } from 'react';
import { Pane, Textarea, Button, majorScale, Avatar } from 'evergreen-ui';
import { getUser } from 'utils/store';
import styles from './EditComment.module.scss';
import { getUserAvatar } from '../../../../../utils/helpers';

export const EditComment = ({ comment, isEditing, onConfirm, onCancel }) => {
  const [visible, setVisible] = useState(isEditing);
  const [input, setInput] = useState(comment || '');
  const handleToggle = () => setVisible(!visible);
  const user = getUser();
  const fullName = [user.firstName, user.lastName].filter(item => !!item).join(', ');
  const name = fullName === '' ? user.email : fullName;

  const handleRef = (element) => {
    if (element) {
      window.setTimeout(() => {
        if (element.selectionStart || element.selectionStart === 0) {
          element.selectionStart = element.value.length;
          element.selectionEnd = element.value.length;
          element.focus();
        }
        element.focus()
      }, 50);
    }
  }

  return (
    <Pane display="flex" flexDirection="row" marginTop="1rem">
      <Pane flexShrink={0}>
        <Avatar src={getUserAvatar(user)} name={name} size={majorScale(4)} />
      </Pane>
      <Pane marginLeft={8} width="100%">
        <Textarea
          ref={handleRef}
          className={styles.textarea}
          onChange={event => setInput(event.target.value)}
          value={input}
          placeholder="Add a comment"
        />
        <Pane className={styles.buttonGroup}>
          <Button
            onClick={() => {
              handleToggle();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            className={styles.buttonComment}
            appearance="primary"
            disabled={input.length <= 0}
            onClick={() => {
              handleToggle();
              onConfirm(input);
              setInput('');
            }}
          >
            Save
          </Button>
        </Pane>
      </Pane>
    </Pane>
  )
}

EditComment.defaultProps = {
  comment: '',
  isEditing: false,
  onConfirm: () => {},
  onCancel: () => {},
}
