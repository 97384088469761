import { db } from 'utils/firebase';
import {TEAM_COLLECTION, USER_COLLECTION} from 'utils/constants';
import firebase from 'firebase';
import { UserInterface, UserSchema } from 'schemas';
import { functions } from 'utils/firebase';

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type QuerySnapshot = firebase.firestore.QuerySnapshot;

export type UsersQuery = {
  search: string,
  size: number,
  page: number,
  sort: string,
  direction: string
}

export type SignUpPayload = {
  email: string,
  password: string,
  code: string,
}

export type UpgradeToProPayload = {
  email: string,
  code: string,
}

/**
 * User Service
 */
export class UserService {
  /**
   * Database Instance
   *
   * @private
   */
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  /**
   * Database Instance
   *
   * @private
   */
  private readonly team: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  private readonly functions: firebase.functions.Functions;

  /**
   * Constructor
   *
   */
  constructor() {
    this.db = db.collection(USER_COLLECTION);
    this.team = db.collection(TEAM_COLLECTION);
    this.functions = functions;
  }

  /**
   * Map item after get query
   *
   * @param querySnapshot
   * @returns
   */
  mapItem(querySnapshot: QuerySnapshot) {
    const collections: UserInterface[] = [];
    querySnapshot.forEach(item => {
      if (item.exists) {
        collections.push({ id: item.id, ...item.data() } as UserInterface);
      }
    });
    return collections;
  }

  /**
   * Get user by uid
   *
   * @param id
   */
  async getOne(id: string): Promise<DocumentSnapshot> {
    return this.db.doc(id).get();
  }

  /**
   * Get user by uid
   *
   * @param id
   */
   async getByEmail(email: string) {
    const users =  await this.db.where('email', '==', email.toLowerCase()).get().then(this.mapItem);
    if (users.length > 0) {
      return users[0];
    }
    return null;
  }

  /**
   * Update user data
   *
   * @param id
   * @param data
   */
  async update(id: string, data: UserSchema) {
     return this.db.doc(id).update(data);
  }

  /**
   * Get users by emails
   *
   * @param emails
   * @returns
   */
  async getManyByEmails(emails: string[]) {
    const users = await this.db
      .where('email', 'in', emails)
      .get()
      .then(this.mapItem);
    return users;
  }

  /**
   * Get Users
   *
   * @param none
   * @return user list
   */
  getUsers(usersQuery: UsersQuery){
    const callable = functions.httpsCallable("getUsers");
    return callable({...usersQuery});
  }

  /**
   * Users Aggregation
   *
   * @param none
   * @return summary of users
   */
  usersAggregation(){
    const callable = functions.httpsCallable("usersAggregation");
    return callable({});
  }

  /**
   * Get Profile
   *
   * @param
   * * @return user profile
   */
  getProfile(){
    const callable = functions.httpsCallable("getProfile");
    return callable({});
  }

  /**
   * Sign up and redeem
   *
   * @param
   * * @return user profile
   */
  signUpAndRedeem(payload: SignUpPayload){
    const callable = functions.httpsCallable("signUpAndRedeem");
    return callable(payload);
  }

  /**
   * Upgrade user to pro
   *
   * @param
   * * * @return
   */
  upgradeToPro(payload: UpgradeToProPayload){
    const callable = functions.httpsCallable("upgradeToPro");
    return callable(payload);
  }

  /**
   * Upgrade user to pro
   *
   * @param
   * * * @return
   */
  cancelCodes(codes: string[]){
    const callable = functions.httpsCallable("cancelCodes");
    return callable({codes});
  }

  /**
   * Upgrade user to pro
   *
   * @param
   * * * @return
   */
  insertCodes(codes: string[]){
    const callable = functions.httpsCallable("insertCode");
    return callable({codes});
  }

  /**
   * Get user by uid
   *
   * @param id
   */
  async deleteUser(id: string): Promise<void> {
    await this.db.doc(id).delete();
    const teams = await this.team.where('admin', '==', id).get();
    for (const team of teams.docs) {
      if (team.exists) {
        await this.team.doc(team.id).delete();
      }
    }
  }
}
