import { Control } from '../../Control/Control';
import styles from './AlignmentPanel.module.scss';
import {
  AlignmentBottomIcon,
  AlignmentHorizontalCenterIcon,
  AlignmentLeftIcon,
  AlignmentRightIcon,
  AlignmentTopIcon,
  AlignmentVerticalCenterIcon,
  Pane
} from 'evergreen-ui';
import { Box } from '../../Box/Box';

const AlignmentPanel = () => {
  return (
    <Pane marginTop="0.5rem">
      <Box title="Alignment">
        <Control
          className={styles.alignGroup}
          defaultValue="left"
          options={[
            { label: (<AlignmentLeftIcon/>), value: 'left' },
            { label: (<AlignmentVerticalCenterIcon/>), value: 'vertical' },
            { label: (<AlignmentRightIcon/>), value: 'right' },
            { label: (<AlignmentTopIcon/>), value: 'top' },
            { label: (<AlignmentHorizontalCenterIcon/>), value: 'horizontal' },
            { label: (<AlignmentBottomIcon/>), value: 'bottom' },
          ]}
        />
      </Box>
    </Pane>
  )
}

export default AlignmentPanel;
