import { useEffect } from 'react';
import clsx from 'clsx';
import { Observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import styles from './NotePage.module.scss';
import useStores from 'hooks/useStores';
import NoteEditorContainer from 'components/NoteEditor/NoteEditorContainer';

export default function NoteViewPage() {
  const params = useParams() as any;
  const { noteStore } = useStores();

  const noteId = params.id as string;

  useEffect(() => {
    if (noteId) {
      noteStore.setNoteId(noteId);
    }
  }, [noteId]);

  return (
    <>
      <section className={clsx(styles.mainSection)}>
        <div className={clsx(styles.viewerBody)}>
          <Observer>
            {() => (
              <>
                {noteStore.noteId && (
                  <NoteEditorContainer
                    isPublicView={true}
                    key={noteStore.noteId}
                  />
                )}
              </>
            )}
          </Observer>
        </div>
      </section>
    </>
  );
}
