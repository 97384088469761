import {Dialog} from "../../../components/Dialog";
import {Pane} from "evergreen-ui";
import {useState} from "react";
import {ObjectiveService} from "../../../services/objective/objective.service";

type Props = {
  objectiveId: string,
  isShown: boolean,
  setIsShown: Function,
  successCallback?: Function,
}

const objectiveService = new ObjectiveService();

const DeleteObjectiveModal = ({objectiveId, isShown, setIsShown, successCallback}: Props ) => {
  const [isDeleting, setDeleting] = useState(false);

  const onDeleteObjective = ()  => {
    setDeleting(true);
    objectiveService.delete(objectiveId)
      .then(() => {
        setIsShown(true);
        successCallback && successCallback();
      })
      .finally(() => {
        setDeleting(false);
      })
  }

  return (
    <Dialog
      isShown={isShown}
      title="Delete Objective"
      onCloseComplete={() => setIsShown && setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Delete"
      intent="danger"
      onConfirm={onDeleteObjective}
      isConfirmLoading={isDeleting}
    >
      <Pane>Are you sure to delete objective?</Pane>
    </Dialog>
  )
}

export default DeleteObjectiveModal;
