import {Button, Checkbox, majorScale, minorScale, Pane, PeopleIcon, Popover, Position, Text} from "evergreen-ui";
import styles from "./TaskItem.module.scss";
import {useEffect, useReducer, useRef, useState} from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import {DatePicker} from "antd";
import {isToday} from "../../utils/datetime";
import AssigneesPanel from "./AssigneesPanel";
import _ from "lodash"

import './antd.css';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/button/style/index.css';
import './TaskItem.scss';
import AssigneeAvatar from "./AssigneeAvatar";

type Props = {
  id?: string,
  title?: string,
  description?: string,
  dueDate?: any,
  completeDate?: any,
  isComplete?: boolean,
  assignees?: any,
  onSelect?: Function,
  isSelected?: boolean,
  setTaskInfo?: Function
  project?: string,
  members?: any,
  isViewMode?:boolean,
  dateAble?: boolean,
  saveTask?: Function
}

const TaskItemInput = (task: Props) => {
  const {id, title, description, dueDate, assignees, isComplete, setTaskInfo, project, members, completeDate, dateAble, saveTask} = task;
  const textTitleRef = useRef(null);
  const textDescriptionRef = useRef(null);
  const [today] = useState(new Date());

  const updateState = (state: any, updateState: any) => {
    return {...state, ...updateState};
  }
  const [submitData, dispatchSubmitData] = useReducer(updateState, {
    id, title, description, dueDate, assignees, isComplete, project
  })

  const textareaWithoutScroll = (el: any) => {
    setTimeout(() => {
      const currentEl = el?.current as any;
      if(currentEl){
        currentEl.style.height = currentEl.scrollHeight + "px";
      }
    }, 10);
  }

  useEffect(() => {
    setTaskInfo && setTaskInfo(submitData);
  }, [submitData])

  useEffect(() => {
    setTimeout(() => {
      const currentEl = textTitleRef?.current as any;
      if(currentEl){
        currentEl.style.height = "20px";
      }
    }, 1);
  }, []);

  useEffect(() => {
    textareaWithoutScroll(textTitleRef);
    textareaWithoutScroll(textDescriptionRef);
  }, [textTitleRef]);

  const onTitleChange = (val: string) => {
    dispatchSubmitData({title: val});
    textareaWithoutScroll(textTitleRef);
  }

  const onDescriptionChange = (val: string) => {
    dispatchSubmitData({description: val});
    textareaWithoutScroll(textDescriptionRef);
  }

  const onDueDate = (date: any, dateStr: string) => {
    const dueDate = new Date(date);
    dispatchSubmitData({dueDate: dueDate.getTime()});
  }

  const displayDate = (date: any) => {
    if(isToday(date, today)) return "Today";

    return  dayjs(new Date(date)).format('MMM DD, YYYY');
  }

  const onAssignComplete = (data: any) => {
    dispatchSubmitData({assignees: data});
  }

  const disabledDate = (current : any) => {
    const date = new Date();
    date.setHours(0, 0, 0);
    return current && current < date;
  }

  const onInputKeyUp = (e: any) => {
    if(e.code === "Enter"){
      saveTask && saveTask();
    }
  }

  return (
    <>
      <Pane
        cursor={"pointer"}
        paddingX={majorScale(2)}
        paddingY={majorScale(2)}
        borderRadius="4px"
        border="1px solid #D8DAE5"
        className={clsx(styles.inputTask)}
      >
        <Pane
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Pane display={"flex"} width="100%">
            <Pane>
              <Checkbox
                marginY={majorScale(1)}
                checked={!!submitData.isComplete}
                onChange={(val) => dispatchSubmitData({isComplete: val.target.checked})}
              />
            </Pane>
            <Pane
              display={"flex"}
              alignContent={"center"}
              marginLeft={majorScale(1)}
              width="100%"
              className={clsx(styles.inputText)}
            >
              <input
                placeholder={"New Task"}
                color={"#101840"}
                defaultValue={title}
                className={clsx(styles.descriptionArea)}
                autoFocus={true}
                onChange={(event: any) => onTitleChange(event.target.value)}
                onKeyPress={onInputKeyUp}
                ref={textTitleRef}
              />
            </Pane>
          </Pane>
          <Pane minWidth={"max-content"} display={"flex"}>
            {isComplete &&
              <Text paddingTop={minorScale(1)} fontSize={12} color="#696F8C">
                {"Completed " + displayDate(completeDate)}
              </Text>
            }
            {!isComplete && submitData.dueDate &&
              <Pane display={"flex"} minWidth={"max-content"} marginTop={minorScale(1)} height="32px" marginLeft={majorScale(1)}>
                <Text fontSize={12} color="#696F8C">{displayDate(submitData.dueDate)}</Text>
              </Pane>
            }
            <Pane display={"flex"} minWidth={"max-content"}>
              {!_.isEmpty(assignees) &&
                Object.values(assignees).map((assignee: any) => (
                  <Pane marginLeft={majorScale(1)}>
                    <AssigneeAvatar assignee={assignee} members={members} size={majorScale(3)}/>
                  </Pane>
                ))
              }
            </Pane>
          </Pane>
        </Pane>
        <Pane paddingLeft="24px" marginTop={minorScale(1)}>
          <Pane display={"flex"} alignContent={"center"} className={clsx(styles.inputText)}>
            <textarea
              placeholder={"Notes"}
              className={styles.descriptionArea}
              defaultValue={description}
              onChange={(event: any) => onDescriptionChange(event.target.value)}
              ref={textDescriptionRef}
            />
          </Pane>
          <Pane display={"flex"} paddingTop={majorScale(2)}>
            {dateAble &&
              <Pane width="24px" height="24px">
                <DatePicker className="dueDatePicker" onChange={onDueDate} disabledDate={disabledDate}/>
              </Pane>
            }
            {!_.isEmpty(members) &&
              <Popover
                position={Position.RIGHT}
                content={({ close }) => (
                  <AssigneesPanel
                    members={members}
                    close={close} taskId={id}
                    onAssignComplete={onAssignComplete}
                    assignees={assignees}
                  />
                )}
              >
                <Button
                  size="small"
                  marginLeft={majorScale(1)}
                  color="#3366FF"
                  border={"1px solid #D8DAE5"}
                >
                  <PeopleIcon size={11}/>
                  <Text fontWeight={500} marginLeft={majorScale(1)} color="#3366FF" fontSize="12px">Assign</Text>
                </Button>
              </Popover>
            }
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export default TaskItemInput;
