import {
  ChevronDownIcon,
  ChevronRightIcon,
  DotIcon,
  FolderSharedIcon,
  FolderSharedOpenIcon,
  Menu,
  Pane, PlusIcon,
  ShareIcon
} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import styles from "./ShareFolderItem.module.scss"
import _ from "lodash"
import {useHistory, useLocation} from "react-router-dom";
import ShareChildFolder from "./ShareChildFolder";
import ShareFolderModal from "../Folder/ShareFolderModal";
import CreateFolderModal from "../Folder/CreateFolderModal";
import {getUser, isPROTeam} from "../../utils/store";
import {useDrop} from "react-dnd";
import {DocumentService} from "../../services/document";

type Props = {
  folder: any,
}

const ShareFolderItem = ({folder}: Props) => {
  const hasChildren = !_.isEmpty(folder.children);
  const [isExpand, setIsExpand] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({x: 0, y: 0});
  const [isShowShare, setShowShare] = useState(false);
  const [isShowCreate, setShowCreate] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const editRoles = ["editor"];
  const documentService = new DocumentService();

  const isSelectFolder = (folderId: string) => {
    const isLoadingFolder = location.pathname.includes(folderId);
    return {
      isActive: isLoadingFolder
    }
  }

  const openFolder = (folderId: string) => {
    history.push({
      pathname: '/folders/' + folderId,
      state: { folder: folderId }
    });
  }

  const onClickFolder = (folderClick: any) => {
    const children = (folderClick || {children: []});
    if(!_.isEmpty(children)) setIsExpand(!isExpand)
    openFolder(folderClick.id);
  }

  const onShareClick = () => {
    setShowShare(true);
  }

  const isDocumentOwner = (doc: any) => {
    const user = getUser();
    const isOwner = _.get(doc.roles, user.uid) === "owner";
    return isOwner;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [{ canDrop, isOver }, dropDocRef] = useDrop(() => ({
    accept: ['DOCUMENT'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item: any, monitor) => {
      const hasEditRoles = editRoles.includes(folder.roleOfCurrentUser);
      return item.type === 'DOCUMENT' && hasEditRoles && isDocumentOwner(item.doc);
    },
    drop: async (item: any, monitor)  => {
      if (item.type === 'DOCUMENT' && item.doc) {
        await documentService.updateDocumentFolder(item.doc.id, folder.id);
      }
    }
  }));

  const onClickToCloseRightMenu = (e: any) => {
    setShowContextMenu(false);
    document.removeEventListener("click", onClickToCloseRightMenu);
    document.removeEventListener("contextmenu", onClickToCloseRightMenu);
  }

  const onRightMenu = (e: any) => {
    if(!editRoles.includes(folder.roleOfCurrentUser)) return;
    if(!isPROTeam() && folder.parentFolder) return;

    setAnchorPoint({x: e.pageX, y: e.pageY});
    setShowContextMenu(true);
  }

  useEffect(() => {
    if(showContextMenu){
      document.addEventListener("click", onClickToCloseRightMenu);
      document.addEventListener("contextmenu", onClickToCloseRightMenu);
    }
  }, [showContextMenu])

  return (
    <Pane className={clsx(styles.rootFolder)}>
      <Pane
        className={clsx(styles.folder, styles.folderName, styles.shareFolder,isSelectFolder(folder.id))}
        minWidth={"max-content"}
        onClick={() => onClickFolder(folder)}
        onContextMenu={(e: any) => onRightMenu(e)}
        ref={dropDocRef}
      >
        <span className={clsx(styles.folderIcon, styles.preIcon)}>
          { hasChildren && isExpand &&
            <ChevronDownIcon size={13} color={"#8F95B2"}/>
          }
          { hasChildren && !isExpand &&
            <ChevronRightIcon size={13} color={"#8F95B2"}/>
          }
          { !hasChildren &&
            <DotIcon size={12} color={"#D8DAE5"}/>
          }
        </span>
        <span className={clsx(styles.folderIcon, styles.shareFolderIcon)}>
          { isExpand && hasChildren &&
            <FolderSharedOpenIcon size={16}/>
          }
          { (!isExpand || !hasChildren) &&
            <FolderSharedIcon size={16}/>
          }
        </span>
        <span className={clsx(styles.folderName, styles.folderNameLabel)}>{folder.name}</span>
      </Pane>
      {isExpand &&
        <Pane className={clsx(styles.childrenFolders)}>
          {
            (folder.children || []).map((childFolder: any) => (
              <ShareChildFolder folder={childFolder} parentFolder={folder} key={childFolder.id}/>
            ))
          }
        </Pane>
      }
      {showContextMenu && folder?.id && (
        <Menu>
          <div
            className={styles.shareMenu}
            style={{ top: anchorPoint.y, left: anchorPoint.x }}
          >
            <Menu.Group title={folder.name}>
              {isPROTeam() &&
                <Menu.Item
                  className={styles.menuItem}
                  icon={<ShareIcon color={"#8F95B2"}/>}
                  onClick={() => onShareClick()}
                >
                  <span className={styles.contextItemLabel}>Share Folder</span>
                </Menu.Item>
              }
              {!folder.parentFolder &&
                <Menu.Item
                  className={styles.menuItem}
                  icon={<PlusIcon color={"#8F95B2"}/>}
                  onClick={() => setShowCreate(true)}
                >
                  <span className={styles.contextItemLabel}>Add Folder</span>
                </Menu.Item>
              }
            </Menu.Group>
          </div>
        </Menu>
      )}
      <ShareFolderModal isShown={isShowShare} setIsShown={setShowShare} folder={folder}/>
      <CreateFolderModal isShown={isShowCreate} setIsShown={setShowCreate} parentFolder={folder}/>
    </Pane>
  )
};

export default ShareFolderItem;
