import { db } from 'utils/firebase';
import { DOCUMENT_COLLECTION, FOLDER_COLLECTION } from 'utils/constants';
import { getUser } from 'utils/store';
import {FolderInput, FolderSchema} from 'schemas';
import firebase from 'firebase';
import { sort } from 'fast-sort';
import { UserSchema } from 'schemas/user';
import {UserService} from "../user";
import { EmailService } from 'services/email';

type QuerySnapshot = firebase.firestore.QuerySnapshot;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
export class FolderService {
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  readonly documentdb: any;
  private readonly user: UserSchema;
  private readonly userService: UserService;
  private readonly emailService: EmailService;

  constructor() {
    this.db = db.collection(FOLDER_COLLECTION);
    this.documentdb = db.collection(DOCUMENT_COLLECTION);
    this.user = getUser() || {};
    this.userService = new UserService();
    this.emailService = new EmailService();
  }

  mapItem(querySnapshot: QuerySnapshot) {
    const collections: FolderSchema[] = [];
    querySnapshot.forEach(item => {
      if (item.exists) {
        collections.push({ id: item.id, ...item.data() } as FolderSchema);
      }
    });
    return collections;
  }

  async getOne(id: string): Promise<DocumentSnapshot> {
    return this.db.doc(id).get();
  }

  async create(folder: FolderInput, parentFolder: FolderSchema | undefined) {
    const { uid } = getUser();
    const folders = await this.db
      .where(`roles.${uid}`, '==', 'owner')
      .get()
      .then(this.mapItem);

    let maxOrdinal = 0;
    const folderOrdinals = folders.map(o => o.ordinal || 0);
    if (folderOrdinals && folderOrdinals.length > 0) {
      maxOrdinal = Math.max.apply(Math, folderOrdinals);
    }
    let roles = {} as any;
    if(parentFolder){
      const owner = Object.keys(parentFolder.roles).find(key => parentFolder.roles[key] === "owner") || "";
      roles[owner] = 'owner';
    }
    else
      roles[uid] = 'owner';

    const parent = parentFolder ? db.collection(FOLDER_COLLECTION).doc(parentFolder.id) : '';
    const data = {
      ...folder,
      isDeleted: false,
      isParentDeleted: false,
      ordinal: maxOrdinal + 10,
      parentFolder: parent,
      roles: roles,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const newFolder = await this.db.add(data);
    return newFolder;
  }

  async updateParentFolder(id: string, folder: string) {
    const newDoc = await this.db.doc(id).update({
      parentFolder: folder ? this.db.doc(folder) : '',
      updatedAt: new Date(),
    });
    return newDoc;
  }

  /**
   * Update oridinal folder by drag and drop
   *
   * @param id
   * @param folder
   * @param removeParent
   */
  async updateOrdinalAfterFolder(
    id: string,
    folder: FolderSchema,
    removeParent = false,
  ) {
    const { uid } = this.user;
    const currentFolder = await this.db.doc(id).get();
    const folders = await this.db
      .where(`roles.${uid}`, '==', 'owner')
      .where('isDeleted', '==', false)
      .where('isParentDeleted', '==', false)
      .get()
      .then(this.mapItem);
    const sortedFolder = sort(folders).asc([u => u.ordinal, u => u.createdAt]);

    const targetIndex = sortedFolder.findIndex(function (o) {
      return o.id === folder.id;
    });
    if (targetIndex !== -1) {
      const target = sortedFolder[targetIndex];
      let newOrdinal = target.ordinal + 10;
      if (sortedFolder[targetIndex + 1]) {
        const nextTarget = sortedFolder[targetIndex + 1];
        newOrdinal = (target.ordinal + nextTarget.ordinal) / 2;
      }
      let newParentFolder = currentFolder.data()?.parentFolder;
      if (removeParent) {
        newParentFolder = '';
      }
      if (target.parentFolder) {
        newParentFolder = target.parentFolder;
      }
      await this.db.doc(id).update({
        ordinal: newOrdinal,
        parentFolder: newParentFolder,
        updatedAt: new Date(),
      });
    }
  }

  async updateShareFolderOrdinal(
    id: string,
    folder: FolderSchema,
  ) {
    const folders = await this.db
      .where('parentFolder', '==', folder.parentFolder)
      .where('isDeleted', '==', false)
      .where('isParentDeleted', '==', false)
      .get()
      .then(this.mapItem);

    const sortedFolder = sort(folders).asc([u => u.ordinal, u => u.createdAt]);

    const targetIndex = sortedFolder.findIndex(function (o) {
      return o.id === folder.id;
    });
    if (targetIndex !== -1) {
      const target = sortedFolder[targetIndex];
      let newOrdinal = target.ordinal + 10;
      if (sortedFolder[targetIndex + 1]) {
        const nextTarget = sortedFolder[targetIndex + 1];
        newOrdinal = (target.ordinal + nextTarget.ordinal) / 2;
      }
      this.db.doc(id).update({
        ordinal: newOrdinal,
        updatedAt: new Date(),
      }).catch(ex => {
        console.log("update ordinal error message", ex.message);
      });
    }
  }

  async rename(id: string, name: string) {
    const newFolder = await this.db.doc(id).update({
      name: name,
      updatedAt: new Date(),
    });
    return newFolder;
  }

  async delete(id: string) {
    const folder = this.db.doc(id);
    // const childFolders = await this.db.where({ parentFolder: folder }).get();
    // childFolders.forEach((snapshot: { ref: { delete: () => any; }; }) => snapshot.ref.delete());

    // const documents = await this.documentdb.where({ folder: folder }).get();
    // documents.forEach((snapshot: { ref: { delete: () => any; }; }) => snapshot.ref.delete());

    await folder.delete();
  }

  queryTrash(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    const user = getUser() || {};
    return this.db
      .where('isDeleted', '==', true)
      .where(`roles.${user.uid}`, '==', 'owner')
      .get();
  }

  async softDelete(id: string) {
    const currentFolder = await this.db.doc(id);
    const childFolders = await this.db
      .where('parentFolder', '==', currentFolder)
      .get();
    childFolders.forEach((childFolder: any) => {
      this.db.doc(childFolder.id).update({
        isParentDeleted: true,
      });
    });
    return this.db.doc(id).update({ isDeleted: true });
  }

  async restore(id: string): Promise<void> {
    const currentFolder = await this.db.doc(id);
    const childFolders = await this.db
      .where('parentFolder', '==', currentFolder)
      .get();
    childFolders.forEach((childFolder: any) => {
      this.db.doc(childFolder.id).update({ isDeleted: false, isParentDeleted: false });
    });
    return this.db.doc(id).update({ isDeleted: false });
  }

  deleteOne(id: string): Promise<void> {
    return this.db.doc(id).delete();
  }

  async getTrash(): Promise<FolderSchema[]> {
    return this.queryTrash().then((querySnapshot: QuerySnapshot) => {
      const collections: FolderSchema[] = [];
      querySnapshot.forEach(item => {
        if (item.exists) {
          collections.push({ id: item.id, ...item.data() } as FolderSchema);
        }
      });
      return collections;
    });
  }

  async deleteAll(): Promise<void> {
    const snapshots = await this.db.where('isDeleted', '==', true).get();
    snapshots.forEach((snapshot: { ref: { delete: () => any } }) =>
      snapshot.ref.delete(),
    );

    const childSnapshots = await this.db.where('isParentDeleted', '==', true).get();
    childSnapshots.forEach((snapshot: { ref: { delete: () => any } }) =>
      snapshot.ref.delete(),
    );
  }

  async updateUserRole(id: string, email: string, role: string) {
    const user = getUser() || {};
    const folder = await this.db.doc(id).get();
    const folderData = folder.data() as FolderSchema;
    let roles = folderData.roles;

    const isRemoveShare = role === "remove";
    const inviteUser = await this.userService.getByEmail(email);
    if (isRemoveShare && inviteUser){
      delete roles[inviteUser.id];
    }
    else if (inviteUser?.id) {
      roles[inviteUser.id] = {
        type: role,
        updatedAt: new Date(),
        updatedBy: user.uid,
      };
    }
    await this.db.doc(id).update({
      roles: roles,
      updatedAt: new Date(),
    });
  }

  async addUserRole(id: string, emails: string[], role: string) {
    const user = getUser() || {};
    const folder = await this.db.doc(id).get();
    const docData = folder.data() as FolderSchema;
    let roles = docData.roles;
    const users = await this.userService.getManyByEmails(emails);

    // todo
    // let existEmails = [] as string[];
    // let newEmails = [] as string[];

    if (users.length > 0) {
      const shareUserIds = users.map(item => item.id);
      // todo
      // existEmails = users.map(item => item.email);
      shareUserIds.forEach(shareUid => {
        if (shareUid && shareUid !== user.uid) {
          roles[shareUid] = {
            type: role,
            updatedAt: new Date(),
            updatedBy: user.uid,
            updatedByEmail: user.email || '',
          };
        }
      });
    }

    // todo
    // newEmails = emails.filter(item => !existEmails.includes(item));

    await this.db.doc(id).update({
      roles: roles,
      updatedAt: new Date(),
    });

    // todo
    // if (existEmails.length > 0 || newEmails.length > 0) {
      // Send invite email
      // await this.emailService.sendInvites({
      //   newEmails,
      //   existEmails,
      //   documentId: id,
      //   documentName: docData.name,
      //   role: _.startCase(role)
      // });
    // }
    await this.emailService.sendInviteFolder({
      users: {emails},
      folderName: docData.name,
      role
    });
  }

  async updateTeamRole(folderId: string, teamId: string, role: string) {
    const user = getUser();
    const doc = await this.db.doc(folderId).get();
    const docData = doc.data() as FolderSchema;
    let teamRoles = docData.teamRoles || {};
    const newRole = {
      type: role,
      updatedBy: user.uid,
      updatedByEmail: user.email
    }
    teamRoles = {
      ...teamRoles,
      [teamId]: newRole
    }

    await this.db.doc(folderId).update({
      teamRoles,
      updatedAt: new Date(),
    });

    this.emailService.sendInviteFolder({
      team: {id: teamId},
      folderName: docData.name,
      role
    }).catch(() => {
      console.log("send invite folder fail");
    });
  }
}
