import { useState } from 'react';
import { Pane, Text, Textarea, Button } from 'evergreen-ui';
import styles from './AddComment.module.scss';

export const AddComment = ({ isEditing, onConfirm, onCancel }) => {
  const [visible, setVisible] = useState(isEditing);
  const [input, setInput] = useState('');
  const handleToggle = () => setVisible(!visible);

  const handleRef = (element) => {
    if (element) {
      window.setTimeout(() => element.focus(), 50);
    }
  }

  return (
    <Pane>
      {visible ? (
        <Pane width="100%">
          <Textarea
            ref={handleRef}
            className={styles.textarea}
            onChange={event => setInput(event.target.value)}
            value={input}
            placeholder="Add a comment"
          />
          <Pane className={styles.buttonGroup}>
            <Button
              onClick={() => {
                handleToggle();
                onCancel();
                setInput('');
              }}
            >
              Cancel
            </Button>
            <Button
              className={styles.buttonComment}
              appearance="primary"
              disabled={input.length <= 0}
              onClick={() => {
                handleToggle();
                onConfirm(input);
                setInput('');
              }}
            >
              Comment
            </Button>
          </Pane>
        </Pane>
      ) : (
        <Pane
          className={styles.container}
          onClick={handleToggle}
        >
          <Text className={styles.label}>Add a comment</Text>
        </Pane>
      )}
    </Pane>
  )
}

AddComment.defaultProps = {
  isEditing: false,
  onConfirm: () => {},
  onCancel: () => {},
}
