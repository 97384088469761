import { Dialog } from 'components/Dialog';
import clsx from 'clsx';

import styles from './NoteShortcutModal.module.scss';
import { Button } from 'evergreen-ui';

interface Props {
  isShown: boolean;
  setIsShown: Function;
}

export default function NoteShortcutModal({ isShown, setIsShown }: Props) {

  return (
    <Dialog
      isShown={isShown}
      title="Markdown Shortcuts"
      onCloseComplete={() => {
        setIsShown(false);
      }}
      preventBodyScrolling
      hasCancel={false}
      hasFooter={false}
      width="433px"
    >
      <table className={styles.infoModal}>
        <tbody>
          <tr>
            <td>Heading</td>
            <td>
              <span># H1</span> <br />
              <span>## H2</span> <br />
              <span>### H3</span>
            </td>
          </tr>
          <tr>
            <td>Bold</td>
            <td><span>**bold text**</span></td>
          </tr>
          <tr>
            <td>Italic</td>
            <td><span>*italicized text*</span></td>
          </tr>
          <tr>
            <td>Blockquote</td>
            <td><span>{`>`} block quote</span></td>
          </tr>
          <tr>
            <td>Code</td>
            <td><span>`code`</span></td>
          </tr>
          <tr>
            <td>Ordered List</td>
            <td><span>1. First item</span></td>
          </tr>
          <tr>
            <td>Unordered List</td>
            <td><span>- First item</span></td>
          </tr>
          <tr>
            <td>Horizontal Rule</td>
            <td><span>---</span></td>
          </tr>
        </tbody>
      </table>

      <Button className={clsx('primary-btn', styles.okBtn)} appearance="primary" onClick={() => setIsShown(false)} size="medium">
        OK 
      </Button>
    </Dialog>
  );
}
