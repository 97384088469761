import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';

import {
  Pane,
  Button,
  TimeIcon,
  Popover,
  Position,
  CrossIcon,
  TextInput
}
  from "evergreen-ui";

import styles from "./DuaDate.module.scss";

export default function DuaDate() {
  const [startDate, setStartDate] = useState(new Date());
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const handleFieldChange = (event: any) => {
    if (event.target.name === "date") {
      setDate(event.target.value)
    } else {
      setTime(event.target.value)
    }
  }

  const handleFieldDatePicker = (event: any) => {
    setDate(moment(event).format("MM/DD/YYYY"))
    setTime(moment(event).format("HH:mm"))
    setStartDate(event)
  }

  return (
    <Popover
      content={({ close }) => (
        <Pane className={styles.formDuaDate}>
          <Pane className={styles.formHeader}>
            <Pane className={styles.title}>Due Date</Pane>
            <Pane className={styles.closeIcon}>
              <CrossIcon className={styles.icon} onClick={close} />
            </Pane>
          </Pane>
          <Pane className={styles.bodyDuaDate}>
            <Pane className={styles.formDateTime}>
              <Pane className={styles.date}>
                <Pane className={styles.text}>Date</Pane>
                <TextInput name="date" value={date} onChange={handleFieldChange} placeholder="MM/DD/YYYY" />
              </Pane>
              <Pane className={styles.time}>
                <Pane className={styles.text}>Time</Pane>
                <TextInput name="time" value={time} onChange={handleFieldChange} placeholder="HH:mm" />
              </Pane>
            </Pane>
            <Pane>
              <DatePicker
                selected={startDate}
                onChange={handleFieldDatePicker}
                inline
              />
            </Pane>
          </Pane>
          <Pane className={styles.footerDuaDate}>
            <Button className={styles.btnRemove}>Remove</Button>
            <Button className={styles.btnSave}>Save</Button>
          </Pane>
        </Pane>
      )}
      shouldCloseOnExternalClick={false}
      position={Position.BOTTOM_LEFT}
    >
      <Button marginY={4} iconBefore={TimeIcon}>
        Due Date
      </Button>
    </Popover>
  )
}
