import mx from '../mx';
import { mxUtils } from '../util';

const mxPopupMenuHandler = mx.mxPopupMenuHandler;

/**
 * Function: mouseUp
 *
 * Handles the event by setting the translation on the view or showing the
 * popupmenu.
 */
mxPopupMenuHandler.prototype.mouseUp = function (sender, me) {
  this.checkShowPopupMenu = false;
  if (
    this.popupTrigger &&
    this.inTolerance &&
    this.triggerX != null &&
    this.triggerY != null
  ) {
    var cell = this.getCellForPopupEvent(me);

    // Selects the cell for which the context menu is being displayed
    if (
      this.graph.isEnabled() &&
      this.isSelectOnPopup(me) &&
      cell != null &&
      !this.graph.isCellSelected(cell)
    ) {
      this.graph.setSelectionCell(cell);
    } else if (this.clearSelectionOnBackground && cell == null) {
      this.graph.clearSelection();
    }

    // Hides the tooltip if there is one
    this.graph.tooltipHandler.hide();

    // Menu is shifted by 1 pixel so that the mouse up event
    // is routed via the underlying shape instead of the DIV
    var origin = mxUtils.getScrollOrigin();
    this.popup(
      me.getX() + origin.x + 1,
      me.getY() + origin.y + 1,
      cell,
      me.getEvent(),
    );
    me.consume();
  }

  this.popupTrigger = false;
  this.inTolerance = false;
};

export { mxPopupMenuHandler };
