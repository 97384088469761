import React from 'react'
import { Position, Tooltip } from 'evergreen-ui'

const EltSidebar = (content,showElt) => {
  return (
    <div>
      <Tooltip content={content} position={Position.RIGHT}>
          {showElt}
      </Tooltip>
    </div>
  )
}

EltSidebar.propTypes = {
  content : String,
  showElt:Element
}

export default EltSidebar

