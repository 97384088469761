import React, { useState } from 'react';
import {
    Pane,
    Button,
    PeopleIcon,
    Popover,
    Position,
    CrossIcon,
    Avatar,
    TickIcon
}
    from "evergreen-ui";

import styles from "./Member.module.scss";

const listMembers = [
    {
        id: "1",
        name: "Dan Smith",
        checked: true
    },
    {
        id: "2",
        name: "Newton Hua",
        checked: false
    },
    {
        id: "3",
        name: "Sam Smith",
        checked: false
    },
    {
        id: "4",
        name: "Hieu tran",
        checked: false
    }
]

export default function Member() {
    const [members, setMembers] = useState(listMembers)

    const onChangeCheckedMember = (value: any, index: number) => {
        let mb = [...members]
        mb[index].checked = !value.checked
        setMembers(mb)
    }

    return (
        <Popover
            content={({ close }) => (
                <Pane className={styles.formMember}>
                    <Pane className={styles.formHeader}>
                        <Pane className={styles.title}>Members</Pane>
                        <Pane className={styles.closeIcon}>
                            <CrossIcon className={styles.icon} onClick={close} />
                        </Pane>
                    </Pane>
                    <Pane className={styles.listMember}>
                        {
                            members.map((val, index) => {
                                return (
                                    <Pane className={styles.item} onClick={() => onChangeCheckedMember(val, index)}>
                                        <Pane className={styles.itemInfo}>
                                            <Avatar name={val.name} size={32} marginRight={10} />
                                            <Pane className={styles.titleItem}>
                                                {
                                                    val.name
                                                }
                                            </Pane>
                                        </Pane>
                                        {
                                            val.checked
                                            &&
                                            <Pane className={styles.iconCheck}>
                                                <TickIcon />
                                            </Pane>
                                        }
                                    </Pane>
                                )
                            })
                        }
                    </Pane>
                </Pane>
            )}
            shouldCloseOnExternalClick={false}
            position={Position.BOTTOM_LEFT}
        >
            <Button marginY={4} iconBefore={PeopleIcon}>
                Members
            </Button>
        </Popover>
    )
}
