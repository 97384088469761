import {majorScale, Pane, Text, Tooltip} from "evergreen-ui";
import React from "react";

function TooltipCustom({activator, text, ...props}) {
  return (
    <Tooltip
      showDelay={0}
      hideDelay={0}
      content={(
        <Pane height={majorScale(2)} transform={`translateY(-3px)`}>
          <Text
            className="tooltip"
            color={"white"}
          >{text}</Text>
        </Pane>
      )}
      {...props}
    >
      {activator}
    </Tooltip>
  );
}

export default TooltipCustom;
