import {
  Card,
  Pane,
  Text,
  TrashIcon,
  DocumentIcon,
  Link,
  PeopleIcon,
  Heading,
  Tooltip,
  IconButton,
  PlusIcon,
  Position,
  EyeOpenIcon,
  EyeOffIcon,
} from 'evergreen-ui';
import clsx from 'clsx';
import { Link as ReactRouterLink, useHistory, useLocation } from 'react-router-dom';

import boardStyles from '../Board.module.scss';
import noteStyles from './NoteBoard.module.scss';
import AppFooterBoard from '../App/Footer';
import TagSidebar from 'components/NoteEditor/Tag/TagSidebar';
import { useState } from 'react';
import CreateNotebookModal from 'components/NoteEditor/Modal/CreateNotebookModal';
import NotebookSidebar from 'components/NoteEditor/Notebook/NotebookSidebar';
import { NOTE_TRASH_URL, NOTE_URL, SHARED_NOTE_URL, SIGN_IN_URL } from 'utils/constants';
import SharedNotebookSidebar from 'components/NoteEditor/Notebook/SharedNotebookSidebar';
import { getTagSetting, getUser, isFreeUser, setTagSetting } from 'utils/store';
import ProFeatureModal from 'components/Modals/ProFeatureModal/ProFeatureModal';
import { UpgradeToProModal } from 'components/Modals';

type Props = {
  header: any;
};

const NoteBoard = ({ header }: Props) => {
  const location = useLocation();
  const [isShowCreate, setShowCreate] = useState(false);
  const tagSetting = getTagSetting();
  const [showTag, setShowTag] = useState(tagSetting);
  const history = useHistory();

  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);

  if (!getUser()) {
    history.push({
      pathname: SIGN_IN_URL,
      search: `?refer=${location.pathname}${location.search}`
    });
  }

  const isActivePath = (path: string) => {
    return location.pathname.includes(path);
  };

  const isActiveRoute = (path: string, queryPath?: string) => {
    if (
      isActivePath(path) ||
      (queryPath && location.search.includes(queryPath))
    ) {
      return {
        isActive: true,
      };
    }
    return {
      isActive: false,
    };
  };

  const handleToggleTag = () => {
    const newStatus = !showTag;
    setShowTag(newStatus);
    setTagSetting(newStatus);
  };

  const handleCreateNotebook = () => {
    if(isFreeUser()){
      setShowProFeature(true);
      return;
    }
    setShowCreate(true);
  }

  return (
    <>
      <Card className={clsx(noteStyles.asideContainer, noteStyles.taskBoard)}>
        <Pane className={noteStyles.headerBar}>{header}</Pane>
        <Pane className={noteStyles.topSidebar}>
          <Pane>
            <Pane
              className={clsx(
                noteStyles.mynoteBox,
                noteStyles.sidebarItem,
                isActiveRoute(NOTE_URL, 'ref=mynote'),
              )}
            >
              <Link is={ReactRouterLink} to={NOTE_URL}>
                <Heading>
                  <DocumentIcon color="#52BD94" />
                  <span className={noteStyles.sidebarLabel}>My Notes</span>
                </Heading>
              </Link>

              <Tooltip
                content={
                  <div className={boardStyles.toolTipLabel}>New Notebook</div>
                }
                position={Position.RIGHT}
              >
                <IconButton
                  width={20}
                  className={boardStyles.plusIconBtn}
                  icon={<PlusIcon size={9} />}
                  onClick={handleCreateNotebook}
                />
              </Tooltip>
            </Pane>
            {!isFreeUser() && <NotebookSidebar />}
          </Pane>

          <Pane>
            <Heading
              className={clsx(
                noteStyles.sidebarItem,
                isActiveRoute(SHARED_NOTE_URL, 'ref=share'),
              )}
            >
              <Link is={ReactRouterLink} to={SHARED_NOTE_URL}>
                <PeopleIcon color="#3366FF" />
                <span className={noteStyles.sidebarLabel}>Shared with me</span>
              </Link>
            </Heading>

            <SharedNotebookSidebar />
          </Pane>

          <Heading
            className={clsx(
              noteStyles.sidebarItem,
              isActiveRoute(NOTE_TRASH_URL, 'ref=trash'),
            )}
          >
            <Link is={ReactRouterLink} to={NOTE_TRASH_URL}>
              <TrashIcon color="#D14343" />
              <span className={noteStyles.sidebarLabel}>Trash</span>
            </Link>
          </Heading>
        </Pane>

        <Pane className={boardStyles.bottomSidebar}>
          <AppFooterBoard />
        </Pane>

        <Pane className={noteStyles.tagContainer}>
          <Pane>
            <Text className={clsx(noteStyles.tagHeading)}>TAGS</Text>

            <Tooltip
              content={
                <div className={boardStyles.toolTipLabel}>
                  {showTag ? 'Hide Tags' : 'Show Tags'}
                </div>
              }
              position={Position.RIGHT}
            >
              <IconButton
                width={20}
                className={clsx(boardStyles.plusIconBtn, 'ml-2')}
                icon={
                  showTag ? <EyeOffIcon size={12} /> : <EyeOpenIcon size={12} />
                }
                onClick={handleToggleTag}
              />
            </Tooltip>
          </Pane>
          <Pane className={noteStyles.tagBody}>
            {showTag && <TagSidebar />}
          </Pane>
        </Pane>
      </Card>

      <CreateNotebookModal isShown={isShowCreate} setIsShown={setShowCreate} />
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={'Premium Feature'}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={
          <Pane>
            This feature is not available to Free users. Upgrade to a
            <br />
            paid plan for unlimited access and more!
          </Pane>
        }
      />
      <UpgradeToProModal
        isShown={isShowUpgradeModal}
        setIsShown={setShowUpgradeModal}
      />
    </>
  );
};

export default NoteBoard;
