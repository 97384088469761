import {
  SearchInput,
  Menu,
  Popover,
  Position,
  SortIcon,
  IconButton,
} from 'evergreen-ui';
import useStores from 'hooks/useStores';

import styles from './NoteSearchBox.module.scss';

type Props = {
  keyword: string;
  setKeyword: Function;

  sortBy: string;
  handleSort: Function;
};

export default function SearchBox({
  keyword,
  setKeyword,
  sortBy,
  handleSort,
}: Props) {

  const { noteStore } = useStores();
  const handleSearchNote = (e: any) => {
    setKeyword(e.target.value);
    noteStore.setSearch(e.target.value);
  }

  return (
    <div className={styles.searchBox}>
      <SearchInput
        className={styles.searchInput}
        placeholder="Search all notes"
        value={keyword}
        onChange={handleSearchNote}
        style={{ fill: '#DADADA'}}
      />

      <Popover
        position={Position.BOTTOM_RIGHT}
        content={
          <Menu>
            <Menu.OptionsGroup
              title="Sort By"
              options={[
                { label: 'Name: A-Z', value: 'title:asc' },
                { label: 'Name: Z-A', value: 'title:desc' },
                { label: 'Created: Newest', value: 'createdAt:desc' },
                { label: 'Created: Oldest', value: 'createdAt:asc' },
                { label: 'Modified: Newest', value: 'updatedAt:desc' },
                { label: 'Modified: Oldest', value: 'updatedAt:asc' },
              ]}
              selected={sortBy}
              onChange={selected => handleSort(selected)}
            />
          </Menu>
        }
      >
        <IconButton
          className={styles.sortIcon}
          icon={<SortIcon size={16} />}
          appearance="minimal"
          intent={`minimal` as any}
        />
      </Popover>
      </div>
  );
}
