import { useEffect } from 'react';
import { useState } from 'react';
import { toaster } from 'evergreen-ui';
import { DocumentService } from 'services/document';
import { Modal } from '../Modal/Modal';
import { TrashItem } from 'schemas';
import { FolderService } from 'services/folder';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  trashItem: TrashItem;
  onDelete: Function;
}

const documentService = new DocumentService();
const folderService = new FolderService();

const DeleteDocumentModal = ({ isShown, setIsShown, trashItem, onDelete }: Props) => {
  const [currentItem, setCurrentDocument] = useState(trashItem);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrentDocument(trashItem);
  }, [trashItem]);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      if (isFolderItem(currentItem)) {
        await folderService.deleteOne(currentItem.id);
      } else {
        await documentService.deleteOne(currentItem.id);
      }
      setIsShown(false);
      onDelete(currentItem.id);
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isFolderItem = (doc: TrashItem) => {
    return doc.hasOwnProperty('parentFolder');
  }

  const getTypeDeletion = () => {
    return isFolderItem(currentItem) ? 'folder' : 'document';
  }

  return (
    <Modal
      width={400}
      isShown={isShown}
      title={`Delete ${isFolderItem(currentItem) ? 'Folder' : 'Document'}`}
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Delete"
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
    >
      Are you sure to delete this { getTypeDeletion() }?
      <br/>This action will remove the { getTypeDeletion() } permanently.
    </Modal>
  );
}

export default DeleteDocumentModal;
