import {
  Descendant,
} from 'slate';

export const SHORTCUTS = {
  '*': 'list-item',
  '-': 'list-item',
  '+': 'list-item',
  '>': 'block-quote',
  '#': 'heading-one',
  '##': 'heading-two',
  '###': 'heading-three',
  '####': 'heading-four',
  '#####': 'heading-five',
  '######': 'heading-six',
};

export type STYPE = '*' | '-' | '+' | '>' | '#';

export const initialValue: Descendant[] = [
  {
    type: 'paragraph',
    children: [
      { text: '' },
    ],
  }
]

export const initSelection = {
  "anchor": {
      "path": [
          0,
          0
      ],
      "offset": 0
  },
  "focus": {
      "path": [
          0,
          0
      ],
      "offset": 0
  }
};
