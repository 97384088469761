import firebase from 'firebase';

export interface NoteSchema {
  id: string;
  title: string;
  text: string;
  content: any;
  user: any;
  notebook: any;
  tags: string[];
  roles: any;
  teamRoles: any;
  invites: any;
  isDeleted: boolean;
  updateBy: any;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

export type NoteInput = {
  title?: string;
}

export enum NoteListType {
  MYNOTE = 'mynote',
  SHARE = 'share',
  TRASH = 'trash',
  TAG = 'tag',
}
