import { Avatar, Tooltip, majorScale, Pane, Paragraph } from 'evergreen-ui';
import { useState, useEffect } from 'react';
import { getUserAvatar, getUserDisplayName } from '../../../../utils/helpers';
import { UserService } from '../../../../services/user';
import { UserInterface } from '../../../../schemas';

type Props = {
  activeUsers: any;
};

const userService = new UserService();

const OnlineUsers = ({ activeUsers }: Props) => {
  const [onlineUsers, setOnlineUsers] = useState<UserInterface[]>([]);

  const MAX_DISPLAY_USER = 4;

  const isExceedMaxDisplay = activeUsers.length > MAX_DISPLAY_USER;
  if (isExceedMaxDisplay) {
    const exceedUsers = activeUsers.length - MAX_DISPLAY_USER + 1;
    const lastElement = MAX_DISPLAY_USER - 1;

    activeUsers = activeUsers.slice(0, MAX_DISPLAY_USER);
    activeUsers[lastElement] = `+ ${exceedUsers}`;
  }

  useEffect(() => {
    if (activeUsers?.length > 0) {
      fetchOnlineUsers();
    }
  }, [activeUsers]);

  const fetchOnlineUsers = async () => {
    const sessionUsers = activeUsers.map((item: { email: string; }) => item.email);
    console.log(sessionUsers);
    const users = await userService.getManyByEmails(sessionUsers);
    console.log(users);
    setOnlineUsers(users);
  }

  return (
    <Pane flexDirection={'row-reverse'} display={'flex'}>
      {onlineUsers.map((user: any, index: any) => (
        <Tooltip
          key={user.email}
          content={
            <Paragraph style={{ color: 'white', fontSize: '12px', lineHeight: '16px'}}>
              <span>{getUserDisplayName(user)}</span>
              <span><br /> {user.email}</span>
            </Paragraph>
          }
        >
          <Avatar
            src={getUserAvatar(user)}
            sizeLimitOneCharacter={2}
            name={user.username?.[0] || user.email[0]}
            size={majorScale(3)}
            transform={`translateX(${index * 4}px)`}
          />
        </Tooltip>
      ))}
    </Pane>
  );
};

export default OnlineUsers;
