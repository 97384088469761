import { ColorPaletteContainer } from '../../ColorPalette/ColorPaletteContainer';
import { ColorPalette } from '../../ColorPalette/ColorPalette';
import { Box } from '../../Box/Box';
import { updateSelectionColor } from "../../../../utils/Actions";

import { mxConstants } from 'libs/mxgraph';

const shapeColors = {
  '#D8F636': {
    topColor: '#CAE33E',
    fontColor: '#000000',
  },
  '#9EF1EC': {
    topColor: '#3EDBE2',
    fontColor: '#000000',
  },
  '#FFBDDB': {
    topColor: '#F38DBC',
    fontColor: '#000000',
  },
  '#FECE00': {
    topColor: '#F2C400',
    fontColor: '#000000',
  },
  '#D6E0FF': {
    topColor: '#C2CFF5',
    fontColor: '#000000',
  }
}

const ShapeColorPanel = ({ graph, editorUi, graphState, dispatchGraphState }) => {

  const onSelectColor = (color) => {
    const cell = graph.getSelectionCell();
    graph.getModel().beginUpdate();
    dispatchGraphState({fillColor: color});
    const strokeColor = shapeColors[color] ? shapeColors[color].topColor : '#000000';
    const fontColor = shapeColors[color] ? shapeColors[color].fontColor : '#000000';
    try {
      graph.setCellStyles(mxConstants.STYLE_FONTCOLOR, fontColor, [cell]);
      updateSelectionColor(editorUi, color, strokeColor );
    } finally {
      graph.getModel().endUpdate();
    }
  }

  return (
    <Box title="Shape Color" isDivider>
      <ColorPaletteContainer>
        {Object.keys(shapeColors).map(item => (
          <ColorPalette
            key={item}
            isActive={item === graphState.fillColor}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
      </ColorPaletteContainer>
    </Box>
  )
}

export default ShapeColorPanel;
