import _ from 'lodash';
import useSelection from './useSelection';
import useResize from './useResize';
import { getStyle, getSelectionSize, stylesToMap } from '../utils/GraphUtils'
import { OPTIONS } from '../utils/ComponentPanel';
import { ZwfConstants } from '../utils/ZwfConstants';

function useGraphState(editorUi, graphState, dispatchGraphState) {

  const onSelectionChange = (sender) => {
    const selectionShape = sender.graph.getSelectionCell();
    let state = {};
    _.set(state, "isSelection", !!selectionShape);

    if(!selectionShape) {
      _.set(state, "resetGraphState", true);
      dispatchGraphState(state);

      return ;
    };

    state = {...state, ...(stylesToMap(selectionShape.style) || {})};

    const options = _.get(OPTIONS, getStyle(selectionShape.style, ZwfConstants.STYLE_TYPE), OPTIONS.default);
    const quickSize = {
      zwfSSize: getStyle(selectionShape.style, ZwfConstants.TEXT_SIZE_SMALL) || _.get(options, "quickSize.zwfSSize"),
      zwfMSize: getStyle(selectionShape.style, ZwfConstants.TEXT_SIZE_MEDIUM) || _.get(options, "quickSize.zwfMSize"),
      zwfLSize: getStyle(selectionShape.style, ZwfConstants.TEXT_SIZE_LARGE) || _.get(options, "quickSize.zwfLSize")
    }

    _.set(state, "options", options);
    _.set(state, "size", getSelectionSize(editorUi));
    _.set(state, "quickSize", {...quickSize});
    _.set(state, "resetGraphState", false);
    _.set(state, ZwfConstants.TYPE_LINE, selectionShape.edge);
    _.set(state, ZwfConstants.TYPE_LINE_LABEL, (selectionShape.parent || {edge: false}).edge);
    _.set(state, ZwfConstants.IS_VERTEX, sender.graph.model.isVertex(selectionShape));
    dispatchGraphState(state);
  }

  const onResize = () => {
    dispatchGraphState({size: getSelectionSize(editorUi)});
  }

  useResize(editorUi, onResize);
  useSelection(editorUi, onSelectionChange);

	return true;
}

export default useGraphState;
