//
// Atomic changes
//

/**
 * Class: mxRootChange
 *
 * Action to change the root in a model.
 *
 * Constructor: mxRootChange
 *
 * Constructs a change of the root in the
 * specified model.
 */
export function mxRootChange(model, root) {
  this.model = model;
  this.root = root;
  this.previous = root;
}

/**
 * Function: execute
 *
 * Carries out a change of the root using
 * <mxGraphModel.rootChanged>.
 */
mxRootChange.prototype.execute = function () {
  this.root = this.previous;
  this.previous = this.model.rootChanged(this.previous);
};

/**
 * Class: mxChildChange
 *
 * Action to add or remove a child in a model.
 *
 * Constructor: mxChildChange
 *
 * Constructs a change of a child in the
 * specified model.
 */
export function mxChildChange(model, parent, child, index) {
  this.model = model;
  this.parent = parent;
  this.previous = parent;
  this.child = child;
  this.index = index;
  this.previousIndex = index;
}

/**
 * Function: execute
 *
 * Changes the parent of <child> using
 * <mxGraphModel.parentForCellChanged> and
 * removes or restores the cell's
 * connections.
 */
mxChildChange.prototype.execute = function () {
  if (this.child != null) {
    var tmp = this.model.getParent(this.child);
    var tmp2 = tmp != null ? tmp.getIndex(this.child) : 0;

    if (this.previous == null) {
      this.connect(this.child, false);
    }

    tmp = this.model.parentForCellChanged(
      this.child,
      this.previous,
      this.previousIndex,
    );

    if (this.previous != null) {
      this.connect(this.child, true);
    }

    this.parent = this.previous;
    this.previous = tmp;
    this.index = this.previousIndex;
    this.previousIndex = tmp2;
  }
};

/**
 * Function: disconnect
 *
 * Disconnects the given cell recursively from its
 * terminals and stores the previous terminal in the
 * cell's terminals.
 */
mxChildChange.prototype.connect = function (cell, isConnect) {
  isConnect = isConnect != null ? isConnect : true;

  var source = cell.getTerminal(true);
  var target = cell.getTerminal(false);

  if (source != null) {
    if (isConnect) {
      this.model.terminalForCellChanged(cell, source, true);
    } else {
      this.model.terminalForCellChanged(cell, null, true);
    }
  }

  if (target != null) {
    if (isConnect) {
      this.model.terminalForCellChanged(cell, target, false);
    } else {
      this.model.terminalForCellChanged(cell, null, false);
    }
  }

  cell.setTerminal(source, true);
  cell.setTerminal(target, false);

  var childCount = this.model.getChildCount(cell);

  for (var i = 0; i < childCount; i++) {
    this.connect(this.model.getChildAt(cell, i), isConnect);
  }
};

/**
 * Class: mxTerminalChange
 *
 * Action to change a terminal in a model.
 *
 * Constructor: mxTerminalChange
 *
 * Constructs a change of a terminal in the
 * specified model.
 */
export function mxTerminalChange(model, cell, terminal, source) {
  this.model = model;
  this.cell = cell;
  this.terminal = terminal;
  this.previous = terminal;
  this.source = source;
}

/**
 * Function: execute
 *
 * Changes the terminal of <cell> to <previous> using
 * <mxGraphModel.terminalForCellChanged>.
 */
mxTerminalChange.prototype.execute = function () {
  if (this.cell != null) {
    this.terminal = this.previous;
    this.previous = this.model.terminalForCellChanged(
      this.cell,
      this.previous,
      this.source,
    );
  }
};

/**
 * Class: mxValueChange
 *
 * Action to change a user object in a model.
 *
 * Constructor: mxValueChange
 *
 * Constructs a change of a user object in the
 * specified model.
 */
export function mxValueChange(model, cell, value) {
  this.model = model;
  this.cell = cell;
  this.value = value;
  this.previous = value;
}

/**
 * Function: execute
 *
 * Changes the value of <cell> to <previous> using
 * <mxGraphModel.valueForCellChanged>.
 */
mxValueChange.prototype.execute = function () {
  if (this.cell != null) {
    this.value = this.previous;
    this.previous = this.model.valueForCellChanged(this.cell, this.previous);
  }
};

/**
 * Class: mxStyleChange
 *
 * Action to change a cell's style in a model.
 *
 * Constructor: mxStyleChange
 *
 * Constructs a change of a style in the
 * specified model.
 */
export function mxStyleChange(model, cell, style) {
  this.model = model;
  this.cell = cell;
  this.style = style;
  this.previous = style;
}

/**
 * Function: execute
 *
 * Changes the style of <cell> to <previous> using
 * <mxGraphModel.styleForCellChanged>.
 */
mxStyleChange.prototype.execute = function () {
  if (this.cell != null) {
    this.style = this.previous;
    this.previous = this.model.styleForCellChanged(this.cell, this.previous);
  }
};

/**
 * Class: mxGeometryChange
 *
 * Action to change a cell's geometry in a model.
 *
 * Constructor: mxGeometryChange
 *
 * Constructs a change of a geometry in the
 * specified model.
 */
export function mxGeometryChange(model, cell, geometry) {
  this.model = model;
  this.cell = cell;
  this.geometry = geometry;
  this.previous = geometry;
}

/**
 * Function: execute
 *
 * Changes the geometry of <cell> ro <previous> using
 * <mxGraphModel.geometryForCellChanged>.
 */
mxGeometryChange.prototype.execute = function () {
  if (this.cell != null) {
    this.geometry = this.previous;
    this.previous = this.model.geometryForCellChanged(this.cell, this.previous);
  }
};

/**
 * Class: mxCollapseChange
 *
 * Action to change a cell's collapsed state in a model.
 *
 * Constructor: mxCollapseChange
 *
 * Constructs a change of a collapsed state in the
 * specified model.
 */
export function mxCollapseChange(model, cell, collapsed) {
  this.model = model;
  this.cell = cell;
  this.collapsed = collapsed;
  this.previous = collapsed;
}

/**
 * Function: execute
 *
 * Changes the collapsed state of <cell> to <previous> using
 * <mxGraphModel.collapsedStateForCellChanged>.
 */
mxCollapseChange.prototype.execute = function () {
  if (this.cell != null) {
    this.collapsed = this.previous;
    this.previous = this.model.collapsedStateForCellChanged(
      this.cell,
      this.previous,
    );
  }
};

/**
 * Class: mxVisibleChange
 *
 * Action to change a cell's visible state in a model.
 *
 * Constructor: mxVisibleChange
 *
 * Constructs a change of a visible state in the
 * specified model.
 */
export function mxVisibleChange(model, cell, visible) {
  this.model = model;
  this.cell = cell;
  this.visible = visible;
  this.previous = visible;
}

/**
 * Function: execute
 *
 * Changes the visible state of <cell> to <previous> using
 * <mxGraphModel.visibleStateForCellChanged>.
 */
mxVisibleChange.prototype.execute = function () {
  if (this.cell != null) {
    this.visible = this.previous;
    this.previous = this.model.visibleStateForCellChanged(
      this.cell,
      this.previous,
    );
  }
};

/**
 * Class: mxCellAttributeChange
 *
 * Action to change the attribute of a cell's user object.
 * There is no method on the graph model that uses this
 * action. To use the action, you can use the code shown
 * in the example below.
 *
 * Example:
 *
 * To change the attributeName in the cell's user object
 * to attributeValue, use the following code:
 *
 * (code)
 * model.beginUpdate();
 * try
 * {
 *   var edit = new mxCellAttributeChange(
 *     cell, attributeName, attributeValue);
 *   model.execute(edit);
 * }
 * finally
 * {
 *   model.endUpdate();
 * }
 * (end)
 *
 * Constructor: mxCellAttributeChange
 *
 * Constructs a change of a attribute of the DOM node
 * stored as the value of the given <mxCell>.
 */
export function mxCellAttributeChange(cell, attribute, value) {
  this.cell = cell;
  this.attribute = attribute;
  this.value = value;
  this.previous = value;
}

/**
 * Function: execute
 *
 * Changes the attribute of the cell's user object by
 * using <mxCell.setAttribute>.
 */
mxCellAttributeChange.prototype.execute = function () {
  if (this.cell != null) {
    var tmp = this.cell.getAttribute(this.attribute);

    if (this.previous == null) {
      this.cell.value.removeAttribute(this.attribute);
    } else {
      this.cell.setAttribute(this.attribute, this.previous);
    }

    this.previous = tmp;
  }
};
