import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import {Button, majorScale, Pane, Paragraph, TextInput} from 'evergreen-ui';
import { AuthService } from 'services/authService';
import google from 'assets/img/google.svg';
import { DOCUMENT_URL } from 'utils/constants';
import useQuery from 'hooks/useQuery';
import { useMemo } from 'react';

export const SignInForm = ({ onError }: { onError: Function }) => {
  const history = useHistory();
  const authService = new AuthService();
  const query = useQuery();
  const refer = useMemo(() => {
    return query.get('refer') as string || DOCUMENT_URL;
  }, [query]);

  const handleSignInWithGoogle = async () => {
    try {
      await authService.signInWithGoogle();
      history.push(refer);
    } catch (e: any) {
      onError(e.message);
    }
  }

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email()
        .required(),
      password: Yup
        .string()
        .required(),
    }),
    onSubmit: async ({ email, password }) => {
      try {
        await authService.signInWithEmail(email, password);
        history.push(refer);
      } catch (e: any) {
        onError(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <Pane>
      <form className="mt-45" onSubmit={handleSubmit}>
        <TextInput
          type="email"
          placeholder="Email Address"
          className="w-100"
          isInvalid={!!(touched.email && errors.email)}
          {...getFieldProps('email')}
        />
        <TextInput
          type="password"
          placeholder="Password"
          className="mt-3 w-100"
          isInvalid={!!(touched.password && errors.password)}
          {...getFieldProps('password')}
        />
        <Button
          appearance="primary"
          type="submit"
          className="w-100 mt-3"
          height={majorScale(5)}
          isLoading={isSubmitting}
        >
          Sign In
        </Button>
        <hr className="mt-3 mb-3" style={{ border: '0.5px solid #D8DAE5' }} />
      </form>
      <Button className="w-100" onClick={handleSignInWithGoogle}>
        <img className="mr-2" src={google} alt="google"/>Sign In with Google
      </Button>
      <Paragraph className="mt-4 text-center">
        <Link to="/reset-password" className="text-decoration-none">Forgot Password?</Link>
      </Paragraph>
    </Pane>
  )
}
