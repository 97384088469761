import {Alert, Combobox, Pane} from "evergreen-ui";
import styles from "./Board.module.scss";
import {useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

const MobileAppBoard = () => {
  const [alertMobile, setAlertMobile] = useState(true);

  const history = useHistory();
  const location = useLocation();

  const isActivePath = (path: string) => {
    return location.pathname.includes(path);
  }

  const handleChangeMobileRoute = (selected: any) => {
    if (selected.value) {
      history.push(selected.value);
    }
  }

  return (
    <>
      {alertMobile && (
        <Pane>
          <Alert
            className={styles.mobileAlert}
            intent="none"
            title="Mobile Device Access"
            isRemoveable={true}
            onRemove={() => {
              setAlertMobile(false);
            }}
          >
            Documents cannot be edited on mobile devices. Use a desktop browser to
            access all features.
          </Alert>
        </Pane>
      )}
      <Combobox
        initialSelectedItem={
          isActivePath('/shared-with-me')
            ? { label: 'Shared with me' }
            : { label: 'My Documents' }
        }
        width="100%"
        height={40}
        items={[
          { label: 'My Documents', value: '/app' },
          { label: 'Shared with me', value: '/shared-with-me' },
        ]}
        itemToString={(item) => (item ? item.label : '')}
        onChange={handleChangeMobileRoute}
        className={styles.mobileCombobox}
        size="medium"
      />
    </>
  )
}

export default MobileAppBoard;
