
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function timeFromNow(updatedAt?: any, createdAt?: any) {
  const lastModified = updatedAt || createdAt;
  if (lastModified) {
    return dayjs(lastModified).fromNow();
  }
  return '';
}

export function toDateString(time: string){
  try {
    if(!time) return "";
    const dateFormat = {
      year: 'numeric', month: 'numeric', day: 'numeric'
    } as any;
    const date = new Date(time);

    return date.toLocaleDateString('en-US', dateFormat);
  }
  catch(e) {
    return "";
  }
}

export function isToday(someDateTimestamp: any, today: any){
  try{
    const someDate = new Date(someDateTimestamp);

    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }
  catch (ex){
    return false;
  }
}
