import _ from "lodash"
import { mxUtils } from 'libs/mxgraph';
import { ZwfConstants } from './ZwfConstants';

/**
 * Get current slection shape as string
 * 
 * @param {*} graph 
 * @returns 
 */
const getSelectionShape = (graph) => {
  const selectionCell = graph?.getSelectionCell();
  if (selectionCell) {
    const style = graph.getCellStyle(selectionCell);
    let type = style[ZwfConstants.STYLE_TYPE] || style[ZwfConstants.STYLE_SHAPE] || ''
    if (selectionCell.style?.includes('group;') || selectionCell.style === 'group') {
      type = 'group';
    }
    return mxUtils.camelize(type.split('.').pop().replace('_', ' '));
  }
  return '';
}

const getStyle = (styles, key) => {
  if (styles == null) return null;
  const style = styles.split(';').map(pair => {
    const pairToArr = pair.split("=");
    return {
      key: _.get(pairToArr, 0),
      value: _.get(pairToArr, 1),
    }
  }).find(pair => pair.key === key);

  return style != null ? style.value : null;
};

const stylesToMap = (styles) => {
  if (styles == null) return null;
  const temp = {};
  return  styles.split(';').map(pair => {
    const pairToArr = pair.split("=");
    return {
      key: _.get(pairToArr, 0),
      value: _.get(pairToArr, 1),
    }
  }).reduce((obj, item) => {
    return {
      ...obj,
      [item['key']]: item.value,
    };
  }, temp);;
}

const getSelectionSize = (editorUi) => {
  const graph = editorUi.editor.graph;
  const selectionCell = graph.getSelectionCell();
  if(!selectionCell) return ;

  let geo = editorUi.editor.graph.getCellGeometry(selectionCell);
  return !geo ? {} : {
    width: geo.width,
    height: geo.height,
  }
}

export {
  getStyle,
  getSelectionShape,
  getSelectionSize,
  stylesToMap
}
