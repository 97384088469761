/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Spinner } from 'evergreen-ui';
import { Link, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import styles from './NoteList.module.scss';
import { NoteSchema } from 'schemas';
import useStores from 'hooks/useStores';
import useQuery from 'hooks/useQuery';

type Props = {
  isLoading?: boolean;
  notes: NoteSchema[];
  type?: string;
};

export default function NoteList({ isLoading = false, notes, type }: Props) {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const { noteStore } = useStores();
  const search = query.get('search') as string;
  
  useEffect(() => {
    noteStore.setSearch(search);
  }, [search])
  

  const isActivePath = (path: string) => {
    return location.pathname.includes(path) || location.search.includes(path);
  };

  const isActiveRoute = (path: string) => {
    if (isActivePath(path)) {
      return {
        isActive: true,
      };
    }
    return {
      isActive: false,
    };
  };

  const onSetNote = (noteId: string) => {
    history.push({
      pathname: location.pathname,
      search: `?noteId=${noteId}&search=${noteStore.search || ''}`
    })
    noteStore.setNoteId(noteId);
  }

  return (
    <div className={clsx(styles.list, isLoading && 'isLoading')}>
      {isLoading && <Spinner />}
      <ul>
        {notes?.map(note => (
          <li key={note.id} className={clsx(isActiveRoute(note.id))} onClick={() => onSetNote(note.id)}>
            <span>
            {_.truncate(note.title, { length: 40, separator: '...'}) || 'New Note'}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
