import React, {Dispatch, SyntheticEvent, useState, SetStateAction, useEffect} from 'react';
import {TextInputField} from 'evergreen-ui';
import { Modal } from '../Modal/Modal';
import {setCellLink} from "../../Editor/utils/Actions";

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  editorUi: any;
}


const InsertLinkModal = ({ isShown, setIsShown, editorUi }: Props) => {
  const [link, setLink] = useState("");
  const handleConfirm = () => {
    setCellLink(editorUi ,link);
    setIsShown(false)
  }

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLTextAreaElement;
    const value = target.value;
    setLink(value);
  }

  const onKeyPress = (evt: any) => {
    if (evt.code === "Enter" || evt.code === "NumpadEnter") {
      handleConfirm();
    }
  }

  useEffect(() => {
    if(isShown) {
      const graph = editorUi.current.editor.graph;
      const cell = graph.getSelectionCell();
      if(cell){
        const linkText = graph.getLinkForCell(cell);
        setLink(linkText);
      }
    }
  }, [isShown]);

  return (
    <>
      <Modal
        width={376}
        isShown={isShown}
        title="Insert Link"
        onCloseComplete={() => setIsShown(false)}
        onConfirm={handleConfirm}
        minHeightContent={0}
        confirmLabel="Save"
      >
        <TextInputField
          name="insert-link"
          placeholder="Type your URL here"
          value={link}
          onKeyPress={onKeyPress}
          onChange={handleChange} margin={0}
        />
      </Modal>
    </>
  );
}

export default InsertLinkModal;
