import { Pane } from "evergreen-ui";
import React, { useState, useEffect } from "react";
import styles from "./Sounds.module.scss";

import { SOUNDS_DEFINITION } from "./SoundsDefinition";
import { SOUNDS_CATEGORIES } from "./SoundsCategories";
import SoundsCard from "./SoundsCard";
import ReactHowler from 'react-howler';
//Recoil
import { useRecoilValue } from 'recoil';
import { actionSound, activeCategorySound } from '../../recoil/atom';
import { isFreeUser } from 'utils/store';

import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const title = "Free White Noise & Background Sounds | Zen Sounds"
const meta = "Curated library of free white noise, rain noises, focus music, calming music, nature background. Loved by +1.6M users. Start listening free"

const Sounds = () => {
  const location = useLocation();

  const [isPuclic, setIsPuclic] = useState(false);

  const soundActive = useRecoilValue(actionSound)
  const categorySoundActive = useRecoilValue(activeCategorySound)

  useEffect(() => {
    let pathName = location.pathname.split('/')[1]
    if (pathName === 'zen-sound' || pathName === 'zen-sounds') {
      setIsPuclic(true)
    }
  }, [])

  return (
    <Pane padding={"0px"} className={styles.mainPage} >
      {
        isPuclic
        &&
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={meta} />
        </Helmet>
      }
      <Pane className={styles.sounds}>
        {
          categorySoundActive === 'All'
            ?
            SOUNDS_DEFINITION.map((val, index) => (
              <SoundsCard cardInfo={val} key={index} checkUser={isFreeUser()} isPuclic={isPuclic} />
            ))
            :
            SOUNDS_CATEGORIES.find((v) => v.name === categorySoundActive)?.data.map((val, index) => (
              <SoundsCard cardInfo={val} key={index} checkUser={isFreeUser()} isPuclic={isPuclic} />
            ))
        }
      </Pane>
      <ReactHowler
        src={[soundActive.sound]}
        playing={soundActive.playing}
        volume={soundActive.volume}
        loop={true}
      />
      {
        isPuclic
        &&
        <Pane>
          <Pane className={styles.titleBottom}><Pane display="inline" fontWeight="bold">Zen Sounds</Pane> is a free online white noise app to help you focus, calm, meditate, and sleep. Our free sound library include rain noises, coffee shop, waves, focus music, nature background sounds, calming & meditation music, etc.</Pane>
          <Pane className={styles.titleBottom2}>Looking for more sounds? Send us your sound requests to <Pane display="inline" fontWeight="bold">help@zenflowchart.com</Pane></Pane>
        </Pane>
      }
    </Pane>
  )
}

export default Sounds;
