import {Button, majorScale, Pane} from "evergreen-ui";
import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {usePageStatic} from "../../services/page/page.helper";
import PageHeader from "./PageHeader";
import Objective from "./Objective";
import {useObjectives} from "../../services/objective/objective.helper";
import {ObjectiveService} from "../../services/objective/objective.service";
import {useDrop} from "react-dnd";
import _ from "lodash";
import Expand from "react-expand-animated";
import clsx from "clsx";
import styles from "./Page.module.scss";

const objectiveService = new ObjectiveService();

const OkrPage = () => {
  const params = useParams() as any;
  const [pageId, setPageId] = useState(params.id);
  const {page} = usePageStatic(pageId);
  const {objectives} = useObjectives({pageId});
  const [focusObjective, setFocusObjective] = useState("");
  const [resultSelected, setResultSelected] = useState(0);
  const latestData = useRef({} as any);
  const [showArchive, setShowArchive] = useState(false);

  useEffect(() => {
    setPageId(params.id);
  }, [params]);

  useEffect(() => {
    latestData.current = {...latestData.current, resultSelected, objectives};
  }, [objectives, resultSelected]);

  const onDeleteResult = (event: any) => {
    const deleteKeyCode = 8;
    if(event.keyCode !== deleteKeyCode) return;

    const selectedResultId = latestData.current.resultSelected;
    const latestObjectives = latestData.current.objectives;

    const updateObjective = (latestObjectives || []).find((objective: any) => {
      return (objective.results || []).some((result: any) => result.id === selectedResultId);
    });

    if(!updateObjective) return;

    objectiveService.partialUpdate({
      id: updateObjective.id,
      results: (updateObjective.results || []).filter((result: any) => result.id !== selectedResultId)
    });
  }

  useEffect(() => {
    document.addEventListener('keyup', onDeleteResult, true);
    return () => {
      document.removeEventListener('keyup', onDeleteResult, true);
    };
  }, []);

  const [{ isDropObjectiveOver }, dropObjectiveRef] = useDrop(() => ({
    accept: ["OBJECTIVE"],
    collect: (monitor) => ({
      isDropObjectiveOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "OBJECTIVE"
    }),
    canDrop: (item: any, monitor) => {
      return !item.objective.archived;
    },
    drop: async (item: any, monitor)  => {
      // objective.onDropObjective(item.objective, objective);
      onDropObjective(item.objective, null);
    }
  }), []);

  const updateOrdinal = (id: string, ordinal: number) => {
    objectiveService.partialUpdate({id, ordinal})
  }

  const onDropObjective = (drag: any, drop: any) => {
    const isDragToFirst = !drop;
    if(isDragToFirst) {
      updateOrdinal(drag.id, (new Date()).getTime());
      return;
    }
    const dropIndex = objectives.findIndex((objective) => objective.id === drop.id);
    const nextObjective = _.get(objectives, dropIndex + 1);
    if(nextObjective){
      const newOrdinal = Math.round((nextObjective.ordinal + drop.ordinal)/2);
      updateOrdinal(drag.id, newOrdinal);
      console.log("newOrdinal ", newOrdinal);
    }

    const isDragToEnd = !nextObjective;
    if(isDragToEnd){
      updateOrdinal(drag.id, drop.ordinal - 10000);
    }
  }

  return (
   <>
     {pageId &&
       <Pane paddingY={majorScale(6)} paddingX={majorScale(17)}>
         <Pane ref={dropObjectiveRef}>
           <PageHeader
             page={page}
             canManagePage={true}
             setFocusObjective={setFocusObjective}
           />

           <Expand
             duration={200}
             open={isDropObjectiveOver}
             transitions={["height", "opacity"]}
           >
             <Pane className={clsx(isDropObjectiveOver && styles.dropObjectiveOver)}/>
           </Expand>
         </Pane>
         {objectives.filter(objective => !objective.archived)
           .map(objective => (
           <Pane>
             <Objective
               key={objective.id}
               {...objective}
               page={page}
               focusObjective={focusObjective}
               resultSelected={resultSelected}
               setFocusObjective={setFocusObjective}
               setResultSelected={setResultSelected}
               onDropObjective={onDropObjective}
             />
           </Pane>
         ))}
         <Pane>
           {!_.isEmpty(objectives.filter(objective => !!objective.archived)) &&
             <Button onClick={() => setShowArchive(!showArchive)}>
               {`${showArchive ? 'Hide' : 'Show'} Archived Objectives`}
             </Button>
           }
         </Pane>
         {showArchive &&
           <Pane paddingTop={majorScale(3)}>
             {objectives.filter(objective => !!objective.archived)
               .map(objective => (
                 <Pane>
                   <Objective
                     key={objective.id}
                     {...objective}
                     page={page}
                     focusObjective={focusObjective}
                     setFocusObjective={setFocusObjective}
                     resultSelected={resultSelected}
                     setResultSelected={setResultSelected}
                   />
                 </Pane>
               ))}
           </Pane>
         }
       </Pane>
     }
   </>
  )
}

export default OkrPage;
