import { useState } from 'react';
import { Avatar as Preview, Pane, Heading, majorScale, toaster } from 'evergreen-ui';
import Avatar from 'react-avatar-edit';
import { Modal } from '../Modal/Modal';
import { getUser, updateUser } from 'utils/store';
import { getUserName } from 'utils/helpers';
import { UserService } from '../../../services/user';

interface Params {
  modal: boolean,
  setModal: Function,
}

const userService = new UserService();

const ChangeAvatarModal = ({ modal, setModal }: Params) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState((getUser()).photoUrl);
  const name = getUserName();

  const handleCrop = (preview: string) => {
    setPreview(preview);
  }

  const handleClose = () => {
    setPreview((getUser()).photoUrl || '');
  }

  const handleClickSave = async () => {
    try {
      setLoading(true);
      await userService.update((getUser().uid), { photoUrl: preview });
      updateUser({ photoUrl: preview });
      setModal(false);
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      width={433}
      isShown={modal}
      hasCancel={false}
      title="Change Avatar"
      confirmLabel="Save Avatar"
      onCloseComplete={() => setModal(false)}
      onConfirm={handleClickSave}
      isConfirmLoading={loading}
    >
      <Pane display="flex">
        <Pane marginRight="1.5rem">
          <Avatar
            width={200}
            height={150}
            label="Select a file"
            borderStyle={{
              borderStyle: 'dashed',
              borderWidth: '1px',
              borderColor: '#8F95B2',
              textAlign: 'center',
            }}
            labelStyle={{
              fontSize: '14px',
              color: '#101840',
            }}
            onCrop={handleCrop}
            onClose={handleClose}
          />
        </Pane>
        <Pane>
          <Heading fontWeight="500" fontSize="14px">Preview</Heading>
          <Preview
            marginTop="0.5rem"
            src={preview}
            name={name}
            size={majorScale(6)}
          />
        </Pane>
      </Pane>
    </Modal>
  );
}

export default ChangeAvatarModal;
