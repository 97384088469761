import {Avatar, Paragraph, Position, Tooltip} from "evergreen-ui";
import _ from "lodash"

type Props = {
  assignee: any,
  members: any,
  size: number
}

const AssigneeAvatar = ({assignee, members, size}: Props) => {

  const formatDisplayName = (id: any) => {
    const data = _.get(members, id, assignee || {});
    return [data.firstName, data.lastName].filter(item => !!item).join(' ') || data.email;
  }

  const formatName = (id: any) => {
    const data = _.get(members, id, assignee || {});
    return [data.firstName, data.lastName].filter(item => !!item).join(' ');
  }

  const getAvatar = (id: any) => {
    const data = _.get(members, id, assignee || {});
    return data.photoUrl || data.googleAvatar;
  }

  return (
    <>
      <Tooltip
        content={(
          <Paragraph
            style={{
              color: 'white',
              fontSize: '12px',
              lineHeight: '16px'
            }}
            className="tooltip-on-dialog"
          >
            {formatName(assignee.id) ? formatName(assignee.id) : assignee.email}
          </Paragraph>
        )}
        hideDelay={0}
        position={Position.BOTTOM}
      >
        <Avatar
          sizeLimitOneCharacter={2}
          name={formatDisplayName(assignee.id)}
          size={size}
          src={getAvatar(assignee.id)}
          alignSelf={"center"}
        />
      </Tooltip>
    </>
  )
}

export default AssigneeAvatar;
