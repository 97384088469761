// Images
import STREAM_WATER from "../../assets/img/sounds/101-stream-water.jpeg";
import LIGHT_RAIN from "../../assets/img/sounds/102-light-rain.jpeg";
import COFFEE_SHOP from "../../assets/img/sounds/103-coffee-shop.jpeg";
import FOCUS_RENEW from "../../assets/img/sounds/104-focus-renew.jpeg";
import BIRDS_CHIRPING from "../../assets/img/sounds/105-birds-chirping.jpeg";
import DOWNPOUR from "../../assets/img/sounds/106-downpour.jpeg";
import FIREPLACE from "../../assets/img/sounds/107-fireplace.jpeg";
import FOCUS_BETAWAVES from "../../assets/img/sounds/108-focus-betawaves.jpeg";
import FOCUS_DELTAWAVES from "../../assets/img/sounds/109-focus-deltawaves.jpeg";
import FOCUS_HOPEFUL from "../../assets/img/sounds/110-focus-hopeful.jpeg";
import FOCUS_THETA_WAVES from "../../assets/img/sounds/111-focus-theta-waves.jpeg";
import FOREST from "../../assets/img/sounds/112-forest.jpeg";
import JUNGLE from "../../assets/img/sounds/113-jungle.jpeg";
import LAKE_WAVES from "../../assets/img/sounds/114-lake-waves.jpeg";
import LOWFI_NYC from "../../assets/img/sounds/115-lowfi-nyc.jpeg";
import LOWFI_SAIGON from "../../assets/img/sounds/116-lowfi-saigon.jpeg";
import CLEAR_SKY from "../../assets/img/sounds/117-meditate-clear-sky.jpeg";
import DAYDREAM from "../../assets/img/sounds/118-meditate-daydream.jpeg";
import OCEAN_WAVES from "../../assets/img/sounds/119-ocean-waves.jpeg";
import RAINDROPS from "../../assets/img/sounds/120-raindrops.jpeg";
import RESTAURANT_CROWD from "../../assets/img/sounds/121-restaurant-crowd.jpeg";
import RIVER_FOREST from "../../assets/img/sounds/122-river-forest.jpeg";
import SLEEP_FLOAT_AWAY from "../../assets/img/sounds/123-sleep-float-away.jpeg";
import WIND_CHIME from "../../assets/img/sounds/124-wind-chime.jpeg";
import WINDSTORM from "../../assets/img/sounds/125-windstorm.jpeg";
import CRICKETS from "../../assets/img/sounds/crickets.jpeg";
import LIGHT_WIND from "../../assets/img/sounds/light-wind.jpeg";
import UNDERWATER from "../../assets/img/sounds/underwater.jpeg";
import TRAIN from "../../assets/img/sounds/train.jpeg";
import CHOPIN_PIANO from "../../assets/img/sounds/chopin-piano-raindrops.jpeg";
import BACH_PRELUDE from "../../assets/img/sounds/bach-prelude-piano.jpeg";
import OUTERSPACE from "../../assets/img/sounds/outerspace.jpeg";
import SLEEP_SOUNDSCAPE from "../../assets/img/sounds/sleep-soundscape.jpeg";

export const SOUNDS_DEFINITION = [
  {
    id: 1,
    name: "Stream Water",
    image: STREAM_WATER,
    sound: `/sounds/StreamWater.mp3`,
    pro: false
  },
  {
    id: 2,
    name: "Light Rain",
    image: LIGHT_RAIN,
    sound: '/sounds/LightRain.mp3',
    pro: false
  },
  {
    id: 3,
    name: "Coffee Shop",
    image: COFFEE_SHOP,
    sound: '/sounds/CoffeeShop.mp3',
    pro: false
  },
  {
    id: 4,
    name: "Focus Renew",
    image: FOCUS_RENEW,
    sound: '/sounds/FocusRenew.mp3',
    pro: false
  },
  {
    id: 5,
    name: "Birds Chirping",
    image: BIRDS_CHIRPING,
    sound: '/sounds/BirdsChirping.mp3',
    pro: false
  },
  {
    id: 6,
    name: "Fireplace",
    image: FIREPLACE,
    sound: '/sounds/Fireplace.mp3',
    pro: false
  },
  {
    id: 7,
    name: "Downpour",
    image: DOWNPOUR,
    sound: '/sounds/RainDownpour.mp3',
    pro: false
  },
  {
    id: 8,
    name: "Focus Betawaves",
    image: FOCUS_BETAWAVES,
    sound: '/sounds/FocusBetawaves.mp3',
    pro: false
  },
  {
    id: 9,
    name: "Focus Deltawaves",
    image: FOCUS_DELTAWAVES,
    sound: '/sounds/FocusDeltaWaves.mp3',
    pro: false
  },
  {
    id: 10,
    name: "Focus Hopeful",
    image: FOCUS_HOPEFUL,
    sound: '/sounds/Focus_Hopeful.mp3',
    pro: true
  },
  {
    id: 11,
    name: "Focus Thetawaves",
    image: FOCUS_THETA_WAVES,
    sound: '/sounds/FocusThetaWaves.mp3',
    pro: true
  },
  {
    id: 12,
    name: "Forest",
    image: FOREST,
    sound: '/sounds/Forest.mp3',
    pro: false
  },
  {
    id: 13,
    name: "Jungle",
    image: JUNGLE,
    sound: '/sounds/Jungle.mp3',
    pro: true
  },
  {
    id: 14,
    name: "Lake Waves",
    image: LAKE_WAVES,
    sound: '/sounds/LakeWaves.mp3',
    pro: false
  },
  {
    id: 15,
    name: "Lowfi NYC",
    image: LOWFI_NYC,
    sound: '/sounds/Lowfi_NYC.mp3',
    pro: false
  },
  {
    id: 16,
    name: "Lowfi Saigon",
    image: LOWFI_SAIGON,
    sound: '/sounds/LowfiSaigon.mp3',
    pro: true
  },
  {
    id: 17,
    name: "Meditate Clear Sky",
    image: CLEAR_SKY,
    sound: '/sounds/MeditateClearSky.mp3',
    pro: true
  },
  {
    id: 18,
    name: "Meditate Daydream",
    image: DAYDREAM,
    sound: '/sounds/MeditateDaydream.mp3',
    pro: false
  },
  {
    id: 19,
    name: "Ocean Waves",
    image: OCEAN_WAVES,
    sound: '/sounds/OceanWaves.mp3',
    pro: true
  },
  {
    id: 20,
    name: "Raindrops",
    image: RAINDROPS,
    sound: '/sounds/RainDrops.mp3',
    pro: true
  },
  {
    id: 21,
    name: "Restaurant Crowd",
    image: RESTAURANT_CROWD,
    sound: '/sounds/RestaurantCrowd.mp3',
    pro: false
  },
  {
    id: 22,
    name: "River Forest",
    image: RIVER_FOREST,
    sound: '/sounds/RiverForest.mp3',
    pro: false
  },
  {
    id: 23,
    name: "Sleep Float Away",
    image: SLEEP_FLOAT_AWAY,
    sound: '/sounds/SleepFloatAway.mp3',
    pro: false
  },
  {
    id: 24,
    name: "Wind Chime",
    image: WIND_CHIME,
    sound: '/sounds/MeditateWindChimes.mp3',
    pro: true
  },
  {
    id: 25,
    name: "Wind Storm",
    image: WINDSTORM,
    sound: '/sounds/WindStorm.mp3',
    pro: false
  },
  {
    id: 26,
    name: "Crickets",
    image: CRICKETS,
    sound: '/sounds/Crickets.mp3',
    pro: false
  },
  {
    id: 27,
    name: "Light Wind",
    image: LIGHT_WIND,
    sound: '/sounds/Light-Wind.mp3',
    pro: false
  },
  {
    id: 28,
    name: "Underwater",
    image: UNDERWATER,
    sound: '/sounds/Underwater.mp3',
    pro: true
  },
  {
    id: 29,
    name: "Train",
    image: TRAIN,
    sound: '/sounds/Train.mp3',
    pro: false
  },
  {
    id: 30,
    name: "Chopin Piano Raindrops",
    image: CHOPIN_PIANO,
    sound: '/sounds/Chopin-Piano-Raindrops.mp3',
    pro: false
  },
  {
    id: 31,
    name: "Bach Prelude Piano",
    image: BACH_PRELUDE,
    sound: '/sounds/Bach-Prelude-Piano.mp3',
    pro: false
  },
  {
    id: 32,
    name: "Outerspace",
    image: OUTERSPACE,
    sound: '/sounds/Outer-Space.mp3',
    pro: true
  },
  {
    id: 33,
    name: "Sleep Soundscape",
    image: SLEEP_SOUNDSCAPE,
    sound: '/sounds/Sleep-Soundscape.mp3',
    pro: false
  }
];
