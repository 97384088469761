import {
  Button,
  majorScale,
  HistoryIcon,
  Pane,
  TrashIcon,
} from 'evergreen-ui';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './NoteMenubar.module.scss';
import useStores from 'hooks/useStores';
import { NoteService } from 'services/note';
import DeleteNoteModal from '../Modal/DeleteNoteModal';
import { NOTE_TRASH_URL } from 'utils/constants';

const noteService = new NoteService();

export default function NoteTrashMenubar() {
  const [isShowDelete, setShowDelete] = useState(false);

  const { noteStore } = useStores();
  const history = useHistory();

  const handleRestoreNote = async () => {
    if (noteStore.note?.id) {
      await noteService.restore(noteStore.note?.id);
      history.push(NOTE_TRASH_URL);
    }
  };

  return (
    <>
      <Pane className={styles.noteMenubar}>
        <Button
          height={majorScale(3)}
          className="border-lighten-gray mr-2"
          onClick={() => setShowDelete(true)}
        >
          <TrashIcon size={12} color="#D14343" className="mr-2" />
          Delete Note
        </Button>

        <Button
          height={majorScale(3)}
          appearance="primary"
          onClick={handleRestoreNote}
        >
          <HistoryIcon size={12} className="mr-2" />
          Restore Note
        </Button>
      </Pane>

      <DeleteNoteModal isShown={isShowDelete} setIsShown={setShowDelete} />
    </>
  );
}
