import mx from '../mx';
import { mxConstants } from '../util/mxConstants';

const mxPerimeter = mx.mxPerimeter;

export const mxStylesheet = mx.mxStylesheet;

/**
 * Function: createDefaultVertexStyle
 *
 * Creates and returns the default vertex style.
 */
mxStylesheet.prototype.createDefaultVertexStyle = function () {
  const style = {};

  style[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
  style[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
  style[mxConstants.STYLE_VERTICAL_ALIGN] = mxConstants.ALIGN_MIDDLE;
  style[mxConstants.STYLE_ALIGN] = mxConstants.ALIGN_CENTER;
  style[mxConstants.STYLE_FILLCOLOR] = mxConstants.DEFAULT_STYLE_FILLCOLOR;
  style[mxConstants.STYLE_STROKECOLOR] = mxConstants.DEFAULT_STYLE_STROKECOLOR;
  style[mxConstants.STYLE_FONTCOLOR] = mxConstants.DEFAULT_STYLE_FONTCOLOR;

  return style;
};
