import React, {useEffect, useState} from 'react';
import {Image, majorScale, Menu, Pane, Popover, Position, Text} from 'evergreen-ui';
import LineLeft from "../../../../../../assets/img/left.svg";
import LineRight from "../../../../../../assets/img/right.svg";
import LineBidirectional from "../../../../../../assets/img/bidirection.svg";
import LineNormal from "../../../../../../assets/img/normal_line.svg";
import LineDash from "../../../../../../assets/img/dash_line.svg";
import CaretButton from "../../../../../../assets/img/caret.svg";
import { ZwfConstants } from "../../../../utils/ZwfConstants";
import {Box} from "../../Box/Box";
import _ from "lodash"
import styles from "./LinePanel.module.scss"

const LinePanel = ({
graph,
graphState,
}) => {
  const LINE_STYLE = {
    RIGHT: "right",
    LEFT: "left",
    BI_DIRECTION: "bi_direction",
    NORMAL: "normal",
    DASH: "dash",
  }
  const LINE_CORNER = {
    SHARP: "sharp",
    ROUNDED: "rounded",
    CURVED: "curved"
  }
  const LINE_THICKNESS = [0.5, 1, 1.5, 2, 3, 4, 5, 6];
  const [lineStyle, setLineStyle] = useState(LINE_STYLE.BI_DIRECTION);
  const [lineCorner, setLineCorner] = useState(LINE_CORNER.SHARP);
  const [lineThickness, setLineThickness] = useState(1);

  useEffect(() =>{
    const initialLineStyle = () => {
      const startArrow = _.get(graphState, ZwfConstants.STYLE_STARTARROW);
      const endArrow = _.get(graphState, ZwfConstants.STYLE_ENDARROW);
      const dash = _.get(graphState, ZwfConstants.STYLE_DASHED);
      const strokeWidth = _.get(graphState, ZwfConstants.STYLE_STROKEWIDTH);
      const isLineThickness = _.get(graphState, ZwfConstants.IS_LINE_THICKNESS);

      if (dash === '1') setLineStyle(LINE_STYLE.DASH)
      else if(startArrow === 'classic' && endArrow === 'classic') setLineStyle(LINE_STYLE.BI_DIRECTION)
      else if(startArrow === 'classic' && endArrow !== 'classic') setLineStyle(LINE_STYLE.LEFT)
      else if(startArrow !== 'classic' && endArrow === 'classic') setLineStyle(LINE_STYLE.RIGHT)
      else if(startArrow !== 'classic' && endArrow !== 'classic') setLineStyle(LINE_STYLE.NORMAL)

      if (isLineThickness) setLineThickness(strokeWidth);
    }

    const initialLineCorner = () => {
      const isRounded = _.get(graphState, ZwfConstants.LINE_ROUNDED) === "1";
      const isCurve = _.get(graphState, ZwfConstants.LINE_CURVED) === "1";

      if(isCurve) setLineCorner(LINE_CORNER.CURVED)
      else if(isRounded) setLineCorner(LINE_CORNER.ROUNDED)
      else setLineCorner(LINE_CORNER.SHARP)
    }

    initialLineStyle();
    initialLineCorner();
  }, [graphState]);

  const handleLineStyleSelect = (option) => {
    const cell = graph.getSelectionCell();
    graph.getModel().beginUpdate();
    try {
      setLineStyle(option)
      switch (option){
        case LINE_STYLE.RIGHT:
          graph.setCellStyles(ZwfConstants.STYLE_STARTARROW, "none", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_ENDARROW, "classic", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_DASHED, 0, [cell]);
          break;
        case LINE_STYLE.LEFT:
          graph.setCellStyles(ZwfConstants.STYLE_STARTARROW, "classic", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_ENDARROW, "none", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_DASHED, 0, [cell]);
          break;
        case LINE_STYLE.BI_DIRECTION:
          graph.setCellStyles(ZwfConstants.STYLE_STARTARROW, "classic", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_ENDARROW, "classic", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_DASHED, 0, [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_DASHED, 0, [cell]);
          break;
        case LINE_STYLE.NORMAL:
          graph.setCellStyles(ZwfConstants.STYLE_STARTARROW, "none", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_ENDARROW, "none", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_DASHED, 0, [cell]);
          break;
        case LINE_STYLE.DASH:
          graph.setCellStyles(ZwfConstants.STYLE_STARTARROW, "none", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_ENDARROW, "none", [cell]);
          graph.setCellStyles(ZwfConstants.STYLE_DASHED, 1, [cell]);
          break;
        default:
          break;
      }
    }
    finally {
      graph.getModel().endUpdate();
    }
  }

  const handleLineCornerSelect = (corner) => {
    const cell = graph.getSelectionCell();
    graph.getModel().beginUpdate();
    try {
      setLineCorner(corner);
      switch (corner){
        case LINE_CORNER.ROUNDED:
          graph.setCellStyles(ZwfConstants.LINE_ROUNDED, 1, [cell]);
          graph.setCellStyles(ZwfConstants.LINE_CURVED, 0, [cell]);
          break;
        case LINE_CORNER.CURVED:
          graph.setCellStyles(ZwfConstants.LINE_ROUNDED, 0, [cell]);
          graph.setCellStyles(ZwfConstants.LINE_CURVED, 1, [cell]);
          break;
        case LINE_CORNER.SHARP:
          graph.setCellStyles(ZwfConstants.LINE_ROUNDED, 0, [cell]);
          graph.setCellStyles(ZwfConstants.LINE_CURVED, 0, [cell]);
          break;
        default:
          break;
      }
    }
    finally {
      graph.getModel().endUpdate();
    }
  }

  const handleLineThicknessSelect = (thickness) => {
    const cell = graph.getSelectionCell();
    graph.getModel().beginUpdate();
    try {
      setLineThickness(thickness);
      graph.setCellStyles(ZwfConstants.IS_LINE_THICKNESS, 1, [cell]);
      graph.setCellStyles(ZwfConstants.STYLE_STROKEWIDTH, thickness, [cell]);
    }
    finally {
      graph.getModel().endUpdate();
    }
  }

  console.log(lineThickness);

  return (
    <Box title="Line style" isDivider>
      <Pane paddingTop={majorScale(2)}>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Pane width={144} onClick={close}>
              <Menu>
                <Menu.Item
                  onPointerDown={() => handleLineStyleSelect(LINE_STYLE.RIGHT)}
                >
                  <Image src={LineRight}/>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item
                  onPointerDown={() => handleLineStyleSelect(LINE_STYLE.LEFT)}
                >
                  <Image src={LineLeft}/>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item
                  onPointerDown={() => handleLineStyleSelect(LINE_STYLE.BI_DIRECTION)}
                >
                  <Image src={LineBidirectional}/>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item
                  onPointerDown={() => handleLineStyleSelect(LINE_STYLE.NORMAL)}
                >
                  <Pane display={"flex"} alignItems={"center"}><Image src={LineNormal}/></Pane>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item
                  onPointerDown={() => handleLineStyleSelect(LINE_STYLE.DASH)}
                >
                  <Pane display={"flex"} alignItems={"center"}><Image src={LineDash} alignSelf={"center"}/></Pane>
                </Menu.Item>
              </Menu>
            </Pane>
          )}
        >
          <Pane
            width={144}
            height={32}
            display={"inline-flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            border={"1px solid #D8DAE5"}
            borderRadius={"4px"}
            paddingLeft={majorScale(3)}
            cursor={"pointer"}
          >
            {lineStyle === LINE_STYLE.RIGHT && <Image src={LineRight}/>}
            {lineStyle === LINE_STYLE.LEFT && <Image src={LineLeft}/>}
            {lineStyle === LINE_STYLE.BI_DIRECTION && <Image src={LineBidirectional}/>}
            {lineStyle === LINE_STYLE.NORMAL && <Image src={LineNormal}/>}
            {lineStyle === LINE_STYLE.DASH && <Image src={LineDash}/>}
            <Pane marginRight={majorScale(1)}><Image src={CaretButton} float={"right"}/></Pane>
          </Pane>
        </Popover>
      </Pane>
      <Pane display={"inline-flex"} paddingTop={majorScale(2)}>
        <Pane display={"flex"} justifyContent={"center"} paddingRight={majorScale(4)}>
          <Text alignSelf={"center"}>Corner</Text>
        </Pane>
        <Pane>
          <Popover
            position={Position.BOTTOM_LEFT}
            content={({ close }) => (
              <Pane width={120} onClick={close}>
                <Menu>
                  <Menu.Item
                    className={styles.lineCornerItem}
                    onPointerDown={() => handleLineCornerSelect(LINE_CORNER.SHARP)}
                  >
                    <Text>{_.capitalize(LINE_CORNER.SHARP)}</Text>
                  </Menu.Item>
                  <Menu.Divider/>
                  <Menu.Item
                    className={styles.lineCornerItem}
                    onPointerDown={() => handleLineCornerSelect(LINE_CORNER.ROUNDED)}
                  >
                    <Text>{_.capitalize(LINE_CORNER.ROUNDED)}</Text>
                  </Menu.Item>
                  <Menu.Divider/>
                  <Menu.Item
                    className={styles.lineCornerItem}
                    onPointerDown={() => handleLineCornerSelect(LINE_CORNER.CURVED)}
                  >
                    <Text>{_.capitalize(LINE_CORNER.CURVED)}</Text>
                  </Menu.Item>
                </Menu>
              </Pane>
            )}
          >
            <Pane
              width={120}
              height={32}
              display={"inline-flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              border={"1px solid #D8DAE5"}
              borderRadius={"4px"}
              paddingLeft={majorScale(3)}
              cursor={"pointer"}
            >
              <Text>{_.capitalize(lineCorner)}</Text>
              <Pane marginRight={majorScale(1)}><Image src={CaretButton} float={"right"}/></Pane>
            </Pane>
          </Popover>
        </Pane>
      </Pane>
      <Pane display={"inline-flex"} paddingTop={majorScale(2)}>
        <Pane display={"flex"} justifyContent={"center"} paddingRight={majorScale(4)}>
          <Text alignSelf={"center"}>Line thickness</Text>
        </Pane>
        <Pane>
          <Popover
            position={Position.BOTTOM_LEFT}
            content={({ close }) => (
              <Pane width={72} onClick={close}>
                <Menu>
                  {LINE_THICKNESS.map((item, index) => (
                    <>
                      <Menu.Item
                        className={styles.lineCornerItem}
                        onPointerDown={() => handleLineThicknessSelect(item)}
                      >
                        <Text>{item}</Text>
                      </Menu.Item>
                      {LINE_THICKNESS.length !== index + 1 && <Menu.Divider/>}
                    </>
                  ))}
                </Menu>
              </Pane>
            )}
          >
            <Pane
              width={72}
              height={32}
              display={"inline-flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              border={"1px solid #D8DAE5"}
              borderRadius={"4px"}
              paddingLeft={majorScale(3)}
              cursor={"pointer"}
            >
              <Text>{lineThickness}</Text>
              <Pane marginRight={majorScale(1)}><Image src={CaretButton} float={"right"}/></Pane>
            </Pane>
          </Popover>
        </Pane>
      </Pane>
    </Box>
  );
};

export default LinePanel;
