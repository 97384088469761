/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Spinner } from 'evergreen-ui';
import Fuse from 'fuse.js';
import { Link, useLocation } from 'react-router-dom';
import { sort } from 'fast-sort';
import _ from 'lodash';

import {
  getSortedNote,
  handleSortNote,
  useNoteCollection,
  useSearchNotes,
} from 'services/note';
import styles from './NoteList.module.scss';
import { NoteListType, NoteSchema } from 'schemas';
import { NoteService } from 'services/note';
import { getUserSort, setUserSort } from 'utils/store';
import { NOTE_SORT } from 'utils/constants';
import MyNoteHeader from './MyNoteHeader';
import TrashNoteHeader from './TrashNoteHeader';
import useStores from 'hooks/useStores';
import SearchBox from './NoteSearchBox';
import NoteList from './NoteList';

const noteService = new NoteService();

export type NoteListProps = {
  type: NoteListType;
  notebookId?: string;
};

export default function MyNoteList({ type, notebookId }: NoteListProps) {
  const { isLoading, notes, setNotes } = useNoteCollection(type, notebookId);
  const [keyword, setKeyword] = useState('');
  const [sortedNotes, setSortedNotes] = useState([] as NoteSchema[]);
  const { resultNotes } = useSearchNotes(keyword, notes);
  const userSort = getUserSort(NOTE_SORT);
  const [selectedOrder, setSelectedOrder] = useState(`${userSort?.field}:${userSort?.direction}`);

  useEffect(() => {
    setSortedNotes(getSortedNote(resultNotes));
  }, [resultNotes]);

  const handleSort = (input: string) => {
    handleSortNote(input, notes, setSelectedOrder, setNotes);
  };

  const ListHeader = useCallback(() => {
    switch (type) {
      case NoteListType.TRASH:
        return <TrashNoteHeader />;
      default:
        return <MyNoteHeader />;
    }
  }, [type]);

  return (
    <div className={clsx(styles.noteList)}>
      <div className={styles.headerContainer}>
        <ListHeader />
        <hr />
        <SearchBox
          keyword={keyword}
          setKeyword={setKeyword}
          sortBy={selectedOrder}
          handleSort={handleSort}
        />
        <hr />
      </div>
      <NoteList isLoading={isLoading} notes={sortedNotes} type={type} />
    </div>
  );
}
