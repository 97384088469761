import { useState } from 'react';
import { Button, InlineAlert, IntentTypes, TextInput } from 'evergreen-ui';
import { useFormik } from 'formik';
import { getUser, updateUser } from 'utils/store';
import { db } from 'utils/firebase';
import * as Yup from 'yup';
import { USER_COLLECTION } from 'utils/constants';
import styles from '../UserSettings/UserSettings.module.scss';

const ChangeNameForm = () => {
  const [intent, setIntent] = useState<IntentTypes>('success');
  const [message, setMessage] = useState('');
  const user = getUser();
  const { firstName, lastName } = user || {};
  const {
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      firstName,
      lastName,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('First name required'),
      lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Last name required'),
    }),
    onSubmit: async (values) => {
      try {
        const { uid } = getUser() || {};
        await db.collection(USER_COLLECTION).doc(uid).update(values);
        updateUser({
          firstName: values.firstName || '',
          lastName: values.lastName || '',
        })
        setIntent('success');
        setMessage('Name saved.');
      } catch (e: any) {
        setIntent('danger');
        setMessage(e.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <TextInput
        type="text"
        className="mt-3"
        placeholder="First Name"
        isInvalid={!!(touched.firstName && errors.firstName)}
        {...getFieldProps('firstName')}
      />
      <TextInput
        type="text"
        className="mt-3"
        placeholder="Last Name"
        isInvalid={!!(touched.lastName && errors.lastName)}
        {...getFieldProps('lastName')}
      />
      <div className="d-flex mt-4">
        <Button
          appearance="primary"
          type="submit"
          className="mr-4"
          disabled={isSubmitting}
        >
          Save Name
        </Button>
        {message && (
          <InlineAlert intent={intent}>
            {message}
          </InlineAlert>
        )}
      </div>
    </form>
  )
};

export default ChangeNameForm;
