import {Checkbox, DocumentIcon, majorScale, Pane, Text} from "evergreen-ui";
import {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import styles from "./TaskItem.module.scss"
import dayjs from "dayjs";
import TaskItemInput from "./TaskItemInput";
import {TaskService} from "../../services/task/task.service";
import {TaskInput} from "../../schemas";
import {isToday} from "../../utils/datetime";
import _ from "lodash"
import {useDrag, useDrop} from "react-dnd";
import {getUser} from "../../utils/store";
import AssigneeAvatar from "./AssigneeAvatar";
import Expand from "react-expand-animated";
import {useLocation} from "react-router-dom";
import {Animated} from "react-animated-css";
import {TASK_TYPE} from "../../services/task/tasks.helper";

type Props = {
  id?: string,
  title?: string,
  description?: string,
  dueDate?: any,
  completeDate?: any,
  isComplete?: boolean,
  assignees?: any,
  onSelect?: Function,
  isSelected?: boolean,
  members?: any,
  isViewMode?: boolean,
  dateAble?: boolean,
  draggableTasks?: any[],
  updateOrderTasks?: Function,
  ordinal?: number
}

const taskService = new TaskService();

const TaskItem = (task: Props) => {
  const {
    id,
    title,
    description,
    dueDate,
    assignees,
    onSelect,
    isSelected,
    completeDate,
    isComplete,
    members,
    isViewMode,
    dateAble,
    updateOrderTasks,
    draggableTasks
  } = task;
  const [isExpanded, setExpanded] = useState(false);
  const expandPaneRef = useRef(null);
  const textDescriptionRef = useRef(null);
  const [today] = useState(new Date());
  const submitData = useRef({title, description, dueDate, assignees, isSelected} as unknown);
  const [isChecked, setChecked] = useState(!!isComplete);
  const [taskCompleteTimeout, setTaskCompleteTimeout] = useState(null as any);
  const latestData = useRef({});
  const taskRef = useRef(null);
  const location = useLocation();
  const locationQuery = new URLSearchParams(location.search);
  const viewMode = locationQuery.get("view") || "";
  const [visible, setVisible] = useState(true);

  const descriptionWithoutScroll = () => {
    setTimeout(() => {
      const currentEl = textDescriptionRef?.current as any;
      if(currentEl){
        currentEl.style.height = currentEl.scrollHeight + "px";
      }
    }, 10);
  }

  useEffect(() => {
    if(isExpanded){
      descriptionWithoutScroll();
    }
  }, [isExpanded]);

  useEffect(() => {
    latestData.current = {...latestData.current, isSelected};
  }, [isSelected]);

  const isClickOnEl = (selectors: string, event: any) => {
    const el = document.querySelector(selectors);
    return el && el.contains(event.target);
  }

  const saveAndCollapse = () => {
    taskService.update(submitData.current as TaskInput)
      .catch(ex => {
        console.log("task update ex", ex.message);
      });
    setExpanded(false);
  }

  const handleClickOutTaskItem = (event: any) => {
    const isTaskSelected = _.get(latestData.current, "isSelected", false);
    const isExpanded = expandPaneRef.current;
    if(isExpanded){
      if(isClickOnEl('.ant-picker-panel-container', event)) return;
      if(isClickOnEl('.assignees-panel', event)) return;

      // @ts-ignore
      const isClickInsideExpandPanel = expandPaneRef.current.contains(event.target);
      if(isClickInsideExpandPanel) return;

      saveAndCollapse();
    }
    else if(isTaskSelected && !isExpanded){
      const taskEl = taskRef?.current as any;
      if (taskEl?.contains(event.target)) return;

      onSelect && onSelect(null);
    }
  };

  const onHandleDeleteKey = (event: any) => {
    const isTaskSelected = _.get(latestData.current, "isSelected", false);
    const isExpanded = expandPaneRef.current;

    if(!isExpanded && isTaskSelected){
      const user = getUser();
      const deleteKeyCode = 8;
      if(event.keyCode === deleteKeyCode){
        taskService.partialUpdate({
          id,
          isDelete: true,
          isDeletedBy: user.uid
        });
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutTaskItem, true);
    document.addEventListener("keyup", onHandleDeleteKey);
    return () => {
      document.removeEventListener('click', handleClickOutTaskItem, true);
      document.removeEventListener("keyup", onHandleDeleteKey);
    };
  }, []);

  const setTaskInfo = (val: any) => {
    submitData.current = val;
  }

  const displayDate = (date: any) => {
    if(isToday(date, today)) return "Today";

    return  dayjs(new Date(date)).format('MMM DD, YYYY');
  }

  const onCheckBoxClick = (e: any) => {
    if(isViewMode) return;
    e.stopPropagation();

    setChecked(e.target.checked);

    if(taskCompleteTimeout) {
      clearTimeout(taskCompleteTimeout);
      setTaskCompleteTimeout(null);
    }

    const timeout = setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        taskService.updateComplete(id || "", e.target.checked)
          .catch(ex => {
            console.log("set task complete exception ex", ex.message);
          });
      }, 500);
    }, 1500);

    setTaskCompleteTimeout(timeout);
  }

  const [{ isDragging }, dragTaskRef] = useDrag(() => ({
    type: 'TASK',
    item: {
      type: 'TASK',
      task: task
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }), [task]);

  const canArrange = () => {
    return  !["tome", "today"].includes(viewMode);
  }

  const [{ isDropTaskOver, isDropHeadingOver }, dropTaskRef] = useDrop(() => ({
    accept: ["TASK"],
    collect: (monitor) => ({
      isDropTaskOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "TASK"
        && canArrange()
        && _.get(monitor.getItem(), "task.type") !== TASK_TYPE.HEADING,
      isDropHeadingOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "TASK"
        && canArrange()
        && _.get(monitor.getItem(), "task.type") === TASK_TYPE.HEADING
      ,
    }),
    canDrop: (item: any, monitor) => {
      const isTask = _.get(item, "type") === "TASK";
      const sameProject = _.get(item.task, "project") === _.get(task, "project");
      const isTodoTask = !_.get(item.task, "isComplete");

      return isTask && isTodoTask && sameProject && canArrange();
    },
    drop: async (item: any, monitor)  => {
      const indexOfDropTask = (draggableTasks || [])?.findIndex(t => t.id === task.id);
      // if drop to unexpected task
      if(indexOfDropTask < 0) {
        return
      }

      const nextTask = _.get(draggableTasks, indexOfDropTask + 1);
      const isLastTask = !nextTask;

      const updateDraggableTask = (id: string, newOrdinal: number) => {
        const taskInList = draggableTasks?.find(task => task.id === id);
        if(taskInList){
          taskInList.ordinal = newOrdinal;
          const sortByOrdinalTask = (draggableTasks?.filter(task => !task.isComplete) || [])
            .sort((first, second) => {
              return first.ordinal > second.ordinal ? -1 : 1;
            })
          updateOrderTasks && updateOrderTasks(sortByOrdinalTask);
        }
      }

      if(nextTask){
        const isValidOrdinal = nextTask.ordinal && task.ordinal;
        if(!isValidOrdinal) return;
        const newOrdinal = Math.round((task.ordinal + nextTask.ordinal)/2);
        taskService.partialUpdate(

          {
            id: item.task.id,
            ordinal: newOrdinal
          }
        );

        updateDraggableTask(item.task.id, newOrdinal);
      }
      else if(isLastTask && task.ordinal){
        const newOrdinal = Math.round(task.ordinal - 10000);
        taskService.partialUpdate(
          {
            id: item.task.id,
            ordinal: newOrdinal
          }
        );

        updateDraggableTask(item.task.id, newOrdinal);
      }
    }
  }), [task]);

  const onItemRef = (ref: any) => {
    dropTaskRef(ref);
    dragTaskRef(ref);
  }

  return (
    <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1} animationOutDuration={500} isVisible={visible}>
      <Pane display={isDragging ? 'none' : 'unset'} ref={taskRef}>
        {!isExpanded &&
          <>
            <Pane
              justifyContent={"space-between"}
              alignContent={"center"}
              cursor={"pointer"}
              className={clsx(isSelected && !isExpanded && styles.taskSelected)}
              onClick={() => onSelect && onSelect(id)}
              onDoubleClick={() => setExpanded(true)}
              paddingX={majorScale(2)}
              marginLeft={majorScale(2)}
              ref={onItemRef}
            >
              <Pane justifyContent={"space-between"} display={"flex"}>
                <Pane display={"flex"}>
                  <Pane>
                    <Checkbox
                      marginY={majorScale(1)}
                      checked={isChecked}
                      onChange={(e: any) => onCheckBoxClick(e)}
                      onClick={(e: any) => e.stopPropagation()}/>
                  </Pane>
                  <Pane
                    display={"flex"}
                    alignContent={"center"}
                    marginLeft={majorScale(1)}
                    justifyContent={"center"}
                  >
                    <Text
                      alignSelf={"center"}
                      fontSize={14}
                      color={"#101840"}
                      className={clsx(styles.noSelectAble)}
                    >{title}</Text>
                    {!_.isEmpty(description) &&
                      <DocumentIcon
                        color="#8F95B2"
                        size={12}
                        justifySelf={"center"}
                        marginTop={"auto"}
                        marginBottom={"auto"}
                        marginLeft={majorScale(1)}
                      />
                    }
                  </Pane>
                </Pane>
                <Pane display={"flex"} minWidth={"max-content"}>
                  {isComplete &&
                    <Text marginTop="6px" fontSize={12} color="#696F8C">
                      {"Completed " + displayDate(completeDate)}
                    </Text>
                  }
                  {!isComplete && dueDate &&
                    <Text marginTop="6px" fontSize={12} color="#696F8C">
                      {displayDate(dueDate)}
                    </Text>
                  }
                  {!_.isEmpty(assignees) &&
                    <Pane
                      marginLeft={majorScale(1)}
                      marginTop="4px"
                      display={"flex"}
                    >
                      {Object.values(assignees).map((assignee: any) => (
                        <Pane marginLeft={majorScale(1)}>
                          <AssigneeAvatar assignee={assignee} members={members} size={majorScale(3)}/>
                        </Pane>
                      ))
                      }
                    </Pane>
                  }
                </Pane>
              </Pane>
              <Expand
                duration={200}
                open={isDropTaskOver}
                transitions={["height", "opacity"]}
              >
                <Pane className={clsx(isDropTaskOver && styles.taskDropOver)}/>
              </Expand>
            </Pane>
            <Expand
              duration={200}
              open={isDropHeadingOver}
              transitions={["height", "opacity"]}
            >
              <Pane className={clsx(isDropHeadingOver && styles.taskDropOver)}/>
            </Expand>
          </>
        }
        <Expand
          duration={200}
          open={isExpanded}
          transitions={["height", "opacity"]}
        >
          {isExpanded &&
            <Pane
              className={clsx(styles.taskItem)}
              marginLeft={majorScale(2)}
              ref={expandPaneRef}
            >
              <TaskItemInput
                {...{id, title, description, dueDate, assignees, onSelect, isSelected, completeDate, isComplete, members}}
                setTaskInfo={setTaskInfo}
                isViewMode={true}
                dateAble={dateAble}
                saveTask={saveAndCollapse}
              />
            </Pane>
          }
        </Expand>
      </Pane>
    </Animated>
  )
}

export default TaskItem;
