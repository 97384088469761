import React, { memo } from 'react';
import {
    Pane,
    Button,
    PlayIcon,
    CrossIcon,
    Select
} from 'evergreen-ui';
import Countdown, {
    zeroPad
} from "react-countdown";

import styles from './Style.module.scss';


type PropsTime = {
    minutes: string,
    seconds: string
}

type PropsTimer = {
    timerStart: boolean,
    setTimerStart: any,
    selectedTime: string,
    setSelectedTime: any,
    setIsCompleted: any
}

const listTime = [
    { label: "5 mins", value: "5" },
    { label: "10 mins", value: "10" },
    { label: "15 mins", value: "15" },
    { label: "20 mins", value: "20" },
    { label: "25 mins", value: "25" },
    { label: "30 mins", value: "30" },
    { label: "35 mins", value: "35" },
    { label: "40 mins", value: "40" },
    { label: "45 mins", value: "45" },
    { label: "50 mins", value: "50" },
    { label: "55 mins", value: "55" },
    { label: "60 mins", value: "60" }
]
// Renderer callback with condition
const renderer: any = ({ minutes, seconds }: PropsTime) => {
    return (
        <span>
            {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
    );
};

function Timer({ timerStart, setTimerStart, selectedTime, setSelectedTime, setIsCompleted }: PropsTimer) {
    return (
        <Pane className={styles.volumeForm} marginBottom={15}>
            <Pane className={styles.titlePage}>
                TIMER
          </Pane>
            {
                !timerStart
                    ?
                    <Pane display={"flex"} className={styles.play}>
                        <Pane paddingRight={15}>
                            <Select value={selectedTime} onChange={event => setSelectedTime(event.target.value)}>
                                {
                                    listTime.map((value, index) => {
                                        return (
                                            <option value={value.value} key={index}>{value.label}</option>
                                        )
                                    })
                                }
                            </Select>
                        </Pane>
                        <Pane>
                            <Button
                                className={styles.playBtn}
                                iconBefore={PlayIcon}
                                disabled={timerStart}
                                onClick={() => {
                                    setTimerStart(true)
                                }}
                            >
                                Start
                  </Button>
                        </Pane>
                    </Pane>
                    :
                    <Pane display={"flex"} className={styles.play}>
                        <Pane width={60} className={styles.valueTimer}>
                            <Countdown date={Date.now() + (parseInt(selectedTime) * 60 * 1000)} autoStart={timerStart} renderer={renderer} onComplete={() => {
                                setTimerStart(false)
                                setIsCompleted(true)
                            }} />
                        </Pane>
                        <Pane>
                            <Button
                                className={styles.playBtn}
                                iconBefore={CrossIcon}
                                onClick={() => {
                                    setTimerStart(false)
                                }}
                            >
                                Cancel
                  </Button>
                        </Pane>
                    </Pane>
            }
        </Pane>
    );
}

export default memo(Timer);