import {mxCell, mxGeometry, mxGraphModel, mxPoint} from "mxgraph";

import * as cmx from 'libs/mxgraph';
import {
  ICellData,
  IGeometry,
  IModelData,
  IPoint,
  IStyleData
} from "./MxGraphData";

export class Deserializer {

  public static deserializeMxGraphModel(json: IModelData): mxGraphModel {
    const cells = {...json.cells};
    const rootJson = cells[json.root];
    delete cells[json.root];

    const rootCell = Deserializer.deserializeMxCell(json.root, rootJson);

    const model = new cmx.mxGraphModel(rootCell);
    model.createIds = false;

    model.setRoot(rootCell);

    let sortedCells = Object.values(cells).sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
    sortedCells.forEach((cellJson) => {
      const cell = Deserializer.deserializeMxCell(cellJson.id, cellJson);
      model.cellAdded(cell);
    });

    sortedCells.forEach((cellData) => {
      const cell = model.cells[cellData.id];
      Deserializer.resolveCellRelationships(cell, cellData, model);

    });

    return model;
  }

  public static deserializeMxCell(id: string, cellData: ICellData): mxCell {
    const value = Deserializer.deserializeValue(cellData.value);

    const style = Deserializer.deserializeStyle(cellData.style);
    const geometry = Deserializer.deserializeGeometry(cellData.geometry);

    const cell = new cmx.mxCell(value, geometry, style);
    cell.id = id;

    if (cellData.collapsed !== undefined) {
      cell.setCollapsed(cellData.collapsed);
    }

    if (cellData.connectable !== undefined) {
      cell.setConnectable(cellData.connectable);
    }

    if (cellData.visible !== undefined) {
      cell.setVisible(cellData.visible);
    }

    if (cellData.vertex === true) {
      cell.setVertex(true);
    }

    if (cellData.edge === true) {
      cell.setEdge(true);
    }

    if (cellData.style !== undefined) {
      const mxConstants = cmx.mxConstants;
      const styleData = cellData.style.styles;

      if (!styleData.hasOwnProperty(mxConstants.STYLE_FONTFAMILY)) {
        styleData[mxConstants.STYLE_FONTFAMILY] = mxConstants.DEFAULT_FONTFAMILY;
      }
      if (!styleData.hasOwnProperty(mxConstants.STYLE_FONTSIZE)) {
        styleData[mxConstants.STYLE_FONTSIZE] = mxConstants.DEFAULT_FONTSIZE;
      }

      if (cellData.style.classes.includes('group') && !styleData.hasOwnProperty(mxConstants.STYLE_FILLCOLOR)) {
        styleData[mxConstants.STYLE_FILLCOLOR] = 'none';
        styleData[mxConstants.STYLE_STROKECOLOR] = 'none';
      }

      const cellStyle = Deserializer.deserializeStyle(cellData.style);
      if (cellStyle) {
        cell.setStyle(cellStyle);
      }
    }

    return cell;
  }

  public static resolveCellRelationships(
    cell: mxCell,
    cellData: ICellData,
    model: mxGraphModel): void {

    if (cellData.parent) {
      const parent = model.cells[cellData.parent];
      if (parent) {
        if (cellData.index !== undefined) {
          parent.insert(cell, cellData.index);
        } else {
          parent.insert(cell);
        }
      }
    }
    if (cellData.source) {
      const source = model.cells[cellData.source];
      if (source) {
        source.insertEdge(cell, true);
      }
    }

    if (cellData.target) {
      const target = model.cells[cellData.target];
      if (target) {
        target.insertEdge(cell, false);
      }
    }
  }

  public static deserializeValue(jsonValue: any): any {
    // TODO handle an xml node.
    return jsonValue;
  }

  public static deserializeStyle(jsonStyle?: IStyleData): string | undefined {
    if (jsonStyle === undefined) {
      return ;
    }

    let style = "";
    jsonStyle.classes.forEach((className: string) => {
      style += className + ";";
    });

    Object.keys(jsonStyle.styles).forEach((key: string) => {
      const value = jsonStyle.styles[key];
      style += key + "=" + value + ";";
    });

    return style;
  }

  public static deserializeGeometry(geom?: IGeometry): mxGeometry | undefined {
    if (!geom) {
      return;
    }

    const result = new cmx.mxGeometry(geom.x, geom.y, geom.width, geom.height);

    if (geom.points) {
      result.points = geom.points.map((p: IPoint) => Deserializer.deserializePoint(p));
    }

    if (geom.sourcePoint) {
      result.setTerminalPoint(Deserializer.deserializePoint(geom.sourcePoint), true);
    }

    if (geom.targetPoint) {
      result.setTerminalPoint(Deserializer.deserializePoint(geom.targetPoint), false);
    }

    if (typeof geom.relative == 'boolean') {
        result.relative = geom.relative;
    }

    if (geom.offset) {
      result.offset = Deserializer.deserializePoint(geom.offset);
    }

    return result;
  }

  public static deserializePoint(jsonPoint: IPoint): mxPoint {
    return new cmx.mxPoint(jsonPoint.x, jsonPoint.y);
  }
}
