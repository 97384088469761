import {useEffect} from "react";

function useResize(editorUi, callback) {

	useEffect(() => {
    const graph = editorUi.editor.graph;
    graph.addListener("cellsResized", callback);

    return () => {
      graph.removeListener("cellsResized", callback);
    };
	}, []);

	return true;
}

export default useResize;
