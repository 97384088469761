import {Dialog} from "../../../components/Dialog";
import {Pane} from "evergreen-ui";
import {ProjectService} from "../../../services/project/project.service";
import {useState} from "react";
import {getUser} from "../../../utils/store";

type Props = {
  projectId: string,
  isShown: boolean,
  setIsShown: Function,
  successCallback: Function,
}

const projectService = new ProjectService();

const LeaveProjectModal = ({projectId, isShown, setIsShown, successCallback}: Props ) => {
  const [isDeleting, setDeleting] = useState(false);
  const user = getUser();

  const onDeleteProject = ()  => {
    setDeleting(true);
    projectService.removeMembers(projectId, [user.uid])
      .then(() => {
        setIsShown(true);
        successCallback && successCallback();
      })
      .finally(() => {
        setDeleting(false);
      })
  }

  return (
    <Dialog
      isShown={isShown}
      title="Leave Project"
      onCloseComplete={() => setIsShown && setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Leave Project"
      intent="danger"
      onConfirm={onDeleteProject}
      isConfirmLoading={isDeleting}
    >
      <Pane>Are you sure to leave this project?<br/>To rejoin, you will need to be invited again.</Pane>
    </Dialog>
  )
}

export default LeaveProjectModal;
