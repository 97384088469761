import {fuego} from '@nandorojo/swr-firestore';
import {FolderSchema} from 'schemas/folder';
import {FOLDER_COLLECTION, USER_COLLECTION} from 'utils/constants';
import {sort} from 'fast-sort';
import {useCallback, useEffect, useState} from "react";
import {ShareUserSchema} from "../../schemas";
import {getTeams, getUser} from "../../utils/store";
import _ from "lodash"
import {db} from "../../utils/firebase";

export const mergeFolderStructure = (folders?: FolderSchema[] | null, childFolders?: FolderSchema[] | null) => {
  const allFolders = [...(folders || [])];
  (childFolders || []).forEach(child => {
    const isExisting = allFolders?.find(folder => child.id === folder.id);
    if(!isExisting) allFolders.push(child);
  })

  if (allFolders) {
    const parentFolders = allFolders.filter(folder => {
      const isHaveNotParent = folder.parentFolder === '';
      const isShareChildOnly = folder.parentFolder
        && !allFolders.find(f => f.id === (folder.parentFolder || {}).id);

      return isHaveNotParent || isShareChildOnly;
    });
    const childFolders = allFolders.filter(folder => folder.parentFolder !== '');

    if (parentFolders && childFolders) {
      const sorted = sort(parentFolders).asc([
        u => u.ordinal,
        u => u.createdAt
      ]);

      sorted.forEach(parentFolder => {
        const parentRef = fuego.db
          .collection(FOLDER_COLLECTION)
          .doc(parentFolder.id);
        const childs = childFolders.filter(
          child => child.parentFolder && child.parentFolder.id === parentRef.id,
        );
        const sortedChilds = sort(childs).asc([
          u => u.ordinal,
          u => u.createdAt
        ]);
        parentFolder.children = sortedChilds;
      });
      return sorted;
    }
  }

  return allFolders;
};

export const addShareStatus = (folders: FolderSchema[]) => {
  (folders || []).forEach(folder => {
    const hasUserSharing = Object.keys(folder.roles || {}).length > 1;
    const hasTeamSharing = Object.values((folder.teamRoles || {})).find((share: any) => share.type !== "none");

    folder.isShared = hasUserSharing || !!hasTeamSharing;
  })
}

export const getHighestShareRole = (folder: any) => {
  const user = getUser();
  const teams = getTeams() || [];
  const priorityPermissions = ["editor", "commenter", "viewer"];

  const userRole = _.get(folder,`roles.${user.uid}.type`);
  const teamRoles = teams.map((team: any) => _.get(folder,`teamRoles.${team.id}.type`))
    .filter((role: string) => role && role !== "none");

  const sortByPriority = [userRole, ...teamRoles].filter(role => role)
    .sort((first: string, second: string) => {
      return priorityPermissions.indexOf(first) > priorityPermissions.indexOf(second) ? 1 : -1;
    });

  const highestRole = _.get(sortByPriority, 0);
  return highestRole;
}

export const addFolderRoleSharing = (folders: FolderSchema[] | null | undefined, userId: string) => {
  (folders || []).forEach(parentFolder => {
    const roleOnParent = getHighestShareRole(parentFolder);
    parentFolder.roleOfCurrentUser = roleOnParent;
    (parentFolder.children || []).forEach(childFolder => {
      const roleOnChild = getHighestShareRole(childFolder);
      childFolder.roleOfCurrentUser = roleOnChild || roleOnParent;
    })
  })
}

export const useShareFolder = () => {
  const [folder, setFolder] = useState({} as FolderSchema);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([] as any[]);
  const [shareUsers, setShareUsers] = useState([] as ShareUserSchema[]);
  const [teamRoles, setTeamRoles] = useState([] as any[]);
  const [shareTeams, setShareTeams] = useState([] as any[]);
  const user = getUser();

  useEffect(() => {
    const getShareDetail = async() => {
      const resShare: ShareUserSchema[] = [];
      const userRef = fuego.db.collection(USER_COLLECTION);
      if (roles) {
        for (const [uid, role] of Object.entries(roles)) {
          const user = await userRef.doc(uid).get();
          resShare.push({
            id: user.id,
            ...user.data(),
            role,
          });
        }
      }

      const teamRolesMap = Object.keys((teamRoles || {})).map((teamId) => ({
        role: _.get(teamRoles, teamId, {}).type,
        id: teamId,
      }));

      setShareUsers(resShare);
      setShareTeams(teamRolesMap);
    };
    getShareDetail();
  }, [roles, user?.id, teamRoles]);

  const getShareFolder = useCallback(async (folderId: string) => {
    const ref = fuego.db.collection(FOLDER_COLLECTION);
    if (!user) {
      return false;
    }
    let query = ref.doc(folderId);

    const unsubscribe = query.onSnapshot(
      {
        includeMetadataChanges: true,
      },
      (doc: any) => {
        setIsLoading(false);
        const resDoc = {
          id: doc.id,
          ...doc.data(),
        };
        const roles = resDoc.roles;
        const teamRoles = resDoc.teamRoles;
        setFolder(resDoc);
        setRoles(roles);
        setTeamRoles(teamRoles);
      }
    );
    return unsubscribe;
  }, [user?.id]);

  return {
    folder,
    roles,
    shareUsers,
    isLoading,
    getShareFolder,
    shareTeams
  };
};

export const useFolderDetail = (folderId: string | undefined) => {
  const [folder, setFolder] = useState(undefined as any);
  const user = getUser();

  useEffect(() => {
    if(!folderId) return;
    const getShareRole = (folder: any) => {
      const isOwner =_.get(folder,`roles.${user?.uid || ''}`);
      if (isOwner === 'owner') {
        return 'owner';
      }

      if (!user) {
        return 'anyone';
      }

      return getHighestShareRole(folder);
    }

    db.collection(FOLDER_COLLECTION).doc(folderId)
      .onSnapshot((folderDoc) => {
        const folderDocData = folderDoc.data() || {roles: {}};
        const currentFolder = {...folderDocData};

        const parent = folderDocData.parentFolder;
        if (parent){
          parent.onSnapshot((parentFolderDoc: any) => {
            const shareRole = getShareRole(parentFolderDoc.data());
            const parentFolder = {
              ...parentFolderDoc.data(),
              shareRole
            }
            setFolder({...currentFolder, parentFolder})
          })
        }

        const shareRole = getShareRole(folderDocData);
        _.set(currentFolder, "shareRole", shareRole);

        if(!folderDocData.parentFolder){
          setFolder(currentFolder);
        }
      })
  }, [folderId])

  return {folder};
}

export const useCheckFolder = (folder: any) => {
  const [isShareFolder, setShareFolder] = useState(false);
  const [folderRole, setFolderRole] = useState("");
  const [permissions, setPermission] = useState([] as string[]);

  useEffect(() => {
    const notNullFolder = (folder || {});
    const checkShareFolder = notNullFolder.shareRole !== "owner" && folder;
    setShareFolder(checkShareFolder);

    const checkRole = notNullFolder.shareRole || (notNullFolder.parentFolder || {}).shareRole;
    setFolderRole(checkRole);

    const definePermissions = {
      viewer: ["ViewFolder"],
      commenter: ["ViewFolder"],
      editor: ["AddFolder", "EditFolder"],
      owner: ["EditFolder", "AddFolder", "DeleteFolder", "ViewFolder", "EditFolder"],
    }

    const checkPermissions = _.get(definePermissions, checkRole, ["ViewFolder"]);
    setPermission(checkPermissions);

  }, [folder])

  return {
    isShareFolder, folderRole, permissions
  }
}
