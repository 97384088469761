import {useCollection} from "@nandorojo/swr-firestore";
import {OBJECTIVES_COLLECTION, TASK_COLLECTION} from "../../utils/constants";
import {getUser} from "../../utils/store";
import {useEffect, useState} from "react";

type Props = {
  pageId?: string,
  today?: any
}

export const useObjectives = ({pageId}: Props) => {
  const [objectives, setObjectives] = useState([] as any[]);
  const [objectiveCount, setObjectiveCount] = useState(0);

  const { data: rawObjectives } = useCollection<any>(OBJECTIVES_COLLECTION, {
    where: [
      ['page', '==', pageId]
    ],
    listen: true,
  });

  useEffect(() => {
    const sortedObjectives = (rawObjectives || [])
      .sort((first, second) => {
        return first.ordinal > second.ordinal ? -1 : 1;
      })

    setObjectives(sortedObjectives);
    setObjectiveCount(sortedObjectives.length);
  }, [rawObjectives])

  return {
    objectives,
    objectiveCount
  }
}

export const useTrashObjectives = () => {
  const user = getUser();
  const [deletedObjectives, setDeletedObjectives] = useState([] as any[]);

  const { data: rawObjectives } = useCollection<any>(TASK_COLLECTION, {
    where: [
      ['isDeletedBy', '==', user.uid],
    ],
    listen: true,
  });

  useEffect(() => {
    const sortByDeleted = (rawObjectives || []).sort(
      (first: any, second: any) => first.deletedAt > second.deletedAt ? -1 : 1
    );
    setDeletedObjectives(sortByDeleted);
  }, [rawObjectives]);

  return {
    deletedObjectives
  }
}
