import clsx from "clsx";
import styles from "./SignUp.module.scss";
import {
  DataLineageIcon,
  LayoutAutoIcon,
  majorScale,
  minorScale,
  Pane,
  PresentationIcon,
  Text,
  BookIcon,
  PropertyIcon,
  LocateIcon,
  VolumeUpIcon,
} from "evergreen-ui";

const VersionPanel = () => {
  return (
    <>
      <Pane display={"flex"} height="100%" className={clsx(styles.leftPanel)} justifyContent={"end"}>
        <Pane
          background="#1969C8"
          height={"470px"}
          maxWidth="346px"
          minWidth="346px"
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
          padding={majorScale(4)}
          paddingBottom={"unset"}
        >
          <Pane
            color="#FFFFFF"
            fontSize="1.4675em"
            fontWeight={600}
            fontFamily="SF Pro Text"
            lineHeight={"32px"}
          >
            One account, <br/>endless productivity.
          </Pane>
          <Pane className={styles.featureContainer} paddingTop={majorScale(6)} display={"flex"}>
            <Pane background="#4AA3E6" className={clsx(styles.feature)}>
              <DataLineageIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText, styles.spaceBetween)}>Flowchart</Text>
            <Pane background="#FFB020" className={clsx(styles.feature)}>
              <BookIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText)}>Notes</Text>
          </Pane>
          <Pane paddingTop={minorScale(3)} display={"flex"}>
            <Pane background="#EF8432" className={clsx(styles.feature)}>
              <LayoutAutoIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText, styles.spaceBetween)}>Mind Map</Text>
            <Pane background="#52BD94" className={clsx(styles.feature)}>
              <PropertyIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText)}>Task</Text>
          </Pane>
          <Pane paddingTop={minorScale(3)} display={"flex"}>
            <Pane background="#DE4C4E" className={clsx(styles.feature)}>
              <PresentationIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText, styles.spaceLast)}>Whiteboard</Text>
            <Pane background="#25CBD6" className={clsx(styles.feature)}>
              <LocateIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText)}>OKR</Text>
          </Pane>
          <Pane paddingTop={minorScale(3)} display={"flex"}>

            <Pane background="#ED55C2" className={clsx(styles.feature)}>
              <VolumeUpIcon size={16} color={"white"} alignSelf={"center"}/>
            </Pane>
            <Text className={clsx(styles.featureText)}>Sounds</Text>
          </Pane>
          <Pane marginTop={majorScale(6)}>
            <Text
              fontSize="14px"
              fontWeight={400}
              color={"white"}
            >Trusted by +1.6M users worldwide</Text>
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export default VersionPanel;
