import clsx from 'clsx';
import {
  Pane,
  Link,
  BookIcon
} from 'evergreen-ui';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import { useShareNotebooks } from 'services/note';
import { SHARED_NOTEBOOK_URL } from 'utils/constants';
import { getTeams } from 'utils/store';
import styles from './NotebookSidebar.module.scss';

export default function SharedNotebookSidebar() {
  const activateTeams = getTeams() || [];
  const { notebooks } = useShareNotebooks(activateTeams);
  const location = useLocation();

  const isActivePath = (path: string) => {
    return location.pathname.includes(path);
  };

  const isActiveRoute = (path: string, queryPath?: string) => {
    if (
      isActivePath(path) ||
      (queryPath && location.search.includes(queryPath))
    ) {
      return {
        isActive: true,
      };
    }
    return {
      isActive: false,
    };
  };

  return (
    <>
      <Pane className={styles.notebookSidebar}>
        {notebooks.map(notebook => (
          <Pane key={notebook.id} className={clsx(styles.item, 'sharedItem', isActiveRoute(`${SHARED_NOTEBOOK_URL}/${notebook.id}`))}>
            <Link is={ReactRouterLink} to={`${SHARED_NOTEBOOK_URL}/${notebook.id}`}>
              <BookIcon color="#3366FF" />
              <span className={clsx(styles.sidebarLabel)}>{notebook.name}</span>
            </Link>
          </Pane>
        ))}
      </Pane>
    </>
  );
}
