/* eslint-disable @typescript-eslint/no-unused-vars */

import { Autocomplete, TextInput, TagInput } from 'evergreen-ui';
import { useEffect, useMemo, useState } from 'react';

import styles from './TagInput.module.scss';
import { TagService } from 'services/tag';
import { NoteService } from 'services/note';
import useStores from 'hooks/useStores';
import _ from 'lodash';

const noteService = new NoteService();

export default function TagInputBox() {
  const { noteStore } = useStores();
  const [inputTags, setInputTags] = useState([] as string[]);

  useEffect(() => {
    setInputTags(noteStore.note?.tags || []);
  }, [noteStore.note]);

  const autocompleteItems = useMemo(() => {
    const allValues = noteStore.tagList;
    return allValues.filter(i => !inputTags.includes(i));
  }, [noteStore.tagList, inputTags]);

  const handleAddTag = async (values: string[]) => {
    if (values && noteStore.note?.id) {
      const noteTags = noteStore.note.tags || [];
      let newTags: string[] = [];
      values.forEach(value => {
        if (!noteTags.includes(value)) {
          newTags.push(value);
        }
      })
      if (newTags) {
        noteStore.note.tags = [...noteTags, ...newTags];
        noteService.updateOne(noteStore.note.id, {
          tags: [...noteTags, ...newTags],
        });
      }
    }
  };

  const handleRemoveTag = (tag: string) => {
    if (noteStore.note?.id) {
      const noteTags = noteStore.note.tags || [];
      _.remove(noteTags, item => item === tag);
      noteService.updateOne(noteStore.note.id, {
        tags: noteTags,
      });
    }
  };

  const onAdd = (newValues: string[]) => {
    if (newValues) {
      const noteTags = noteStore.note?.tags || [];
      let newTags: string[] = [];
      newValues.forEach((value: string) => {
        if (value && !noteTags.includes(value)) {
          newTags.push(value);
        }
      });
      setInputTags([...inputTags, ...newTags]);
      handleAddTag(newTags);
    }
  };

  const onRemove = (_value: any, index: any) => {
    setInputTags(inputTags.filter((_: any, i: any) => i !== index));
    if (_value) {
      handleRemoveTag(_value);
    }
  };

  return (
    <TagInput
      className={styles.input}
      inputProps={{ placeholder: 'Add Tag...' }}
      values={inputTags}
      autocompleteItems={autocompleteItems}
      width="100%"
      onAdd={onAdd}
      onRemove={onRemove}
    />
  );
}
