import { Button, majorScale, Paragraph, Pane } from 'evergreen-ui';
import CardAuth from 'components/CardAuth/CardAuth';
import { Link } from "react-router-dom";

const SentPassword = () => (
  <CardAuth title="Email sent!">
    <Paragraph className="text-center mt-5 mb-5" style={{ color: '#474D66' }}>
      Please check your inbox for the <br/>
      link to reset your password.
    </Paragraph>
    <Pane textAlign="center">
      <Link to="/sign-in" className="text-decoration-none">
        <Button
          appearance="primary"
          height={majorScale(5)}
        >
          Return Home
        </Button>
      </Link>
    </Pane>
  </CardAuth>
)

export default SentPassword;
