import { Container, Row, Col } from 'react-grid-system';

type Props = {
  children: any
};

const Rest = ({ children }: Props) => (
  <Container className="h-100">
    <Row className="h-100 justify-content-center align-items-center">
      <Col xl={4} md={5}>
        {children}
      </Col>
    </Row>
  </Container>
);

export default Rest;
