import React from 'react'
import {
    Pane,
    IconButton,
    NotificationsIcon,
    Popover,
    Position,
    CrossIcon,
    Avatar
}
    from "evergreen-ui";

import styles from "./ChangeBgModal.module.scss";

export default function NotificationModal() {

    return (
        <Popover
            content={({ close }) => (
                <Pane className={styles.formPopoverNocation}>
                    <Pane className={styles.formHeader}>
                        <Pane className={styles.title}>Notifications</Pane>
                        <Pane className={styles.closeIcon}>
                            <CrossIcon className={styles.icon} onClick={close} />
                        </Pane>
                    </Pane>
                    <Pane className={styles.formBody}>
                        <Pane className={styles.item}>
                            <Avatar name="Hieu Tran" size={32} marginRight={10} />
                            <Pane className={styles.titleItem}>
                                Sam Smith left a new comment on the card New Header
                            </Pane>
                            <CrossIcon className={styles.icon} onClick={close} width={30} height={30} />
                        </Pane>
                        <Pane className={styles.item}>
                            <Avatar name="Hieu Tran" size={32} marginRight={10} />
                            <Pane className={styles.titleItem}>
                                Sam Smith left a new comment on the card New Header
                            </Pane>
                            <CrossIcon className={styles.icon} onClick={close} width={30} height={30} />
                        </Pane>
                        <Pane className={styles.item}>
                            <Avatar name="Hieu Tran" size={32} marginRight={10} />
                            <Pane className={styles.titleItem}>
                                Sam Smith left a new comment on the card New Header
                            </Pane>
                            <CrossIcon className={styles.icon} onClick={close} width={30} height={30} />
                        </Pane>
                        <Pane className={styles.item}>
                            <Avatar name="Hieu Tran" size={32} marginRight={10} />
                            <Pane className={styles.titleItem}>
                                Sam Smith left a new comment on the card New Header
                            </Pane>
                            <CrossIcon className={styles.icon} onClick={close} width={30} height={30} />
                        </Pane>
                        <Pane className={styles.item}>
                            <Avatar name="Hieu Tran" size={32} marginRight={10} />
                            <Pane className={styles.titleItem}>
                                Sam Smith left a new comment on the card New Header
                            </Pane>
                            <CrossIcon className={styles.icon} onClick={close} width={30} height={30} />
                        </Pane>
                    </Pane>
                </Pane>
            )}
            position={Position.BOTTOM_RIGHT}
        >
            <IconButton icon={NotificationsIcon} height={32} width={32} />
        </Popover>
    )
}
