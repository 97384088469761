import { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'components/Dialog';
import dayjs from 'dayjs';
import clsx from 'clsx';

import { getUser } from 'utils/store';
import styles from './NoteInfoModal.module.scss';
import { Button } from 'evergreen-ui';
import useStores from 'hooks/useStores';
import { UserService } from 'services/user';
import { UserSchema } from 'schemas';

interface Props {
  isShown: boolean;
  setIsShown: Function;
}

const userService = new UserService();

export default function NoteInfoModal({ isShown, setIsShown }: Props) {
  const { noteStore } = useStores();
  const { note: currentNote } = noteStore;
  const [ownerEmail, setOwnerEmail] = useState('');

  useEffect(() => {
    if (currentNote.id) {
      (async() => {
        const user = getUser();
        if (user?.uid === currentNote.user) {
          setOwnerEmail(user.email);
        } else {
          const email = await getOwnerById();
          setOwnerEmail(email);
        }
      })();
    }
  }, []);

  const getOwnerById = useCallback(async () => {
    const ownerUser = await userService.getOne(currentNote.user);
    const ownerData = ownerUser.data() as UserSchema;
    return ownerData.email || '';
  }, [currentNote.user])

  return (
    <Dialog
      isShown={isShown}
      title="Note Information"
      onCloseComplete={() => {
        setIsShown(false);
      }}
      preventBodyScrolling
      hasCancel={false}
      hasFooter={false}
      width="433px"
    >
      <table className={styles.infoModal}>
        <tbody>
          <tr>
            <td>Last Modified:</td>
            <td>{dayjs(currentNote.updatedAt?.toDate()).fromNow()}</td>
          </tr>
          <tr>
            <td>Date Created:</td>
            <td>
              {dayjs(currentNote.createdAt?.toDate()).format('MMM DD, YYYY')}
            </td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>
              {ownerEmail}
            </td>
          </tr>
        </tbody>
      </table>

      <Button className={clsx('primary-btn', styles.okBtn)} appearance="primary" onClick={() => setIsShown(false)} size="medium">
        OK 
      </Button>
    </Dialog>
  );
}
