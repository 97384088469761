import ALGORITHM_FLOW from "../../assets/templates/algorithm-flow.png"
import CUSTOMER_SERVICE from "../../assets/templates/customer-service.png"
import ORGCHART_HORIZONTAL from "../../assets/templates/orgchart-horizontal.png"
import ORGCHART_VERTICAL from "../../assets/templates/orgchart-vertical.png"
import RECRUITING_PROCESS from "../../assets/templates/recruiting-process.png"
import SALES_PROCESS from "../../assets/templates/sales-process.png"
import SCRUM_WORKFLOW from "../../assets/templates/scrum-workflow.png"
import SWIMLANE_HORIZONTAL from "../../assets/templates/swimlane-horizontal.png"
import SWIMLANE_VERTICAL from "../../assets/templates/swimlane-vertical.png"
import WEBSITE_SITEMAP from "../../assets/templates/website-sitemap.png"
import {
  ALGORITHM_FLOW_XML,
  CUSTOMER_SERVICE_XML,
  ORGCHART_HORIZONTAL_XML,
  ORGCHART_VERTICAL_XML,
  RECRUITING_PROCESS_XML,
  SALES_PROCESS_XML,
  SCRUM_WORKFLOW_XML,
  SWIMLANE_HORIZONTAL_XML,
  SWIMLANE_VERTICAL_XML,
  WEBSITE_SITEMAP_XML
} from "../../components/Editor/xml"
import {DocumentType} from "../../schemas";

export const TEMPLATES_DEFINITION = [
  {
    name: "B2B Sales Process",
    preview: SALES_PROCESS,
    xml: SALES_PROCESS_XML,
    param: "sales-process",
    type: DocumentType.Flowchart,
  },
  {
    name: "Website Sitemap",
    preview: WEBSITE_SITEMAP,
    xml: WEBSITE_SITEMAP_XML,
    param: "website-sitemap",
    type: DocumentType.Flowchart,
  },
  {
    name: "Customer Service Process",
    preview: CUSTOMER_SERVICE,
    xml: CUSTOMER_SERVICE_XML,
    param: "customer-service",
    type: DocumentType.Flowchart,
  },
  {
    name: "Horizontal Swimlane Diagram",
    preview: SWIMLANE_HORIZONTAL,
    xml: SWIMLANE_HORIZONTAL_XML,
    param: "swimlane-horizontal",
    type: DocumentType.Flowchart,
  },
  {
    name: "Vertical Swimlane Diagram",
    preview: SWIMLANE_VERTICAL,
    xml: SWIMLANE_VERTICAL_XML,
    param: "swimlane-vertical",
    type: DocumentType.Flowchart,
  },
  {
    name: "Organizational Chart Horizontal",
    preview: ORGCHART_HORIZONTAL,
    xml: ORGCHART_HORIZONTAL_XML,
    param: "orgchart-horizontal",
    type: DocumentType.Flowchart,
  },
  {
    name: "Organizational Chart Vertical",
    preview: ORGCHART_VERTICAL,
    xml: ORGCHART_VERTICAL_XML,
    param: "orgchart-vertical",
    type: DocumentType.Flowchart,
  },
  {
    name: "Scrum Workflow",
    preview: SCRUM_WORKFLOW,
    xml: SCRUM_WORKFLOW_XML,
    param: "scrum-workflow",
    type: DocumentType.Flowchart,
  },
  {
    name: "Standard Recruitment Process",
    preview: RECRUITING_PROCESS,
    xml: RECRUITING_PROCESS_XML,
    param: "recruiting-process",
    type: DocumentType.Flowchart,
  },
  {
    name: "Algorithm Flow",
    preview: ALGORITHM_FLOW,
    xml: ALGORITHM_FLOW_XML,
    param: "algorithm-flow",
    type: DocumentType.Flowchart,
  },
];

export const getXMLByParam = (param: string) => {
  const template = TEMPLATES_DEFINITION.find(template => template.param === param);
  return template ? template.xml : null;
};
