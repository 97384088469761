import mx from '../mx';

const mxUtils = mx.mxUtils;

/**
 * Convert any string into camel case
 * @param string
 */
mxUtils.camelize = function (string) {
  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export { mxUtils };
