import { Pane, Text, TextInput } from 'evergreen-ui';
import { Control } from '../../Control/Control';
import { Box } from '../../Box/Box';
import {
  resizeSelection,
  updateButtonSize,
  updateCircleButtonSize,
  updateQuickSizeSelection
} from '../../../../utils/Actions';
import { ZwfConstants } from '../../../../utils/ZwfConstants';

import { mxUtils } from 'libs/mxgraph';

const SizePanel = ({
  graph,
  editorUi,
  graphState,
  dispatchGraphState,
  accessorPanel
}) => {
  const handleSizeChange = ({width, height}) => {
    const widthNum = parseInt(width);
    const heightNum = parseInt(height);

    const cell = graph.getSelectionCell();
    const style = graph.getCellStyle(cell);
    const isNoResize = mxUtils.getValue(style, ZwfConstants.NO_RESIZE);

    if(!isNaN(widthNum) && !isNaN(heightNum) && !isNoResize) {
      dispatchGraphState({size: {width: width, height: height}});
      resizeSelection(editorUi, width, height);
    }
  }

  const handleQuickSize = (val) => {
    const cell = graph.getSelectionCell();
    const style = graph.getCellStyle(cell);
    const quickSize = graphState?.quickSize[val];

    if (quickSize) {
      const size = quickSize.split('x');
      graph.getModel().beginUpdate()
      try {
        handleSizeChange({ width: size.shift(), height: size.pop() });
        updateQuickSizeSelection(editorUi, val);
        dispatchGraphState({ zwfSize: quickSize });
      } finally {
        graph.getModel().endUpdate();
      }
    }

    // Handle button action
    const isNoResize = mxUtils.getValue(style, ZwfConstants.NO_RESIZE);
    const type = mxUtils.getValue(style, ZwfConstants.STYLE_TYPE, 'default');
    if (!isNoResize) {
      graph.getModel().beginUpdate()
      try {
        if (type === ZwfConstants.TYPE_BUTTON) {
          updateButtonSize({ editor: editorUi, dispatchGraphState, graph, val });
        } else if(type === ZwfConstants.TYPE_CIRCLE_BUTTON) {
          updateCircleButtonSize({ editor: editorUi, val });
        }
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }

  // const cell = graph.getSelectionCell();
  // const style = graph.getCellStyle(cell);
  // const textSize = mxUtils.getValue(style, ZwfConstants.STYLE_TEXT_SIZE, ZwfConstants.TEXT_SIZE_SMALL);

  return (
    <Box
      title="Size"
      isDivider
    >
      {accessorPanel.isActionVisible(ZwfConstants.ACTION_QUICK_SIZE) &&
        <Pane className="mt-3">
          <Control
            width={122}
            value={graphState?.zwfSize}
            options={[
              { label: 'S', value: ZwfConstants.TEXT_SIZE_SMALL },
              { label: 'M', value: ZwfConstants.TEXT_SIZE_MEDIUM },
              { label: 'L', value: ZwfConstants.TEXT_SIZE_LARGE },
            ]}
            onChange={(e) => handleQuickSize(e)}
          />
        </Pane>
      }
      {accessorPanel.isActionVisible(ZwfConstants.ACTION_MANUAL_RESIZE) &&
        <Pane className="mt-3 d-flex align-items-center">
          <Pane className="mr-3">
            <Text className="mr-2">W</Text>
            <TextInput
              value={graphState?.size?.width}
              width={56}
              disabled={accessorPanel.isActionVisible(ZwfConstants.HEIGHT_RESIZE)}
              onChange={(e) => handleSizeChange(
                {width: e.target.value, height: graphState.size?.height}
              )}
            />
          </Pane>
          <Pane className="mr-3">
            <Text className="mr-2">H</Text>
            <TextInput
              value={graphState?.size?.height}
              width={56}
              disabled={accessorPanel.isActionVisible(ZwfConstants.WIDTH_RESIZE)}
              onChange={(e) => handleSizeChange(
                {width: graphState.size?.width, height: e.target.value}
              )}
            />
          </Pane>
        </Pane>
      }
    </Box>
  )
}

export default SizePanel;
