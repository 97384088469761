import { useEffect } from 'react';
import { TextInput } from 'evergreen-ui';
import { Dialog } from 'components/Dialog';
import { useState } from 'react';

import { DocumentService } from 'services/document';
import { DocumentSchema } from 'schemas/document';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  document: DocumentSchema;
}

const documentService = new DocumentService();

export default function RenameDocumentModal({ isShown, setIsShown, document }: Props) {
  const [newName, setNewName] = useState(document.name);
  const [currentDocument, setCurrentDocument] = useState(document);

  useEffect(() => {
    setCurrentDocument(document);
    setNewName(document.name);
  }, [document]);

  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      setNewName(e.target.value);
      await handleRenameDocument();
    }
  }

  const handleRenameDocument = async () => {
    try {
      setIsShown(false);
      if (currentDocument.id) {
        await documentService.rename(currentDocument.id, newName);
      }
      setIsShown(false);
    } finally {
    }
  };

  return (
    <Dialog
      isShown={isShown}
      title="Rename Document"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Save"
      onConfirm={handleRenameDocument}
    >
      <TextInput
        width="100%"
        required
        name="name"
        autoFocus={true}
        value={newName}
        onKeyDown={handleKeyDown}
        onChange={(e: any) => setNewName(e.target.value)}
      />
    </Dialog>
  );
}
