import {useEffect} from "react";

function useSelection(editorUi, callback) {

	useEffect(() => {
    const graph = editorUi.editor.graph;
    const selectionShape = graph.getSelectionModel();
    if(!selectionShape) return ;
    // Binding event when the selection shape is changed
    selectionShape.addListener("change", callback);

    return () => {
      selectionShape.removeListener("change", callback);
    };
	}, []);

	return true;
}

export default useSelection;
