import React from 'react';
import {Card, Heading, minorScale, Pane, Paragraph} from 'evergreen-ui';
import logo from 'assets/img/logo.svg';
import {HOME_PAGE} from "../../utils/constants";

type Props = {
  title: string,
  subtitle?: string,
  children: any,
  className?: string
}

const CardAuth = ({ title, subtitle, children, className}: Props) => {
  return (
    <Card
      paddingX="32px"
      paddingY="33px"
      boxShadow="0px 4px 8px #10184014;"
      backgroundColor="#FFFFFF"
      marginLeft={"unset"}
      marginBottom={minorScale(6)}
      className={className}
    >
      <Pane className="text-center" cursor={"pointer"}>
        <img src={logo} width="40" alt="logo" onClick={() => window.location.href=HOME_PAGE}/>
      </Pane>
      {title && <Heading size={800} className="text-center mt-4">{title}</Heading>}
      {subtitle && <Paragraph className="text-center mt-2">{subtitle}</Paragraph>}
      {children}
    </Card>
  )
};

export default CardAuth
