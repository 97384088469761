import { db } from 'utils/firebase';
import {
  DOCUMENT_COLLECTION,
  COMMENT_COLLECTION,
  CollectionReference,
} from 'utils/constants';
import { getUser } from '../../utils/store';
import { UserSchema } from '../../schemas';

/**
 * Comment Service
 *
 */
export class CommentService {
  /**
   * Group of comments in parent
   *
   * @private
   */
  private parentId: string;

  /**
   * User reference
   *
   * @private
   */
  private user: UserSchema;

  /**
   * Database instance
   *
   * @private
   */
  private db: CollectionReference;

  /**
   * Constructor
   *
   * @param documentId document id
   */
  constructor(documentId: string) {
    this.user = getUser();
    this.db = db
      .collection(DOCUMENT_COLLECTION)
      .doc(documentId)
      .collection(COMMENT_COLLECTION);
  }

  /**
   * Set parent ID
   *
   * @param parentId
   */
  setParent(parentId: string) {
    this.parentId = parentId;
    return this;
  }

  /**
   * Add new comment into database
   *
   * @param comment comment want to add
   */
  addComment(comment: string) {
    const date = new Date();
    const data: any = {
      user: this.user.uid,
      comment,
      isResolved: false,
      createdAt: date,
      updatedAt: date,
    };
    if (this.parentId) {
      data.parent = this.parentId;
    }
    return this.db.add(data);
  }

  /**
   * Update of existing comment
   * @param id id of comment
   * @param comment text want to update
   */
  updateComment(id: string, comment: string) {
    return this.db.doc(id).update({ comment, updatedAt: new Date() });
  }

  /**
   * Delete of existing comment
   *
   * @param id id of comment
   */
  deleteComment(id: string) {
    return this.db.doc(id).delete();
  }
}
