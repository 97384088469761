import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import { fuego } from 'utils/firebase';
import App from 'pages/App/App';
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StoresProvider } from './hooks/useStores';
import { AppProvider } from './hooks/useApp';
import { environment, SENTRY_DSN } from './utils/constants';
//recoil
import {
  RecoilRoot
} from 'recoil';
import "react-datepicker/dist/react-datepicker.css";

// react-datepicker
import './assets/styles/react-datepicker.module.scss';

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
window.process = process;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: `zen-flowchart@${process.env.REACT_APP_VERSION}`,
  environment,
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <FuegoProvider fuego={fuego}>
        <DndProvider backend={isFirefox ? HTML5Backend : TouchBackend} options={{ enableMouseEvents: true }}>
          <AppProvider>
            <StoresProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </StoresProvider>
          </AppProvider>
        </DndProvider>
      </FuegoProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
