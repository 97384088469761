/* eslint-disable */

import mx from '../mx';
import {
  mxUtils,
  mxEvent,
  mxRectangle,
  mxGeometry,
  mxCell,
  mxEventObject,
  mxPoint,
} from '../';

const mxGraph = mx.mxGraph;

/**
 * Group: Order
 */

/**
 * Function: orderCells
 *
 * Moves the given cells to the front or back. The change is carried out
 * using <cellsOrdered>. This method fires <mxEvent.ORDER_CELLS> while the
 * transaction is in progress.
 *
 * Parameters:
 *
 * back - Boolean that specifies if the cells should be moved to back.
 * cells - Array of <mxCells> to move to the background. If null is
 * specified then the selection cells are used.
 */
mxGraph.prototype.orderCells = function (back, cells) {
  if (cells == null) {
    cells = mxUtils.sortCells(this.getSelectionCells(), true);
  }
  this.model.beginUpdate();
  try {
    this.cellsOrdered(cells, back);
    this.fireEvent(
      new mxEventObject(mxEvent.ORDER_CELLS, 'back', back, 'cells', cells),
    );
  } finally {
    this.model.endUpdate();
  }

  return cells;
};

/**
 * Function: cellsOrdered
 *
 * Moves the given cells to the front or back. This method fires
 * <mxEvent.CELLS_ORDERED> while the transaction is in progress.
 *
 * Parameters:
 *
 * cells - Array of <mxCells> whose order should be changed.
 * back - Boolean that specifies if the cells should be moved to back.
 */
mxGraph.prototype.cellsOrdered = function (cells, back) {
  if (cells != null) {
    this.model.beginUpdate();
    try {
      for (var i = 0; i < cells.length; i++) {
        var parent = this.model.getParent(cells[i]);

        if (back) {
          this.model.add(parent, cells[i], i);
        } else {
          this.model.add(
            parent,
            cells[i],
            this.model.getChildCount(parent) - 1,
          );
        }
      }

      this.fireEvent(
        new mxEventObject(mxEvent.CELLS_ORDERED, 'back', back, 'cells', cells),
      );
    } finally {
      this.model.endUpdate();
    }
  }
};

/**
 * Function: orderCellsForward
 *
 * Moves the given cells to the front or back. The change is carried out
 * using <cellsOrdered>. This method fires <mxEvent.ORDER_CELLS> while the
 * transaction is in progress.
 *
 * Parameters:
 *
 * back - Boolean that specifies if the cells should be moved to back.
 * cells - Array of <mxCells> to move to the background. If null is
 * specified then the selection cells are used.
 */
mxGraph.prototype.orderCellsForward = function (back, cells) {
  if (cells == null) {
    cells = mxUtils.sortCells(this.getSelectionCells(), true);
  }
  this.model.beginUpdate();
  try {
    this.cellsOrderedForward(cells, back);
    this.fireEvent(
      new mxEventObject(mxEvent.ORDER_CELLS, 'back', back, 'cells', cells),
    );
  } finally {
    this.model.endUpdate();
  }

  return cells;
};

/**
 * Function: cellsOrderedForward
 *
 * Moves the given cells to the front or back. This method fires
 * <mxEvent.CELLS_ORDERED> while the transaction is in progress.
 *
 * Parameters:
 *
 * cells - Array of <mxCells> whose order should be changed.
 * back - Boolean that specifies if the cells should be moved to back.
 */

mxGraph.prototype.cellsOrderedForward = function (cells, back) {
  if (cells != null) {
    this.model.beginUpdate();
    try {
      for (var i = 0; i < cells.length; i++) {
        var parent = this.model.getParent(cells[i]);
        if (back) {
          this.model.add(
            parent,
            cells[i],
            parent.children.indexOf(cells[i]) - 1,
          );
        } else {
          this.model.add(
            parent,
            cells[i],
            parent.children.indexOf(cells[i]) + 1,
          );
        }
      }

      this.fireEvent(
        new mxEventObject(mxEvent.CELLS_ORDERED, 'back', back, 'cells', cells),
      );
    } finally {
      this.model.endUpdate();
    }
  }
};
/**
 * Group: Grouping
 */

/**
 * Function: groupCells
 *
 * Adds the cells into the given group. The change is carried out using
 * <cellsAdded>, <cellsMoved> and <cellsResized>. This method fires
 * <mxEvent.GROUP_CELLS> while the transaction is in progress. Returns the
 * new group. A group is only created if there is at least one entry in the
 * given array of cells.
 *
 * Parameters:
 *
 * group - <mxCell> that represents the target group. If null is specified
 * then a new group is created using <createGroupCell>.
 * border - Optional integer that specifies the border between the child
 * area and the group bounds. Default is 0.
 * cells - Optional array of <mxCells> to be grouped. If null is specified
 * then the selection cells are used.
 */
mxGraph.prototype.groupCells = function (group, border, cells) {
  if (cells == null) {
    cells = mxUtils.sortCells(this.getSelectionCells(), true);
  }

  cells = this.getCellsForGroup(cells);
  if (group == null) {
    group = this.createGroupCell(cells);
  }

  var bounds = this.getBoundsForGroup(group, cells, border);

  if (cells.length > 1 && bounds != null) {
    // Uses parent of group or previous parent of first child
    var parent = this.model.getParent(group);

    if (parent == null) {
      parent = this.model.getParent(cells[0]);
    }

    this.model.beginUpdate();
    try {
      // Checks if the group has a geometry and
      // creates one if one does not exist
      if (this.getCellGeometry(group) == null) {
        this.model.setGeometry(group, new mxGeometry());
      }

      // Adds the group into the parent
      var index = this.model.getChildCount(parent);
      this.cellsAdded([group], parent, index, null, null, false, false, false);

      // Adds the children into the group and moves
      index = this.model.getChildCount(group);
      console.log('group', group);
      this.cellsAdded(cells, group, index, null, null, false, false, false);
      this.cellsMoved(cells, -bounds.x, -bounds.y, false, false, false);

      // Resizes the group
      this.cellsResized([group], [bounds], false);

      this.fireEvent(
        new mxEventObject(
          mxEvent.GROUP_CELLS,
          'group',
          group,
          'border',
          border,
          'cells',
          cells,
        ),
      );
    } finally {
      this.model.endUpdate();
    }
  }

  return group;
};

/**
 * Function: getCellsForGroup
 *
 * Returns the cells with the same parent as the first cell
 * in the given array.
 */
mxGraph.prototype.getCellsForGroup = function (cells) {
  console.log('getCellsForGroup');
  var result = [];

  if (cells != null && cells.length > 0) {
    var parent = this.model.getParent(cells[0]);
    result.push(cells[0]);

    // Filters selection cells with the same parent
    for (var i = 1; i < cells.length; i++) {
      if (this.model.getParent(cells[i]) === parent) {
        result.push(cells[i]);
      }
    }
  }

  return result;
};

/**
 * Function: getBoundsForGroup
 *
 * Returns the bounds to be used for the given group and children.
 */
mxGraph.prototype.getBoundsForGroup = function (group, children, border) {
  console.log('getBoundsForGroup');
  var result = this.getBoundingBoxFromGeometry(children, true);

  if (result != null) {
    if (this.isSwimlane(group)) {
      var size = this.getStartSize(group);

      result.x -= size.width;
      result.y -= size.height;
      result.width += size.width;
      result.height += size.height;
    }

    // Adds the border
    if (border != null) {
      result.x -= border;
      result.y -= border;
      result.width += 2 * border;
      result.height += 2 * border;
    }
  }

  return result;
};

/**
 * Function: createGroupCell
 *
 * Hook for creating the group cell to hold the given array of <mxCells> if
 * no group cell was given to the <group> function.
 *
 * The following code can be used to set the style of new group cells.
 *
 * (code)
 * var graphCreateGroupCell = graph.createGroupCell;
 * graph.createGroupCell = function(cells)
 * {
 *   var group = graphCreateGroupCell.apply(this, arguments);
 *   group.setStyle('group');
 *
 *   return group;
 * };
 */
mxGraph.prototype.createGroupCell = function (cells) {
  var group = new mxCell('');
  group.setVertex(true);
  group.setConnectable(false);

  return group;
};

/**
 * Function: ungroupCells
 *
 * Ungroups the given cells by moving the children the children to their
 * parents parent and removing the empty groups. Returns the children that
 * have been removed from the groups.
 *
 * Parameters:
 *
 * cells - Array of cells to be ungrouped. If null is specified then the
 * selection cells are used.
 */
mxGraph.prototype.ungroupCells = function (cells) {
  var result = [];

  if (cells == null) {
    cells = this.getCellsForUngroup();
  }

  if (cells != null && cells.length > 0) {
    this.model.beginUpdate();
    try {
      for (var i = 0; i < cells.length; i++) {
        var children = this.model.getChildren(cells[i]);

        if (children != null && children.length > 0) {
          children = children.slice();
          var parent = this.model.getParent(cells[i]);
          var index = this.model.getChildCount(parent);

          this.cellsAdded(children, parent, index, null, null, true);
          result = result.concat(children);

          // Fix relative child cells
          for (var j = 0; j < children.length; j++) {
            var state = this.view.getState(children[j]);
            var geo = this.getCellGeometry(children[j]);

            if (state != null && geo != null && geo.relative) {
              geo = geo.clone();
              geo.x = state.origin.x;
              geo.y = state.origin.y;
              geo.relative = false;

              this.model.setGeometry(children[j], geo);
            }
          }
        }
      }

      this.removeCellsAfterUngroup(cells);
      this.fireEvent(new mxEventObject(mxEvent.UNGROUP_CELLS, 'cells', cells));
    } finally {
      this.model.endUpdate();
    }
  }

  return result;
};

/**
 * Function: getCellsForUngroup
 *
 * Returns the selection cells that can be ungrouped.
 */
mxGraph.prototype.getCellsForUngroup = function () {
  var cells = this.getSelectionCells();

  // Finds the cells with children
  var tmp = [];

  for (var i = 0; i < cells.length; i++) {
    if (
      this.model.isVertex(cells[i]) &&
      this.model.getChildCount(cells[i]) > 0
    ) {
      tmp.push(cells[i]);
    }
  }

  return tmp;
};

/**
 * Function: removeCellsAfterUngroup
 *
 * Hook to remove the groups after <ungroupCells>.
 *
 * Parameters:
 *
 * cells - Array of <mxCells> that were ungrouped.
 */
mxGraph.prototype.removeCellsAfterUngroup = function (cells) {
  this.cellsRemoved(this.addAllEdges(cells));
};

/**
 * Function: removeCellsFromParent
 *
 * Removes the specified cells from their parents and adds them to the
 * default parent. Returns the cells that were removed from their parents.
 *
 * Parameters:
 *
 * cells - Array of <mxCells> to be removed from their parents.
 */
mxGraph.prototype.removeCellsFromParent = function (cells) {
  if (cells == null) {
    cells = this.getSelectionCells();
  }

  this.model.beginUpdate();
  try {
    var parent = this.getDefaultParent();
    var index = this.model.getChildCount(parent);

    this.cellsAdded(cells, parent, index, null, null, true);
    this.fireEvent(
      new mxEventObject(mxEvent.REMOVE_CELLS_FROM_PARENT, 'cells', cells),
    );
  } finally {
    this.model.endUpdate();
  }

  return cells;
};

/**
 * Function: updateGroupBounds
 *
 * Updates the bounds of the given groups to include all children and returns
 * the passed-in cells. Call this with the groups in parent to child order,
 * top-most group first, the cells are processed in reverse order and cells
 * with no children are ignored.
 *
 * Parameters:
 *
 * cells - The groups whose bounds should be updated. If this is null, then
 * the selection cells are used.
 * border - Optional border to be added in the group. Default is 0.
 * moveGroup - Optional boolean that allows the group to be moved. Default
 * is false.
 * topBorder - Optional top border to be added in the group. Default is 0.
 * rightBorder - Optional top border to be added in the group. Default is 0.
 * bottomBorder - Optional top border to be added in the group. Default is 0.
 * leftBorder - Optional top border to be added in the group. Default is 0.
 */
mxGraph.prototype.updateGroupBounds = function (
  cells,
  border,
  moveGroup,
  topBorder,
  rightBorder,
  bottomBorder,
  leftBorder,
) {
  if (cells == null) {
    cells = this.getSelectionCells();
  }

  border = border != null ? border : 0;
  moveGroup = moveGroup != null ? moveGroup : false;
  topBorder = topBorder != null ? topBorder : 0;
  rightBorder = rightBorder != null ? rightBorder : 0;
  bottomBorder = bottomBorder != null ? bottomBorder : 0;
  leftBorder = leftBorder != null ? leftBorder : 0;

  this.model.beginUpdate();
  try {
    for (var i = cells.length - 1; i >= 0; i--) {
      var geo = this.getCellGeometry(cells[i]);

      if (geo != null) {
        var children = this.getChildCells(cells[i]);

        if (children != null && children.length > 0) {
          var bounds = this.getBoundingBoxFromGeometry(children, true);

          if (bounds != null && bounds.width > 0 && bounds.height > 0) {
            // Adds the size of the title area for swimlanes
            var size = this.isSwimlane(cells[i])
              ? this.getActualStartSize(cells[i], true)
              : new mxRectangle();
            geo = geo.clone();

            if (moveGroup) {
              geo.x = Math.round(
                geo.x + bounds.x - border - size.x - leftBorder,
              );
              geo.y = Math.round(
                geo.y + bounds.y - border - size.y - topBorder,
              );
            }

            geo.width = Math.round(
              bounds.width +
                2 * border +
                size.x +
                leftBorder +
                rightBorder +
                size.width,
            );
            geo.height = Math.round(
              bounds.height +
                2 * border +
                size.y +
                topBorder +
                bottomBorder +
                size.height,
            );

            this.model.setGeometry(cells[i], geo);
            this.moveCells(
              children,
              border + size.x - bounds.x + leftBorder,
              border + size.y - bounds.y + topBorder,
            );
          }
        }
      }
    }
  } finally {
    this.model.endUpdate();
  }

  return cells;
};

/**
 * Function: getBoundingBox
 *
 * Returns the bounding box for the given array of <mxCells>. The bounding box for
 * each cell and its descendants is computed using <mxGraphView.getBoundingBox>.
 *
 * Parameters:
 *
 * cells - Array of <mxCells> whose bounding box should be returned.
 */
mxGraph.prototype.getBoundingBox = function (cells) {
  var result = null;

  if (cells != null && cells.length > 0) {
    for (var i = 0; i < cells.length; i++) {
      if (this.model.isVertex(cells[i]) || this.model.isEdge(cells[i])) {
        var bbox = this.view.getBoundingBox(this.view.getState(cells[i]), true);

        if (bbox != null) {
          if (result == null) {
            result = mxRectangle.fromRectangle(bbox);
          } else {
            result.add(bbox);
          }
        }
      }
    }
  }

  return result;
};

/**
 * Function: addCells
 *
 * Adds the cells to the parent at the given index, connecting each cell to
 * the optional source and target terminal. The change is carried out using
 * <cellsAdded>. This method fires <mxEvent.ADD_CELLS> while the
 * transaction is in progress. Returns the cells that were added.
 *
 * Parameters:
 *
 * cells - Array of <mxCells> to be inserted.
 * parent - <mxCell> that represents the new parent. If no parent is
 * given then the default parent is used.
 * index - Optional index to insert the cells at. Default is to append.
 * source - Optional source <mxCell> for all inserted cells.
 * target - Optional target <mxCell> for all inserted cells.
 * absolute - Optional boolean indicating of cells should be kept at
 * their absolute position. Default is false.
 */
mxGraph.prototype.addCells = function (
  cells,
  parent,
  index,
  source,
  target,
  absolute,
) {
  if (parent == null) {
    parent = this.getDefaultParent();
  }

  if (index == null) {
    index = this.model.getChildCount(parent);
  }

  this.model.beginUpdate();
  try {
    this.cellsAdded(
      cells,
      parent,
      index,
      source,
      target,
      absolute != null ? absolute : false,
      true,
    );
    this.fireEvent(
      new mxEventObject(
        mxEvent.ADD_CELLS,
        'cells',
        cells,
        'parent',
        parent,
        'index',
        index,
        'source',
        source,
        'target',
        target,
      ),
    );
  } finally {
    this.model.endUpdate();
  }

  return cells;
};

/**
 * Function: cellsAdded
 *
 * Adds the specified cells to the given parent. This method fires
 * <mxEvent.CELLS_ADDED> while the transaction is in progress.
 */
mxGraph.prototype.cellsAdded = function (
  cells,
  parent,
  index,
  source,
  target,
  absolute,
  constrain,
  extend,
) {
  if (cells != null && parent != null && index != null) {
    this.model.beginUpdate();
    try {
      var parentState = absolute ? this.view.getState(parent) : null;
      var o1 = parentState != null ? parentState.origin : null;
      var zero = new mxPoint(0, 0);

      for (var i = 0; i < cells.length; i++) {
        if (cells[i] == null) {
          index--;
        } else {
          var previous = this.model.getParent(cells[i]);

          // Keeps the cell at its absolute location
          if (o1 != null && cells[i] != parent && parent != previous) {
            var oldState = this.view.getState(previous);
            var o2 = oldState != null ? oldState.origin : zero;
            var geo = this.model.getGeometry(cells[i]);

            if (geo != null) {
              var dx = o2.x - o1.x;
              var dy = o2.y - o1.y;

              // FIXME: Cells should always be inserted first before any other edit
              // to avoid forward references in sessions.
              geo = geo.clone();
              geo.translate(dx, dy);

              if (
                !geo.relative &&
                this.model.isVertex(cells[i]) &&
                !this.isAllowNegativeCoordinates()
              ) {
                geo.x = Math.max(0, geo.x);
                geo.y = Math.max(0, geo.y);
              }

              this.model.setGeometry(cells[i], geo);
            }
          }

          // Decrements all following indices
          // if cell is already in parent
          if (
            parent == previous &&
            index + i > this.model.getChildCount(parent)
          ) {
            index--;
          }

          this.model.add(parent, cells[i], index + i);

          if (this.autoSizeCellsOnAdd) {
            this.autoSizeCell(cells[i], true);
          }

          // Extends the parent or constrains the child
          if (
            (extend == null || extend) &&
            this.isExtendParentsOnAdd(cells[i]) &&
            this.isExtendParent(cells[i])
          ) {
            this.extendParent(cells[i]);
          }

          // Additionally constrains the child after extending the parent
          if (constrain == null || constrain) {
            this.constrainChild(cells[i]);
          }

          // Sets the source terminal
          if (source != null) {
            this.cellConnected(cells[i], source, true);
          }

          // Sets the target terminal
          if (target != null) {
            this.cellConnected(cells[i], target, false);
          }
        }
      }

      this.fireEvent(
        new mxEventObject(
          mxEvent.CELLS_ADDED,
          'cells',
          cells,
          'parent',
          parent,
          'index',
          index,
          'source',
          source,
          'target',
          target,
          'absolute',
          absolute,
        ),
      );
    } finally {
      this.model.endUpdate();
    }
  }
};

export { mxGraph };
