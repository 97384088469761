import {useCollection} from "@nandorojo/swr-firestore";
import {TASK_COLLECTION} from "../../utils/constants";
import {getUser} from "../../utils/store";
import {useEffect, useState} from "react";
import _ from "lodash"

export enum VIEW_MODE {
  INBOX,
  TODAY,
  SOMEDAY,
  TOME,
  TRASH,
  PROJECT
}

export enum TASK_TYPE {
  TASK,
  HEADING,
}

type Props = {
  projectId?: string,
  viewMode?: VIEW_MODE,
  today?: any
}

export const useTasks = ({projectId, viewMode}: Props) => {
  const user = getUser();
  const [todoTasks, setTodoTasks] = useState([] as any[]);
  const [today] = useState((new Date()).getTime());
  const [completeTasks, setCompleteTasks] = useState([] as any[]);
  const [completeItemCount, setCompleteItemCount] = useState(0);
  const [projectsTodoTasks, setProjectsTodoTasks] = useState({});
  const [projectsCompleteTasks, setProjectsCompleteTasks] = useState({});

  const greaterThanDate = new Date(today);
  greaterThanDate.setHours(0, 0, 0)
  const lessThanDate = new Date(today);
  lessThanDate.setHours(23, 59, 59)

  const queryMap = {
    [VIEW_MODE.INBOX]: [
      ['createdBy', '==', user.uid],
      ['project', '==', null],
      ['isPrivate', '==', true],
      ['isDelete', '==', false],
      ['viewMode', '==', VIEW_MODE.INBOX]
    ],
    [VIEW_MODE.TODAY]: [
      ['createdBy', '==', user.uid],
      ['isDelete', '==', false],
      ['dueDate', '>=', greaterThanDate.getTime()],
      ['dueDate', '<=', lessThanDate.getTime()],
      ['viewMode', 'in', [VIEW_MODE.INBOX, VIEW_MODE.PROJECT]]
    ],
    [VIEW_MODE.SOMEDAY]: [
      ['createdBy', '==', user.uid],
      ['project', '==', null],
      ['isDelete', '==', false],
      // ['dueDate', '==', undefined],
      ['viewMode', '==', VIEW_MODE.SOMEDAY]
    ],
    [VIEW_MODE.TOME]: [
      [`assignees.${user.uid}.id`, 'in', [user.uid]],
      ['isDelete', '==', false]
    ],
    [VIEW_MODE.PROJECT]: [
      ['project', '==', projectId],
      ['isDelete', '==', false]
    ],
  }

  const isProjectTask = !!projectId;
  const query = (isProjectTask ? VIEW_MODE.PROJECT : (viewMode || VIEW_MODE.INBOX)).valueOf();
  const whereClause = _.get(queryMap, query, []) as any;

  const { data: tasks } = useCollection<any>(TASK_COLLECTION, {
    where: whereClause,
    listen: true,
  });

  useEffect(() => {
    const todo = (tasks?.filter(task => !task.isComplete) || [])
      .sort((first, second) => {
        return first.ordinal > second.ordinal ? -1 : 1;
      })
    const complete = (tasks?.filter(task => task.isComplete) || [])
      .sort((first, second) => first.completeDate > second.completeDate ? -1 : 1);

    setTodoTasks(todo);
    setCompleteTasks(complete);
    setCompleteItemCount(complete.length);

    const todoGroupByProject = _.mapValues(_.groupBy(todo, 'project'),
      tasks => tasks.map(task => _.omit(task, 'project')));
    setProjectsTodoTasks(todoGroupByProject);

    const completeGroupByProject = _.mapValues(_.groupBy(complete, 'project'),
      tasks => tasks.map(task => _.omit(task, 'project')));
    setProjectsCompleteTasks(completeGroupByProject);

  }, [tasks])

  return {
    todoTasks: todoTasks,
    completeTasks: completeTasks,
    completeItemCount,
    projectsTodoTasks,
    projectsCompleteTasks
  }
}

export const useTrashTasks = () => {
  const user = getUser();
  const [deletedTasks, setDeletedTasks] = useState([] as any[]);

  const { data: rawTasks } = useCollection<any>(TASK_COLLECTION, {
    where: [
      ['isDeletedBy', '==', user.uid],
      ['isDelete', '==', true],
    ],
    listen: true,
  });

  useEffect(() => {
    const sortByDeleted = (rawTasks || []).sort(
      (first: any, second: any) => first.deletedAt > second.deletedAt ? -1 : 1
    );
    setDeletedTasks(sortByDeleted);
  }, [rawTasks]);

  return {
    deletedTasks
  }
}
