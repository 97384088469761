import firebase from 'firebase';
import { STRIPE_PUBLISHABLE_KEY, STRIPE_SECRET_KEY, STRIPE_TOKEN_URL } from 'utils/constants';
import { functions } from 'utils/firebase';
import { UpgradeTier } from '../schemas';

/**
 * Stripe credit card validator
 */
interface StripeCreditCard {
  number: string,
  cvc: string,
  expMonth: string,
  expYear: string,
}

/**
 * Stripe Subscription Params
 */
interface StripeSubscriptionParams {
  plan: string,
  quantity: number,
  upgradeTier: UpgradeTier,
  paymentMethod?: string,
}

/**
 * Stripe Subscription Params
 */
interface Stripe3DSecurePaymentParams {
  quantity: number;
  upgradeTier: UpgradeTier;
  subscription: string;
  paymentToken: string;
}

/**
 * Stripe Session Params
 */
interface StripeSessionParams {
  sessionId: string;
}

/**
 * Stripe Subscription Params
 */
interface StripeCheckoutParams {
  customer: string;
  priceId: string;
  quantity?: number;
  allowAdjustQuantity: boolean;
}


/**
 * List of available firebase functions
 */
enum StripeFunction {
  CreateStripeSubscription = 'createStripeSubscription',
  Confirm3DSecurePayment = 'confirm3DSecurePayment',
  CreatePortalLink = 'createPortalLink',
  CreateCheckoutSession = 'createCheckoutSession',
  CheckoutSession = 'checkoutSession'
}

/**
 * Stripe service
 */
export class StripeService {
  /**
   * Stripe publish key
   * @private
   */
  private readonly publishableKey: string;

  /**
   * Stripe secret key
   * @private
   */
  private readonly secretKey: string;

  /**
   * Firebase functions
   * @private
   */
  private readonly functions: firebase.functions.Functions;

  /**
   * Initialize publish and secret key
   */
  constructor() {
    this.publishableKey = STRIPE_PUBLISHABLE_KEY;
    this.secretKey = STRIPE_SECRET_KEY;
    this.functions = functions
  }

  /**
   * Create stripe token for insensitive credit card
   *
   * @param number
   * @param cvc
   * @param expMonth
   * @param expYear
   */
  async createToken({ number, cvc, expMonth, expYear } : StripeCreditCard) {
    const response = await fetch(STRIPE_TOKEN_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${this.secretKey}`
      },
      body: new URLSearchParams({
        key: this.publishableKey,
        'card[number]': number,
        'card[cvc]': cvc,
        'card[exp_month]': expMonth,
        'card[exp_year]': expYear,
      })
    });

    return response.json();
  }

  /**
   * Create Stripe Subscription Functions
   *
   */
  async createSubscription(params: StripeSubscriptionParams): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(StripeFunction.CreateStripeSubscription)
    return callable(params);
  }

  /**
   * Confirm 3D Secure Payment Functions
   *
   */
  async confirm3DSecurePayment(params: Stripe3DSecurePaymentParams): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(StripeFunction.Confirm3DSecurePayment)
    return callable(params);
  }

  /**
   * Create Stripe Subscription Functions
   *
   */
  async checkoutSession(params: StripeSessionParams): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(StripeFunction.CheckoutSession);
    return callable(params);
  }

  /**
   * Create Stripe Subscription Functions
   *
   */
  async createCheckoutSession(params: StripeCheckoutParams): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(StripeFunction.CreateCheckoutSession)
    return callable(params);
  }

  /**
   * Create Portal Link from subscription
   *
   */
  async createPortalLink(): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(StripeFunction.CreatePortalLink)
    return callable({ returnUrl: window.location.origin + window.location.pathname });
  }
}
