var ZwfConstants = {
  STYLE_SHAPE: 'shape',
  STYLE_TYPE: 'zwfType',
  /**
   * Variable: STYLE_KEYBOARD_STATE
   *
   * Define key for keyboard state. this will include two value
   * of key board, one is visible, the other is invisible
   */
  STYLE_KEYBOARD_STATE: 'zwfKeyboardState',

  /**
   * Variable: STYLE_KEYBOARD_TYPE
   *
   * Define key for keyboard type. It consists of two types
   * one is qwerty and other is numpad
   */
  STYLE_KEYBOARD_TYPE: 'zwfKeyboardType',

  /**
   * Variable: STYLE_KEYBOARD_ORIENTATION
   *
   * Define key for frame orientation. currently, frame
   * can be portrait or can be landscape
   */
  STYLE_FRAME_ORIENTATION: 'zwfFrameOrientation',

  /**
   * Variable: STYLE_TEXT_SIZE
   *
   * Define key for text size, this can be used to change
   * size of text
   */
  STYLE_TEXT_SIZE: 'zwfTextSize',

  /**
   * Variable: KEYBOARD_STATE_VISIBLE
   *
   * Constant for keyboard state visible. Default is visible. Use this
   * value to change to visible.
   */
  KEYBOARD_STATE_VISIBLE: 'visible',

  /**
   * Variable: KEYBOARD_STATE_HIDDEN
   *
   * Constant for keyboard state hidden. Default is hidden. Use this
   * value to change to hidden.
   */
  KEYBOARD_STATE_HIDDEN: 'hidden',

  /**
   * Variable: KEYBOARD_TYPE_QWERTY
   *
   * Constant for keyboard type qwerty. Default is qwerty. Use this
   * value to change to qwerty.
   */
  KEYBOARD_TYPE_QWERTY: 'qwerty',

  /**
   * Variable: KEYBOARD_TYPE_NUMPAD
   *
   * Constant for keyboard type numpad. Default is numpad. Use this
   * value to change to numpad.
   */
  KEYBOARD_TYPE_NUMPAD: 'numpad',

  /**
   * Variable: FRAME_ORIENTATION_PORTRAIT
   *
   * Constant for frame orientation portrait. Default is portrait. Use this
   * value to change to portrait.
   */
  FRAME_ORIENTATION_PORTRAIT: 'portrait',

  /**
   * Variable: FRAME_ORIENTATION_LANDSCAPE
   *
   * Constant for text small size. Default is zwfSSize. Use this
   * value to change to small size.
   */
  FRAME_ORIENTATION_LANDSCAPE: 'landscape',

  /**
   * Variable: TEXT_SIZE_SMALL
   *
   * Constant for text small size. Default is zwfSSize. Use this
   * value to change to small size.
   */
  TEXT_SIZE_SMALL: 'zwfSSize',

  /**
   * Variable: TEXT_SIZE_MEDIUM
   *
   * Constant for text medium size. Default is zwfMSize. Use this
   * value to change text size to medium.
   */
  TEXT_SIZE_MEDIUM: 'zwfMSize',

  /**
   * Variable: TEXT_SIZE_LARGE
   *
   * Constant for text large size. Default is zwfLSize. Use this
   * value to change text size to large.
   */
  TEXT_SIZE_LARGE: 'zwfLSize',

  /**
   * Variable: PORTRAIT_SIZE
   *
   * Size of portrait frame
   * value to change text size to large.
   */
  PORTRAIT_SIZE: 'zwfPortraitSize',

  /**
   * Variable: LANDSCAPE_SIZE
   *
   * Size of portrait frame
   * value to change text size to large.
   */
  LANDSCAPE_SIZE: 'zwfLandscapeSize',

  /**
   * Variable: NO RESIZE
   *
   * Constant for prevent resize.
   */
  NO_RESIZE: 'zwfNoResize',

  /**
   * Variable: WIDTH_RESIZE
   *
   * Constant for width resize. Use this value to prevent resize height
   */
  WIDTH_RESIZE: 'zwfWidthResizeOnly',

  /**
   * Variable: ACTION_TEXT_ALIGNMENT
   *
   * Constant for width text alignment. Use this value to align text
   */
  ACTION_TEXT_ALIGNMENT: 'zwfTextAlignment',

  /**
   * Variable: ACTION_TEXT_STYLE
   *
   * Constant for width text style. Use this value to styling text
   */
  ACTION_TEXT_STYLE: 'zwfTextStyle',

  ACTION_TEXT_COLOR: 'actionTextColor',

  /**
   * Variable: HEIGHT_RESIZE
   *
   * Constant for height resize. Use this value to prevent resize width
   */
  HEIGHT_RESIZE: 'zwfHeightResizeOnly',

  /**
   * Variable: NOT_SUPPORT
   *
   * Constant for text small size. Default is zwfSSize. Use this
   * value to change to small size.
   */

  /**
   * Variable: TOGGLE STATE
   *
   * Define key for toggle panel. currently, toggle
   * can be on or can be off
   */
  TOGGLE_STATE: 'zwfToggleState',

  /**
   * Variable: TOGGLE STATE ON
   *
   * Define value for toggle panel. Display status checked of toggle
   */
  TOGGLE_STATE_ON: 'on',

  /**
   * Variable: TOGGLE STATE OFF
   *
   * Define value for toggle panel. Display status unchecked of toggle
   */
  TOGGLE_STATE_OFF: 'off',

  /**
   * Variable: FREE SIZE
   *
   * Allow component resize with keep ratio
   */
  FREE_SIZE: 'zwfFreeSize',

  /**
   * Variable: POSITION STATE
   *
   * Define key for position panel
   *
   */
  POSITION_STATE: 'zwfPosition',

  /**
   * Variable: POSITION STATE OPTIONS
   *
   * Define options for position state
   *
   */
  POSITION_LEFT: 'left',
  POSITION_TOP: 'top',
  POSITION_RIGHT: 'right',
  POSITION_BOTTOM: 'bottom',

  /**
   * Variable: SIZE SELECTION
   *
   * Define key for quick size selection
   *
   */
  SIZE_SELECTION: 'zwfSize',

  /**
   * Variable: LINE STYLE
   *
   * Define line style
   *
   */
  STYLE_STARTARROW: 'startArrow',
  STYLE_ENDARROW: 'endArrow',
  STYLE_DASHED: 'dashed',
  STYLE_STROKEWIDTH: 'strokeWidth',
  IS_LINE_THICKNESS: 'isLineThickness',

  LINE_ROUNDED: 'rounded',
  LINE_CURVED: 'curved',

  TYPE_BASIC_SHAPE: 'basicShape',

  TYPE_LINE: "isLine",

  TYPE_LINE_LABEL: "lineLabel",

  IS_VERTEX: "isVertex",

  NOT_SUPPORT: null,

  TYPE_BUTTON: 'button',
  TYPE_CIRCLE: 'circle',
  TYPE_CIRCLE_BUTTON: 'circleButton',
  TYPE_ANNOTATION: 'annotation',
  TYPE_MOBILE_MENU_ROW: 'mobileMenuRow',

  PANEL_ALL: 'panelAll',

  PANEL_SIZE: 'panelSize',

  PANEL_COLOR: 'panelColor',

  SHAPE_PANEL_LABEL: 'shapePanelLabel',

  SHAPE_PANEL_COLOR: 'shapePanelColor',

  PANEL_TEXT_COLOR: 'panelTextColor',

  PANEL_BRUSH_COLOR: 'panelBrushColor',

  PANEL_TEXT: 'panelText',

  PANEL_ICON: 'panelIcon',

  PANEL_ORIENTATION: 'panelOrientation',

  PANEL_KEYBOARD: 'panelKeyboard',

  PANEL_POSITION: 'panelPosition',

  PANEL_HEADING : 'panelHeading',

  PANEL_ALIGNMENT: 'panelAlignment',

  PANEL_TOGGLE: 'panelToggle',

  PANEL_BUTTON_STATE: 'panelButtonState',

  PANEL_LINE: 'panelLine',

  ACTION_QUICK_SIZE: 'actionQuickSize',

  ACTION_MANUAL_RESIZE: 'actionManualResize',

  ACTION_KEYBOARD_STATE: 'actionKeyboardState',

  ACTION_KEYBOARD_TYPE: 'actionKeyboardType',

  LABEL_POSITION: 'labelPosition',

  NOTE_POSITION: 'notePosition',

  LINE_STYLE: 'lineStyle',

  FILL_BRIGHT_COLORS: ['#FFFFFF', '#D8DAE5', '#EDEFF5'],

  FREE_USER_LIMIT_COMPONENT: process.env.REACT_APP_FREE_USER_LIMIT_COMPONENT || 20
};

Object.freeze(ZwfConstants);

window.ZwfConstants = ZwfConstants;

export { ZwfConstants };
