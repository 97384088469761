import { SyntheticEvent, useState, useEffect, useContext } from 'react';
import { TextInputField, Button, Pane } from 'evergreen-ui';
import { EditorContext } from 'context/EditorContext';
import { Modal } from '../Modal/Modal';

const AddLinkModal = () => {
  // @ts-ignore
  const { designer, mindplot, linkModal, setLinkModal } = useContext(EditorContext);
  const [isEditLink, setEditLink] = useState(false);
  const [link, setLink] = useState('');

  useEffect(() => {
    if (linkModal) {
      const url = getLinkSelectedTopic();
      setLink(url);
      setEditLink(url.length > 0)
    }
  }, [linkModal]);

  useEffect(() => {
    window.addEventListener('clickLinkIcon', handleClickLinkIcon);
    return () => window.removeEventListener('clickLinkIcon', handleClickLinkIcon);
  }, []);

  const handleClickLinkIcon = () => {
    setLinkModal(true);
  }

  const getLinkSelectedTopic = () => {
    const designerModel = designer.getModel();
    const topic = designerModel.selectedTopic();
    const model = topic.getModel();
    const links = model.findFeatureByType(mindplot.TopicFeature.Link.id);
    let result;
    if (links.length > 0) {
      result = links[0].getUrl();
    }

    return result || '';
  }

  const setLinkSelectedTopic = (value: (string | null)) => {
    const designerModel = designer.getModel();
    const topic = designerModel.selectedTopic();
    const model = topic.getModel();
    const topicId = topic.getId();
    const dispatcher = mindplot.ActionDispatcher.getInstance();
    const links = model.findFeatureByType(mindplot.TopicFeature.Link.id);
    if (!value) {
      const featureId = links[0].getId();
      dispatcher.removeFeatureFromTopic(topicId, featureId);
    } else {
      if (links.length > 0) {
        dispatcher.changeFeatureToTopic(topicId, links[0].getId(), { url: value });
      } else {
        dispatcher.addFeatureToTopic(topicId, mindplot.TopicFeature.Link.id, { url: value });
      }
    }
  }

  const handleConfirm = () => {
    setLinkSelectedTopic(link);
    setLinkModal(false);
  }

  const handleCancel = () => {
    if (isEditLink) {
      setLinkSelectedTopic(null)
    }
    setLinkModal(false);
  }

  const handleChange = (event: SyntheticEvent) => {
    setLink((event.target as HTMLTextAreaElement).value);
  }

  const handleKeyPress = (event: any) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleConfirm();
    }
  }

  return (
    <Modal
      width={376}
      intent={'editModal'}
      isShown={linkModal}
      title={`${isEditLink  ? 'Edit' : 'Insert'} Link`}
      onCloseComplete={() => setLinkModal(false)}
      hasFooter={false}
      footer={
        <Pane display="flex" width="100%">
          {isEditLink && <Button onClick={() => window.open(link)}>Open Link</Button>}
          <Pane justifyContent="flex-end" marginLeft="auto">
            <Button color={isEditLink ? '#C04E49' : '#474D66'} onClick={handleCancel}>{isEditLink ? 'Remove Link' : 'Cancel'}</Button>
            <Button appearance="primary" onClick={handleConfirm} marginLeft="0.5rem">Save</Button>
          </Pane>
        </Pane>
      }
      minHeightContent={0}
    >
      <TextInputField
        id="insertLink"
        name="insert-link"
        placeholder="Type your URL here"
        value={link}
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        margin={0}
      />
    </Modal>
  );
}

export default AddLinkModal;
