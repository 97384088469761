import React, { useEffect, useState } from 'react';
import { Box } from '../../Box/Box';
import { Button, Pane } from 'evergreen-ui';
import styles from './HeadingPanel.module.scss';
import _ from 'lodash';
import { resizeHeadingSelection } from 'components/Editor/utils/Actions';

const HeadingPanel = ({
  editorUi,
  graphState,
  dispatchGraphState,
  accessorPanel,
}) => {
  const [activeHeading, setActiveHeading] = useState('');
  useEffect(() => {
    setActiveHeading(_.get(graphState, 'fontSize', '32'));
  }, [graphState]);

  const onHeadingChange = val => {
    const fontSizeHeading = _.toString(val);
    if (fontSizeHeading === '') return;

    dispatchGraphState({ fontSize: fontSizeHeading });
    resizeHeadingSelection(editorUi, fontSizeHeading);
  };

  return (
    <Box>
      <Pane className={styles.heading}>
        <Pane className={styles.fontFormat}>
          <Button
            className={activeHeading === '32' ? styles.activeHeading : ''}
            onClick={() => onHeadingChange('32')}
          >
            H1
          </Button>
          <Button
            className={activeHeading === '20' ? styles.activeHeading : ''}
            onClick={() => onHeadingChange('20')}
          >
            H2
          </Button>
          <Button
            className={activeHeading === '16' ? styles.activeHeading : ''}
            onClick={() => onHeadingChange('16')}
          >
            H3
          </Button>
        </Pane>
      </Pane>
    </Box>
  );
};

export default HeadingPanel;
