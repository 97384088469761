import React from "react";

function CopyLinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#8F95B2"
        fillRule="evenodd"
        d="M3.708 9.17a.704.704 0 00.501-.205l4.236-4.236a.708.708 0 00-1.002-1.002L3.206 7.962a.708.708 0 00.501 1.207zm2.718-1.426L4.703 9.466l-.705.706-.008-.007c-.254.254-.6.423-.988.423A1.416 1.416 0 011.59 9.176c0-.388.17-.734.424-.988l-.007-.007.706-.706 1.722-1.722a2.486 2.486 0 00-.727-.113c-.776 0-1.475.325-1.983.84l-.015-.014-.705.706.014.014a2.779 2.779 0 00-.84 1.983 2.823 2.823 0 002.823 2.824c.777 0 1.476-.325 1.984-.84l.014.014.706-.706-.014-.014c.515-.508.84-1.207.84-1.983 0-.248-.043-.488-.106-.72zm5.047-4.222A2.823 2.823 0 008.649.7c-.776 0-1.475.325-1.983.84l-.014-.014-.706.706.014.014a2.808 2.808 0 00-.84 1.983c0 .254.042.487.106.72l1.722-1.722.706-.706.007.007c.254-.254.6-.423.988-.423.777 0 1.412.635 1.412 1.411 0 .389-.17.734-.423.989l.007.007-.706.705-1.716 1.73c.233.063.473.106.72.106.777 0 1.476-.325 1.984-.84l.014.014.706-.706-.014-.014c.515-.508.84-1.2.84-1.984z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CopyLinkIcon;
