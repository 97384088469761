import { useEffect, useState } from 'react';
import {
  Button,
  Pane,
  Popover,
  Position,
  Switch,
  Text,
  Image
} from 'evergreen-ui';
import styles from './IconPanel.module.scss';
import { Box } from '../../Box/Box';
import { updateButtonSize } from '../../../../utils/Actions';
import { ZwfConstants } from '../../../../utils/ZwfConstants';
import IconMenu from './IconMenu';

import { mxConstants, mxUtils } from 'libs/mxgraph';

const IconPanel = ({ editorUi, dispatchGraphState, graph }) => {
  const [icon, setIcon] = useState('heart.svg');
  const [isEnabled, setIsEnabled] = useState(false);
  const cell = graph.getSelectionCell();

  useEffect(() => {
    const style = graph.getCellStyle(cell);
    const image = mxUtils.getValue(style, mxConstants.STYLE_IMAGE, null);
    setIcon(image ? image.replace('/icons/', '') : 'heart.svg');
    setIsEnabled(image !== null);
  }, [graph, cell]);

  const handleEnableIcon = event => {
    const enabled = event.target.checked;
    const cell = graph.getSelectionCell();
    const style = graph.getCellStyle(cell);
    const size = mxUtils.getValue(style, ZwfConstants.STYLE_TEXT_SIZE, ZwfConstants.TEXT_SIZE_MEDIUM);
    const type = mxUtils.getValue(style, ZwfConstants.STYLE_TYPE, ZwfConstants.TYPE_BUTTON);
    const fillColor = mxUtils.getValue(style, mxConstants.STYLE_FILLCOLOR, mxConstants.DEFAULT_STYLE_FILLCOLOR);

    // Update cell style
    graph.getModel().beginUpdate();
    try {
      if (enabled) {
        if (ZwfConstants.FILL_BRIGHT_COLORS.includes(fillColor.toUpperCase())) {
          graph.setCellStyles(mxConstants.STYLE_IMAGE, `/icons/${icon}`, [cell]);
        } else {
          graph.setCellStyles(mxConstants.STYLE_IMAGE, `/white-icons/${icon}`, [cell]);
        }
      } else {
        graph.setCellStyles(mxConstants.STYLE_IMAGE, null, [cell]);
      }

      if (type === ZwfConstants.TYPE_BUTTON) {
        updateButtonSize({ editor: editorUi, dispatchGraphState, graph, val: size });
      }

      if (type === ZwfConstants.TYPE_MOBILE_MENU_ROW) {
        if (enabled) {
          graph.setCellStyles(mxConstants.STYLE_SPACING, 16, [cell]);
          graph.setCellStyles(mxConstants.STYLE_SPACING_LEFT, 40, [cell]);
        } else {
          graph.setCellStyles(mxConstants.STYLE_SPACING, 0, [cell]);
          graph.setCellStyles(mxConstants.STYLE_SPACING_LEFT, 24, [cell]);
        }
      }
    } finally {
      graph.getModel().endUpdate();
    }

    setIsEnabled(enabled);
  }

  const handleChangeIcon = iconName => {
    // Update new icon
    const selectedIcon = `${iconName}.svg`;
    const cell = graph.getSelectionCell();
    const style = graph.getCellStyle(cell);
    const fillColor = mxUtils.getValue(style, mxConstants.STYLE_FILLCOLOR, mxConstants.DEFAULT_STYLE_FILLCOLOR);
    graph.getModel().beginUpdate();
    try {
      if (ZwfConstants.FILL_BRIGHT_COLORS.includes(fillColor.toUpperCase())) {
        graph.setCellStyles(mxConstants.STYLE_IMAGE, `/icons/${selectedIcon}`, [cell]);
      } else {
        graph.setCellStyles(mxConstants.STYLE_IMAGE, `/white-icons/${selectedIcon}`, [cell]);
      }
    } finally {
      graph.getModel().endUpdate()
    }
    setIcon(selectedIcon);
  }

  return (
    <Box title="Icon" isDivider>
      <Pane className="mt-3 d-flex align-items-center">
        <Text>Add Icon</Text>
        <Pane className={styles.fontSize}>
          <Switch checked={isEnabled} onChange={handleEnableIcon}/>
        </Pane>
      </Pane>
      {isEnabled && (
        <Pane className="mt-3 d-flex align-items-center">
          <Text>Select Icon</Text>
          <Pane className={styles.fontSize}>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={(
                <IconMenu
                  editorUi={editorUi}
                  onClick={iconName => handleChangeIcon(iconName)}
                />
              )}
            >
              <Button
                className="border-lighten-gray justify-content-between"
                width={80}
                paddingTop={12}
                paddingRight={6}
                paddingBottom={12}
                paddingLeft={12}
              >
                <Image width={10} height={10} src={`/icons/${icon.replace('/white-icons', '').replace('/icons', '')}`}/>
                <Image src="/caret.svg"/>
              </Button>
            </Popover>
          </Pane>
        </Pane>
      )}
    </Box>
  )
}

export default IconPanel;
