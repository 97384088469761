import { Button, toaster, TrashIcon, DocumentIcon, FolderCloseIcon } from 'evergreen-ui';
import { Card, CardBody, CardHeader, CardTitle } from 'components/Documents/Card/Card';
import {
  Table,
  TableBody,
  TableCell,
  TableFirstCell,
  TableHead,
  TableHeaderCell,
  TableHeaderLastCell,
  TableLastCell,
  TableRow
} from 'components/Documents/Table/Table';
import { Spinner } from 'components/Spinner/Spinner';
import { DocumentService } from 'services/document';
import { date } from 'utils/helpers';
import styles from './Trash.module.scss';
import { useEffect, useState } from 'react';
import { AuthService } from 'services/authService';
import { SortDirection, SortField } from 'schemas/sort';
import { sort } from 'fast-sort';
import { EmptyTrashModal, DeleteDocumentModal } from 'components/Modals';
import { FolderService } from 'services/folder';
import { TrashItem } from 'schemas';
import _ from "lodash"

const documentService = new DocumentService();
const folderService = new FolderService();

const Trash = () => {
  const [trashData, setTrashData] = useState<TrashItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [trashItem, setTrashItem] = useState({} as TrashItem);
  const [modalEmpty, setModalEmpty] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setLoading(true);
        const documentData = await documentService.getTrash() as TrashItem[];
        const folderData = await folderService.getTrash() as TrashItem[];
        const data = documentData.concat(folderData);
        setTrashData(data);
      } catch (e: any) {
        toaster.danger(e.message);
      } finally {
        setLoading(false);
      }
    }
    if (AuthService.isAuthenticated()) {
      fetchAll();
    }
  }, []);

  const handleSort = (key: SortField) => {
    if (sortDirection === SortDirection.ASC) {
      setTrashData(sort(trashData).asc(item => _.get(item, key, "")));
      setSortDirection(SortDirection.DESC);
    } else {
      setTrashData(sort(trashData).desc(item => _.get(item, key, "")));
      setSortDirection(SortDirection.ASC);
    }
  }

  const handleRestore = async (doc: TrashItem) => {
    try {
      const id = doc.id;
      if (isFolderItem(doc)) {
        await folderService.restore(id);
      } else {
        await documentService.restore(id);
      }
      setTrashData(trashData.filter(item => item.id !== id));
    } catch (e: any) {
      toaster.danger(e.message);
    }
  }

  const isFolderItem = (doc: TrashItem) => {
    return doc.hasOwnProperty('parentFolder');
  }

  const handleShowModalDelete = (doc: TrashItem) => {
    setTrashItem(doc);
    setModalDelete(true);
  }

  const handleDeleted = (id: string) => {
    setTrashData(trashData.filter(item => item.id !== id));
  }

  const handleEmpty = () => {
    setTrashData([]);
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Trash</CardTitle>
          <Button
            iconBefore={TrashIcon}
            className="ml-3"
            intent="danger"
            onClick={() => setModalEmpty(true)}
            style={{ lineHeight: 'inherit' }}
          >
            Empty Trash
          </Button>
        </CardHeader>
        <CardBody>
          {loading && <Spinner />}
          {!loading && (
            <Table>
              <TableHead>
                <TableHeaderCell onSort={() => handleSort(SortField.Name)}>Title</TableHeaderCell>
                <TableHeaderCell onSort={() => handleSort(SortField.CreatedAt)}>Date Created</TableHeaderCell>
                <TableHeaderLastCell />
              </TableHead>
              <TableBody>
                {trashData.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableFirstCell>
                      <span className={styles.labelContainer}>
                        <span className={styles.itemIcon}>{doc.hasOwnProperty('parentFolder') ? <FolderCloseIcon /> : <DocumentIcon /> }</span>
                        <span className={styles.itemLabel}>{doc.name}</span>
                      </span>
                    </TableFirstCell>
                    <TableCell>{date(doc.createdAt.toDate())}</TableCell>
                    <TableLastCell>
                      <Button
                        appearance="primary"
                        intent="none"
                        className={styles.buttonRestore}
                        onClick={() => handleRestore(doc)}
                      >
                        Restore
                      </Button>
                      <Button
                        appearance="primary"
                        intent="danger"
                        className={styles.buttonDelete}
                        onClick={() => handleShowModalDelete(doc)}
                      >
                        Delete
                      </Button>
                    </TableLastCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardBody>
      </Card>
      <DeleteDocumentModal
        isShown={modalDelete}
        setIsShown={setModalDelete}
        trashItem={trashItem}
        onDelete={handleDeleted}
      />
      <EmptyTrashModal
        isShown={modalEmpty}
        setIsShown={setModalEmpty}
        trashItem={trashItem}
        onEmpty={handleEmpty}
      />
    </>
  );
};

export default Trash;
