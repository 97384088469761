/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Button,
  toaster,
  Pane,
  Text,
  PlusIcon,
} from 'evergreen-ui';
import { useHistory } from 'react-router-dom';

import styles from './NoteList.module.scss';
import { NoteService } from 'services/note';

const noteService = new NoteService();

type Props = {
  tagId: string;
}

export default function TagNoteHeader({ tagId }: Props) {
  const history = useHistory();
  
  const handleCreateNote = async () => {
    const saveData: any = {
      title: '',
    };
    try {
      const { id } = await noteService.create(saveData);
      history.push(`/notes/${id}`);
    } catch (e: any) {
      toaster.danger(e.message);
    }
  };

  return (
    <header className={styles.header}>
      <span className={styles.headerTitle}>Tag: {tagId}</span>
      {/* <Button onClick={handleCreateNote}>
        <Pane display="flex" justifyContent="center">
          <PlusIcon
            size={12}
            className="mr-2 mt-1"
            color="#8F95B2"
            fontSize="12"
          />
          <Text fontSize={'12px'}>Note</Text>
        </Pane>
      </Button> */}
    </header>
  );
}
