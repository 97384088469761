type SettingIconProps = {
  color?: string;
}

export default function SettingIcon({...props}: SettingIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.238 6.955h-1.742a5.04 5.04 0 00-.423-.978l1.28-1.28a.755.755 0 000-1.064L13.289 2.57a.756.756 0 00-1.063 0l-1.28 1.28a5.66 5.66 0 00-.979-.415v-1.75a.755.755 0 00-.753-.753H7.708a.755.755 0 00-.753.753v1.75a5.62 5.62 0 00-1.035.443L4.696 2.654a.727.727 0 00-1.025 0L2.654 3.68a.727.727 0 000 1.026L3.878 5.93c-.189.32-.34.658-.452 1.025H1.685a.755.755 0 00-.753.753v1.506c0 .414.339.753.753.753h1.74c.104.348.255.668.424.979l-1.28 1.28a.755.755 0 000 1.063l1.064 1.064a.756.756 0 001.063 0l1.28-1.28c.311.17.631.31.98.414v1.75c0 .415.338.754.752.754h1.506a.755.755 0 00.753-.753v-1.75a5.629 5.629 0 001.035-.443l1.224 1.223a.727.727 0 001.026 0l1.026-1.026a.727.727 0 000-1.025l-1.224-1.224c.179-.33.339-.668.452-1.035h1.741A.755.755 0 0016 9.205V7.699a.768.768 0 00-.762-.744zm-6.777 4.33A2.82 2.82 0 015.638 8.46 2.82 2.82 0 018.46 5.638a2.82 2.82 0 012.824 2.823 2.82 2.82 0 01-2.824 2.824z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
