import React, { useEffect, useState } from 'react';
import { TextInput } from 'evergreen-ui';
import { Dialog } from 'components/Dialog';
import { useHistory } from 'react-router-dom';

import { DocumentService } from 'services/document';
import { DocumentType } from '../../schemas';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  folder: string;
}

const documentService = new DocumentService();

export default function CreateDocumentModal({ isShown, setIsShown, folder }: Props) {
  const [newDocumentName, setNewDocumentName] = useState('');
  const [currentFolder, setCurrentFolder] = useState(folder);
  const history = useHistory();

  useEffect(() => {
    setCurrentFolder(folder);
  }, [folder]);

  const handleCreateDocument = async () => {
    try {
      setIsShown(false);
      documentService.create({
        name: newDocumentName,
        folder: currentFolder || '',
        type: DocumentType.Flowchart,
      }).then(resp => {
        history.push(`/docs/${resp.id}`);
      });
    } finally {
      setNewDocumentName('');
    }
  };

  const onKeyPress = async (evt: any) => {
    if (evt.code === "Enter" || evt.code === "NumpadEnter") {
      await handleCreateDocument();
    }
  }

  return (
    <Dialog
      isShown={isShown}
      title="New Document"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Create"
      onConfirm={handleCreateDocument}
    >
      <TextInput
        width="100%"
        required
        name="name"
        autoFocus={true}
        value={newDocumentName}
        onChange={(e: any) => setNewDocumentName(e.target.value)}
        placeholder="Document name"
        onKeyPress={onKeyPress}
      />
    </Dialog>
  );
}
