/**
 * Chunk array to mutiple array by size input
 * 
 * @param arr array
 * @param size number
 * @returns 
 */
export const chunk = (arr: Array<any>, size: number) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );
};
