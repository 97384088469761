import Projects from "./Projects";
import {useOwnerProjects} from "../../../services/project/projects.helper";

const OwnerProjects = () => {
  const {projects: ownerProjects, projectCount} = useOwnerProjects();
  return (
    <Projects title="MY PROJECTS" projects={ownerProjects} isAddAble={true} projectCount={projectCount}/>
  )
}

export default OwnerProjects;
