import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { NoteSchema } from 'schemas';
import { NotebookSchema } from 'schemas/notebook';
import { TagSchema } from 'schemas/tag';

import { RootStore } from 'store';
import { getUser } from 'utils/store';

export default class NoteStore {
  rootStore: RootStore;

  noteId: string;
  note: NoteSchema;
  notebook: NotebookSchema;
  search: string;
  tag: string;

  tags: TagSchema[];
  notes: NoteSchema[];
  sharedNotes: NoteSchema[];

  noteList: NoteSchema[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.note = {} as NoteSchema;
    this.notebook = {} as NotebookSchema;
    this.tags = [] as TagSchema[];
    this.notes = [] as NoteSchema[];
    this.sharedNotes = [] as NoteSchema[];
    this.noteList = [] as NoteSchema[];
    this.tag = '';
    this.noteId = '';
    makeAutoObservable(this);
  }

  setNote(note: NoteSchema): void {
    this.note = note;
  }
  
  setNotebook(note: NotebookSchema): void {
    this.notebook = note;
  }

  setSearch(search: string): void {
    this.search = search;
  }

  setNotes(notes: NoteSchema[]): void {
    this.notes = notes;
    this.noteList = [
      ...this.notes,
      ...this.sharedNotes
    ];
  }

  setTags(tags: TagSchema[]): void {
    this.tags = tags;
  }

  setTag(tag: string): void {
    this.tag = tag;
  }

  setSharedNotes(notes: NoteSchema[]): void {
    this.sharedNotes = notes;
    this.noteList = [
      ...this.notes,
      ...this.sharedNotes
    ];
  }

  setNoteId(id: string): void {
    this.noteId = id;
  }

  get tagList() {
    const user = getUser() || { uid: '' };
    let result: string[] = [];

    if (user.uid) {
      const isEditableNote = (uid: string, note: NoteSchema) => {
        const role = note.roles[uid];
        if (role && role?.type === 'viewer') {
          return false;
        }
        return true;
      }
      const noteList = [
        ...this.notes,
        ...this.sharedNotes
      ]
      let rawTags: string[] = [];
      noteList.forEach(note => {
        if (note.tags && isEditableNote(user.uid, note)) {
          rawTags = _.union(rawTags, note.tags);
        }
      })
      result = rawTags.sort((a, b) => a.localeCompare(b));
    }
    return result;
  }
}
