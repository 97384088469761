import { useState } from 'react';
import { Alert } from 'evergreen-ui';
import CardAuth from 'components/CardAuth/CardAuth';
import CardLink from 'components/CardAuth/CardLink';
import { ResetPasswordForm } from './ResetPasswordForm';

const ResetPassword = () => {
  const [error, setError] = useState('');
  return (
    <>
      {error && (
        <Alert
          intent="danger"
          title={error}
        />
      )}
      <CardAuth
        title="Reset Password"
        subtitle="Get a password reset link"
      >
        <ResetPasswordForm onError={(message: string) => setError(message)}/>
      </CardAuth>
      <CardLink
        title="Pick your poison."
        links={[
          {
            text: 'Sign In',
            link: '/sign-in',
          },
          {
            text: 'Sign Up',
            link: '/sign-up',
          }
        ]}
      />
    </>
  );
}

export default ResetPassword;
