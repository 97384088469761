import { useState } from 'react';
import { Alert } from 'evergreen-ui';
import { Helmet } from 'react-helmet-async';

import CardAuth from 'components/CardAuth/CardAuth';
import CardLink from 'components/CardAuth/CardLink';
import { SignInForm } from './SignInForm';

const SignIn = () => {
  const [error, setError] = useState('');
  return (
    <>
      <Helmet>
        <meta name="description" content="Sign In to Zen Flowchart, The Simplest Online Free Flowchart Maker." />
      </Helmet>
      {error && (
        <Alert
          intent="danger"
          title={error}
          marginBottom="1rem"
        />
      )}
      <CardAuth
        title="Sign In"
        subtitle="Let’s start creating!"
      >
        <SignInForm onError={(message: string) => setError(message)} />
      </CardAuth>
      <CardLink
        title="Don’t have an account yet?"
        links={[{
          text: 'Sign Up',
          link: '/sign-up',
        }]}
      />
    </>
  );
}

export default SignIn;
