import { useState, Dispatch, SetStateAction } from 'react';
import {
  Dialog,
  Pane,
  CrossIcon,
  TextInput,
  Button,
  MoreIcon,
  PaperclipIcon,
  EyeOpenIcon,
  ArchiveIcon,
  TrashIcon,
  InfoSignIcon,
  TickIcon,
  PropertiesIcon,
  Textarea,
  Checkbox,
  Avatar,
  Image,
  Badge,
  Popover,
  Position,
  AnnotationIcon
} from 'evergreen-ui';
import { Col, Row } from 'react-grid-system';
import useComponentVisible from "../../../common/useComponentVisible";
import Member from "./Member/Member";
import Label from "./Label/Label";
import DuaDate from "./DuaDate/DuaDate";
import MoveCard from "./MoveCard/MoveCard";
// Image
import jakeImg from "../../../assets/img/kabanDemo/jake.png";
import finnImg from "../../../assets/img/kabanDemo/finn.png";
import bmoImg from "../../../assets/img/kabanDemo/bmo.png";

import styles from './Card.module.scss';

type Props = {
  isShown: boolean;
  data: any;
  setIsShown: Dispatch<SetStateAction<boolean>>
}

const CardModal = ({ isShown, data, setIsShown }: Props) => {
  const [refDescription, isVisibleDescription, setIsVisibleDescription] = useComponentVisible(false);
  const [refTask, isVisibleTask, setIsVisibleTask] = useComponentVisible(false);

  const [isShowModalDeleteCard, setIsShowModalDeleteCard] = useState(false)

  return (
    <>
      <Dialog
        width={704}
        isShown={isShown}
        hasHeader={false}
        hasFooter={false}
        title="Invite Members"
        preventBodyScrolling={false}
        onCloseComplete={() => setIsShown(false)}
        containerProps={{ className: styles.modal }}
        contentContainerProps={{ className: styles.modalCard }}
      >
        {({ close }) => (
          <Row className="m-0">
            <Col className="p-0">
              <Pane className={styles.cardLeft}>
                <Pane className={styles.title}>
                  {
                    data.content
                  }
                  <Pane marginTop={8}>
                    <Badge color="neutral" marginRight={8}>
                      neutral
                    </Badge>
                    <Badge color="green" marginRight={8}>
                      green
                    </Badge>
                    <Badge color="blue" marginRight={8}>
                      blue
                    </Badge>
                  </Pane>
                  <Pane className={styles.infoHeader}>
                    <Pane className={styles.members}>
                      <Pane className={styles.titleMember}>
                        members
                      </Pane>
                      <Pane className={styles.listAvatar}>
                        <Avatar marginRight={5} src={data.author.avatarUrl} alt={data.author.name} name={data.author.name} size={30} />
                        <Avatar marginRight={5} src={data.author.avatarUrl} alt={data.author.name} name={data.author.name} size={30} />
                        <Avatar marginRight={5} src={data.author.avatarUrl} alt={data.author.name} name={data.author.name} size={30} />
                      </Pane>
                    </Pane>
                    <Pane className={styles.date}>
                      <Pane className={styles.titleDate}>
                        due date
                      </Pane>
                      <Pane className={styles.dateValue}>
                        March 22 at 5:00 PM
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
                {/* Description */}
                <Pane className={styles.formFlex}>
                  <InfoSignIcon />
                  <Pane className={styles.text}>Description</Pane>
                </Pane>
                <Pane className={styles.title} marginTop={5}>
                  {
                    isVisibleDescription
                      ?
                      <Pane ref={refDescription}>
                        <Textarea name="textarea-1" placeholder="Detailed description..." rowGap={2} />
                        <Button className={styles.btnColor} onClick={() => setIsVisibleDescription(false)}>
                          Save
                        </Button>
                      </Pane>
                      :
                      <TextInput name="text-input-name" placeholder="Add description..." className={styles.inputDesription} onClick={() => setIsVisibleDescription(true)} />
                  }
                </Pane>
                {/* Checklist */}
                <Pane className={styles.formFlex}>
                  <TickIcon />
                  <Pane className={styles.text}>Checklist</Pane>
                </Pane>
                <Pane className={styles.title} marginTop={5}>
                  <Checkbox label="Header" className={styles.checkBoxTask} />
                  <Checkbox label="Footer icons" className={styles.checkBoxTask} />
                  <Checkbox label="Check design once wireframe is completed" className={styles.checkBoxTask} />
                  {
                    isVisibleTask
                      ?
                      <Pane ref={refTask}>
                        <TextInput name="task" placeholder="Name task..." rowGap={2} className={styles.inputTask} />
                        <Button className={styles.btnColor} marginTop={5} onClick={() => setIsVisibleTask(false)}>
                          Save
                        </Button>
                      </Pane>
                      :
                      <Checkbox label="Add a task..." onClick={() => setIsVisibleTask(true)} className={styles.checkBoxAddTask} />
                  }
                </Pane>
                {/* Attachments */}
                <Pane className={styles.formFlex}>
                  <PaperclipIcon />
                  <Pane className={styles.text}>Attachments</Pane>
                </Pane>
                <Pane className={styles.containerAttach}>
                  <Pane className={styles.formFlexAttach}>
                    <Image width={80} height={60} src={jakeImg} />
                    <Pane marginLeft={16} className={styles.info}>
                      <Pane className={styles.titleAct}>
                        Sample design.jpg
                      </Pane>
                      <Pane className={styles.timeAct}>
                        June 12 at 6:51 AM
                      </Pane>
                      <Pane className={styles.actionFooter}>
                        Make Cover
                      </Pane>
                    </Pane>
                  </Pane>
                  <Pane className={styles.formFlexAttach}>
                    <Image width={80} height={60} src={finnImg} />
                    <Pane marginLeft={16} className={styles.info}>
                      <Pane className={styles.titleAct}>
                        Sample design.jpg
                      </Pane>
                      <Pane className={styles.timeAct}>
                        June 12 at 6:51 AM
                      </Pane>
                      <Pane className={styles.actionFooter}>
                        Remove Cover
                      </Pane>
                    </Pane>
                  </Pane>
                  <Pane className={styles.formFlexAttach}>
                    <Image width={80} height={60} src={bmoImg} />
                    <Pane marginLeft={16} className={styles.info}>
                      <Pane className={styles.titleAct}>
                        Icons.zip
                      </Pane>
                      <Pane className={styles.timeAct}>
                        June 12 at 6:51 AM
                      </Pane>
                    </Pane>
                  </Pane>
                </Pane>
                {/* Activities */}
                <Pane className={styles.formFlex}>
                  <PropertiesIcon />
                  <Pane className={styles.text}>Activities</Pane>
                </Pane>
                <Pane className={styles.formFlex} marginTop={12} marginBottom={12}>
                  <Avatar name="Jeroen Ransijn" size={32} marginRight={10} />
                  <TextInput name="text-input-name" placeholder="Write a comment..." className={styles.inputAct} />
                </Pane>
                <Pane className={styles.formActivities}>
                  <Pane className={styles.formFlex} marginTop={12} marginBottom={12}>
                    <Avatar name="Bill Gates" size={32} marginRight={10} />
                    <Pane>
                      <Pane className={styles.titleAct}>
                        <span className={styles.name}>Saqlain Mcintyre</span> added this card to Backlog
                    </Pane>
                      <Pane className={styles.timeAct}>
                        June 12 at 6:51 AM
                    </Pane>
                    </Pane>
                  </Pane>
                  <Pane className={styles.formFlex} marginTop={12} marginBottom={12}>
                    <Avatar name="A C" size={32} marginRight={10} />
                    <Pane className={styles.formComment}>
                      <Pane className={styles.titleActComment}>
                        <Pane className={styles.nameComment}>
                          <Pane>
                            Sam Smith
                        </Pane>
                          <Pane className={styles.timeActComment}>
                            June 12 at 6:51 AM
                        </Pane>
                        </Pane>
                      </Pane>
                      <Pane className={styles.comment}>
                        Could you let me know where are the design files?
                    </Pane>
                    </Pane>
                  </Pane>
                  <Pane className={styles.formFlex} marginTop={12} marginBottom={12}>
                    <Avatar name="B" size={32} marginRight={10} />
                    <Pane className={styles.formComment}>
                      <Pane className={styles.titleActComment}>
                        <Pane className={styles.nameComment}>
                          <Pane>
                            Jordan Spector
                        </Pane>
                          <Pane className={styles.timeActComment}>
                            June 12 at 6:51 AM
                        </Pane>
                        </Pane>
                        <Pane className={styles.moreIcon}>
                          <Popover
                            content={
                              <Pane>
                                <Pane className={styles.actionEdit}>
                                  <Button iconBefore={AnnotationIcon} appearance="minimal">
                                    Edit
                                </Button>
                                </Pane>
                                <Pane className={styles.actionRemove}>
                                  <Button iconBefore={TrashIcon} appearance="minimal">
                                    Delete Comment
                                </Button>
                                </Pane>
                              </Pane>
                            }
                            position={Position.BOTTOM_RIGHT}
                          >
                            <MoreIcon height={20} width={20} />
                          </Popover>
                        </Pane>
                      </Pane>
                      <Pane className={styles.comment}>
                        I just added more tasks to the check list. Let me know if you’d like to see more added.
                    </Pane>
                    </Pane>
                  </Pane>
                </Pane>
              </Pane>
            </Col>
            <Col md={4} className="p-0">
              <Pane className={styles.cardRight}>
                <Pane className={styles.closeIcon}>
                  <CrossIcon className={styles.icon} onClick={close} />
                </Pane>
                <Pane className={styles.formAction}>
                  <Pane className={styles.title}>
                    Add to card
                  </Pane>
                  <Pane className={styles.listAction}>
                    <Member />
                    <Label />
                    <DuaDate />
                    <Button marginY={4} iconBefore={PaperclipIcon}>
                      Attachment
                    </Button>
                  </Pane>
                </Pane>
                <Pane className={styles.formAction}>
                  <Pane className={styles.title}>
                    actions
                  </Pane>
                  <Pane className={styles.listAction}>
                    <Button marginY={4} iconBefore={EyeOpenIcon}>
                      Watch
                    </Button>
                    <MoveCard />
                    <Button marginY={4} iconBefore={ArchiveIcon}>
                      Archive
                    </Button>
                    <Button marginY={4} iconBefore={TrashIcon} onClick={() => setIsShowModalDeleteCard(true)}>
                      Delete
                    </Button>
                  </Pane>
                </Pane>
              </Pane>
            </Col>
            <Dialog
              width={368}
              isShown={isShowModalDeleteCard}
              title="Delete Card"
              intent="danger"
              onCloseComplete={() => setIsShowModalDeleteCard(false)}
              confirmLabel="Delete"
            >
              Are you sure to delete this card? This action cannot be undone.
            </Dialog>
          </Row>
        )}
      </Dialog>
    </>
  );
}

export default CardModal;
