/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactElement, useEffect, useState } from 'react';
import { useSlate } from 'slate-react';
import {
  BoldIcon,
  ItalicIcon,
  CitationIcon,
  UnderlineIcon,
  HeaderOneIcon,
  HeaderTwoIcon,
  NumberedListIcon,
  PropertiesIcon,
  Text,
  CodeIcon,
  majorScale,
  LinkIcon,
} from 'evergreen-ui';
import clsx from 'clsx';
import {
  addColumn,
  addRow,
  AlignToolbarButton,
  BalloonToolbar,
  BlockToolbarButton,
  CodeBlockToolbarButton,
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  getPreventDefaultHandler,
  indent,
  insertTable,
  ListToolbarButton,
  MARK_BOLD,
  MARK_CODE,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_KBD,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
  MarkToolbarButton,
  outdent,
  TableToolbarButton,
  ToolbarButton,
  usePlateEditorRef,
  LinkToolbarButton,
} from '@udecode/plate';
import {
  Editor,
  Element as SlateElement,
} from 'slate'

import Button from '../Elements/Button';
import {
  toggleBlock,
  toggleMark,
  isMarkActive,
  addMarkData,
  isBlockActive,
  activeMark,
} from '../utils/slateFunctions';
import styles from './Toolbar.module.scss';
import NoteShortcutModal from '../Modal/NoteShortcutModal';

export type ToolbarProps = {
  readOnly: boolean;
};

const toolbars = {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_UL,
  MARK_BOLD,
  MARK_CODE,
  MARK_HIGHLIGHT,
  MARK_ITALIC,
  MARK_KBD,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
};

export default function Toolbar({ readOnly }: ToolbarProps) {
  const editor = useSlate();
  const [showShortcut, setShowShortcut] = useState(false);
  // const isTable = useTable(editor);

  const BlockButton = ({
    format,
    icon,
  }: {
    format: string;
    icon: ReactElement;
  }) => {
    return (
      <Button
        active={isBlockActive(editor, format)}
        format={format}
        onMouseDown={(e: any) => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
      >
        {icon}
      </Button>
    );
  };

  const MarkButton = ({
    format,
    icon,
  }: {
    format: any;
    icon: ReactElement;
  }) => {
    return (
      <Button
        active={isMarkActive(editor, format)}
        format={format}
        onMouseDown={(e: any) => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
      >
        {icon}
      </Button>
    );
  };

  const isLinkActive = (editor: any) => {
    if (editor) {
      const [link] = Editor.nodes(editor, {
        match: (n: any) => {
          return !Editor.isEditor(n) && SlateElement.isElement(n) && (n.type === 'link' || n.type as any === 'a');
        },
      }) as any;
      return !!link
    }
    return false;
  }

  return (
    <div className={clsx(styles.noteToolbar, readOnly ? 'readOnly' : '')}>
      {!readOnly && (
        <>
          <MarkButton
            format={toolbars.MARK_BOLD}
            icon={<BoldIcon size={16} />}
          />
          <MarkButton
            format={toolbars.MARK_ITALIC}
            icon={<ItalicIcon size={16} />}
          />
          <MarkButton
            format={toolbars.MARK_UNDERLINE}
            icon={<UnderlineIcon size={16} />}
          />

          <BlockButton
            format={toolbars.ELEMENT_BLOCKQUOTE}
            icon={<CitationIcon size={16} />}
          />
          <BlockButton
            format={toolbars.ELEMENT_H1}
            icon={<HeaderOneIcon size={16} />}
          />
          <BlockButton
            format={toolbars.ELEMENT_H2}
            icon={<HeaderTwoIcon size={16} />}
          />
          <BlockButton
            format={toolbars.ELEMENT_UL}
            icon={<PropertiesIcon size={16} />}
          />
          <BlockButton
            format={toolbars.ELEMENT_OL}
            icon={<NumberedListIcon size={16} />}
          />

          <LinkToolbarButton icon={<Button active={isLinkActive(editor)} children={<LinkIcon size={16} />} />} />

          <Button
            height={majorScale(3)}
            className={clsx('mr-2', styles.shortcut)}
            padding={'10px'}
            onClick={() => setShowShortcut(true)}
          >
            <CodeIcon
              size={12}
              className="mr-2"
              color="#8F95B2"
              fontSize="12"
            />
            <Text fontSize="12">Markdown Shortcuts</Text>
          </Button>
        </>
      )}

      <NoteShortcutModal isShown={showShortcut} setIsShown={setShowShortcut} />
    </div>
  );
}
