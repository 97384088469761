import {Card, Pane} from "evergreen-ui";
import clsx from "clsx";
import boardStyles from "../Board.module.scss";
import taskStyles from "../Task/Task.module.scss";
import React, {useEffect} from "react";
import {CustomDragLayer} from "../../../components/DragLayer/CustomDragLayer";
import Pages from "./Pages";
import {useOwnerPages, useSavePageToStorage, useSharePages} from "../../../services/page/page.helper";
import AppFooterBoard from "../App/Footer";
import {PageService} from "../../../services/page/page.service";

type Props = {
  header: any;
}
const pageService = new PageService();

const PageBoard = ({header}: Props) => {
  const { pages: ownerPages } = useOwnerPages();
  const { pages: sharePages } = useSharePages();
  useSavePageToStorage(ownerPages, sharePages);

  useEffect(() => {
    pageService.getSamplePages().then(resp => {
      const stillDoesNotCreateSample = resp.size === 0;
      if(!stillDoesNotCreateSample) return;

      pageService.createSamplePages();
    });
  }, []);

  return (
    <>
      <Card className={clsx(boardStyles.asideContainer, taskStyles.taskBoard)}>
        {header}
        <Pane className={taskStyles.projectTask}>
          <Pages title="MY OKR PAGES" pages={ownerPages} isAddAble={true}/>
          <Pages title="SHARED WITH ME" pages={sharePages} isAddAble={false}/>
          <CustomDragLayer snapToGrid={false} />
        </Pane>
        <Pane className={boardStyles.bottomSidebar}>
          <AppFooterBoard />
        </Pane>
        <CustomDragLayer snapToGrid={false} />
      </Card>
    </>
  )
}

export default PageBoard;
