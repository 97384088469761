import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert } from 'evergreen-ui';
import { SIGN_IN_URL } from 'utils/constants';
import CardAuth from 'components/CardAuth/CardAuth';
import { Spinner } from 'components/Spinner/Spinner';
import { ResetService, ResetType } from 'services/reset';
import { SetPasswordForm } from './SetPasswordForm';

const SetPassword = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const resetService = new ResetService();
        await resetService.validateResetToken(ResetType.ResetPassword, token);
      } catch (e) {
        history.push(SIGN_IN_URL);
      } finally {
        setLoading(false);
      }
    }
    verifyToken();
  }, [history, token]);

  if (loading) {
    return <Spinner/>;
  }

  return (
    <>
      {error && (
        <Alert
          intent="danger"
          title={error}
        />
      )}
      <CardAuth
        title="Set new password"
        subtitle="Enter your new password"
      >
        <SetPasswordForm onError={(message: string) => setError(message)}/>
      </CardAuth>
    </>
  );
}

export default SetPassword;
