import DocumentPage from 'pages/Documents/Documents';
import SharedWithMePage from 'pages/SharedWithMe/SharedWithMe';
import TrashPage from 'pages/Trash/Trash';
import SettingPage from 'pages/Settings/SettingPage';
import SignInPage from 'pages/SignIn/SignIn';
import SignUpPage from 'pages/SignUp/SignUp';
import ResetEmailPage from 'pages/ResetEmail/ResetEmail';
import ResetPasswordPage from 'pages/ResetPassword/ResetPassword';
import SentPassword from 'pages/SentPassword/SentPassword';
import SetPassword from 'pages/SetPassword/SetPassword';
import NotFound from 'pages/NotFound/NotFound';
import EditorApp from 'pages/Editor/EditorApp';
import Sounds from 'pages/Sounds';
import KanbanBoard from 'pages/KanbanBoard';
import DocumentEditor from 'pages/Editor/DocumentEditor';
import CancelCodes from "./Admin/RefundCodes/CancelCodes";
import SignUpRedeem from "./SignUpRedeem/SignUpRedeem";
import RedeemCode from "./RedeemCode/RedeemCode";
import TemplatesPage from "./Templates/TemplatesPage";
import EditMindMap from './EditMindMap/EditMindMap';
import NotePage from './Note/NotePage';
import NoteTrashPage from './Note/NoteTrashPage';
import NoteDetailPage from './Note/NoteDetailPage';
import Support from './Admin/Support/Support';
import TaskPage from "./Task/TaskPage";
import TrashTasks from "./Task/TrashTask";
import NoteSharePage from './Note/NoteSharePage';
import NoteTagPage from './Note/NoteTagPage';
import NoteViewPage from './Note/NoteViewPage';
import {
  NOTEBOOK_URL,
  NOTE_TAG_URL,
  NOTE_TRASH_URL,
  NOTE_URL,
  NOTE_VIEW_URL,
  SHARED_NOTEBOOK_URL,
  SHARED_NOTE_URL,
} from 'utils/constants';
import NotebookPage from './Note/NotebookPage';
import OkrPage from "./Page/Page";

const routes = [
  {
    path: '/',
    layout: 'appBoard',
    exact: true,
    component: DocumentPage
  },
  {
    path: '/app',
    layout: 'appBoard',
    exact: true,
    component: DocumentPage
  },
  {
    path: '/docs/:id',
    layout: 'editor',
    exact: true,
    component: DocumentEditor
  },
  {
    path: '/flowchart/:id',
    layout: 'editor',
    exact: true,
    component: DocumentEditor
  },
  {
    path: '/mindmap/:id',
    layout: 'editor',
    exact: true,
    component: EditMindMap
  },
  {
    path: NOTE_URL,
    layout: 'noteBoard',
    exact: true,
    component: NotePage,
    title: 'My Notes'
  },
  {
    path: `${NOTEBOOK_URL}/:id`,
    layout: 'noteBoard',
    exact: true,
    component: NotebookPage
  },
  {
    path: SHARED_NOTE_URL,
    layout: 'noteBoard',
    exact: true,
    component: NoteSharePage,
    title: 'Shared with me'
  },
  {
    path: `${SHARED_NOTEBOOK_URL}/:id`,
    layout: 'noteBoard',
    exact: true,
    component: NoteSharePage,
    title: 'Shared with me'
  },
  {
    path: `${NOTE_TAG_URL}/:id`,
    layout: 'noteBoard',
    exact: true,
    component: NoteTagPage,
    title: 'Tag'
  },
  {
    path: NOTE_TRASH_URL,
    layout: 'noteBoard',
    exact: true,
    component: NoteTrashPage,
    title: 'Trash'
  },
  {
    path: `${NOTE_VIEW_URL}/:id`,
    layout: 'editor',
    exact: true,
    component: NoteViewPage
  },
  {
    path: '/notes/:id',
    layout: 'noteBoard',
    exact: true,
    component: NoteDetailPage
  },
  {
    path: '/wireframe/:id',
    layout: 'editor',
    exact: true,
    component: DocumentEditor
  },
  {
    path: '/whiteboard/:id',
    layout: 'editor',
    exact: true,
    component: DocumentEditor
  },
  {
    path: '/folders/:id',
    layout: 'appBoard',
    exact: true,
    component: DocumentPage
  },
  {
    path: '/shared-with-me',
    layout: 'appBoard',
    exact: true,
    component: SharedWithMePage
  },
  {
    path: '/trash',
    layout: 'appBoard',
    exact: true,
    component: TrashPage
  },
  {
    path: '/sound',
    layout: 'sidebar-sound',
    exact: true,
    component: Sounds
  },
  {
    path: '/sounds',
    layout: 'sidebar-sound',
    exact: true,
    component: Sounds
  },
  {
    path: '/zen-sound',
    layout: 'sidebar-sound',
    exact: true,
    component: Sounds
  },
  {
    path: '/zen-sounds',
    layout: 'sidebar-sound',
    exact: true,
    component: Sounds
  },
  {
    path: '/kanban',
    layout: 'sidebar-kanban',
    exact: true,
    component: KanbanBoard
  },
  {
    path: '/templates',
    layout: 'appBoard',
    exact: true,
    component: TemplatesPage
  },
  {
    path: '/task',
    layout: 'taskBoard',
    exact: true,
    component: TaskPage
  },
  {
    path: '/okr',
    layout: 'okrBoard',
    exact: true,
    component: OkrPage
  },
  {
    path: '/okr/:id',
    layout: 'okrBoard',
    exact: true,
    component: OkrPage
  },
  {
    path: '/task/trash',
    layout: 'taskBoard',
    exact: true,
    component: TrashTasks
  },
  {
    path: '/task/project/:id',
    layout: 'taskBoard',
    exact: true,
    component: TaskPage
  },
  {
    path: '/settings',
    layout: 'appBoard',
    exact: true,
    component: SettingPage
  },
  {
    path: '/edit',
    layout: 'editor',
    exact: true,
    component: EditorApp
  },
  {
    path: '/sign-up',
    layout: 'signUp',
    exact: true,
    component: SignUpPage
  },
  {
    path: '/sign-in',
    layout: 'auth',
    exact: true,
    component: SignInPage
  },
  {
    path: '/signup-redeem',
    layout: 'no-auth',
    exact: true,
    component: SignUpRedeem
  },
  {
    path: '/redeem',
    layout: 'no-auth',
    exact: true,
    component: RedeemCode
  },
  {
    path: '/reset-email/:token',
    layout: 'rest',
    exact: true,
    component: ResetEmailPage
  },
  {
    path: '/reset-password',
    layout: 'auth',
    exact: true,
    component: ResetPasswordPage
  },
  {
    path: '/sent-password',
    layout: 'auth',
    exact: true,
    component: SentPassword
  },
  {
    path: '/set-password/:token',
    layout: 'auth',
    exact: true,
    component: SetPassword
  },
  {
    path: '/admin/support',
    layout: 'admin',
    exact: true,
    component: Support
  },
  {
    path: '/admin/cancel-codes',
    layout: 'admin',
    exact: true,
    component: CancelCodes
  },
  {
    path: '/404',
    layout: 'rest',
    exact: true,
    component: NotFound
  },
  {
    path: '*',
    layout: 'rest',
    exact: true,
    component: NotFound
  }
];

export default routes;
