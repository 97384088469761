/*eslint eqeqeq: 'off'*/

import mx from '../mx';
// import { mxChildChange } from '../'

const mxGraphModel = mx.mxGraphModel;

/**
 * Function: filterDescendants
 *
 * Visits all cells recursively and applies the specified filter function
 * to each cell. If the function returns true then the cell is added
 * to the resulting array. The parent and result paramters are optional.
 * If parent is not specified then the recursion starts at <root>.
 *
 * Example:
 * The following example extracts all vertices from a given model:
 * (code)
 * var filter = function(cell)
 * {
 * 	return model.isVertex(cell);
 * }
 * var vertices = model.filterDescendants(filter);
 * (end)
 *
 * Parameters:
 *
 * filter - JavaScript function that takes an <mxCell> as an argument
 * and returns a boolean.
 * parent - Optional <mxCell> that is used as the root of the recursion.
 */
 mxGraphModel.prototype.filterDescendants = function(filter, parent)
 {
   // Creates a new array for storing the result
   var result = [];
 
   // Recursion starts at the root of the model
   parent = parent || this.getRoot();
 
   // Checks if the filter returns true for the cell
   // and adds it to the result array
   if (filter == null || filter(parent))
   {
     result.push(parent);
   }
 
   // Visits the children of the cell
   var childCount = this.getChildCount(parent);
 
   for (var i = 0; i < childCount; i++)
   {
     var child = this.getChildAt(parent, i);
     if (child) {
      result = result.concat(this.filterDescendants(filter, child));
     }
   }
 
   return result;
 };

export { mxGraphModel };

// /**
//  * Function: add
//  *
//  * Adds the specified child to the parent at the given index using
//  * <mxChildChange> and adds the change to the current transaction. If no
//  * index is specified then the child is appended to the parent's array of
//  * children. Returns the inserted child.
//  *
//  * Parameters:
//  *
//  * parent - <mxCell> that specifies the parent to contain the child.
//  * child - <mxCell> that specifies the child to be inserted.
//  * index - Optional integer that specifies the index of the child.
//  */
// mxGraphModel.prototype.add = function (parent, child, index) {
//   if (child != parent && parent != null && child != null) {
//     // Appends the child if no index was specified
//     if (index == null) {
//       index = this.getChildCount(parent);
//     }

//     var parentChanged = parent != this.getParent(child);
//     this.execute(new mxChildChange(this, parent, child, index));

//     // Maintains the edges parents by moving the edges
//     // into the nearest common ancestor of its terminals
//     if (this.maintainEdgeParent && parentChanged) {
//       this.updateEdgeParents(child);
//     }
//   }

//   return child;
// };
