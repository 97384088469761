import { CardCvcElement } from '@stripe/react-stripe-js';
import { ShieldIcon } from 'evergreen-ui';
import styles from './CardPayment.module.scss';
import CardPayment from './CardPayment';
import { useOptions } from './useOptions';

const CardCvc = () => {
  const options = useOptions();
  return (
    <CardPayment className={styles.cardCvc} icon={ShieldIcon}>
      <CardCvcElement options={options} />
    </CardPayment>
  )
}

export default CardCvc;
