import clsx from 'clsx';
import { Dialog as EDialog, DialogProps } from 'evergreen-ui';

import styles from './index.module.scss';

export const Dialog = ({ children, intent, ...props }: DialogProps) => (
  <EDialog
    {...props}
    containerProps={{
      className: clsx(styles.modal, intent === 'danger' && styles.dangerModal),
    }}
    contentContainerProps={{
      className: styles.content,
    }}
    minHeightContent="0"
    width={props.width || '376px'}
  >
    {children}
  </EDialog>
);
