import {majorScale, Pane} from "evergreen-ui";
import TasksView from "./TasksView";

const TaskPage = () => {
  return (
    <Pane backgroundColor={"white"}>
      <Pane paddingX={majorScale(17)} paddingY={majorScale(6)}>
        <TasksView/>
      </Pane>
    </Pane>
  )
}

export default TaskPage;
