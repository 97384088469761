import { createElement, ReactNode } from 'react';
import { Pane } from 'evergreen-ui';
import clsx from 'clsx';

type Props = {
  className?: string;
  icon: any;
  children: ReactNode
}

const CardPayment = ({ className, icon, children } : Props) => (
  <Pane
    width="100%"
    height={32}
    position="relative"
    display="inline-flex"
    className={clsx(
      className && className
    )}
  >
    <Pane
      width={32}
      height={32}
      pointerEvents="none"
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {createElement(icon, { zIndex: 3, size: 12, color: '#8F95B2' }, null)}
    </Pane>
    {children}
  </Pane>
);

export default CardPayment;
