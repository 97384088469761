import {
  BoxIcon,
  Card,
  majorScale,
  minorScale,
  Pane,
  ProjectsIcon,
  StarIcon,
  Text,
  TrashIcon,
  UserIcon
} from "evergreen-ui";
import boardStyles from "../Board.module.scss";
import taskStyles from "./Task.module.scss";
import TaskNotification from "./Notification";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import OwnerProjects from "./OwnerProjects";
import ShareProjects from "./ShareProjects";
import {getUser} from "../../../utils/store";
import styles from "./Notification.module.scss";
import {useHistory, useLocation} from "react-router-dom";
import {CustomDragLayer} from "../../../components/DragLayer/CustomDragLayer";
import {TaskService} from "../../../services/task/task.service";
import {useDrop} from "react-dnd";
import {getAssignees} from "./TaskUtils";
import {DatePicker} from "antd";
import {useOwnerProjects, useSaveProjectToStorage, useShareProjects} from "../../../services/project/projects.helper";
import AppFooterBoard from "../App/Footer";
import {TASK_TYPE, VIEW_MODE} from "../../../services/task/tasks.helper";

type Props = {
  header: any;
}

const taskService = new TaskService();

const TaskBoard = ({ header }: Props) => {

  const history = useHistory();
  const location = useLocation();
  const isTrashPage = location.pathname.includes("/task/trash")
  const [somedayTaskId] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { projects: ownerProjects } = useOwnerProjects();
  const { projects: shareProjects } = useShareProjects();
  useSaveProjectToStorage(ownerProjects, shareProjects);

  const user = getUser();
  const [today] = useState((new Date()).getTime());
  const greaterThanDate = new Date(today);
  greaterThanDate.setHours(0);
  greaterThanDate.setMinutes(0);
  greaterThanDate.setSeconds(0);
  const lessThanDate = new Date(today);
  lessThanDate.setHours(23);
  lessThanDate.setMinutes(59);
  lessThanDate.setSeconds(59);


  const taskNotifications = {
    inbox: {
      icon: <BoxIcon />,
      label: "Inbox",
      color: "#52BD94",
      backgroundColor: "#DCF2EA",
      hideAmount: false,
      viewMode: "inbox",
      whereClause: [
        ['createdBy', '==', user.uid],
        ['isPrivate', '==', true],
        ['isComplete', '==', false],
        ['isDelete', '==', false],
        ['viewMode', '==', VIEW_MODE.INBOX]
      ]
    },
    today: {
      icon: <StarIcon />,
      label: "Today",
      color: "#FFB020",
      backgroundColor: "#FFEFD2",
      hideAmount: false,
      viewMode: "today",
      whereClause: [
        ['createdBy', '==', user.uid],
        // ['isPrivate', '==', true],
        ['isComplete', '==', false],
        ['isDelete', '==', false],
        ['dueDate', '>=', greaterThanDate.getTime()],
        ['dueDate', '<=', lessThanDate.getTime()],
        ['viewMode', 'in', [VIEW_MODE.INBOX, VIEW_MODE.PROJECT]]
      ]
    },
    someday: {
      icon: <ProjectsIcon />,
      label: "Someday",
      color: "#897AE3",
      backgroundColor: "#E7E4F9",
      hideAmount: false,
      viewMode: "someday",
      whereClause: [
        ['createdBy', '==', user.uid],
        ['isPrivate', '==', true],
        ['isComplete', '==', false],
        ['isDelete', '==', false],
        // ['dueDate', '==', null],
        ['viewMode', '==', VIEW_MODE.SOMEDAY],
      ]
    },
    toMe: {
      icon: <UserIcon />,
      label: "Assigned to me",
      color: "#3366FF",
      backgroundColor: "#D6E0FF",
      hideAmount: false,
      viewMode: "tome",
      whereClause: [
        ['isComplete', '==', false],
        [`assignees.${user.uid}.id`, 'in', [user.uid]],
        ['isDelete', '==', false]
      ]
    },
  };

  const [notifications] = useState(taskNotifications);

  const onDropTask = async (viewMode: string, item: any) => {
    const isHeading = item.task.type === TASK_TYPE.HEADING;
    if(isHeading && viewMode !== "trash") return;

    switch (viewMode) {
      case 'inbox':
        await taskService.partialUpdate({
          id: item.task.id,
          assignees: null,
          createdBy: user.uid,
          project: null,
          isPrivate: true,
          isDelete: false,
          viewMode: VIEW_MODE.INBOX
        });
        break;
      case "today":
        await taskService.partialUpdate({
          id: item.task.id,
          dueDate: (new Date()).getTime(),
          isDelete: false
        });
        break;
      case "tome":
        const project = item.task.project;
        if (!project) return;

        await taskService.partialUpdate({
          id: item.task.id,
          assignees: getAssignees(item.task.assignees, user),
        });
        break;
      case "trash":
        await taskService.partialUpdate({
          id: item.task.id,
          isDelete: true,
          isDeletedBy: user.uid
        });
        break;
      case "someday":
        await taskService.partialUpdate({
          id: item.task.id,
          // dueDate: null,
          isPrivate: true,
          project: null,
          viewMode: VIEW_MODE.SOMEDAY
        });
        break;
      default:
        break;
    }
  }

  const [{ isOver }, dropItemRef] = useDrop(() => ({
    accept: ["TASK"],
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    drop: async (item: any, monitor) => {
      onDropTask && await onDropTask("trash", item);
    }
  }));

  const onSetDueDate = async (dateSelected: any) => {
    setShowDatePicker(false);
    await taskService.partialUpdate({
      id: somedayTaskId,
      dueDate: (new Date(dateSelected)).getTime(),
      isDelete: false,
    });
  }

  const handleClickOutside = (event: any) => {
    const isClickOnEl = (selectors: string, event: any) => {
      const el = document.querySelector(selectors);
      return el && el.contains(event.target);
    }
    if (isClickOnEl(".ant-picker-panel-container", event)) return;

    setShowDatePicker(false);
    document.removeEventListener('click', handleClickOutside, true);
  }

  useEffect(() => {
    if (showDatePicker) {
      setTimeout(() => {
        document.addEventListener('click', handleClickOutside, true);
      }, 100);
    }
    else {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [showDatePicker]);

  const disabledDate = (current: any) => {
    const date = new Date();
    date.setHours(0, 0, 0);
    return current && current < date;
  }

  return (
    <>
      <Card className={clsx(boardStyles.asideContainer, taskStyles.taskBoard)}>
        {header}
        <DatePicker
          className="hiddenDatePicker"
          onChange={onSetDueDate}
          open={showDatePicker}
          disabledDate={disabledDate}
        />
        <Pane paddingTop={majorScale(5)} backgroundColor="#FAFBFF">
          {Object.values(notifications).map((notification, index) => (
            <TaskNotification
              key={index}
              {...notification}
              onDropTask={onDropTask}
            />)
          )}
        </Pane>

        <Pane
          display={"flex"}
          justifyContent={"space-between"}
          padding={minorScale(1)}
          className={clsx(styles.notificationItem, isTrashPage && styles.selected, isOver && styles.dropOver)}
          ref={dropItemRef}
          onClick={() => history.push("/task/trash")}
        >
          <Pane display={"flex"}>
            <Pane color="#D14343" paddingRight={majorScale(2)} alignSelf={"center"} display={"flex"}>{<TrashIcon />}</Pane>
            <Text color="#101840" fontWeight={500} alignSelf={"center"}>Trash</Text>
          </Pane>
        </Pane>
        <Pane className={taskStyles.projectTask}>
          <OwnerProjects />
          <ShareProjects />
          <CustomDragLayer snapToGrid={false} />
        </Pane>
        <Pane className={boardStyles.bottomSidebar}>
          <AppFooterBoard />
        </Pane>
      </Card>
    </>
  )
}

export default TaskBoard;
