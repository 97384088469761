import { useEffect, useLayoutEffect, useReducer, useRef, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import './GraphEditor.css';
import './utils/ZwfConstants';
import { DocumentService } from 'services/document';
import useLoad from './hooks/useLoad';
import Toolbar from './modules/Toolbar/Toolbar';
import Menubar from './modules/Menubar/Menubar';
import MenubarViewer from './modules/Menubar/MenubarViewer';
import { PopupMenuRender } from './modules/PopupMenu/RenderPopupMenu';
import Sidebar from './modules/Sidebar/Sidebar';
import { Spinner } from '../Spinner/Spinner';
import styles from './EditorApp.module.scss';
import { Format } from './modules/Format/Format';
import { db } from '../../utils/firebase';
import { EditorContextProvider } from '../../context/EditorContext';
import { CommentBar } from './modules/CommentBar/CommentBar';
import { COMMENT_COLLECTION, DOCUMENT_COLLECTION, USER_COLLECTION, CONVERGENCE_URL } from 'utils/constants';
import { mxGraphModel, mxUtils, mxPopupMenu, mxEvent, mxResources, mxAutoSaveManager, mxCodec } from 'libs/mxgraph';
import { PermissonType } from 'schemas/role';
import { isHavePermission } from 'services/user';
import {
  MxGraphAdapter,
  PointerManager,
  SelectionManager,
  Deserializer,
  Serializer,
} from 'libs/mxgraph-adapter';
import { useDocumentDetail } from 'services/document';
import useStores from 'hooks/useStores';
import { getUser, isFreeUser } from 'utils/store';
import { Helmet } from 'react-helmet-async';
import { AuthService } from 'services/authService';
import { chunk } from 'utils/array';
import {getXMLByParam} from "../../pages/Templates/TemplateDefinition";
import {DEFAULT_GRAPH, DEFAULT_GRAPH_FLOW_CHART} from "./xml";

TimeAgo.addDefaultLocale(en);

const documentService = new DocumentService();

export default function DocumentEditor(props) {
  const { document: docData, isLoading: isLoadingDocument, getDocument, folder } = useDocumentDetail();
  const graphContainer = useRef();
  const editorUi = useRef();
  const isMxScriptReady = useLoad();
  const unsubcribeDocumentRef = useRef(null)
  const unsubscribeDocumentCellRef = useRef(null);
  const unsubcribeCommentRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [isCommentBarVisible, setIsCommentBarVisible] = useState(false);
  const [isEditorInitReady, setEditorInitReady] = useState(false);
  const [isCheckedPermission, setCheckedPermission] = useState(false);
  const [haveEditPermisson, setHaveEditPermisson] = useState(false);
  const [isLoadedGraph, setLoadedGraph] = useState(false);
  const [isSyncLoaded, setSyncLoaded] = useState(false);
  const [themes, setThemes] = useState({});
  const [isConnectedConvergence, setIsConnectedConvergence]= useState(false);
  const [panelState, dispatchPanelState] = useReducer((state, panel) => {
    return { ...state, ...panel };
  }, { optionPanel: true, sidePanel: true });
  const history = useHistory();
  const location = useLocation();
  const template = new URLSearchParams(location.search).get('template');
  const isFlowchart = location.pathname.includes("/flowchart/");

  const params = useParams();

  const { convergenceStore, graphStore } = useStores();

  useEffect(() => {
    graphStore.setSession({
      id: Math.random().toString(16).slice(2),
    });

    const updateOnlineStatus = () => {
      graphStore.setIsOnline(window.navigator.onLine);
    }

    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  }, []);

  useEffect(() => {
    const fetchDocument = async documentId => {
      return await getDocument(documentId);
    }

    const fetchComments = documentId => {
      return db
        .collection(DOCUMENT_COLLECTION)
        .doc(documentId)
        .collection(COMMENT_COLLECTION)
        .onSnapshot(async querySnapshot => {
          const comments = [];
          for (const doc of querySnapshot.docs) {
            const comment = { id: doc.id, ...doc.data() };

            // Populate user
            if (comment.user) {
              const user = await db
                .collection(USER_COLLECTION)
                .doc(comment.user.toString())
                .get();
              if (user.exists) {
                const userData = user.data();
                userData.fullName = [userData.firstName, userData.lastName]
                  .filter(item => !!item)
                  .join(' ');
                comment.user = { id: user.id, ...userData };
              }
            }
            comments.push(comment);
          }

          // Update comments
          setComments(comments);
        })
    }

    (async () => {
      if (params.id) {
        unsubcribeDocumentRef.current = await fetchDocument(params.id);
        unsubcribeCommentRef.current = fetchComments(params.id);
      }
    })()

  }, [params.id]);

  useEffect(() => {
    return () => {
      // Unsubscribe firebase snapshot when change to new document
      if (unsubcribeDocumentRef.current) {
        unsubcribeDocumentRef.current();
      }
      if (unsubcribeCommentRef.current) {
        unsubcribeCommentRef.current();
      }
      if (unsubscribeDocumentCellRef.current) {
        unsubscribeDocumentCellRef.current();
      }
    }
  }, [])

  useEffect(() => {
    const isFolderLoaded = (folder && docData.folder);
    const isDocumentHasNotFolder = (docData && !docData.folder);
    if (!isLoadingDocument && (isDocumentHasNotFolder || isFolderLoaded)) {
      graphStore.setDocument(docData);
      const user = getUser();
      if (!isHavePermission(user, docData, PermissonType.ViewDoc, folder)) {
        history.push('/');
      }
      if (isHavePermission(user, docData, PermissonType.EditDoc, folder)) {
        setHaveEditPermisson(true);
      } else {
        setHaveEditPermisson(false);
      }
      setCheckedPermission(true);
    }
  }, [isLoadingDocument, folder]);

  useLayoutEffect(() => {
    if (isMxScriptReady && isCheckedPermission) {
      const EditorUi = window.EditorUi;
      const Editor = window.Editor;
      const Graph = window.Graph;
      const mxLanguage = window.mxLanguage;

      const RESOURCE_BASE = window.RESOURCE_BASE;
      const STYLE_PATH = window.STYLE_PATH;

      const editorUiInit = EditorUi.prototype.init;

      EditorUi.prototype.init = function() {
        editorUiInit.apply(this, arguments);
        this.actions.get('export').setEnabled(false);

        // Updates action states which require a backend
        if (!Editor.useLocalStorage) {
          const enabled = false;
          this.actions.get('open').setEnabled(enabled || Graph.fileSupport);
          this.actions.get('import').setEnabled(enabled || Graph.fileSupport);
          this.actions.get('save').setEnabled(enabled);
          this.actions.get('saveAs').setEnabled(enabled);
          this.actions.get('export').setEnabled(enabled);
        }
      };

      mxResources.loadDefaultBundle = false;
      var bundle =
        mxResources.getDefaultBundle(RESOURCE_BASE, mxLanguage) ||
        mxResources.getSpecialBundle(RESOURCE_BASE, mxLanguage);

      mxUtils.getAll(
        [bundle, STYLE_PATH + '/default.xml'],
        async function(xhr) {
          mxResources.parse(xhr[0].getText());
          setThemes({ default: xhr[1].getDocumentElement() });
          setEditorInitReady(true);
        },
        function() {
          document.body.innerHTML =
            '<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>';
        },
      );
      return () => {
        editorUi.current?.destroy();
      };
    }
  }, [isMxScriptReady, isCheckedPermission]);

  useEffect(() => {
    (async() => {
      if (isEditorInitReady && isCheckedPermission) {
        await loadGraphData();
        await setupSyncRealtime();
        setSyncLoaded(true);
      }
    })()
  }, [isEditorInitReady, isCheckedPermission]);

  useEffect(() => {
    const handleTasks = async () => {
      if (!convergenceStore.isConnected) {
        let token = null;
        if (getUser()) {
          const authService = new AuthService();
          token = await authService.getConvergenceToken();
        }
        await convergenceStore.connect(CONVERGENCE_URL, token);
        convergenceStore.setConnected(true);
        setIsConnectedConvergence(true);
      } else {
        setIsConnectedConvergence(true);
      }
    }

    handleTasks();
  }, []);

  useEffect(() => {
    if (isLoadedGraph && isConnectedConvergence && isSyncLoaded) {
      connectToConvergence();
    }
  }, [isLoadedGraph, isConnectedConvergence, isSyncLoaded]);

  const graphModelChanged = async () => {
    const ui = editorUi.current;
    if (graphStore.isOnline && params.id) {
      try {
        const xmlData = ui.editor.getGraphXml();
        if (typeof xmlData === 'object') {
          graphStore.setIsSaving(true);
          const xml = mxUtils.getXml(xmlData);
          await documentService.updateOne(params.id, { updatedAt: new Date(), xmlData: xml });
          graphStore.setIsSaving(false);
        } else {
          console.log('xml Data not an object: ', xmlData);
        }
      } catch (e) {
        console.log('Error when save graph: ', e);
      }
    }
  }

  /**
   * Load graph data from xml
   */
  const loadGraphData = useCallback(async () => {
    const EditorUi = window.EditorUi;
    const Editor = window.Editor;
    const urlParams = window.urlParams;

    const mxModel = new mxGraphModel();
    const defaultGraph = isFlowchart ? DEFAULT_GRAPH_FLOW_CHART : DEFAULT_GRAPH;
    var doc = mxUtils.parseXml(docData.xmlData || docData.graph || getXMLByParam(template) || defaultGraph);
    var codec = new mxCodec(doc);
    codec.decode(doc.documentElement, mxModel);

    const jsonModel = Serializer.serializeMxGraphModel(mxModel);
    const graphModel = Deserializer.deserializeMxGraphModel(jsonModel);

    try {
      const editor = new Editor(urlParams['chrome'] === '0', themes, graphModel);
      if (!haveEditPermisson) {
        EditorUi.prototype.formatWidth = 0;
        EditorUi.prototype.menubarHeight = 48;
        EditorUi.prototype.toolbarHeight = 0;
      }
      const ui = new EditorUi(editor, graphContainer.current);
      ui.handleError = (e) => {
        console.error(e);
      };
      graphStore.setEditorUi(ui);
      editorUi.current = ui;
      const defaultGraph = isFlowchart ? DEFAULT_GRAPH_FLOW_CHART : DEFAULT_GRAPH;
      ui.editor.setGraphXml(docData.xmlData || docData.graph || getXMLByParam(template) || defaultGraph);

      setTimeout(() => {
        editorUi?.current?.editor.graph.view.refresh();
        editorUi?.current?.setPageVisible(false);
        editorUi?.current?.refresh();

        // to force save xml
        const isCreateFromTemplate = template;
        if(isCreateFromTemplate){
          graphModelChanged();
        }
      }, 0);
      editorUi.current?.setFreeUser(isFreeUser());

      handleGraphEvent();
      setLoadedGraph(true);
    } catch (e) {
      console.log('Error when connect to realtime server: ', e);
    }
  });

  /**
   * Setup sync realtime
   */
  const setupSyncRealtime = useCallback(async() => {
    try {
      const mxModel = new mxGraphModel();
      const defaultGraph = isFlowchart ? DEFAULT_GRAPH_FLOW_CHART : DEFAULT_GRAPH;
      var doc = mxUtils.parseXml(docData.xmlData || docData.graph || getXMLByParam(template) || defaultGraph);
      var codec = new mxCodec(doc);
      codec.decode(doc.documentElement, mxModel);

      const jsonModel = Serializer.serializeMxGraphModel(mxModel);
      const cellCollection = documentService.getDoc(docData.id).collection('cells');
      const existCellCollections = await cellCollection.doc('1').get();
      if (!existCellCollections.exists) {
        const modelCells = jsonModel.cells || {};
        const keyCells = Object.keys(modelCells) || [];

        const batchCells = chunk(keyCells, 400);
        batchCells.forEach(keyList => {
          const batch = db.batch();
          keyList.forEach((index) => {
            if (modelCells[index]) {
              batch.set(cellCollection.doc(index), modelCells[index]);
            }
          });
          batch.commit();
        });
      }

      const graph = editorUi.current.editor.graph;
      const modelAdapter = new MxGraphAdapter(graph, cellCollection, graphStore.session);
      convergenceStore.setModelAdapter(modelAdapter);

      const isTableChange = (cellData) => {
        if (cellData.style?.styles?.shape === 'table' || cellData.style?.styles?.shape === 'partialRectangle') {
          return true;
        }
        return false;
      }

      let initState = true;
      const unsubscribeDocumentCell = documentService
        .getDoc(docData.id)
        .collection('cells')
        .onSnapshot((snapshot) => {
          if (initState) {
            initState = false;
          } else {
            if (!snapshot.docChanges().empty) {
              snapshot.docChanges().forEach((change) => {
                const cellData = change.doc.data();
                const cell = graph.model.cells[cellData.id];

                if (cellData.session?.id !== graphStore.session?.id && isTableChange(cellData)) {
                  if (cellData.parent === '1') {

                  }
                }

                if (cellData.session?.id !== graphStore.session?.id) {
                  cellData.remote = true;

                  if (change.type === 'added') {
                    convergenceStore.modelAdapter?.handleRemoteCellAdded(cellData);
                  }

                  if (cell && change.type === 'modified') {
                    if (cellData.style) {
                      const newStyle = Deserializer.deserializeStyle(cellData.style);
                      cell.setStyle(newStyle);
                      graph.view.removeState(cell);
                    }
                    if (cellData.geometry) {
                      const geometry = Deserializer.deserializeGeometry(
                        cellData.geometry,
                      );
                      if (geometry) {
                        cell.setGeometry(geometry);
                        // if (cell.parent && (cell.parent.style?.includes('shape=table'))) {
                        //   const parentCell = cell.parent;
                        //   graph.view.clear(parentCell);
                        // }
                      }
                    }

                    if (cellData.link) {
                      graph.setAttributeForCell(cell, 'link', cellData.link);
                    } else {
                      cell.setValue(cellData.value || '');
                    }

                    // Index
                    convergenceStore.modelAdapter?.handleRemoteUpdateIndex(cell, cellData);

                    // Target
                    const targetId = cellData.target;
                    const target = targetId === null ? null : graph.model.cells[targetId];
                    cell.setTerminal(target, false);

                    // Source
                    const sourceId = cellData.source;
                    const source = sourceId === null ? null : graph.model.cells[sourceId];
                    cell.setTerminal(source, true);

                    cell.setVisible(cellData.visible);

                    // Setup parent
                    convergenceStore.modelAdapter?.handleRemoteUpdateParent(cell, cellData);
                  }
                }

                if (change.type === 'removed') {
                  const cell = graph.model.cells[cellData.id];
                  if (cell) {
                    convergenceStore.modelAdapter?.handleRemoteCellRemoved(cellData);
                  }
                }
              });
              graph?.view?.refresh();
            }
          }
        });
        unsubscribeDocumentCellRef.current = unsubscribeDocumentCell;
    } catch (e) {
      console.log('Error when setup sync: ', e);
    }
  })

  /**
   * Connect to convergence realtime server
   */
  const connectToConvergence = useCallback(async () => {
    try {
      const user = getUser();
      const graphId = params?.id;

      Promise.all([convergenceStore.joinActivity(graphId)])
        .then(() => {
          // const rtGraph = convergenceStore.model.root();
          const graph = ((editorUi.current.editor || {}).graph || {});
          // convergenceStore.setRtGraph(rtGraph);

          if (user) {
            const pointerManager = new PointerManager(graph, convergenceStore.activity, convergenceStore.activityColorManager);
            const selectionManager = new SelectionManager(graph, convergenceStore.activity,
                convergenceStore.activityColorManager, convergenceStore.modelAdapter);
            convergenceStore.setPointerManager(pointerManager);
            convergenceStore.setSelectionManager(selectionManager);
            convergenceStore.initPresenceList();
          }
        });
    } catch (e) {
      console.log('Error when connect to realtime server: ', e);
    }
  });

  /**
   * Handle graph event
   */
  const handleGraphEvent = (defaultConnected) => {
    const user = getUser();

    const graph = editorUi.current.editor.graph;
    const mgr = new mxAutoSaveManager(graph);
    mgr.graphModelChanged = graphModelChanged;

    graph.addListener(mxEvent.CLICK, function(graph, mxEventObject) {
      if (mxPopupMenu.prototype.checkShowPopupMenu) {
        PopupMenuRender(editorUi, graphStore.document);
        mxPopupMenu.prototype.checkShowPopupMenu = false;
      }
    });
    if (isHavePermission(user, docData, PermissonType.EditDoc, folder)) {
      editorUi.current.editor.graph.setEnabled(true);
    } else {
      editorUi.current.editor.graph.setEnabled(false);
    }
  };

  useEffect(() => {
    const user = getUser();
    const editor = editorUi?.current?.editor;
    if(!editor) return ;
    if (isHavePermission(user, docData, PermissonType.EditDoc, folder)) {
      editor.graph.setEnabled(true);
    }else {
      editor.graph.setEnabled(false);
    }
  }, [folder])

  const handleChangeDocumentName = async name => {
    await documentService.updateOne(docData.id, { name });
  };

  const toggleCommentBar = () => setIsCommentBarVisible(!isCommentBarVisible);

  if (!docData.id || !isLoadedGraph) {
    return <Spinner />;
  }

  return (
    <EditorContextProvider
      value={{
        documentId: params.id,
        comments: comments,
        toggleCommentBar,
        isCommentBarVisible,
        editorUi: editorUi.current,
        dispatchPanelState,
      }}
    >
      <Helmet
        title={`Zen Flowchart - ${docData.name}`}
      />
      <div className={styles.container}>
        {haveEditPermisson ? (
          <>
            <div className={styles.menubarContainer}>
              <Menubar
                editorUi={editorUi}
                documentName={docData.name}
                unreadComments={comments.length}
                folder={folder}
                onChangeDocumentName={handleChangeDocumentName}
              />
            </div>

            <div className={styles.toolbarContainer}>
              <Toolbar
                editorUi={editorUi}
                dispatchPanelState={dispatchPanelState}
                panelState={panelState}
                document={docData}
              />
            </div>
          </>
        ) : (
          <div className={styles.toolbarContainer}>
            <MenubarViewer
              editorUi={editorUi}
              documentName={docData.name}
              unreadComments={comments.length}
              onChangeDocumentName={handleChangeDocumentName}
              dispatchPanelState={dispatchPanelState}
              panelState={panelState}
              folder={folder}
            />
          </div>
        )}

        {haveEditPermisson && (
          <>
            <div className={clsx('zwfSidebarContainer', styles.sidebarContainer)}>
              <Sidebar
                editorUi={editorUi.current}
                dispatchPanelState={dispatchPanelState}
              />
            </div>

            <div className={clsx('zwfFormatContainer', styles.formatContainer)}>
              <Format
                editorUi={editorUi.current}
                dispatchPanelState={dispatchPanelState}
              />
            </div>
          </>
        )}

        <div
          className={
            clsx(
              'zwfCommentBarContainer',
              styles.commentBarContainer,
              !haveEditPermisson && styles.commentBarView,
              isCommentBarVisible && styles.commentBarVisible,
            )}
        >
          <CommentBar />
        </div>


        <div ref={graphContainer} id='graphContainer' />
      </div>
    </EditorContextProvider>
  );
}
