import {
  STRIPE_MONTHLY_PLAN_ID,
  STRIPE_YEARLY_PLAN_ID,
  STRIPE_PLUS_MONTHLY_PLAN_ID,
  STRIPE_PLUS_YEARLY_PLAN_ID,
  STRIPE_TEAM_MONTHLY_PLAN_ID,
  STRIPE_TEAM_YEARLY_PLAN_ID,
} from '../utils/constants';
import { UpgradeTier } from './user';

// Plan
export enum Plan {
  Yearly = 95.4,
  Monthly = 9.95,
}

export enum PlanPlus {
  Yearly = 75.6,
  Monthly = 7.9,
}

export enum PlanTeam {
  Yearly = 108,
  Monthly = 12,
}

// Price
export const Price = {
  [Plan.Yearly]: STRIPE_YEARLY_PLAN_ID,
  [Plan.Monthly]: STRIPE_MONTHLY_PLAN_ID,
};

export const PricePlus = {
  [PlanPlus.Yearly]: STRIPE_PLUS_YEARLY_PLAN_ID,
  [PlanPlus.Monthly]: STRIPE_PLUS_MONTHLY_PLAN_ID
}

export const PriceTeam = {
  [PlanTeam.Yearly]: STRIPE_TEAM_YEARLY_PLAN_ID,
  [PlanTeam.Monthly]: STRIPE_TEAM_MONTHLY_PLAN_ID,
}

export const eachMonth = (price: number) => isYearly(price) ? (price / 12).toFixed(2) : price;

export const eachMonthTeam = (price: number) => isYearly(price) ? (price / 12) : price;


export const isYearly = (value: number) => {
  return (value === Plan.Yearly || value === PlanPlus.Yearly || value === PlanTeam.Yearly);
}

export const isMonthly = (value: number) => {
  return (value === Plan.Monthly || value === PlanPlus.Monthly || value === PlanTeam.Monthly);
}

export const isSelectPlus = (selected: string) => {
  return UpgradeTier.Plus === selected;
}

export const isSelectPro = (selected: string) => {
  return UpgradeTier.Pro === selected;
}

export const isSelectTeam = (selected: string) => {
  return UpgradeTier.Team === selected;
}

export const getTotal = (price: number, quantity: string) => {
  return (price * parseInt(quantity)).toFixed(2);
}
