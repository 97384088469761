import { TextInput } from 'evergreen-ui';
import { useCallback, useState } from 'react';
import { Dialog } from 'components/Dialog';
import useKeypress from 'hooks/useKeyPress';

interface Props {
  isShown: boolean;
  setIsShown: Function;
}

export default function CreateBoardModal({ isShown, setIsShown }: Props) {
  const [newBoardName, setNewBoardName] = useState('');

  const createNewBoard = useCallback(async () => {
    setIsShown(false);
    // try {
    //   setIsShown(false);
    //   await folderService.create({
    //     name: newBoardName,
    //   }, parentFolder);
    // } finally {
    //   setNewBoardName('');
    // }
  }, [newBoardName, setIsShown]);

  return (
    <>
      <Dialog
        isShown={isShown}
        title="Create New Board"
        onCloseComplete={() => setIsShown(false)}
        preventBodyScrolling
        confirmLabel="Create"
        onConfirm={createNewBoard}
      >
        <NewBoardContent
          createNewBoard={createNewBoard}
          setNewBoardName={setNewBoardName}
          newBoardName={newBoardName}
        />
      </Dialog>
    </>
  );
}

type NewBoardContentProps = {
  newBoardName: string;
  setNewBoardName: Function;
  createNewBoard: Function;
};

const NewBoardContent = ({
  newBoardName,
  setNewBoardName,
  createNewBoard,
}: NewBoardContentProps) => {
  useKeypress('Enter', createNewBoard);

  return (
    <TextInput
      width="100%"
      required
      name="name"
      autoFocus={true}
      value={newBoardName}
      onChange={(e: any) => setNewBoardName(e.target.value)}
      placeholder="Board Name"
    />
  );
};
