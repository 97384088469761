import store from 'store2';
import {
  KEY,
  CONVERGENCE_TOKEN_KEY,
  TIER,
  PRO_USER,
  PRO_TEAM,
  TEAMS,
  PROJECTS,
  OWNER_PROJECTS,
  SHARE_PROJECTS,
  SHOW_TAG,
  PAGES,
  OWNER_PAGES,
  SHARE_PAGES,
  PLUS_USER,
} from './constants';

export const getUser = () => store(KEY);
export const setUser = (data: any) => store(KEY, data);
export const updateUser = (data: any) => store(KEY, { ...getUser(), ...data });
export const deleteUser = () => store.remove(KEY);
export const isFreeUser = () => (store(KEY)?.[TIER] !== PRO_USER && store(KEY)?.[TIER] !== PLUS_USER);
export const isPlusUser = () => store(KEY)?.[TIER] === PLUS_USER;
export const isPROTeam = () => store(KEY)?.[PRO_TEAM];

export const getProjects = () => store(`${KEY}.${PROJECTS}`);
export const setProjects = (data: any) => store(`${KEY}.${PROJECTS}`, data);
export const getOwnerProjects = () => store(`${KEY}.${OWNER_PROJECTS}`);
export const setOwnerProjects = (data: any) => store(`${KEY}.${OWNER_PROJECTS}`, data);
export const getShareProjects = () => store(`${KEY}.${SHARE_PROJECTS}`);
export const setShareProjects = (data: any) => store(`${KEY}.${SHARE_PROJECTS}`, data);

export const getPages = () => store(`${KEY}.${PAGES}`);
export const setPages = (data: any) => store(`${KEY}.${PAGES}`, data);
export const getOwnerPages = () => store(`${KEY}.${OWNER_PAGES}`);
export const setOwnerPages = (data: any) => store(`${KEY}.${OWNER_PAGES}`, data);
export const getSharePages = () => store(`${KEY}.${SHARE_PAGES}`);
export const setSharePages = (data: any) => store(`${KEY}.${SHARE_PAGES}`, data);

export const getTeams = () => store(TEAMS);
export const setTeams = (data: any) => store(TEAMS, data);

export const setUserSort = (key: string, data: object) => {
  return store(key, JSON.stringify(data));
}

export const getUserSort = (key: string): {
  field: string;
  direction: string;
} => {
  return JSON.parse(store(key));
}

export const setCollaborationToken = (data: string) => store(CONVERGENCE_TOKEN_KEY, data);

export const getCollaborationToken = () => store(CONVERGENCE_TOKEN_KEY);

export const getTagSetting = () => store(SHOW_TAG);
export const setTagSetting = (show: boolean) => store(SHOW_TAG, show);
