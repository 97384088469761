import React, { useState } from 'react';
import {
  StatusIndicator,
  Pane,
  Tooltip,
  PlusIcon,
  IconButton,
  Position,
  Card
} from 'evergreen-ui';
import CreateBoardModal from "../../../components/Kanban/CreateBoardModal";

import boardStyles from '../Board.module.scss';
import styles from './Style.module.scss';
// import clsx from 'clsx';
import AppFooterBoard from "../App/Footer";

function KanbanBoard({ header }: any) {
  const [isShowCreate, setShowCreate] = useState(false);

  return (
    <Card className={boardStyles.asideContainer}>
      {header}
      <Pane className={styles.kabanStyle}>
        <Pane>
          <Pane className={styles.titleKaban}>
            <span className={styles.sidebarLabel}>My Documents</span>
            <Tooltip
              content={<div className={styles.toolTipLabel}>New Board</div>}
              position={Position.RIGHT}
            >
              <IconButton
                width={20}
                className={styles.plusIconBtn}
                icon={<PlusIcon size={9} />}
                onClick={() => setShowCreate(true)}
              />
            </Tooltip>
          </Pane>
          <Pane className={styles.bodyBoard}>
            <Pane className={styles.element}>
              <StatusIndicator color="#8F95B2" className={styles.content}>Mind Map App</StatusIndicator>
            </Pane>
            <Pane className={styles.element}>
              <StatusIndicator color="#A73636" className={styles.content}>Mobile Diary App</StatusIndicator>
            </Pane>
            <Pane className={styles.element}>
              <StatusIndicator color="#1F3D99" className={styles.content}>Team Collaboration Feature</StatusIndicator>
            </Pane>
          </Pane>
        </Pane>

        <Pane>
          <Pane className={styles.titleKaban}>
            <span className={styles.sidebarLabel}>Share with me</span>
          </Pane>
          <Pane className={styles.bodyShare}>
            <Pane className={styles.element}>
              <StatusIndicator color="#8F95B2" className={styles.content}>Mind Map App</StatusIndicator>
            </Pane>
            <Pane className={styles.element}>
              <StatusIndicator color="#A73636" className={styles.content}>Mobile Diary App</StatusIndicator>
            </Pane>
          </Pane>
        </Pane>
        <CreateBoardModal isShown={isShowCreate} setIsShown={setShowCreate} />
      </Pane>
      <Pane className={boardStyles.bottomSidebar}>
        <AppFooterBoard />
      </Pane>
    </Card>
  );
}

export default KanbanBoard;