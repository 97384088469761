import {Button, majorScale, Pane} from "evergreen-ui";
import TaskItem from "./TaskItem";
import React, {useEffect, useState} from "react";
import _ from "lodash";

type Props = {
  tasks: any[],
  assignees?: any,
  taskSelected?: boolean,
  onSelect?: Function
  dateAble: boolean
}

const TaskComplete = ({tasks, assignees, taskSelected, onSelect, dateAble}: Props) => {
  const [showCompleteItems, setShowCompleteItems] = useState(false);
  const [taskCount, setTaskCount] = useState((tasks || []).length);

  useEffect(() => {
    setTaskCount((tasks || []).length);
  }, [tasks]);

  return (
    <>
      {taskCount !== 0 &&
        <Pane paddingTop={majorScale(5)} marginBottom={majorScale(1)}>
          <Button onClick={() => setShowCompleteItems(!showCompleteItems)}>
            {(showCompleteItems ? "Hide " : "Show ") + taskCount} Completed Item{taskCount > 1 ? "s" : ""}
          </Button>
        </Pane>
      }
      {
        taskCount !== 0 && showCompleteItems &&
        <>
          {
            tasks.map(
              (item) => (
                <TaskItem
                  key={item.id}
                  {...item}
                  onSelect={onSelect}
                  isSelected={_.get(item, "id") === taskSelected}
                  members={assignees}
                  dateAble={dateAble}
                />
              )
            )
          }
        </>
      }
    </>
  )
}

export default TaskComplete;
