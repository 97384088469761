import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useContext, useState } from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { useElements, CardNumberElement, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import {
  ArrowLeftIcon,
  Badge,
  Button,
  CrossIcon,
  Dialog,
  Heading,
  LockIcon,
  majorScale,
  Pane,
  Paragraph,
  Select,
  SmallTickIcon,
  Switch,
  Text,
  TickCircleIcon,
  toaster,
  DataLineageIcon,
  BookIcon,
  LayoutAutoIcon,
  PropertyIcon,
  VolumeUpIcon,
  PresentationIcon,
  LocateIcon,
} from 'evergreen-ui';
import { Col, Row } from 'react-grid-system';
import firebase from 'firebase';
import {
  eachMonth,
  eachMonthTeam,
  getTotal,
  isMonthly,
  isSelectPlus,
  isSelectPro,
  isSelectTeam,
  Plan,
  PlanPlus,
  PlanTeam,
  Price,
  PricePlus,
  PriceTeam,
} from 'schemas/plan';
import { EVENT_CATALOG, TRACKING_CATALOG, UPGRADE_TO_PRO } from 'utils/constants';
import { CardNumber, CardExpiry, CardCvc } from 'components/CardPayment';
import { StripeService } from 'services/stripe';
import { createSelectOption } from 'utils/helpers';
import { EditorContext } from 'context/EditorContext';
import styles from './UpgradeToProModal.module.scss';
import logo from 'assets/img/logo.svg';
import { UpgradeTier } from 'schemas';
import UpgradeSuccessModal from './UpgradeSuccessModal';
import { updateUser } from '../../../utils/store';

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>
}

const stripeService = new StripeService();

const UpgradeToProModal = ({ isShown, setIsShown }: Props) => {
  const [toggle, setToggle] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [quantity, setQuantity] = useState('1');
  const [selectedPlan, setSelectedPlan] = useState(UpgradeTier.Pro)
  const [plan, setPlan] = useState<Plan>(Plan.Yearly);
  const [planPlus, setPlanPlus] = useState<PlanPlus>(PlanPlus.Yearly);
  const [planTeam, setPlanTeam] = useState<PlanTeam>(PlanTeam.Yearly);
  const { editorUi } = useContext(EditorContext);
  const elements = useElements();
  const stripe = useStripe();


  const handleSwitch = () => {
    const type = !toggle;
    setPlan(type ? Plan.Yearly : Plan.Monthly);
    setPlanTeam(type ? PlanTeam.Yearly : PlanTeam.Monthly);
    setPlanPlus(type ? PlanPlus.Yearly : PlanPlus.Monthly);
    setToggle(type);
  }

  const handleChangeMember = (event: ChangeEvent<HTMLSelectElement>) => {
    setQuantity(event.target.value as string);
  }

  const handleClickPlan = (tier: UpgradeTier) => {
    setSelectedPlan(tier);
  }

  const getPriceId = (selectedPlan: UpgradeTier) => {
    if (selectedPlan === UpgradeTier.Plus) {
      return PricePlus[planPlus];
    } else if (selectedPlan === UpgradeTier.Pro) {
      return Price[plan];
    } else if (selectedPlan === UpgradeTier.Team) {
      return PriceTeam[planTeam];
    } else {
      return '';
    }
  }

  const getPrice = (selectedPlan: UpgradeTier) => {
    if (selectedPlan === UpgradeTier.Plus) {
      return planPlus;
    } else if (selectedPlan === UpgradeTier.Pro) {
      return plan;
    } else if (selectedPlan === UpgradeTier.Team) {
      return getTotal(planTeam, quantity);
    } else {
      return '';
    }
  }

  const getBadge = (selectedPlan: UpgradeTier) => {
    if (selectedPlan === UpgradeTier.Plus) {
      return 'Plus';
    } else if (selectedPlan === UpgradeTier.Pro) {
      return 'PRO';
    } else if (selectedPlan === UpgradeTier.Team) {
      return `TEAM x ${quantity}`;
    } else {
      return '';
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!elements || !stripe) return;
      setIsSubmitting(true);
      const cardElement = elements.getElement(CardNumberElement);

      // @ts-ignore
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        card: cardElement,
        type: 'card',
      })

      if (error && error.message != null) {
        toaster.danger(error.message);
        return false;
      }

      // Create subscription
      const plan = getPriceId(selectedPlan);
      const { data } = await stripeService.createSubscription({
        plan,
        quantity: parseInt(quantity),
        upgradeTier: selectedPlan,
        paymentMethod: paymentMethod?.id,
      });

      // Required 3D Secure
      if (data.actionRequired) {
        const { error } = await stripe.confirmCardPayment(data.clientSecret);
        if (error) {
          toaster.danger('Error in payment, please try again later');
          console.log(error)
          return false;
        }
        await stripeService.confirm3DSecurePayment({
          quantity: parseInt(quantity),
          upgradeTier: selectedPlan,
          subscription: data.subscription,
          paymentToken: data.id,
        })
      }

      // Update store data after upgraded successfully
      const updatedData: any = {
        subscriptionPeriodUntil: firebase.firestore.Timestamp.fromDate(new Date(data.current_period_end * 1000)),
        tier: UpgradeTier.Pro
      };

      if (isSelectTeam(selectedPlan)) {
        updatedData.isAdminTeam = true;
        updatedData.isPROTeam = true;
      }

      if (isSelectPlus(selectedPlan)) {
        updatedData.tier = UpgradeTier.Plus;
      }

      updateUser(updatedData);
      const price = isSelectTeam(selectedPlan) ? getTotal(planTeam, quantity) : plan;
      ReactGA.event({ category: TRACKING_CATALOG[UPGRADE_TO_PRO], action: UPGRADE_TO_PRO });
      ReactPixel.track(EVENT_CATALOG[UPGRADE_TO_PRO], { value: price, currency: 'USD' });
      editorUi.setFreeUser(false);
      setShowSuccess(true);
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsShown(false);
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Dialog
        width={1238}
        isShown={isShown}
        hasHeader={false}
        hasFooter={false}
        onCloseComplete={() => setIsShown(false)}
        containerProps={{ className: styles.modal }}
        contentContainerProps={{ className: styles.modalContent }}
      >
        <Row>
          <Col md={8}>
            <Pane className={styles.upgradePlan}>
              <Pane className={styles.planType}>
                <Heading size={600}>Select a plan</Heading>
                <Paragraph display="flex" alignItems="center">
                  <Text marginRight={8}>Monthly</Text>
                  <Switch
                    marginRight={8}
                    checked={toggle}
                    onChange={handleSwitch}
                  />
                  <Text marginRight={8}>Yearly</Text>
                  <Badge color="green">saves 20%</Badge>
                </Paragraph>
              </Pane>
              <Row className="mt-3">
                <Col className={styles.colPr0}>
                  <Pane
                    className={clsx(
                      styles.plan,
                      styles.planPlus,
                      isSelectPlus(selectedPlan) && styles.planSelected,
                    )}
                    onClick={() => handleClickPlan(UpgradeTier.Plus)}
                  >
                    <TickCircleIcon className={styles.tick} />
                    <Heading size={600} className="mt-2">Plus</Heading>
                    <Paragraph className={styles.planPriceHighlight}>${eachMonth(planPlus)}</Paragraph>
                    <Paragraph fontSize={12}>per user, per month</Paragraph>
                    <Pane className={styles.feature}>
                      <ul>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Unlimited Flowcharts</Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Unlimited Mind Maps</Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Live Collaboration</Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Email Support</Text>
                        </li>
                      </ul>
                    </Pane>
                  </Pane>
                </Col>
                <Col className={styles.colPr0}>
                  <Pane
                    className={clsx(
                      styles.plan,
                      styles.planPro,
                      isSelectPro(selectedPlan) && styles.planSelected,
                    )}
                    onClick={() => handleClickPlan(UpgradeTier.Pro)}
                  >
                    <TickCircleIcon className={styles.tick} />
                    <Pane display="flex" alignItems="center" className="mt-2">
                      <Heading size={600}>PRO</Heading>
                      <Badge color="blue" marginLeft="1rem">MOST POPULAR</Badge>
                    </Pane>
                    <Paragraph className={styles.planPriceHighlight}>${eachMonth(plan)}</Paragraph>
                    <Paragraph fontSize={12}>per user, per month</Paragraph>
                    <Paragraph marginTop="2.25rem">Unlimited access to all apps, live collaboration, and more!</Paragraph>
                    <Pane className={clsx(styles.feature, styles.featureTwo)}>
                      <Pane display="flex">
                        <ul>
                          <li>
                            <DataLineageIcon className={styles.featureIcon} color="#3366FF" />
                            <Text>Flowchart</Text>
                          </li>
                          <li>
                            <LayoutAutoIcon className={styles.featureIcon} color="#DE7548" />
                            <Text>Mind Map</Text>
                          </li>
                          <li>
                            <PresentationIcon className={styles.featureIcon} color="#D14343" />
                            <Text>Whiteboard</Text>
                          </li>
                          <li>
                            <LocateIcon className={styles.featureIcon} color="#52BD94" />
                            <Text>Zen OKR</Text>
                          </li>
                        </ul>
                        <ul className="ml-4">
                          <li>
                            <BookIcon className={styles.featureIcon} color="#FFB020" />
                            <Text>Zen Notes</Text>
                          </li>
                          <li>
                            <PropertyIcon className={styles.featureIcon} color="#52BD94" />
                            <Text>Zen Task</Text>
                          </li>
                          <li>
                            <VolumeUpIcon className={styles.featureIcon} color="#F499DA" />
                            <Text>Zen Sounds</Text>
                          </li>
                        </ul>
                      </Pane>
                    </Pane>
                  </Pane>
                </Col>
                <Col>
                  <Pane
                    className={clsx(
                      styles.plan,
                      styles.planTeam,
                      isSelectTeam(selectedPlan) && styles.planSelected
                    )}
                    onClick={() => handleClickPlan(UpgradeTier.Team)}
                  >
                    <TickCircleIcon className={styles.tick} />
                    <Heading size={600} className="mt-2">TEAM</Heading>
                    <Paragraph className={styles.planPriceHighlight}>${eachMonthTeam(planTeam)}</Paragraph>
                    <Paragraph fontSize={12}>per user, per month</Paragraph>
                    <Pane className={styles.feature}>
                      <ul>
                        <li>
                          <ArrowLeftIcon className={styles.featureIcon} />
                          <Text><strong>Everything in PRO</strong></Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Shared team documents</Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Shared team folders</Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Team management</Text>
                        </li>
                        <li>
                          <SmallTickIcon className={styles.featureIcon} />
                          <Text>Unlimited editors</Text>
                        </li>
                      </ul>
                    </Pane>
                    <Pane className={styles.planSeat}>
                      <Text className={styles.planSeatLabel}>Members</Text>
                      <Select width={64} onChange={handleChangeMember}>
                        {(createSelectOption()).map(item => (
                          <option
                            value={item}
                            selected={item === 1}
                            key={item}
                          >
                            {item}
                          </option>
                        ))}
                      </Select>
                    </Pane>
                  </Pane>
                </Col>
              </Row>
            </Pane>
          </Col>
          <Col md={4}>
            <Pane className={styles.upgradeCard}>
              <Pane
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <Pane
                  alignSelf="flex-end"
                  cursor="pointer"
                >
                  <Button
                    className={styles.close}
                    onClick={() => setIsShown(false)}
                  >
                    <CrossIcon />
                  </Button>
                </Pane>
                <Pane>
                  <img src={logo} width="40" alt="logo" />
                </Pane>
              </Pane>
              <Heading className="mt-3" size={600}>Upgrade Zen Flowchart</Heading>
              <Text>to enjoy exclusive features</Text>
              <Pane className={styles.summaryContainer}>
                <Heading className={styles.summaryTitle}>Summary</Heading>
                <ul className={styles.summaryList}>
                  <li className={styles.summaryListItem}>
                    <Text>Zen Flowchart {getBadge(selectedPlan)}</Text>
                    <Text className={styles.summaryPrice}>${getPrice(selectedPlan)}</Text>
                  </li>
                  <li className={clsx(styles.summaryListItem, styles.summaryTotal)}>
                    <Text>Billed {isMonthly(plan) ? 'monthly' : 'yearly'}</Text>
                    {isSelectTeam(selectedPlan) && <Text>${planTeam} each</Text>}
                  </li>
                </ul>
              </Pane>
              <form className={styles.form} onSubmit={handleSubmit}>
                <CardNumber />

                <Pane display="flex" marginTop="8px">
                  <CardExpiry />
                  <CardCvc />
                </Pane>
                <div className="d-flex mt-3">
                  <Button
                    type="submit"
                    appearance="primary"
                    intent="success"
                    className="w-100"
                    height={majorScale(5)}
                    isLoading={isSubmitting}
                  >
                    Upgrade Now - ${getPrice(selectedPlan)}
                  </Button>
                </div>
                <Paragraph display="flex" alignItems="center" justifyContent="center" className="mt-3">
                  <LockIcon className={styles.secureIcon} /> Payments are secured and encrypted.
                </Paragraph>
              </form>
            </Pane>
          </Col>
        </Row>
      </Dialog>
      <UpgradeSuccessModal isShown={showSuccess} setIsShown={setShowSuccess} />
    </>
  );
}

export default UpgradeToProModal;
