import {majorScale, Pane, Text, TrashIcon} from "evergreen-ui";
import {useTrashTasks} from "../../services/task/tasks.helper";
import styles from "./TasksView.module.scss";
import _ from "lodash";
import React, {useState} from "react";
import clsx from "clsx";
import TaskItem from "./TaskItem";
import {TaskService} from "../../services/task/task.service";
import EmptyTrashTaskModal from "./Modal/EmptyTrashTaskModal";

const taskService = new TaskService();

const TrashTasks = () => {
  const {deletedTasks} = useTrashTasks();
  const [itemSelected, setItemSelected] = useState(null);
  const [showEmptyTasks, setShowEmptyTasks] = useState(false);

  const onConfirmEmptyTrash = () => {
    (deletedTasks || []).forEach(task => taskService.delete(task.id));
  }

  return (
    <Pane paddingX={majorScale(17)} paddingY={majorScale(6)}>
      <Pane className={styles.title} paddingY={majorScale(1)}>Trash</Pane>
      <Pane paddingY={majorScale(2)}>
        <Pane className={clsx(styles.trashButton)} display={"flex"} onClick={() => setShowEmptyTasks(true)}>
          <TrashIcon color="white" size={12} alignSelf={"center"}/>
          <Text color="white" fontSize={12} paddingLeft={majorScale(1)}>Empty trash</Text>
        </Pane>
      </Pane>
      <Pane paddingTop={majorScale(3)}>
        {
          deletedTasks?.map((task: any, index) => (
            <TaskItem
              key={task.id}
              {...task}
              onSelect={setItemSelected}
              isSelected={_.get(task, "id") === itemSelected}
              isViewMode={true}
            />
          ))
        }
      </Pane>
      <EmptyTrashTaskModal
        isShown={showEmptyTasks}
        setIsShown={setShowEmptyTasks}
        onConfirm={onConfirmEmptyTrash}
      />
    </Pane>
  )
}

export default TrashTasks;
