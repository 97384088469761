import jwt_decode from "jwt-decode";

import { UserSchema } from '../schemas/user';
import { getCollaborationToken, getUser, setCollaborationToken, setUser } from '../utils/store';
import { USER_COLLECTION } from '../utils/constants';
import { auth, db, provider } from '../utils/firebase';
import firebase from 'firebase';
import { UserService } from './user';
import { AuthProvider } from '../schemas/authProvider';
import { functions } from 'utils/firebase';
import { ConvergenceToken } from "schemas";

type CollectionReference = firebase.firestore.CollectionReference;

/**
 * Auth Service
 */
export class AuthService {
  /**
   * Collection Reference
   * @private
   */
  private readonly db: CollectionReference;

  /**
   * Firebase functions
   * @private
   */
   private readonly functions: firebase.functions.Functions;


  /**
   * User schema
   * @private
   */
  private readonly user: UserSchema;

  /**
   * User service constructor
   * @private
   */
  private readonly userService: UserService;

  /**
   * Firebase auth
   * @private
   */
  private auth: firebase.auth.Auth;

  /**
   * Constructor
   */
  constructor() {
    this.db = db.collection(USER_COLLECTION);
    this.auth = auth;
    this.user = getUser();
    this.userService = new UserService();
    this.functions = functions;
  }

  /**
   * Check user is authentication or not
   */
  static isAuthenticated() {
    return getUser() !== null;
  }

  /**
   * Sign In With Email
   */
  async signInWithEmail(email: string, password: string) {
    const { user } = await auth.signInWithEmailAndPassword(email, password);

    // Not find user then exist
    if (!user) {
      throw new Error('Incorrect user information');
    }

    // Update user into local storage
    const { uid } = user;
    await this.updateUserStore(uid, AuthProvider.Email);
  }

  /**
   * Sign Up With Email
   */
  async signUpWithEmail(email: string, password: string) {
    await auth.createUserWithEmailAndPassword(email, password);
    const { user } = await auth.signInWithEmailAndPassword(email, password);

    // Not find user then exist
    if (!user) {
      throw new Error('Please contact to help@zenwireframe.com to report this error');
    }

    // Update user into local storage
    const { uid } = user;
    await this.updateUserStore(uid, AuthProvider.Email);
  }

  /**
   * Sign in with google
   */
  async signInWithGoogle () {
    const { user } = await auth.signInWithPopup(provider);
    console.log(user);

    // Not find user then exist
    if (!user) {
      throw new Error('Incorrect user information');
    }

    // Update user into local storage
    const { uid, photoURL } = user;
    await this.updateUserStore(uid, AuthProvider.Google, { photoUrl: (photoURL || '') });
    if(photoURL){
      this.userService.update(uid, {googleAvatar: photoURL})
        .catch(ex => console.log("update user throw ex", ex.message));
    }
  }

  /**
   * Sign In with token
   * @param token
   */
  async signInWithToken(token: string) {
    const { user } = await auth.signInWithCustomToken(token);

    // Not find user then exist
    if (!user) {
      throw new Error('Incorrect user information');
    }

    // Update user into local storage
    const { uid, photoURL } = user;
    await this.updateUserStore(uid, AuthProvider.Google, { photoUrl: (photoURL || '')});
    if(photoURL){
      this.userService.update(uid, {googleAvatar: photoURL})
        .catch(ex => console.log("update user throw ex", ex.message));
    }
  }

  /**
   * Update user into store
   *
   * @param uid
   * @param provider
   * @param userData
   */
  async updateUserStore(uid: string, provider: AuthProvider, userData = {} as UserSchema) {
    const userSchema: UserSchema = { uid, provider };

    await this.userService.getProfile().then(resp => {
      const data = resp.data || {};
      userSchema.email = data.email;
      userSchema.firstName = data.firstName;
      userSchema.lastName = data.lastName;
      userSchema.subscriptionPeriodUntil = data.subscriptionPeriodUntil;
      userSchema.isAdmin = data.isAdmin;
      userSchema.isAdminTeam = data.isAdminTeam;
      userSchema.isPROTeam = data.isPROTeam;
      userSchema.tier = data.tier;
      if (data.photoUrl || userData.photoUrl) userSchema.photoUrl = data.photoUrl || userData.photoUrl;
      setUser(userSchema);
    });
  }

  /**
   * Get convergence token
   *
   * @returns string
   */
  async getConvergenceToken() {
    const currentToken = getCollaborationToken();
    let token = currentToken;
    let callRefresh = false;
    if (token) {
      try {
        const { exp } = jwt_decode<ConvergenceToken>(token);
        if (Date.now() >= exp * 1000) {
          callRefresh = true;
        }
      } catch {

      }
    }
    if (!currentToken || callRefresh) {
      const callable = this.functions.httpsCallable('getConvergenceToken');
      const response = await callable();
      setCollaborationToken(response.data.token);
      return response.data.token;
    }
    return token;
  }
}
