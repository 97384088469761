import React, { useState } from "react";
import { Card, Image, majorScale, Pane, Text, IconButton, PlayIcon, StopIcon } from "evergreen-ui";
import Crown from '../../assets/img/sounds/crown.svg'
import ProFeatureModal from "../../components/Modals/ProFeatureModal/ProFeatureModal";
import { UpgradeToProModal } from 'components/Modals';
import { useHistory } from "react-router-dom";
// utils
import { SIGN_UP_URL } from "../../utils/constants";
import { auth } from "../../utils/firebase";
import { deleteUser } from "../../utils/store";
import useDatabaseSnapshot from "../../hooks/useDatabaseSnapshot";

import styles from "./Sounds.module.scss";
//Recoil
import { useRecoilState } from 'recoil';
import { actionSound } from '../../recoil/atom';

type Props = {
  cardInfo: any,
  checkUser: boolean,
  isPuclic: boolean
}

const TemplateCard = ({ cardInfo, checkUser, isPuclic }: Props) => {
  const history = useHistory();
  const { unsubscribeDatabaseSnapshot } = useDatabaseSnapshot();

  const [modalUpgrade, setModalUpgrade] = useState(false);

  const [isShowProFeature, setShowProFeature] = useState(false);
  const [soundActive, setSoundActive] = useRecoilState(actionSound)

  const onClickCard = () => {
    if (checkUser && cardInfo.pro) {

      setShowProFeature(true)
      setSoundActive({
        ...soundActive,
        playing: false
      })
    } else {
      if (isPuclic && cardInfo.pro) {
        setShowProFeature(true)
        setSoundActive({
          ...soundActive,
          playing: false
        })
      } else {
        if (cardInfo.id === soundActive.id) {
          setSoundActive({
            ...soundActive,
            playing: !soundActive.playing
          })
        } else {
          setSoundActive({
            ...cardInfo,
            volume: soundActive.volume,
            playing: true
          })
        }
      }
    }
  }

  return (
    <Card background={"white"} className={[styles.card, cardInfo.id === soundActive.id && soundActive.playing ? styles.cardActive : ''].join(" ")} onClick={onClickCard} >
      <Image src={cardInfo.image} className={styles.image} />
      <Pane padding={majorScale(1)} paddingTop={"6px"}>
        <IconButton height={20} icon={cardInfo.id === soundActive.id && soundActive.playing ? StopIcon : PlayIcon} marginRight={majorScale(1)} className={styles.iconButton} />
        <Text className={styles.text}>{cardInfo.name}</Text>
        {
          isPuclic && cardInfo.pro
            ?
            <Image src={Crown} float={'right'} paddingTop={"3px"} />
            :
            <>
              {
                checkUser && cardInfo.pro && <Image src={Crown} float={'right'} paddingTop={"3px"} />
              }
            </>
        }
      </Pane>
      <UpgradeToProModal isShown={modalUpgrade} setIsShown={setModalUpgrade} />
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Premium Sound"}
        isPublic={isPuclic}
        onConfirm={async () => {
          if (!isPuclic) {
            setShowProFeature(false);
            setModalUpgrade(true)
          } else {
            if (unsubscribeDatabaseSnapshot) {
              unsubscribeDatabaseSnapshot();
            }
            await auth.signOut();
            deleteUser();
            history.push(SIGN_UP_URL);
          }
        }}
        message={(
          <Pane>
            This sound is not available to Free users. Upgrade to a
            <br />paid plan for unlimited access and more!
          </Pane>)}
      />
    </Card>
  )
}

export default TemplateCard;
