import firebase from "firebase";
import {PAGES_COLLECTION} from "../../utils/constants";
import {db} from 'utils/firebase';
import {PageInput} from "../../schemas/page";
import {getUser} from "../../utils/store";
import _ from "lodash";
import {UserService} from "../user";
import {TeamService} from "../team/team.service";
import {ObjectiveService} from "../objective/objective.service";
import {samplePages} from "./sample";

export class PageService {
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private readonly userService: UserService;
  private readonly teamService: TeamService;
  private readonly objectiveService: ObjectiveService;

  constructor() {
    this.db = db.collection(PAGES_COLLECTION);
    this.userService = new UserService();
    this.teamService = new TeamService();
    this.objectiveService = new ObjectiveService();
  }

  async create({name, isSample, sampleIndex}: PageInput | any){
    const { uid, email, firstName, lastName } = getUser();
    const data = {
      name,
      isSample: !!isSample,
      sampleIndex: sampleIndex || 0,
      owner: uid,
      members: {
        [uid]: {
          id: uid,
          email,
          firstName,
          lastName,
          isOwner: true,
          addedAt: (new Date()).getTime()
        }
      },
      isDelete: false,
      ordinal: (new Date()).getTime(),
      createdAt: new Date()
    }

    return this.db.add(data);
  }

  async updatePartial(id: string, payload: PageInput){
    const validData = {};
    Object.keys(payload).forEach(key => {
      const value = _.get(payload, key);
      if(value === null) return;

      _.set(validData, key, value);
    })
    const { uid } = getUser();
    const data = {
      ...payload,
      updatedBy: uid,
    }

    await this.db.doc(id).update(data);
  }

  async deletePage(id: string){
    this.db.doc(id).delete().then(() => {
      this.objectiveService.deleteByPageId(id);
    });
  }

  async softDeletePage(id: string){
    this.db.doc(id).update({isDelete: true}).then(() => {
      this.objectiveService.deleteByPageId(id);
    });
  }

  async removeMembers(id: string, removeMemberIds: string[]){
    const pageDoc = await this.db.doc(id).get();
    const page = pageDoc.exists ? pageDoc.data() : {};

    const savedMembers = _.get(page, "members", {});
    const owner = _.get(page, "owner");

    (removeMemberIds || []).forEach(memberId => {
      const isOwner = owner === memberId;
      if(isOwner) return;

      const isExisting = Object.keys(savedMembers).includes(memberId);
      if(!isExisting) return;

      delete savedMembers[memberId];
    })

    this.db.doc(id).update({
      members: savedMembers
    }).catch(ex => {
      console.log("add member throw ex ", ex.message);
    })
  }

  async addMembers(id: string, addMembers: string[]){
    const loginUser = getUser() || {};
    let users = [] as any[];
    let page = {};
    const usersPromise = this.userService
      .getManyByEmails(addMembers)
      .then(resp => {
        users = resp;
      });
    const pagePromise = this.db.doc(id).get()
      .then(resp => {
        page = resp?.data() || [] as any;
      });

    await Promise.all([usersPromise, pagePromise]);

    if(!users || !page) return;

    const savedMembers = _.get(page, "members", {});
    const owner = _.get(page, "owner");

    (users || []).forEach(user => {
      const isOwner = user.id === owner;
      if(isOwner) return;
      savedMembers[user.id] = {
        ...user,
        updatedByEmail: loginUser.email || '',
        addedAt: (new Date()).getTime()
      }
    })

    this.db.doc(id).update({
      members: savedMembers
    }).catch(ex => {
      console.log("add member throw ex ", ex.message);
    })
  }

  async getPageList(ids: string[]){
    const validIds = (ids || []).filter(id => id);
    return this.db.where(firebase.firestore.FieldPath.documentId(), 'in', validIds).get();
  }

  async updateTeam(id: string, teamId: string, isAdd = false){
    let team = [] as any[];
    let page = {};
    const teamPromise = this.teamService.getTeamById(teamId)
      .then(resp => {
        team = resp?.data() || [] as any;
      });
    const pagePromise = this.db.doc(id).get()
      .then(resp => {
        page = resp?.data() || [] as any;
      });

    await Promise.all([teamPromise, pagePromise]);

    if(!team || !page) return;

    const savedTeams = _.get(page, "teams", {});
    if(isAdd){
      savedTeams[teamId] = {
        id: teamId
      }
    }
    else {
      const teamIds = Object.keys(savedTeams);
      const isExistingTeamId = teamIds.includes(teamId);
      if(isExistingTeamId){
        delete savedTeams[teamId];
      }
    }

    this.db.doc(id).update({
      teams: savedTeams
    }).catch(ex => {
      console.log("add member throw ex ", ex.message);
    })
  }

  updateOrdinalPages(pages: any[]) {
    (pages || []).forEach((page: any) => {
      this.db.doc(page.id).update({
        ordinal: page.ordinal
      })
        .catch(ex => {
          console.log("update ordinal throw ex ", ex.message)
        })
    });
  }

  getSamplePages() {
    const user = getUser();
    return this.db
      .where('owner', "==", user.uid)
      .where("isSample", "==", true)
      .get();
  }

  createObjectives(page: string, objectives: any[]) {
    let curTime = ((new Date()).getTime()) + 10000;
    (objectives || []).reverse().forEach(objective => {
      this.objectiveService.create({
        page,
        title: objective.title,
        results: objective.results,
        ordinal: curTime
      })
        .catch(ex => {
          console.log("objectiveService create ex = ", ex.message);
        });

      curTime = curTime + 10000;
    });
  }

  createSamplePages() {
    samplePages.forEach(page => {
      this.create({
        name: page.name,
        isSample: true,
        sampleIndex: page.sampleIndex
      } as any)
        .then(resp => {
          this.createObjectives(resp.id, page.objectives);
      });
    });
  }
}
