import {useTasks, VIEW_MODE} from "../../services/task/tasks.helper";
import {majorScale, Pane} from "evergreen-ui";
import TaskTodo from "./TaskTodo";
import _ from "lodash";
import React, {useState} from "react";
import {getProjects} from "../../utils/store";
import TaskComplete from "./TaskComplete";
import TaskCreateView from "./TaskCreateView";
import {useParams} from "react-router-dom";
import {useProjectInfo} from "../../services/project/projects.helper";

type Props = {
  viewMode: VIEW_MODE,
  createAble?: boolean
}

const TaskGroupByProject = ({viewMode, createAble}: Props) => {
  const {projectsTodoTasks, projectsCompleteTasks} = useTasks({projectId: undefined, viewMode});
  const projects = getProjects();
  const params = useParams() as any;
  const [projectId] = useState(params.id)
  const {assignMembers} = useProjectInfo(projectId || "");
  const [itemSelected, setItemSelected] = useState(false);
  // const isSomeday = viewMode === VIEW_MODE.SOMEDAY;

  const projectNameFormat = (taskId: string) => {
    const name = (_.get(projects, taskId, {name: ""})).name;
    return _.upperCase(name);
  }

  const onSelectItem = (id: any) => {
    setItemSelected(id);
  }

  return (
    <Pane>
      {createAble &&
        <TaskCreateView
          canManageProject={true}
          members={assignMembers}
          projectId={projectId}
          dateAble={true}
          viewMode={viewMode}
        />
      }
      {!createAble &&
        <Pane marginTop={majorScale(3)}></Pane>
      }
      {(Object.keys(projectsTodoTasks).includes("null") || Object.keys(projectsCompleteTasks).includes("null")) &&
        <Pane paddingBottom={majorScale(4)}>
          <TaskTodo
            tasks={_.get(projectsTodoTasks, "null", [])}
            assignees={{}}
            onSelect={onSelectItem}
            taskSelected={itemSelected}
            dateAble={true}
          />
          <TaskComplete
            tasks={_.get(projectsCompleteTasks, "null", [])}
            onSelect={onSelectItem}
            taskSelected={itemSelected}
            dateAble={true}
          />
        </Pane>
      }
      {
        Object.keys(projectsTodoTasks)
          .filter(taskId => taskId && taskId !== "null")
          .map((taskId, index) => (
          <Pane key={index} paddingBottom={majorScale(4)}>
            {projectNameFormat(taskId) &&
              <Pane fontWeight={600} fontSize={12} color="#101840" paddingBottom={majorScale(2)}>
                {projectNameFormat(taskId)}
              </Pane>
            }
            <TaskTodo
              tasks={_.get(projectsTodoTasks, taskId, [])}
              assignees={{}}
              onSelect={onSelectItem}
              taskSelected={itemSelected}
              dateAble={true}
            />
            <TaskComplete
              tasks={_.get(projectsCompleteTasks, taskId, [])}
              onSelect={onSelectItem}
              taskSelected={itemSelected}
              dateAble={true}
            />
          </Pane>
        ))
      }
    </Pane>
  )
}

export default TaskGroupByProject;
