import {useState} from 'react';
import {Alert, majorScale, Pane} from 'evergreen-ui';
import {Helmet} from 'react-helmet-async';

import CardAuth from 'components/CardAuth/CardAuth';
import CardLink from 'components/CardAuth/CardLink';
import {SignUpForm} from './SignUpForm';
import {Col, Row} from "react-grid-system";
import styles from "./SignUp.module.scss"
import clsx from "clsx";
import VersionPanel from "./VersionPanel";
import "./VersionPanel.scss"

const SignUp = () => {
  const [error, setError] = useState('');
  return (
    <>
      <Row>
        <Helmet>
          <meta name="description" content="Sign Up Free on Zen Flowchart. The Simplest Online Free Flowchart Maker. Get Started Free Now!" />
        </Helmet>
      </Row>

      <Row className={clsx(styles.bottomPanel)}>
        <Col lg={6} sm={12}/>
        <Col lg={6} md={12} className={clsx(styles.column, styles.columnCenterMd)}>
          {error && (
            <Pane padding={majorScale(1)} paddingBottom={majorScale(3)}>
              <Alert
                intent="danger"
                title={error}
                className="versionPanelAlert"
              />
            </Pane>
          )}
        </Col>
      </Row>
      <Row className={clsx(styles.mainPanel)}>
        <Col lg={6} md={12} className={clsx(styles.column, styles.versionPanelColumn)}>
          <VersionPanel/>
        </Col>
        <Col lg={6} md={12} className={clsx(styles.column, styles.columnCenterMd)}>
          <Pane>
            <CardAuth
              title="Let’s get started!"
              subtitle="Create your FREE account now"
              className="versionPanelRegisterCard"
            >
              <SignUpForm onError={(message: string) => setError(message)}/>
            </CardAuth>
          </Pane>
        </Col>
      </Row>
      <Row className={clsx(styles.bottomPanel)}>
        <Col lg={6} md={12}/>
        <Col lg={6} md={12} className={clsx(styles.column, styles.columnCenterMd)}>
          <Pane className={clsx(styles.fixWidthMd)}>
            <CardLink
              title="Already have an account?"
              links={[{
                text: 'Sign In',
                link: '/sign-in',
              }]}
            />
          </Pane>
        </Col>
      </Row>
    </>
  );
}

export default SignUp;
