// import { UserSchema } from "./user";

export enum Role {
  Viewer = 'viewer',
  Commenter = 'commenter',
  Owner = 'owner',
  Editor = 'editor',
  Remove = 'remove',
  None = 'none',
}

export enum PermissonType {
  EditComment = 'EditComment',
  AddComment = 'AddComment',
  DeleteComment = 'DeleteComment',
  ViewComment = 'ViewComment',
  DeleteDoc = 'DeleteDoc',
  RenameDoc = 'RenameDoc',
  CommentDoc = 'CommentDoc',
  EditDoc = 'EditDoc',
  ViewDoc = 'ViewDoc',
  InviteUser = 'InviteUser',
  ExportDoc = 'ExportDoc',
}

export enum NotePermissonType {
  EditNote = 'EditNote',
  ViewNote = 'ViewNote',
  InviteUser = 'InviteUser',
  DeleteNote = 'DeleteNote',
}

export enum FolderPermission {
  AddFolder = 'AddFolder',
  EditFolder = 'EditFolder',
  DeleteFolder = 'DeleteFolder',
  ViewFolder = 'ViewFolder',
}

export interface RoleSchema {
  type: 'viewer' | 'commenter' | 'owner' | 'editor' | 'none';
  updatedBy: any;
  createdAt: any;
  updatedAt: any;

  roles?: any;
}

export interface NoteRoleSchema {
  type: 'viewer' | 'owner' | 'editor';
  updatedBy: any;
  createdAt: any;
  updatedAt: any;

  roles?: any;
}
