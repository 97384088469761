import { Dispatch, SyntheticEvent, useState, SetStateAction } from 'react';
import { Textarea, toaster, Text } from 'evergreen-ui';
import { Modal } from '../Modal/Modal';
import { EmailService } from 'services/email';

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>
}

const emailService = new EmailService();

const SendFeedbackModal = ({ isShown, setIsShown }: Props) => {
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await emailService.sendFeedback(message);
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsSubmitting(false);
      setIsShown(false);
      setIsSent(true);
    }
  }

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLTextAreaElement;
    const value = target.value;
    setMessage(value);
  }

  return (
    <>
      <Modal
        width={592}
        isShown={isShown}
        title="Send Feedback"
        onCloseComplete={() => setIsShown(false)}
        onConfirm={handleConfirm}
        confirmLabel="Submit"
        isConfirmLoading={isSubmitting}
      >
        <Text>
          Thank you for using Zen Flowchart! <br/>
          Please send us your questions or feedback using this form. We appreciate your feedback, and will get back to you within 1 working day.
          <br />
        </Text>
        <br/>
        <Textarea name="message" placeholder="Your feedback" onChange={handleChange} />
      </Modal>
      <Modal
        width={433}
        isShown={isSent}
        title="Feedback Sent!"
        hasCancel={false}
        onCloseComplete={() => setIsSent(false)}
        confirmLabel="OK"
      >
        <Text>
          Thank you for your feedback. We’ll try to respond as <br />
          soon as we can.
        </Text>
      </Modal>
    </>
  );
}

export default SendFeedbackModal;
