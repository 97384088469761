import React, { useState } from 'react';
import {
  Pane,
  Button,
  Popover,
  Position,
  TagIcon
}
  from "evergreen-ui";
import View from "./View"
import Add from "./Add"
import Edit from "./Edit"

import styles from "./Label.module.scss";

export default function Label() {
  const [isView, setIsView] = useState("view")

  const onHandleChangeView = (params: string) => {
    setIsView(params)
  }

  return (
    <Popover
      content={({ close }) => (
        <Pane className={styles.formLabel}>
          {
            isView === "view" && <View onClose={close} setIsView={onHandleChangeView} />
          }
          {
            isView === "add" && <Add onClose={close} setIsView={onHandleChangeView} />
          }
          {
            isView === "edit" && <Edit onClose={close} setIsView={onHandleChangeView} />
          }
        </Pane>
      )}
      onClose={() => {
        setIsView('view')
      }}
      shouldCloseOnExternalClick={false}
      position={Position.BOTTOM_LEFT}
    >
      <Button marginY={4} iconBefore={TagIcon}>
        Labels
      </Button>
    </Popover>
  )
}
