import {useCollection} from "@nandorojo/swr-firestore";
import {PAGES_COLLECTION} from "../../utils/constants";
import {getTeams, getUser} from "../../utils/store";
import {useEffect, useState} from "react";
import {db} from "../../utils/firebase";
import {TeamService} from "../team/team.service";
import _ from "lodash";
import {PageService} from "./page.service";

export const useOwnerPages = () => {
  const user = getUser();
  const [pagesSorted, setPagesSorted] = useState([] as any[]);
  const [pageCount, setPageCount] = useState(-1);

  const { data: pages } = useCollection<any>(PAGES_COLLECTION, {
    where: [
      ['owner', '==', user.uid],
      ['isDelete', '==', false],
    ],
    listen: true,
  });

  useEffect(() => {
    const sorted = pages?.filter(page => !page.isDeleted)
      .sort((first, second) => {
      return first.ordinal > second.ordinal ? 1 : -1;
    }) || [];

    setPageCount(pages ? pages.length : -1);

    setPagesSorted(sorted);
  }, [pages])

  return {
    pages: pagesSorted,
    pageCount
  }
}

export const useSharePages = () => {
  const user = getUser();
  const [pages, setPages] = useState([] as any[]);
  const teamIds = (getTeams() || []).map((team: any) => team.id);
  const firstTeamId = _.get(teamIds, 0, "null");

  const { data: rawPages } = useCollection<any>(PAGES_COLLECTION, {
    where: [
      [`members.${user.uid}.id`, 'in', [user.uid]],
      ['isDelete', '==', false],
    ],
    listen: true,
  });

  const { data: sharePages } = useCollection<any>(PAGES_COLLECTION, {
    where: [
      [`teams.${firstTeamId}.id`, 'in', [firstTeamId]],
      ['isDelete', '==', false],
    ],
    listen: true,
  });

  useEffect(() => {
    let allPages = rawPages || [];
    (sharePages || []).forEach(page => {
      const isDuplicate = allPages.findIndex(p => p.id === page.id) >= 0;
      if(isDuplicate) return;

      allPages = [...allPages, page];
    });

    allPages = allPages.filter(page => page.owner !== user.uid);

    setPages(allPages);
  }, [rawPages, sharePages])

  return {
    pages
  }
}

export const usePageStatic = (id: string) => {
  const [page, setPage] = useState({} as any);

  useEffect(() => {
    if(id){
      db.collection(PAGES_COLLECTION).doc(id).get().then(resp => {
        setPage({id: resp.id ,...resp.data()})
      })
    }
  }, [id]);

  return {
    page
  }
}

export const useSavePageToStorage = (ownerPages: any[], sharePages: any[]) => {

  useEffect(() => {
    // setOwnerPages(ownerPages);
    // setSharePages(sharePages);

    const allPages = [...(ownerPages || []), ...(sharePages || [])];
    const pagesObj = {} as any;
    allPages.forEach(page => {
      pagesObj[page.id] = page;
    })
    // setPages(pagesObj);

  }, [ownerPages, sharePages]);

  return {};
}

export const usePageInfo = (id: string) => {
  const [page, setPage] = useState({} as any);
  const [members, setMembers] = useState({});
  const [teams, setTeams] = useState({});
  const [assignMembers, setAssignMembers] = useState({});
  const teamService = new TeamService();

  useEffect(() => {
    if(id){
      db.collection(PAGES_COLLECTION).doc(id).onSnapshot(resp => {
        setPage({id: resp.id ,...resp.data()});
        const membersProp = _.get(resp.data(), "members", {});
        setMembers(membersProp);
        setTeams((resp.data() || {teams: {}}).teams || {});

        setAssignMembers(membersProp);
      })
    }
  }, [id]);

  useEffect(() => {
    const teamIds = Object.keys(teams);
    if(teamIds.length <= 0) return;

    teamService.getTeams(teamIds)
      .then(resp => {
        let allAssignees = {};
          resp.docs
          .map(doc => (doc.data() || {members: {}}).members)
            .forEach(members => {
              allAssignees = {...allAssignees, ...members}
            });
        allAssignees = {...members, ...allAssignees};

        setAssignMembers(allAssignees);
      })
  }, [teams]);

  return {
    page,
    members,
    teams,
    assignMembers
  }
}

export const usePages = (ids: string[]) => {
  const [pages, setPages] = useState({} as any[]);
  const pageService = new PageService();

  useEffect(() => {
    const validIds = (ids || []).filter(id => id && id !== "null");
    if(_.isEmpty(validIds)) return;

    pageService.getPageList(validIds).then(resp => {
      const dataPages = {} as any;
      resp.docs?.forEach(page => {
        dataPages[page.id] = {
          id: page.id,
          ...page.data()
        }
      });

      setPages(dataPages);
    });
  }, [ids]);

  return {
    pages
  }
}
