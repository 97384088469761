import {
  AnnotationIcon,
  ArchiveIcon,
  Button,
  majorScale,
  Menu,
  minorScale,
  MoreIcon,
  Pane,
  PlusIcon,
  Popover,
  Position,
  Text,
  TrashIcon
} from "evergreen-ui";
import styles from "./Page.module.scss"
import clsx from "clsx";
import React, {useRef, useState} from "react";
import AutosizeInput from "react-input-autosize";
import {ObjectiveService} from "../../services/objective/objective.service";
import KeyResult from "./KeyResult";
import DeleteObjectiveModal from "./Modal/DeleteObjectiveModal";
import {useDrag, useDrop} from "react-dnd";
import _ from "lodash";
import Expand from "react-expand-animated";

type Props = {
  id: string,
  title: string,
  results: any[],
  page: any,
  focusObjective: string,
  resultSelected: number,
  archived: boolean,
  setResultSelected: Function,
  setFocusObjective: Function,
  onDropObjective: Function,
}

const objectiveService = new ObjectiveService();

const Objective = (objective: Props) => {
  const {id, title, results, page, focusObjective, setFocusObjective, resultSelected, setResultSelected, archived} = objective;
  const [time] = useState(Date.now());
  const [newObjectiveTitle, setNewObjectiveTitle] = useState(title);
  const [showRename, setShowRename] = useState(false);
  const [showDeleteObjective, setShowDeleteObjective] = useState(false);
  const submitResults = useRef(results);
  const [focusKeyResult, setFocusKeyResult] = useState(0);

  const onChangeTitle = () => {
    setFocusObjective("");
    setShowRename(false);
    objectiveService.partialUpdate({id, title: newObjectiveTitle});
  }

  const onInputKeyUp = (e: any) => {
    if(e.code === "Enter"){
      onChangeTitle();
    }
  }

  const onArchiveObject = () => {
    objectiveService.partialUpdate({id, archived: !archived});
  }

  const addResult = () => {
    const resultId = (new Date()).getTime();
    setFocusKeyResult(resultId);
    const newResult = {id: resultId}

    const updateResults = [newResult, ...(results || [])];
    objectiveService.partialUpdate({id, results: updateResults});
  }

  const setResultInfo = (submitResult: any) => {
    const result = (results || []).find(result => result.id === submitResult.id);
    if(!result) return;

    Object.keys(submitResult).forEach(key => {
      result[key] = submitResult[key];
    })

    submitResults.current = results;
  }

  const saveResult = () => {
    objectiveService.partialUpdate({id, results: submitResults.current});
  }

  const onDropResult = (drag: any, drop: any) => {
    results.splice(results.findIndex((result: any) => result.id === drag.id), 1);
    results.splice(drop ? results.findIndex((result: any) => result.id === drop.id) + 1 : 0, 0, drag);
    objectiveService.partialUpdate({id, results});
  }

  const [{ isDropResultOver }, dropResultRef] = useDrop(() => ({
    accept: ["RESULT"],
    collect: (monitor) => ({
      isDropResultOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "RESULT"
        && _.get(monitor.getItem(), "result.objective") === id
    }),
    canDrop: (item: any, monitor) => {
      return item.result.objective === id;
    },
    drop: async (item: any, monitor)  => {
      onDropResult(item.result, null);
    }
  }), []);

  const [{ isObjectiveDragging }, dragObjectiveRef] = useDrag(() => ({
    type: 'OBJECTIVE',
    item: {
      type: 'OBJECTIVE',
      objective: objective
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },
    canDrag: (monitor) => {
      return !archived;
    },
    collect: (monitor) => ({
      isObjectiveDragging: monitor.isDragging(),
    })
  }), [objective]);

  const [{ isDropObjectiveOver }, dropObjectiveRef] = useDrop(() => ({
    accept: ["OBJECTIVE"],
    collect: (monitor) => ({
      isDropObjectiveOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "OBJECTIVE"
    }),
    canDrop: (item: any, monitor) => {
      return !item.objective.archived;
    },
    drop: async (item: any, monitor)  => {
      objective.onDropObjective(item.objective, objective);
    }
  }), [objective]);

  const onObjectRef = (ref: any) => {
    dragObjectiveRef(ref);
    dropObjectiveRef(ref);
  }

  const onDeleteResult = (result: any) => {
    const remainingResults = results.filter(r => r.id !== result.id);
    objectiveService.partialUpdate({id, results: remainingResults});
  }

  return (
    <>
      <Pane
        className={clsx(styles.objectivePanel)}
        ref={onObjectRef}
        display={isObjectiveDragging ? "none" : "block"}
        marginBottom={majorScale(3)}
      >
        <Pane display={"flex"} paddingBottom={minorScale(3)} ref={dropResultRef}>
          <Pane display={"flex"} alignItems={"center"} height={24}>
            {!(id === focusObjective || showRename) &&
              <Text className={clsx(styles.objectiveTitle)}>{title}</Text>
            }
            {(id === focusObjective || showRename) &&
              <AutosizeInput
                key={time}
                name="form-field-name"
                value={newObjectiveTitle}
                className={clsx(styles.inputObjectiveTitle)}
                onChange={e => setNewObjectiveTitle(e.target.value)}
                inputStyle={{ fontSize: 16, fontWeight: 600, }}
                onBlur={() => onChangeTitle()}
                autoFocus={true}
                onKeyUp={(e) => onInputKeyUp(e)}
              />
            }
          </Pane>
          <Pane display={"flex"} alignItems={"center"}>
            <Popover
              position={Position.BOTTOM_LEFT}
              shouldCloseOnExternalClick={true}
              content={
                ({ close }) => (
                  <Pane onClick={close}>
                    <Menu>
                      <Menu.Group>
                        <Menu.Item icon={(<AnnotationIcon color="#8F95B2"/>)} onClick={() => setShowRename(true)}>
                          <Text color="#474D66">Rename Objective</Text>
                        </Menu.Item>
                        <Menu.Item icon={(<ArchiveIcon color="#8F95B2"/>)} onClick={() => onArchiveObject()}>
                          <Text color="#474D66">{objective.archived ? "Unarchive" : "Archive"} Objective</Text>
                        </Menu.Item>
                        <Menu.Item
                          icon={(<TrashIcon color="#D14343"/>)}
                          onClick={() => setShowDeleteObjective(true)}
                        >
                          Delete Objective
                        </Menu.Item>
                      </Menu.Group>
                    </Menu>
                  </Pane>
                )
              }
            >
              <Pane
                className={styles.moreObjectiveButton}
                display={"flex"}
                justifyItems={"center"}
                justifyContent={"center"}
              >
                <MoreIcon color="#8F95B2" size={12} alignSelf={"center"} justifySelf={"center"}/>
              </Pane>
            </Popover>
          </Pane>
        </Pane>
        <Expand
          duration={200}
          open={isDropResultOver}
          transitions={["height", "opacity"]}
        >
          <Pane className={clsx(isDropResultOver && styles.dropResultOver)}/>
        </Expand>
        {(results || []).map((result, index) => (
          <KeyResult
            key={result.id}
            result={result}
            members={page?.members}
            isSelected={resultSelected === result.id}
            onSelect={setResultSelected}
            isAutoFocus={focusKeyResult === result.id}
            objective={{id}}
            setResultInfo={setResultInfo}
            saveResult={saveResult}
            onDropResult={onDropResult}
            setFocusResult={setFocusKeyResult}
            onDeleteResult={onDeleteResult}
          />
        ))}
        <Pane marginTop={majorScale(2)}>
          <Button
            iconBefore={<PlusIcon color="#8F95B2"/>}
            className={clsx(styles.headlineBtn)}
            color="#696F8C"
            onClick={() => addResult()}
          >
            Key Result
          </Button>
        </Pane>

        <DeleteObjectiveModal
          objectiveId={id}
          isShown={showDeleteObjective}
          setIsShown={setShowDeleteObjective}
        />
      </Pane>
      <Expand
        duration={200}
        open={isDropObjectiveOver}
        transitions={["height", "opacity"]}
      >
        <Pane className={clsx(isDropObjectiveOver && styles.dropObjectiveOver)}/>
      </Expand>
    </>
  )
}

export default Objective;
