import {Pane, Switch, Text} from 'evergreen-ui';
import styles from './KeyboardPanel.module.scss';
import { Box } from '../../Box/Box';
import _ from "lodash";
import { ZwfConstants } from '../../../../utils/ZwfConstants';
import {Control} from "../../Control/Control";
import { activeKeyboard, changeKeyboardType } from "../../../../utils/Actions";

const KeyboardPanel = ({editorUi, graphState, dispatchGraphState, accessorPanel}) => {

  const onKeyboardActive = (check) => {
    const zwfKeyboard = check ?
      ZwfConstants.KEYBOARD_STATE_VISIBLE : ZwfConstants.KEYBOARD_STATE_HIDDEN;
    dispatchGraphState({zwfKeyboardState: zwfKeyboard});
    activeKeyboard(editorUi, zwfKeyboard);
  }

  const onKeyboardType = (type) => {
    dispatchGraphState({zwfKeyboardType: type});
    changeKeyboardType(editorUi, type);
  }

  const isDisableBySpecialComponent = () => {
    const isFrameMobile = _.get(graphState, ZwfConstants.STYLE_TYPE) === "mobileFrame";
    const isLandscape = _.get(graphState, ZwfConstants.STYLE_FRAME_ORIENTATION) === ZwfConstants.FRAME_ORIENTATION_LANDSCAPE;

    return isFrameMobile && isLandscape;
  }

  return (
    <Box title="Keyboard" isDivider>
      {accessorPanel.isActionVisible(ZwfConstants.ACTION_KEYBOARD_STATE) &&
        <Pane className="mt-3 d-flex align-items-center">
          <Text>Add Keyboard</Text>
          <Pane className={styles.fontSize}>
            <Switch
              checked={_.get(graphState, ZwfConstants.STYLE_KEYBOARD_STATE) === ZwfConstants.KEYBOARD_STATE_VISIBLE}
              onChange={(e) => onKeyboardActive(e.target.checked)}
            />
          </Pane>
        </Pane>
      }
      {accessorPanel.isActionVisible(ZwfConstants.ACTION_KEYBOARD_TYPE) &&
      _.get(graphState, ZwfConstants.STYLE_KEYBOARD_STATE) === ZwfConstants.KEYBOARD_STATE_VISIBLE &&
      !isDisableBySpecialComponent() &&
        <Pane className="mt-3">
          <Control
            value={_.get(graphState, ZwfConstants.STYLE_KEYBOARD_TYPE)}
            options={[
              { label: 'QWERTY', value: ZwfConstants.KEYBOARD_TYPE_QWERTY },
              { label: 'Numpad', value: ZwfConstants.KEYBOARD_TYPE_NUMPAD },
            ]}
            onChange={val => onKeyboardType(val)}
          />
        </Pane>
      }
    </Box>
  )
}

export default KeyboardPanel;
