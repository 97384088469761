const ActionStore = (state,action) => {
  const { updateProperty } = action;
  return {
    ...state,
    ...updateProperty
  };
}

export default ActionStore

