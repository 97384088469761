import clsx from 'clsx';
import {
  Pane,
  Link,
  Position,
  IconButton,
  MoreIcon,
  Popover,
  Menu,
  BookIcon,
  AnnotationIcon,
  TrashIcon,
  ShareIcon
} from 'evergreen-ui';
import { useState } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { NotebookSchema } from 'schemas/notebook';

import { useNotebookCollection } from 'services/note';
import { NOTEBOOK_URL } from 'utils/constants';
import DeleteNotebookModal from '../Modal/DeleteNotebookModal';
import RenameNotebookModal from '../Modal/RenameNotebookModal';
import ShareNotebookModal from '../Modal/ShareNotebookModal';
import styles from './NotebookSidebar.module.scss';

export default function NotebookSidebar() {
  const [showRename, setShowRename] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { notebooks } = useNotebookCollection();
  const location = useLocation();
  const [editNotebook, setEditNotebook] = useState({} as NotebookSchema);

  const isActivePath = (path: string) => {
    return location.pathname.includes(path);
  };

  const isActiveRoute = (path: string, queryPath?: string) => {
    if (
      isActivePath(path) ||
      (queryPath && location.search.includes(queryPath))
    ) {
      return {
        isActive: true,
      };
    }
    return {
      isActive: false,
    };
  };

  const onSelectRename = (notebook: NotebookSchema) => {
    setEditNotebook(notebook);
    setShowRename(true);
  }

  const onSelectShare = (notebook: NotebookSchema) => {
    setEditNotebook(notebook);
    setShowShare(true);
  }
  
  const onSelectDelete = async (notebook: NotebookSchema) => {
    setEditNotebook(notebook);
    setShowDelete(true);    
  }

  return (
    <>
      <Pane className={styles.notebookSidebar}>
        {notebooks.map(notebook => (
          <Pane key={notebook.id} className={clsx(styles.item, isActiveRoute(`${NOTEBOOK_URL}/${notebook.id}`))}>
            <Link is={ReactRouterLink} to={`${NOTEBOOK_URL}/${notebook.id}`}>
              <BookIcon color="#52BD94" />
              <span className={styles.sidebarLabel}>{notebook.name}</span>
            </Link>

            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item icon={(<AnnotationIcon color="#8F95B2"/>)} onSelect={() => onSelectRename(notebook)}>
                      Rename Notebook
                    </Menu.Item>
                    <Menu.Item icon={(<ShareIcon color="#3366FF"/>)} onSelect={() => onSelectShare(notebook)}>
                      Share Notebook
                    </Menu.Item>
                    <Menu.Item icon={(<TrashIcon color="#D14343"/>)} onSelect={() => onSelectDelete(notebook)}>
                      Delete Notebook
                    </Menu.Item>
                  </Menu.Group>
                </Menu>
              }
            >
              <IconButton
                  className={styles.optionIcon}
                  icon={<MoreIcon size={9} />}
                  onClick={() => setShowRename(true)}
                />
            </Popover>
          </Pane>
        ))}
      </Pane>

      <RenameNotebookModal notebook={editNotebook} isShown={showRename} setIsShown={setShowRename} />
      <ShareNotebookModal notebook={editNotebook} isShown={showShare} setIsShown={setShowShare} />
      <DeleteNotebookModal notebook={editNotebook} isShown={showDelete} setIsShown={setShowDelete} />
    </>
  );
}
