import React, {useEffect, useState} from "react";
import {useTasks, VIEW_MODE} from "../../services/task/tasks.helper";
import {useProjectInfo} from "../../services/project/projects.helper";
import {useLocation} from "react-router-dom";
import TaskTodo from "./TaskTodo";
import TaskComplete from "./TaskComplete";
import TaskCreateView from "./TaskCreateView";
import "./Animated.css"

type Props = {
  projectId: string,
  canManageProject: boolean,
  setAssignees?: Function
}

const TaskList = ({projectId, canManageProject, setAssignees}: Props) => {
  const [viewMode, setViewMode] = useState(projectId ? VIEW_MODE.PROJECT : VIEW_MODE.INBOX);
  const {todoTasks, completeTasks} = useTasks({projectId, viewMode});
  const location = useLocation();
  const locationQuery = new URLSearchParams(location.search);
  const {assignMembers} = useProjectInfo(projectId || "");
  const [itemSelected, setItemSelected] = useState(false);
  const dateAble = true;

  useEffect(() => {
    setAssignees && setAssignees(assignMembers);
  }, [assignMembers]);

  useEffect(() => {
    const view = locationQuery.get("view");
    switch (view){
      case "inbox":
        setViewMode(VIEW_MODE.INBOX);
        break;
      case "today":
        setViewMode(VIEW_MODE.TODAY);
        break;
      case "someday":
        setViewMode(VIEW_MODE.SOMEDAY);
        break;
      case "tome":
        setViewMode(VIEW_MODE.TOME);
        break;
      case "trash":
        setViewMode(VIEW_MODE.TRASH);
        break;
    }
  }, [locationQuery]);

  const onSelectItem = (id: any) => {
    setItemSelected(id);
  }

  return (
    <>
      <TaskCreateView {...{projectId, canManageProject, members: assignMembers, dateAble, viewMode, headline: true}}/>
      <TaskTodo
        tasks={todoTasks}
        assignees={assignMembers || []}
        onSelect={onSelectItem}
        taskSelected={itemSelected} dateAble
      />
      <TaskComplete tasks={completeTasks} assignees={assignMembers} dateAble/>
    </>
  )
}

export default TaskList;
