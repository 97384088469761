import React, {useState} from "react";
import {ChevronDownIcon, ChevronRightIcon, majorScale, Pane, Text} from "evergreen-ui";

function VersionsInDate({label, histories, setCurrentVersion, currentVersion}) {
  const [expandState, setExpand] = useState(false);
  const formatDate = (time) => {
    const dateFormat = {
      month: 'long', day: 'numeric', minute: 'numeric', hour: 'numeric'
    };
    const date = new Date(time);

    return date.toLocaleDateString('en-US', dateFormat);
  }

  return (
    <Pane
      borderBottom="1px solid #E6E8F0"
      marginX={majorScale(4)}
      paddingY={majorScale(1)}
    >
      <Pane
        paddingY={majorScale(1)}
        display="flex"
        justifyItems="center"
        cursor="pointer"
        onClick={() => setExpand(!expandState)}
      >
        <Pane marginTop="1px">
          {!expandState && <ChevronRightIcon color="#8F95B2"/>}
          {expandState && <ChevronDownIcon color="#8F95B2"/>}
        </Pane>
        <Text marginLeft={majorScale(1)} color="#101840" fontWeight="500">{label}</Text>
      </Pane>
      {expandState && (
        <Pane paddingLeft={majorScale(3)} cursor="pointer" maxHeight="60vh" overflowY="auto">
          {
            (histories || []).map(history => (
              <Pane
                key={history.generation}
                paddingLeft={majorScale(2)}
                paddingBottom={majorScale(1)}
                onClick={() => setCurrentVersion(history)}
              >
                <Text color={history.generation === currentVersion.generation ? "#3366FF" : "#474D66"} fontWeight="400">
                  {formatDate(history.timeCreated)}
                </Text>
              </Pane>
            ))
          }
        </Pane>
      )}
    </Pane>
  )
}

export default VersionsInDate;
