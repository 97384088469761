import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Button, majorScale, TextInput } from 'evergreen-ui';
import { SENT_PASSWORD_URL } from 'utils/constants';
import { ResetService, ResetType } from 'services/reset';

export const ResetPasswordForm = ({ onError }: { onError: Function }) => {
  const history = useHistory();
  const resetService = new ResetService();

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email()
        .required(),
    }),
    onSubmit: async ({ email }) => {
      try {
        await resetService.sendResetEmail(ResetType.ResetPassword, email);
        history.push(SENT_PASSWORD_URL);
      } catch (e: any) {
        onError(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="mt-45" onSubmit={handleSubmit}>
      <TextInput
        type="email"
        placeholder="Email Address"
        className="w-100"
        isInvalid={!!(touched.email && errors.email)}
        {...getFieldProps('email')}
      />
      <Button
        appearance="primary"
        type="submit"
        className="w-100 mt-3"
        height={majorScale(5)}
        isLoading={isSubmitting}
      >
        Reset Password
      </Button>
    </form>
  )
}
