import React from 'react';
import ReactDOM from 'react-dom';
import PopupMenu from './PopupMenu';
import { isHavePermission } from 'services/user';
import { getUser } from 'utils/store';
import { PermissonType } from 'schemas/role';

export const PopupMenuRender = (editorUi, doc) => {
  const user = getUser();
  const isEditable = isHavePermission(user, doc, PermissonType.EditDoc);
  
  if (isEditable) {
    ReactDOM.render(
      <PopupMenu editorUi={editorUi} doc={doc} />,
      document.querySelector('.mxPopupMenu')
    )
  } else {
    document.querySelector('.mxPopupMenu').style.opacity = 0;
  }
}
