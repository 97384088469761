import { useContext } from 'react';
import styles from './TipModal.module.scss';
import { Modal } from '../Modal/Modal';
import { EditorContext } from '../../../context/EditorContext';

const TipModal = () => {
  const { tipModal, setTipModal } = useContext(EditorContext);
  return (
    <Modal
      width={433}
      isShown={tipModal}
      hasCancel={false}
      title="Keyboard Shortcuts"
      confirmLabel="OK"
      onCloseComplete={() => setTipModal(false)}
      onConfirm={() => setTipModal(false)}
    >
      Move between topics: <span className={styles.highlight}>Arrow keys</span> <br/>
      Insert Sibling topic: <span className={styles.highlight}>Enter/Return key</span> <br/>
      Insert subtopic: <span className={styles.highlight}>Tab key</span> <br/>
      Edit text in a node: <span className={styles.highlight}>Just start typing</span> <br/>
      Delete: <span className={styles.highlight}>Delete key</span> <br/>
      Undo: <span className={styles.highlight}>Ctrl/⌘ + Z</span> <br/>
      Redo: <span className={styles.highlight}>Ctrl/⌘ + Y</span> <br/>
      Open or close a branch: <span className={styles.highlight}>Click on the root of branch</span>
    </Modal>
  );
}

export default TipModal;
