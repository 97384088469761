import {
  Button,
  CogIcon,
  CrownIcon,
  CreditCardIcon,
  LogOutIcon,
  majorScale,
  Menu,
  MenuIcon,
  Popover,
  Position,
  Pane,
  Text,
  toaster
} from 'evergreen-ui';
import React, {useState} from 'react';
import {UpgradeToProModal} from '../../../components/Modals';
import {auth} from '../../../utils/firebase';
import {deleteUser, isFreeUser} from '../../../utils/store';
import {SIGN_IN_URL} from '../../../utils/constants';
import useDatabaseSnapshot from '../../../hooks/useDatabaseSnapshot';
import {useHistory} from 'react-router-dom';
import {StripeService} from 'services/stripe';

const stripe = new StripeService();

const AppFooterBoard = () => {
  const { unsubscribeDatabaseSnapshot } = useDatabaseSnapshot();
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleClickSignOut = async () => {
    try {
      if (unsubscribeDatabaseSnapshot) {
        unsubscribeDatabaseSnapshot();
      }
      await auth.signOut();
      deleteUser();
      history.push(SIGN_IN_URL);
    } catch (e: any) {
      toaster.danger(e.message);
    }
  };

  const handleClickCustomerPortal = async () => {
    try {
      setLoading(true);
      const { data } = await stripe.createPortalLink()
      window.open(data.url, '_blank');
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Pane justifyContent={"space-between"} display={"flex"}>
        <Popover
          position={Position.TOP_LEFT}
          content={
            <Menu>
              <Menu.Group title={(<Text color="#8F95B2" fontSize="10px">Menu</Text>)}>
                <Menu.Item icon={(<CogIcon color="#8F95B2"/>)} onClick={() => history.push("/settings")}>
                  <Text color="#8F95B2">Settings</Text>
                </Menu.Item>
                {!isFreeUser() && (
                  <Menu.Item
                    disabled={loading}
                    icon={(<CreditCardIcon color="#8F95B2"/>)}
                    width="208px"
                    onClick={handleClickCustomerPortal}
                  >
                    <Text color="#8F95B2">Billing & Receipts</Text>
                  </Menu.Item>
                )}
                <Menu.Item icon={(<LogOutIcon color="#8F95B2"/>)} onClick={handleClickSignOut}>
                  <Text color="#8F95B2">Sign Out</Text>
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
          minWidth="208px"
        >
          <Button alignSelf={"center"}>
            <MenuIcon size={10}/>
            <Text paddingLeft={majorScale(1)} fontSize={12}>Menu</Text>
          </Button>
        </Popover>
        {isFreeUser() &&
          <Button
            alignSelf={"center"}
            color="#3366FF"
            marginLeft={majorScale(1)}
            onClick={() => setModalUpgrade(true)}
          >
            <CrownIcon size={12}/>
            <Text paddingLeft={majorScale(1)} color="#3366FF" fontSize={12}>Upgrade</Text>
          </Button>
        }
      </Pane>

      <UpgradeToProModal isShown={modalUpgrade} setIsShown={setModalUpgrade} />
    </>
  )
}

export default AppFooterBoard;
