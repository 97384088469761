import {
  Pane,
  Text,
  Paragraph,
  MoreIcon,
  TickIcon,
  Popover,
  Menu,
  Position,
  AnnotationIcon,
  TrashIcon,
  Tooltip,
  majorScale,
  Avatar
} from 'evergreen-ui';
import clsx from 'clsx';
import ReactTimeAgo from 'react-time-ago';
import styles from './CommentItem.module.scss';

export const CommentItem = ({
  id,
  email,
  name,
  comment,
  avatar,
  updatedAt,
  totalReply,
  allowSelect,
  allowResolve,
  replyCounter,
  actionBar,
  onSelect,
  onEdit,
  onDelete
}) => {
  return (
    <Pane
      display="flex"
      flexDirection="row"
      marginTop="1rem"
      width="100%"
      className={clsx(allowSelect && styles.container)}
      onClick={() => onSelect(id)}
    >
      <Pane flexShrink={0}>
        <Avatar src={avatar} name={name === '' ? email : name} size={majorScale(4)} />
      </Pane>
      <Pane marginLeft={8} width="100%">
        <Pane display="flex">
          <Pane flexGrow={1}>
            <Text className={styles.title}>{name === '' ? email : name}</Text>
          </Pane>
          {actionBar && (
            <Pane className={styles.actionBar}>
              <Popover
                position={Position.BOTTOM_LEFT}
                content={({ close }) => (
                  <Menu>
                    <Menu.Group>
                      <Menu.Item
                        icon={AnnotationIcon}
                        onClick={() => onEdit(id)}
                      >
                        Edit Comment
                      </Menu.Item>
                      <Menu.Item
                        icon={TrashIcon}
                        intent="danger"
                        onClick={() => {
                          onDelete(id);
                          close();
                        }}
                      >
                        Delete Comment
                      </Menu.Item>
                    </Menu.Group>
                  </Menu>
                )}
              >
                <MoreIcon className={styles.moreIcon}/>
              </Popover>
              {allowResolve && (
                <Tooltip
                  content={(
                    <Paragraph
                      style={{
                        color: 'white',
                        fontSize: '12px',
                        lineHeight: '16px'
                      }}
                    >
                      Resolve Comment
                    </Paragraph>
                  )}
                  position={Position.RIGHT}
                >
                  <TickIcon className={styles.tickIcon}/>
                </Tooltip>
              )}
            </Pane>
          )}
        </Pane>
        <Paragraph className={styles.content}>{comment}</Paragraph>
        <Text className={styles.status}>
          {replyCounter ? (
            <>
              <ReactTimeAgo date={updatedAt} locale="en-us" /> • {totalReply} REPLY
            </>
          ) : (
            <ReactTimeAgo date={updatedAt} locale="en-us" />
          )}

        </Text>
        <hr className={styles.divider} />
      </Pane>
    </Pane>
  )
}

CommentItem.defaultProps = {
  avatar: '',
  email: 'johndoe@zwf.com',
  name: 'John Doe',
  comment: 'The buttons’ paddings are too large at the moment. Please remove them.',
  updatedAt: Date.now(),
  totalReply: 0,
  actionBar: true,
  allowSelect: true,
  allowResolve: false,
  replyCounter: true,
  onSelect: () => {},
  onEdit: () => {},
  onDelete: () => {},
}
