import clsx from "clsx";
import {useRef} from "react";
import styles from "./TaskItem.module.scss"

type Props = {
  project: string,
  heading?: any,
  setHeadingInfo?: Function,
  saveHeading?: Function
}

const HeadingInput = ({project, heading, setHeadingInfo, saveHeading}: Props) => {
  const headingRef = useRef(null);

  const onInputHeading = (value: string) => {
    setHeadingInfo && setHeadingInfo({
      ...(heading || {}),
      title: value,
      project: project
    });
  }

  const onInputKeyUp = (event: any) => {
    console.log("on event.code", event.code);
    if(event.code === "Enter"){
      console.log("onInputKeyUp trigger saveHeading");
      saveHeading && saveHeading();
    }
  }

  return (
    <>
      <input
        placeholder={"New Headline"}
        color={"#101840"}
        defaultValue={heading?.title}
        className={clsx(styles.headingTitleInput)}
        autoFocus={true}
        onChange={(event: any) => onInputHeading(event.target.value)}
        onKeyUp={onInputKeyUp}
        ref={headingRef}
      />
    </>
  )
}

export default HeadingInput;
