export const LISTCOLOR = [
    {
        id: 1,
        color: "#696F8C",
        checked: false
    },
    {
        id: 2,
        color: "#1F3D99",
        checked: false
    },
    {
        id: 3,
        color: "#317159",
        checked: false
    },
    {
        id: 4,
        color: "#66460D",
        checked: false
    },
    {
        id: 5,
        color: "#7D2828",
        checked: false
    },
    {
        id: 6,
        color: "#8E3374",
        checked: false
    },
    {
        id: 7,
        color: "#85462B",
        checked: false
    },
    {
        id: 8,
        color: "#474D66",
        checked: false
    },
    {
        id: 9,
        color: "#2952CC",
        checked: false
    },
    {
        id: 10,
        color: "#429777",
        checked: false
    },
    {
        id: 11,
        color: "#996A13",
        checked: false
    },
    {
        id: 12,
        color: "#A73636",
        checked: false
    },
    {
        id: 13,
        color: "#BE449B",
        checked: false
    },
    {
        id: 14,
        color: "#B25E3A",
        checked: false
    },
    {
        id: 15,
        color: "#101840",
        checked: false
    },
    {
        id: 16,
        color: "#9DB5FF",
        checked: false
    },
    {
        id: 17,
        color: "#52BD94",
        checked: false
    },
    {
        id: 18,
        color: "#6E62B6",
        checked: false
    },
    {
        id: 19,
        color: "#524988",
        checked: false
    },
    {
        id: 20,
        color: "#10899E",
        checked: false
    },
    {
        id: 21,
        color: "#0F5156",
        checked: false
    }
];