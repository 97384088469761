import clsx from 'clsx';
import {
  Button,
  CaretDownIcon,
  Image,
  majorScale,
  Menu,
  minorScale,
  Pane,
  Popover,
  Position,
  Text,
  MinusIcon,
  SmallPlusIcon,
} from 'evergreen-ui';
import { useState, useContext } from 'react';
import { Observer } from 'mobx-react-lite';

import styles from './Menubar.module.scss';
import logo from 'assets/img/logo.svg';
import './MenuBar.css';
import { truncate } from 'utils/helpers';
import OnlineUsers from './OnlineUsers';
import useStores from 'hooks/useStores';
import ShareDocumentModal from 'components/Documents/ShareDocumentModal';
import { getUser } from 'utils/store';
import { PermissonType } from 'schemas/role';
import { isHavePermission } from 'services/user';
import { EditorContext } from 'context/EditorContext';

const MenubarViewerMindMap = ({
  documentName,
  unreadComments,
  folder
}) => {
  const { convergenceStore, graphStore } = useStores();
  const [isShowShare, setShowShare] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(100);
  const { isCommentBarVisible, toggleCommentBar, designer } = useContext(EditorContext);
  const zoomRatios = [1.2, 1.0500000000000005, 0.9500000000000004, 0.8, 0.5499999999999998, 0.30000000000000004];

  const user = getUser();

  const handleClickZoomOut = () => {
    designer.zoomOut();
    setCurrentZoom(designer.getZoomVisualValue());
  };

  const handleClickZoomTo = scale => {
    designer.setZoom(scale);
    setCurrentZoom(designer.getZoomVisualValue());
  };

  const handleClickZoomIn = () => {
    designer.zoomIn();
    setCurrentZoom(designer.getZoomVisualValue());
  }

  const getAction = key => {
    try {
      const actions = graphStore.editorUi.actions;
      return actions.get(key).funct || function () {};
    } catch (ex) {
      console.log('Menu bar getAction throw ex', ex);
    }
  };

  const convertToRatio = scale => {
    return designer ? designer.convertScaleToVisualValue(scale) : scale;
  }

  return (
    <Pane
      height={majorScale(6)}
      display="flex"
      borderBottom="1px solid"
      borderBottomColor="#D8DAE5"
      paddingX={majorScale(2)}
    >
      <Pane flex={1} display="flex" alignItems={'center'}>
        <a href="/app">
          <Image src={logo} width={minorScale(5)} alt="logo" />
        </a>
        <Pane className={styles.titleWidget}>
          <Pane className={clsx(styles.titleLabel)}>
            <Text marginX={majorScale(2)} className={styles.titleLabelInner}>
              {truncate(documentName || '', 120)}
            </Text>
          </Pane>
        </Pane>
      </Pane>
      <Pane display={'flex'} alignItems={'center'} className="zwfMenubarViewMobile">
        <Pane marginRight={majorScale(2)}>
          <Observer>
            {() => (
              <OnlineUsers
                activeUsers={Object.values(convergenceStore.sessions)}
              />
            )}
          </Observer>
        </Pane>

        {isHavePermission(
          user,
          graphStore.document,
          PermissonType.CommentDoc,
          folder
        ) && (
          <Button
            height={majorScale(3)}
            marginRight={majorScale(1)}
            className={clsx(
              'border-lighten-gray',
              styles.commentBarWidget,
              styles.commentBarViewer,
              isCommentBarVisible && styles.commentBarVisible
            )}
            onClick={toggleCommentBar}
          >
            <Pane marginRight={majorScale(1)}>Comment</Pane>
            <Pane
              borderRadius={minorScale(1)}
              backgroundColor="#D6E0FF"
              maxHeight={minorScale(4)}
              minWidth={minorScale(5)}
              display="flex"
              justifyContent={'center'}
            >
              <Text fontSize={11} color="#2952CC" alignSelf={'center'}>
                {unreadComments}
              </Text>
            </Pane>
          </Button>
        )}

        {isHavePermission(
          user,
          graphStore.document,
          PermissonType.ExportDoc,
        ) && (
          <Popover
            position={Position.BOTTOM_LEFT}
            content={({ close }) => (
              <Pane onClick={close} minWidth={140}>
                <Menu>
                  <Menu.Item
                    paddingX={0}
                    onClick={() =>
                      getAction('export')('zen_wireframe', 'jpg', false)
                    }
                  >
                    <Text fontSize={12}>Export as JPG</Text>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    paddingX={0}
                    onClick={() =>
                      getAction('export')('zen_wireframe', 'png', true)
                    }
                  >
                    <Text fontSize={12}>Export as PNG</Text>
                  </Menu.Item>
                </Menu>
              </Pane>
            )}
          >
            <Button
              height={majorScale(3)}
              marginRight={majorScale(1)}
              className="border-lighten-gray"
            >
              Export
              <CaretDownIcon size={12} className="ml-2" color="#8F95B2" />
            </Button>
          </Popover>
        )}

        <Pane marginRight={majorScale(2)} className="on-one-line">
          <Button
            height={majorScale(4)}
            width={majorScale(4)}
            paddingX={'auto'}
            onClick={handleClickZoomOut}
            className="border-lighten-gray"
          >
            <MinusIcon size={12} color="#474D66" />
          </Button>
          <Popover
            position={Position.BOTTOM_LEFT}
            minWidth={150}
            content={({ close }) => (
              <Pane onClick={close}>
                <Menu>
                  {zoomRatios.map((ratio, index) => (
                    <Pane key={index}>
                      <Menu.Divider />
                      <Menu.Item
                        paddingX={0}
                        display={'flex'}
                        alignItems={'center'}
                        onClick={() => handleClickZoomTo(ratio)}
                      >
                        <Text fontSize={12}>{convertToRatio(ratio)}%</Text>
                      </Menu.Item>
                    </Pane>
                  ))}
                </Menu>
              </Pane>
            )}
          >
            <Button
              height={majorScale(4)}
              paddingX={majorScale(2)}
              paddingY={majorScale(1)}
              marginX={minorScale(1)}
              minWidth={90}
              className="border-lighten-gray"
            >
              {currentZoom}%
              <CaretDownIcon size={12} color="#474D66" className="ml-2" />
            </Button>
          </Popover>
          <Button
            height={majorScale(4)}
            width={majorScale(4)}
            paddingX={'auto'}
            onClick={handleClickZoomIn}
            className="border-lighten-gray"
          >
            <SmallPlusIcon size={16} color="#474D66" />
          </Button>
        </Pane>

        <a href="/sign-up">
          <Button height={majorScale(4)} appearance="primary">
            Sign Up
          </Button>
        </a>
      </Pane>
      <ShareDocumentModal
        isMindMap
        isShown={isShowShare}
        setIsShown={setShowShare}
        doc={graphStore.document}
      />
    </Pane>
  );
};

MenubarViewerMindMap.defaultProps = {
  unreadComments: 0,
};

export default MenubarViewerMindMap;
