import {Button, Card, majorScale, Pane, Textarea} from "evergreen-ui";
import React, {useState} from "react";
import _ from "lodash"
import {UserService} from "../../../services/user";

const CancelCodes = () => {
  const [codes, setCodes] = useState([] as string[]);
  const [text, setText] = useState("");
  const userService = new UserService();

  const onTextarea = (val: string) => {
    const replaceNonAlphaToLineBreak = val.toUpperCase().replace(/[\W_]+/g, "\n");
    setText(replaceNonAlphaToLineBreak);
    const toArray = replaceNonAlphaToLineBreak.split("\n").filter(code => !_.isEmpty(code));
    setCodes(toArray);
  }

  const cancelCodes = async () => {
    userService.cancelCodes(codes).then(() => {});
    setText("");
  }

  return (
    <Pane padding={majorScale(4)}>
      <Card padding={majorScale(4)} background={"white"} elevation={1}>
        <Pane color="#101840" fontWeight={600} fontSize={16}>Refund codes</Pane>
        <Pane paddingY={majorScale(1)}>
          <Textarea
            onChange={(e: any) => onTextarea(e.target.value)}
            value={text}
            width={400}
            height={144}
            placeholder={"Codes"}
          />
        </Pane>
        <Pane paddingY={majorScale(1)}>
          <Button
            appearance="primary"
            onClick={() => cancelCodes()}
          >
            Remove PRO Status
          </Button>
        </Pane>
      </Card>
    </Pane>
  )
}

export default CancelCodes;
