import React, { useState } from 'react';
import { Alert } from 'evergreen-ui';
import CardAuth from 'components/CardAuth/CardAuth';
import CardLink from 'components/CardAuth/CardLink';
import { SignUpRedeemForm } from './SignUpRedeemForm';

const SignUpRedeem = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  return (
    <>
      {error && (
        <Alert
          intent="danger"
          title={error}
        />
      )}
      {success && (
        <Alert
          intent="success"
          title={success}
        />
      )}
      <CardAuth
        title="Sign Up & Redeem"
        subtitle="Redeem a Zen Flowchart PRO account"
      >
        <SignUpRedeemForm
          onSuccess={(message: string) => setSuccess(message)}
          onError={(message: string) => setError(message)}
        />
      </CardAuth>
      <CardLink
        title="Redeem for an existing account?"
        links={[{
          text: 'Click here.',
          link: '/redeem',
        }]}
      />
    </>
  );
}

export default SignUpRedeem;
