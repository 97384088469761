import { SyntheticEvent, useState, useEffect, useContext } from 'react';
import { TextareaField } from 'evergreen-ui';
import { EditorContext } from 'context/EditorContext';
import { Modal } from '../Modal/Modal';

const AddNoteModal = () => {
  // @ts-ignore
  const { designer, mindplot, noteModal, setNoteModal } = useContext(EditorContext);
  const [isEditNote, setEditNote] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (noteModal) {
      const url = getNoteSelectedTopic();
      setNote(url);
      setEditNote(url.length > 0)
    }
  }, [noteModal]);

  useEffect(() => {
    window.addEventListener('clickNoteIcon', handleClickNoteIcon);
    return () => window.removeEventListener('clickNoteIcon', handleClickNoteIcon);
  }, []);

  const handleClickNoteIcon = () => {
    setNoteModal(true);
  }

  const getNoteSelectedTopic = () => {
    const designerModel = designer.getModel();
    const topic = designerModel.selectedTopic();
    const model = topic.getModel();
    const notes = model.findFeatureByType(mindplot.TopicFeature.Note.id);
    let result;
    if (notes.length > 0) {
      result = notes[0].getText();
    }

    return result || '';
  }

  const setNoteSelectedTopic = (value: (string | null)) => {
    const designerModel = designer.getModel();
    const topic = designerModel.selectedTopic();
    const model = topic.getModel();
    const topicId = topic.getId();
    const dispatcher = mindplot.ActionDispatcher.getInstance();
    const notes = model.findFeatureByType(mindplot.TopicFeature.Note.id);
    if (!value) {
      var featureId = notes[0].getId();
      dispatcher.removeFeatureFromTopic(topicId, featureId);
    }
    else {
      if (notes.length > 0) {
        dispatcher.changeFeatureToTopic(topicId, notes[0].getId(), { text: value });
      }
      else {
        dispatcher.addFeatureToTopic(topicId, mindplot.TopicFeature.Note.id, { text: value });
      }
    }
  }

  const handleConfirm = () => {
    setNoteSelectedTopic(note);
    setNoteModal(false);
  }

  const handleCancel = () => {
    if (isEditNote) {
      setNoteSelectedTopic(null)
    }
    setNoteModal(false);
  }

  const handleChange = (event: SyntheticEvent) => {
    setNote((event.target as HTMLTextAreaElement).value);
  }

  const handleKeyPress = (event: any) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleConfirm();
    }
  }

  return (
    <Modal
      width={420}
      intent={isEditNote ? 'editModal' : 'none'}
      isShown={noteModal}
      title={`${isEditNote  ? 'Edit' : 'Add'} Note`}
      cancelLabel={isEditNote ? 'Remove Note' : 'Cancel'}
      onCloseComplete={() => setNoteModal(false)}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      minHeightContent={0}
      confirmLabel="Save"
    >
      <TextareaField
        id="insertNote"
        name="insert-note"
        placeholder="Note"
        onKeyPress={handleKeyPress}
        onChange={handleChange}
        margin={0}
      >
        {note}
      </TextareaField>
    </Modal>
  );
}

export default AddNoteModal;
