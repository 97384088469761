import { useContext, useState } from 'react';
import { toaster } from 'evergreen-ui';
import { EditComment } from '../EditComment/EditComment';
import { EditorContext } from '../../../../../context/EditorContext';
import { CommentService } from '../../../../../services/document/comment.service';
import { CommentItem } from './CommentItem';
import { getUser } from '../../../../../utils/store';
import { getUserAvatar } from '../../../../../utils/helpers';

export const CommentItemContainer = ({ comment }) => {
  const [editing, setEditing] = useState(false);
  const { documentId } = useContext(EditorContext);
  const commentService = new CommentService(documentId);
  const user = getUser();

  const handleToggle = () => setEditing(!editing);

  const handleConfirm = async input => {
    try {
      await commentService.updateComment(comment.id, input);
      handleToggle();
    } catch (e) {
      toaster.danger(e.message);
    }
  }

  const handleDelete = async id => {
    try {
      await commentService.deleteComment(id);
    } catch (e) {
      toaster.danger(e.message);
    }
  }

  return (
    <>
      {editing ? (
        <EditComment
          comment={comment.comment}
          onConfirm={handleConfirm}
          onCancel={handleToggle}
        />
      ) : (
        <CommentItem
          allowResolve={false}
          allowSelect={false}
          replyCounter={false}
          actionBar={user?.uid === comment.user?.id}
          id={comment.id}
          userId={comment.user?.id}
          email={comment.user?.email}
          name={comment.user?.fullName}
          avatar={getUserAvatar(comment.user)}
          comment={comment.comment}
          updatedAt={comment.updatedAt.toDate()}
          onEdit={handleToggle}
          onDelete={handleDelete}
        />
      )}
    </>
  )
}

CommentItemContainer.defaultProps = {
  comment: {}
}
