import firebase from 'firebase';
import _ from 'lodash';

import { db } from 'utils/firebase';
import { NOTEBOOK_COLLECTION } from 'utils/constants';
import { getUser } from 'utils/store';
import { NotebookInput, NotebookSchema } from 'schemas/notebook';
import { UserService } from 'services/user';
import { EmailService } from 'services/email';
import { functions } from 'utils/firebase';
import { NoteService } from './note.service';

type QuerySnapshot = firebase.firestore.QuerySnapshot;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

export class NotebookService {
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private readonly userService: UserService;
  private readonly emailService: EmailService;
  private readonly noteService: NoteService;

  /**
   * Firebase functions
   * @private
   */
  private readonly functions: firebase.functions.Functions;

  constructor() {
    this.db = db.collection(NOTEBOOK_COLLECTION);
    this.userService = new UserService();
    this.emailService = new EmailService();
    this.noteService = new NoteService();
    this.functions = functions;
  }

  mapItem(querySnapshot: QuerySnapshot) {
    const collections: NotebookSchema[] = [];
    querySnapshot.forEach(item => {
      if (item.exists) {
        collections.push({ id: item.id, ...item.data() } as NotebookSchema);
      }
    });
    return collections;
  }

  async getOne(id: string): Promise<DocumentSnapshot> {
    return this.db.doc(id).get();
  }

  queryTrash(): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    const user = getUser() || {};
    return this.db
      .where('isDeleted', '==', true)
      .where('user', '==', user.uid)
      .get();
  }

  async getTrash(): Promise<NotebookSchema[]> {
    return this.queryTrash().then((querySnapshot: QuerySnapshot) => {
      const collections: NotebookSchema[] = [];
      querySnapshot.forEach(item => {
        if (item.exists) {
          collections.push({ id: item.id, ...item.data() } as NotebookSchema);
        }
      });
      return collections;
    });
  }

  async getSharedDocuments(): Promise<NotebookSchema[]> {
    const user = getUser() || {};
    const query = this.db
      .where('isDeleted', '==', false)
      .where(`roles.${user.uid}.type`, 'in', ['editor', 'commenter', 'viewer'])
      .get();

    return query.then((querySnapshot: QuerySnapshot) => {
      const collections: NotebookSchema[] = [];
      querySnapshot.forEach(item => {
        if (item.exists) {
          collections.push({ id: item.id, ...item.data() } as NotebookSchema);
        }
      });
      return collections;
    });
  }

  async removeSharedDocument(id: string): Promise<void> {
    const user = getUser() || {};
    const document = await this.db.doc(id).get();
    const roles = document?.data()?.roles;
    delete roles[user.uid];
    await this.db.doc(id).update({
      roles: roles,
      updatedAt: new Date(),
    });
  }

  async removeTeamSharedDocument(id: string, teamsIds: any): Promise<void> {
    const document = await this.db.doc(id).get();
    const oldTeamRoles = document?.data()?.teamRoles || {};
    let teamRoles = {};
    Object.keys(oldTeamRoles).forEach(teamId => {
      if (!(teamsIds || []).includes(teamId)) {
        teamRoles = { ...teamRoles, [teamId]: _.get(oldTeamRoles, teamId, {}) };
      }
    });

    await this.db.doc(id).update({
      teamRoles: teamRoles,
      updatedAt: new Date(),
    });
  }

  async create(notebook: NotebookInput) {
    const { uid } = getUser();
    let roles = {} as any;
    roles[uid] = 'owner';
    const data = {
      ...notebook,
      isDeleted: false,
      user: uid,
      roles: roles,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const newDoc = await this.db.add(data);
    return newDoc;
  }

  async rename(id: string, name: string) {
    const newDoc = await this.db.doc(id).update({
      name: name,
      updatedAt: new Date(),
    });
    return newDoc;
  }

  async updateDocumentFolder(id: string, folder: string) {
    const newDoc = await this.db.doc(id).update({
      folder: folder,
      updatedAt: new Date(),
    });
    return newDoc;
  }

  /**
   * Add user share role
   *
   * @param id
   * @param emails
   * @param role
   */
  async addUserRole(id: string, emails: string[], role: string) {
    const user = getUser() || {};
    const doc = await this.db.doc(id).get();
    const docData = doc.data() as NotebookSchema;
    let roles = docData.roles;
    let invites = docData.invites || {};
    const users = await this.userService.getManyByEmails(emails);

    let existEmails = [] as string[];
    let newEmails = [] as string[];

    if (users.length > 0) {
      const shareUids = users.map(item => item.id);
      existEmails = users.map(item => item.email);
      shareUids.forEach(shareUid => {
        if (shareUid && shareUid !== user.uid) {
          roles[shareUid] = {
            type: role,
            updatedAt: new Date(),
            updatedBy: user.uid,
            updatedByEmail: user.email || '',
          };
        }
      });
    }

    newEmails = emails.filter(item => !existEmails.includes(item));
    for (const newEmail of newEmails) {
      invites[btoa(newEmail)] = {
        type: role,
        updatedAt: new Date(),
        updatedBy: user.uid,
        updatedByEmail: user.email || '',
        inviteEmail: newEmail,
      };
    }
    await this.db.doc(id).update({
      roles: roles,
      invites: invites,
      updatedAt: new Date(),
    });

    if (existEmails.length > 0 || newEmails.length > 0) {
      // Send invite email
      // await this.emailService.sendInvites({
      //   newEmails,
      //   existEmails,
      //   documentId: id,
      //   documentName: docData.name,
      //   role: _.startCase(role),
      // });
    }
  }

  /**
   * Update user role
   *
   * @param id
   * @param email
   * @param role
   */
  async updateUserRole(id: string, email: string, role: string) {
    const user = getUser() || {};
    const doc = await this.db.doc(id).get();
    const docData = doc.data() as NotebookSchema;
    let roles = docData.roles;
    let invites = docData.invites || {};

    const isRemoveShare = role === 'remove';
    const inviteUser = await this.userService.getByEmail(email);
    if (isRemoveShare && inviteUser) {
      delete roles[inviteUser.id];
    } else if (inviteUser?.id) {
      roles[inviteUser.id] = {
        type: role,
        updatedAt: new Date(),
        updatedBy: user.uid,
      };
    } else {
      if (isRemoveShare) {
        delete invites[btoa(email)];
      } else {
        invites[btoa(email)] = {
          type: role,
          inviteEmail: email,
          updatedAt: new Date(),
          updatedBy: user.uid,
        };
      }
    }
    await this.db.doc(id).update({
      roles: roles,
      invites: invites,
      updatedAt: new Date(),
    });
  }

  /**
   * Update team role
   *
   * @param id
   * @param email
   * @param role
   */
  async updateTeamRole(docId: string, teamId: string, role: string) {
    const user = getUser();
    const doc = await this.db.doc(docId).get();
    const docData = doc.data() as NotebookSchema;
    let teamRoles = docData.teamRoles || {};
    const newRole = {
      type: role,
      updatedBy: user.uid,
      updatedByEmail: user.email,
    };
    teamRoles = {
      ...teamRoles,
      [teamId]: newRole,
    };

    await this.db.doc(docId).update({
      teamRoles,
      updatedAt: new Date(),
    });
  }

  /**
   * Update link role
   *
   * @param id
   * @param role
   */
  async updatePublicRole(id: string, role: string) {
    const user = getUser() || {};
    const doc = await this.db.doc(id).get();
    const docData = doc.data() as NotebookSchema;
    let roles = docData.roles;
    if (role) {
      roles['anyone'] = {
        type: role,
        updatedAt: new Date(),
        updatedBy: user.uid,
      };
      await this.db.doc(id).update({
        roles: roles,
        updatedAt: new Date(),
      });
    }
  }

  async softDelete(id: string) {
    await this.noteService.softDeleteByNotebook(id);
    return this.db.doc(id).update({ isDeleted: true });
  }

  restore(id: string): Promise<void> {
    return this.db.doc(id).update({ isDeleted: false });
  }

  updateOne(id: string, data: object): Promise<void> {
    const user = getUser() || {};
    return this.db.doc(id).update({
      ...data,
      updatedAt: new Date(),
      updatedBy: user.uid,
    });
  }

  getDoc(id: string) {
    return this.db.doc(id);
  }

  deleteOne(id: string): Promise<void> {
    return this.db.doc(id).delete();
  }

  async deleteAll(): Promise<void> {
    const snapshots = await this.queryTrash();
    snapshots.forEach((snapshot: { ref: { delete: () => any } }) =>
      snapshot.ref.delete(),
    );
  }
}
