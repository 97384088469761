import { useEffect } from 'react';
import { TextInput } from 'evergreen-ui';
import { Dialog } from 'components/Dialog';
import { useState } from 'react';

import { NotebookService } from 'services/note';
import { NotebookSchema } from 'schemas/notebook';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  notebook: NotebookSchema;
}

const notebookService = new NotebookService();

export default function RenameNotebookModal({ isShown, setIsShown, notebook }: Props) {
  const [newName, setNewName] = useState('');
  const [currentNotebook, setCurrentNotebook] = useState(notebook);

  useEffect(() => {
    setCurrentNotebook(notebook);
    setNewName(notebook.name);
  }, [notebook]);

  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      setNewName(e.target.value);
      await handleRenameNotebook();
    }
  };

  const handleRenameNotebook = async () => {
    try {
      setIsShown(false);
      if (currentNotebook.id) {
        await notebookService.rename(currentNotebook.id, newName);
      }
    } finally {
      // setIsShown(false);
    }
  };

  return (
    <Dialog
      isShown={isShown}
      title="Rename Notebook"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Save"
      onConfirm={handleRenameNotebook}
    >
      <TextInput
        width="100%"
        required
        name="name"
        autoFocus={true}
        value={newName}
        onKeyDown={handleKeyDown}
        onChange={(e: any) => setNewName(e.target.value)}
      />
    </Dialog>
  );
}
