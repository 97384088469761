import { Combobox as ECombobox, ComboboxOwnProps } from 'evergreen-ui';

interface Props extends ComboboxOwnProps {
  popoverMinWidth?: number;
  inputValue?: any;
}

export default function Combobox({
  initialSelectedItem,
  items,
  onChange,
  itemToString,
  inputValue,
  popoverMinWidth = 120,
  ...props
}: Props) {
  const handleOnChange = (selected: any) => {
    if (onChange) {
      onChange(selected);
    }
  };
  return (
    <ECombobox
      initialSelectedItem={initialSelectedItem}
      items={items}
      itemToString={itemToString}
      onChange={onChange}
      autocompleteProps={{
        popoverMinWidth: popoverMinWidth,
        items: items,
        onChange: (selected: any) => handleOnChange(selected),
        children: (props: any) => <div onChange={props.onChange} />,
        buttonProps: { className: 'comboboxListItem' },
      }}
      {...props}
    />
  );
}
