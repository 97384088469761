import React from 'react';
import { Button as EButton } from 'evergreen-ui';

const Button = (props: any) => {
  const { children, format, active, ...rest } = props;
  return (
    <EButton
      className={active ? 'btnActive' : 'lol'}
      title={format}
      appearance="minimal"
      {...rest}
      style={{ color: active ? '#3366FF' : '#696F8C' }}
    >
      {children}
    </EButton>
  );
};

export default Button;
