import { useState } from 'react';
import {DotIcon, UnorderedList, ListItem, FolderSharedOpenIcon, FolderSharedIcon} from 'evergreen-ui';
import {
  ChevronRightIcon,
  FolderCloseIcon,
  FolderOpenIcon,
} from 'evergreen-ui';
import { useHistory, useLocation } from "react-router-dom";
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useEffect } from 'react';

import styles from './index.module.scss';
import clsx from 'clsx';
import { FolderSchema } from 'schemas/folder';
import { FolderService } from 'services/folder';
import { useDrop, useDrag } from 'react-dnd'
import { DocumentService } from 'services/document';
import {getUser} from "../../utils/store";
import _ from "lodash";

const folderService = new FolderService();
const documentService = new DocumentService();

const Tree = ({ data = [], setFolderContext }: { data: any[], setFolderContext: Function }) => {
  return (
    <>
      <UnorderedList
        className={clsx(styles.treeList)}
      >
        {data.map((tree) => (
          <TreeNode key={tree.id} node={tree} setFolderContext={setFolderContext} />
        ))}
      </UnorderedList>
    </>
  );
};

const TreeNode = ({
  node,
  setFolderContext,
}: {
  node: any;
  setFolderContext: Function;
}) => {
  const history = useHistory();
  const location = useLocation();
  const [childVisible, setChildVisiblity] = useState(false);
  const hasChild = node.children && node.children.length > 0 ? true : false;

  const handleContextMenu = (folder: FolderSchema) => {
    setFolderContext(folder);
  };

  const handleClickNode = (node: any) => {
    setChildVisiblity((v) => !v);
    history.push({
      pathname: '/folders/' + node.id,
      state: { folder: node.id }
    });
  }

  const isActiveRoute = (path: string) => {
    if (location.pathname.includes(path)) {
      return {
        isActive: true
      };
    }
    return {
      isActive: false
    };
  }

  const isDocumentOwner = (doc: any) => {
    const user = getUser();
    const isOwner = _.get(doc.roles, user.uid) === "owner";
    return isOwner;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [{ canDrop, isOver }, dropDocRef] = useDrop(() => ({
    accept: ['DOCUMENT', 'FOLDER'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item: any, monitor) => {
      if (item.type === 'DOCUMENT') {
        return isDocumentOwner(item.doc);
      } else if (item.type === 'FOLDER') {
        if (item.folder?.children?.length > 0) {
          return false;
        }
        if (node?.parentFolder) {
          return false;
        }
        if (item.folder?.id === node.id) {
          return false;
        }
        return true;
      }
      return false;
    },
    drop: async (item: any, monitor)  => {
      if (item.type === 'DOCUMENT') {
        if (item.doc) {
          await documentService.updateDocumentFolder(item.doc.id, node.id);
        }
      } else if (item.type === 'FOLDER') {
        if (item.folder) {
          await folderService.updateParentFolder(item.folder.id, node.id);
        }
      }
    }
  }));

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [{ canDrop: canDropOrdinal, isOver: isOverOrdinal }, dropOrdinalRef] = useDrop(() => ({
    accept: ['FOLDER'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: (item: any, monitor) => {
      if (item.folder?.id === node.id) {
        return false;
      }
      return true;
    },
    drop: async (item: any, monitor)  => {
      if (item.folder) {
        const removeParent = item.folder.parentFolder ? true : false;
        await folderService.updateOrdinalAfterFolder(item.folder.id, node, removeParent);
      }
    }
  }));

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [{ isDragging }, dragFolderRef, preview] = useDrag(() => ({
    type: 'FOLDER',
    item: {
      type: 'FOLDER',
      folder: node
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },

    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (monitor) => {
      return true;
      // return !hasChild;
    }
  }));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  return (
    <ListItem className={clsx(styles.treeNode)}>
      <div className={clsx(styles.beforeFolderItem)}></div>
      <div className={styles.folderItem} onClick={(e) => handleClickNode(node)} ref={dropDocRef} >
        {hasChild ? (
          <span
            className={clsx(styles.folderCaret, styles.folderParentCaret, styles.treeToggler, childVisible ? styles.treeActive : '')}
          >
            <ChevronRightIcon size={13} />
          </span>
        ) : (
          <span className={clsx(styles.folderCaret, styles.folderChildCaret)}>
            <DotIcon size={12} />
          </span>
        )}

        <div className={clsx(isOver && styles.canDrop)} onContextMenu={() => handleContextMenu(node)}>
          <span ref={dragFolderRef} className={clsx(styles.folderName, isActiveRoute(node.id))}>
            {hasChild && childVisible ? (
              <span className={styles.folderIcon}>
                {!node.isShared &&
                  <FolderOpenIcon/>
                }
                {node.isShared &&
                  <FolderSharedOpenIcon/>
                }
              </span>
            ) : (
              <span className={styles.folderIcon}>
                {!node.isShared &&
                  <FolderCloseIcon />
                }
                {node.isShared &&
                  <FolderSharedIcon />
                }
              </span>
            )}
            <span className={styles.folderNameLabel}>{node.name}</span>
          </span>
        </div>
      </div>

      <div className={clsx(styles.afterFolderItem, isOverOrdinal && 'canDrop')} ref={dropOrdinalRef}></div>

      {hasChild && childVisible && (
        <Tree data={node.children} setFolderContext={setFolderContext} />
      )}
    </ListItem>
  );
};

export default Tree;
