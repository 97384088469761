/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from 'react';
import { Link as ReactRouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  Heading,
  Pane,
  Card,
  Button,
  DocumentIcon,
  PeopleIcon,
  TrashIcon,
  LogOutIcon,
  EnvelopeIcon,
  Link,
  IconButton,
  PlusIcon,
  Tooltip,
  toaster,
  Position,
  Alert,
  Combobox, ControlIcon
} from 'evergreen-ui';
import clsx from 'clsx';

import { auth } from 'utils/firebase';
import { SIGN_IN_URL } from 'utils/constants';
import { getUser, deleteUser, isFreeUser, isPROTeam } from 'utils/store';
import logo from 'assets/img/logo.svg';
import SettingIcon from 'components/Icon/SettingIcon';
import styles from './Note.module.scss';
import { SendFeedbackModal, UpgradeToProModal } from 'components/Modals';
import CreateFolderModal from 'components/Folder/CreateFolderModal';
import proBadge from 'assets/img/pro_badge.svg';
import teamBadge from 'assets/img/team_badge.svg';
import { CustomDragLayer } from 'components/DragLayer/CustomDragLayer';
import { useDatabaseSnapshot } from 'hooks/useDatabaseSnapshot';
import ShareFolders from '../../components/ShareFolder/ShareFolders';
import UpgradeSuccessModal from '../../components/Modals/UpgradeToProModal/UpgradeSuccessModal';

type Props = {
  children: any;
};

const NoteLayout = ({ children }: Props) => {
  const [isShowCreate, setShowCreate] = useState(false);
  const [alertMobile, setAlertMobile] = useState(true);
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const { unsubscribeDatabaseSnapshot } = useDatabaseSnapshot();
  const history = useHistory();
  const location = useLocation();

  const handleClickSignOut = async () => {
    try {
      if (unsubscribeDatabaseSnapshot) {
        unsubscribeDatabaseSnapshot();
      }
      await auth.signOut();
      deleteUser();
      history.push(SIGN_IN_URL);
    } catch (e: any) {
      toaster.danger(e.message);
    }
  };

  if (!getUser()) {
    history.push(SIGN_IN_URL);
  }

  const isActivePath = (path: string) => {
    return location.pathname.includes(path);
  }

  const isActiveRoute = (path: string) => {
    if (isActivePath(path)) {
      return {
        isActive: true
      };
    }
    return {
      isActive: false
    };
  }

  const handleChangeMobileRoute = (selected: any) => {
    if (selected.value) {
      history.push(selected.value);
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={clsx(styles.mobileHeader)}>
          <Pane className={clsx('text-center', styles.mobileLogo)}>
            <img src={logo} width="40" alt="logo" />
          </Pane>
          {alertMobile && (
            <Pane>
              <Alert
                className={styles.mobileAlert}
                intent="none"
                title="Mobile Device Access"
                isRemoveable={true}
                onRemove={() => {
                  setAlertMobile(false);
                }}
              >
                Documents cannot be edited on mobile devices. Use a desktop browser to
                access all features.
              </Alert>
            </Pane>
          )}
          <Combobox
            initialSelectedItem={
              isActivePath('/shared-with-me')
                ? { label: 'Shared with me' }
                : { label: 'My Documents' }
            }
            width="100%"
            height={40}
            items={[
              { label: 'My Documents', value: '/app' },
              { label: 'Shared with me', value: '/shared-with-me' },
            ]}
            itemToString={(item) => (item ? item.label : '')}
            onChange={handleChangeMobileRoute}
            className={styles.mobileCombobox}
            size="medium"
          />
        </div>

        <aside className={styles.aside}>
          <Card className={styles.asideContainer}>
            <Pane className="text-center">
              <img src={logo} width="35" alt="logo" />
            </Pane>
            <Pane className="text-center">
              {!isFreeUser() ? (
                <img src={isPROTeam() ? teamBadge : proBadge} width="40" alt="logo" className="mt-3" />
              ) : (
                  <Button
                    className={styles.upgradeBtn}
                    appearance="primary"
                    onClick={() => setModalUpgrade(true)}
                  >
                    Upgrade to PRO
                  </Button>
                )}
            </Pane>
            
            <Pane className={clsx(styles.folderContainer)}>
              
            </Pane>

            <Heading
              className={clsx(styles.sidebarItem, isActiveRoute('/shared-with-me'))}
            >
              <Link is={ReactRouterLink} to="/shared-with-me">
                <PeopleIcon />
                <span className={styles.sidebarLabel}>Shared with me</span>
              </Link>
            </Heading>
            <ShareFolders />
            <Heading className={clsx(styles.sidebarItem, isActiveRoute('/templates'))}>
              <Link is={ReactRouterLink} to="/templates">
                <ControlIcon />
                <span className={styles.sidebarLabel}>Templates</span>
              </Link>
            </Heading>
            <Heading className={clsx(styles.sidebarItem, isActiveRoute('/trash'))}>
              <Link is={ReactRouterLink} to="/trash">
                <TrashIcon />
                <span className={styles.sidebarLabel}>Trash</span>
              </Link>
            </Heading>

            <Pane className={styles.bottomSidebar}>
              <hr className={styles.hr} />
              <Heading
                className={clsx('mt-3', styles.sidebarItem)}
                onClick={() => setModalFeedback(true)}
              >
                <Link href="#feedback">
                  <EnvelopeIcon />
                  <span className={styles.sidebarLabel}>Feedback</span>
                </Link>
              </Heading>
              <Heading className={clsx(styles.sidebarItem, isActiveRoute('/settings'))}>
                <Link is={ReactRouterLink} to="/settings">
                  <SettingIcon />
                  <span className={styles.sidebarLabel}>Settings</span>
                </Link>
              </Heading>
              <Heading className={styles.sidebarItem} onClick={handleClickSignOut}>
                <Link href="#">
                  <LogOutIcon />
                  <span className={styles.sidebarLabel}>Sign Out</span>
                </Link>
              </Heading>
            </Pane>
          </Card>
        </aside>

        <div className={styles.main}>
          <div className={styles.mainContent}>{children}</div>
        </div>
      </div>

      <CreateFolderModal isShown={isShowCreate} setIsShown={setShowCreate} />
      <UpgradeToProModal isShown={modalUpgrade} setIsShown={setModalUpgrade} />
      <SendFeedbackModal isShown={modalFeedback} setIsShown={setModalFeedback} />
      <UpgradeSuccessModal isShown={modalSuccess} setIsShown={setModalSuccess} />
      <CustomDragLayer snapToGrid={false} />
    </>
  );
};

export default NoteLayout;
