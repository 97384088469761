import {
  Button,
  majorScale,
  ShareIcon,
  Pane,
  RefreshIcon,
  TickIcon,
  Text,
  InfoSignIcon,
  RemoveIcon,
} from 'evergreen-ui';
import { useState } from 'react';

import styles from './NoteMenubar.module.scss';
import NoteInfoModal from '../Modal/NoteInfoModal';
import useStores from 'hooks/useStores';
import ShareNoteModal from '../Modal/ShareNoteModal';
import { isHaveNotePermission } from 'services/user';
import { NotePermissonType } from 'schemas/role';
import RemoveNoteModal from '../Modal/RemoveNoteModal';

type Props = { isSaving: boolean, isEditable: boolean };

export default function NoteShareMenubar({ isSaving, isEditable }: Props) {
  const [isShowShare, setShowShare] = useState(false);
  const [isShowInfo, setShowInfo] = useState(false);
  const [isShowRemove, setShowRemove] = useState(false);

  const { noteStore } = useStores();

  return (
    <>
      <Pane className={styles.noteMenubar}>
        {isEditable && (
          <Button
            height={majorScale(3)}
            width="6.4375rem"
            className="border-lighten-gray mr-2"
          >
            <Pane display="flex" justifyContent="center">
              {isSaving ? (
                <RefreshIcon
                  size={12}
                  className="mr-2 mt-1"
                  color="#8F95B2"
                  fontSize="12"
                />
              ) : (
                <TickIcon
                  size={12}
                  className="mr-2 mt-1"
                  color="#8F95B2"
                  fontSize="12"
                />
              )}
              <Text fontSize="12">{isSaving ? 'Saving...' : 'Saved'}</Text>
            </Pane>
          </Button>
        )}

        <Button
          height={majorScale(3)}
          className="border-lighten-gray mr-2"
          onClick={() => setShowRemove(true)}
        >
          <RemoveIcon size={12} className="mr-2" color="#D14343" />
          Remove
        </Button>

        <Button
          height={majorScale(3)}
          width={majorScale(4)}
          className="border-lighten-gray mr-2"
          onClick={() => setShowInfo(true)}
        >
          <Pane display="flex" justifyContent="center">
            <InfoSignIcon size={12} color="#8F95B2" />
          </Pane>
        </Button>

        {isHaveNotePermission(noteStore.note, NotePermissonType.InviteUser) && (
          <Button
            height={majorScale(3)}
            onClick={() => setShowShare(true)}
            appearance="primary"
          >
            <ShareIcon size={12} className="mr-2" />
            Share
          </Button>
        )}
      </Pane>

      <NoteInfoModal isShown={isShowInfo} setIsShown={setShowInfo} />

      <ShareNoteModal isShown={isShowShare} setIsShown={setShowShare} />

      <RemoveNoteModal isShown={isShowRemove} setIsShown={setShowRemove} />
    </>
  );
}
