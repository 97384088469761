import { FC, memo } from 'react'
import styles from './Document.module.scss';
export interface DocumentProps {
  title: string
  preview?: boolean
}

export const Document: FC<DocumentProps> = memo(function Document({ title, preview }) {
  return (
    <div
      className={styles.documentPreview}
      role={preview ? 'DocumentPreview' : 'Document'}
    >
      <span>{title}</span>
    </div>
  )
})
