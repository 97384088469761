import {getUser} from "../../utils/store";
import {useEffect, useState} from "react";
import {TEAM_COLLECTION} from "../../utils/constants";
import {useCollection} from "@nandorojo/swr-firestore";

export const useActivateTeam = () => {
  const user = getUser() || {uid: null};
  const [activateTeams, setActivateTeams] = useState([] as any[]);

  const { data: activateTeamsDocs } = useCollection<any>(TEAM_COLLECTION, {
    where: [
      [`members.${user.uid}`, '!=', null],
    ],
    listen: true,
  });

  useEffect(() => {
    const validTeams = activateTeamsDocs?.filter(team => {
      if(!team.subscriptionPeriodUntil) return false;
      return new Date(team.subscriptionPeriodUntil.toMillis()) > new Date();
    })

    setActivateTeams(validTeams as any[]);
    // console.log("validTeams", validTeams);
  }, [activateTeamsDocs]);

  return{
    activateTeams
  }
}
