import {
  Button,
  majorScale,
  minorScale,
  Pane,
  Paragraph,
  PeopleIcon,
  Popover,
  Position,
  Text,
  Tooltip,
  TrashIcon
} from "evergreen-ui";
import styles from "./KeyResult.module.scss";
import React, {useEffect, useReducer, useRef, useState} from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import {DatePicker, Slider} from "antd";
import _ from "lodash"

import './../Task/antd.css';
import 'antd/lib/date-picker/style/index.css';
import 'antd/lib/slider/style/index.css';
import 'antd/lib/tag/style/index.css';
import 'antd/lib/button/style/index.css';
import './../Task/TaskItem.scss';
import {isToday} from "../../utils/datetime";
import AssigneeAvatar from "../Task/AssigneeAvatar";
import AssigneesPanel from "../Task/AssigneesPanel";
import {useDrag} from "react-dnd";

type Props = {
  result: {
    id?: string,
    title?: string,
    description?: string,
    dueDate?: any,
    progress?: number,
    assignees?: any,
  },
  members?: any,
  saveResult?: Function,
  setResultInfo?: Function,
  onSelect?: Function,
  isSelected?: boolean,
  onDeleteResult?: Function,
}

const KeyResultInput = ({result, members, saveResult, setResultInfo, onDeleteResult}: Props) => {
  const textTitleRef = useRef(null);
  const textDescriptionRef = useRef(null);
  const [today] = useState(new Date());
  const [progressBar, setProgressbar] = useState(result?.progress || 0);
  const [assignees, setAssignees] = useState(result?.assignees || {});

  const updateState = (state: any, updateState: any) => {
    return {...state, ...updateState};
  }
  const [submitData, dispatchSubmitData] = useReducer(updateState, {...(result || {})});

  const textareaWithoutScroll = (el: any) => {
    setTimeout(() => {
      const currentEl = el?.current as any;
      if(currentEl){
        currentEl.style.height = currentEl.scrollHeight + "px";
      }
    }, 10);
  }

  useEffect(() => {
    setResultInfo && setResultInfo(submitData);
  }, [submitData])

  useEffect(() => {
    setTimeout(() => {
      const currentEl = textTitleRef?.current as any;
      if(currentEl){
        currentEl.style.height = "20px";
      }
    }, 1);
  }, []);

  useEffect(() => {
    textareaWithoutScroll(textTitleRef);
    textareaWithoutScroll(textDescriptionRef);
  }, [textTitleRef]);

  const onTitleChange = (val: string) => {
    dispatchSubmitData({title: val});
    textareaWithoutScroll(textTitleRef);
  }

  const onDescriptionChange = (val: string) => {
    dispatchSubmitData({description: val});
    textareaWithoutScroll(textDescriptionRef);
  }

  const onDueDate = (date: any, dateStr: string) => {
    const dueDate = new Date(date);
    dispatchSubmitData({dueDate: dueDate.getTime()});
  }

  const onProgressBar = (value: number) => {
    setProgressbar(value);
    dispatchSubmitData({progress: value});
  }

  const displayDate = (date: any) => {
    if(isToday(date, today)) return "Today";

    return  dayjs(new Date(date)).format('MMM DD, YYYY');
  }

  const onAssignComplete = (data: any) => {
    setAssignees(data);
    dispatchSubmitData({assignees: data});
  }

  const disabledDate = (current : any) => {
    const date = new Date();
    date.setHours(0, 0, 0);
    return current && current < date;
  }

  const onInputKeyUp = (e: any) => {
    if(e.code === "Enter"){
      saveResult && saveResult();
    }
  }

  const onInputProgress = (value: number) => {
    let validValue = value < 0 ? 0 : value;
    validValue = value > 100 ? 100 : value;

    setProgressbar(validValue);
    dispatchSubmitData({progress: validValue});
  }

  // eslint-disable-next-line
  const [skipped, resultInputDrag] = useDrag(() => ({
    type: 'RESULT_INPUT',
    canDrag: (monitor) => {
      console.log("resultInputDrag monitor ", monitor);
      return true;
    },
  }), [result]);


  return (
    <>
      <Pane
        cursor={"pointer"}
        paddingX={majorScale(2)}
        paddingY={majorScale(2)}
        borderRadius="4px"
        border="1px solid #D8DAE5"
        className={clsx(styles.inputTask)}
        ref={resultInputDrag}
      >
        <Pane
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Pane display={"flex"} width="100%">
            <Pane
              display={"flex"}
              alignContent={"center"}
              width="100%"
              className={clsx(styles.inputText)}
            >
              <input
                placeholder={"New Task"}
                color={"#101840"}
                defaultValue={result.title}
                className={clsx(styles.descriptionArea)}
                autoFocus={true}
                onChange={(event: any) => onTitleChange(event.target.value)}
                onKeyPress={onInputKeyUp}
                ref={textTitleRef}
              />
            </Pane>
          </Pane>
          <Pane minWidth={"max-content"} display={"flex"}>
            <Pane display={"flex"} minWidth={"max-content"}>
              {!_.isEmpty(assignees) &&
                Object.values(assignees || {}).map((assignee: any) => (
                  <Pane marginLeft={majorScale(1)} display={"grid"}>
                    <AssigneeAvatar assignee={assignee} members={members} size={majorScale(3)}/>
                  </Pane>
                ))
              }
            </Pane>
            {submitData.dueDate &&
              <Pane display={"grid"} minWidth={"max-content"} height="32px" marginLeft={majorScale(1)}>
                <Text fontSize={12} color="#696F8C" alignSelf={"center"}>{displayDate(submitData.dueDate)}</Text>
              </Pane>
            }
            <Pane paddingLeft={majorScale(2)} display={"grid"}>
              <Text
                fontSize={11}
                height={18}
                width={36}
                backgroundColor={"gray"}
                paddingX={6}
                borderRadius={4}
                alignSelf={"center"}
                background={(progressBar || 0) < 40 ? "#F9DADA" : (progressBar || 0) < 70 ? "#FFEFD2" : "#DCF2EA"}
                color={(progressBar || 0) < 40 ? "#7D2828" : (progressBar || 0) < 70 ? "#66460D" : "#317159"}
              >{(progressBar || 0) + "%"}</Text>
            </Pane>
          </Pane>
        </Pane>
        <Pane marginTop={minorScale(1)}>
          <Pane display={"flex"} alignContent={"center"} className={clsx(styles.inputText)}>
            <textarea
              placeholder={"Notes"}
              className={styles.descriptionArea}
              defaultValue={result?.description}
              onChange={(event: any) => onDescriptionChange(event.target.value)}
              ref={textDescriptionRef}
            />
          </Pane>
          <Pane display={"flex"} paddingTop={majorScale(2)}>
            <Pane>
              <Tooltip
                content={(
                  <Paragraph
                    style={{
                      color: 'white',
                      fontSize: '12px',
                      lineHeight: '16px'
                    }}
                  >
                    Delete Key Result
                  </Paragraph>
                )}
                position={Position.BOTTOM}
              >
                <Pane
                  height={majorScale(3)}
                  width={majorScale(3)}
                  className={clsx(styles.deleteBtn, "border-lighten-gray")}
                  borderRadius="3px"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => onDeleteResult && onDeleteResult(result)}
                >
                  <TrashIcon size={12} color={"#D14343"}/>
                </Pane>
              </Tooltip>
            </Pane>
            <Pane width="24px" height="24px" marginX={majorScale(2)}>
              <DatePicker className="dueDatePicker" onChange={onDueDate} disabledDate={disabledDate}/>
            </Pane>
            {!_.isEmpty(members) &&
              <Popover
                position={Position.RIGHT}
                content={({ close }) => (
                  <AssigneesPanel
                    members={members}
                    close={close}
                    onAssignComplete={onAssignComplete}
                    assignees={result?.assignees || {}}
                  />
                )}
              >
                <Button
                  size="small"
                  color="#3366FF"
                  border={"1px solid #D8DAE5"}
                >
                  <PeopleIcon size={11}/>
                  <Text fontWeight={500} marginLeft={majorScale(1)} color="#3366FF" fontSize="12px">Assign</Text>
                </Button>
              </Popover>
            }
            <Pane className={clsx(styles.sliderWrap)}>
              <Slider
                min={1}
                max={100}
                onChange={(val) => onProgressBar(val)}
                value={progressBar}
                handleStyle={{width: "10px", height: "10px"}}
                className={clsx(styles.slider)}
              />
            </Pane>
            <Pane
              marginLeft={majorScale(2)}
              className={clsx(styles.progress)}
            >
              <Pane>
                <input
                  defaultValue={progressBar}
                  value={progressBar}
                  type={"number"}
                  min={0}
                  max={100}
                  className={styles.inputProgress}
                  onChange={(e: any) => onInputProgress(e.target.value)}
                />
              </Pane>
              <Text className={styles.percent}>%</Text>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
    </>
  )
}

export default KeyResultInput;
