import { useEffect, useState } from 'react';
import useScript from './useScript';

const MINDMAP_BASE_FOLDER = '/mindplot'

function scriptFile(fileName) {
  const isDev = process.env.NODE_ENV !== 'production';
  const name = isDev ? fileName : fileName.replace('.js', '.min.js').replace('.min.min.js', '.min.js');
  return `${MINDMAP_BASE_FOLDER}/${name}`;
}

function useLoad() {
  const [isMxScriptReady, setMxScriptReady] = useState(false);
  useScript(scriptFile('hammer.min.js'))
  useScript(scriptFile('emoji-button/emoji-button-2.8.0.min.js'))
  useScript(scriptFile('editor/jquery.js'));
  useScript(scriptFile('editor/jquery-mousewheel.js'));
  useScript(scriptFile('editor/hotkeys.js'))
  useScript(scriptFile('editor/bootstrap.js'));
  useScript(scriptFile('editor/underscore.js'));
  useScript(scriptFile('editor/mootools-core.js'));
  useScript(scriptFile('editor/core.js'));
  useScript(scriptFile('ThemeHelper.js'));
  useScript(scriptFile('editor/wrap.js'));
  useScript(scriptFile('editor/constants.js'));
  useScript(scriptFile('editor/editor.js'));
  useScript(scriptFile('header.js'));
  useScript(scriptFile('Events.js'));
  useScript(scriptFile('Options.js'));
  useScript(scriptFile('web2d/target/classes/web2d.svg-min.js'));
  useScript(scriptFile('Messages.js'));
  useScript(scriptFile('TopicEventDispatcher.js'));
  useScript(scriptFile('model/IMindmap.js'));
  useScript(scriptFile('model/Mindmap.js'));
  useScript(scriptFile('model/INodeModel.js'));
  useScript(scriptFile('model/NodeModel.js'));
  useScript(scriptFile('model/RelationshipModel.js'));
  useScript(scriptFile('ActionDispatcher.js'));
  useScript(scriptFile('StandaloneActionDispatcher.js'));
  useScript(scriptFile('DesignerModel.js'));
  useScript(scriptFile('Designer.js'));
  useScript(scriptFile('ScreenManager.js'));
  useScript(scriptFile('Workspace.js'));
  useScript(scriptFile('ShrinkConnector.js'));
  useScript(scriptFile('Keyboard.js'));
  useScript(scriptFile('DesignerKeyboard.js'));
  useScript(scriptFile('Keyboard.js'));
  useScript(scriptFile('TopicStyle.js'));
  useScript(scriptFile('NodeGraph.js'));
  useScript(scriptFile('Topic.js'));
  useScript(scriptFile('CentralTopic.js'));
  useScript(scriptFile('MainTopic.js'));
  useScript(scriptFile('DragTopic.js'));
  useScript(scriptFile('DragManager.js'));
  useScript(scriptFile('DragPivot.js'));
  useScript(scriptFile('ConnectionLine.js'));
  useScript(scriptFile('Relationship.js'));
  useScript(scriptFile('DragConnector.js'));
  useScript(scriptFile('TextEditor.js'));
  useScript(scriptFile('MultilineTextEditor.js'));
  useScript(scriptFile('TextEditorFactory.js'));
  useScript(scriptFile('util/Shape.js'));
  useScript(scriptFile('util/FadeEffect.js'));
  useScript(scriptFile('util/Export.js'));
  useScript(scriptFile('persistence/ModelCodeName.js'));
  useScript(scriptFile('persistence/XMLSerializer_Pela.js'));
  useScript(scriptFile('persistence/XMLSerializer_Tango.js'));
  useScript(scriptFile('persistence/Pela2TangoMigrator.js'));
  useScript(scriptFile('persistence/XMLSerializer_Beta.js'));
  useScript(scriptFile('persistence/Beta2PelaMigrator.js'));
  useScript(scriptFile('persistence/XMLSerializerFactory.js'));
  useScript(scriptFile('PersistenceManager.js'));
  useScript(scriptFile('APIPersistenceManager.js'));
  useScript(scriptFile('RestPersistenceManager.js'));
  useScript(scriptFile('LocalStorageManager.js'));
  useScript(scriptFile('EditorProperties.js'));
  useScript(scriptFile('IconGroup.js'));
  useScript(scriptFile('Icon.js'));
  useScript(scriptFile('LinkIcon.js'));
  useScript(scriptFile('NoteIcon.js'));
  useScript(scriptFile('ActionIcon.js'));
  useScript(scriptFile('ImageIcon.js'));
  useScript(scriptFile('model/FeatureModel.js'));
  useScript(scriptFile('model/IconModel.js'));
  useScript(scriptFile('model/LinkModel.js'));
  useScript(scriptFile('model/NoteModel.js'));
  useScript(scriptFile('Command.js'));
  useScript(scriptFile('DesignerActionRunner.js'));
  useScript(scriptFile('DesignerUndoManager.js'));
  useScript(scriptFile('ControlPoint.js'));
  useScript(scriptFile('EditorOptions.js'));
  useScript(scriptFile('RelationshipPivot.js'));
  useScript(scriptFile('TopicFeature.js'));
  useScript(scriptFile('commands/GenericFunctionCommand.js'));
  useScript(scriptFile('commands/DeleteCommand.js'));
  useScript(scriptFile('commands/DragTopicCommand.js'));
  useScript(scriptFile('commands/AddTopicCommand.js'));
  useScript(scriptFile('commands/ChangeFeatureToTopicCommand.js'));
  useScript(scriptFile('commands/RemoveFeatureFromTopicCommand.js'));
  useScript(scriptFile('commands/AddFeatureToTopicCommand.js'));
  useScript(scriptFile('commands/AddRelationshipCommand.js'));
  useScript(scriptFile('commands/MoveControlPointCommand.js'));
  useScript(scriptFile('widget/ModalDialogNotifier.js'));
  useScript(scriptFile('widget/ToolbarNotifier.js'));
  useScript(scriptFile('widget/ToolbarItem.js'));
  useScript(scriptFile('widget/ToolbarPaneItem.js'));
  useScript(scriptFile('widget/NoteEditor.js'));
  useScript(scriptFile('widget/LinkEditor.js'));
  useScript(scriptFile('widget/FloatingTip.js'));
  useScript(scriptFile('widget/LinkIconTooltip.js'));
  useScript(scriptFile('widget/KeyboardShortcutTooltip.js'));
  useScript(scriptFile('widget/ColorPalettePanel.js'));
  useScript(scriptFile('widget/ListToolbarPanel.js'));
  useScript(scriptFile('widget/FontFamilyPanel.js'));
  useScript(scriptFile('widget/FontSizePanel.js'));
  useScript(scriptFile('widget/TopicShapePanel.js'));
  useScript(scriptFile('widget/IMenu.js'));
  useScript(scriptFile('widget/Menu.js'));
  useScript(scriptFile('TopicFeature.js'));
  useScript(scriptFile('layout/EventBusDispatcher.js'));
  useScript(scriptFile('layout/ChangeEvent.js'));
  useScript(scriptFile('layout/LayoutManager.js'));
  useScript(scriptFile('layout/Node.js'));
  useScript(scriptFile('layout/RootedTreeSet.js'));
  useScript(scriptFile('layout/ChildrenSorterStrategy.js'));
  useScript(scriptFile('layout/AbstractBasicSorter.js'));
  useScript(scriptFile('layout/BalancedSorter.js'));
  useScript(scriptFile('layout/SymmetricSorter.js'));
  useScript(scriptFile('layout/GridSorter.js'));
  useScript(scriptFile('layout/OriginalLayout.js'));
  useScript(scriptFile('layout/EventBus.js'));
  useScript(scriptFile('MessageBundle_en.js'));
  useScript(scriptFile('MessageBundle_es.js'));
  useScript(scriptFile('MessageBundle_de.js'));
  useScript(scriptFile('MessageBundle_fr.js'));
  useScript(scriptFile('MessageBundle_pt_BR.js'));
  useScript(scriptFile('MessageBundle_zh_CN.js'));
  useScript(scriptFile('MessageBundle_zh_TW.js'));
  useScript(scriptFile('MessageBundle_ca.js'));
  useScript(scriptFile('footer.js'));
  useScript(scriptFile('publish-helper.js'));
  const status = useScript(scriptFile('editor/init-mindmap.js'));
  useEffect(() => {
    if (status === 'ready') {
      setMxScriptReady(true);
    }
  }, [status]);

  return isMxScriptReady;
}

export default useLoad;
