import {DotIcon, majorScale, minorScale, Pane, Text} from "evergreen-ui";
import clsx from "clsx";
import styles from "./Page.module.scss";
import {useHistory, useLocation} from "react-router-dom";
import {useDrag, useDrop} from "react-dnd";
import Expand from "react-expand-animated";

type Props = {
  id: string,
  name: string,
  dragAble?: boolean,
  onDragPage?: Function
}

const Page = (page: Props) => {
  const {id, name, dragAble, onDragPage} = page;
  const location = useLocation();
  const history = useHistory();
  const isSelected = location.pathname.includes(`/${id}`);

  const onPageClick = () => {
    history.push({
      pathname: '/okr/' + id,
    })
  }

  const [{ isPageDragging }, dragPageRef] = useDrag(() => ({
    type: 'PAGE',
    item: {
      type: 'PAGE',
      page: page
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },
    canDrag: (monitor) => {
      return !!dragAble;
    },
    collect: (monitor) => ({
      isPageDragging: monitor.isDragging(),
    })
  }), [page]);

  const [{ isDropPageOver }, dropPageRef] = useDrop(() => ({
    accept: ["PAGE"],
    collect: (monitor) => ({
      isDropPageOver: monitor.isOver() && !!dragAble
    }),
    canDrop: (item: any, monitor) => {
      return !!dragAble;
    },
    drop: async (item: any, monitor)  => {
      onDragPage && onDragPage(item.page, page);
    }
  }), [page]);

  const onPageRef = (ref: any) => {
    dropPageRef(ref);
    dragPageRef(ref);
  }

  return (
    <>
      <Pane
        display={isPageDragging ? "none" : "flex"}
        justifyContent={"space-between"}
        padding={minorScale(1)}
        paddingLeft={majorScale(2)}
        marginY="2px"
        cursor={"pointer"}
        onClick={onPageClick}
        className={clsx(isSelected && styles.selected)}
        ref={onPageRef}
      >
        <Pane display={"flex"}>
          <Pane display={"flex"} paddingRight={majorScale(2)}><DotIcon color="#8F95B2" alignSelf={"center"}/></Pane>
          <Pane display={"flex"} width="195px" justifyContent={"space-between"}>
            <Text className={clsx('text-truncate', 'page-label')}>{name}</Text>
          </Pane>
        </Pane>
      </Pane>
      <Expand
        duration={200}
        open={isDropPageOver}
        transitions={["height", "opacity"]}
      >
        <Pane display={isDropPageOver && !!dragAble ? "flex" : "none"} className={clsx(styles.previewDropPosition)}></Pane>
      </Expand>
    </>
  )
}

export default Page;
