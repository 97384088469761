import {Dialog} from "../../../components/Dialog";
import {Pane} from "evergreen-ui";
import {useState} from "react";
import {PageService} from "../../../services/page/page.service";

type Props = {
  page: any,
  isShown: boolean,
  setIsShown: Function,
  successCallback: Function,
}

const pageService = new PageService();

const DeletePageModal = ({page, isShown, setIsShown, successCallback}: Props ) => {
  const [isDeleting, setDeleting] = useState(false);

  const onDeleteProject = ()  => {
    setDeleting(true);
    const softDelete = page.isSample;
    if(softDelete){
      pageService.softDeletePage(page.id)
        .then(() => {
          setIsShown(true);
          successCallback && successCallback();
        })
        .finally(() => {
          setDeleting(false);
        })
    }
    else{
      pageService.deletePage(page.id)
        .then(() => {
          setIsShown(true);
          successCallback && successCallback();
        })
        .finally(() => {
          setDeleting(false);
        })
    }
  }

  return (
    <Dialog
      isShown={isShown}
      title="Delete OKR Page"
      onCloseComplete={() => setIsShown && setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Delete"
      intent="danger"
      onConfirm={onDeleteProject}
      isConfirmLoading={isDeleting}
    >
      <Pane>Are you sure to delete OKR page?</Pane>
    </Dialog>
  )
}

export default DeletePageModal;
