import { ComponentPanel } from './ComponentPanel';
import { ZwfConstants } from './ZwfConstants';

function AccessorPanel(shapeType) {
  const shapeKey = shapeType || 'default';
  this.abilities = ComponentPanel[shapeKey] ? ComponentPanel[shapeKey] : ComponentPanel['default'];
}

AccessorPanel.prototype.canVisibleAll = function() {
  return this.abilities.includes(ZwfConstants.PANEL_ALL);
}

AccessorPanel.prototype.isPanelVisible = function(panel) {
  if (this.canVisibleAll()) return true;
  return this.abilities.includes(panel);
}

AccessorPanel.prototype.isActionVisible = function(action) {
  if (this.canVisibleAll()) return true;
  return this.abilities.includes(action);
}

export default AccessorPanel;
