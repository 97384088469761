import clsx from 'clsx';
import {
  Button,
  CaretDownIcon,
  Image,
  majorScale,
  Menu,
  minorScale,
  Pane,
  Popover,
  Position,
  Text,
  MinusIcon,
  SmallPlusIcon,
} from 'evergreen-ui';
import { useState, useReducer, useEffect, useContext } from 'react';
import { Observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import styles from './Menubar.module.scss';
import logo from 'assets/img/logo.svg';
import './MenuBar.css';
import { truncate } from 'utils/helpers';
import OnlineUsers from './OnlineUsers';
import useStores from 'hooks/useStores';
import ShareDocumentModal from 'components/Documents/ShareDocumentModal';
import { getUser } from 'utils/store';
import { PermissonType } from 'schemas/role';
import { isHavePermission } from 'services/user';
import { ActionState } from '../Toolbar/Binding';
import ActionStore from 'store/ActionStore';
import { EditorContext } from '../../../../context/EditorContext';

const MenubarViewer = ({
  editorUi,
  documentName,
  unreadComments,
  folder
}) => {
  const { convergenceStore, graphStore } = useStores();
  const [isShowShare, setShowShare] = useState(false);
  const [actionState, dispatchActionState] = useReducer(ActionStore, {});
  const [currentZoom, setCurrentZoom] = useState(100);
  const { isCommentBarVisible, toggleCommentBar } = useContext(EditorContext);
  const zoomRatios = [0.2, 0.5, 0.7, 1.0, 1.5, 2.0];

  const user = getUser();

  const zoomTo = offset => {
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    const zoomTo = clamp(currentZoom + offset, 20, 200);
    getToolbarAction('zoomTo').funct(zoomTo / 100);
  };

  const getToolbarAction = key => {
    return actionState[key] || {};
  };

  const getAction = key => {
    try {
      const actions = graphStore.editorUi.actions;
      return actions.get(key).funct || function () {};
    } catch (ex) {
      console.log('Menu bar getAction throw ex', ex);
    }
  };

  const resetGraphView = () => {
    const scale = editorUi?.current.editor.graph.view.scale || 1.0;
    setCurrentZoom(Math.trunc(scale * 100));
  };

  useEffect(() => {
    let editor = null;
    let actions = null;
    try {
      editor = editorUi.current.editor;
      actions = editorUi.current.actions;
    } catch (err) {
      return;
    }

    Object.keys(ActionState).forEach(key => {
      const action = actions.get(key);
      if (!action) return;
      const initState = {};
      initState[key] = action;
      dispatchActionState({ updateProperty: initState });

      const stateChanged = () => {
        const updateState = {};
        updateState[key] = action;
        dispatchActionState({ updateProperty: updateState });
      };

      action.addListener('stateChanged', stateChanged);
    });

    editor.addListener('resetGraphView', resetGraphView);
    editor.graph?.view?.addListener('scale', resetGraphView);

    return () => {
      Object.keys(ActionState).forEach(key => {
        const action = actions.get(key);
        if (!action) return;

        const stateChanged = () => {
          const updateState = {};
          updateState[key] = action;
          dispatchActionState({ updateProperty: updateState });
        };

        action.removeListener('stateChanged', stateChanged);
      });
      editor.removeListener('resetGraphView', resetGraphView);
      editor.graph?.view?.removeListener('scale', resetGraphView);
    };
  }, []);

  return (
    <Pane
      height={majorScale(6)}
      display="flex"
      borderBottom="1px solid"
      borderBottomColor="#D8DAE5"
      paddingX={majorScale(2)}
    >
      <Pane flex={1} display="flex" alignItems={'center'}>
        <Link to="/app">
          <Image src={logo} width={minorScale(5)} alt="logo" />
        </Link>
        <Pane className={styles.titleWidget}>
          <Pane className={clsx(styles.titleLabel)}>
            <Text marginX={majorScale(2)} className={styles.titleLabelInner}>
              {truncate(documentName, 120)}
            </Text>
          </Pane>
        </Pane>
      </Pane>
      <Pane display={'flex'} alignItems={'center'} className="zwfMenubarViewMobile">
        <Pane marginRight={majorScale(2)}>
          <Observer>
            {() => (
              <OnlineUsers
                activeUsers={Object.values(convergenceStore.sessions)}
              />
            )}
          </Observer>
        </Pane>

        {isHavePermission(
          user,
          graphStore.document,
          PermissonType.CommentDoc,
          folder
        ) && (
          <Button
            height={majorScale(3)}
            marginRight={majorScale(1)}
            className={clsx(
              'border-lighten-gray',
              styles.commentBarWidget,
              styles.commentBarViewer,
              isCommentBarVisible && styles.commentBarVisible
            )}
            onClick={toggleCommentBar}
          >
            <Pane marginRight={majorScale(1)}>Comment</Pane>
            <Pane
              borderRadius={minorScale(1)}
              backgroundColor="#D6E0FF"
              maxHeight={minorScale(4)}
              minWidth={minorScale(5)}
              display="flex"
              justifyContent={'center'}
            >
              <Text fontSize={11} color="#2952CC" alignSelf={'center'}>
                {unreadComments}
              </Text>
            </Pane>
          </Button>
        )}

        {isHavePermission(
          user,
          graphStore.document,
          PermissonType.ExportDoc,
        ) && (
          <Popover
            position={Position.BOTTOM_LEFT}
            content={({ close }) => (
              <Pane onClick={close} minWidth={140}>
                <Menu>
                  <Menu.Item
                    paddingX={0}
                    onClick={() =>
                      getAction('export')('zen_wireframe', 'jpg', false)
                    }
                  >
                    <Text fontSize={12}>Export as JPG</Text>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    paddingX={0}
                    onClick={() =>
                      getAction('export')('zen_wireframe', 'png', true)
                    }
                  >
                    <Text fontSize={12}>Export as PNG</Text>
                  </Menu.Item>
                </Menu>
              </Pane>
            )}
          >
            <Button
              height={majorScale(3)}
              marginRight={majorScale(1)}
              className="border-lighten-gray"
            >
              Export
              <CaretDownIcon size={12} className="ml-2" color="#8F95B2" />
            </Button>
          </Popover>
        )}

        <Pane marginRight={majorScale(2)} className="on-one-line">
          <Button
            height={majorScale(4)}
            width={majorScale(4)}
            paddingX={'auto'}
            disabled={!getToolbarAction('zoomOut').enabled}
            onClick={() => zoomTo(-10)}
            className="border-lighten-gray"
          >
            <MinusIcon size={12} color="#474D66" />
          </Button>
          <Popover
            position={Position.BOTTOM_LEFT}
            minWidth={150}
            content={({ close }) => (
              <Pane onClick={close}>
                <Menu>
                  <Menu.Item
                    paddingX={0}
                    onClick={getToolbarAction('fitWindow').funct}
                  >
                    <Text fontSize={12}>Zoom to Content</Text>
                  </Menu.Item>
                  {zoomRatios.map((ratio, index) => (
                    <Pane key={index}>
                      <Menu.Divider />
                      <Menu.Item
                        paddingX={0}
                        display={'flex'}
                        alignItems={'center'}
                        onClick={() => getToolbarAction('zoomTo').funct(ratio)}
                      >
                        <Text fontSize={12}>{ratio * 100}%</Text>
                      </Menu.Item>
                    </Pane>
                  ))}
                </Menu>
              </Pane>
            )}
          >
            <Button
              height={majorScale(4)}
              paddingX={majorScale(2)}
              paddingY={majorScale(1)}
              marginX={minorScale(1)}
              minWidth={90}
              className="border-lighten-gray"
            >
              {currentZoom}%
              <CaretDownIcon size={12} color="#474D66" className="ml-2" />
            </Button>
          </Popover>
          <Button
            height={majorScale(4)}
            width={majorScale(4)}
            paddingX={'auto'}
            disabled={!getToolbarAction('zoomIn').enabled}
            onClick={() => zoomTo(10)}
            className="border-lighten-gray"
          >
            <SmallPlusIcon size={16} color="#474D66" />
          </Button>
        </Pane>

        <Link to="/sign-up">
          <Button height={majorScale(4)} appearance="primary">
            Sign Up
          </Button>
        </Link>
      </Pane>
      <ShareDocumentModal
        isMindMap={false}
        isShown={isShowShare}
        setIsShown={setShowShare}
        doc={graphStore.document}
      />
    </Pane>
  );
};

MenubarViewer.defaultProps = {
  unreadComments: 0,
};

export default MenubarViewer;
