import {DotIcon, majorScale, minorScale, Pane, PlusIcon, Position, Text, Tooltip} from "evergreen-ui";
import clsx from "clsx";
import styles from "./Projects.module.scss";
import Project from "./Project";
import React, {useEffect, useState} from "react";
import {ProjectService} from "../../../services/project/project.service";
import {useDrop} from "react-dnd";
import {getOrdinalProjects} from "./TaskUtils";
import {getOwnerProjects, isFreeUser} from "../../../utils/store";
import {FREE_USER_LIMIT_PROJECTS} from "../../../utils/constants";
import ProFeatureModal from "../../../components/Modals/ProFeatureModal/ProFeatureModal";
import {UpgradeToProModal} from "../../../components/Modals";

const projectService = new ProjectService();

type Props = {
  title: string,
  projects?: any[],
  isAddAble?: boolean,
  projectCount?: number
}

const Projects = ({title, projects, isAddAble = false, projectCount}: Props) => {
  const [newProject, setNewProject] = useState(false);
  const [projectName, setProjectName] = useState("New Project");
  const [needHideNewProject, setNeedHideNewProject] = useState(false);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const IS_PROJECTS_LOADING = -1;

  const onNewProject = () => {
    const isLimitByFreeUser = isFreeUser() && (projectCount || 0) >= FREE_USER_LIMIT_PROJECTS;
    if(isLimitByFreeUser){
      setShowProFeature(true);
      return;
    }

    setProjectName("New Project");
    setNewProject(true);
  }

  const createNewProject = (name: string) => {
    projectService.create({name})
      .catch(ex => {
        console.log("on create project exception ex = ", ex.message);
      });

    setNewProject(false);
  }

  const onBlurProjectInput = () => {
    createNewProject(projectName);
  }

  useEffect(() => {
    if(needHideNewProject){
      setNewProject(false);
      setNeedHideNewProject(false);
    }
  }, [projects]);

  const [{ isOver }, dropProjectRef] = useDrop(() => ({
    accept: ["PROJECT"],
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    canDrop: () => {
      return !!isAddAble;
    },
    drop: async (item: any, monitor)  => {
      const project = item.project;
      const updateProjects = getOrdinalProjects(project.id, null, getOwnerProjects());
      projectService.updateOrdinalProjects(updateProjects || []);
    }
  }));

  const onKeyPressNewProject = (e: any) => {
    if(e.code === "Enter"){
      createNewProject(projectName);
    }
  }

  return (
    <>
      <Pane
        display={"flex"}
        justifyContent={"space-between"}
        paddingTop={majorScale(3)}
        paddingLeft={majorScale(2)}
        paddingBottom={majorScale(1)}
        ref={dropProjectRef}
      >
        <Pane>
          <Text fontSize={12} color="#474D66" fontWeight={600}>{title}</Text>
        </Pane>
        {isAddAble && projectCount !== IS_PROJECTS_LOADING &&
          <Tooltip
            content={(<Pane><Text fontSize={12} color={"white"}>New Project</Text></Pane>)}
            position={Position.RIGHT}
          >
            <Pane className={clsx(styles.addProjectBtn)}>
              <PlusIcon
              size={12}
              color="#8F95B2"
              alignSelf={"center"}
              alignItems={"center"}
              onClick={onNewProject}
              />
            </Pane>
          </Tooltip>
        }
      </Pane>
      <Pane display={isOver && !!isAddAble ? "flex" : "none"} className={clsx(styles.previewDropPosition)}></Pane>
      <Pane>
        {(projects || []).map((project) => (
          <Project key={(project.id)} {...project} dragAble={isAddAble}/>
        ))}
      </Pane>
      {newProject &&
        <Pane display={"flex"} padding={minorScale(1)} paddingLeft={majorScale(2)} height="32px">
          <Pane display={"flex"} paddingRight={majorScale(2)}><DotIcon color="#8F95B2" alignSelf={"center"}/></Pane>
          <input
            value={projectName}
            autoFocus={true}
            onBlur={onBlurProjectInput}
            onChange={e => setProjectName(e.target.value)}
            onKeyPress={onKeyPressNewProject}
            className={clsx(styles.inputProjectName)}
          />
        </Pane>
      }
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Free Limit Reached"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            Free accounts are limited to {FREE_USER_LIMIT_PROJECTS} projects. Upgrade to a
            <br/>paid plan for unlimited access and more!
          </Pane>)}
      />
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} />
    </>
  )
}

export default Projects;
