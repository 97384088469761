import {CrossIcon, majorScale, minorScale, Pane, Text, TickIcon} from "evergreen-ui";
import styles from "./AssigneesPanel.module.scss"
import clsx from "clsx";
import {TaskService} from "../../services/task/task.service";
import _ from "lodash";
import AssigneeAvatar from "./AssigneeAvatar";

type Props = {
  members: any,
  assignees?: any,
  close: any,
  taskId?: string,
  onAssignComplete?: Function,
  onAssigneeChange?: Function
}

const taskService = new TaskService();

const AssigneesPanel = ({members, assignees, close, taskId, onAssignComplete}: Props) => {
  const formatDisplayName = (assignee: any) => {
    return [assignee.firstName, assignee.lastName].filter(item => !!item).join(' ') || assignee.email;
  }

  const onAssign = async (assignees: any, assignee: any) => {
    const isEditTask = !!taskId;
    if(isEditTask){
      const submitAssignees = assignees || {};
      const assigneeIds = Object.keys(submitAssignees);
      const isUnAssign = assigneeIds.includes(assignee.id);
      if(isUnAssign){
        delete submitAssignees[assignee.id];
      }
      else{
        submitAssignees[assignee.id] = {...assignee, addedAt: (new Date()).getTime()};
      }

      taskService.assign(taskId || "", submitAssignees)
        .then(() => {
          onAssignComplete && onAssignComplete(submitAssignees);
        })
        .catch(ex => {
          console.log("assign member throw ex", ex.message)
        });
    }
    else {
      const assigneeIds = Object.keys(assignees);
      const isUnAssign = assigneeIds.includes(assignee.id);
      if(isUnAssign){
        delete assignees[assignee.id];
      }
      else{
        assignees[assignee.id] = {...assignee, addedAt: (new Date()).getTime()};
      }

      onAssignComplete && onAssignComplete(assignees);
    }

    close && close();
  }

  return (
    <Pane className={clsx(styles.panel, "assignees-panel")}>
      <Pane display={"flex"} justifyContent={"space-between"} paddingBottom={majorScale(3)}>
        <Text className={clsx(styles.title)}>Assign Task</Text>
        <CrossIcon onClick={() => close && close()} alignSelf={"center"} color="#8F95B2" size={14} cursor={"pointer"}/>
      </Pane>
      {
        Object.values(members || {}).map((assignee: any) => (
          <Pane
            key={assignee.id}
            display={"flex"}
            justifyContent={"space-between"}
            paddingY={minorScale(1)}
            cursor={"pointer"}
            onClick={() => onAssign(assignees, assignee)}
          >
            <Pane display={"flex"} alignItems={"center"}>
              <Pane marginRight={majorScale(1)}>
                <AssigneeAvatar assignee={assignee} members={members} size={majorScale(4)}/>
              </Pane>
              <Text>{formatDisplayName(assignee)}</Text>
            </Pane>
            <Pane display={"flex"} alignItems={"center"}>
              {!_.isEmpty(assignees) && Object.keys(assignees).includes(assignee.id) &&
                <TickIcon color="#8F95B2"/>
              }
            </Pane>
          </Pane>
        ))
      }
    </Pane>
  )
}

export default AssigneesPanel;
