import {DoubleCaretVerticalIcon, Table as EvTable} from 'evergreen-ui';
import styles from './Table.module.scss';
import {MouseEventHandler, ReactNode} from 'react';

import clsx from 'clsx';

type Props = {
  children?: ReactNode;
  className?: any;
  style?: any;
  width? : string | number | undefined;
}

type HeaderProps = {
  children?: ReactNode
  onSort?: MouseEventHandler
  width?: number | string | undefined
  className?: string | undefined
}

export const Table = ({ children }: Props) => (
  <EvTable className={styles.table}>
    {children}
  </EvTable>
);

export const TableHead = ({ children }: Props) => (
  <EvTable.Head className={clsx(styles.tableHead)}>
    {children}
  </EvTable.Head>
);

export const TableHeaderCell = ({ children, onSort, className }: HeaderProps) => (
  <EvTable.TextHeaderCell cursor="pointer" onClick={onSort} className={className}>
    {children}
    <DoubleCaretVerticalIcon
      position="absolute"
      marginLeft="0.25rem"
      color="#8F95B2"
    />
  </EvTable.TextHeaderCell>
);

export const TableHeaderLastCell = () => (
  <EvTable.TextHeaderCell className={styles.tableHeaderLastCell} />
);

export const TableBody = ({ children }: Props) => (
  <EvTable.Body>
    {children}
  </EvTable.Body>
);


export const TableRow = ({ children }: Props) => (
  <EvTable.Row className={styles.tableRow}>
    {children}
  </EvTable.Row>
);

export const TableFirstCell = ({ children }: Props) => (
  <EvTable.TextCell textProps={{ size: 400 }} className={styles.tableFirstCell}>
    {children}
  </EvTable.TextCell>
);

export const TableCell = ({ children, ...props }: Props) => (
  <EvTable.TextCell textProps={{ size: 400 }} className={clsx(styles.tableCell, props.className)}>
    {children}
  </EvTable.TextCell>
);

export const TableLastCell = ({ children, ...props }: Props) => (
  <EvTable.Cell {...props} className={clsx('justify-content-end pr-3', props.className)}>
    {children}
  </EvTable.Cell>
);
