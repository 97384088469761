import clsx from 'clsx';
import {
  Button,
  CaretDownIcon,
  HistoryIcon,
  Image,
  majorScale,
  Menu,
  minorScale,
  Pane,
  Popover,
  Position,
  RefreshIcon,
  ShareIcon,
  Text,
  TickIcon,
  WarningSignIcon,
} from 'evergreen-ui';
import AutosizeInput from 'react-input-autosize';
import React, { useContext, useState } from 'react';
import { Observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import styles from './Menubar.module.scss';
import logo from 'assets/img/logo.svg';
import './MenuBar.css';
import { truncate } from 'utils/helpers';
import OnlineUsers from './OnlineUsers';
import useStores from 'hooks/useStores';
import VersionModal from './History/VersionModal';
import useHistoryVersion from '../../hooks/useHistoryVersion';
import ShareDocumentModal from 'components/Documents/ShareDocumentModal';
import { getUser } from 'utils/store';
import { PermissonType } from 'schemas/role';
import { isHavePermission } from 'services/user';
import TooltipCustom from "../PopupMenu/Tooltip";
import { EditorContext } from '../../../../context/EditorContext';

const Menubar = ({
  editorUi,
  documentName,
  unreadComments,
  onChangeDocumentName,
  folder
}) => {
  const [input, setInput] = useState(documentName);
  const [time, setTime] = useState(Date.now());
  const [visible, setVisible] = useState(true);
  const { convergenceStore, graphStore } = useStores();
  const [modalHistoryVersion, setModalHistoryVersion] = useState(false);
  const [histories, setHistories] = useState({});
  const [currentVersion, setCurrentVersion] = useState({});
  const [isShowShare, setShowShare] = useState(false);
  const { isMindMap, isCommentBarVisible, toggleCommentBar, mindplot, documentNode } = useContext(EditorContext);
  const user = getUser();

  useHistoryVersion({
    editorUi,
    histories,
    setHistories,
    setSaving: graphStore.setIsSaving,
  });

  const getAction = key => {
    try {
      const actions = graphStore.editorUi.actions;
      return actions.get(key).funct || function () {};
    } catch (ex) {
      console.log('Menu bar getAction throw ex', ex);
    }
  };

  const handleToggle = () => {
    setVisible(!visible);
  };

  const handleBlur = () => {
    handleToggle();
    if (input === '') {
      setInput('Untitled document');
      onChangeDocumentName('Untitled document');
    } else {
      onChangeDocumentName(input);
    }
  };

  const handleChange = event => {
    setInput(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) {
      setTime(Date.now());
      handleBlur();
    }
  };

  const handleClickExportAsPNG = () => {
    const documentContent = documentNode.querySelector('svg').cloneNode(true);
    mindplot.util.Export.toPNG(documentName, documentContent, true);
  }

  const handleClickExportAsJPG = () => {
    const documentContent = documentNode.querySelector('svg').cloneNode(true);
    mindplot.util.Export.toJPG(documentName, documentContent, true);
  }

  return (
    <Pane
      height={majorScale(5)}
      borderBottom="1px solid"
      borderBottomColor="#D8DAE5"
      paddingX={majorScale(2)}
    >
      <Pane width={"100%"} height={"100%"} display="flex" className={"zwfMenubar"}>
        <Pane flex={1} display="flex" alignItems={'center'}>
          {editorUi ? (
            <Link to="/app">
              <Image src={logo} width={minorScale(5)} alt="logo" />
            </Link>
          ) : (
            <a href="/app">
              <Image src={logo} width={minorScale(5)} alt="logo" />
            </a>
          )}

          <TooltipCustom
            activator={(
              <Pane className={styles.titleWidget}>
                <Pane className={clsx(styles.titleLabel, !visible && 'invisible')}>
                  <Text marginX={majorScale(2)} className={styles.titleLabelInner}>
                    {truncate(input)}
                  </Text>
                </Pane>
                <AutosizeInput
                  key={time}
                  name="form-field-name"
                  className={styles.titleInput}
                  value={input}
                  onChange={handleChange}
                  onFocus={handleToggle}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                />
              </Pane>
            )}
            text="Rename"
            position={Position.BOTTOM}
          />

          {isHavePermission(user, graphStore.document, PermissonType.EditDoc, folder) && (
            <>
              <Observer>
                {() => {
                  if (!graphStore.isOnline) {
                    return (
                      <Button
                        height={majorScale(3)}
                        className="border-lighten-gray mr-1"
                      >
                        <Pane display="flex" justifyContent="center">
                          <WarningSignIcon
                            size={12}
                            className="mr-2 mt-1"
                            color="#8F95B2"
                            fontSize="12"
                          />
                          <Text fontSize="12" fontWeight="500">
                            Offline. Changes are not saved.
                          </Text>
                        </Pane>
                      </Button>
                    );
                  } else {
                    return (
                      <Button
                        height={majorScale(3)}
                        width="6.4375rem"
                        className="border-lighten-gray mr-1"
                      >
                        <Pane display="flex" justifyContent="center">
                          {graphStore.isSaving ? (
                            <RefreshIcon
                              size={12}
                              className="mr-2 mt-1"
                              color="#8F95B2"
                              fontSize="12"
                            />
                          ) : (
                            <TickIcon
                              size={12}
                              className="mr-2 mt-1"
                              color="#8F95B2"
                              fontSize="12"
                            />
                          )}

                          <Text fontSize="12" fontWeight="500">
                            {graphStore.isSaving ? 'Saving...' : 'Saved'}
                          </Text>
                        </Pane>
                      </Button>
                    );
                  }
                }}
              </Observer>

              <TooltipCustom
                activator={(
                  <Button
                    height={majorScale(3)}
                    width={majorScale(4)}
                    className="border-lighten-gray"
                    onClick={() => setModalHistoryVersion(true)}
                  >
                    <Pane display="flex" justifyContent="center">
                      <HistoryIcon lab size={12} color="#8F95B2" />
                    </Pane>
                  </Button>
                )}
                text="Version History"
                position={Position.BOTTOM}
              />
            </>
          )}
        </Pane>
        <Pane display={'flex'} alignItems={'center'}>
          <Pane marginRight={majorScale(2)}>
            <Observer>
              {() => (
                <OnlineUsers
                  activeUsers={Object.values(convergenceStore.sessions)}
                />
              )}
            </Observer>
          </Pane>

          {isHavePermission(
            user,
            graphStore.document,
            PermissonType.CommentDoc,
            folder
          ) && (
            <Button
              height={majorScale(3)}
              marginRight={majorScale(1)}
              className={clsx(
                'border-lighten-gray',
                styles.commentBarWidget,
                isCommentBarVisible && styles.commentBarVisible
              )}
              onClick={toggleCommentBar}
            >
              <Pane marginRight={majorScale(1)}>Comment</Pane>
              <Pane
                borderRadius={minorScale(1)}
                backgroundColor="#D6E0FF"
                maxHeight={minorScale(4)}
                minWidth={minorScale(5)}
                display="flex"
                justifyContent={'center'}
              >
                <Text fontSize={11} color="#2952CC" alignSelf={'center'}>
                  {unreadComments}
                </Text>
              </Pane>
            </Button>
          )}

          {isHavePermission(
            user,
            graphStore.document,
            PermissonType.ExportDoc,
            folder
          ) && (
            <>
              {editorUi ? (
                <Popover
                  position={Position.BOTTOM_LEFT}
                  content={({ close }) => (
                    <Pane onClick={close} minWidth={140}>
                      <Menu>
                        <Menu.Item
                          paddingX={0}
                          onClick={() =>
                            getAction('export')(documentName, 'jpg', false)
                          }
                        >
                          <Text fontSize={12}>Export as JPG</Text>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          paddingX={0}
                          onClick={() =>
                            getAction('export')(documentName, 'png', true)
                          }
                        >
                          <Text fontSize={12}>Export as PNG</Text>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          paddingX={0}
                          onClick={() =>
                            getAction('export')(documentName, 'pdf', true)
                          }
                        >
                          <Text fontSize={12}>Export as PDF</Text>
                        </Menu.Item>
                      </Menu>
                    </Pane>
                  )}
                >
                  <Button
                    height={majorScale(3)}
                    marginRight={majorScale(1)}
                    className="border-lighten-gray"
                  >
                    Export
                    <CaretDownIcon size={12} className="ml-2" color="#8F95B2" />
                  </Button>
                </Popover>
              ) : (
                <Popover
                  position={Position.BOTTOM_LEFT}
                  content={({ close }) => (
                    <Pane onClick={close} minWidth={140}>
                      <Menu>
                        <Menu.Item
                          paddingX={0}
                          onClick={handleClickExportAsJPG}
                        >
                          <Text fontSize={12}>Export as JPG</Text>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                          paddingX={0}
                          onClick={handleClickExportAsPNG}
                        >
                          <Text fontSize={12}>Export as PNG</Text>
                        </Menu.Item>
                      </Menu>
                    </Pane>
                  )}
                >
                  <Button
                    height={majorScale(3)}
                    marginRight={majorScale(1)}
                    className="border-lighten-gray"
                  >
                    Export
                    <CaretDownIcon size={12} className="ml-2" color="#8F95B2" />
                  </Button>
                </Popover>
              )}
            </>
          )}

          {isHavePermission(
            user,
            graphStore.document,
            PermissonType.ExportDoc,
            folder
          ) && (
            <Button
              height={majorScale(3)}
              onClick={() => setShowShare(true)}
              appearance="primary"
            >
              <ShareIcon size={12} className="mr-2" />
              Share
            </Button>
          )}
        </Pane>
        <VersionModal
          editorUi={editorUi}
          isShown={modalHistoryVersion}
          setModalHistoryVersion={setModalHistoryVersion}
          histories={histories}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
        />
        <ShareDocumentModal
          isMindMap={isMindMap}
          isShown={isShowShare}
          setIsShown={setShowShare}
          doc={graphStore.document}
        />
      </Pane>
      <Pane display={"none"} className={"zwfMenubarMobile"} alignItems={'center'} height={"100%"}>
        <Link to="/app">
          <Image src={logo} width={minorScale(5)} alt="logo" />
        </Link>
        <Text paddingLeft={majorScale(1)}>Zen Flowchart - {documentName}</Text>
      </Pane>
    </Pane>
  );
};

Menubar.defaultProps = {
  documentName: 'To be determined',
  unreadComments: 0,
};

export default Menubar;
