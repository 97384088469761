import {Button, Card, Image, majorScale, Pane, Text} from "evergreen-ui";
import React, {useState} from "react";
import {isFreeUser} from "../../utils/store";
import {FREE_USER_LIMIT_DOCS} from "../../utils/constants";
import {useHistory} from "react-router-dom";
import {DocumentService} from "../../services/document";
import ProFeatureModal from "../../components/Modals/ProFeatureModal/ProFeatureModal";
import {UpgradeToProModal} from "../../components/Modals";
import {DocumentType} from "../../schemas";

type Props = {
  cardInfo: any,
  docCount: number,
}

const documentService = new DocumentService();

const TemplateCard = ({cardInfo, docCount}: Props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);

  const handleCreateDocument = (template: any) => {
    setLoading(true);
    try {
      documentService.create({
        name: template.name,
        folder: '',
        type: template.type
      }).then(resp => {
        switch (template.type){
          case DocumentType.Wireframe:
            history.push(`/wireframe/${resp.id}?template=${template.param}`);
            break;
          case DocumentType.Whiteboard:
            history.push(`/whiteboard/${resp.id}?template=${template.param}`);
            break;
          default:
            history.push(`/flowchart/${resp.id}?template=${template.param}`);
            break;
        }
      }).finally(() => {
        setLoading(false);
      });
    } finally {

    }
  };

  const onCreateDocFromTemplate = (template: any) => {
    const isLimitDocs = isFreeUser() && (docCount >= FREE_USER_LIMIT_DOCS || docCount === -1);
    setShowProFeature(isLimitDocs);
    if(isLimitDocs) return;
    handleCreateDocument(template);
  }

  return (
    <Card padding={majorScale(2)} background={"white"} width={"344px"} height={"336px"} marginTop={majorScale(2)}>
      <Image width={"312px"} height={"200px"} src={cardInfo.preview}/>
      <Pane paddingY={majorScale(2)}><Text>{cardInfo.name}</Text></Pane>
      <Pane display={"flex"} justifyContent={"space-between"} marginTop={majorScale(2)}>
        <Pane></Pane><Button onClick={() => onCreateDocFromTemplate(cardInfo)} isLoading={loading}>Use Template</Button>
      </Pane>

      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Free Limit Reached"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            You have reached 3 documents per account limit for
            <br/>free accounts. Upgrade to a paid plan for unlimited
            <br/> access and more!
          </Pane>)}
      />
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} />
    </Card>
  )
}

export default TemplateCard;
