import mx from '../mx';

const mxConstants = {
  ...mx.mxConstants,

  /**
   * Variable: DEFAULT_IMAGE_SPACING
   *
   * Defines the default width and height for images used in the
   * label shape. Default is 0.
   */
  DEFAULT_IMAGE_SPACING: 0,

  /**
   * Variable: DEFAULT_CIRCLE_IMAGESIZE
   *
   * Defines the default width and height for images used in the
   * circle shape. Default is 18.
   */
  DEFAULT_CIRCLE_IMAGESIZE: 18,

  /**
   * Variable: STYLE_IMAGE_SPACING_RIGHT
   *
   * Defines the key for the imageHeight style. The type of this value is
   * int, the value is the image height in pixels and must be greater than 0.
   * Value is "imageHeight".
   */
  STYLE_IMAGE_SPACING_RIGHT: 'imageSpacingRight',

  /**
   * Variable: STYLE_AUTO_RESIZE_CELL
   *
   * Defines the key for the autoResizeCell style. If this is true then it will auto
   * resize cell same as text for a given cell. Possible values are true or false (1 or 0).
   * Default is false. Value is "autoResizeCell".
   */
  STYLE_AUTO_RESIZE_CELL: 'autoResizeCell',

  /**
   * Variable: SHAPE_CIRCLE_BUTTON
   *
   * Name under which <mxCircleButton> is registered in <mxCellRenderer>.
   * Default is label.
   */
  SHAPE_CIRCLE_BUTTON: 'circleButton',

  DEFAULT_STYLE_FILLCOLOR: '#FFFFFF',
  DEFAULT_STYLE_STROKECOLOR: '#000000',
  DEFAULT_STYLE_FONTCOLOR: '#000000',

  /**
   * Measurements Units
   */
  POINTS: 1,
  MILLIMETERS: 2,
  INCHES: 3,

  /**
   * This ratio is with page scale 1
   */
  PIXELS_PER_MM: 3.937,
  PIXELS_PER_INCH: 100,

  SHADOW_OPACITY: 0.25,
  SHADOWCOLOR: '#EDEFF5',
  VML_SHADOWCOLOR: '#EDEFF5',

  // Overwrite default mxConstants
  LINE_HEIGHT: 1.5,
  STYLE_STROKE_LINECAP: 'strokeLinecap',
  DEFAULT_STROKE_LINECAP: 'butt',
};

// Sets colors for handles
mxConstants.HANDLE_FILLCOLOR = '#29b6f2';
mxConstants.HANDLE_STROKECOLOR = '#0088cf';
mxConstants.VERTEX_SELECTION_COLOR = '#4B9EF4';
mxConstants.OUTLINE_COLOR = '#00a8ff';
mxConstants.OUTLINE_HANDLE_FILLCOLOR = '#99ccff';
mxConstants.OUTLINE_HANDLE_STROKECOLOR = '#00a8ff';
mxConstants.CONNECT_HANDLE_FILLCOLOR = '#cee7ff';
mxConstants.EDGE_SELECTION_COLOR = '#00a8ff';
mxConstants.DEFAULT_VALID_COLOR = '#00a8ff';
mxConstants.LABEL_HANDLE_FILLCOLOR = '#cee7ff';
mxConstants.GUIDE_COLOR = '#0088cf';
mxConstants.HIGHLIGHT_OPACITY = 30;
mxConstants.VERTEX_SELECTION_DASHED = false;
mxConstants.VERTEX_SELECTION_STROKEWIDTH = 1;
mxConstants.DEFAULT_FONTFAMILY = 'Inter';
mxConstants.HIGHLIGHT_SIZE = 5;

mxConstants.DEFAULT_FONTSIZE = 14;

export { mxConstants };
