import { sort } from 'fast-sort';
import Fuse from 'fuse.js';
import { fuego, useCollection } from '@nandorojo/swr-firestore';

import { NOTE_COLLECTION, NOTE_SORT, USER_COLLECTION } from 'utils/constants';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { getUser, getUserSort, setUserSort } from 'utils/store';
import { ShareUserSchema } from 'schemas/share';
import _ from 'lodash';
import { db } from 'utils/firebase';
import { NoteSchema, NoteListType } from 'schemas';

/**
 * Use note detail
 *
 * @returns
 */
export const useNoteDetail = () => {
  const [note, setNote] = useState({} as NoteSchema);
  const [isLoading, setIsLoading] = useState(true);

  const getNote = useCallback(async (id: string) => {
    const ref = fuego.db.doc(`${NOTE_COLLECTION}/${id}`);
    let query = ref;

    const unsubscribe = query.onSnapshot(
      {
        includeMetadataChanges: true,
      },
      (doc: any) => {
        const docData = doc.data();
        setNote({
          id: doc.id,
          ...docData,
        });
        setIsLoading(false);
      },
      (error: any) => {
        setIsLoading(false);
      },
    );
    return unsubscribe;
  }, []);

  return {
    note,
    isLoading,
    getNote,
    setNote,
  };
};

/**
 * Use note collection
 *
 * @returns
 */
export const useNoteCollection = (
  type: NoteListType,
  defaultNotebookId?: string,
) => {
  const ref = fuego.db.collection(NOTE_COLLECTION);
  const [notes, setNotes] = useState([] as NoteSchema[]);
  const [isLoading, setIsLoading] = useState(true);
  const user = getUser();
  let unsubscribe: any = false;
  const notebookId = defaultNotebookId ? defaultNotebookId : '';

  useEffect(() => {
    if (user?.uid) {
      const { uid } = user;
      let query: any;

      if (type === NoteListType.TRASH) {
        query = ref.where(`user`, '==', uid).where('isDeleted', '==', true);
      } else {
        query = ref
          .where(`user`, '==', uid)
          .where('notebook', '==', notebookId)
          .where('isDeleted', '==', false);
      }

      unsubscribe = query.onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (querySnapshot: any) => {
          const docs: any[] = [];
          querySnapshot.forEach((change: any) => {
            docs.push({
              id: change.id,
              ...change.data(),
            });
          });
          setIsLoading(false);
          setNotes(docs);
        },
      );
    }
    return () => unsubscribe && unsubscribe();
  }, [user?.uid, type]);

  return {
    notes,
    isLoading,
    setNotes,
    unsubscribe,
  };
};

/**
 * Use notes by shared notebook
 *
 * @returns
 */
export const useNotesByNotebook = (notebookId: string) => {
  const ref = fuego.db.collection(NOTE_COLLECTION);
  const [notes, setNotes] = useState([] as NoteSchema[]);
  const [isLoading, setIsLoading] = useState(true);
  let unsubscribe: any = false;

  useEffect(() => {
    let query: any;
    query = ref
      .where('notebook', '==', notebookId)
      .where('isDeleted', '==', false);

    unsubscribe = query.onSnapshot(
      {
        includeMetadataChanges: true,
      },
      (querySnapshot: any) => {
        const docs: any[] = [];
        querySnapshot.forEach((change: any) => {
          docs.push({
            id: change.id,
            ...change.data(),
          });
        });
        setIsLoading(false);
        setNotes(docs);
      },
    );
    return () => unsubscribe && unsubscribe();
  }, []);

  return {
    notes,
    isLoading,
    setNotes,
    unsubscribe,
  };
};

/**
 * Use note list
 *
 * @returns
 */
export const useNoteList = () => {
  const ref = fuego.db.collection(NOTE_COLLECTION);
  const [notes, setNotes] = useState([] as NoteSchema[]);
  const [isLoading, setIsLoading] = useState(true);
  const user = getUser();
  let unsubscribe: any = false;

  useEffect(() => {
    if (user?.uid) {
      const { uid } = user;
      let query: any;
      query = ref.where(`user`, '==', uid).where('isDeleted', '==', false);
      unsubscribe = query.onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (querySnapshot: any) => {
          const docs: any[] = [];
          querySnapshot.forEach((change: any) => {
            docs.push({
              id: change.id,
              ...change.data(),
            });
          });
          setIsLoading(false);
          setNotes(docs);
        },
      );
    }
    return () => unsubscribe && unsubscribe();
  }, [user?.uid]);

  return {
    notes,
    isLoading,
    setNotes,
    unsubscribe,
  };
};

export const useShareNote = () => {
  const [document, setDocuments] = useState({} as NoteSchema);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([] as any[]);
  const [teamRoles, setTeamRoles] = useState([] as any[]);
  const [invites, setInvites] = useState([] as any[]);
  const [shareUsers, setShareUsers] = useState([] as ShareUserSchema[]);
  const [shareTeams, setShareTeams] = useState([] as any[]);
  const user = getUser();
  let unsubscribe: any = false;

  useEffect(() => {
    const getShareDetail = async () => {
      const resShare: ShareUserSchema[] = [];
      const userRef = fuego.db.collection(USER_COLLECTION);
      if (roles) {
        for (const [uid, role] of Object.entries(roles)) {
          if (uid !== 'anyone') {
            const user = await userRef.doc(uid).get();
            resShare.push({
              id: user.id,
              ...user.data(),
              role,
            });
          }
        }
      }

      if (invites) {
        for (const [uid, invite] of Object.entries(invites)) {
          if (uid !== 'anyone') {
            resShare.push({
              id: user.id,
              email: invite.inviteEmail,
              role: invite,
            });
          }
        }
      }

      const teamRolesMap = Object.keys(teamRoles || {}).map(teamId => ({
        role: _.get(teamRoles, teamId, {}).type,
        id: teamId,
      }));

      setShareUsers(resShare);
      setShareTeams(teamRolesMap);
    };
    getShareDetail();
  }, [roles, invites, user?.uid, teamRoles]);

  const getShareDocument = useCallback(
    async (docId: string) => {
      const ref = fuego.db.collection(NOTE_COLLECTION);
      if (!user) {
        return false;
      }
      let query = ref.doc(docId);

      unsubscribe = query.onSnapshot(
        {
          includeMetadataChanges: true,
        },
        (doc: any) => {
          setIsLoading(false);
          const resDoc = {
            id: doc.id,
            ...doc.data(),
          };
          const roles = resDoc.roles;
          const invites = resDoc.invites;
          const teamRoles = resDoc.teamRoles;
          setDocuments(resDoc);
          setRoles(roles);
          setInvites(invites);
          setTeamRoles(teamRoles);
        },
      );
      return unsubscribe;
    },
    [user?.uid],
  );

  return {
    document,
    shareUsers,
    roles,
    isLoading,
    getShareDocument,
    shareTeams,
    unsubscribe,
  };
};

export const useShareNotes = (
  activateTeams: any[],
  defaultRoles?: string[],
) => {
  const roles = defaultRoles ? defaultRoles : ['editor', 'commenter', 'viewer'];
  const [shareDocs, setShareDocs] = useState([] as NoteSchema[]);
  const user = getUser();
  const [teamShareDocsMap, dispatchTeamShareDocsMap] = useReducer(
    (state: any, teamShareDocs: any) => {
      return { ...state, ...teamShareDocs };
    },
    {},
  );

  const shareToUsersSubQuery = [
    [`roles.${user.uid}.type`, 'in', roles],
    ['isDeleted', '==', false],
  ] as any[];

  const { data: rawDocuments } = useCollection<NoteSchema>(NOTE_COLLECTION, {
    where: [...shareToUsersSubQuery],
    listen: true,
  });

  useEffect(() => {
    if ((activateTeams || []).length <= 0) return;

    activateTeams.forEach(team => {
      db.collection(NOTE_COLLECTION)
        .where(`teamRoles.${team.id}.type`, 'in', roles)
        .where('isDeleted', '==', false)
        .onSnapshot(resp => {
          dispatchTeamShareDocsMap({
            [team.id]: resp.docs.map(doc => ({ ...doc.data(), id: doc.id })),
          });
        });
    });
  }, [activateTeams?.length]);

  useEffect(() => {
    let totalShareDocs = [] as any[];
    Object.values(teamShareDocsMap).forEach((docs: any) => {
      const removeDuplicateDocs = (docs || [])
        .filter((doc: any) => _.get(doc.roles, user.uid) !== 'owner')
        .filter(
          (doc: any) =>
            !totalShareDocs.find(shareDoc => shareDoc.id === doc.id),
        );
      totalShareDocs = [...totalShareDocs, ...removeDuplicateDocs];
    });

    setShareDocs(totalShareDocs);
  }, [teamShareDocsMap]);

  useEffect(() => {
    dispatchTeamShareDocsMap({
      none_team: rawDocuments as NoteSchema[],
    });
  }, [rawDocuments]);

  return {
    notes: shareDocs,
    setNotes: setShareDocs,
  };
};

/**
 * Get sorted notes
 *
 * @param notes
 * @param tag
 * @returns
 */
export const getSortedNote = (notes: NoteSchema[], tag?: string) => {
  const userSort = getUserSort(NOTE_SORT);
  let sortedDocuments: NoteSchema[] = [];
  let result: NoteSchema[] = notes;
  if (userSort) {
    if (userSort.direction === 'asc') {
      sortedDocuments = sort(notes).asc((item: any) => item[userSort.field]);
    } else {
      sortedDocuments = sort(notes).desc((item: any) => item[userSort.field]);
    }
    result = sortedDocuments;
  }

  if (tag) {
    result = _.filter(result, item => item.tags?.includes(tag));
  }

  return result;
};

/**
 * Handle sort note
 *
 * @param input
 * @param notes
 * @param setSelectedOrder
 * @param setNotes
 */
export const handleSortNote = (
  input: string,
  notes: NoteSchema[],
  setSelectedOrder: Function,
  setNotes: Function,
) => {
  const inputArray = input.split(':');
  setSelectedOrder(input);
  if (inputArray) {
    const key = inputArray[0];
    const direction = inputArray[1];
    if (direction === 'asc') {
      setNotes(sort(notes).asc(item => _.get(item, key, '')));
    } else {
      setNotes(sort(notes).desc(item => _.get(item, key, '')));
    }
    setUserSort(NOTE_SORT, {
      field: key,
      direction: direction,
    });
  }
};

/**
 * Use search notes
 *
 * @param keyword
 * @param notes
 * @param tagId
 * @returns
 */
export const useSearchNotes = (
  keyword: string,
  notes: NoteSchema[],
  tagId?: string,
) => {
  const [resultNotes, setResult] = useState([] as NoteSchema[]);

  useEffect(() => {
    let docResult: NoteSchema[] = [];
    if (keyword === '') {
      docResult = notes;
      if (tagId) {
        docResult = _.filter(docResult, item => item.tags?.includes(tagId));
      }
      setResult(docResult);
    } else {
      const options = {
        includeScore: true,
        keys: ['title', 'text'],
      };
      const fuse = new Fuse(notes, options);
      const result = fuse.search(keyword);
      result.forEach(item => {
        docResult.push(item.item);
      });
      if (tagId) {
        docResult = _.filter(docResult, item => item.tags?.includes(tagId));
      }
      setResult(docResult);
    }
  }, [keyword, notes, tagId]);

  return {
    resultNotes,
    setResult,
  };
};
