/* eslint-disable */
import mx from '../mx';

import { mxUtils } from './mxUtils';

const mxSvgCanvas2D = mx.mxSvgCanvas2D;

/**
 * Function: text
 *
 * Paints the given text. Possible values for format are empty string for plain
 * text and html for HTML markup. Note that HTML markup is only supported if
 * foreignObject is supported and <foEnabled> is true. (This means IE9 and later
 * does currently not support HTML text as part of shapes.)
 */
 mxSvgCanvas2D.prototype.text = function(x, y, w, h, str, align, valign, wrap, format, overflow, clip, rotation, dir)
 {
   if (this.textEnabled && str != null)
   {
     rotation = (rotation != null) ? rotation : 0;

     if (this.foEnabled && format == 'html')
     {
       var div = this.createDiv(str);

       // Ignores invalid XHTML labels
       if (div != null)
       {
         if (dir != null)
         {
           div.setAttribute('dir', dir);
         }

         this.addForeignObject(x, y, w, h, str, align, valign, wrap,
           format, overflow, clip, rotation, dir, div, this.root);
       }
     }
     else
     {
       this.plainText(x + this.state.dx, y + this.state.dy, w, h, str,
         align, valign, wrap, overflow, clip, rotation, dir);
     }
   }
 };

/**
 * Function: svg
 *
 * Render svg
 */
mxSvgCanvas2D.prototype.svg = function(innerHTML, x, y, w, h, style, stylesToAttrs)
{
  const s = this.state;
  let el = this.createElement('svg');
  el.setAttribute('x', this.format((x + s.dx) * s.scale));
  el.setAttribute('y', this.format((y + s.dy) * s.scale));
  el.setAttribute('width', this.format(w * s.scale));
  el.setAttribute('height', this.format(h * s.scale));

  const viewBox = mxUtils.getValue(style, "viewBox");
  if(viewBox){
    el.setAttribute('viewBox', viewBox);
  }

  (stylesToAttrs || []).forEach(attr => {
    el.setAttribute(attr, mxUtils.getValue(style, attr) || 'none')
  })

  el.innerHTML = innerHTML;

  this.node = el;
};

export { mxSvgCanvas2D };
