import {Link} from "react-router-dom";
import {Pane, Paragraph} from "evergreen-ui";
import React from "react";

type Props = {
  title: string,
  links: any,
  width?: number
}

const CardLink = ({ title, links }: Props) => (
  <Pane>
    <Paragraph className="text-center">
      {title}&nbsp;
      {links
        .map((item: any) => (
          <Link to={item.link} className="text-decoration-none">
            {item.text}
          </Link>
        ))
        .reduce((prev: any, curr: any) => [prev, ' or ', curr])}
    </Paragraph>
  </Pane>
);

export default CardLink;
