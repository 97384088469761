import {Dialog} from "../../../components/Dialog";
import {Pane} from "evergreen-ui";
import {ProjectService} from "../../../services/project/project.service";
import {useState} from "react";

type Props = {
  projectId: string,
  isShown: boolean,
  setIsShown: Function,
  successCallback: Function,
}

const projectService = new ProjectService();

const DeleteProjectModal = ({projectId, isShown, setIsShown, successCallback}: Props ) => {
  const [isDeleting, setDeleting] = useState(false);

  const onDeleteProject = ()  => {
    setDeleting(true);
    projectService.deleteProject(projectId)
      .then(() => {
        setIsShown(true);
        successCallback && successCallback();
      })
      .finally(() => {
        setDeleting(false);
      })
  }

  return (
    <Dialog
      isShown={isShown}
      title="Delete Project"
      onCloseComplete={() => setIsShown && setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Delete"
      intent="danger"
      onConfirm={onDeleteProject}
      isConfirmLoading={isDeleting}
    >
      <Pane>Are you sure to delete project?</Pane>
    </Dialog>
  )
}

export default DeleteProjectModal;
