/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import {
  CaretDownIcon,
  IconButton,
  majorScale,
  Pane,
  SearchIcon,
  CrossIcon,
  Button
} from 'evergreen-ui';
import clsx from 'clsx';
import { Container } from 'react-grid-system';
import EltSidebar from './EltSidebar';
import SelectCustom from './SelectCustom/SelectCustom';
import { zfcOptions } from './dataListOption';
import editorStyles from '../../EditorApp.module.scss';
import SearchInput from '../../../NumberInput/SearchInput';
import styles from './Sidebar.module.scss';
import ProFeatureModal from "../../../Modals/ProFeatureModal/ProFeatureModal";
import { UpgradeToProModal } from "../../../Modals";

const Sidebar = ({ editorUi, dispatchPanelState }) => {
  const { sidebar } = editorUi;
  const [checkFilter, setCheckFilter] = useState(true);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState('all_categories');

  useEffect(() => {
    if (checkFilter && sidebar) {
      sidebar.selectAllCategories(defaultValue);
    }
  }, [checkFilter, defaultValue, sidebar]);

  const handleSearch = event => {
    const searchElement = event.target;
    const resultSearch = document.querySelector('.resultSearch');
    sidebar.find(searchElement, resultSearch, EltSidebar);
    mxEvent.consume(event);
  };

  const handleClear = () => {
    sidebar.selectAllCategories('all_categories');
    setDefaultValue('all_categories');
  }

  const handleFilterCategories = (categories) => {
    sidebar.selectAllCategories(categories);
    setDefaultValue(categories);
  };

  const handleCloseSidebar = () => {
    editorUi.toggleSidebarPanel();
    dispatchPanelState({ sidePanel: false });
  };

  useEffect(() => {
    const onLimitComponentEvent = () => {
      setShowProFeature(true);
    }
    editorUi.addListener("limitComponentsAdded", onLimitComponentEvent);
    return () => {
      editorUi.removeListener("resetGraphView", onLimitComponentEvent);
    }
  }, []);

  return (
    <>
      <Pane className="titleBox">
        <Container>
          <label>All Shapes</label>
          <Button
            className={clsx(
              editorStyles.closeContainer,
              styles.close,
            )}
            onClick={handleCloseSidebar}
          >
            <CrossIcon />
          </Button>
        </Container>
      </Pane>

      <Pane className="filterBox">
        <Container>
          <Pane className="filterSidebar">
            {/* search */}
            {checkFilter ? (
              <Pane display="flex" alignItems="center">
                <IconButton
                  icon={SearchIcon}
                  marginRight={majorScale(1)}
                  onClick={() => setCheckFilter(!checkFilter)}
                />
              </Pane>
            ) : (
                <SearchInput
                  width="100%"
                  placeholder="Search element"
                  onChange={handleSearch}
                  onClear={handleClear}
                />
              )}

            {/* filter */}
            {!checkFilter ? (
              <Pane display="flex" alignItems="center">
                <IconButton
                  icon={CaretDownIcon}
                  className="buttonSelect"
                  marginRight={majorScale(2)}
                  onClick={() => setCheckFilter(!checkFilter)}
                />
              </Pane>
            ) : (
                <SelectCustom
                  options={zfcOptions}
                  defaultValue={defaultValue}
                  selectOption={(data) => handleFilterCategories(data.value)}
                />
              )}
          </Pane>
        </Container>
      </Pane>

      <Container className="sidebar-box">
        <Pane className="resultSearch" />
      </Container>

      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Free Limit Reached"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            You have reached 20 components per document limit
            <br />for free accounts. Upgrade to a paid plan for unlimited
            <br /> access and more!
          </Pane>)}
      />
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} key={"sidebar"} />
    </>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
