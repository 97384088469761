import {useEffect, useState} from 'react';
import {Button, Pane, SearchInput, toaster} from 'evergreen-ui';
import {sort} from 'fast-sort';
import clsx from 'clsx';
import Fuse from "fuse.js";
import _ from "lodash"

import {Card, CardBody, CardHeader, CardTitle} from 'components/Documents/Card/Card';
import {
  Table,
  TableBody,
  TableCell,
  TableFirstCell,
  TableHead,
  TableHeaderCell,
  TableHeaderLastCell,
  TableLastCell,
  TableRow
} from 'components/Documents/Table/Table';
import {Spinner} from 'components/Spinner/Spinner';
import {date} from 'utils/helpers';
import styles from './SharedWithMe.module.scss';
import { DocumentSchema, DocumentType } from '../../schemas/document';
import {DocumentService, useShareDocuments} from 'services/document';
import {SortDirection, SortField} from 'schemas/sort';
import {getTeams, getUser} from "../../utils/store";

const documentService = new DocumentService();

const SharedWithMe = () => {
  const [originalDocuments, setOriginalDocuments] = useState<DocumentSchema[]>([]);
  const [documents, setDocuments] = useState<DocumentSchema[]>([]);
  const [loading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [keyword, setKeyword] = useState('');
  const activateTeams = getTeams() || [];
  const {shareDocs} = useShareDocuments(activateTeams);

  useEffect(() => {
    setOriginalDocuments(shareDocs);
    setDocuments(shareDocs);
  }, [shareDocs])

  useEffect(() => {
    if (keyword === '') {
      setDocuments(originalDocuments);
    } else {
      const options = {
        includeScore: true,
        keys: ['name']
      };
      const fuse = new Fuse(documents, options);
      const result = fuse.search(keyword);
      let docResult: DocumentSchema[] = [];
      result.forEach(item => {
        docResult.push(item.item);
      })
      setDocuments(docResult);
    }
  }, [keyword])

  const isShareToTeam = (doc: any) => {
    const activeTeamIds = (activateTeams || []).map((team: any) => team.id);
    const hasTeamRole = Object.keys((doc.teamRoles || {})).find(teamId => activeTeamIds.includes(teamId));

    return hasTeamRole;
  }

  const handleDelete = async (doc: any) => {
    try {
      setSubmitting(true);
      if(isShareToTeam(doc)){
        // await documentService.removeTeamSharedDocument(doc.id, (activateTeams || []).map(team => team.id));
      }
      else {
        await documentService.removeSharedDocument(doc.id);
      }
      setDocuments(documents.filter(item => item.id !== doc.id));
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setSubmitting(false);
    }
  }

  const getSharedBy = (doc: any, teams: any) => {
    const { uid } = getUser() || {};
    const shareToUser = _.get(doc, `roles.${uid}.updatedByEmail`, "");
    const shareToTeams = (teams || []).map((team: any) => (_.get(doc, `teamRoles.${team.id}.updatedByEmail`, "")));
    const firstRole = _.get(shareToTeams, 0, "");

    return shareToUser || firstRole;
  }

  const handleSort = (key: SortField) => {
    const { uid } = getUser() || {};
    const convertKey = {
      'sharedBy': `roles.${uid}.updatedByEmail`
    };
    const path = _.get(convertKey, key, key);

    if (sortDirection === SortDirection.ASC) {
      setDocuments(sort(documents).asc(item => _.get(item, path, "")));
      setSortDirection(SortDirection.DESC);
    } else {
      setDocuments(sort(documents).desc(item => _.get(item, path, "")));
      setSortDirection(SortDirection.ASC);
    }
  }

  const handleRedirectDocument = (id: string, type: DocumentType) => {
    const route = window.open(`/${type || 'docs'}/${id}`, "_blank");
    route?.focus();
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Shared with me</CardTitle>
          <Pane className={clsx('d-none d-md-block')}>
            <SearchInput value={keyword} onChange={(e: any) => setKeyword(e.target.value)} placeholder="Search" />
          </Pane>
        </CardHeader>
        <CardBody>
          {loading && <Spinner />}
          {!loading && (
            <Table>
              <TableHead>
                <TableHeaderCell onSort={() => handleSort(SortField.Name)}>Title</TableHeaderCell>
                <TableHeaderCell onSort={() => handleSort(SortField.SharedBy)}>Shared By</TableHeaderCell>
                <TableHeaderCell onSort={() => handleSort(SortField.CreatedAt)}>Share Date</TableHeaderCell>
                <TableHeaderLastCell />
              </TableHead>
              <TableBody>
                {documents.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableFirstCell>
                      <span onClick={() => { handleRedirectDocument(doc.id, doc.type) }}>{doc.name}</span>
                    </TableFirstCell>
                    <TableCell>{getSharedBy(doc, activateTeams)}</TableCell>
                    <TableCell>{date(doc.createdAt.toDate())}</TableCell>
                    <TableLastCell>
                      {!isShareToTeam(doc) &&
                        <Button
                          appearance="primary"
                          intent="danger"
                          className={styles.buttonDelete}
                          isLoading={submitting}
                          onClick={() => handleDelete(doc)}
                        >
                          Remove
                        </Button>
                      }
                    </TableLastCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default SharedWithMe;
