import { createContext, useContext } from 'react';
import { RootStore } from 'store';

export const StoresContext = createContext({} as RootStore);

export const useStores = () => useContext(StoresContext);

export const StoresProvider = ({ children }: { children: any }) => {
  return (
    <StoresContext.Provider value={new RootStore()}>
      {children}
    </StoresContext.Provider>
  );
};

export default useStores;
