import { getUser } from './store';

const getPageName = pathname => {
  // if (pathname === '/app' || pathname === '/') return 'My Documents';
  return pathname.replace('/', '').split('-').map(item => item.charAt(0).toUpperCase() + item.slice(1, item.length)).join(' ');
};

const isEmpty = object => {
  return object ? Object.keys(object).length === 0 : object;
}

const date = (a) => {
  const currentDate = a || new Date();
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  const year = currentDate.getFullYear();
  const month = months[currentDate.getMonth()];
  const date = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
  return `${month} ${date}, ${year}`;
}

const camelCaseToDash = str => {
  return str.replace( /([a-z])([A-Z])/g, '$1-$2' ).toLowerCase();
}

const truncate = (str, length = 50) => {
  return str.length >= length ? str.slice(0, length).trim() + '...' : str;
}

const createSelectOption = () => {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100];
}

const getUserAvatar = user => {
  return user?.photoUrl || user?.googleAvatar;
}

const getUserDisplayName = user => {
  const fullName = [user.firstName, user.lastName].filter(item => !!item).join(' ');
  return fullName === '' ? user.email : fullName;
}

const getUserName = () => {
  const user = getUser();
  const fullName = [user.firstName, user.lastName].filter(item => !!item).join(' ');
  return fullName === '' ? user.email : fullName;
}

export { getUserAvatar, getPageName, isEmpty, date, camelCaseToDash, truncate, createSelectOption, getUserName, getUserDisplayName };
