import { Pane, SelectField, Text } from 'evergreen-ui';
import styles from './PositionPanel.module.scss';
import { Box } from '../../Box/Box';
import {ZwfConstants} from "../../../../utils/ZwfConstants";
import _ from "lodash";
import { updatePosition, updateAnnotation } from "../../../../utils/Actions";

const PositionPanel = ({graph, editorUi ,graphState, dispatchGraphState, accessorPanel}) => {
  const onLabelOptionChange = (e) => {
    const value = e.target.value;
    dispatchGraphState({zwfPosition: value});
    updatePosition(editorUi, value);
    updateAnnotation({editorUi, graphState, dispatchGraphState, position: value});
  }

  return (
    <Box>
      {accessorPanel.isPanelVisible(ZwfConstants.LABEL_POSITION) &&
        <Pane className="d-flex align-items-center">
          <Text>Label Position</Text>
          <Pane className={styles.fontSize}>
            <SelectField
              value={_.get(graphState, ZwfConstants.POSITION_STATE)}
              width={93}
              onChange={onLabelOptionChange}
            >
              <option value={ZwfConstants.POSITION_LEFT} selected>Left</option>
              <option value={ZwfConstants.POSITION_RIGHT} selected>Right</option>
              <option value={ZwfConstants.POSITION_BOTTOM} selected>Bottom</option>
              <option value={ZwfConstants.POSITION_TOP} selected>Top</option>
            </SelectField>
          </Pane>
        </Pane>
      }
      {accessorPanel.isPanelVisible(ZwfConstants.NOTE_POSITION) &&
        <Pane className="mt-3 d-flex align-items-center">
          <Text>Note Position</Text>
          <Pane className={styles.fontSize}>
            <SelectField width={93}>
              <option value="right" selected>Right</option>
            </SelectField>
          </Pane>
        </Pane>
      }
    </Box>
  )
}

export default PositionPanel;
