import { Card, Heading } from 'evergreen-ui';
import {getUser} from 'utils/store';
import {AuthProvider} from 'schemas/authProvider';
import styles from './UserSettings.module.scss';
import ChangeNameForm from '../Form/ChangeNameForm';
import ChangePasswordForm from '../Form/ChangePasswordForm';
import ChangeEmailForm from '../Form/ChangeEmailForm';
import ChangeAvatarForm from '../Form/ChangeAvatarForm';

const UserSettings = () => {
  const user = getUser() || {};
  console.log(user);

  return (
    <>
      <Card className={styles.card}>
        <Heading size={500}>Change Name</Heading>
        <ChangeNameForm />
      </Card>
      <Card className={styles.card}>
        <Heading size={500}>My Avatar</Heading>
        <ChangeAvatarForm/>
      </Card>
      {user.provider === AuthProvider.Email && (
        <>
          <Card className={styles.card}>
            <Heading size={500}>Change Password</Heading>
            <ChangePasswordForm />
          </Card>
          <Card className={styles.card}>
            <Heading size={500}>Change Email Address</Heading>
            <ChangeEmailForm />
          </Card>
        </>
      )}
    </>
  );
};

export default UserSettings;
