/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import {
  Pane,
  Button,
  IconButton,
  MoreIcon,
  InboxIcon,
  NewPersonIcon,
  Avatar,
  Popover,
  Position,
  TrashIcon,
  Dialog,
  AnnotationIcon,
  LogOutIcon,
  ContrastIcon
} from "evergreen-ui";
import InviteModal from "../../../components/Kanban/Invite/InviteModal";
import ChangeBgModal from "../../../components/Kanban/ChangeBg/ChangeBgModal";
import FilterCard from "../../../components/Kanban/FilterCard/FilterCardModal";
import Notification from "../../../components/Kanban/Notification/NotificationModal";

import styles from "./HeaderMainBoard.module.scss";

export default function HeaderMainBoard() {
  const [isShowInviteModal, setIsShowInviteModal] = useState(false)
  const [isShowModalDeleteBoard, setIsShowModalDeleteBoard] = useState(false)
  const [isShowModalChangeBg, setIsShowModalChangeBg] = useState(false)
  const [isShowModalFilterCard, setIsShowModalFilterCard] = useState(false)

  return (
    <Pane className={styles.headerMainBoard}>
      <Pane className={styles.actionLeft}>
        <Pane className={styles.title}>
          Mobile Diary App
        </Pane>
        <Pane className={styles.moreIcon}>
          <Popover
            content={
              <Pane className={styles.formPopover}>
                <Pane className={styles.title}>board options</Pane>
                <Pane className={styles.actionEdit}>
                  <Button iconBefore={AnnotationIcon} appearance="minimal">
                    Rename
                  </Button>
                </Pane>
                <Pane className={styles.actionEdit}>
                  <Button iconBefore={ContrastIcon} appearance="minimal" onClick={() => setIsShowModalChangeBg(true)}>
                    Change Background
                  </Button>
                </Pane>
                <Pane className={styles.actionEdit}>
                  <Button iconBefore={LogOutIcon} appearance="minimal">
                    Leave Board
                  </Button>
                </Pane>
                <Pane className={styles.actionRemove}>
                  <Button iconBefore={TrashIcon} appearance="minimal" onClick={() => setIsShowModalDeleteBoard(true)}>
                    Delete Board
                  </Button>
                </Pane>
              </Pane>
            }
            position={Position.BOTTOM_RIGHT}
          >
            <IconButton icon={MoreIcon} height={20} width={20} />
          </Popover>
          <Dialog
            width={368}
            isShown={isShowModalDeleteBoard}
            title="Delete Board"
            intent="danger"
            onCloseComplete={() => setIsShowModalDeleteBoard(false)}
            confirmLabel="Delete Board"
          >
            Are you sure to delete this board? This action cannot be undone.
          </Dialog>
          <ChangeBgModal isShowModalChangeBg={isShowModalChangeBg} setIsShowModalChangeBg={setIsShowModalChangeBg} />
        </Pane>
        <Pane className={styles.filterIcon}>
          <FilterCard isShowModalFilterCard={isShowModalFilterCard} setIsShowModalFilterCard={setIsShowModalFilterCard} />
        </Pane>
        <Pane className={styles.filterIcon}>
          <Button marginY={8} marginRight={12} iconBefore={InboxIcon} appearance="minimal">
            Show Archive
          </Button>
        </Pane>
      </Pane>
      <Pane className={styles.actionRight}>
        <Pane className={styles.avatars}>
          <Avatar name="Steve Jobs" size={32} marginRight={-4} />
          <Avatar name="Bill Gates" size={32} marginRight={-4} />
          <Avatar name="Elon Musk" size={32} marginRight={-4} />
          <Avatar name="Allen Kleiner" color="green" size={32} />
        </Pane>
        <Pane className={styles.inviteIcon}>
          <Button marginY={8} marginRight={12} iconBefore={NewPersonIcon} onClick={() => setIsShowInviteModal(true)}>
            Invite
          </Button>
        </Pane>
        <Pane className={styles.notifiIcon}>
          <Notification />
        </Pane>
      </Pane>
      <InviteModal isShown={isShowInviteModal} setIsShown={setIsShowInviteModal} />
    </Pane>
  )
}
