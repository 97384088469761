import { useEffect } from 'react';
import clsx from 'clsx';
import { Observer } from 'mobx-react-lite';

import styles from './NotePage.module.scss';
import { useParams } from 'react-router-dom';
import NoteTagList from 'components/NoteEditor/NoteList/NoteTagList';
import useQuery from 'hooks/useQuery';
import NoteEditorContainer from 'components/NoteEditor/NoteEditorContainer';
import useStores from 'hooks/useStores';

export default function NoteTagPage() {
  const params = useParams() as any;
  const query = useQuery();
  const noteId = query.get('noteId') as string;
  const { noteStore } = useStores();
  const tagId = params.id;

  useEffect(() => {
    noteStore.setNoteId(noteId);
  }, [noteId]);

  return (
    <>
      <section className={clsx(styles.mainSection)}>
        <div className={clsx(styles.listContainer)}>
          <Observer>
            {() => (
              <>
                <NoteTagList noteList={noteStore.noteList} tagId={tagId} />
              </>
            )}
          </Observer>
        </div>

        <div className={clsx(styles.editorContainer)}>
          <Observer>
            {() => (
              <>
                {noteStore.noteId && (
                  <NoteEditorContainer key={noteStore.noteId} />
                )}
              </>
            )}
          </Observer>
        </div>
      </section>
    </>
  );
}
