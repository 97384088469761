import { Pane, Button, PlusIcon, TextInput } from "evergreen-ui";
import React, { useState } from "react";
import styled from "@emotion/styled";
import styles from "./Kanban.module.scss";
import Column from "./column";
import reorder, { reorderQuoteMap } from "./reorder";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { authorQuoteMap } from "../../common/data";
import CreateBoardModal from "../../components/Kanban/CreateBoardModal";
import useComponentVisible from "../../common/useComponentVisible";
import HeaderMainBoard from "./layouts/HeaderMainBoard";
import { useRecoilValue } from 'recoil';
import { activeColorBG } from '../../recoil/atom';

const data = authorQuoteMap

const ParentContainer = styled.div`
    background-color: ${({ color }) => color};
    height: 100%;
`;


export default function Board() {
  const activeBg = useRecoilValue(activeColorBG)

  const [refAddList, isVisibleAddList, setIsVisibleAddList] = useComponentVisible(false);

  const [isCombineEnabled] = useState<boolean>(false)
  const [columns, setColumns] = useState<any>(data)
  const [ordered, setOrdered] = useState(Object.keys(data))
  const [isShowCreate, setIsShowCreate] = useState(false)

  const onDragEnd = (result: any) => {
    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);

        setOrdered(shallow)
        return;
      }

      const column: any = columns[result.source.droppableId];
      const withQuoteRemoved = [...column];
      withQuoteRemoved.splice(result.source.index, 1);

      setColumns({
        ...columns,
        [result.source.droppableId]: withQuoteRemoved
      })
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const order: any = reorder(
        ordered,
        source.index,
        destination.index
      );

      setOrdered(order)
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination
    });

    setColumns(data.quoteMap)
  };

  const board = (
    <Droppable
      droppableId="board"
      type="COLUMN"
      direction="horizontal"
      ignoreContainerClipping
      isCombineEnabled={isCombineEnabled}
    >
      {provided => (
        <Pane className={styles.Container} ref={provided.innerRef} {...provided.droppableProps}>
          {ordered.map((key, index) => (
            <Column
              key={key}
              index={index}
              title={key}
              quotes={columns[key]}
              isScrollable={false}
              isCombineEnabled={isCombineEnabled}
            />
          ))}
          {provided.placeholder}
          <Pane className={styles.containerAnother}>
            <Pane ref={refAddList}>
              {
                isVisibleAddList
                  ?
                  <Pane className={styles.formAnother}>
                    <Pane className={styles.addAnother}>
                      <TextInput name="text-input-name" placeholder="Enter list title..." />
                      <Button marginTop={8} appearance="primary" className={styles.btnColor}>
                        Add list
                    </Button>
                    </Pane>
                  </Pane>
                  :
                  <Pane className={styles.titleAnother} onClick={() => setIsVisibleAddList(true)}>
                    <Pane className={styles.contentAnother}>
                      + Add another list
                    </Pane>
                  </Pane>
              }
            </Pane>
          </Pane>
        </Pane>
      )}
    </Droppable>
  );

  return (
    <ParentContainer color={activeBg}>
      <Pane className={styles.mainPage}>
        {
          false
            ?
            <Pane className={styles.formNoData}>
              <div>
                <div className={styles.title}>
                  Create your first board
              </div>
                <div className={styles.content}>
                  No board available. <br />
                Let’s create a new board to get started!
              </div>
                <Button
                  className={styles.createNewBoardBtn}
                  iconBefore={PlusIcon}
                  onClick={() => setIsShowCreate(true)}
                >
                  New Board
              </Button>
              </div>
            </Pane>
            :
            <Pane>
              <Pane className={styles.pageHeaderBoard}>
                <HeaderMainBoard />
              </Pane>
              <DragDropContext onDragEnd={onDragEnd}>
                <Pane className={styles.parentContainer}>{board}</Pane>
              </DragDropContext>
            </Pane>
        }
        <CreateBoardModal isShown={isShowCreate} setIsShown={setIsShowCreate} />
      </Pane>
    </ParentContainer>
  );
}