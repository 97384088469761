import firebase from 'firebase';
import { functions } from 'utils/firebase';

/**
 * List of available firebase functions
 */
export enum EmailFunction {
  SendFeedbackEmail = 'sendFeedbackEmail',
  SendInviteEmail = 'sendInviteEmail',
  SendInviteFolderMail = 'sendInviteFolder',
}

export type InviteProps = {
  newEmails: string[];
  existEmails: string[];
  documentId: string;
  documentName: string;
  role: string;
}

export type InviteFolderProps = {
  users?: any;
  team?: any;
  folderName: string;
  role: string;
}

/**
 * Stripe service
 */
export class EmailService {
  /**
   * Firebase functions
   * @private
   */
  private readonly functions: firebase.functions.Functions;

  /**
   * Initialize publish and secret key
   */
  constructor() {
    this.functions = functions
  }

  /**
   * Send feedback
   *
   */
  async sendFeedback(message: string): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(EmailFunction.SendFeedbackEmail);
    return callable({ message });
  }

  /**
   * Send invites
   *
   */
   async sendInvites({ existEmails, newEmails, documentId, documentName, role}: InviteProps): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(
      EmailFunction.SendInviteEmail,
    );
    return callable({
      existEmails,
      newEmails,
      documentId,
      documentName,
      role
    });
  }

  /**
   * Send invites folder
   *
   */
   async sendInviteFolder(payload: InviteFolderProps): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(
      EmailFunction.SendInviteFolderMail,
    );
    return callable(payload);
  }
}
