import {
  Button,
  toaster,
  Pane,
  Text,
  PlusIcon,
} from 'evergreen-ui';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './NoteList.module.scss';
import { NoteService } from 'services/note';
import useStores from 'hooks/useStores';
import { useMemo, useState } from 'react';

const noteService = new NoteService();

export default function MyNoteHeader() {
  const history = useHistory();
  const { noteStore } = useStores();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  
  const handleCreateNote = async () => {
    const saveData: any = {
      title: '',
      notebook: noteStore.notebook?.id || ''
    };
    try {
      setIsLoading(true);
      const { id: noteId } = await noteService.create(saveData);
      history.push({
        pathname: location.pathname,
        search: `?noteId=${noteId}`
      })
      noteStore.setNoteId(noteId);
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const textHeader = useMemo(() => {
    if (noteStore.notebook?.id) {
      return noteStore.notebook.name;
    }
    return 'My Notes';
  }, [noteStore.notebook])

  return (
    <header className={styles.header}>
      <span className={styles.headerTitle}>{textHeader}</span>
      <Button onClick={handleCreateNote} isLoading={isLoading}>
        <Pane display="flex" justifyContent="center">
          <PlusIcon
            size={12}
            className="mr-2 mt-1"
            color="#8F95B2"
            fontSize="12"
          />
          <Text fontSize={'12px'}>Note</Text>
        </Pane>
      </Button>
    </header>
  );
}
