import firebase from 'firebase';
export const HOME_URL = process.env.REACT_APP_HOME_URL || 'https://zen-wf.web.app';
export const HOME_TITLE = 'Zen Flowchart';
export const TERM_OF_USE = "https://www.zenflowchart.com/terms-of-use";
export const FACEBOOK_PIXEL_ID = '795988261796223';
export const NATIVE_GA_MEASUREMENT_ID = 'UA-139053586-1';
export const GA_MEASUREMENT_ID = "G-GZTBF7V4BK";
export const TAG_MANAGER_ID = "GTM-TBW6D2L";
export const SENTRY_DSN = 'https://66e5a6ae505d4acd8f3455e362f40b59@o1090210.ingest.sentry.io/6106024';
export const HOME_PAGE = 'https://www.zenflowchart.com/'

// Local Storge Key Nme
export const environment = (process.env.REACT_APP_ENVIRONMENT || 'development');
export const KEY = `${environment}_profile`;
export const TEAMS = `${environment}_teams`;
export const TIER = 'tier';
export const PRO_USER = 'pro';
export const PLUS_USER = 'plus';
export const PRO_TEAM = 'isPROTeam';
export const CONVERGENCE_TOKEN_KEY = `${environment}_cvg_key`;
export const PROJECTS = 'projects';
export const OWNER_PROJECTS = 'ownerProjects';
export const SHARE_PROJECTS = 'shareProjects';
export const PAGES = 'pages';
export const OWNER_PAGES = 'ownerPages';
export const SHARE_PAGES = 'sharePages';
export const SHOW_TAG = 'showtag';

// Routing
export const SIGN_IN_URL = '/sign-in';
export const SIGN_UP_URL = '/sign-up';
export const SENT_PASSWORD_URL = '/sent-password';
export const DOCUMENT_URL = '/app';
export const NOT_FOUND_URL = '/404';

export const DIAGRAM_URL = '/';
export const TASK_URL = '/task?view=inbox';
export const KANBAN_URL = '/kanban';
export const SOUND_URL = '/sounds';

export const NOTE_URL = '/notes/mynotes';
export const NOTE_TRASH_URL = '/notes/trash';
export const NOTE_VIEW_URL = '/notes/view';
export const NOTE_TAG_URL = '/notes/tags';
export const NOTEBOOK_URL = '/notes/notebooks';
export const SHARED_NOTE_URL = '/notes/shared-notes';
export const SHARED_NOTEBOOK_URL = '/notes/shared-notebooks';

export const OKR_URL = '/okr'

// Firestore Collection
export const USER_COLLECTION = 'users';
export const DOCUMENT_COLLECTION = 'documents';
export const FOLDER_COLLECTION = 'folders';
export const SHARE_COLLECTION = 'shares';
export const COMMENT_COLLECTION = 'comments';
export const PAYMENT_COLLECTION = 'payments';
export const TEAM_COLLECTION = 'teams';
export const NOTE_COLLECTION = 'notes';
export const NOTEBOOK_COLLECTION = 'notebooks';
export const TAG_COLLECTION = 'tags';
export const PROJECT_COLLECTION = 'projects';
export const TASK_COLLECTION = 'tasks';
export const PAGES_COLLECTION = 'pages';
export const OBJECTIVES_COLLECTION = 'objectives';

// Configuration
export const STRIPE_API_URL = 'https://api.stripe.com/v1';
export const STRIPE_TOKEN_URL = STRIPE_API_URL + '/tokens';
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
export const STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY || '';
export const STRIPE_YEARLY_PLAN_ID = process.env.REACT_APP_STRIPE_YEARLY_PLAN_ID || '';
export const STRIPE_MONTHLY_PLAN_ID = process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID || '';
export const STRIPE_TEAM_YEARLY_PLAN_ID = process.env.REACT_APP_STRIPE_TEAM_YEARLY_PLAN_ID || '';
export const STRIPE_TEAM_MONTHLY_PLAN_ID = process.env.REACT_APP_STRIPE_TEAM_MONTHLY_PLAN_ID || '';
export const STRIPE_PLUS_YEARLY_PLAN_ID = process.env.REACT_APP_STRIPE_PLUS_YEARLY_PLAN_ID || '';
export const STRIPE_PLUS_MONTHLY_PLAN_ID = process.env.REACT_APP_STRIPE_PLUS_MONTHLY_PLAN_ID || '';

const firebaseProd = {
  apiKey: "AIzaSyAfbGAW8seZBRy9EB5SmhUeODUFCVTrNtE",
  authDomain: "zenflowchart.firebaseapp.com",
  projectId: "zenflowchart",
  storageBucket: "zenflowchart.appspot.com",
  messagingSenderId: "693693805038",
  appId: "1:693693805038:web:42bf611b1eaf6c468e9eff",
  measurementId: "G-YT74TZW5L9"
};

const firebaseStag = {
  apiKey: "AIzaSyALkz1qgmwPymBbe_2doBhIrElk9RjIk4c",
  authDomain: "zenflowchart-staging.firebaseapp.com",
  projectId: "zenflowchart-staging",
  storageBucket: "zenflowchart-staging.appspot.com",
  messagingSenderId: "497610778799",
  appId: "1:497610778799:web:7c8ad2e790b85c2b7d0c5a"
};

export const FIREBASE_CONFIG = process.env.REACT_APP_ENV === 'production' ? firebaseProd : firebaseStag;
export const MAX_NODE_LIMIT = 200;

// Sort config
export const FOLDER_SORT = 'folderSort';
export const SHARE_FOLDER_SORT = 'shareFolderSort';
export const NOTE_SORT = 'noteSort';

// Convergence
export const CONVERGENCE_URL = process.env.REACT_APP_CONVERGENCE_URL || 'https://convergence.zfcstaging.com/realtime/convergence/default';
export const CVG_COLLECTION_ID = process.env.REACT_APP_CVG_COLLECTION_ID || 'zfc_mxgraph';

// Note Server
export const NOTE_SERVER = process.env.REACT_APP_NOTE_SERVER || 'wss://connect.ungdunghay.net';

// History
export const HISTORY_EXPORT_INTERVAL = 5 * 60 * 1000 // 5 min
export const HISTORY_CAPTURE_CONTENT_TYPE = 'application/xml'

// Firestore TYPE
export type CollectionReference = firebase.firestore.CollectionReference;
export type DocumentData = firebase.firestore.DocumentData;


// Online user color
export const ONLINE_COLORS = [
  '#2952CC',
  '#524988',
  '#317159',
  '#474D66',
  '#66460D',
  '#0F5156',
  '#85462B',
  '#8E3374',
  '#7D2828'
];

export const BG_ONLINE_COLORS: any = {
  '#2952CC': '#D6E0FF',
  '#524988': '#E7E4F9',
  '#317159': '#DCF2EA',
  '#474D66': '#EDEFF5',
  '#66460D': '#FFEFD2',
  '#0F5156': '#D3F5F7',
  '#85462B': '#F8E3DA',
  '#8E3374': '#FBDDF3',
  '#7D2828': '#F9DADA',
};

// Free users
export const FREE_USER_LIMIT_DOCS = process.env.REACT_APP_FREE_USER_LIMIT_DOCS || 3;
export const FREE_USER_LIMIT_PROJECTS = process.env.REACT_APP_FREE_USER_LIMIT_PROJECTS || 2;
export const FREE_USER_LIMIT_PAGES = process.env.REACT_APP_FREE_USER_LIMIT_PAGES || 2;

// Native tracking


// action: catalog
export const SIGN_UP_SUCCESS = "Sign up successful";
export const UPGRADE_TO_PRO = "Upgrade";

// category: catalog
export const TRACKING_CATALOG = {
  [SIGN_UP_SUCCESS]: "Sign up",
  [UPGRADE_TO_PRO]: "Purchase",
}

export const EVENT_CATALOG = {
  [SIGN_UP_SUCCESS]: 'CompleteRegistration',
  [UPGRADE_TO_PRO]: 'Purchase',
}


// DESIGNER OPTIONS
export const DESIGNER_OPTIONS = {
  readOnly: false,
  zoom: 1,
  saveOnLoad: true,
  size: {
    height: window.screen.height,
    width: window.screen.width
  },
  viewPort: {
    height: window.innerHeight - 70,
    width: window.innerWidth,
  },
  locale: 'en'
};

export const DESIGNER_PUBLISH_OPTIONS = {
  ...DESIGNER_OPTIONS,
  readOnly: true,
}
