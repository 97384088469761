import {
  Image,
  majorScale,
  Pane,
  Position,
  SearchInput,
} from 'evergreen-ui';
import React, { useState } from 'react';
import availableIcons from "../../../../utils/AvailableIcons";
import { camelCaseToDash } from '../../../../../../utils/helpers';
import './IconMenu.css';
import _ from 'lodash';
import TooltipCustom from "../../../PopupMenu/Tooltip";

const IconMenu = ({ onClick }) => {
  const [search, setSearch] = useState('');
  return(
    <Pane
      padding={majorScale(2)}
      paddingRight={majorScale(1)}
      paddingBottom={majorScale(1)}
    >
      <Pane >
        <SearchInput placeholder="Search icons" onChange={(e) => setSearch(e.target.value)} paddingRight={1}/>
      </Pane>
      <div className="icon-menu">
        <Pane
          marginTop={majorScale(2)}
          display="grid"
          gridTemplateColumns="repeat(6, 1fr)"
          maxHeight={288}
          overflowY="scroll"
        >
          {Object
            .keys(availableIcons)
            .filter(iconName => {
              try {
                if(!search || search === '') return true;

                return _.startCase(iconName).toLowerCase().includes(search.toLowerCase());
              }
              catch (ex){
                return false;
              }
            })
            .map((iconName, index) => (
              <Pane key={index} onClick={() => onClick(camelCaseToDash(iconName))}>
                <TooltipCustom
                  activator={(
                    <Pane
                      width={majorScale(5)}
                      height={majorScale(5)}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      cursor={"pointer"}
                      className="icon-state-hover"
                      marginRight={majorScale(1)}
                      marginBottom={majorScale(1)}
                    >
                      <Pane>
                        <Image src={_.get(availableIcons, iconName).icon}/>
                      </Pane>
                    </Pane>
                  )}
                  text={_.startCase(_.camelCase(iconName))}
                  position={Position.RIGHT}
                />
              </Pane>
              )
            )}
        </Pane>
      </div>
    </Pane>
  )
}

export default IconMenu;
