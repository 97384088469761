import { useEffect, useState } from 'react';
import NoteEditor from 'components/NoteEditor/NoteEditor';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useNoteDetail } from 'services/note';
import useStores from 'hooks/useStores';
import { isHaveNotebookPermission, isHaveNotePermission } from 'services/user';
import { NotePermissonType } from 'schemas';
import useQuery from 'hooks/useQuery';
import NoteViewerEditor from './NoteViewerEditor';
import { Spinner } from 'components/Spinner/Spinner';
import { HOME_TITLE, NOTE_URL } from 'utils/constants';

type Props = {
  isHideMenu?: boolean;
  isPublicView?: boolean;
}

export default function NoteEditorContainer({ isHideMenu = false, isPublicView = false } : Props) {
  const query = useQuery();
  const search = query.get('search') as string;
  const { noteStore } = useStores();
  const { note: noteData, getNote } = useNoteDetail();
  const [isCheckedPermission, setCheckedPermission] = useState(false);
  const history = useHistory();

  const noteId = noteStore.noteId;

  useEffect(() => {
    let subscribeNote: any;
    subscribeNote = getNote(noteId);
    return () => {
      if (typeof subscribeNote === 'function') {
        subscribeNote();
      }
    };
  }, [noteId]);

  useEffect(() => {
    if (noteData) {
      noteStore.setNote(noteData);
    }
  }, [noteData?.id]);

  useEffect(() => {
    if (noteData.id) {
      if (noteStore.notebook?.id) {
        // const user = getUser();
        const isViewable = isHaveNotebookPermission(
          noteStore.notebook,
          NotePermissonType.ViewNote,
        );
        if (!isViewable) {
          history.push(NOTE_URL);
        }
      } else {
        const isViewable = isHaveNotePermission(
          noteData,
          NotePermissonType.ViewNote,
        );
        if (!isViewable) {
          history.push(NOTE_URL);
        }
      }
      setCheckedPermission(true);
    }
  }, [noteData.id]);

  if (!isCheckedPermission) {
    return <Spinner />;
  }

  return (
    <>
      <Helmet
        title={`${HOME_TITLE} - ${noteStore?.note?.title || 'Note'}`}
      />
      {!isPublicView && noteId && (
        <NoteEditor noteId={noteId} isHideMenu={isHideMenu} key={noteId + search} />
      )}
      {isPublicView && noteId && (
        <NoteViewerEditor noteId={noteId} key={noteId} />
      )}
    </>
  );
}
