import firebase from 'firebase';
import { functions } from 'utils/firebase';

/**
 * List of available firebase functions
 */
export enum ImageFunction {
  ConvertImageToBase64 = 'convertImageToBase64',
}

/**
 * Stripe service
 */
export class ImageService {
  /**
   * Firebase functions
   * @private
   */
  private readonly functions: firebase.functions.Functions;

  /**
   * Initialize publish and secret key
   */
  constructor() {
    this.functions = functions
  }

  /**
   * Send feedback
   *
   */
  async convertImageToBase64(imageUrl: string): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(ImageFunction.ConvertImageToBase64);
    return callable({ imageUrl });
  }
}
