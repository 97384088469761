import { CardNumberElement } from '@stripe/react-stripe-js';
import { CreditCardIcon } from 'evergreen-ui';
import CardPayment from './CardPayment';
import { useOptions } from './useOptions';

const CardNumber = () => {
  const options = useOptions();
  return (
    <CardPayment icon={CreditCardIcon}>
      <CardNumberElement options={options} />
    </CardPayment>
  )
}

export default CardNumber;
