import React, { useEffect } from 'react';
import {TextInput, Dialog, Button, Heading, Pane} from 'evergreen-ui';
import { useState } from 'react';
import clsx from 'clsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Combobox from 'components/Elements/Combobox';
import { DocumentService, useShareDocument } from 'services/document';
import { DocumentSchema } from 'schemas/document';
import styles from './ShareDocumentModal.module.scss';
import CopyLinkIcon from 'components/Icon/CopyLinkIcon';
import { Role } from 'schemas/role';
import { capitalize } from 'utils/string';
import { HOME_URL } from 'utils/constants';
import { isFreeUser } from 'utils/store';
import ProFeatureModal from "../Modals/ProFeatureModal/ProFeatureModal";
import {UpgradeToProModal} from "../Modals";
import {useActivateTeam} from "../../services/team/team.helper";

interface Props {
  isMindMap?: boolean;
  isShown: boolean;
  setIsShown: Function;
  doc: DocumentSchema;
}

const documentService = new DocumentService();

export default function ShareDocumentModal({ isMindMap, isShown, setIsShown, doc }: Props) {
  const [inviteList, setEmailList] = useState('');
  const [currentDocument, setCurrentDocument] = useState(doc);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  // const [selected, setSelected] = useState({ label: 'Commenter', value: 'commenter'} as any);
  const [selected, setSelected] = useState({ label: 'Editor', value: 'editor'} as any);
  const [linkOption, setLinkOption] = useState({label: 'None', value: 'none'} as any);

  const { shareUsers, getShareDocument, shareTeams } = useShareDocument();
  const [copyLabel, setCopyLabel] = useState('Copy Link');
  // const publicRole = currentDocument?.roles ? currentDocument?.roles['anyone']?.type : 'None';
  // const publicRoleLabel = publicRole === 'viewer' ? 'Can View' : (publicRole === 'commenter' ? 'Can Comment' : 'None');

  const { activateTeams } = useActivateTeam();

  const roleList: any[] = [];
  const inviteRoleList: any[] = [];
  Object.entries(Role).forEach(([label, value]) => {
    roleList.push({
      label,
      value
    })
    if (value !== 'owner') {
      inviteRoleList.push({
        label,
        value
      })
    }
  });

  const inviteRoles = [{ label: 'Editor', value: 'editor' }, { label: 'Viewer', value: 'viewer' }, { label: 'Commenter', value: 'commenter' }];
  // const inviteRoles = [{ label: 'Viewer', value: 'viewer' }, { label: 'Commenter', value: 'commenter' }];
  const linkRoles = [{ label: 'None', value: 'none' }, { label: 'Can View', value: 'viewer' }, { label: 'Can Comment', value: 'commenter' }];
  const userRoles = [
    { label: 'Viewer', value: 'viewer' },
    { label: 'Commenter', value: 'commenter' },
    { label: 'Editor', value: 'editor' },
    { label: 'Remove', value: 'remove' },
  ];

  const teamRoles = [
    { label: 'None', value: 'none' },
    { label: 'Can View', value: 'viewer' },
    { label: 'Can Comment', value: 'commenter' },
    { label: 'Can edit', value: 'editor' }
  ];

  useEffect(() => {
    if (doc && doc.id) {
      setCurrentDocument(doc);
      getShareDocument(doc.id);

      const publicRole = doc?.roles ? doc?.roles['anyone']?.type : 'none';
      const publicRoleLabel = publicRole === 'viewer' ? 'Can View' : (publicRole === 'commenter' ? 'Can Comment' : 'None');
      setLinkOption({
        label: publicRoleLabel,
        value: publicRole
      });
    }
  }, [doc]);

  const handleInvite = async () => {
    try {
      const freeUserInvites = ['viewer'];
      const isLimitedByFreeUser = isFreeUser() && !freeUserInvites.includes(selected.value);
      setShowProFeature(isLimitedByFreeUser);

      if(isLimitedByFreeUser) return;

      let emailList = inviteList.split(',');
      emailList = emailList.map(item => item.trim().toLowerCase());
      if (inviteList && emailList && selected) {
        setIsLoading(true);
        await documentService.addUserRole(currentDocument.id, emailList, selected.value);
      }
    } finally {
      setIsLoading(false);
      setEmailList('');
    }
  };

  const handleChangeInviteRole = async (email?: string, newRole?: string) => {
    if (email && newRole) {
      await documentService.updateUserRole(currentDocument.id, email, newRole);
    }
  }

  const handleChangeTeamRole = async (teamId: string, newRole: string) => {
    if (teamId && newRole) {
      await documentService.updateTeamRole(currentDocument.id, teamId, newRole);
    }
  }

  const handleChangeLinkOption = async(selected: any) => {
    const freeUserOptions = ["none"];
    if(!isMindMap && isFreeUser() && !freeUserOptions.includes(selected.value)){
      setShowProFeature(true);
      return;
    }

    setLinkOption(selected);
    if (selected && currentDocument.id) {
      await documentService.updatePublicRole(currentDocument.id, selected.value);
    }
  }

  const handleCopyLink = () => {
    setCopyLabel('Copied');
    setTimeout(() => {
      setCopyLabel('Copy Link');
    }, 3000);
  }

  const initialTeamRole = (teamId: string) => {
    const shareRole = shareTeams.find(t => t.id === teamId);
    const noneRole = teamRoles.find(role => role.value === "none");

    if(!shareRole) return noneRole;

    return teamRoles.find(role => role.value === shareRole.role);
  }

  return (
    <Dialog
      isShown={isShown}
      title="Share & Collaborate"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Save"
      containerProps={{
        className: clsx(styles.modal),
      }}
      contentContainerProps={{
        className: styles.content,
      }}
      hasFooter={false}
    >
      <div>
        <TextInput
          width="263px"
          required
          name="name"
          value={inviteList}
          onChange={(e: any) => setEmailList(e.target.value)}
          placeholder="Email, comma separated."
        />

        <Combobox
          initialSelectedItem={{ label: selected?.label || '' }}
          items={inviteRoles}
          itemToString={(item) => (item ? item.label : '')}
          onChange={(selected: any) => setSelected(selected)}
          buttonProps={{ className: 'roleListIcon' }}
        />

        <Button
          appearance="primary"
          className={clsx(styles.inviteBtn)}
          onClick={handleInvite}
          isLoading={isLoading}
        >
          Invite
        </Button>
      </div>

      <hr />

      <div>
        <Heading is="h4" className={clsx(styles.permissionHeading)}>
          Permissions
        </Heading>

        <div className={styles.sharedList}>
          <div className={clsx(styles.sharedItem, styles.anynomousItem)}>
            <div className={styles.shareTarget}>Anyone with the link</div>
            <div className={styles.shareAction}>
              {linkOption.value && linkOption.value !== 'none' && (
                <CopyToClipboard
                  onCopy={handleCopyLink}
                  text={`${HOME_URL}/${currentDocument.type || 'docs'}/${currentDocument.id}`}>
                  <Button
                    className={clsx(styles.copyBtn)}
                    iconBefore={<CopyLinkIcon />}
                  >
                    {copyLabel}
                  </Button>
              </CopyToClipboard>
              )}
              <Combobox
                selectedItem={linkOption}
                items={linkRoles}
                itemToString={(item) => (item ? item.label : '')}
                onChange={(selected: any) => handleChangeLinkOption(selected)}
                buttonProps={{ className: styles.roleListBtn }}
              />
            </div>
          </div>
          {activateTeams?.map((team) => (
            <div key={team.id} className={clsx(styles.sharedItem, styles.ownerItem)}>
              <div className={styles.shareTarget}>
                {team.name}
              </div>
              <div className={styles.shareAction}>
                <Combobox
                  selectedItem={initialTeamRole(team.id)}
                  items={teamRoles}
                  itemToString={(item) => (item ? item.label : '')}
                  buttonProps={{ className: styles.roleListBtn }}
                  onChange={(selected: any) => handleChangeTeamRole(team.id, selected.value)}
                />
              </div>
            </div>
          ))
          }
          {shareUsers?.map((shareUser) => (
            <div key={shareUser.id} className={clsx(styles.sharedItem, styles.ownerItem)}>
              <div className={styles.shareTarget}>
                {shareUser?.firstName && shareUser?.lastName
                  ? `${shareUser.firstName} ${shareUser.lastName}`
                  : shareUser?.email}
              </div>
              <div className={styles.shareAction}>
                <Combobox
                  initialSelectedItem={{ label: capitalize(shareUser.role?.type) || 'Owner' }}
                  disabled={(shareUser.role?.type || 'Owner') === 'Owner'}
                  items={userRoles}
                  itemToString={(item) => (item ? item.label : '')}
                  onChange={(selected: any) => handleChangeInviteRole(shareUser.email, selected.value)}
                  buttonProps={{ className: styles.roleListBtn }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"PRO Feature"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            This feature is not available to Free users. Upgrade to a
            <br/>paid plan for unlimited access and more!
          </Pane>)}
      />
      <UpgradeToProModal
        isShown={isShowUpgradeModal}
        setIsShown={setShowUpgradeModal}
      />
    </Dialog>
  );
}
