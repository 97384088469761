import HeadingInput from "./HeadingInput";
import React, {useEffect, useRef, useState} from "react";
import {majorScale, Pane, Text} from "evergreen-ui";
import clsx from "clsx";
import styles from "./TaskItem.module.scss";
import {TaskService} from "../../services/task/task.service";
import _ from "lodash"
import {useDrag, useDrop} from "react-dnd";
import {getUser} from "../../utils/store";
import Expand from "react-expand-animated";
import {TASK_TYPE} from "../../services/task/tasks.helper";

type Props = {
  task: any,
  isSelected?: boolean,
  onSelected?: Function,
  draggableTasks: any[],
  updateOrderTasks: Function
}

const taskService = new TaskService();

const HeadingItem = ({task, isSelected, onSelected, draggableTasks, updateOrderTasks}: Props) => {
  const [isEditing, setEditing] = useState(false);
  const editPanelRef = useRef(null);
  const headingSubmit = useRef({...task} as any);
  const latestData = useRef({});

  const saveHeading = async () => {
    console.log("on save heading")
    setEditing(false);
    await taskService.partialUpdate({
      id: task.id, title: headingSubmit.current.title
    });
  }

  const handleClickOutTaskItem = async (event: any) => {
    const isTaskSelected = _.get(latestData.current, "isSelected", false);
    if(editPanelRef?.current){
      // @ts-ignore
      const isClickInsideEditPanel = editPanelRef.current.contains(event.target);
      if(isClickInsideEditPanel) return;

      await saveHeading();
    }
    else if(isTaskSelected){
      onSelected && onSelected(null);
    }
  };

  useEffect(() => {
    latestData.current = {...latestData.current, isSelected};
  }, [isSelected]);

  const onHandleDeleteKey = (event: any) => {
    const isTaskSelected = _.get(latestData.current, "isSelected", false);
    if(!editPanelRef?.current && isTaskSelected){
      const user = getUser();
      const deleteKeyCode = 8;
      if(event.keyCode === deleteKeyCode){
        taskService.partialUpdate({
          id: task.id,
          isDelete: true,
          isDeletedBy: user.uid
        });
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutTaskItem, true);
    document.addEventListener('keyup', onHandleDeleteKey, true);
    return () => {
      document.removeEventListener('click', handleClickOutTaskItem, true);
      document.removeEventListener('keyup', onHandleDeleteKey, true);
    };
  }, []);

  const setHeadingInfo = (heading: any) => {
    headingSubmit.current = heading;
  }

  const [{ isDragging }, dragTaskRef] = useDrag(() => ({
    type: 'TASK',
    item: {
      type: 'TASK',
      task: task
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }), [task]);

  const [{ isDropTaskOver, isDropHeadingOver }, dropTaskRef] = useDrop(() => ({
    accept: ["TASK"],
    collect: (monitor) => ({
      isDropTaskOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "TASK"
        && _.get(monitor.getItem(), "task.type") !== TASK_TYPE.HEADING
      ,
      isDropHeadingOver: monitor.isOver()
        && _.get(monitor.getItem(), "type") === "TASK"
        && _.get(monitor.getItem(), "task.type") === TASK_TYPE.HEADING
    }),
    canDrop: (item: any, monitor) => {
      const isTask = _.get(item, "type") === "TASK";
      const sameProject = _.get(item.task, "project") === _.get(task, "project");
      const isTodoTask = !_.get(item.task, "isComplete");

      return isTask && isTodoTask && sameProject;
    },
    drop: async (item: any, monitor)  => {
      const indexOfDropTask = (draggableTasks || [])?.findIndex(t => t.id === task.id);
      // if drop to unexpected task
      if(indexOfDropTask < 0) {
        return
      }

      const nextTask = _.get(draggableTasks, indexOfDropTask + 1);
      const isLastTask = !nextTask;

      const updateDraggableTask = (id: string, newOrdinal: number) => {
        const taskInList = draggableTasks?.find(task => task.id === id);
        if(taskInList){
          taskInList.ordinal = newOrdinal;
          const sortByOrdinalTask = (draggableTasks?.filter(task => !task.isComplete) || [])
            .sort((first, second) => {
              return first.ordinal > second.ordinal ? -1 : 1;
            })
          updateOrderTasks && updateOrderTasks(sortByOrdinalTask);
        }
      }

      if(nextTask){
        const isValidOrdinal = nextTask.ordinal && task.ordinal;
        if(!isValidOrdinal) return;
        const newOrdinal = Math.round((task.ordinal + nextTask.ordinal)/2);
        taskService.partialUpdate(

          {
            id: item.task.id,
            ordinal: newOrdinal
          }
        );

        updateDraggableTask(item.task.id, newOrdinal);
      }
      else if(isLastTask && task.ordinal){
        const newOrdinal = Math.round(task.ordinal - 10000);
        taskService.partialUpdate(
          {
            id: item.task.id,
            ordinal: newOrdinal
          }
        );

        updateDraggableTask(item.task.id, newOrdinal);
      }
    }
  }), [task]);

  const onItemRef = (ref: any) => {
    dropTaskRef(ref);
    dragTaskRef(ref);
  }

  const isFirstTask = () => {
    return draggableTasks.indexOf(task) === 0;
  }

  return (
    <Pane
      cursor={"pointer"}
      onClick={() => {onSelected && onSelected(task.id)}}
      display={isDragging ? 'none' : 'unset'}
      ref={onItemRef}
    >
      {!isEditing &&
        <Pane
          borderRadius="4px"
          className={clsx(isSelected && styles.headingSelected, styles.noSelectAble, styles.headingPanel)}
          onDoubleClick={() => setEditing(true)}
          marginBottom={majorScale(1)}
          marginTop={isFirstTask() ? 0 : majorScale(1)}
        >
          <Text className={clsx(styles.headingTitle)}>{
            !_.isEmpty(_.get(headingSubmit?.current, "title")) ? _.get(headingSubmit?.current, "title") : "New Heading"
          }</Text>
        </Pane>
      }
      {isEditing &&
        <Pane
          ref={editPanelRef}
          className={clsx(styles.headingPanel, styles.headingSelected)}
          marginBottom={majorScale(1)}
          marginTop={isFirstTask() ? 0 : majorScale(1)}
        >
          <HeadingInput
            setHeadingInfo={setHeadingInfo}
            saveHeading={saveHeading}
            project={task.project}
            heading={{...headingSubmit.current}}
          />
        </Pane>
      }
      <Expand
        duration={200}
        open={isDropHeadingOver}
        transitions={["height", "opacity"]}
      >
        <Pane className={clsx(isDropHeadingOver && styles.taskDropOver)}/>
      </Expand>
      <Pane paddingX={majorScale(2)} marginLeft={majorScale(2)}>
        <Expand
          duration={200}
          open={isDropTaskOver}
          transitions={["height", "opacity"]}
        >
          <Pane className={clsx(isDropTaskOver && styles.taskDropOver)}/>
        </Expand>
      </Pane>
    </Pane>
  )
}

export default HeadingItem;
