import {useCollection} from "@nandorojo/swr-firestore";
import {FolderSchema} from "../../schemas";
import {FOLDER_COLLECTION} from "../../utils/constants";
import {getUser} from "../../utils/store";
import React, {useEffect, useReducer, useState} from "react";
import {addFolderRoleSharing, mergeFolderStructure} from "../../services/folder";
import ShareFolderItem from "./ShareFolderItem";
import {db} from "../../utils/firebase";
import _ from "lodash"
import {useActivateTeam} from "../../services/team/team.helper";

const ShareFolders = () => {
  const user = getUser();
  const uid = user?.uid || '';
  const [folders, setFolderTree] = useState([] as FolderSchema[]);
  const [children, setChildren] = useState([] as FolderSchema[]);
  const {activateTeams} = useActivateTeam();
  const [parentShareFolders, setParentShareFolders] = useState([] as FolderSchema[]);
  const [shareToTeamFoldersMap, dispatchTeamShareFoldersMap] = useReducer((state: any, teamShareFolders: any) => {
    return {...state, ...teamShareFolders};
  }, {});

  // Query share folders which are share to single users
  const { data: shareToOnUserFolders } = useCollection<FolderSchema>(FOLDER_COLLECTION, {
    where: [
      [`roles.${uid}.type`, 'in', ['editor', 'commenter', 'viewer']],
      ['isDeleted', '==', false],
      ['isParentDeleted', '==', false],
    ],
    listen: true,
  });

  // Merge folders which are shared to single users and teams
  useEffect(() => {
    let shareFolders = [...(shareToOnUserFolders || [])];
    Object.values(shareToTeamFoldersMap).forEach((folders: any) => {
      const removeDuplicateFolder = (folders || [])
        // The folder of owner user should not display on the share folders tree list
        .filter((folder: any) => _.get(folder.roles, user.uid) !== "owner")
        // To prevent duplicate share folders, because the folder can be shared multiple by share to single users and share to team
        .filter(
          (folder: any) => !shareFolders.find(shareDoc => shareDoc.id === folder.id)
        )

      shareFolders = [...shareFolders, ...removeDuplicateFolder]
    });

    setParentShareFolders(shareFolders);
  }, [shareToOnUserFolders, shareToTeamFoldersMap]);

  useEffect(() => {

  }, [shareToTeamFoldersMap])

  // Fetch children folders
  useEffect(() => {
    if(_.isEmpty(parentShareFolders)) return;
    db.collection(FOLDER_COLLECTION)
      .where("parentFolder", "in", parentShareFolders?.map(folder => {
      return db.collection(FOLDER_COLLECTION).doc(folder.id)
    }))
      .onSnapshot(querySnapshot => {
        const folders = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id})) as FolderSchema[];
        setChildren(folders);
      });
  }, [parentShareFolders]);

  // Fetch share folders which are shared to teams
  useEffect(() => {
    if((activateTeams || []).length <= 0) return;

    activateTeams.forEach((team: any) => {
      db.collection(FOLDER_COLLECTION)
        .where(`teamRoles.${team.id}.type`, 'in', ['editor', 'commenter', 'viewer'])
        .where('isDeleted', '==', false)
        .where('isParentDeleted', '==', false)
        .onSnapshot(resp => {
          dispatchTeamShareFoldersMap({
            [team.id]: resp.docs.map(folderDoc => ({...folderDoc.data(), id: folderDoc.id}))
          })
        })
    })
  }, [activateTeams])

  useEffect(() => {
    if(!user) return;
    const res = mergeFolderStructure(parentShareFolders, children);
    addFolderRoleSharing(res, user.uid);
    if (res) {
      setFolderTree(res);
    }
  }, [parentShareFolders, children])

  return (
    <>
      {folders.map(shareFolder => (<ShareFolderItem folder={shareFolder}/>))}
    </>
  )
}

export default ShareFolders;
