import { useEffect, useState } from 'react';
import useScript from './useScript';

import * as cmx from 'libs/mxgraph';

const mx = cmx.mx;

/**
 * Hold Alt to ignore drop target.
 */
var mxGraphHandlerIsValidDropTarget = mx.mxGraphHandler.prototype.isValidDropTarget;
mx.mxGraphHandler.prototype.isValidDropTarget = function(target, me)
{
  return mxGraphHandlerIsValidDropTarget.apply(this, arguments) &&
    !cmx.mxEvent.isAltDown(me.getEvent);
};

/* eslint-disable */
var mxCellEditorGetInitialValue = mx.mxCellEditor.prototype.getInitialValue;
mx.mxCellEditor.prototype.getInitialValue = function(state, trigger)
{
  if (cmx.mxUtils.getValue(state.style, 'html', '0') == '0')
  {
    return mxCellEditorGetInitialValue.apply(this, arguments);
  }
  else
  {
    var result = this.graph.getEditingValue(state.cell, trigger)

    if (cmx.mxUtils.getValue(state.style, 'nl2Br', '1') == '1')
    {
      result = result.replace(/\n/g, '<br/>');
    }

    result = this.graph.sanitizeHtml(result, true);

    return result;
  }
};

var mxCellEditorGetCurrentValue = mx.mxCellEditor.prototype.getCurrentValue;
mx.mxCellEditor.prototype.getCurrentValue = function(state)
{
  if (cmx.mxUtils.getValue(state.style, 'html', '0') == '0')
  {
    return mxCellEditorGetCurrentValue.apply(this, arguments);
  }
  else
  {
    var result = this.graph.sanitizeHtml(this.textarea.innerHTML, true);

    if (cmx.mxUtils.getValue(state.style, 'nl2Br', '1') == '1')
    {
      result = result.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>');
    }
    else
    {
      result = result.replace(/\r\n/g, '').replace(/\n/g, '');
    }

    return result;
  }
};

mx.mxGraphView.prototype.validate = function(cell)
{
  var t0 = mx.mxLog.enter('mxGraphView.validate');
  window.status = mx.mxResources.get(this.updatingDocumentResource) ||
    this.updatingDocumentResource;

  this.resetValidationState();

  // Improves IE rendering speed by minimizing reflows
  var prevDisplay = null;

  if (this.optimizeVmlReflows && this.canvas != null && this.textDiv == null &&
    ((document.documentMode === 8 && !mx.mxClient.IS_EM) || mx.mxClient.IS_QUIRKS))
  {
    // Placeholder keeps scrollbar positions when canvas is hidden
    this.placeholder = document.createElement('div');
    this.placeholder.style.position = 'absolute';
    this.placeholder.style.width = this.canvas.clientWidth + 'px';
    this.placeholder.style.height = this.canvas.clientHeight + 'px';
    this.canvas.parentNode.appendChild(this.placeholder);

    prevDisplay = this.drawPane.style.display;
    this.canvas.style.display = 'none';

    // Creates temporary DIV used for text measuring in mxText.updateBoundingBox
    this.textDiv = document.createElement('div');
    this.textDiv.style.position = 'absolute';
    this.textDiv.style.whiteSpace = 'nowrap';
    this.textDiv.style.visibility = 'hidden';
    this.textDiv.style.display = (mx.mxClient.IS_QUIRKS) ? 'inline' : 'inline-block';
    this.textDiv.style.zoom = '1';

    document.body.appendChild(this.textDiv);
  }

  var graphBounds = this.getBoundingBox(this.validateCellState(
    this.validateCell(cell || ((this.currentRoot != null) ?
      this.currentRoot : this.graph.getModel().getRoot()))));
  // graphBounds = graphBounds.y < 0 ? { x: 1207, y: 312, h:582, w: 411.5} : graphBounds
  if (graphBounds instanceof mx.mxRectangle && graphBounds.y < 0) {
    graphBounds.x = 1207;
    graphBounds.y = 312;
    graphBounds.height = 582;
    graphBounds.width = 411.5;
  }
  this.setGraphBounds((graphBounds != null) ? graphBounds : this.getEmptyBounds());
  this.validateBackground();

  if (prevDisplay != null)
  {
    this.canvas.style.display = prevDisplay;
    this.textDiv.parentNode.removeChild(this.textDiv);

    if (this.placeholder != null)
    {
      this.placeholder.parentNode.removeChild(this.placeholder);
    }

    // Textdiv cannot be reused
    this.textDiv = null;
  }

  this.resetValidationState();

  window.status = mx.mxResources.get(this.doneResource) ||
    this.doneResource;
  mx.mxLog.leave('mxGraphView.validate', t0);
};

// Overwrite function
mx.mxCellState.prototype.clone = function()
{
  var clone = new mx.mxCellState(this.view, this.cell, this.style);

  // Clones the absolute points
  if (this.absolutePoints != null)
  {
    clone.absolutePoints = [];

    for (var i = 0; i < this.absolutePoints.length; i++)
    {
      if (this.absolutePoints[i]) {
        if (typeof this.absolutePoints[i].clone === 'function') {
          clone.absolutePoints[i] = this.absolutePoints[i].clone();
        } else {
          clone.absolutePoints[i] = Object.assign(Object.create(Object.getPrototypeOf(this.absolutePoints[i])), this.absolutePoints[i]);
        }
      }
    }
  }

  if (this.origin != null)
  {
    clone.origin = this.origin.clone();
  }

  if (this.absoluteOffset != null)
  {
    clone.absoluteOffset = this.absoluteOffset.clone();
  }

  if (this.boundingBox != null)
  {
    clone.boundingBox = this.boundingBox.clone();
  }

  clone.terminalDistance = this.terminalDistance;
  clone.segments = this.segments;
  clone.length = this.length;
  clone.x = this.x;
  clone.y = this.y;
  clone.width = this.width;
  clone.height = this.height;
  clone.unscaledWidth = this.unscaledWidth;
  clone.unscaledHeight = this.unscaledHeight;

  return clone;
};

function scriptFile(fileName) {
  const isDev = process.env.NODE_ENV !== 'production';
  return isDev ? fileName : fileName.replace('.js', '.min.js');
}

function useLoad() {
  const [isMxScriptReady, setMxScriptReady] = useState(false);


  useScript(scriptFile('/mxgraphEditor/editorInit.js'));
  useScript(scriptFile('/mxgraph/js/mxClient.js'));
  // window.mxClient = mx.mxClient;
  // useScript("/mxgraph/js/util/mxLog.js");
  window.mxLog = mx.mxLog;
  // useScript('/mxgraph/js/util/mxObjectIdentity.js');
  window.mxObjectIdentity = mx.mxObjectIdentity;
  // useScript('/mxgraph/js/util/mxDictionary.js');
  window.mxDictionary = mx.mxDictionary;
  // useScript('/mxgraph/js/util/mxResources.js');
  window.mxResources = mx.mxResources;
  // useScript('/mxgraph/js/util/mxPoint.js');
  window.mxPoint = mx.mxPoint;
  // useScript('/mxgraph/js/util/mxRectangle.js');
  window.mxRectangle = mx.mxRectangle;
  // useScript('/mxgraph/js/util/mxEffects.js');
  window.mxEffects = mx.mxEffects;
  // useScript('/mxgraph/js/util/mxUtils.js');
  window.mxUtils = cmx.mxUtils;
  // useScript("/mxgraph/js/util/mxConstants.js");
  window.mxConstants = cmx.mxConstants;
  // useScript('/mxgraph/js/util/mxEventObject.js');
  window.mxEventObject = mx.mxEventObject;
  // useScript('/mxgraph/js/util/mxMouseEvent.js');
  window.mxMouseEvent = mx.mxMouseEvent;
  // useScript('/mxgraph/js/util/mxEventSource.js');
  window.mxEventSource = mx.mxEventSource;
  // useScript('/mxgraph/js/util/mxEvent.js');
  window.mxEvent = cmx.mxEvent;
  // useScript('/mxgraph/js/util/mxXmlRequest.js');
  window.mxXmlRequest = mx.mxXmlRequest;
  // useScript('/mxgraph/js/util/mxClipboard.js');
  window.mxClipboard = mx.mxClipboard;
  // useScript('/mxgraph/js/util/mxWindow.js');
  window.mxWindow = mx.mxWindow;
  // useScript('/mxgraph/js/util/mxForm.js');
  window.mxForm = mx.mxForm;
  // useScript('/mxgraph/js/util/mxImage.js');
  window.mxImage = mx.mxImage;
  // useScript('/mxgraph/js/util/mxDivResizer.js');
  window.mxDivResizer = mx.mxDivResizer;
  // useScript("/mxgraph/js/util/mxDragSource.js");
  window.mxDragSource = mx.mxDragSource;
  // useScript('/mxgraph/js/util/mxToolbar.js');
  window.mxToolbar = mx.mxToolbar;
  // useScript('/mxgraph/js/util/mxUndoableEdit.js');
  window.mxUndoableEdit = mx.mxUndoableEdit;
  // useScript('/mxgraph/js/util/mxUndoManager.js');
  window.mxUndoManager = mx.mxUndoManager;
  // useScript('/mxgraph/js/util/mxUrlConverter.js');
  window.mxUrlConverter = mx.mxUrlConverter;
  // useScript('/mxgraph/js/util/mxPanningManager.js');
  window.mxPanningManager = mx.mxPanningManager;
  // useScript("/mxgraph/js/util/mxPopupMenu.js");
  window.mxPopupMenu = cmx.mxPopupMenu;
  // useScript('/mxgraph/js/util/mxAutoSaveManager.js');
  window.mxAutoSaveManager = cmx.mxAutoSaveManager;
  // useScript('/mxgraph/js/util/mxAnimation.js');
  window.mxAnimation = mx.mxAnimation;
  // useScript('/mxgraph/js/util/mxMorphing.js');
  window.mxMorphing = mx.mxMorphing;
  // useScript('/mxgraph/js/util/mxImageBundle.js');
  window.mxImageBundle = mx.mxImageBundle;
  // useScript('/mxgraph/js/util/mxImageExport.js');
  window.mxImageExport = mx.mxImageExport;
  // useScript('/mxgraph/js/util/mxAbstractCanvas2D.js');
  window.mxAbstractCanvas2D = cmx.mxAbstractCanvas2D;
  // useScript('/mxgraph/js/util/mxXmlCanvas2D.js');
  window.mxXmlCanvas2D = mx.mxXmlCanvas2D;
  // useScript('/mxgraph/js/util/mxSvgCanvas2D.js');
  window.mxSvgCanvas2D = cmx.mxSvgCanvas2D;
  // useScript('/mxgraph/js/util/mxVmlCanvas2D.js');
  window.mxVmlCanvas2D = mx.mxVmlCanvas2D;
  // useScript('/mxgraph/js/util/mxGuide.js');
  window.mxGuide = mx.mxGuide;
  // useScript('/mxgraph/js/shape/mxShape.js');
  window.mxShape = cmx.mxShape;
  // useScript('/mxgraph/js/shape/mxStencil.js');
  window.mxStencil = mx.mxStencil;
  // useScript('/mxgraph/js/shape/mxStencilRegistry.js');
  window.mxStencilRegistry = mx.mxStencilRegistry;
  // useScript('/mxgraph/js/shape/mxMarker.js');
  window.mxMarker = mx.mxMarker;
  // useScript('/mxgraph/js/shape/mxActor.js');
  window.mxActor =  mx.mxActor;
  // useScript('/mxgraph/js/shape/mxCloud.js');
  window.mxCloud = mx.mxCloud;
  // useScript('/mxgraph/js/shape/mxRectangleShape.js');
  window.mxRectangleShape = cmx.mxRectangleShape;
  // useScript('/mxgraph/js/shape/mxEllipse.js');
  window.mxEllipse = mx.mxEllipse;
  // useScript('/mxgraph/js/shape/mxDoubleEllipse.js');
  window.mxDoubleEllipse = mx.mxDoubleEllipse;
  // useScript('/mxgraph/js/shape/mxRhombus.js');
  window.mxRhombus = mx.mxRhombus;
  // useScript('/mxgraph/js/shape/mxPolyline.js');
  window.mxPolyline = mx.mxPolyline;
  // useScript('/mxgraph/js/shape/mxArrow.js');
  window.mxArrow = mx.mxArrow;
  // useScript('/mxgraph/js/shape/mxArrowConnector.js');
  window.mxArrowConnector = mx.mxArrowConnector;
  // useScript('/mxgraph/js/shape/mxText.js');
  window.mxText = mx.mxText;
  // useScript('/mxgraph/js/shape/mxTriangle.js');
  window.mxTriangle = mx.mxTriangle;
  // useScript('/mxgraph/js/shape/mxHexagon.js');
  window.mxHexagon = mx.mxHexagon;
  // useScript('/mxgraph/js/shape/mxLine.js');
  window.mxLine = mx.mxLine;
  // useScript("/mxgraph/js/shape/mxImageShape.js");
  window.mxImageShape = mx.mxImageShape;
  // useScript('/mxgraph/js/shape/mxLabel.js');
  window.mxLabel = cmx.mxLabel;
  // useScript('/mxgraph/js/shape/mxCylinder.js');
  window.mxCylinder = mx.mxCylinder;
  // useScript('/mxgraph/js/shape/mxConnector.js');
  window.mxConnector = mx.mxConnector;
  // useScript('/mxgraph/js/shape/mxSwimlane.js');
  window.mxSwimlane = mx.mxSwimlane;
  // useScript('/mxgraph/js/shape/mxCircleButton.js');
  window.mxCircleButton = cmx.mxCircleButton;
  // useScript('/mxgraph/js/layout/mxGraphLayout.js');
  window.mxGraphLayout = mx.mxGraphLayout;
  // useScript('/mxgraph/js/layout/mxStackLayout.js');
  window.mxStackLayout = mx.mxStackLayout;
  // useScript('/mxgraph/js/layout/mxPartitionLayout.js');
  window.mxPartitionLayout = mx.mxPartitionLayout;
  // useScript('/mxgraph/js/layout/mxCompactTreeLayout.js');
  window.mxCompactTreeLayout = mx.mxCompactTreeLayout;
  // useScript('/mxgraph/js/layout/mxRadialTreeLayout.js');
  window.mxRadialTreeLayout = mx.mxRadialTreeLayout;
  // useScript('/mxgraph/js/layout/mxFastOrganicLayout.js');
  window.mxFastOrganicLayout = mx.mxFastOrganicLayout;
  // useScript('/mxgraph/js/layout/mxCircleLayout.js');
  window.mxCircleLayout = mx.mxCircleLayout;
  // useScript('/mxgraph/js/layout/mxParallelEdgeLayout.js');
  window.mxParallelEdgeLayout = mx.mxParallelEdgeLayout;
  // useScript('/mxgraph/js/layout/mxCompositeLayout.js');
  window.mxCompositeLayout = mx.mxCompositeLayout;
  // useScript('/mxgraph/js/layout/mxEdgeLabelLayout.js');
  window.mxEdgeLabelLayout = mx.mxEdgeLabelLayout;
  // useScript(
  //   '/mxgraph/js/layout/hierarchical/model/mxGraphAbstractHierarchyCell.js',
  // );
  window.mxGraphAbstractHierarchyCell = mx.mxGraphAbstractHierarchyCell;
  // useScript('/mxgraph/js/layout/hierarchical/model/mxGraphHierarchyNode.js');
  window.mxGraphHierarchyNode = mx.mxGraphHierarchyNode;
  // useScript('/mxgraph/js/layout/hierarchical/model/mxGraphHierarchyEdge.js');
  window.mxGraphHierarchyEdge = mx.mxGraphHierarchyEdge;
  // useScript('/mxgraph/js/layout/hierarchical/model/mxGraphHierarchyModel.js');
  window.mxGraphHierarchyModel = mx.mxGraphHierarchyModel;
  // useScript('/mxgraph/js/layout/hierarchical/model/mxSwimlaneModel.js');
  window.mxSwimlaneModel = mx.mxSwimlaneModel;
  // useScript(
  //   '/mxgraph/js/layout/hierarchical/stage/mxHierarchicalLayoutStage.js',
  // );
  window.mxHierarchicalLayoutStage = mx.mxHierarchicalLayoutStage;
  // useScript(
  //   '/mxgraph/js/layout/hierarchical/stage/mxMedianHybridCrossingReduction.js',
  // );
  window.mxMedianHybridCrossingReduction = mx.mxMedianHybridCrossingReduction;
  // useScript('/mxgraph/js/layout/hierarchical/stage/mxMinimumCycleRemover.js');
  window.mxMinimumCycleRemover = mx.mxMinimumCycleRemover;
  // useScript('/mxgraph/js/layout/hierarchical/stage/mxCoordinateAssignment.js');
  window.mxCoordinateAssignment = mx.mxCoordinateAssignment;
  // useScript('/mxgraph/js/layout/hierarchical/stage/mxSwimlaneOrdering.js');
  window.mxSwimlaneOrdering = mx.mxSwimlaneOrdering;
  // useScript('/mxgraph/js/layout/hierarchical/mxHierarchicalLayout.js');
  window.mxHierarchicalLayout = mx.mxHierarchicalLayout;
  // useScript('/mxgraph/js/layout/hierarchical/mxSwimlaneLayout.js');
  window.mxSwimlaneLayout = mx.mxSwimlaneLayout;
  // useScript('/mxgraph/js/model/mxGraphModel.js');
  window.mxGraphModel = cmx.mxGraphModel;
  window.mxRootChange = cmx.mxRootChange;
  window.mxChildChange = cmx.mxChildChange;
  window.mxTerminalChange = cmx.mxTerminalChange;
  window.mxValueChange = cmx.mxValueChange;
  window.mxStyleChange = cmx.mxStyleChange;
  window.mxGeometryChange = cmx.mxGeometryChange;
  window.mxCollapseChange = cmx.mxCollapseChange;
  window.mxVisibleChange = cmx.mxVisibleChange;
  window.mxCellAttributeChange = cmx.mxCellAttributeChange;

  // useScript('/mxgraph/js/model/mxCell.js');
  window.mxCell = cmx.mxCell;
  // useScript('/mxgraph/js/model/mxGeometry.js');
  window.mxGeometry = cmx.mxGeometry;
  // useScript('/mxgraph/js/model/mxCellPath.js');
  window.mxCellPath = mx.mxCellPath;
  // useScript('/mxgraph/js/view/mxPerimeter.js');
  window.mxPerimeter = mx.mxPerimeter;
  // useScript('/mxgraph/js/view/mxPrintPreview.js');
  window.mxPrintPreview = mx.mxPrintPreview;
  // useScript('/mxgraph/js/view/mxStylesheet.js');
  window.mxStylesheet = cmx.mxStylesheet;
  // useScript('/mxgraph/js/view/mxCellState.js');
  window.mxCellState = mx.mxCellState;
  // useScript('/mxgraph/js/view/mxGraphSelectionModel.js');
  window.mxGraphSelectionModel = mx.mxGraphSelectionModel;
  // useScript('/mxgraph/js/view/mxCellEditor.js');
  window.mxCellEditor = mx.mxCellEditor;
  // useScript('/mxgraph/js/view/mxCellRenderer.js');
  window.mxCellRenderer = mx.mxCellRenderer;
  // useScript('/mxgraph/js/view/mxEdgeStyle.js');
  window.mxEdgeStyle = mx.mxEdgeStyle;
  // useScript('/mxgraph/js/view/mxStyleRegistry.js');
  window.mxStyleRegistry = mx.mxStyleRegistry;
  // useScript('/mxgraph/js/view/mxGraphView.js');
  window.mxGraphView = mx.mxGraphView;
  // useScript('/mxgraph/js/view/mxGraph.js');
  window.mxGraph = cmx.mxGraph;
  // useScript('/mxgraph/js/view/mxCellOverlay.js');
  window.mxCellOverlay = mx.mxCellOverlay;
  // useScript('/mxgraph/js/view/mxOutline.js');
  window.mxOutline = mx.mxOutline;
  // useScript('/mxgraph/js/view/mxMultiplicity.js');
  window.mxMultiplicity = mx.mxMultiplicity;
  // useScript('/mxgraph/js/view/mxLayoutManager.js');
  window.mxLayoutManager = mx.mxLayoutManager;
  // useScript('/mxgraph/js/view/mxSwimlaneManager.js');
  window.mxSwimlaneManager = mx.mxSwimlaneManager;
  // useScript('/mxgraph/js/view/mxTemporaryCellStates.js');
  window.mxTemporaryCellStates = mx.mxTemporaryCellStates;
  // useScript('/mxgraph/js/view/mxCellStatePreview.js');
  window.mxCellStatePreview = mx.mxCellStatePreview;
  // useScript('/mxgraph/js/view/mxConnectionConstraint.js');
  window.mxConnectionConstraint = mx.mxConnectionConstraint;
  // useScript('/mxgraph/js/handler/mxGraphHandler.js');
  window.mxGraphHandler = mx.mxGraphHandler;
  // useScript('/mxgraph/js/handler/mxPanningHandler.js');
  window.mxPanningHandler = mx.mxPanningHandler;
  // useScript("/mxgraph/js/handler/mxPopupMenuHandler.js");
  window.mxPopupMenuHandler = cmx.mxPopupMenuHandler;
  // useScript('/mxgraph/js/handler/mxCellMarker.js');
  window.mxCellMarker = mx.mxCellMarker;
  // useScript('/mxgraph/js/handler/mxSelectionCellsHandler.js');
  window.mxSelectionCellsHandler = mx.mxSelectionCellsHandler;
  // useScript('/mxgraph/js/handler/mxConnectionHandler.js');
  window.mxConnectionHandler = mx.mxConnectionHandler;
  // useScript('/mxgraph/js/handler/mxConstraintHandler.js');
  window.mxConstraintHandler = mx.mxConstraintHandler;
  // useScript('/mxgraph/js/handler/mxRubberband.js');
  window.mxRubberband = mx.mxRubberband;
  // useScript('/mxgraph/js/handler/mxHandle.js');
  window.mxHandle = mx.mxHandle;
  // useScript('/mxgraph/js/handler/mxVertexHandler.js');
  window.mxVertexHandler = mx.mxVertexHandler;
  // useScript("/mxgraph/js/handler/mxEdgeHandler.js");
  window.mxEdgeHandler = mx.mxEdgeHandler;
  // useScript('/mxgraph/js/handler/mxElbowEdgeHandler.js');
  window.mxElbowEdgeHandler = mx.mxElbowEdgeHandler;
  // useScript('/mxgraph/js/handler/mxEdgeSegmentHandler.js');
  window.mxEdgeSegmentHandler = mx.mxEdgeSegmentHandler;
  // useScript("/mxgraph/js/handler/mxKeyHandler.js");
  window.mxKeyHandler = mx.mxKeyHandler;
  // useScript('/mxgraph/js/handler/mxTooltipHandler.js');
  window.mxTooltipHandler = mx.mxTooltipHandler;
  // useScript('/mxgraph/js/handler/mxCellTracker.js');
  window.mxCellTracker = mx.mxCellTracker;

  // useScript("/mxgraph/js/handler/mxCellHighlight.js");
  window.mxCellHighlight = cmx.mxCellHighlight;

  // useScript('/mxgraph/js/editor/mxDefaultKeyHandler.js');
  window.mxDefaultKeyHandler = mx.mxDefaultKeyHandler;
  // useScript('/mxgraph/js/editor/mxDefaultPopupMenu.js');
  window.mxDefaultPopupMenu = mx.mxDefaultPopupMenu;
  // useScript('/mxgraph/js/editor/mxDefaultToolbar.js');
  window.mxDefaultToolbar = mx.mxDefaultToolbar;
  // useScript('/mxgraph/js/editor/mxEditor.js');
  window.mxEditor = cmx.mxEditor;
  // useScript('/mxgraph/js/io/mxCodecRegistry.js');
  window.mxCodecRegistry = mx.mxCodecRegistry;
  // useScript('/mxgraph/js/io/mxCodec.js');
  window.mxCodec = mx.mxCodec;
  // useScript('/mxgraph/js/io/mxObjectCodec.js');
  window.mxObjectCodec = mx.mxObjectCodec;

  // useScript('/mxgraphEditor/js/shapes/mxCircleButton.js');

  // useScript('/mxgraph/js/io/mxCellCodec.js');
  // window.mxCellCodec = mx.mxCellCodec;
  // // useScript('/mxgraph/js/io/mxModelCodec.js');
  // window.mxModelCodec = mx.mxModelCodec;
  // // useScript('/mxgraph/js/io/mxRootChangeCodec.js');
  // window.mxRootChangeCodec = mx.mxRootChangeCodec;
  // useScript('/mxgraph/js/io/mxChildChangeCodec.js');
  // useScript('/mxgraph/js/io/mxTerminalChangeCodec.js');
  // useScript('/mxgraph/js/io/mxGenericChangeCodec.js');
  // useScript('/mxgraph/js/io/mxGraphCodec.js');
  // useScript('/mxgraph/js/io/mxGraphViewCodec.js');
  // useScript('/mxgraph/js/io/mxStylesheetCodec.js');
  // useScript('/mxgraph/js/io/mxDefaultKeyHandlerCodec.js');
  // useScript("/mxgraph/js/io/mxDefaultToolbarCodec.js");
  // window.mxDefaultToolbarCodec = mx.mxDefaultToolbarCodec;
  // useScript('/mxgraph/js/io/mxDefaultPopupMenuCodec.js');
  // useScript('/mxgraph/js/io/mxEditorCodec.js');

  useScript(scriptFile('/mxgraphEditor/js/Init.js'));
  useScript('/mxgraphEditor/deflate/pako.min.js');
  useScript(scriptFile('/mxgraphEditor/deflate/base64.js'));
  useScript(scriptFile('/mxgraphEditor/jscolor/jscolor.js'));
  useScript('/mxgraphEditor/sanitizer/sanitizer.min.js');
  useScript(scriptFile('/mxgraphEditor/js/EditorUi.js'));
  useScript(scriptFile('/mxgraphEditor/js/Editor.js'));
  useScript(scriptFile('/mxgraphEditor/js/Sidebar.js'));
  useScript(scriptFile('/mxgraphEditor/js/Graph.js'));
  useScript(scriptFile('/mxgraphEditor/js/Format.js'));
  useScript(scriptFile('/mxgraphEditor/js/Shapes.js'));
  useScript(scriptFile('/mxgraphEditor/js/Actions.js'));
  useScript(scriptFile('/mxgraphEditor/js/Menus.js'));
  useScript(scriptFile('/mxgraphEditor/js/Toolbar.js'));
  useScript(scriptFile('/mxgraphEditor/js/Dialogs.js'));
  useScript(scriptFile('/mxgraphEditor/js/mxFreehand.js'));
  const status = useScript('/mxgraphEditor/jspdf.umd.min.js');

  useEffect(() => {
    if (status === 'ready') {
      setMxScriptReady(true);
    }
  }, [status]);

  return isMxScriptReady;
}

export default useLoad;
