import firebase from "firebase";
import { functions } from 'utils/firebase';

export type GetPayload = {
  documentId: string
}
export type UploadPayload = {
  documentId: string,
  screenshot: string, // base64
  chart: string, // xml format
  contentType: string // ex: application/xml
}

export class HistoryService{
  private readonly functions: firebase.functions.Functions;

  constructor() {
    this.functions = functions;
  }

  async list(payload: GetPayload): Promise<firebase.functions.HttpsCallableResult>{
    const callable = this.functions.httpsCallable('getHistory');
    return callable({...payload})
  }

  async upload(payload: UploadPayload): Promise<firebase.functions.HttpsCallableResult>{
    const callable = this.functions.httpsCallable('uploadHistory');
    return callable({...payload})
  }
}
