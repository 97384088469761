import firebase from "firebase";
import {OBJECTIVES_COLLECTION} from "../../utils/constants";
import {db} from 'utils/firebase';
import {getUser} from "../../utils/store";

export class ObjectiveService {
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor() {
    this.db = db.collection(OBJECTIVES_COLLECTION);
  }

  async create({page, title, results, ordinal}: any){
    const { uid } = getUser();
    const data = {
      title: title || null,
      page: page || null,
      createdBy: uid,
      results: results || [],
      createdAt: (new Date()).getTime(),
      ordinal: ordinal || (new Date()).getTime()
    }
    console.log("create Objective data", data);
    return this.db.add(data);
  }

  async update({id, title, description, dueDate, assignees, isComplete}: any){
    const { uid } = getUser();
    const data = {
      title: title || null,
      description: description || null,
      dueDate: dueDate || null,
      assignees: assignees || null,
      isComplete: isComplete || false,
      completeDate: isComplete ? (new Date()).getTime() : null,
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  partialUpdate(
    {id, title, isDeletedBy, createdBy, ordinal, results, archived}: any
  ){
    const { uid } = getUser();
    const data = {} as any;

    if (title !== undefined) data["title"] = title;
    if (isDeletedBy !== undefined) data["isDeletedBy"] = isDeletedBy;
    if (createdBy !== undefined) data["createdBy"] = createdBy;
    if (results !== undefined) data["results"] = results;
    if (ordinal) data["ordinal"] = ordinal;
    if (archived !== undefined) data["archived"] = archived;

    data["updatedBy"] = uid;
    this.db.doc(id).update(data).catch(ex => {
      console.log("Objective service update throw ex ", ex.message);
    })
  }

  async updateComplete(id: string, isComplete: boolean){
    const { uid } = getUser();
    const data = {
      isComplete: isComplete,
      completeDate: (new Date()).getTime(),
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  async assign(id: string, assignees: any){
    const { uid } = getUser();
    const data = {
      assignees: assignees,
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  async delete(id: string){
    this.db.doc(id).delete().catch(ex => {
      console.log("delete task ex = ", ex.message);
    })
  }

  async deleteByPageId(page: string){
    this.db.where("project", "==", page).get().then((docObjectives) => {
      docObjectives.forEach(task => {
        task.ref.delete();
      })
    });
  }
}
