import Add from "../../../assets/icons/add.svg";
import Airplane from "../../../assets/icons/airplane.svg";
import AlignCenter from "../../../assets/icons/align-center.svg";
import AlignJustify from "../../../assets/icons/align-justify.svg";
import AlignLeft from "../../../assets/icons/align-left.svg";
import AlignRight from "../../../assets/icons/align-right.svg";
import AlignmentBottom from "../../../assets/icons/alignment-bottom.svg";
import AlignmentHorizontalCenter from "../../../assets/icons/alignment-horizontal-center.svg";
import AlignmentLeft from "../../../assets/icons/alignment-left.svg";
import AlignmentRight from "../../../assets/icons/alignment-right.svg";
import AlignmentTop from "../../../assets/icons/alignment-top.svg";
import AlignmentVerticalCenter from "../../../assets/icons/alignment-vertical-center.svg";
import Annotation from "../../../assets/icons/annotation.svg";
import Application from "../../../assets/icons/application.svg";
import Archive from "../../../assets/icons/archive.svg";
import ArrowBottomLeft from "../../../assets/icons/arrow-bottom-left.svg";
import ArrowBottomRight from "../../../assets/icons/arrow-bottom-right.svg";
import ArrowDown from "../../../assets/icons/arrow-down.svg";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import ArrowTopLeft from "../../../assets/icons/arrow-top-left.svg";
import ArrowTopRight from "../../../assets/icons/arrow-top-right.svg";
import ArrowUp from "../../../assets/icons/arrow-up.svg";
import ArrowsHorizontal from "../../../assets/icons/arrows-horizontal.svg";
import ArrowsVertical from "../../../assets/icons/arrows-vertical.svg";
import Asterisk from "../../../assets/icons/asterisk.svg";
import BankAccount from "../../../assets/icons/bank-account.svg";
import Barcode from "../../../assets/icons/barcode.svg";
import Bold from "../../../assets/icons/bold.svg";
import Book from "../../../assets/icons/book.svg";
import Bookmark from "../../../assets/icons/bookmark.svg";
import Box from "../../../assets/icons/box.svg";
import Briefcase from "../../../assets/icons/briefcase.svg";
import Build from "../../../assets/icons/build.svg";
import Calculator from "../../../assets/icons/calculator.svg";
import Calendar from "../../../assets/icons/calendar.svg";
import Camera from "../../../assets/icons/camera.svg";
import CaretDown from "../../../assets/icons/caret-down.svg";
import CaretLeft from "../../../assets/icons/caret-left.svg";
import CaretRight from "../../../assets/icons/caret-right.svg";
import CaretUp from "../../../assets/icons/caret-up.svg";
import CellTower from "../../../assets/icons/cell-tower.svg";
import Changes from "../../../assets/icons/changes.svg";
import Chart from "../../../assets/icons/chart.svg";
import Chat from "../../../assets/icons/chat.svg";
import ChevronBackward from "../../../assets/icons/chevron-backward.svg";
import ChevronDown from "../../../assets/icons/chevron-down.svg";
import ChevronForward from "../../../assets/icons/chevron-forward.svg";
import ChevronLeft from "../../../assets/icons/chevron-left.svg";
import ChevronRight from "../../../assets/icons/chevron-right.svg";
import ChevronUp from "../../../assets/icons/chevron-up.svg";
import Circle from "../../../assets/icons/circle.svg";
import CircleArrowDown from "../../../assets/icons/circle-arrow-down.svg";
import CircleArrowLeft from "../../../assets/icons/circle-arrow-left.svg";
import CircleArrowRight from "../../../assets/icons/circle-arrow-right.svg";
import CircleArrowUp from "../../../assets/icons/circle-arrow-up.svg";
import Clean from "../../../assets/icons/clean.svg";
import Clipboard from "../../../assets/icons/clipboard.svg";
import Cloud from "../../../assets/icons/cloud.svg";
import CloudDownload from "../../../assets/icons/cloud-download.svg";
import CloudUpload from "../../../assets/icons/cloud-upload.svg";
import Code from "../../../assets/icons/code.svg";
import Cog from "../../../assets/icons/cog.svg";
import CollapseAll from "../../../assets/icons/collapse-all.svg";
import Comment from "../../../assets/icons/comment.svg";
import Compass from "../../../assets/icons/compass.svg";
import Console from "../../../assets/icons/console.svg";
import Contrast from "../../../assets/icons/contrast.svg";
import CreditCard from "../../../assets/icons/credit-card.svg";
import Cross from "../../../assets/icons/cross.svg";
import Crown from "../../../assets/icons/crown.svg";
import Cube from "../../../assets/icons/cube.svg";
import Cut from "../../../assets/icons/cut.svg";
import Dashboard from "../../../assets/icons/dashboard.svg";
import Database from "../../../assets/icons/database.svg";
import Delete from "../../../assets/icons/delete.svg";
import Desktop from "../../../assets/icons/desktop.svg";
import DiagramTree from "../../../assets/icons/diagram-tree.svg";
import DirectionLeft from "../../../assets/icons/direction-left.svg";
import DirectionRight from "../../../assets/icons/direction-right.svg";
import Disable from "../../../assets/icons/disable.svg";
import Document from "../../../assets/icons/document.svg";
import Dollar from "../../../assets/icons/dollar.svg";
import DoubleCaretHorizontal from "../../../assets/icons/double-caret-horizontal.svg";
import DoubleCaretVertical from "../../../assets/icons/double-caret-vertical.svg";
import DoubleChevronDown from "../../../assets/icons/double-chevron-down.svg";
import DoubleChevronLeft from "../../../assets/icons/double-chevron-left.svg";
import DoubleChevronRight from "../../../assets/icons/double-chevron-right.svg";
import DoubleChevronUp from "../../../assets/icons/double-chevron-up.svg";
import DoughnutChart from "../../../assets/icons/doughnut-chart.svg";
import DragHandleVertical from "../../../assets/icons/drag-handle-vertical.svg";
import Draw from "../../../assets/icons/draw.svg";
import DriveTime from "../../../assets/icons/drive-time.svg";
import Duplicate from "../../../assets/icons/duplicate.svg";
import Edit from "../../../assets/icons/edit.svg";
import Eject from "../../../assets/icons/eject.svg";
import Envelope from "../../../assets/icons/envelope.svg";
import Eraser from "../../../assets/icons/eraser.svg";
import Euro from "../../../assets/icons/euro.svg";
import ExpandAll from "../../../assets/icons/expand-all.svg";
import Export from "../../../assets/icons/export.svg";
import EyeOff from "../../../assets/icons/eye-off.svg";
import EyeOpen from "../../../assets/icons/eye-open.svg";
import FastBackward from "../../../assets/icons/fast-backward.svg";
import FastForward from "../../../assets/icons/fast-forward.svg";
import Feed from "../../../assets/icons/feed.svg";
import Film from "../../../assets/icons/film.svg";
import Filter from "../../../assets/icons/filter.svg";
import Flag from "../../../assets/icons/flag.svg";
import Flame from "../../../assets/icons/flame.svg";
import Flash from "../../../assets/icons/flash.svg";
import FolderClose from "../../../assets/icons/folder-close.svg";
import Font from "../../../assets/icons/font.svg";
import Fork from "../../../assets/icons/fork.svg";
import Form from "../../../assets/icons/form.svg";
import Fullscreen from "../../../assets/icons/fullscreen.svg";
import Geolocation from "../../../assets/icons/geolocation.svg";
import GitBranch from "../../../assets/icons/git-branch.svg";
import GitCommit from "../../../assets/icons/git-commit.svg";
import GitMerge from "../../../assets/icons/git-merge.svg";
import GitNewBranch from "../../../assets/icons/git-new-branch.svg";
import GitPull from "../../../assets/icons/git-pull.svg";
import GitPush from "../../../assets/icons/git-push.svg";
import GitRepo from "../../../assets/icons/git-repo.svg";
import Glass from "../../../assets/icons/glass.svg";
import Globe from "../../../assets/icons/globe.svg";
import GlobeNetwork from "../../../assets/icons/globe-network.svg";
import GridView from "../../../assets/icons/grid-view.svg";
import GroupObjects from "../../../assets/icons/group-objects.svg";
import Header from "../../../assets/icons/header.svg";
import HeaderOne from "../../../assets/icons/header-one.svg";
import HeaderTwo from "../../../assets/icons/header-two.svg";
import Headset from "../../../assets/icons/headset.svg";
import Heart from "../../../assets/icons/heart.svg";
import HeartBroken from "../../../assets/icons/heart-broken.svg";
import Help from "../../../assets/icons/help.svg";
import Highlight from "../../../assets/icons/highlight.svg";
import History from "../../../assets/icons/history.svg";
import Home from "../../../assets/icons/home.svg";
import HorizontalDistribution from "../../../assets/icons/horizontal-distribution.svg";
import IdNumber from "../../../assets/icons/id-number.svg";
import Import from "../../../assets/icons/import.svg";
import Inbox from "../../../assets/icons/inbox.svg";
import InfoSign from "../../../assets/icons/info-sign.svg";
import Inheritance from "../../../assets/icons/inheritance.svg";
import InnerJoin from "../../../assets/icons/inner-join.svg";
import Insert from "../../../assets/icons/insert.svg";
import Intersection from "../../../assets/icons/intersection.svg";
import Issue from "../../../assets/icons/issue.svg";
import Italic from "../../../assets/icons/italic.svg";
import Key from "../../../assets/icons/key.svg";
import KeyBackspace from "../../../assets/icons/key-backspace.svg";
import KeyCommand from "../../../assets/icons/key-command.svg";
import KeyControl from "../../../assets/icons/key-control.svg";
import KeyDelete from "../../../assets/icons/key-delete.svg";
import KeyEnter from "../../../assets/icons/key-enter.svg";
import KeyEscape from "../../../assets/icons/key-escape.svg";
import KeyOption from "../../../assets/icons/key-option.svg";
import KeyShift from "../../../assets/icons/key-shift.svg";
import KeyTab from "../../../assets/icons/key-tab.svg";
import Label from "../../../assets/icons/label.svg";
import Layers from "../../../assets/icons/layers.svg";
import LayoutAuto from "../../../assets/icons/layout-auto.svg";
import LayoutGrid from "../../../assets/icons/layout-grid.svg";
import Learning from "../../../assets/icons/learning.svg";
import LeftJoin from "../../../assets/icons/left-join.svg";
import Lifesaver from "../../../assets/icons/lifesaver.svg";
import Lightbulb from "../../../assets/icons/lightbulb.svg";
import Link from "../../../assets/icons/link.svg";
import Locate from "../../../assets/icons/locate.svg";
import Lock from "../../../assets/icons/lock.svg";
import LogIn from "../../../assets/icons/log-in.svg";
import LogOut from "../../../assets/icons/log-out.svg";
import Manual from "../../../assets/icons/manual.svg";
import Map from "../../../assets/icons/map.svg";
import MapMarker from "../../../assets/icons/map-marker.svg";
import Maximize from "../../../assets/icons/maximize.svg";
import Media from "../../../assets/icons/media.svg";
import Menu from "../../../assets/icons/menu.svg";
import Minimize from "../../../assets/icons/minimize.svg";
import MobilePhone from "../../../assets/icons/mobile-phone.svg";
import MobileVideo from "../../../assets/icons/mobile-video.svg";
import Moon from "../../../assets/icons/moon.svg";
import More from "../../../assets/icons/more.svg";
import Mountain from "../../../assets/icons/mountain.svg";
import Move from "../../../assets/icons/move.svg";
import Music from "../../../assets/icons/music.svg";
import Notifications from "../../../assets/icons/notifications.svg";
import NumberedList from "../../../assets/icons/numbered-list.svg";
import Numerical from "../../../assets/icons/numerical.svg";
import Office from "../../../assets/icons/office.svg";
import Offline from "../../../assets/icons/offline.svg";
import PageLayout from "../../../assets/icons/page-layout.svg";
import Paperclip from "../../../assets/icons/paperclip.svg";
import Paragraph from "../../../assets/icons/paragraph.svg";
import Pause from "../../../assets/icons/pause.svg";
import People from "../../../assets/icons/people.svg";
import Person from "../../../assets/icons/person.svg";
import Phone from "../../../assets/icons/phone.svg";
import PieChart from "../../../assets/icons/pie-chart.svg";
import Play from "../../../assets/icons/play.svg";
import Plus from "../../../assets/icons/plus.svg";
import Power from "../../../assets/icons/power.svg";
import Prescription from "../../../assets/icons/prescription.svg";
import Presentation from "../../../assets/icons/presentation.svg";
import Print from "../../../assets/icons/print.svg";
import Projects from "../../../assets/icons/projects.svg";
import Properties from "../../../assets/icons/properties.svg";
import Pulse from "../../../assets/icons/pulse.svg";
import Quote from "../../../assets/icons/quote.svg";
import Random from "../../../assets/icons/random.svg";
import Record from "../../../assets/icons/record.svg";
import Redo from "../../../assets/icons/redo.svg";
import Refresh from "../../../assets/icons/refresh.svg";
import Remove from "../../../assets/icons/remove.svg";
import Repeat from "../../../assets/icons/repeat.svg";
import Reset from "../../../assets/icons/reset.svg";
import Resolve from "../../../assets/icons/resolve.svg";
import RightJoin from "../../../assets/icons/right-join.svg";
import Search from "../../../assets/icons/search.svg";
import Selection from "../../../assets/icons/selection.svg";
import Settings from "../../../assets/icons/settings.svg";
import Share from "../../../assets/icons/share.svg";
import Shield from "../../../assets/icons/shield.svg";
import Shop from "../../../assets/icons/shop.svg";
import ShoppingCart from "../../../assets/icons/shopping-cart.svg";
import Snowflake from "../../../assets/icons/snowflake.svg";
import Sort from "../../../assets/icons/sort.svg";
import SortAlphabetical from "../../../assets/icons/sort-alphabetical.svg";
import Square from "../../../assets/icons/square.svg";
import Star from "../../../assets/icons/star.svg";
import StarEmpty from "../../../assets/icons/star-empty.svg";
import StepBackward from "../../../assets/icons/step-backward.svg";
import StepForward from "../../../assets/icons/step-forward.svg";
import Stop from "../../../assets/icons/stop.svg";
import Stopwatch from "../../../assets/icons/stopwatch.svg";
import Strikethrough from "../../../assets/icons/strikethrough.svg";
import Style from "../../../assets/icons/style.svg";
import SwapHorizontal from "../../../assets/icons/swap-horizontal.svg";
import SwapVertical from "../../../assets/icons/swap-vertical.svg";
import Tag from "../../../assets/icons/tag.svg";
import TakeAction from "../../../assets/icons/take-action.svg";
import Taxi from "../../../assets/icons/taxi.svg";
import TextHighlight from "../../../assets/icons/text-highlight.svg";
import ThumbsDown from "../../../assets/icons/thumbs-down.svg";
import ThumbsUp from "../../../assets/icons/thumbs-up.svg";
import Tick from "../../../assets/icons/tick.svg";
import TickCircle from "../../../assets/icons/tick-circle.svg";
import Time from "../../../assets/icons/time.svg";
import TimelineAreaChart from "../../../assets/icons/timeline-area-chart.svg";
import TimelineBarChart from "../../../assets/icons/timeline-bar-chart.svg";
import TimelineLineChart from "../../../assets/icons/timeline-line-chart.svg";
import Tint from "../../../assets/icons/tint.svg";
import Train from "../../../assets/icons/train.svg";
import Translate from "../../../assets/icons/translate.svg";
import Trash from "../../../assets/icons/trash.svg";
import Tree from "../../../assets/icons/tree.svg";
import TrendingDown from "../../../assets/icons/trending-down.svg";
import TrendingUp from "../../../assets/icons/trending-up.svg";
import Truck from "../../../assets/icons/truck.svg";
import Unarchive from "../../../assets/icons/unarchive.svg";
import Underline from "../../../assets/icons/underline.svg";
import Undo from "../../../assets/icons/undo.svg";
import UngroupObjects from "../../../assets/icons/ungroup-objects.svg";
import Unlock from "../../../assets/icons/unlock.svg";
import Unpin from "../../../assets/icons/unpin.svg";
import Unresolve from "../../../assets/icons/unresolve.svg";
import User from "../../../assets/icons/user.svg";
import VerticalBarChart from "../../../assets/icons/vertical-bar-chart.svg";
import VerticalDistribution from "../../../assets/icons/vertical-distribution.svg";
import Video from "../../../assets/icons/video.svg";
import VolumeDown from "../../../assets/icons/volume-down.svg";
import VolumeOff from "../../../assets/icons/volume-off.svg";
import VolumeUp from "../../../assets/icons/volume-up.svg";
import Walk from "../../../assets/icons/walk.svg";
import WarningSign from "../../../assets/icons/warning-sign.svg";
import Widget from "../../../assets/icons/widget.svg";
import WidgetButton from "../../../assets/icons/widget-button.svg";
import Wrench from "../../../assets/icons/wrench.svg";
import ZoomIn from "../../../assets/icons/zoom-in.svg";
import ZoomOut from "../../../assets/icons/zoom-out.svg"

const availableIcons = {
  add: {icon: Add},
  airplane: {icon: Airplane},
  alignCenter: {icon: AlignCenter},
  alignJustify: {icon: AlignJustify},
  alignLeft: {icon: AlignLeft},
  alignRight: {icon: AlignRight},
  alignmentBottom: {icon: AlignmentBottom},
  alignmentHorizontalCenter: {icon: AlignmentHorizontalCenter},
  alignmentLeft: {icon: AlignmentLeft},
  alignmentRight: {icon: AlignmentRight},
  alignmentTop: {icon: AlignmentTop},
  alignmentVerticalCenter: {icon: AlignmentVerticalCenter},
  annotation: {icon: Annotation},
  application: {icon: Application},
  archive: {icon: Archive},
  arrowBottomLeft: {icon: ArrowBottomLeft},
  arrowBottomRight: {icon: ArrowBottomRight},
  arrowDown: {icon: ArrowDown},
  arrowLeft: {icon: ArrowLeft},
  arrowRight: {icon: ArrowRight},
  arrowTopLeft: {icon: ArrowTopLeft},
  arrowTopRight: {icon: ArrowTopRight},
  arrowUp: {icon: ArrowUp},
  arrowsHorizontal: {icon: ArrowsHorizontal},
  arrowsVertical: {icon: ArrowsVertical},
  asterisk: {icon: Asterisk},
  bankAccount: {icon: BankAccount},
  barcode: {icon: Barcode},
  bold: {icon: Bold},
  book: {icon: Book},
  bookmark: {icon: Bookmark},
  box: {icon: Box},
  briefcase: {icon: Briefcase},
  build: {icon: Build},
  calculator: {icon: Calculator},
  calendar: {icon: Calendar},
  camera: {icon: Camera},
  caretDown: {icon: CaretDown},
  caretLeft: {icon: CaretLeft},
  caretRight: {icon: CaretRight},
  caretUp: {icon: CaretUp},
  cellTower: {icon: CellTower},
  changes: {icon: Changes},
  chart: {icon: Chart},
  chat: {icon: Chat},
  chevronBackward: {icon: ChevronBackward},
  chevronDown: {icon: ChevronDown},
  chevronForward: {icon: ChevronForward},
  chevronLeft: {icon: ChevronLeft},
  chevronRight: {icon: ChevronRight},
  chevronUp: {icon: ChevronUp},
  circle: {icon: Circle},
  circleArrowDown: {icon: CircleArrowDown},
  circleArrowLeft: {icon: CircleArrowLeft},
  circleArrowRight: {icon: CircleArrowRight},
  circleArrowUp: {icon: CircleArrowUp},
  clean: {icon: Clean},
  clipboard: {icon: Clipboard},
  cloud: {icon: Cloud},
  cloudDownload: {icon: CloudDownload},
  cloudUpload: {icon: CloudUpload},
  code: {icon: Code},
  cog: {icon: Cog},
  collapseAll: {icon: CollapseAll},
  comment: {icon: Comment},
  compass: {icon: Compass},
  console: {icon: Console},
  contrast: {icon: Contrast},
  creditCard: {icon: CreditCard},
  cross: {icon: Cross},
  crown: {icon: Crown},
  cube: {icon: Cube},
  cut: {icon: Cut},
  dashboard: {icon: Dashboard},
  database: {icon: Database},
  delete: {icon: Delete},
  desktop: {icon: Desktop},
  diagramTree: {icon: DiagramTree},
  directionLeft: {icon: DirectionLeft},
  directionRight: {icon: DirectionRight},
  disable: {icon: Disable},
  document: {icon: Document},
  dollar: {icon: Dollar},
  doubleCaretHorizontal: {icon: DoubleCaretHorizontal},
  doubleCaretVertical: {icon: DoubleCaretVertical},
  doubleChevronDown: {icon: DoubleChevronDown},
  doubleChevronLeft: {icon: DoubleChevronLeft},
  doubleChevronRight: {icon: DoubleChevronRight},
  doubleChevronUp: {icon: DoubleChevronUp},
  doughnutChart: {icon: DoughnutChart},
  dragHandleVertical: {icon: DragHandleVertical},
  draw: {icon: Draw},
  driveTime: {icon: DriveTime},
  duplicate: {icon: Duplicate},
  edit: {icon: Edit},
  eject: {icon: Eject},
  envelope: {icon: Envelope},
  eraser: {icon: Eraser},
  euro: {icon: Euro},
  expandAll: {icon: ExpandAll},
  export: {icon: Export},
  eyeOff: {icon: EyeOff},
  eyeOpen: {icon: EyeOpen},
  fastBackward: {icon: FastBackward},
  fastForward: {icon: FastForward},
  feed: {icon: Feed},
  film: {icon: Film},
  filter: {icon: Filter},
  flag: {icon: Flag},
  flame: {icon: Flame},
  flash: {icon: Flash},
  folderClose: {icon: FolderClose},
  font: {icon: Font},
  fork: {icon: Fork},
  form: {icon: Form},
  fullscreen: {icon: Fullscreen},
  geolocation: {icon: Geolocation},
  gitBranch: {icon: GitBranch},
  gitCommit: {icon: GitCommit},
  gitMerge: {icon: GitMerge},
  gitNewBranch: {icon: GitNewBranch},
  gitPull: {icon: GitPull},
  gitPush: {icon: GitPush},
  gitRepo: {icon: GitRepo},
  glass: {icon: Glass},
  globe: {icon: Globe},
  globeNetwork: {icon: GlobeNetwork},
  gridView: {icon: GridView},
  groupObjects: {icon: GroupObjects},
  header: {icon: Header},
  headerOne: {icon: HeaderOne},
  headerTwo: {icon: HeaderTwo},
  headset: {icon: Headset},
  heart: {icon: Heart},
  heartBroken: {icon: HeartBroken},
  help: {icon: Help},
  highlight: {icon: Highlight},
  history: {icon: History},
  home: {icon: Home},
  horizontalDistribution: {icon: HorizontalDistribution},
  idNumber: {icon: IdNumber},
  import: {icon: Import},
  inbox: {icon: Inbox},
  infoSign: {icon: InfoSign},
  inheritance: {icon: Inheritance},
  innerJoin: {icon: InnerJoin},
  insert: {icon: Insert},
  intersection: {icon: Intersection},
  issue: {icon: Issue},
  italic: {icon: Italic},
  key: {icon: Key},
  keyBackspace: {icon: KeyBackspace},
  keyCommand: {icon: KeyCommand},
  keyControl: {icon: KeyControl},
  keyDelete: {icon: KeyDelete},
  keyEnter: {icon: KeyEnter},
  keyEscape: {icon: KeyEscape},
  keyOption: {icon: KeyOption},
  keyShift: {icon: KeyShift},
  keyTab: {icon: KeyTab},
  label: {icon: Label},
  layers: {icon: Layers},
  layoutAuto: {icon: LayoutAuto},
  layoutGrid: {icon: LayoutGrid},
  learning: {icon: Learning},
  leftJoin: {icon: LeftJoin},
  lifesaver: {icon: Lifesaver},
  lightbulb: {icon: Lightbulb},
  link: {icon: Link},
  locate: {icon: Locate},
  lock: {icon: Lock},
  logIn: {icon: LogIn},
  logOut: {icon: LogOut},
  manual: {icon: Manual},
  map: {icon: Map},
  mapMarker: {icon: MapMarker},
  maximize: {icon: Maximize},
  media: {icon: Media},
  menu: {icon: Menu},
  minimize: {icon: Minimize},
  mobilePhone: {icon: MobilePhone},
  mobileVideo: {icon: MobileVideo},
  moon: {icon: Moon},
  more: {icon: More},
  mountain: {icon: Mountain},
  move: {icon: Move},
  music: {icon: Music},
  notifications: {icon: Notifications},
  numberedList: {icon: NumberedList},
  numerical: {icon: Numerical},
  office: {icon: Office},
  offline: {icon: Offline},
  pageLayout: {icon: PageLayout},
  paperclip: {icon: Paperclip},
  paragraph: {icon: Paragraph},
  pause: {icon: Pause},
  people: {icon: People},
  person: {icon: Person},
  phone: {icon: Phone},
  pieChart: {icon: PieChart},
  play: {icon: Play},
  plus: {icon: Plus},
  power: {icon: Power},
  prescription: {icon: Prescription},
  presentation: {icon: Presentation},
  print: {icon: Print},
  projects: {icon: Projects},
  properties: {icon: Properties},
  pulse: {icon: Pulse},
  quote: {icon: Quote},
  random: {icon: Random},
  record: {icon: Record},
  redo: {icon: Redo},
  refresh: {icon: Refresh},
  remove: {icon: Remove},
  repeat: {icon: Repeat},
  reset: {icon: Reset},
  resolve: {icon: Resolve},
  rightJoin: {icon: RightJoin},
  search: {icon: Search},
  selection: {icon: Selection},
  settings: {icon: Settings},
  share: {icon: Share},
  shield: {icon: Shield},
  shop: {icon: Shop},
  shoppingCart: {icon: ShoppingCart},
  snowflake: {icon: Snowflake},
  sort: {icon: Sort},
  sortAlphabetical: {icon: SortAlphabetical},
  square: {icon: Square},
  star: {icon: Star},
  starEmpty: {icon: StarEmpty},
  stepBackward: {icon: StepBackward},
  stepForward: {icon: StepForward},
  stop: {icon: Stop},
  stopwatch: {icon: Stopwatch},
  strikethrough: {icon: Strikethrough},
  style: {icon: Style},
  swapHorizontal: {icon: SwapHorizontal},
  swapVertical: {icon: SwapVertical},
  tag: {icon: Tag},
  takeAction: {icon: TakeAction},
  taxi: {icon: Taxi},
  textHighlight: {icon: TextHighlight},
  thumbsDown: {icon: ThumbsDown},
  thumbsUp: {icon: ThumbsUp},
  tick: {icon: Tick},
  tickCircle: {icon: TickCircle},
  time: {icon: Time},
  timelineAreaChart: {icon: TimelineAreaChart},
  timelineBarChart: {icon: TimelineBarChart},
  timelineLineChart: {icon: TimelineLineChart},
  tint: {icon: Tint},
  train: {icon: Train},
  translate: {icon: Translate},
  trash: {icon: Trash},
  tree: {icon: Tree},
  trendingDown: {icon: TrendingDown},
  trendingUp: {icon: TrendingUp},
  truck: {icon: Truck},
  unarchive: {icon: Unarchive},
  underline: {icon: Underline},
  undo: {icon: Undo},
  ungroupObjects: {icon: UngroupObjects},
  unlock: {icon: Unlock},
  unpin: {icon: Unpin},
  unresolve: {icon: Unresolve},
  user: {icon: User},
  verticalBarChart: {icon: VerticalBarChart},
  verticalDistribution: {icon: VerticalDistribution},
  video: {icon: Video},
  volumeDown: {icon: VolumeDown},
  volumeOff: {icon: VolumeOff},
  volumeUp: {icon: VolumeUp},
  walk: {icon: Walk},
  warningSign: {icon: WarningSign},
  widget: {icon: Widget},
  widgetButton: {icon: WidgetButton},
  wrench: {icon: Wrench},
  zoomIn: {icon: ZoomIn},
  zoomOut: {icon: ZoomOut}
};

export default availableIcons;
