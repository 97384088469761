import {DotIcon, majorScale, minorScale, Pane, Text} from "evergreen-ui";
import {useHistory, useLocation} from "react-router-dom";
import clsx from "clsx";
import styles from "./Project.module.scss"
import {useCollection} from "@nandorojo/swr-firestore";
import {TASK_COLLECTION} from "../../../utils/constants";
import {useEffect, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import _ from "lodash";
import {TaskService} from "../../../services/task/task.service";
import {getOwnerProjects} from "../../../utils/store";
import {getOrdinalProjects} from "./TaskUtils";
import {ProjectService} from "../../../services/project/project.service";

type Props = {
  id: string,
  name: string,
  dragAble?: boolean,
}

const taskService = new TaskService();
const projectService = new ProjectService();

const Project = ({id, name, dragAble}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const isSelected = location.pathname.includes(`/${id}`);
  const [todoTasks, setTodoTasks] = useState(0);

  const onProjectClick = () => {
    history.push({
      pathname: '/task/project/' + id,
    })
  }

  const { data: tasks } = useCollection<any>(TASK_COLLECTION, {
    where: [
      ['project', '==', id],
      ['isComplete', '==', false],
      ['isDelete', '==', false],
    ],
    listen: true,
  });

  useEffect(() => {
    setTodoTasks(tasks?.length || 0);
  }, [tasks]);

  const [{ isDragging }, dragProjectRef] = useDrag(() => ({
    type: 'PROJECT',
    item: {
      type: 'PROJECT',
      project: { id, name }
    },
    end: (item, monitor) => {
      monitor.getDropResult();
    },
    canDrag: () => {
      return !!dragAble;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }), [id]);

  const [{ isDropProjectOver, isDropTaskOver }, dropRef] = useDrop(() => ({
    accept: ["PROJECT", "TASK"],
    collect: (monitor) => ({
      isDropProjectOver: monitor.isOver() && _.get(monitor.getItem(), "type") === "PROJECT",
      isDropTaskOver: monitor.isOver() && _.get(monitor.getItem(), "type") === "TASK",
    }),
    canDrop: (item, monitor) => {
      const isProject = _.get(item, "type") === "PROJECT";
      return isProject ? !!dragAble : true;
    },
    drop: async (item: any, monitor)  => {
      const dropItemToProject = async (task: any) => {
        const taskInProject = !!task.project;
        if(taskInProject) return;

        await taskService.partialUpdate({
          id: task.id,
          project: id,
          isPrivate: false,
        });
      }

      const dropProjectToSort = (project: any) => {
        const updateProjects = getOrdinalProjects(project.id, id, getOwnerProjects());
        projectService.updateOrdinalProjects(updateProjects || []);
      }

      const type = item.type;
      switch (type){
        case "TASK":
          await dropItemToProject(item.task);
          break;
        case "PROJECT":
          dropProjectToSort(item.project);
          break;
        default:
          break;
      }
    }
  }), [id]);

  return (
    <>
      <Pane
        display={isDragging ? "none" : "flex"}
        justifyContent={"space-between"}
        padding={minorScale(1)}
        paddingLeft={majorScale(2)}
        marginY="2px"
        cursor={"pointer"}
        onClick={onProjectClick}
        className={clsx(isSelected && styles.selected, styles.projectItem, isDropTaskOver && styles.dropOver)}
        ref={dropRef}
      >
        <Pane display={"flex"} ref={dragProjectRef}>
          <Pane display={"flex"} paddingRight={majorScale(2)}><DotIcon color="#8F95B2" alignSelf={"center"}/></Pane>
          <Pane display={"flex"} width="195px" justifyContent={"space-between"}>
            <Text className={clsx('text-truncate', 'project-label')}>{name}</Text>
            {todoTasks > 0 &&
              <Pane
                minWidth="20px"
                height={majorScale(2)}
                background="#EDEFF5"
                paddingX={minorScale(1)}
                borderRadius="8px"
                display="flex"
                justifyContent={'center'}
                alignSelf={"center"}
              >
                <Pane
                  alignSelf={"center"}
                  fontSize={11}
                  color="#8F95B2"
                  fontWeight={600}
                >{todoTasks}</Pane>
              </Pane>
            }
          </Pane>
        </Pane>
      </Pane>
      <Pane display={isDropProjectOver && !!dragAble ? "flex" : "none"} className={clsx(styles.previewDropPosition)}></Pane>
    </>
  )
}

export default Project;
