import {
  Button,
  Pane,
  Text,
  TrashIcon,
} from 'evergreen-ui';

import styles from './NoteList.module.scss';
import EmptyNoteModal from '../Modal/EmptyNoteModal';
import { useState } from 'react';

export default function TrashNoteHeader() {
  const [isShowEmptyTrash, setShowTrash] = useState(false);

  return (
    <>
      <header className={styles.header}>
      <span className={styles.headerTitle}>Trash</span>
      <Button onClick={() => setShowTrash(true)}>
        <Pane display="flex" justifyContent="center">
          <TrashIcon
            size={12}
            className="mr-2 mt-1"
            fontSize="12"
            color="#D14343"
          />
          <Text fontSize={'12px'}>Empty Trash</Text>
        </Pane>
      </Button>
    </header>
    <EmptyNoteModal isShown={isShowEmptyTrash} setIsShown={setShowTrash} />
    </>
  );
}
