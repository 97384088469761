import {Menu, ShareIcon, TextInput, TrashIcon} from 'evergreen-ui';
import { useCollection } from '@nandorojo/swr-firestore';
import Tree from 'components/Tree';
import React, { useEffect, useState } from 'react';
import { FolderSchema } from 'schemas/folder';
import {addShareStatus, mergeFolderStructure} from 'services/folder';
import { FOLDER_COLLECTION } from 'utils/constants';

import {getUser, isPROTeam} from 'utils/store';
import { Dialog } from 'components/Dialog';
import styles from './index.module.scss';
import clsx from 'clsx';
import useContextMenu from 'hooks/useContextMenu';
import EditIcon from 'components/Icon/EditIcon';
import { FolderService } from 'services/folder';
import ShareFolderModal from "../Folder/ShareFolderModal";

const folderService = new FolderService();

const TreeList = () => {
  const user = getUser();
  const uid = user?.uid || '';
  const [folders, setFolderTree] = useState([] as FolderSchema[]);

  const { data: rawFolders } = useCollection<FolderSchema>(FOLDER_COLLECTION, {
    where: [
      [`roles.${uid}`, '==', 'owner'],
      ['isDeleted', '==', false],
      ['isParentDeleted', '==', false],
    ],
    listen: true,
  });

  useEffect(() => {
    addShareStatus(rawFolders as FolderSchema[]);
    const res = mergeFolderStructure(rawFolders);
    if (res) {
      setFolderTree(res);
    }
  }, [rawFolders]);

  const [folderContext, setFolderContext] = useState({} as FolderSchema);
  const [editFolder, setEditFolder] = useState({} as FolderSchema);
  const { anchorPoint, show } = useContextMenu(() => {
    setFolderContext({} as FolderSchema);
  });

  const [isShowShare, setShowShare] = useState(false);
  const [isShowRename, setShowRename] = useState(false);
  const [newFolderName, setNewFolderName] = useState(folderContext.name);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const [isShowDelete, setShowDelete] = useState(false);

  const showShareModel = () => {
    setShowShare(true);
  }

  const showRenameModal = (folder: FolderSchema) => {
    setEditFolder(folder);
    setShowRename(true);
  };

  const handleRenameFolder = async () => {
    try {
      if (editFolder.id) {
        setShowRename(false);
        await folderService.rename(editFolder.id, newFolderName);
      }
    } finally {
    }
  };

  const showDeleteModal = (folder: FolderSchema) => {
    setEditFolder(folder);
    setShowDelete(true);
  };

  const handleDeleteFolder = async () => {
    try {
      setDeleteLoading(true);
      if (editFolder.id) {
        await folderService.softDelete(editFolder.id);
      }
      setShowDelete(false);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      setNewFolderName(e.target.value);
      await handleRenameFolder();
    }
  }

  useEffect(() => {
    setNewFolderName(editFolder.name);
  }, [editFolder]);

  return (
    <>
      <div className={styles.treeContainer}>
        <Tree data={folders} setFolderContext={setFolderContext} />
      </div>

      {show && folderContext?.id && (
        <Menu>
          <div
            className={styles.menu}
            style={{ top: anchorPoint.y, left: anchorPoint.x }}
          >
            <Menu.Group title={folderContext.name}>
              {isPROTeam() &&
                <Menu.Item
                  className={styles.menuItem}
                  icon={<ShareIcon color={"#8F95B2"}/>}
                  onClick={() => showShareModel()}
                >
                  <span className={styles.contextItemLabel}>Share Folder</span>
                </Menu.Item>
              }
              <Menu.Item
                className={styles.menuItem}
                icon={EditIcon}
                onClick={() => showRenameModal(folderContext)}
              >
                <span className={styles.contextItemLabel}>Rename</span>
              </Menu.Item>
              <Menu.Item
                className={clsx(styles.menuItem, 'trash-item')}
                icon={<TrashIcon color="#D14343" />}
                onClick={() => showDeleteModal(folderContext)}
              >
                <span className={clsx(styles.contextItemLabel)}>Move to trash</span>
              </Menu.Item>
            </Menu.Group>
          </div>
        </Menu>
      )}

      <Dialog
        isShown={isShowRename}
        title="Rename Folder"
        onCloseComplete={() => setShowRename(false)}
        preventBodyScrolling
        confirmLabel="Save"
        onConfirm={handleRenameFolder}
      >
        <TextInput
          width="100%"
          required
          name="name"
          autoFocus={true}
          value={newFolderName}
          onKeyDown={handleKeyDown}
          onChange={(e: any) => setNewFolderName(e.target.value)}
          placeholder="Folder name"
        />
      </Dialog>

      <Dialog
        isShown={isShowDelete}
        title="Move to Trash"
        intent="danger"
        onCloseComplete={() => setShowDelete(false)}
        confirmLabel="Move to Trash"
        onConfirm={handleDeleteFolder}
        isConfirmLoading={isDeleteLoading}
      >
        Are you sure you want to move this folder to Trash?
      </Dialog>
      <ShareFolderModal isShown={isShowShare} setIsShown={setShowShare} folder={folderContext}/>
    </>
  );
};

export default TreeList;
