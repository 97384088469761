import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { Observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import styles from './NotePage.module.scss';
import NoteList from 'components/NoteEditor/NoteList/MyNoteList';
import NoteShareList from 'components/NoteEditor/NoteList/NoteShareList';
import useStores from 'hooks/useStores';
import { NoteListType } from 'schemas';
import NoteTagList from 'components/NoteEditor/NoteList/NoteTagList';
import NoteEditorContainer from 'components/NoteEditor/NoteEditorContainer';
import useQuery from 'hooks/useQuery';

export default function NoteDetailPage() {
  const params = useParams() as any;
  const query = useQuery();
  const ref = query.get('ref') as any;
  const { noteStore } = useStores();

  const noteId = params.id as string;

  useEffect(() => {
    if (noteId) {
      noteStore.setNoteId(noteId);
    }
  }, [noteId]);

  const ListSidebar = useCallback(() => {
    if (ref === NoteListType.SHARE) {
      return <NoteShareList />;
    } else if (ref?.includes('tag:')) {
      const inputArray = ref.split(':');
      const tagId = inputArray[1];
      return <NoteTagList noteList={[] as any} type={NoteListType.TAG} tagId={tagId} />;
    } else {
      return <NoteList type={ref} />;
    }
  }, [ref]);

  return (
    <React.StrictMode>
      <section className={clsx(styles.mainSection)}>
          <div className={clsx(styles.listContainer)}>
            <ListSidebar />
          </div>

          <div className={clsx(styles.editorContainer)}>
            <Observer>
              {() => (
                <>
                  {noteStore.noteId && (
                    <NoteEditorContainer key={noteStore.noteId} />
                  )}
                </>
              )}
            </Observer>
          </div>
        </section>
    </React.StrictMode>
  );
}
