import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, majorScale, Paragraph, TextInput } from 'evergreen-ui';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { DOCUMENT_URL, EVENT_CATALOG, SIGN_UP_SUCCESS, TERM_OF_USE, TRACKING_CATALOG } from 'utils/constants';
import styles from './SignUpRedeem.module.scss';
import {UserService} from "../../services/user";
import {AuthService} from "../../services/authService";

export const SignUpRedeemForm = (
  { onError, onSuccess }: { onError: Function, onSuccess: Function }
  ) => {
  const authService = new AuthService();
  const history = useHistory();
  const location = useLocation();
  const userService = new UserService();
  const locationQuery = new URLSearchParams(location.search);

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: locationQuery.get('email') || '',
      password: '',
      code: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email()
        .required(),
      password: Yup
        .string()
        .required(),
      code: Yup
        .string()
        .required(),
    }),
    onSubmit: async ({ email, password, code }) => {
      try {
        await userService.signUpAndRedeem({email, password, code})
          .then(async () => {
            ReactGA.event({category: TRACKING_CATALOG[SIGN_UP_SUCCESS], action: SIGN_UP_SUCCESS});
            ReactPixel.track(EVENT_CATALOG[SIGN_UP_SUCCESS]);
            onSuccess("Sign up successfully.");
            await authService.signInWithEmail(email, password)
              .then(() => {
                history.push(DOCUMENT_URL);
            });
        });
      } catch (e: any) {
        onError(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="mt-45" onSubmit={handleSubmit}>
      <TextInput
        type="email"
        placeholder="Email Address"
        className="w-100"
        isInvalid={!!(touched.email && errors.email)}
        {...getFieldProps('email')}
      />
      <TextInput
        type="password"
        placeholder="Password"
        className="mt-3 w-100"
        isInvalid={!!(touched.password && errors.password)}
        {...getFieldProps('password')}
      />
      <TextInput
        placeholder="Your Redeem Code"
        className="mt-3 w-100"
        isInvalid={!!(touched.code && errors.code)}
        {...getFieldProps('code')}
      />
      <Button
        appearance="primary"
        type="submit"
        className="w-100 mt-3"
        height={majorScale(5)}
        isLoading={isSubmitting}
      >
        Sign Up
      </Button>
      <Paragraph className={`mt-3 ${styles.terms}`}>
        By signing up, you agree to the <a href={TERM_OF_USE} target="_blank" rel="noreferrer">Terms of Use</a>
      </Paragraph>
    </form>
  )
}
