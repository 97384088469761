import { createContext, useContext, useState } from 'react';

export const AppContext = createContext({} as { isConnectedConvergence: boolean, setIsConnectedConvergence: Function });

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: any }) => {
  const [isConnectedConvergence, setIsConnectedConvergence] = useState(false);
  return (
    <AppContext.Provider value={{ isConnectedConvergence, setIsConnectedConvergence }}>
      {children}
    </AppContext.Provider>
  );
};

export default useApp;
