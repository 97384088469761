import { ZwfConstants } from './ZwfConstants';

const ComponentPanel = {
  default: [

  ],
  group: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  dialogHeader: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  button: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.WIDTH_RESIZE
  ],
  frame: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  mobileFrame: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_ORIENTATION,
    ZwfConstants.PANEL_KEYBOARD,
    ZwfConstants.ACTION_KEYBOARD_STATE,
    ZwfConstants.ACTION_KEYBOARD_TYPE,
    ZwfConstants.HEIGHT_RESIZE
  ],
  ipadFrame: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_ORIENTATION,
    ZwfConstants.PANEL_KEYBOARD,
    ZwfConstants.ACTION_KEYBOARD_STATE,
    ZwfConstants.HEIGHT_RESIZE
  ],
  circleButton: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.PANEL_ICON,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  icon: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  content: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  rectangle: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.SHAPE_PANEL_LABEL,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_COLOR,
  ],
  roundedRectangle: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.SHAPE_PANEL_LABEL,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_COLOR,
  ],
  ellipse: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.SHAPE_PANEL_LABEL,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_COLOR,
  ],
  square: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.SHAPE_PANEL_LABEL,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_COLOR,
  ],
  circle: [
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  text: [
    ZwfConstants.PANEL_TEXT_COLOR,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
  ],
  heading:[
    ZwfConstants.PANEL_TEXT_COLOR,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
  ],
  linkedText:[
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
  ],
  tooltip: [],
  badge: [
    ZwfConstants.PANEL_COLOR
  ],
  slider: [],
  progress: [],
  toggle: [
    ZwfConstants.PANEL_TOGGLE
  ],
  avatar: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  iconPlaceholder: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  columnChart: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  lineChart: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  pieChart: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  video: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  media: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  line: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.LINE_STYLE
  ],
  verticalLine: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.HEIGHT_RESIZE,
  ],
  horizontalLine: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  datePicker: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  dropdownField: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  filePicker: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  searchBar: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  pagination: [],
  lineOfText: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  blockOfText: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  lorem: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.PANEL_TEXT_COLOR,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_STYLE,
  ],
  checkbox: [
    ZwfConstants.PANEL_BUTTON_STATE
  ],
  radio: [
    ZwfConstants.PANEL_BUTTON_STATE
  ],
  textarea: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  textInput: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.WIDTH_RESIZE,
  ],
  mobileMenuRow: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_ICON,
  ],
  labelPlaceholder: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_QUICK_SIZE,
    ZwfConstants.PANEL_POSITION,
    ZwfConstants.LABEL_POSITION
  ],
  annotation: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT_COLOR,
    ZwfConstants.PANEL_POSITION,
    ZwfConstants.LABEL_POSITION,
  ],
  menuBar: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
  ],
  dialog: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  table: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
  ],
  tableCell: [
    ZwfConstants.PANEL_TEXT_COLOR,
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
  ],
  basicShape: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.SHAPE_PANEL_LABEL,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_COLOR,
  ],
  stickyShape: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.SHAPE_PANEL_COLOR,
  ],
  shape3D: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE,
    ZwfConstants.PANEL_COLOR,
    ZwfConstants.SHAPE_PANEL_LABEL,
    ZwfConstants.PANEL_TEXT,
    ZwfConstants.ACTION_TEXT_STYLE,
    ZwfConstants.ACTION_TEXT_ALIGNMENT,
    ZwfConstants.ACTION_TEXT_COLOR,
  ],
  container: [
    ZwfConstants.PANEL_SIZE,
    ZwfConstants.ACTION_MANUAL_RESIZE
  ],
  freehand: [
    ZwfConstants.PANEL_BRUSH_COLOR
  ],
  freeImage: [

  ],
}

const OPTIONS = {
  default: {
    text: {},
    orientation: {},
    selectIcon: {},
    quickSize: {
      zwfSSize: "24x24",
      zwfMSize: "32x32",
      zwfLSize: "40x40",
    },
    resizeAble: {},
    keyboard: {},
    keyboardType: {},
    position: {},
    alignment: {},
  },
  button: {},
  frame: {
    resizeAble: {},
  },
  mobileFrame: {
    resizeAble: {},
    orientation: {},
    keyboard: {},
    keyboardType: {},
  },
  ipadFrame: {
    resizeAble: {},
    orientation: {},
    keyboard: {}
  },
  icon:{
    quickSize: {
      zwfSSize: "24x24",
      zwfMSize: "32x32",
      zwfLSize: "40x40",
    },
    resizeAble: {},
  },
  Rectangle: {
    resizeAble: {},
  },
  roundedRectangle: {
    resizeAble: {},
  },
  Ellipse: {
    resizeAble: {},
  },
  Circle: {
    resizeAble: {},
  },
  Line: {
    resizeAble: {},
  },
  Square: {
    color: {},
    resizeAble: {},
  },
  Text: {
    color: {},
    resizeAble: {},
  },
  Heading: {
    color: {},
    resizeAble: {},
    heading: {}
  },
  LinkedText: {
    resizeAble: {}
  }
};

Object.freeze(ComponentPanel)
Object.freeze(OPTIONS);

export {
  ComponentPanel,
  OPTIONS
}
