import React, { useState, useEffect } from "react";
import {
  BookIcon,
  Button,
  // ColumnLayoutIcon,
  DataLineageIcon,
  ExpandAllIcon, Image, LocateIcon,
  majorScale,
  Menu,
  Pane,
  Popover,
  Position,
  PropertyIcon,
  Text,
  VolumeUpIcon
} from "evergreen-ui";
import logo from "../../../assets/img/logo.svg";
import { UpgradeToProModal } from "../../../components/Modals";
import { isFreeUser, isPlusUser, isPROTeam } from "../../../utils/store";
import plusBadge from "../../../assets/img/plus_badge.svg";
import proBadge from "../../../assets/img/pro_badge.svg";
import teamBadge from "../../../assets/img/team_badge.svg";
import styles from "../Board.module.scss";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import {DIAGRAM_URL, NOTE_URL, OKR_URL, SOUND_URL, TASK_URL} from 'utils/constants';

const AppHeaderBoard = () => {
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const [isPuclic, setIsPuclic] = useState(false);

  const location = useLocation();

  useEffect(() => {
    let pathName = location.pathname.split('/')[1]
    if (pathName === 'zen-sound' || pathName === 'zen-sounds') {
      setIsPuclic(true)
    }
  }, [])

  return (
    <>
      <Pane display={"flex"} justifyContent={"space-between"} className={styles.header}>
        <Pane display={"flex"} alignItems={"center"}>
          <Pane
            className="text-center"
            display={"flex"}
            alignItems={"center"}
          >
            <Image src={logo} width="24" alt="logo" display={"flex"} alignSelf={"center"} />
          </Pane>
          <Pane
            className="text-center"
            marginLeft={majorScale(2)}
            display={"flex"}
            alignItems={"center"}
          >
            {
              !isPuclic
                ?
                <Pane display={"flex"} justifyContent={"center"}>
                  {!isFreeUser() &&
                    <img src={isPROTeam() ? teamBadge : isPlusUser() ? plusBadge : proBadge} alt="logo" height="16px"/>
                  }
                </Pane>
                :
                <Pane className={styles.nameZen}>Zen Sounds</Pane>

            }
          </Pane>
        </Pane>
        {
          !isPuclic
          &&
          <Pane display={"flex"} alignContent={"center"}>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Group title="All Apps">
                    <Link to={DIAGRAM_URL}>
                      <Menu.Item icon={(<DataLineageIcon color="#3366FF" />)} width="208px" className={clsx(styles.appItem)}>
                        <Text className={clsx(styles.label)}>Zen Diagram</Text>
                      </Menu.Item>
                    </Link>
                    <Link to={TASK_URL}>
                      <Menu.Item icon={(<PropertyIcon color="#52BD94" />)} className={clsx(styles.appItem)}>
                          <Text className={clsx(styles.label)}>Zen Task</Text>
                      </Menu.Item>
                    </Link>
                    <Link to={NOTE_URL}>
                      <Menu.Item icon={(<BookIcon color="#FFB020" />)} className={clsx(styles.appItem)}>
                        <Text className={clsx(styles.label)}>Zen Notes</Text>
                      </Menu.Item>
                    </Link>
                    <Link to={OKR_URL}>
                      <Menu.Item icon={(<LocateIcon color="#25CBD6" />)} className={clsx(styles.appItem)}>
                        <Text className={clsx(styles.label)}>Zen OKR</Text>
                      </Menu.Item>
                    </Link>
                    <Link to={SOUND_URL}>
                      <Menu.Item icon={(<VolumeUpIcon color="#D14343" />)} className={clsx(styles.appItem)}>
                        <Text className={clsx(styles.label)}>Zen Sounds</Text>
                      </Menu.Item>
                    </Link>
                  </Menu.Group>
                </Menu>
              }
              minWidth="208px"
            >
              <Button color="#3366FF" alignSelf={"center"}>
                <Text paddingRight={majorScale(1)} color="#3366FF">All Apps</Text>
                <ExpandAllIcon size={10} />
              </Button>
            </Popover>
          </Pane>
        }
      </Pane>
      {/*{isFreeUser() &&*/}
      {/*  <Pane>*/}
      {/*    <Button*/}
      {/*      className={styles.upgradeBtn}*/}
      {/*      appearance="primary"*/}
      {/*      onClick={() => setModalUpgrade(true)}*/}
      {/*    >*/}
      {/*      Upgrade to PRO*/}
      {/*    </Button>*/}
      {/*  </Pane>*/}
      {/*}*/}

      <UpgradeToProModal isShown={modalUpgrade} setIsShown={setModalUpgrade} />
    </>
  )
}

export default AppHeaderBoard;
