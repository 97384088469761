import { Modal } from '../../../Modals/Modal/Modal';
import { Button, majorScale, Pane, TextInput, Image as ImageTag, InlineAlert } from 'evergreen-ui';
import { useEffect, useRef, useState } from 'react';
import './Toolbar.scss'
import { ImageService } from 'services/image';

const imageService = new ImageService();

const InsertImage = ({ isShown, setShown, insertImage }) => {
  const [image, setImage] = useState('');
  const [imageBase64, setImageBase64] = useState(null);
  const inputFile = useRef(null);
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const { data } = await imageService.convertImageToBase64(image);
      insertImage(data.dataUri, true, data.width, data.height);
      setShown(false);
    } catch (e) {
      setMessage('Can not download image');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isShown) {
      setMessage("");
      setImageBase64(null);
    }
  }, [isShown])

  const handleFileUpload = e => {
    const { files } = e.target;

    if (files && files.length) {
      const file = files[0];
      const mb = 1000000;
      const limitSize = 5;
      if ((file.size || 0)/mb >= limitSize) {
        setMessage(`Please select a file less than ${limitSize}Mb`);
        return;
      } else {
        console.log("is less than 5mb");
      }

      // setImage(files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onloadend = function () {
        setImageBase64(reader.result);
      };
    }
  };

  const onSelectFileClick = () => {
    inputFile.current.click();
  };

  const onLoadImage = (e) => {
    const image = e.target.src;
    const width = e.target.width;
    const height = e.target.height;

    const canvas = document.createElement('canvas');
    const max_size = 1280;
    const ratio = width > height ? width <= max_size ? 1 : max_size / width : height <= max_size ? 1 : max_size/height;

    const adjustWidth = width * ratio;
    const adjustHeight = height * ratio;
    canvas.width = adjustWidth;
    canvas.height = adjustHeight;

    const imageType = new Image();
    imageType.onload = () => {
      canvas.getContext('2d').drawImage(imageType, 0, 0, adjustWidth, adjustHeight);
      const dataUrl = canvas.toDataURL('image/png');
      insertImage(dataUrl, true, width, height);
      setShown(false);
      setImageBase64(null);
    }
    imageType.onerror = () => {
      console.log("resizeImage onerror");
    }
    imageType.crossOrigin = "Anonymous";
    imageType.src = image;
  }

  return (
    <>
      <Modal
        width={376}
        isShown={isShown}
        title="Insert Image"
        hasFooter={false}
        onCancel={() => setShown(false)}
      >
        <Pane>
          Insert an image using URL or select a local file<br/> (jpg, png, svg files {`<`} 5Mb)
          <input
            style={{ display: "none" }}
            ref={inputFile}
            onChange={handleFileUpload}
            type="file"
            accept="image/*"
          />
          <Pane marginTop={majorScale(2)}>
            <TextInput
              placeholder="Image URL"
              width={"100%"}
              onChange={(e) => setImage(e.target.value)}
            />
          </Pane>
          {message &&
            <InlineAlert intent="danger" paddingTop={majorScale(1)}>
              {message}
            </InlineAlert>
          }
        </Pane>
        <Pane paddingY={majorScale(2)} float={"right"}>
          <Button marginRight={majorScale(1)} onClick={onSelectFileClick}>Select a file</Button>
          <Button appearance="primary" onClick={handleConfirm} isLoading={loading}>Insert from URL</Button>
        </Pane>
        {imageBase64 &&
          <ImageTag onLoad={onLoadImage} src={imageBase64} display={"none"} ref={imageRef}/>
        }
      </Modal>
    </>
  )
}

export default InsertImage;
