import React, {useEffect, useState} from 'react';
import {ColorPaletteContainer} from '../../ColorPalette/ColorPaletteContainer';
import {ColorPalette} from '../../ColorPalette/ColorPalette';
import {Box} from '../../Box/Box';
import {changeStrokeWidth, updateSelectionStrokeColor} from "../../../../utils/Actions";
import {majorScale, Pane, Popover, Position, SelectField, Text} from "evergreen-ui";
import {SketchPicker} from "react-color";
import clsx from "clsx";
import styles from "../../ColorPalette/ColorPalette.module.scss";
import _ from "lodash";

const topColors = [
  '#101840',
  '#D8DAE5',
  '#D6E0FF',
  '#DCF2EA',
  '#FFFFFF'
]
const bottomColors = [
  '#3366FF',
  '#FFB020',
  '#D14343',
  '#52BD94',
]

const ColorBrushPanel = ({editorUi, graphState, dispatchGraphState}) => {

  const [freehandStrokeColor, setFreehandStrokeColor] = useState("black");
  const [freehandStrokeWidth, setFreehandStrokeWidth] = useState(1);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const graph = editorUi.editor?.graph;
    if(!graph) return;

    setFreehandStrokeColor(graph.freehand.getStrokeColor());
    setFreehandStrokeWidth(graph.freehand.getStrokeWidth());
  }, []);

  const onSelectColor = (color) => {
    const graph = editorUi.editor?.graph;
    if(!graph) return;

    const isNoCellSelected = !graph.getSelectionCell();
    const isDrawingMode = isNoCellSelected && graph.freehand.isDrawing();
    if(isDrawingMode){
      graph.freehand.setStrokeColor(color);
      setFreehandStrokeColor(color);
    }
    else {
      dispatchGraphState({strokeColor: color});
      updateSelectionStrokeColor(editorUi, color);
    }
  }

  const handleStrokeWidth = val => {
    const graph = editorUi.editor?.graph;
    if(!graph) return;

    const strokeWidth = _.toString(val);
    if (strokeWidth === '') return;
    dispatchGraphState({ strokeWidth });

    const isNoCellSelected = !graph.getSelectionCell();
    const isDrawingMode = isNoCellSelected && graph.freehand.isDrawing();
    if(isDrawingMode){
      graph.freehand.setStrokeWidth(strokeWidth);
      setFreehandStrokeWidth(strokeWidth);
    }
    else{
      changeStrokeWidth(editorUi, strokeWidth);
    }
  };

  const optionSize = () => {
    const options = [];
    for (let i = 0; i <= 50; i += 2) {
      options.push(<option value={Math.max(i, 1)}>{Math.max(i, 1)}</option>);
    }
    return options;
  };

  return (
    <Box title="Brush Color" isDivider>
      <ColorPaletteContainer>
        {topColors.map(item => (
          <ColorPalette
            isActive={[graphState.strokeColor, freehandStrokeColor].includes(item)}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
      </ColorPaletteContainer>
      <ColorPaletteContainer>
        {bottomColors.map(item => (
          <ColorPalette
            isActive={[graphState.strokeColor, freehandStrokeColor].includes(item)}
            color={item}
            onClick={element => onSelectColor(element)}
          />
        ))}
        <Popover
          content={
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <SketchPicker
                color={graphState.strokeColor}
                onChangeComplete={ val => onSelectColor(val.hex) }
              />
            </Pane>
          }
          position={Position.TOP_RIGHT}
        >
          <Pane
            className={clsx(styles.palette)}
            background={"conic-gradient(from -44.17deg at 50% 50%, #D14343 -23.74deg, #3366FF 43.37deg, #52BD94 142.51deg, #FFB020 236.25deg, #D14343 336.26deg, #3366FF 403.37deg)"}
          />
        </Popover>
      </ColorPaletteContainer>

      <Pane
        width={200}
        height={32}
        display={"inline-flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        borderRadius={"4px"}
        marginTop={majorScale(3)}
      >
        <Text marginTop={majorScale(1)}>Stroke Width</Text>
        <Pane>
          <SelectField
            value={_.get(graphState, 'strokeWidth', freehandStrokeWidth)}
            width={80}
            onChange={e => handleStrokeWidth(e.target.value)}
            marginBottom={"0px"}
          >
            {optionSize()}
          </SelectField>
        </Pane>
      </Pane>
    </Box>
  )
}

export default ColorBrushPanel;
