import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import { DOCUMENT_URL } from 'utils/constants';
import { getUser } from 'utils/store';

type Props = {
  children: any
};

const Auth = ({ children }: Props) => {
  const history = useHistory();
  const location = useLocation();
  if (getUser()) {
    history.push(DOCUMENT_URL);
  }
  return (
    <Container className="h-100">
      <Row className="h-100 justify-content-center align-items-center">
        {location.pathname === '/sign-up' ? (
          <Col style={{ width: 'auto', flex: 'none' }}>{children}</Col>
        ) : (
          <Col xl={4} md={5}>
            {children}
          </Col>
        )}
      </Row>
    </Container>
  )
};

export default Auth;
