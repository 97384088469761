import {
  Pane,
  Text,
  CrossIcon,
  Button
} from 'evergreen-ui';
import clsx from 'clsx';
import {
  SizePanel,
  ColorPanel,
  ColorTextPanel,
  TextPanel,
  IconPanel,
  OrientationPanel,
  KeyboardPanel,
  PositionPanel,
  AlignmentPanel,
  HeadingPanel,
  TogglePanel,
  ShapeColorPanel,
} from './Panel';
import styles from './Format.module.scss';
import editorStyles from '../../EditorApp.module.scss';
import React, {useEffect, useReducer, useState} from "react";
import useGraphState from "../../hooks/useGraphState";
import AccessorPanel from '../../utils/AccessorPanel';
import { ZwfConstants } from '../../utils/ZwfConstants';
import _ from "lodash";
import ButtonStatePanel from "./Panel/ButtonStatePanel/ButtonStatePanel";

import { getSelectionShape } from '../../utils/GraphUtils';
import LinePanel from "./Panel/LinePanel/LinePanel";
import ColorBrushPanel from "./Panel/ColorPanel/ColorBrushPanel";

export const Format = ({ editorUi , dispatchPanelState}) => {
  const updateState = (state, updateState) => {
    const resetGraphState = _.get(updateState, "resetGraphState");
    if(resetGraphState) return updateState;
    return {...state, ...updateState};
  }
  const [isFreehand, setFreehand] = useState(false);
  const [graphState, dispatchGraphState] = useReducer(updateState, {});
  const graph = editorUi.editor.graph;
  const selectionCell = graph.getSelectionCell();

  if (selectionCell?.style.indexOf('zwfType') < 0) {
    const isLine = _.get(graphState, ZwfConstants.TYPE_LINE);
    const isLineLabel = _.get(graphState, ZwfConstants.TYPE_LINE_LABEL);
    const isVertex = _.get(graphState, ZwfConstants.IS_VERTEX);

    if (isVertex && !isLine && !isLineLabel) {
      graph.setCellStyles(ZwfConstants.STYLE_TYPE, ZwfConstants.TYPE_BASIC_SHAPE, [selectionCell]);
    }
  }

  useGraphState(editorUi, graphState, dispatchGraphState);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const onFreehandStateChanged = () => {
      setFreehand(graph.freehand?.isDrawing());
    }
    graph.addListener("freehandStateChanged", onFreehandStateChanged);

    return () => {
      graph.removeListener("freehandStateChanged", onFreehandStateChanged);
    }
  }, []);

  const shapeType = getSelectionShape(graph);
  const accessorPanel = new AccessorPanel(shapeType);

  const standardProps = {
    graph,
    editorUi,
    graphState,
    accessorPanel,
    dispatchGraphState
  }

  const handleCloseOptionsBar = () => {
    editorUi.toggleFormatPanel();
    dispatchPanelState({ optionPanel: false });
  }

  return (
    <>
      <Pane className={styles.header}>
        <Button
          className={clsx(
            editorStyles.closeContainer,
            styles.close
          )}
          onClick={handleCloseOptionsBar}
        >
          <CrossIcon />
        </Button>
        <Text>
          Options
        </Text>
      </Pane>
      {graph.getSelectionCell() && (
        <Pane className={styles.content}>
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_SIZE) && <SizePanel {...standardProps}/>}
          {
            (accessorPanel.isPanelVisible(ZwfConstants.SHAPE_PANEL_COLOR))
            && <ShapeColorPanel {...standardProps}/>
          }
          {
            (accessorPanel.isPanelVisible(ZwfConstants.PANEL_COLOR) || _.get(graphState, ZwfConstants.TYPE_LINE))
            &&
            <ColorPanel{...standardProps} panelLabel={accessorPanel.isPanelVisible(ZwfConstants.SHAPE_PANEL_LABEL) ? "Shape Color" : "Color"}/>
          }
          {
            (accessorPanel.isPanelVisible(ZwfConstants.PANEL_TEXT_COLOR) || _.get(graphState, ZwfConstants.TYPE_LINE_LABEL))
            && <ColorTextPanel{...standardProps}/>
          }
          {
            accessorPanel.isPanelVisible(ZwfConstants.PANEL_BRUSH_COLOR) && <ColorBrushPanel{...standardProps} key={ZwfConstants.PANEL_BRUSH_COLOR}/>
          }
          {
            (accessorPanel.isPanelVisible(ZwfConstants.PANEL_TEXT) || _.get(graphState, ZwfConstants.TYPE_LINE_LABEL))
            && <TextPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_ICON) && <IconPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_ORIENTATION) && <OrientationPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_KEYBOARD) && <KeyboardPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_POSITION) && <PositionPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_HEADING) && <HeadingPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_ALIGNMENT) && <AlignmentPanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_TOGGLE) && <TogglePanel {...standardProps}/>}
          {accessorPanel.isPanelVisible(ZwfConstants.PANEL_BUTTON_STATE) && <ButtonStatePanel {...standardProps}/>}
          {
            (accessorPanel.isPanelVisible(ZwfConstants.LINE_STYLE) || _.get(graphState, ZwfConstants.TYPE_LINE))
            && <LinePanel {...standardProps}/>
          }
        </Pane>
      )}
      {!graph.getSelectionCell() && graph.freehand && isFreehand &&
        <Pane className={styles.content}>
          <ColorBrushPanel{...standardProps} key={ZwfConstants.PANEL_BRUSH_COLOR}/>
        </Pane>
      }
      {
        <Pane>{graph.freehand.isDrawing()}</Pane>
      }
    </>
  )
};
