import useStores from 'hooks/useStores';
import styles from './NoteList.module.scss';

type Props = {
  title?: string;
}

export default function ShareNoteHeader({ title }: Props) {
  const { noteStore } = useStores();

  return (
    <>
      <header className={styles.header}>
        <span className={styles.headerTitle}>{title || noteStore.notebook?.name || ''}</span>
      </header>
    </>
  );
}
