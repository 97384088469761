import Projects from "./Projects";
import {useShareProjects} from "../../../services/project/projects.helper";

const ShareProjects = () => {
  const {projects: shareProjects} = useShareProjects();

  return (
    <Projects title="SHARED WITH ME" projects={shareProjects} isAddAble={false}/>
  )
}

export default ShareProjects;
