import { createContext } from 'react';

const store = {
  editorUi: {
    setFreeUser: boolean => {},
  },
  comments: [],
  tipModal: false,
  isMindMap: true,
  setTipModal: boolean => {},
  linkModal: false,
  setLinkModal: boolean => {},
  setUndoQueue: () => {},
  setRedoQueue: () => {},
}

export const EditorContext = createContext(store);
export const EditorContextProvider = EditorContext.Provider;
