const getEndDateDecember = () => {
  const date = new Date((new Date()).getFullYear(), 11, 31);
  console.log("new getEndDateDecember", date);
  console.log("new (new Date()).getFullYear()", (new Date()).getFullYear());
  return date;
}

const getFirstDateMarch = () => {
  const date = new Date();
  date.setMonth(2);
  date.setDate(1);
  return date;
}

const getFirstDateJuly = () => {
  const date = new Date();
  date.setMonth(6);
  date.setDate(1);
  return date;
}

const now = (new Date()).getTime();

export const samplePages = [
  {
    name: "Personal OKR",
    sampleIndex: 0,
    objectives: [
      {
        title: "Be healthier this year",
        results: [
          {
            id: now + 1,
            title: "Reduce body weight to 150 pounds",
            progress: 90
          },
          {
            id: now + 2,
            title: "Exercise at least twice a week",
            progress: 100
          }
        ]
      },
      {
        title: "Be more financially secure",
        results: [
          {
            id: now + 3,
            title: "Have $20k in savings",
            progress: 20,
            dueDate: getEndDateDecember().getTime()
          },
          {
            id: now + 4,
            title: "Pay off 50% of remaining student loans",
            progress: 60
          },
          {
            id: now + 5,
            title: "Reduce monthly spend to $3k / month",
            progress: 80
          }
        ]
      },
      {
        title: "Be able to speak Japanese",
        results: [
          {
            id: now + 6,
            title: "Learn 5000 more new vocabulary",
            progress: 45,
          },
          {
            id: now + 7,
            title: "Pass Japanese N3 exam",
          },
          {
            id: now + 8,
            title: "Speak with a native Japanese at least 30 minutes per week",
            progress: 50
          }
        ]
      }
    ]
  },
  {
    name: "Sales Team",
    sampleIndex: 1,
    objectives: [
      {
        title: "Increase revenue from sales by",
        results: [
          {
            id: now + 9,
            title: "Acquire 5000 Marketing Qualified Leads",
            progress: 60
          },
          {
            id: now + 10,
            title: "Acquire 1000 Sales Qualified Leads",
            progress: 50
          },
          {
            id: now + 11,
            title: "Reach $4M sales target this year",
            progress: 45,
            dueDate: getEndDateDecember().getTime()
          }
        ]
      },
      {
        title: "Build a world-class sales team",
        results: [
          {
            id: now + 12,
            title: "Hire 1 VP of Sales before March 1st",
            dueDate: getFirstDateMarch().getTime()
          },
          {
            id: now + 13,
            title: "Hire 20 new Account Executives",
            progress: 80
          },
          {
            id: now + 14,
            title: "Complete sales training and documentation before Q2",
            progress: 50,
            dueDate: getFirstDateJuly().getTime()
          }
        ]
      }
    ]
  }
]
