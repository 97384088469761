import { useState } from 'react';
import { toaster } from 'evergreen-ui';

import { DocumentService } from 'services/document';
import { Modal } from '../Modal/Modal';
import { TrashItem } from 'schemas';
import { FolderService } from 'services/folder';

interface Props {
  isShown: boolean;
  setIsShown: Function;
  trashItem: TrashItem;
  onEmpty: Function;
}

const documentService = new DocumentService();
const folderService = new FolderService();

const EmptyTrashModal = ({ isShown, setIsShown, onEmpty }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await documentService.deleteAll();
      await folderService.deleteAll();
      setIsShown(false);
      onEmpty();
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={376}
      isShown={isShown}
      title="Empty Trash"
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Delete"
      onConfirm={handleConfirm}
      isConfirmLoading={isLoading}
    >
      Are you sure to empty trash? Documents will <br />
      be permanently removed.
    </Modal>
  );
};

export default EmptyTrashModal;
