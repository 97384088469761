import React, { useState, useEffect } from 'react';
import {
  Pane,
  Button,
  Text,
  PlayIcon,
  StopIcon,
  VolumeUpIcon,
  VolumeOffIcon,
  Pill,
  Card
} from 'evergreen-ui';
import { SOUNDS_CATEGORIES } from "../../../pages/Sounds/SoundsCategories";
import { SOUNDS_DEFINITION } from "../../../pages/Sounds/SoundsDefinition";
import styles from './Style.module.scss';
import boardStyles from '../Board.module.scss';

import clsx from 'clsx';
import AppFooterBoard from "../App/Footer";
import { useLocation } from "react-router-dom";

import ReactHowler from 'react-howler';

//Recoil
import { useRecoilState, useResetRecoilState } from 'recoil';
import { actionSound, activeCategorySound } from '../../../recoil/atom';
import Timer from './Timer';

function Sound({ header }: any) {
  const [soundActive, setSoundActive] = useRecoilState(actionSound)
  const resetSound = useResetRecoilState(actionSound);
  const [categorySoundActive, setCategorySoundActive] = useRecoilState(activeCategorySound)
  const [selectedTime, setSelectedTime] = useState('15');

  const [isCompleted, setIsCompleted] = useState(false)
  const [timerStart, setTimerStart] = useState(false)
  const [isPuclic, setIsPuclic] = useState(false);

  const location = useLocation();

  const handleCategory = (name: string) => {
    setCategorySoundActive(name)
    resetSound()
  }

  useEffect(() => {
    let pathName = location.pathname.split('/')[1]
    if (pathName === 'zen-sound' || pathName === 'zen-sounds') {
      setIsPuclic(true)
    }
    return () => {
      resetSound()
    }
  }, [])

  return (
    <Card className={boardStyles.asideContainer}>
      {header}
      <Pane className={styles.formSideBarSound}>
        <Pane className={clsx(styles.folderContainer, categorySoundActive === 'All' ? styles.active : '')} onClick={() => handleCategory('All')}>
          <span className={styles.textFormat}>
            <VolumeUpIcon className={styles.colorVolume} />
            <span className={styles.sidebarLabel}>All Sounds</span>
          </span>
          <Pill margin={8} className={styles.text}>
            {SOUNDS_DEFINITION.length}
          </Pill>
        </Pane>
        {
          SOUNDS_CATEGORIES.map((val) => {
            return (
              <Pane className={clsx(styles.folderContainer, categorySoundActive === val.name ? styles.active : '')} key={val.id} onClick={() => handleCategory(val.name)}>
                <span className={styles.textFormat}>
                  <val.icon className={styles[val.color]} />
                  <span className={styles.sidebarLabel}>{val.name}</span>
                </span>
                <Pill margin={8} className={styles.text}>
                  {val.data.length}
                </Pill>
              </Pane>
            )
          })
        }

        <Pane className={styles.playForm}>
          <Pane className={styles.titlePage}>
            Playing
          </Pane>
          {
            soundActive.id
            &&
            <Pane display={"flex"} className={styles.play}>
              <Pane>
                <Button
                  className={styles.playBtn}
                  iconBefore={soundActive.playing ? StopIcon : PlayIcon}
                  onClick={() => {
                    setSoundActive({
                      ...soundActive,
                      playing: !soundActive.playing
                    })
                  }}
                >
                  {
                    soundActive.playing ? 'Stop' : "Play"
                  }
                </Button>
              </Pane>
              <Pane>
                <Text>{soundActive.name}</Text>
              </Pane>

            </Pane>
          }
        </Pane>

        <Pane className={styles.volumeForm}>
          <Pane className={styles.titlePage}>
            Volume
          </Pane>
          <Pane display={"flex"} className={styles.play}>
            <Pane>
              <VolumeOffIcon />
            </Pane>
            <Pane>
              <span className='slider-container'>
                <input
                  type='range'
                  min='0'
                  max='1'
                  step='.05'
                  className={styles.inputVolume}
                  value={soundActive.volume}
                  onChange={e => setSoundActive({
                    ...soundActive,
                    volume: parseFloat(e.target.value)
                  })}
                />
              </span>
            </Pane>
            <Pane marginLeft={'5px'} >
              <VolumeUpIcon />
            </Pane>

          </Pane>
        </Pane>
        <Timer
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          timerStart={timerStart}
          setTimerStart={setTimerStart}
          setIsCompleted={setIsCompleted}
        />
        <ReactHowler
          src={['/sounds/ding-ding.mp3']}
          playing={isCompleted}
          onEnd={() => setIsCompleted(false)}
          loop={false}
        />
      </Pane>
      {
        !isPuclic
        &&
        <Pane className={boardStyles.bottomSidebar}>
          <AppFooterBoard />
        </Pane>
      }
    </Card>
  );
}

export default Sound;