/* eslint-disable no-undef */
import { makeAutoObservable } from 'mobx';
import { DocumentSchema } from 'schemas';

import { RootStore } from 'store';


export default class GraphStore {
  rootStore: RootStore;

  isOnline: boolean = true;
  editorUi: any;
  graph: any = null;
  model: any = null;
  sessions: any = {};  
  isSaving: boolean = false;

  session: any;

  document: DocumentSchema;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setGraph(graph: any): void {
    this.graph = graph;
  }

  setModel(model: any): void {
    this.model = model;
  }

  setSession(session: any): void {
    this.session = session;
  }

  setEditorUi(editorUi: any): void {
    this.editorUi = editorUi;
  }

  setDocument(document: DocumentSchema): void {
    this.document = document;
  }

  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }
  
  setIsOnline(isOnline: boolean) {
    this.isOnline = isOnline;
  }
}
