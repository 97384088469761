import './SelectCustom.scss'
import { createElement } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

const SelectCustom = ({
  options,
  selectOption,
  defaultValue
}) => {
  library.add(faCaretDown);
  
  const customSingleValue = ({ value, label, icon }) => (
    <div className="input-select">
      <div className="input-select__single-value">
        {icon && (
          <span
            className="input-select__icon"
            style={{
              color: defaultValue === value ? '#FFFFFF' : '#C1C4D6'
            }}
          >
            {createElement(icon, { zIndex: 3, size: 12 }, null)}
          </span>
        )}
        <span>{label}</span>
      </div>
    </div>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#D8DAE5',
      minHeight: '30px',
      height: '32px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '32px',
    }),
  };
  // custom caret icon
  const CaretDownIcon = () => {
    return <FontAwesomeIcon icon="caret-down" />;
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const handleChange = (value, { action }) => {
    if (selectOption) {
      if (action === "clear") {
        selectOption(null || []);
      } else {
        selectOption(value);
      }
    }
  };

  return (
    <>
      <Select
        options={options}
        value = {
          options.filter(option =>
             option.value === defaultValue)
        }
        className="selectPalette"
        isSearchable={false}
        components={{ DropdownIndicator }}
        formatOptionLabel={customSingleValue}
        styles={customStyles}
        onChange={handleChange}
      />
    </>
  )
}

SelectCustom.propTypes = {

}

export default SelectCustom

