import {Pane, SearchInput} from "evergreen-ui";
import React, {useState} from "react";
import styles from "./Templates.module.scss"
import {TEMPLATES_DEFINITION} from "./TemplateDefinition"
import TemplateCard from "./TemplateCard";
import {useCountDocOfFreeUser} from "../../services/document";

const TemplatesPage = () => {
  const [keyword, setKeyword] = useState("");
  const {docCount} = useCountDocOfFreeUser();

  return (
    <Pane padding={"0px"} className={styles.mainPage} >
      <Pane display={"flex"} justifyContent={"space-between"}>
        <Pane className={styles.titlePage}>
          Templates
        </Pane>
        <Pane>
          <SearchInput value={keyword} onChange={(e: any) => setKeyword(e.target.value)} placeholder="Search" />
        </Pane>
      </Pane>
      <Pane className={styles.templates}>
        {
          TEMPLATES_DEFINITION.filter(t => !keyword || t.name.toLowerCase().includes(keyword))
            .map(templateDefinition => (
              <TemplateCard cardInfo={templateDefinition} docCount={docCount}/>)
            )
        }
      </Pane>
    </Pane>
  )
}

export default TemplatesPage;
