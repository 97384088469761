import { useMemo, useEffect } from 'react';
import {
  createAlignPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createHeadingPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  Plate,
  // withPlate,
  createFontBackgroundColorPlugin,
  createFontFamilyPlugin,
  createFontColorPlugin,
  createFontSizePlugin,
  createFontWeightPlugin,
  createKbdPlugin,
  createNodeIdPlugin,
  createIndentPlugin,
  createAutoformatPlugin,
  createResetNodePlugin,
  createSoftBreakPlugin,
  createExitBreakPlugin,
  createNormalizeTypesPlugin,
  createTrailingBlockPlugin,
  createSelectOnBackspacePlugin,
  createComboboxPlugin,
  // createMentionPlugin,
  createJuicePlugin,
  // createPlateEditor,
  createHorizontalRulePlugin,
} from '@udecode/plate';

import styles from './NoteEditor.module.scss';
import { useNoteDetail } from 'services/note';
import { initialValue } from './note.config';
import { NoteListType } from 'schemas';
import clsx from 'clsx';
import { withStyledPlaceHolders } from './config/components/withStyledPlaceHolders';
import { CONFIG } from './config/config';
import { Spinner } from 'components/Spinner/Spinner';


export type NoteEditorProps = {
  type?: NoteListType;
  noteId: string;
};

export default function NoteViewerEditor({
  noteId,
}: NoteEditorProps) {
  const { note: noteData, getNote } = useNoteDetail();

  useEffect(() => {
    let subscribeNote: any;
    subscribeNote = getNote(noteId);
    return () => {
      if (typeof subscribeNote === 'function') {
        subscribeNote();
      }
    };
  }, [noteId]);

  let components = createPlateUI();
  components = withStyledPlaceHolders(components);

  const plugins = useMemo(() => {
    return createPlugins(
      [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createTodoListPlugin(),
        createHeadingPlugin(),
        // createImagePlugin(),
        createHorizontalRulePlugin(),
        // createLineHeightPlugin(CONFIG.lineHeight),
        createLinkPlugin(),
        createListPlugin(),
        createTablePlugin(),
        // createMediaEmbedPlugin(),
        // createExcalidrawPlugin(),
        createCodeBlockPlugin(),
        createAlignPlugin(CONFIG.align),
        createBoldPlugin(),
        createCodePlugin(),
        createItalicPlugin(),
        // createHighlightPlugin(),
        createUnderlinePlugin(),
        // createStrikethroughPlugin(),
        // createSubscriptPlugin(),
        // createSuperscriptPlugin(),
        createFontBackgroundColorPlugin(),
        createFontFamilyPlugin(),
        createFontColorPlugin(),
        createFontSizePlugin(),
        createFontWeightPlugin(),
        createKbdPlugin(),
        createNodeIdPlugin(),
        createIndentPlugin(CONFIG.indent),
        createAutoformatPlugin(CONFIG.autoformat),
        createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        createTrailingBlockPlugin(CONFIG.trailingBlock),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
        createComboboxPlugin(),
        // createMentionPlugin(),
        createJuicePlugin(),

        // createFindReplacePlugin({ options: { search } }),
      ],
      {
        components,
      },
    );
  }, []);

  if (!noteId || !noteData?.id) {
    return <Spinner />;
  }

  return (
    <>
      <div className={clsx(styles.noteEditor, 'readOnly')}>
        <Plate
          plugins={plugins}
          id={`${noteId}-${noteData?.updatedAt?.seconds}`}
          value={noteData?.content || initialValue}
          editableProps={{
            autoFocus: false,
            spellCheck: false,
            readOnly: true,
          }}
        >
        </Plate>
      </div>
    </>
  );
}
