import {
  AnnotationIcon,
  Button,
  LogOutIcon,
  majorScale,
  Menu,
  MoreIcon,
  NewPersonIcon,
  Pane,
  PlusIcon,
  Popover,
  Position,
  Text,
  TrashIcon
} from "evergreen-ui";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import AutosizeInput from "react-input-autosize";
import {getUser, isFreeUser} from "../../utils/store";
import styles from "./Page.module.scss"
import {PageService} from "../../services/page/page.service";
import {PageInput} from "../../schemas/page";
import _ from "lodash";
import DeletePageModal from "./Modal/DeletePageModal";
import {useHistory} from "react-router-dom";
import {ObjectiveService} from "../../services/objective/objective.service";
import ProFeatureModal from "../../components/Modals/ProFeatureModal/ProFeatureModal";
import {UpgradeToProModal} from "../../components/Modals";
import AddPageMembersModal from "./Modal/AddPageMembersModal";
import LeavePageModal from "./Modal/LeavePageModal";
import {OKR_URL} from "../../utils/constants";

type Props = {
  page: any,
  canManagePage: boolean,
  setFocusObjective: Function
}

const pageService = new PageService();
const objectiveService = new ObjectiveService();

const PageHeader = ({page, canManagePage, setFocusObjective}: Props) => {
  const history = useHistory();
  const [isEditPage, setIsEditPage] = useState(false);
  const [isShowDeletePage, setShowDeletePage] = useState(false);
  const [isShowLeavePage, setShowLeavePage] = useState(false);
  const [newPageName, setNewPageName] = useState(page?.name);
  const [time] = useState(Date.now());
  const user = getUser();
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);

  useEffect(() => {
    setNewPageName(page?.name)
  }, [page]);

  const onEndEditPage = () => {
    pageService.updatePartial(page.id, {name: newPageName} as PageInput)
      .catch(ex => {
        console.log("update page throw exception ex", ex.message);
      });
    setIsEditPage(false);
    _.set(page, "name", newPageName);
  }

  const onInputKeyUp = (e: any) => {
    if(e.code === "Enter"){
      onEndEditPage();
    }
  }

  const onRename = () => {
    setIsEditPage(true);
  }

  const onDeleteSuccess = () => {
    history.push("/okr");
  }

  const onNewObjective = () => {
    objectiveService.create({page: page.id, title: "New Objective"})
      .then((resp: any) => {
        console.log("onNewObjective success resp", resp);
        setFocusObjective(resp.id);
      });
  }

  const onInviteMembers = () => {
    if(isFreeUser()){
      setShowProFeature(true);
      return;
    }

    setShowAddMembers(true);
  }

  const onHandleSpaceKey = (event: any) => {
    const isSpaceKey = event.code === "Space";
    if(!isSpaceKey) return;

    const activeEl = document.activeElement;
    const tagName = activeEl?.tagName.toLowerCase() || "";
    const inputs = ['input', 'select', 'button', 'textarea'];

    if(!activeEl || inputs.includes(tagName)) return;

    onNewObjective();
  }

  useEffect(() => {
    document.addEventListener("keyup", onHandleSpaceKey);
    return () => {
      document.removeEventListener("keyup", onHandleSpaceKey);
    }
  }, []);

  const successCallback = () => {
    history.push(OKR_URL);
  }
  
  return (
    <Pane paddingBottom={majorScale(3)}>
      <Pane display={"flex"}>
        <Pane display={"flex"}>
          {!isEditPage &&
            <Pane className={styles.title} paddingY={majorScale(1)}>
              {page?.name}
            </Pane>
          }
          {isEditPage &&
            <AutosizeInput
              key={time}
              name="form-field-name"
              value={newPageName}
              className={clsx(styles.inputPageTitle)}
              onChange={e => setNewPageName(e.target.value)}
              inputStyle={{ fontSize: 20, fontWeight: 600, }}
              onBlur={onEndEditPage}
              autoFocus={true}
              onKeyUp={(e) => onInputKeyUp(e)}
            />
          }
          <Pane display={"flex"} alignItems={"center"}>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                ({close}) => (
                  <Pane onClick={close}>
                    <Menu>
                      <Menu.Group>
                        <Menu.Item
                          icon={(<AnnotationIcon color="#8F95B2"/>)}
                          onClick={onRename}
                        >
                          <Text color="#474D66">Rename OKR Page</Text>
                        </Menu.Item>
                        <Menu.Item
                          icon={(<NewPersonIcon  color="#3366FF"/>)}
                          onClick={onInviteMembers}
                        >
                          <Text color="#3366FF">Invite Members</Text>
                        </Menu.Item>
                        {user.uid === page.owner &&
                          <Menu.Item
                            icon={(<TrashIcon color="#D14343"/>)}
                            onClick={() => setShowDeletePage(true)}
                          >
                            Delete OKR Page
                          </Menu.Item>
                        }
                        {user.uid !== page.owner && Object.keys(page?.members || {}).includes(user.uid) &&
                          <Menu.Item
                            icon={(<LogOutIcon color="#D14343"/>)}
                            onClick={() => setShowLeavePage(true)}
                          >
                            Leave OKR Page
                          </Menu.Item>
                        }
                      </Menu.Group>
                    </Menu>
                  </Pane>
                )
              }
            >
              <Pane className={styles.moreButton} display={"flex"} justifyItems={"center"} justifyContent={"center"}>
                <MoreIcon color="#8F95B2" size={12} alignSelf={"center"} justifySelf={"center"}/>
              </Pane>
            </Popover>
          </Pane>
        </Pane>
      </Pane>
      <Pane display={"flex"}>
        {canManagePage &&
          <Pane paddingTop={majorScale(2)}>
            <Button
              appearance="primary"
              iconBefore={PlusIcon}
              className={clsx(styles.newObjectiveBtn)}
              intent="success"
              onClick={() => onNewObjective()}
            >
              New Objective
            </Button>
          </Pane>
        }
      </Pane>
      <DeletePageModal
        page={page}
        isShown={isShowDeletePage}
        setIsShown={setShowDeletePage}
        successCallback={onDeleteSuccess}
      />
      <AddPageMembersModal isShown={showAddMembers} setIsShown={setShowAddMembers} pageId={page?.id}/>
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Premium Feature"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            This feature is not available to Free users. Upgrade to a
            <br/>paid plan for unlimited access and more!
          </Pane>)}
      />
      <LeavePageModal pageId={page.id} isShown={isShowLeavePage} setIsShown={setShowLeavePage} successCallback={successCallback}/>
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} />
    </Pane>
  )
}

export default PageHeader;
