import {
  ApplicationIcon,
  Button,
  CaretDownIcon,
  CircleIcon, DrawIcon,
  FontIcon,
  LinkIcon,
  majorScale,
  MediaIcon,
  Menu,
  MenuIcon,
  minorScale,
  MinusIcon,
  Pane,
  Popover,
  Position,
  RedoIcon,
  SettingsIcon,
  SmallPlusIcon,
  SquareIcon,
  StarEmptyIcon,
  Text,
  TrashIcon,
  UndoIcon,
  WidgetButtonIcon,
  WidgetIcon,
} from "evergreen-ui";
import React, {useEffect, useReducer, useState} from "react";
import {ActionState} from "./Binding";
import IconMenu from "./IconMenu"
import "./Toolbar.scss"
import ActionStore from "store/ActionStore";
import {Col, Row} from "react-grid-system"
import InsertLinkModal from "../../../Modals/InsertLinkModal/InsertLinkModal";
import AddFrameMenu from "./AddFrameMenu";
import InsertImage from "./InsertImageModal";

const Toolbar = ({editorUi, dispatchPanelState, panelState, document}) => {
  const [actionState, dispatchActionState] = useReducer(ActionStore,{});
  const [currentZoom, setCurrentZoom] = useState(100);
  const [showInsertLink, setShowInsertLink] = useState(false);
  const [quickButtons, setQuickButton] = useState([]);
  const [showInsertModal, setShowInsertModal] = useState(false);

  const buttons = {
    Freehand: "Freehand",
    Frame: "Frame",
    Process: "Process",
    Rectangle: "Rectangle",
    Circle: "Circle",
    Button: "Button",
    Text: "Text",
    Image: "Image",
    RawImage: "RawImage",
    Icon: "Icon",
    Stick: "Stick",
    Link: "Link",
    Delete: "Delete",
  }

  let refs = {};
  const updateRef = (key, value) => {
    refs[key] = value;
  }
  const zoomRatios = [0.2, 0.5, 0.7, 1.0, 1.5, 2.0];

  useEffect(() => {
    let editor = null;
    let actions = null;
    try{
      editor = editorUi.current.editor;
      actions = editorUi.current.actions;
    }
    catch (err){
      return ;
    }

    Object.keys(ActionState).forEach(key => {
      const action = actions.get(key);
      if(!action) return;
      const initState = {};
      initState[key] = action;
      dispatchActionState({updateProperty : initState});

      const stateChanged = () => {
        const updateState = {};
        updateState[key] = action;
        dispatchActionState({updateProperty : updateState});
      }

      action.addListener("stateChanged", stateChanged);
    });

    editor.addListener("resetGraphView", resetGraphView);
    editor.graph?.view?.addListener("scale", resetGraphView);
    editor.graph?.addListener("showInsertLink", onShowInsertLink);

    return () => {
      Object.keys(ActionState).forEach(key => {
        const action = actions.get(key);
        if(!action) return;

        const stateChanged = () => {
          const updateState = {};
          updateState[key] = action;
          dispatchActionState({updateProperty : updateState});
        }

        action.removeListener("stateChanged", stateChanged);
      });
      editor.removeListener("resetGraphView", resetGraphView);
      editor.graph?.view?.removeListener("scale", resetGraphView);
      editor.graph?.removeListener("showInsertLink", onShowInsertLink);
    };
  }, []);

  useEffect(() => {
    const docType = (document || {}).type;
    if(docType === "whiteboard"){
      setQuickButton([
        buttons.Freehand, buttons.Rectangle, buttons.Circle, buttons.Text, buttons.Icon, buttons.Stick, buttons.RawImage
      ]);
    }
    else if(docType === "wireframe"){
      setQuickButton([
        buttons.Frame, buttons.Rectangle, buttons.Button, buttons.Text, buttons.Image, buttons.Icon
      ]);
    }
    else{
      setQuickButton([
        buttons.Process, buttons.Text, buttons.Icon, buttons.RawImage, buttons.Link, buttons.Delete
      ]);
    }
  }, [document]);

  // Binding create shape event
  useEffect(() => {
    Object.keys(refs).forEach(key => {
      editorUi.current.sidebar.addDragComponent(refs[key], key);
    })
  }, []);

  const resetGraphView = () => {
    const scale = editorUi?.current.editor.graph.view.scale || 1.0;
    setCurrentZoom(Math.trunc(scale*100));
  }

  const onShowInsertLink = () => {
    setShowInsertLink(true)
  }

  const zoomTo = (offset) => {
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    const zoomTo = clamp(currentZoom + offset, 20, 200);
    getAction("zoomTo").funct(zoomTo/100)
  }

  const getAction = (key) => {
    return actionState[key] || {};
  }

  const handleOpenSidebar = () => {
    editorUi.current.toggleSidebarPanel();
    dispatchPanelState({ sidePanel: true });
  }

  return (
    <Pane width="100%" elevation={1} paddingY={majorScale(1)} paddingX={majorScale(2)} className="zwfToolbar">
      <Row
        height={majorScale(6)}
        paddingX={majorScale(2)}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Col lg={3} md={4}>
          <Pane display={"flex"} alignItems={"center"} minWidth={"max-content"}>
            <Button
              height={majorScale(4)}
              paddingX={majorScale(2)}
              paddingY={majorScale(1)}
              marginRight={majorScale(1)}
              className="blue-hover border-lighten-gray"
              onClick={handleOpenSidebar}
              background={panelState.sidePanel ? " #EBF0FF" : "white"}
            >
              <MenuIcon size={12} className="mr-2" color="#3366FF"/>
              All Shapes
            </Button>
            <Popover
              position={Position.BOTTOM_LEFT}
              minWidth={150}
              content={
                ({close}) => (<AddFrameMenu editorUi={editorUi} close={close}/>)
              }
              display={quickButtons.includes(buttons.Frame) ? "unset" : "none"}
            >
              <Button
                height={majorScale(4)}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
                className="border-lighten-gray"
                display={quickButtons.includes(buttons.Frame) ? "unset" : "none"}
              >
                Add Frame
                <CaretDownIcon size={12} className="ml-2" color="#474D66"/>
              </Button>
            </Popover>
          </Pane>
        </Col>
        <Col lg={5} md={2} className="d-flex justify-content-lg-center">
          <Pane display={"flex"}>
            <Pane class="shape-buttons on-one-line">
              <Pane display={quickButtons.includes(buttons.Freehand) ? "unset" : "none"}>
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
                  marginRight={majorScale(2)}
                  className="green-hover border-lighten-gray"
                  onClick={() =>
                    getAction('freehand').funct()
                  }
                >
                  <DrawIcon size={12} className="mr-2" color="#52BD94"/>Drawing
                </Button>
              </Pane>
              <Pane display={quickButtons.includes(buttons.Process) ? "unset" : "none"}>
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
                  marginRight={majorScale(2)}
                  className="green-hover border-lighten-gray"
                  ref={(el) => updateRef('shapes.process', el)}
                >
                  <WidgetButtonIcon size={12} className="mr-2" color="#52BD94"/>Process
                </Button>
              </Pane>
              <Pane display={quickButtons.includes(buttons.Rectangle) ? "unset" : "none"}>
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(1)}
                  paddingY={majorScale(1)}
                  marginRight={majorScale(2)}
                  className="green-hover border-lighten-gray"
                  ref={(el) => updateRef('shapes.rectangle', el)}
                >
                  <SquareIcon size={12} color="#52BD94"/>
                </Button>
              </Pane>
              <Pane display={quickButtons.includes(buttons.Circle) ? "unset" : "none"}>
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(1)}
                  paddingY={majorScale(1)}
                  marginRight={majorScale(2)}
                  className="green-hover border-lighten-gray"
                  ref={(el) => updateRef('shapes.circle', el)}
                >
                  <CircleIcon size={12} color="#52BD94"/>
                </Button>
              </Pane>
              <Pane display={quickButtons.includes(buttons.Button) ? "unset" : "none"}>
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
                  marginRight={majorScale(2)}
                  className="green-hover border-lighten-gray"
                  ref={(el) => updateRef('shapes.button', el)}
                >
                  <WidgetIcon size={12} className="mr-2" color="#52BD94"/>Button
                </Button>
              </Pane>
              <Pane display={quickButtons.includes(buttons.Text) ? "unset" : "none"}>
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
                  marginRight={majorScale(2)}
                  className="green-hover border-lighten-gray"
                  ref={(el) => updateRef('shapes.text', el)}
                >
                  <FontIcon size={12} className="mr-2" color="#52BD94"/>Text
                </Button>
              </Pane>
            </Pane>
            <Pane display={quickButtons.includes(buttons.Image) ? "unset" : "none"}>
              <Button
                height={majorScale(4)}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
                marginRight={majorScale(2)}
                className="green-hover border-lighten-gray"
                ref={(el) => updateRef('shapes.image', el)}
              >
                <MediaIcon size={12} className="mr-2" color="#52BD94"/>Image
              </Button>
            </Pane>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                ({close}) => <IconMenu  editorUi={editorUi} close={close}/>
              }
              display={quickButtons.includes(buttons.Icon) ? "unset" : "none"}
            >
              <Button
                height={majorScale(4)}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
                marginRight={majorScale(2)}
                className="green-hover border-lighten-gray"
              >
                <StarEmptyIcon size={12} className="mr-2" color="#52BD94"/>Icon
              </Button>
            </Popover>
            <Pane display={quickButtons.includes(buttons.Stick) ? "unset" : "none"}>
              <Button
                height={majorScale(4)}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
                marginRight={majorScale(2)}
                className="green-hover border-lighten-gray"
                ref={(el) => updateRef('shapes.sticky', el)}
              >
                <ApplicationIcon size={12} className="mr-2" color="#52BD94"/>Note
              </Button>
            </Pane>
            <Pane display={quickButtons.includes(buttons.RawImage) ? "unset" : "none"}>
              <Button
                height={majorScale(4)}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
                marginRight={majorScale(2)}
                className="green-hover border-lighten-gray"
                onClick={() =>
                  setShowInsertModal(true)
                }
              >
                <MediaIcon size={12} className="mr-2" color="#52BD94"/>Image
              </Button>
            </Pane>
            <Pane display={quickButtons.includes(buttons.Link) ? "unset" : "none"}>
              <Button
                height={majorScale(4)}
                paddingX={majorScale(2)}
                paddingY={majorScale(1)}
                marginRight={majorScale(2)}
                disabled={!getAction("delete").enabled}
                onClick={onShowInsertLink}
                className="green-hover border-lighten-gray"
              >
                <LinkIcon size={12} className="mr-2" color={getAction('delete').enabled ? "#3366FF" : "#D6E1FE"}/>Link
              </Button>
            </Pane>
            <Button
              height={majorScale(4)}
              width={majorScale(4)}
              paddingX={"auto"}
              disabled={!getAction("delete").enabled}
              onClick={getAction("delete").funct}
              className="border-lighten-gray"
            >
              <TrashIcon size={12} color={getAction('delete').enabled ? "#D14343" : "#F4B6B6"}/>
            </Button>
          </Pane>
        </Col>
        <Col lg={4} md={6}>
          <Pane display={"flex"} justifyContent={"end"} width="100%">
            <Pane marginRight={majorScale(2)} className="on-one-line">
              <Button
                height={majorScale(4)}
                width={majorScale(4)}
                paddingX={"auto"}
                marginRight={minorScale(1)}
                disabled={!getAction("undo").enabled}
                onClick={getAction("undo").funct}
                className="border-lighten-gray"
              >
                <UndoIcon size={12} color={getAction("undo").enabled ? "#474D66" : "disabled"}/>
              </Button>
              <Button
                height={majorScale(4)}
                width={majorScale(4)}
                paddingX={"auto"}
                disabled={!getAction("redo").enabled}
                onClick={getAction("redo").funct}
                className="border-lighten-gray"
              >
                <RedoIcon size={12} color={getAction("redo").enabled ? "#474D66" : "disabled"}/>
              </Button>
            </Pane>
            <Pane marginRight={majorScale(2)} className="on-one-line">
              <Button
                height={majorScale(4)}
                width={majorScale(4)}
                paddingX={"auto"}
                disabled={!getAction("zoomOut").enabled}
                onClick={() => zoomTo(-10)}
                className="border-lighten-gray"
              >
                <MinusIcon size={12} color="#474D66"/>
              </Button>
              <Popover
                position={Position.BOTTOM_LEFT}
                minWidth={150}
                content={
                  ({close}) => (
                    <Pane onClick={close}>
                      <Menu>
                        <Menu.Item
                          paddingX={0}
                          onClick={getAction("fitWindow").funct}
                        >
                          <Text fontSize={12}>Zoom to Content</Text>
                        </Menu.Item>
                        {zoomRatios
                          .map((ratio, index) => (
                            <Pane key={index}>
                              <Menu.Divider/>
                              <Menu.Item
                                paddingX={0}
                                display={"flex"}
                                alignItems={"center"}
                                onClick={() => getAction("zoomTo").funct(ratio)}
                              >
                                <Text fontSize={12}>{ratio*100}%</Text>
                              </Menu.Item>
                            </Pane>
                          ))
                        }
                      </Menu>
                    </Pane>
                  )
                }
              >
                <Button
                  height={majorScale(4)}
                  paddingX={majorScale(2)}
                  paddingY={majorScale(1)}
                  marginX={minorScale(1)}
                  minWidth={90}
                  className="border-lighten-gray"
                >
                  {currentZoom}%<CaretDownIcon size={12} color="#474D66" className="ml-2"/>
                </Button>
              </Popover>
              <Button
                height={majorScale(4)}
                width={majorScale(4)}
                paddingX={"auto"}
                disabled={!getAction("zoomIn").enabled}
                onClick={() => zoomTo(10)}
                className="border-lighten-gray"
              >
                <SmallPlusIcon size={16} color="#474D66"/>
              </Button>
            </Pane>
            <Pane>
              <Button
                height={majorScale(4)}
                className="yellow-hover border-lighten-gray"
                onClick={() => {
                  editorUi.current.toggleFormatPanel();
                  dispatchPanelState({optionPanel: !panelState.optionPanel});
                }}
                backgroundColor={panelState.optionPanel ? "#FFEFD2" : "white"}
              >
                <SettingsIcon size={12} color="#FFB020" className="mr-2"/>Options
              </Button>
            </Pane>
          </Pane>
        </Col>
      </Row>
      <InsertLinkModal isShown={showInsertLink} setIsShown={setShowInsertLink} editorUi={editorUi}/>
      <InsertImage isShown={showInsertModal} setShown={setShowInsertModal} insertImage={(link, isBase64, w, h) => {
        getAction('addImage').funct(link, isBase64, w, h)
      }}/>
    </Pane>
  )
};

export default Toolbar;
