import { TextInput } from 'evergreen-ui';
import { useCallback, useState } from 'react';

import { FolderService } from 'services/folder';
import { Dialog } from 'components/Dialog';
import useKeypress from 'hooks/useKeyPress';
import {FolderSchema} from "../../schemas";
interface Props {
  isShown: boolean;
  setIsShown: Function;
  parentFolder?: FolderSchema | undefined;
}

const folderService = new FolderService();

export default function CreateFolderModal({ isShown, setIsShown, parentFolder }: Props) {
  const [newFolderName, setNewFolderName] = useState('');

  const createNewFolder = useCallback(async () => {
    try {
      setIsShown(false);
      await folderService.create({
        name: newFolderName,
      }, parentFolder);
    } finally {
      setNewFolderName('');
    }
  }, [newFolderName, setIsShown, parentFolder]);

  return (
    <>
      <Dialog
        isShown={isShown}
        title="New Folder"
        onCloseComplete={() => setIsShown(false)}
        preventBodyScrolling
        confirmLabel="Create"
        onConfirm={createNewFolder}
      >
        <FolderContent
          createNewFolder={createNewFolder}
          setNewFolderName={setNewFolderName}
          newFolderName={newFolderName}
        />
      </Dialog>
    </>
  );
}

type FolderContentProps = {
  newFolderName: string;
  setNewFolderName: Function;
  createNewFolder: Function;
};

const FolderContent = ({
  newFolderName,
  setNewFolderName,
  createNewFolder,
}: FolderContentProps) => {
  useKeypress('Enter', createNewFolder);

  return (
    <TextInput
      width="100%"
      required
      name="name"
      autoFocus={true}
      value={newFolderName}
      onChange={(e: any) => setNewFolderName(e.target.value)}
      placeholder="Folder name"
    />
  );
};
