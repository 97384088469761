import {
  Button,
  Card,
  Heading,
  InlineAlert,
  IntentTypes,
  majorScale,
  Pane,
  PlusIcon,
  Spinner,
  Table,
  TableCell,
  Text,
  TextInput, toaster
} from "evergreen-ui";
import styles from "../Team/Team.module.scss";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import _ from "lodash";
import {TeamService} from "../../../services/team/team.service";
import {TeamSchema} from "../../../schemas/team";
import {StripeService} from "../../../services/stripe";

const stripe = new StripeService();

const Team = () => {
  const [nameSaving, setNameSaving] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [saveNameMessage, setSaveNameMessage] = useState("");

  const [addMembersMessage, setAddMembersMessage] = useState("");
  const [saveNameIntent, setSaveNameIntent] = useState<IntentTypes>("success");
  const [addMembersIntent, setAddMembersIntent] = useState<IntentTypes>("success");

  const [remainingLicenses, setRemainingLicense] = useState(0);
  const [memberEmails, setMemberEmails] = useState([] as string[]);
  const [isAddingMembers, setIsAddingMembers] = useState(false);

  const [teamDetail, setTeamDetail] = useState({} as TeamSchema);
  const [members, setMembers] = useState([] as any[]);
  const [isLoadingTeamInfo, setLoadingTeamInfo] = useState(true);
  const [isDeleting, setIsDeleting] = useState([] as string[]);

  const teamService = new TeamService();

  const formatName = (data: any) => {
    if (!data.firstName && !data.lastName) return 'n/a'
    return `${data.firstName || ''} ${data.lastName || ''}`
  }
  const formatRole = (data: any) => {
    return data.isAdmin ? "Admin" : "Member";
  }
  const headers = [
    { title: "EMAIL ADDRESS", value: "email", width: '35%'},
    { title: "NAME", value: "firstName", width: '30%', format: formatName },
    { title: "ROLE", value: "lastName", width: '20%', format: formatRole },
    { title: "", value: "action", width: '15%',},
  ]

  const initTeamData = (respData: any) => {
    const data = respData || {};
    setTeamDetail(data);
    const members = (Object.values((data || {members: {}}).members) || [])
      .sort((first: any) => first.isAdmin ? -1 : 1);
    setMembers(members);
    setTeamName(data.name);
  }

  const handleClickCustomerPortal = async () => {
    try {
      // setLoading(true);
      const { data } = await stripe.createPortalLink()
      window.open(data.url, '_blank');
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      // setLoading(false);
    }
  }

  useEffect(() => {
    teamService.getTeam()
      .then(resp => {
        initTeamData(resp.data);
    }).catch(ex => {

    }).finally(() => {
      setLoadingTeamInfo(false);
    })
  }, []);

  useEffect(() => {
    const licenses = _.get(teamDetail, "licenses", 1) || 1;
    setRemainingLicense(licenses - (members || []).length);
  }, [teamDetail, members])

  const onMemberEmails = (input: string) => {
    const emails = input.split(",").map(email => _.trim(email));
    setMemberEmails(emails);
    setAddMembersMessage("");
  }

  const removeMembers = (member: any) => {
    setIsDeleting([...isDeleting, member.email]);
    teamService.removeMembers([member.email]).then((resp) => {
      initTeamData(resp.data);
    }).finally(() => {
      setIsDeleting(isDeleting.filter(email => member.email !== email));
    })
  }

  const onChangeTeamName = (value: string) => {
    setTeamName(value);
    setSaveNameMessage("");
  }

  const onAddMembers = () => {
    if(_.isEmpty(memberEmails)) return;
    setIsAddingMembers(true);
    setAddMembersMessage("");
    teamService.addMembers(memberEmails).then((resp) => {
      initTeamData(resp.data);
      setAddMembersIntent("success");
      setAddMembersMessage("Member(s) added.");
    }).catch(ex => {
      setAddMembersIntent("danger");
      setAddMembersMessage(ex.message);
    }).finally(() => {
      setIsAddingMembers(false);
    })
  }

  const onSaveName = () => {
    setNameSaving(true);

    teamService.rename(teamName).then(() => {
      setSaveNameIntent("success");
      setSaveNameMessage("Name saved.");
    }).catch(ex => {
      setSaveNameIntent("danger");
      setSaveNameMessage("Can not save name.");
    }).finally(() => {
      setNameSaving(false);
    })
  }

  return (
    <>
      <Card className={styles.card}>
        <Heading size={500}>Team Name</Heading>
        <Pane>
          <TextInput
            value={teamName}
            type="text"
            className="mt-3"
            placeholder="Company or Team name"
            onChange={(e: any) => onChangeTeamName(e.target.value)}
          />
          <div className="d-flex mt-4">
            <Button
              appearance="primary"
              type="submit"
              className="mr-4"
              disabled={nameSaving}
              isLoading={nameSaving}
              onClick={() => onSaveName()}
            >
              Save Name
            </Button>
            {saveNameMessage && (
              <InlineAlert intent={saveNameIntent}>
                {saveNameMessage}
              </InlineAlert>
            )}
          </div>
        </Pane>
      </Card>

      <Card className={styles.cardTeam}>
        <Pane paddingX={"2.5rem"} paddingTop={"2.5rem"}>
          <Heading size={500}>Team Members</Heading>
          <Pane paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
            <Text>You have <Text fontWeight={600}>{`${remainingLicenses}/${_.get(teamDetail, "licenses", 1)}`}</Text> remaining licenses.
              To add more, visit &nbsp;
              <span onClick={() => handleClickCustomerPortal()} className={clsx(styles.portalLink)}>{`Customer Portal`}</span>
              &nbsp;then click on Update Plan {`>`} Update Quantity.
            </Text>
          </Pane>
          <Pane display={"flex"} paddingY={majorScale(1)}>
            <TextInput
              type="text"
              placeholder="Emails, comma separated."
              onChange={(e: any) => onMemberEmails(e.target.value)}
            />
            <Button
              appearance="primary"
              iconBefore={PlusIcon}
              className={clsx('ml-3', styles.newDocumentBtn)}
              intent="success"
              isLoading={isAddingMembers}
              disabled={isAddingMembers}
              onClick={onAddMembers}
            >
              Add Members
            </Button>
            {addMembersMessage && (
              <InlineAlert intent={addMembersIntent} display={"flex"} marginLeft={majorScale(2)}>
                {addMembersMessage}
              </InlineAlert>
            )}
          </Pane>
        </Pane>
        <Pane marginTop={majorScale(2)} paddingX={"2.5rem"}>
          <Table>
            <Table.Head>
              {
                headers.map(header => (
                  <Table.HeaderCell
                    key={header.value}
                    flexBasis={header.width || "auto"}
                    flexShrink={0}
                    flexGrow={0}
                    flexWrap={'wrap'}
                  >
                    <Pane
                      paddingX={majorScale(2)}
                      display={"flex"}
                      alignContent={"center"}
                      className={clsx(styles.headerCell)}
                    >
                      {header.title}
                    </Pane>
                  </Table.HeaderCell>
                  )
                )
              }
            </Table.Head>
            <Table.Body>
              {
                members.map(member => (
                  <Table.Row key={member.id} className={clsx(styles.tableRow)}>
                    {headers.map((header, index) => (
                      <TableCell
                        key={index}
                        flexBasis={header.width || "auto"}
                        flexShrink={0}
                        flexGrow={0}
                        flexWrap={'wrap'}
                      >
                        {header.value !== "action" &&
                          <Pane
                            paddingX={majorScale(2)}
                            className={clsx(styles.bodyCell)}
                          >
                            {header.format ? header.format(member) : _.get(member, header.value, 'n/a')}
                          </Pane>
                        }
                        {header.value === "action" &&
                        <Pane
                          paddingX={majorScale(2)}
                          display={"flex"}
                          alignContent={"center"}
                          justifyContent={"end"}
                        >
                          {!member.isAdmin &&
                            <Button
                              appearance="primary"
                              intent="danger"
                              className={styles.buttonDelete}
                              onClick={() => removeMembers(member)}
                              disabled={isDeleting.includes(member.email)}
                              isLoading={isDeleting.includes(member.email)}
                            >
                              Delete
                            </Button>
                          }
                        </Pane>
                        }
                      </TableCell>)
                    )}
                  </Table.Row>
                ))
              }
            </Table.Body>
            {isLoadingTeamInfo && !_.isEmpty(_.get(teamDetail, 'members', [])) &&
              <Pane
                padding={majorScale(2)}
                display={"flex"}
                alignContent={"center"}
                justifyContent={"center"}
                height={'100px'}
                background={"white"}
              >
                <Spinner alignSelf={"center"}/>
              </Pane>
            }
          </Table>
        </Pane>
      </Card>
    </>
  )
}

export default Team;
