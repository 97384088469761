import clsx from 'clsx';
import { useEffect } from 'react';
import { Observer } from 'mobx-react-lite';

import styles from './NotePage.module.scss';
import NoteList from 'components/NoteEditor/NoteList/MyNoteList';
import { NoteListType } from 'schemas';
import useQuery from 'hooks/useQuery';
import NoteEditorContainer from 'components/NoteEditor/NoteEditorContainer';
import useStores from 'hooks/useStores';

export default function NoteTrashPage() {
  const query = useQuery();
  const noteId = query.get('noteId') as string;
  const { noteStore } = useStores();

  useEffect(() => {
    noteStore.setNoteId(noteId);
  }, [noteId]);

  return (
    <>
      <section className={clsx(styles.mainSection)}>
        <div className={clsx(styles.listContainer)}>
          <NoteList type={NoteListType.TRASH} />
        </div>

        <div className={clsx(styles.editorContainer)}>
          <Observer>
            {() => (
              <>
                {noteStore.noteId && (
                  <NoteEditorContainer key={noteStore.noteId} />
                )}
              </>
            )}
          </Observer>
        </div>
      </section>
    </>
  );
}
