import { ReactNode } from 'react';
import { Card as CustomizedCard, Heading } from 'evergreen-ui';
import styles from './Card.module.scss';

type Props = {
  children?: ReactNode
}

export const Card = ({ children }: Props) => (
  <CustomizedCard className={styles.card}>
    {children}
  </CustomizedCard>
);

export const CardHeader = ({ children }: Props) => (
  <div className={styles.cardHeader}>
    {children}
  </div>
);

export const CardTitle = ({ children }: Props) => (
  <Heading className={styles.cardTitle}>
    {children}
  </Heading>
);

export const CardBody = ({ children }: Props) => (
  <div className={styles.cardBody}>
    {children}
  </div>
);

export default Card;
