const ActionState = {
  customZoom: {},
  delete: {},
  lockUnlock: {},
  undo: {},
  redo: {},
  zoomTo: {},
  zoomIn: {},
  zoomOut: {},
  fitWindow: {},
  addLink: {},
  freehand: {},
  addImage: {}
}

export {
  ActionState
};
