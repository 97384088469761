import { FC, CSSProperties, useEffect, useState, memo } from 'react';
import { Document } from './Document';

const styles: CSSProperties = {
  display: 'inline-block',
};

export interface DocumentDragPreviewProps {
  title: string;
}

export interface DocumentDragPreviewState {
  tickTock: any;
}

export const DocumentDragPreview: FC<DocumentDragPreviewProps> = memo(
  function DocumentDragPreview({ title }) {
    const [tickTock, setTickTock] = useState(false);

    useEffect(
      function subscribeToIntervalTick() {
        const interval = setInterval(() => setTickTock(!tickTock), 500);
        return () => clearInterval(interval);
      },
      [tickTock]
    );

    return (
      <div style={styles}>
        <Document title={title} preview />
      </div>
    );
  }
);
