import React from 'react';
import {
  Pane,
  Button,
  Combobox,
  Popover,
  Position,
  CrossIcon,
  ArrowRightIcon
}
  from "evergreen-ui";

import styles from "./MoveCard.module.scss";

export default function MoveCard() {

  return (
    <Popover
      content={({ close }) => (
        <Pane className={styles.formMoveCard}>
          <Pane className={styles.formHeader}>
            <Pane className={styles.title}>Move Card</Pane>
            <Pane className={styles.closeIcon}>
              <CrossIcon className={styles.icon} onClick={close} />
            </Pane>
          </Pane>
          <Pane className={styles.formBody}>
            <Pane className={styles.board}>
              <Pane className={styles.text}>Board</Pane>
              <Combobox
                openOnFocus
                width="100%"
                items={['Banana', 'Orange', 'Apple', 'Mango']}
                onChange={selected => console.log(selected)}
                placeholder="Mobile Diary App"
              />
            </Pane>
            <Pane className={styles.list}>
              <Pane className={styles.text}>List</Pane>
              <Combobox
                openOnFocus
                width="100%"
                items={['Banana', 'Orange', 'Apple', 'Mango']}
                onChange={selected => console.log(selected)}
                placeholder="Backlog"
              />
            </Pane>
          </Pane>
          <Pane className={styles.formFooter}>

          </Pane>
        </Pane>
      )}
      shouldCloseOnExternalClick={false}
      position={Position.BOTTOM_LEFT}
    >
      <Button marginY={4} iconBefore={ArrowRightIcon}>
        Move
      </Button>
    </Popover>
  )
}
