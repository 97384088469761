import styles from './UpgradeToProModal.module.scss';
import { Dialog, Text } from 'evergreen-ui';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>
}

const UpgradeSuccessModal = ({ isShown, setIsShown }: Props) => {
  return (
    <Dialog
      width={376}
      isShown={isShown}
      title="Upgrade Successful!"
      confirmLabel="OK"
      onConfirm={() => setIsShown(false)}
      hasCancel={false}
      containerProps={{
        className: styles.modalSuccess
      }}
    >
      <Text>
        Thank you for your purchase! Your account has<br/>
        been upgraded. If you still do not have access<br/>
        to paid features, try logging out and logging in<br/>
        again. For more support, email us at <a href="mailto:help@zenflowchart.com">help@zenflowchart.com</a>
      </Text>
    </Dialog>
  )
}

export default UpgradeSuccessModal;
