import {
  AnnotationIcon,
  LogOutIcon,
  majorScale,
  Menu,
  MoreIcon,
  NewPersonIcon,
  Pane,
  Popover,
  Position,
  Text,
  TrashIcon
} from "evergreen-ui";
import clsx from "clsx";
import styles from "./TasksView.module.scss";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useProjectStatic} from "../../services/project/projects.helper";
import AutosizeInput from "react-input-autosize";
import {ProjectService} from "../../services/project/project.service";
import DeleteProjectModal from "./Modal/DeleteProjectModal";
import AddProjectMembersModal from "./AddProjectMembersModal";
import {getUser, isFreeUser} from "../../utils/store";
import TaskGroupByProject from "./TaskGroupByProject";
import {VIEW_MODE} from "../../services/task/tasks.helper";
import TaskList from "./TaskList";
import LeaveProjectModal from "./Modal/LeaveProjectModal";
import ProFeatureModal from "../../components/Modals/ProFeatureModal/ProFeatureModal";
import {UpgradeToProModal} from "../../components/Modals";

const projectService = new ProjectService();

const TasksView = () => {
  const [pageTitle, setPageTitle] = useState('Inbox');
  const params = useParams() as any;
  const [projectId, setProjectId] = useState(params.id);
  const location = useLocation();
  const locationQuery = new URLSearchParams(location.search);
  const {project} = useProjectStatic(projectId);
  const [projectInfo, setProjectInfo] = useState(project);
  const [isEditProject, setEditProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [time] = useState(Date.now());
  const [showDeleteProject, setShowDeleteProject] = useState(false);
  const [showLeaveProject, setShowLeaveProject] = useState(false);
  const history = useHistory();
  const [showAddMembers, setShowAddMembers] = useState(false);
  const [view, setView] = useState(locationQuery.get("view"));
  const user = getUser() || {};
  const isProjectTasks = !!projectId;
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    setProjectId(params.id);
  }, [params]);

  useEffect(() => {
    setProjectInfo(project);
  }, [project]);

  useEffect(() => {
    setNewProjectName(_.get(projectInfo, "name",  ""));
  }, [projectInfo]);

  useEffect(() => {
    const viewMode = locationQuery.get("view");
    setView(viewMode);
    switch (viewMode){
      case "inbox":
        setPageTitle("Inbox");
        break;
      case "today":
        setPageTitle("Today");
        break;
      case "someday":
        setPageTitle("Someday");
        break;
      case "tome":
        setPageTitle("Assigned to me");
        break;
      case "trash":
        break;
    }
  }, [locationQuery]);

  const onRename = () => {
    setEditProject(true);
  }

  const onEndEditProject = () => {
    setEditProject(false);
    projectService.updatePartial(projectId, {name: newProjectName})
      .catch(ex => {
        console.log("task update ex", ex.message);
      });

    setProjectInfo({...projectInfo, name: newProjectName});
  }

  const onDeleteSuccess = () => {
    history.push({
      pathname: '/task',
      search: `view=inbox`
    });
  }

  const onLeaveSuccess = () => {
    history.push({
      pathname: '/task',
      search: `view=inbox`
    });
  }

  const onInputKeyUp = (e: any) => {
    if(e.code === "Enter"){
      onEndEditProject();
      setEditProject(false);
    }
  }

  const onInviteMembers = () => {
    if(isFreeUser()){
      setShowProFeature(true);
      return;
    }

    setShowAddMembers(true);
  }

  return (
    <>
      {!_.isEmpty(projectInfo) &&
        <Pane display={"flex"} paddingX={majorScale(2)}>
          {!isEditProject &&
            <Pane className={styles.title} paddingY={majorScale(1)}>
              {_.get(projectInfo, "name")}
            </Pane>
          }
          {isEditProject &&
            <AutosizeInput
              key={time}
              name="form-field-name"
              value={newProjectName}
              className={clsx(styles.inputProjectTitle)}
              onChange={e => setNewProjectName(e.target.value)}
              inputStyle={{ fontSize: 20, fontWeight: 600, }}
              onBlur={onEndEditProject}
              autoFocus={true}
              onKeyUp={(e) => onInputKeyUp(e)}
            />
          }
          <Pane display={"flex"} alignItems={"center"}>
            <Popover
              position={Position.BOTTOM_LEFT}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item icon={(<AnnotationIcon color="#8F95B2"/>)} onClick={onRename}><Text color="#474D66">Rename Project</Text></Menu.Item>
                    <Menu.Item icon={(<NewPersonIcon  color="#3366FF"/>)} onClick={onInviteMembers}>
                      <Text color="#3366FF">Invite Members</Text>
                    </Menu.Item>
                    {user.uid === project.owner &&
                      <Menu.Item icon={(<TrashIcon color="#D14343"/>)} onClick={() => setShowDeleteProject(true)}>
                        Delete Project
                      </Menu.Item>
                    }
                    {user.uid !== project.owner && Object.keys(project?.members || {}).includes(user.uid) &&
                      <Menu.Item icon={(<LogOutIcon color="#D14343"/>)} onClick={() => setShowLeaveProject(true)}>
                        Leave Project
                      </Menu.Item>
                    }
                  </Menu.Group>
                </Menu>
              }
            >
              <Pane className={styles.moreButton} display={"flex"} justifyItems={"center"} justifyContent={"center"}>
                <MoreIcon color="#8F95B2" size={12} alignSelf={"center"} justifySelf={"center"}/>
              </Pane>
            </Popover>
          </Pane>
        </Pane>
      }
      {_.isEmpty(projectInfo) &&
        <Pane display={"flex"}>
          {!isEditProject &&
            <Pane className={styles.title} paddingY={majorScale(1)}>
              {pageTitle}
            </Pane>
          }
        </Pane>
      }
      {(view === "inbox") &&
        <TaskGroupByProject viewMode={VIEW_MODE.INBOX} createAble={true}/>
      }
      {view === "today" &&
        <TaskGroupByProject viewMode={VIEW_MODE.TODAY}/>
      }
      {view === "someday" &&
        <TaskGroupByProject viewMode={VIEW_MODE.SOMEDAY} createAble={true}/>
      }
      {view === "tome" &&
        <TaskGroupByProject viewMode={VIEW_MODE.TOME}/>
      }
      {isProjectTasks && !view &&
        <TaskList projectId={projectId} canManageProject={true}/>
      }
      <DeleteProjectModal
        projectId={projectId}
        isShown={showDeleteProject}
        setIsShown={setShowDeleteProject}
        successCallback={onDeleteSuccess}
      />
      <LeaveProjectModal
        projectId={projectId}
        isShown={showLeaveProject}
        setIsShown={setShowLeaveProject}
        successCallback={onLeaveSuccess}
      />
      <AddProjectMembersModal isShown={showAddMembers} setIsShown={setShowAddMembers} projectId={projectId}/>
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Premium Feature"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            This feature is not available to Free users. Upgrade to a
            <br/>paid plan for unlimited access and more!
          </Pane>)}
      />
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} />
    </>
  )
}

export default TasksView;
