import {
  Image,
  majorScale,
  Pane, Position,
  SearchInput,
} from "evergreen-ui";
import availableIcons from "../../utils/AvailableIcons"
import React, {useEffect, useState} from "react";
import "./IconMenu.css"
import _ from "lodash"
import TooltipCustom from "../PopupMenu/Tooltip";

const IconMenu = ({editorUi, close}) => {
  const [search, setSearch] = useState("");
  let refs = {};
  const updateRef = (key, value) => {
    refs[key] = value;
  }

  // Binding create icon event
  useEffect(() => {
    Object.keys(refs).forEach(key => {
      editorUi.current.sidebar.addDragComponent(refs[key], key);
    })
  }, []);

  return(
    <Pane
      padding={majorScale(2)}
      paddingRight={majorScale(1)}
      paddingBottom={majorScale(1)}
    >
      <Pane >
        <SearchInput placeholder="Search icons" onChange={(e) => setSearch(e.target.value)} paddingRight={1}/>
      </Pane>
      <div className="icon-menu">
        <Pane
          marginTop={majorScale(2)}
          display="grid"
          gridTemplateColumns="repeat(6, 1fr)"
          maxHeight={288}
          overflowY="scroll"
          onClick={close}
        >
          {Object
            .keys(availableIcons)
            .filter(iconName => {
              try {
                if(!search || search === "") return true;

                return _.startCase(iconName).toLowerCase().includes(search.toLowerCase());
              }
              catch (ex){
                return false;
              }
            })
            .map((iconName, index) => (
                <Pane
                  key={iconName}
                  ref={(el) => updateRef(`icons.${_.kebabCase(iconName)}`, el)}
                >
                  <TooltipCustom
                    activator={(
                      <Pane
                        width={majorScale(5)}
                        height={majorScale(5)}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                        className="icon-state-hover"
                        marginRight={majorScale(1)}
                        marginBottom={majorScale(1)}
                      >
                        <Pane>
                          <Image src={_.get(availableIcons, iconName).icon}/>
                        </Pane>
                      </Pane>
                    )}
                    text={_.startCase(_.camelCase(iconName))}
                    position={Position.RIGHT}
                  />
                </Pane>
              )
            )}
        </Pane>
      </div>
    </Pane>
  )
}

export default IconMenu;
