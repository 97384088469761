import {Card, Heading, Link} from "evergreen-ui";
import React from "react";
import styles from "./Board.module.scss"
import clsx from "clsx";
import {Link as ReactRouterLink, useLocation} from "react-router-dom";

type Props = {
  children: any
}

const AdminBoard = ({ children }: Props) => {
  const location = useLocation();
  const isActiveRoute = (path: string) => {
    const isMatchRoute = location.pathname.includes(path);

    return {
      isActive: isMatchRoute
    }
  }
  return (
    <>
      <div className={styles.container}>
        <aside className={styles.aside}>
          <Card className={styles.asideContainer}>
            <Heading className={clsx(styles.panelTitle)}>
              Admin Dashboard
            </Heading>
            {/*<Heading*/}
            {/*  className={clsx(styles.sidebarItem, isActiveRoute('/admin/users'))}*/}
            {/*>*/}
            {/*  <Link is={ReactRouterLink} to="/admin/users">*/}
            {/*    <span className={styles.sidebarLabel}>All Users</span>*/}
            {/*  </Link>*/}
            {/*</Heading>*/}
            <Heading className={clsx(styles.sidebarItem, isActiveRoute('/admin/support'))}>
              <Link is={ReactRouterLink} to="/admin/support">
                <span className={styles.sidebarLabel}>Support</span>
              </Link>
            </Heading>
            <Heading className={clsx(styles.sidebarItem, isActiveRoute('/admin/cancel-codes'))}>
              <Link is={ReactRouterLink} to="/admin/cancel-codes">
                <span className={styles.sidebarLabel}>Cancel Codes</span>
              </Link>
            </Heading>
          </Card>
        </aside>
        <div className={styles.main}>
          {children}
        </div>
      </div>
    </>
  )
};

export default AdminBoard;
