import {DotIcon, majorScale, minorScale, Pane, PlusIcon, Position, Text, Tooltip} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import styles from "../Task/Projects.module.scss";
import ProFeatureModal from "../../../components/Modals/ProFeatureModal/ProFeatureModal";
import {FREE_USER_LIMIT_PAGES} from "../../../utils/constants";
import {UpgradeToProModal} from "../../../components/Modals";
import {isFreeUser} from "../../../utils/store";
import Page from "./Page";
import {PageService} from "../../../services/page/page.service";
import {PageInput} from "../../../schemas/page";
import {useDrop} from "react-dnd";
import Expand from "react-expand-animated";
import _ from "lodash";

type Props = {
  title: string,
  pages?: any[],
  isAddAble?: boolean,
  pageCount?: number
}

const pageService = new PageService();

const Pages = ({title, pages, isAddAble, pageCount}: Props) => {
  const [newPage, setNewPage] = useState(false);
  const [pageName, setPageName] = useState("New Page");
  const [needHideNewPage, setNeedHideNewPage] = useState(false);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const IS_PAGES_LOADING = -1;

  const onNewPage = () => {
    const isLimitByFreeUser = isFreeUser() && (pageCount || 0) >= FREE_USER_LIMIT_PAGES;
    if(isLimitByFreeUser){
      setShowProFeature(true);
      return;
    }

    setPageName("New OKR Page");
    setNewPage(true);
  }

  const createNewPage = (name: string) => {
    pageService.create({name} as PageInput)
      .catch(ex => {
        console.log("on create page exception ex = ", ex.message);
      });

    setNewPage(false);
  }

  const onBlurPageInput = () => {
    createNewPage(pageName);
  }

  useEffect(() => {
    if(needHideNewPage){
      setNewPage(false);
      setNeedHideNewPage(false);
    }
  }, [pages]);

  const onKeyPressNewPage = (e: any) => {
    if(e.code === "Enter"){
      createNewPage(pageName);
    }
  }

  const updateOrdinal = (id: string, ordinal: number) => {
    pageService.updatePartial(id, {
      ordinal: ordinal
    } as PageInput).catch(ex => {
      console.log("Update partial ex = ", ex.message);
    });
  }

  const onDragPage = (drag: any, drop: any) => {
    const isDropToFirst = !drop;
    if(isDropToFirst) {
      const firstPage = _.get(pages, 0);
      if(!firstPage) return;

      updateOrdinal(drag.id, firstPage.ordinal - 10000);
      return;
    }

    const dropIndex = pages?.findIndex(page => page.id === drop.id);
    const nextPage = _.get(pages, (dropIndex || 0 ) + 1);
    if(nextPage){
      const newOrdinal = Math.round((drop.ordinal + nextPage.ordinal)/2);
      updateOrdinal(drag.id, newOrdinal);
      return;
    }

    const isDropToLast = !nextPage;
    if(isDropToLast){
      updateOrdinal(drag.id, (new Date()).getTime());
      return;
    }
  }

  const [{ isDropPageOver }, dropPageRef] = useDrop(() => ({
    accept: ["PAGE"],
    collect: (monitor) => ({
      isDropPageOver: monitor.isOver() && !!isAddAble
    }),
    canDrop: (item: any, monitor) => {
      return !!isAddAble;
    },
    drop: async (item: any, monitor)  => {
      onDragPage(item.page, null);
    }
  }), [pages]);

  return (
    <>
      <Pane
        display={"flex"}
        justifyContent={"space-between"}
        paddingTop={majorScale(3)}
        paddingLeft={majorScale(2)}
        paddingBottom={majorScale(1)}
        ref={dropPageRef}
      >
        <Pane>
          <Text fontSize={12} color="#474D66" fontWeight={600}>{title}</Text>
        </Pane>
        {isAddAble && pageCount !== IS_PAGES_LOADING &&
          <Tooltip
            content={(<Pane><Text fontSize={12} color={"white"}>New Page</Text></Pane>)}
            position={Position.RIGHT}
          >
            <Pane className={clsx(styles.addProjectBtn)}>
              <PlusIcon
                size={12}
                color="#8F95B2"
                alignSelf={"center"}
                alignItems={"center"}
                onClick={onNewPage}
              />
            </Pane>
          </Tooltip>
        }
      </Pane>
      <Expand
        duration={200}
        open={isDropPageOver}
        transitions={["height", "opacity"]}
      >
        <Pane display={isDropPageOver && !!isAddAble ? "flex" : "none"} className={clsx(styles.previewDropPosition)}></Pane>
      </Expand>
      <Pane>
        {(pages || []).map((page) => (
          <Page key={(page.id)} {...page} dragAble={isAddAble} onDragPage={onDragPage}/>
        ))}
      </Pane>
      {newPage &&
        <Pane display={"flex"} padding={minorScale(1)} paddingLeft={majorScale(2)} height="32px">
          <Pane display={"flex"} paddingRight={majorScale(2)}><DotIcon color="#8F95B2" alignSelf={"center"}/></Pane>
          <input
            value={pageName}
            autoFocus={true}
            onBlur={onBlurPageInput}
            onChange={e => setPageName(e.target.value)}
            onKeyPress={onKeyPressNewPage}
            className={clsx(styles.inputProjectName)}
          />
        </Pane>
      }
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Free Limit Reached"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
            Free accounts are limited to {FREE_USER_LIMIT_PAGES} pages. Upgrade to a
            <br/>paid plan for unlimited access and more!
          </Pane>)}
      />
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} />
    </>
  )
}

export default Pages;
