type Props = {
  children: any
};

const Editor = ({ children }: Props) => {
  return (
    <>
      {children}
    </>
  )
};

export default Editor;
