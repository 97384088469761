import { Modal } from '../Modal/Modal';
import React from "react";
import { Pane } from "evergreen-ui";

interface Props {
  isShown: boolean;
  setIsShown: Function;
  onConfirm: Function;
  message: any,
  title: string,
  isPublic?: boolean
}


const ProFeatureModal = ({ isShown, setIsShown, onConfirm, message, title, isPublic }: Props) => {

  return (
    <Modal
      width={430}
      isShown={isShown}
      title={(<Pane color="#C24E49">{title}</Pane>)}
      onCloseComplete={() => setIsShown(false)}
      confirmLabel={isPublic ? "Sign Up Free" : "Explore Plans"}
      onConfirm={() => onConfirm()}
      hasCancel={false}
      isConfirmLoading={false}
    >
      {message}
    </Modal>
  );
}

export default ProFeatureModal;
