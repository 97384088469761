import { Link } from 'react-router-dom';
import { Paragraph, Button, majorScale, Pane } from 'evergreen-ui';
import CardAuth from 'components/CardAuth/CardAuth';

const NotFound = () => (
  <CardAuth title="404 Error">
    <Paragraph className="text-center mt-5 mb-5" style={{ color: '#474D66' }}>Page was not found.</Paragraph>
    <Pane textAlign="center">
      <Link to="/sign-in" className="text-decoration-none">
        <Button
          className="text-decoration-none"
          appearance="primary"
          height={majorScale(5)}
        >
          Return Home
        </Button>
      </Link>
    </Pane>
  </CardAuth>
)

export default NotFound
