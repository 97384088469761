import firebase from "firebase";
import {TASK_COLLECTION} from "../../utils/constants";
import {db} from 'utils/firebase';
import {TaskInput} from "../../schemas";
import {getUser} from "../../utils/store";

export class TaskService{
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;

  constructor() {
    this.db = db.collection(TASK_COLLECTION);
  }

  async create({title, description, dueDate, assignees, isComplete, project, viewMode, type}: TaskInput){
    const { uid } = getUser();
    const data = {
      title: title || null,
      description: description || null,
      dueDate: dueDate || null,
      assignees: assignees || null,
      isComplete: isComplete || false,
      completeDate: isComplete ? (new Date()).getTime() : null,
      project: project || null,
      isPrivate: !project,
      isDelete: false,
      createdBy: uid,
      createdAt: (new Date()).getTime(),
      viewMode: viewMode,
      ordinal: (new Date()).getTime(),
      type: type || null
    }

    await this.db.add(data);
  }

  async update({id, title, description, dueDate, assignees, isComplete}: TaskInput){
    const { uid } = getUser();
    const data = {
      title: title || null,
      description: description || null,
      dueDate: dueDate || null,
      assignees: assignees || null,
      isComplete: isComplete || false,
      completeDate: isComplete ? (new Date()).getTime() : null,
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  partialUpdate(
    {id, title, description, dueDate, assignees, project, isPrivate, isDelete, isDeletedBy, createdBy, viewMode, ordinal}: TaskInput
  ){
    const { uid } = getUser();
    const data = {} as any;

    if (title !== undefined) data["title"] = title;
    if (description !== undefined) data["description"] = description;
    if (dueDate !== undefined) data["dueDate"] = dueDate;
    if (assignees !== undefined) data["assignees"] = assignees;
    if (project !== undefined) data["project"] = project;
    if (isPrivate !== undefined) data["isPrivate"] = isPrivate;
    if (isDelete !== undefined) data["isDelete"] = isDelete;
    if (isDeletedBy !== undefined) data["isDeletedBy"] = isDeletedBy;
    if (createdBy !== undefined) data["createdBy"] = createdBy;
    if (viewMode !== undefined) data["viewMode"] = viewMode;
    if (ordinal) data["ordinal"] = ordinal;

    data["updatedBy"] = uid;
    this.db.doc(id).update(data).catch(ex => {
      console.log("Task service update throw ex ", ex.message);
    })
  }

  async updateComplete(id: string, isComplete: boolean){
    const { uid } = getUser();
    const data = {
      isComplete: isComplete,
      completeDate: (new Date()).getTime(),
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  async assign(id: string, assignees: any){
    const { uid } = getUser();
    const data = {
      assignees: assignees,
      updatedBy: uid,
    }

    await this.db.doc(id).update(data)
  }

  async delete(id: string){
    this.db.doc(id).delete().catch(ex => {
      console.log("delete task ex = ", ex.message);
    })
  }

  async deleteByProject(projectId: string){
    this.db.where("project", "==", projectId).get().then((docTasks) => {
      docTasks.forEach(task => {
        task.ref.delete();
      })
    });
  }
}
