import Auth from 'layouts/Auth/Auth';
import Editor from 'layouts/Editor/Editor';
import Board from 'layouts/Board/Board';
import Rest from 'layouts/Rest/Rest';
import AdminBoard from "../../pages/Admin/Board";
import { getUser } from "../../utils/store";
import React from "react";
import NotFound from "../../pages/NotFound/NotFound";
import NoAuth from "../NoAuth/NoAuth";
import NoteLayout from 'layouts/Note/Note';
import SignUp from "../SignUp/SignUp";

type Props = {
  layout: string,
  children: any,
}

const LayoutHandler = ({ layout, children }: Props) => {

  if (layout === 'auth') {
    return (
      <Auth>
        {children}
      </Auth>
    )
  }

  if (layout === 'signUp') {
    return (
      <SignUp>
        {children}
      </SignUp>
    )
  }
  if (layout === 'admin') {
    const user = getUser();
    const hasPermission = user.isAdmin;
    if (!hasPermission)
      return (
        <Board layout={layout}>
          <NotFound />
        </Board>
      )
    return (
      <AdminBoard>
        {children}
      </AdminBoard>
    )
  }
  if (layout === 'no-auth') {
    return (
      <NoAuth>
        {children}
      </NoAuth>
    )
  }
  if (layout === 'rest') {
    return (
      <Rest>
        {children}
      </Rest>
    )
  }
  if (layout === 'editor') {
    return (
      <Editor>
        {children}
      </Editor>
    )
  }
  if (layout === 'note') {
    return (
      <NoteLayout>
        {children}
      </NoteLayout>
    )
  }
  return (
    <Board layout={layout}>
      {children}
    </Board>
  )
}

export default LayoutHandler;
