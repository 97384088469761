import React, {useEffect, useState} from 'react';
import {
  Button,
  DataLineageIcon,
  LayoutAutoIcon,
  Menu,
  Pane,
  PlusIcon,
  Popover,
  Position,
  PresentationIcon,
  SearchInput,
  Spinner as Loader,
  toaster,
} from 'evergreen-ui';
import {Card, CardBody, CardHeader, CardTitle} from 'components/Documents/Card/Card';
import {Table, TableBody, TableHead, TableHeaderCell, TableHeaderLastCell} from 'components/Documents/Table/Table';
import {Spinner} from 'components/Spinner/Spinner';
import clsx from 'clsx';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {sort} from 'fast-sort';
import Fuse from 'fuse.js';
import {Helmet} from 'react-helmet-async';

import styles from './Documents.module.scss';
import {DocumentService, useDocumentCollection} from 'services/document';
import {
  DefaultDocumentChart,
  DefaultDocumentName,
  DocumentSchema,
  DocumentTheme,
  DocumentType,
  isMindMap,
} from 'schemas/document';
import {FolderSchema} from 'schemas/folder';
import {FolderService, useCheckFolder, useFolderDetail} from 'services/folder';
import DocumentRow from './DocumentRow';
import CreateDocumentModal from 'components/Documents/CreateDocumentModal';
import RenameDocumentModal from 'components/Documents/RenameDocumentModal';
import ShareDocumentModal from 'components/Documents/ShareDocumentModal';
import ProFeatureModal from 'components/Modals/ProFeatureModal/ProFeatureModal';
import {SortDirection, SortField} from 'schemas/sort';
import {getUserSort, isFreeUser, setUserSort} from 'utils/store';
import {FOLDER_SORT, FREE_USER_LIMIT_DOCS, HOME_TITLE} from 'utils/constants';
import _ from 'lodash';
import {UpgradeToProModal} from '../../components/Modals';

const folderService = new FolderService();
const documentService = new DocumentService();

const Documents = () => {
  const location = useLocation();
  const routeMatch = useRouteMatch<{ id: string }>({
    path: '/folders/:id/',
  });
  const params = routeMatch?.params;

  const { documents, getDocuments, setDocuments, isLoading, createdDocs } = useDocumentCollection({
    folder: params?.id,
  });

  const [sortedDocuments, setSortedDocuments] = useState([] as DocumentSchema[]);
  const [editDocument, setEditDocument] = useState({} as DocumentSchema);
  const [currentFolder, setCurrentFolder] = useState({} as FolderSchema);
  const [creating, setCreating] = useState({ loading: false, type: DocumentType.MindMap });
  const [isShowCreate, setShowCreate] = useState(false);
  const [isShowRename, setShowRename] = useState(false);
  const [isShowShare, setShowShare] = useState(false);
  const [isShowProFeature, setShowProFeature] = useState(false);
  const [isShowUpgradeModal, setShowUpgradeModal] = useState(false);
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [keyword, setKeyword] = useState('');
  const {folder} = useFolderDetail(params?.id);
  const {isShareFolder, folderRole} = useCheckFolder(folder);
  const history = useHistory();

  useEffect(() => {
    if (keyword === '') {
      getDocuments(params?.id);
    } else {
      const options = {
        includeScore: true,
        keys: ['name']
      };
      const fuse = new Fuse(documents, options);
      const result = fuse.search(keyword);
      let docResult: DocumentSchema[] = [];
      result.forEach(item => {
        docResult.push(item.item);
      })
      setDocuments(docResult);
    }
  }, [keyword]);

  useEffect(() => {
    setSortedDocuments(getSorted(documents));
  }, [documents])

  useEffect(() => {
    let subscribe = () => {};
    const getDocs = async() => {
      await getDocuments(params?.id).then((res) => {
        subscribe = res;
      });
    }
    getDocs();
    updateFolder();
    return () => {
      if (typeof subscribe === 'function') {
        subscribe();
      }
    };
  }, [location]);

  const updateFolder = async () => {
    if (params?.id) {
      const folderStore = await folderService.getOne(params.id);
      const folderData = folderStore.data();
      let parentFolder = {} as FolderSchema;
      if (folderData?.parentFolder) {
        const parentData = await folderData?.parentFolder.get();
        parentFolder = {
          id: folderData?.parentFolder.id,
          ...parentData.data(),
        } as FolderSchema;
      }
      const folder = {
        ...folderData,
        id: folderStore.id,
        parentFolder: parentFolder,
      } as FolderSchema;
      setCurrentFolder(folder);
    }
  };

  const isCreating = (type: DocumentType) => {
    return creating.loading && creating.type === type;
  }

  const showRenameModal = (document: DocumentSchema) => {
    setEditDocument(document);
    setShowRename(true);
  };

  const showShareModal = (doc: DocumentSchema) => {
    setEditDocument(doc);
    setShowShare(true);
  };

  const handleSort = (key: SortField) => {
    if (sortDirection === SortDirection.ASC) {
      setDocuments(sort(documents).asc(item => _.get(item, key, "")));
      setUserSort(FOLDER_SORT, {
        field: key,
        direction: SortDirection.ASC
      });
      setSortDirection(SortDirection.DESC);
    } else {
      setDocuments(sort(documents).desc(item => _.get(item, key, "")));
      setUserSort(FOLDER_SORT, {
        field: key,
        direction: SortDirection.DESC
      });
      setSortDirection(SortDirection.ASC);
    }
  }

  const getSorted = (documents: DocumentSchema[]) => {
    const userSort = getUserSort(FOLDER_SORT);
    let sortedDocuments: DocumentSchema[] = [];
    if (userSort) {
      if (userSort.direction === 'asc') {
        sortedDocuments = sort(documents).asc((item: any) => item[userSort.field]);
      } else {
        sortedDocuments = sort(documents).desc((item: any) => item[userSort.field]);
      }
      return sortedDocuments;
    }
    return documents;
  }

  let folderName = 'My Documents';
  if (currentFolder.id) {
    folderName = currentFolder.name;
    if (currentFolder.parentFolder && currentFolder.parentFolder.id) {
      folderName = currentFolder.parentFolder.name + ' / ' + currentFolder.name;
    }
  }

  const handleCreateDocument = async (type: DocumentType) => {
    const isLimitDocs = isFreeUser() && (documents.filter(doc => doc.type !== DocumentType.MindMap).length) >= FREE_USER_LIMIT_DOCS;

    // Show limit modal
    if (isLimitDocs && !isMindMap(type)) {
      setShowProFeature(isLimitDocs);
      return false;
    }

    const saveData: any = {
      name: DefaultDocumentName[type],
      folder: params?.id || '',
      type,
    };

    if (isMindMap(type)) {
      saveData.xmlData = DefaultDocumentChart[type];
      saveData.theme = DocumentTheme.Rainbow;
      localStorage.setItem('theme', DocumentTheme.Rainbow);
    }

    // Create new document
    try {
      setCreating({ loading: true, type });
      const { id } = await documentService.create(saveData);
      setCreating({ loading: false, type });
      history.push(`/${type}/${id}`);
    } catch (e: any) {
      toaster.danger(e.message);
    }
  }

  return (
    <>
      <Helmet
        title={`${HOME_TITLE} - ${currentFolder?.name || 'My Documents'}`}
      />

      <Card>
        <CardHeader>
          <CardTitle>{folderName}</CardTitle>
          <Pane className={clsx('d-none d-md-block')} >
            <SearchInput value={keyword} onChange={(e: any) => setKeyword(e.target.value)} placeholder="Search" />
            {(!isShareFolder || (isShareFolder && folderRole === "editor")) &&
              <Popover
                position={Position.BOTTOM_RIGHT}
                content={
                  <Menu>
                    <Menu.Group>
                      <Menu.Item
                        className={styles.menuSelectDocumentItem}
                        icon={isCreating(DocumentType.Flowchart) ? <Loader color="#8F95B2"/> : <DataLineageIcon/>}
                        onClick={() => handleCreateDocument(DocumentType.Flowchart)}
                      >
                        Flowchart
                      </Menu.Item>
                      <Menu.Item
                        className={styles.menuSelectDocumentItem}
                        icon={isCreating(DocumentType.MindMap) ? <Loader color="#8F95B2"/> : <LayoutAutoIcon/>}
                        onClick={() => handleCreateDocument(DocumentType.MindMap)}
                      >
                        Mind Map
                      </Menu.Item>
                      <Menu.Item
                        className={styles.menuSelectDocumentItem}
                        icon={isCreating(DocumentType.Whiteboard) ? <Loader color="#8F95B2"/> : <PresentationIcon/>}
                        onClick={() => handleCreateDocument(DocumentType.Whiteboard)}
                      >
                        Whiteboard
                      </Menu.Item>
                    </Menu.Group>
                  </Menu>
                }
              >
                <Button
                  appearance="primary"
                  iconBefore={PlusIcon}
                  className={clsx('ml-3', styles.newDocumentBtn)}
                  intent="success"
                  isLoading={createdDocs < 0}
                >
                  New Document
                </Button>
              </Popover>
            }
          </Pane>
        </CardHeader>
        <CardBody>
          {isLoading && <Spinner />}
          {!isLoading && (
            <Table>
              <TableHead>
                <TableHeaderCell onSort={() => handleSort(SortField.Name)}>Title</TableHeaderCell>
                <TableHeaderCell onSort={() => handleSort(SortField.Type)} className={styles.typeColumn}>Type</TableHeaderCell>
                <TableHeaderCell onSort={() => handleSort(SortField.CreatedAt)} className={styles.dateCreated}>Date Created</TableHeaderCell>
                <TableHeaderCell onSort={() => handleSort(SortField.UpdatedAt)}>Last Modified</TableHeaderCell>
                <TableHeaderLastCell />
              </TableHead>
              <TableBody className="dragcontainer">
                {sortedDocuments?.map((doc) => (
                  <DocumentRow
                    key={doc.id}
                    doc={doc}
                    showRenameModal={showRenameModal}
                    showShareModal={showShareModal}
                    createdDocs={createdDocs}
                    setShowProFeature={setShowProFeature}
                    folder={folder}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </CardBody>
      </Card>

      <CreateDocumentModal
        isShown={isShowCreate}
        setIsShown={setShowCreate}
        folder={params?.id || ''}
      />
      <RenameDocumentModal
        isShown={isShowRename}
        setIsShown={setShowRename}
        document={editDocument}
      />
      <ShareDocumentModal
        isMindMap={isMindMap(editDocument.type)}
        isShown={isShowShare}
        setIsShown={setShowShare}
        doc={editDocument}
      />
      <ProFeatureModal
        isShown={isShowProFeature}
        setIsShown={setShowProFeature}
        title={"Free Limit Reached"}
        onConfirm={() => {
          setShowProFeature(false);
          setShowUpgradeModal(true);
        }}
        message={(
          <Pane>
          You have reached 3 documents per account limit for
          <br/>free accounts. Upgrade to a paid plan for unlimited
            <br/> access and more!
        </Pane>)}
      />
      <UpgradeToProModal isShown={isShowUpgradeModal} setIsShown={setShowUpgradeModal} />
    </>
  );
};

export default Documents;
