import { UserSchema } from './user';
import firebase from 'firebase';

export interface DocumentSchema {
  id: string;
  name: string;
  filetype: string;
  folder: string;
  isDeleted: boolean;
  isParentDeleted: boolean;
  ordinal: number;
  theme?: DocumentTheme;
  type: DocumentType;
  user: UserSchema;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;

  roles: any;
  teamRoles: any;
  invites: any;
  xmlData: string;

  graphData: string;
}

export enum DocumentType {
  Flowchart = 'flowchart',
  MindMap = 'mindmap',
  Wireframe = 'wireframe',
  Whiteboard = 'whiteboard',
  Document = 'docs',
}

export enum DocumentTheme {
  Minimal = 'minimal',
  Rainbow = 'rainbow',
  Contrast = 'contrast'
}

export const DefaultDocumentName = {
  [DocumentType.Flowchart]: 'New Flowchart',
  [DocumentType.MindMap]: 'New Mind Map',
  [DocumentType.Wireframe]: 'New Wireframe',
  [DocumentType.Whiteboard]: 'New Whiteboard',
  [DocumentType.Document]: 'New Document',
}

export const DocumentTypeDisplay = {
  [DocumentType.Flowchart]: 'Flowchart',
  [DocumentType.MindMap]: 'Mind Map',
  [DocumentType.Wireframe]: 'Wireframe',
  [DocumentType.Whiteboard]: 'Whiteboard',
  [DocumentType.Document]: 'Document',
}

export const DefaultDocumentChart = {
  [DocumentType.Flowchart]: '',
  [DocumentType.Wireframe]: '',
  [DocumentType.Whiteboard]: '',
  [DocumentType.Document]: '',
  [DocumentType.MindMap]: '<map name="Azr9dXGQgNgTTCVzU3IX" version="tango"><topic central="true" text="Central Topic" id="1" fontStyle=";;#283D59;;;" bgColor="#ffffff"><topic position="-116,-32" order="1" id="3" fontStyle=";;#283D59;;;" bgColor="#ffffff"><topic position="-212,-64" order="0" id="4" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="-212,-32" order="1" id="5" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="-212,0" order="2" id="8" fontStyle=";;#283D59;;;" bgColor="#ffffff"/></topic><topic position="117,-48" order="0" id="2" fontStyle=";;#283D59;;;" bgColor="#ffffff"><topic position="212,-80" order="0" id="11" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="212,-48" order="1" id="12" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="212,-16" order="2" id="13" fontStyle=";;#283D59;;;" bgColor="#ffffff"/></topic><topic position="117,48" order="2" id="14" fontStyle=";;#283D59;;;" bgColor="#ffffff"><topic position="212,16" order="0" id="15" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="212,48" order="1" id="16" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="212,80" order="2" id="17" fontStyle=";;#283D59;;;" bgColor="#ffffff"/></topic><topic position="-116,48" order="3" id="18" fontStyle=";;#283D59;;;" bgColor="#ffffff"><topic position="-211,32" order="0" id="23" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="-211,64" order="1" id="24" fontStyle=";;#283D59;;;" bgColor="#ffffff"/><topic position="10,10" order="2" id="25" fontStyle=";;#283D59;;;" bgColor="#ffffff"/></topic></topic></map>',
}

export type DocumentInput = {
  name: string;
  folder?: string;
  type: DocumentType;
}

export const isMindMap = (type: DocumentType) => type === DocumentType.MindMap;
