import styles from "../UserSettings/UserSettings.module.scss";
import {Button, Card, ChevronRightIcon, Heading, Paragraph, toaster} from "evergreen-ui";
import {useState} from "react";
import {StripeService} from 'services/stripe';

const stripe = new StripeService();

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const handleClickCustomerPortal = async () => {
    try {
      setLoading(true);
      const { data } = await stripe.createPortalLink()
      window.open(data.url, '_blank');
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <Card className={styles.card}>
        <Heading size={500}>Subscription & Billing</Heading>
        <Paragraph className="mt-3">
          Visit our Customer Portal to manage your subscription, payment information, and billing history.
        </Paragraph>
        <Button
          appearance="primary"
          className="mt-3"
          isLoading={loading}
          onClick={handleClickCustomerPortal}
        >
          <span className="mr-2">Customer Portal</span> <ChevronRightIcon size={12} />
        </Button>
      </Card>
    </>
  )
}

export default Subscription;
