import firebase from "firebase";
import {getUser} from "../../utils/store";
import {UserSchema} from "../../schemas";
import {db, functions} from 'utils/firebase';
import {TEAM_COLLECTION} from "../../utils/constants";

export class TeamService {
  private readonly db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
  private readonly functions: firebase.functions.Functions;
  private readonly user: UserSchema;

  constructor() {
    this.user = getUser();
    this.db = db.collection(TEAM_COLLECTION);
    this.functions = functions;
  }

  async getTeams(teamIds: string[]){
    return this.db.where(firebase.firestore.FieldPath.documentId(), 'in', teamIds).get()
  }

  async getTeamById(teamId: string){
    return await this.db.doc(teamId).get();
  }

  async getTeam(): Promise<firebase.functions.HttpsCallableResult>{
    const callable = functions.httpsCallable("getTeam");
    return callable();
  }

  async addMembers(members: any): Promise<firebase.functions.HttpsCallableResult>{
    const callable = functions.httpsCallable("teamAddMembers");
    return callable({members});
  }

  async removeMembers(members: any): Promise<firebase.functions.HttpsCallableResult>{
    const callable = functions.httpsCallable("teamRemoveMembers");
    return callable({members});
  }

  async rename(name: string): Promise<firebase.functions.HttpsCallableResult>{
    const callable = functions.httpsCallable("renameTeam");
    return callable({name});
  }
}
