import {Button, majorScale, Pane, Paragraph, PlusIcon, Position, Tooltip} from "evergreen-ui";
import clsx from "clsx";
import styles from "./TasksView.module.scss";
import TaskCreate from "./TaskCreate";
import React, {useEffect, useState} from "react";
import {TASK_TYPE, VIEW_MODE} from "../../services/task/tasks.helper";

type Props = {
  projectId: string,
  members: any,
  canManageProject: boolean,
  dateAble: boolean,
  viewMode: VIEW_MODE,
  headline?: boolean,
}

const TaskCreateView = ({projectId, members, canManageProject, dateAble, viewMode, headline}: Props) => {
  const [showTaskCreate, setShowTaskCreate] = useState(false);
  const [showHeadingCreate, setShowHeadingCreate] = useState(false);

  const onHandleSpaceKey = (event: any) => {
    const isSpaceKey = event.code === "Space";
    if(!isSpaceKey) return;

    const activeEl = document.activeElement;
    const tagName = activeEl?.tagName.toLowerCase() || "";
    const inputs = ['input', 'select', 'button', 'textarea'];

    if(!activeEl || inputs.includes(tagName)) return;

    setShowTaskCreate(true);
  }

  useEffect(() => {
    document.addEventListener("keyup", onHandleSpaceKey);
    return () => {
      document.removeEventListener("keyup", onHandleSpaceKey);
    }
  }, []);

  return (
    <Pane>
      <Pane display={"flex"} paddingX={majorScale(2)}>
        {canManageProject &&
          <Pane paddingTop={majorScale(2)}>
            <Tooltip
              content={(
                <Paragraph
                  style={{
                    color: 'white',
                    fontSize: '12px',
                    lineHeight: '16px'
                  }}
                >
                  Press Spacebar to add new task
                </Paragraph>
              )}
              position={Position.RIGHT}
            >
              <Button
                appearance="primary"
                iconBefore={PlusIcon}
                className={clsx(styles.newTaskBtn)}
                intent="success"
                onClick={() => setShowTaskCreate(true)}
              >
                New Task
              </Button>
            </Tooltip>
          </Pane>
        }
        {headline &&
          <Pane paddingTop={majorScale(2)} marginLeft={majorScale(1)}>
            <Button
              iconBefore={<PlusIcon color="#8F95B2"/>}
              className={clsx(styles.headlineBtn)}
              onClick={() => setShowHeadingCreate(true)}
              color="#696F8C"
            >
              Headline
            </Button>
          </Pane>
        }
      </Pane>
      <Pane paddingX={majorScale(2)}>
        {showTaskCreate &&
          <TaskCreate
            project={projectId}
            onCreated={() => setShowTaskCreate(false)}
            members={members}
            dateAble={dateAble}
            viewMode={viewMode}
          />
        }
      </Pane>
      <Pane>
        {showHeadingCreate &&
          <TaskCreate
            project={projectId}
            onCreated={() => setShowHeadingCreate(false)}
            members={members}
            dateAble={dateAble}
            viewMode={viewMode}
            taskType={TASK_TYPE.HEADING}
          />
        }
      </Pane>
    </Pane>
  )
}

export default TaskCreateView;
