import clsx from 'clsx';
import { Dialog } from 'evergreen-ui';
import styles from './Modal.module.scss';

export const Modal = ({ intent, ...props }: any) => (
  <Dialog
    {...props}
    containerProps={{
      className: clsx(
        styles.modal,
        intent === 'danger' && styles.modalDanger,
        intent === 'editModal' && styles.modalEditLink,
      ),
    }}
    contentContainerProps={{
      className: styles.modalContent,
    }}
  />
);
