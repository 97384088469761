import { mxCell } from "mxgraph";
import { Serializer } from "./";

export class ZenRealTimeObject {
  private readonly _doc: any;
  private readonly _session: any;
  private readonly _cell: mxCell | undefined;

  constructor(doc: any, cell?: mxCell, session?: any) {
    this._doc = doc;
    this._cell = cell;
    this._session = session;
  }

  async getDoc() {
    const doc = await this._doc.get();
    if (doc.exists) {
      return doc.data();
    }
    return null;
  }

  async set(key: string, value: any) {
    try {
      const cell = await this._doc.get();
      if (cell.exists) {
        this._doc.update({
          [key]: value,
          session: this._session,
        });
      } else if (this._cell) {
        const cellJson = Serializer.serializeMxCell(this._cell);
        console.log('unmap json', cellJson);
      }
    } catch (e: any) {
      console.log('Cant update firestore: ', e);
    }
  }

  hasKey(key: string) {
    return this._doc.hasOwnProperty(key);
  }

  on(key: string, funct: any) {
    return 'ok';
  }

  get(key: string) {
    return this._cell ? this._cell[key] : null;
  }
}
