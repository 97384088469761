import clsx from 'clsx';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import styles from './NotePage.module.scss';
import NoteList from 'components/NoteEditor/NoteList/MyNoteList';
import { NoteListType } from 'schemas';
import useQuery from 'hooks/useQuery';
import NoteEditorContainer from 'components/NoteEditor/NoteEditorContainer';
import useStores from 'hooks/useStores';
import { Observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useNotebookDetail } from 'services/note';
import { HOME_TITLE } from 'utils/constants';

export default function NotePage() {
  const query = useQuery();
  const params = useParams() as any;
  const noteId = query.get('noteId') as string;
  const { noteStore } = useStores();
  const notebookId = params?.id || '';
  const { notebook } = useNotebookDetail(notebookId);

  useEffect(() => {
    noteStore.setNoteId(noteId);
  }, [noteId]);

  useEffect(() => {
    noteStore.setNotebook(notebook);
  }, [notebook]);

  return (
    <>
      {notebook?.id && (
        <Helmet title={`${HOME_TITLE} - ${notebook?.name || 'Note'}`} />
      )}

      <section className={clsx(styles.mainSection)}>
        <div className={clsx(styles.listContainer)}>
          <NoteList
            key={notebookId}
            notebookId={notebookId}
            type={NoteListType.MYNOTE}
          />
        </div>

        <div className={clsx(styles.editorContainer)}>
          <Observer>
            {() => (
              <>
                {noteStore.noteId && (
                  <NoteEditorContainer key={noteStore.noteId} />
                )}
              </>
            )}
          </Observer>
        </div>
      </section>
    </>
  );
}
