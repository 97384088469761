import { Pane } from 'evergreen-ui';
import clsx from 'clsx';
import logo from 'assets/img/logo.svg';
import styles from './Board.module.scss';
import MobileAppBoard from "./MobileAppBoard";
import AppBoard from "./App/AppBoard";
import AppHeaderBoard from "./App/Header";
import TaskBoard from "./Task/TaskBoard";
import NoteBoard from "./Note/NoteBoard";
import SoundBoard from "./Sound";
import KanbanBoard from "./KanbanBoard";
import PageBoard from "./Page/PageBoard";

type Props = {
  children: any;
  layout: string;
};

const Board = ({ children, layout }: Props) => {
  return (
    <>
      <div className={clsx(styles.container, (!layout || layout === "appBoard") ? 'app-main-container' : 'task-main-container')}>
        <div className={clsx(styles.mobileHeader)}>
          <Pane className={clsx('text-center', styles.mobileLogo)}>
            <img src={logo} width="40" alt="logo" />
          </Pane>
          <MobileAppBoard />
        </div>

        <aside className={styles.aside}>
          {layout === "taskBoard" &&
            <TaskBoard header={(<AppHeaderBoard />)} />
          }
          {layout === "okrBoard" &&
            <PageBoard header={(<AppHeaderBoard />)} />
          }
          {layout === "noteBoard" &&
            <NoteBoard header={(<AppHeaderBoard />)} />
          }
          {layout === "sidebar-sound" &&
            <SoundBoard header={(<AppHeaderBoard />)} />
          }
          {layout === "sidebar-kanban" &&
            <KanbanBoard header={(<AppHeaderBoard />)} />
          }
          {(!layout || layout === "appBoard") &&
            <AppBoard header={(<AppHeaderBoard />)} />
          }
        </aside>

        <div
          className={clsx(
            styles.main,
            layout === 'taskBoard' && styles.taskContainer,
            layout === 'okrBoard' && styles.pageContainer,
          )}
        >
          <div className={clsx((!layout || layout === "appBoard") ? styles.mainContent : styles.appContent)}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Board;
