/* eslint-disable @typescript-eslint/no-unused-vars */

import './App.scss';
import 'bootstrap-utilities/bootstrap-utilities.css';

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from 'utils/constants';

import routes from 'pages/routes';
import { getPageName } from 'utils/helpers';
import { ThemeProvider } from 'evergreen-ui';
import LayoutHandler from 'layouts/LayoutHandler/LayoutHandler';
import theme from 'utils/theme';
import { useEffect, useState } from 'react';
import { auth, db } from 'utils/firebase';
import * as Sentry from '@sentry/react';
import { deleteUser, getUser, setTeams } from 'utils/store';
import {
  SIGN_IN_URL,
  USER_COLLECTION,
  HOME_TITLE,
  // CONVERGENCE_URL,
  GA_MEASUREMENT_ID,
  TAG_MANAGER_ID,
  NATIVE_GA_MEASUREMENT_ID, FACEBOOK_PIXEL_ID,
} from 'utils/constants';
// // import { AuthService } from '../../services/authService';
// import { useStores } from '../../hooks/useStores';
// import { useApp } from '../../hooks/useApp';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
import { DatabaseSnapshotContext } from '../../hooks/useDatabaseSnapshot';
import { useActivateTeam } from "../../services/team/team.helper";

const App = () => {
  const location = useLocation();
  const history = useHistory();
  // const { convergenceStore } = useStores();
  // const { isConnectedConvergence, setIsConnectedConvergence } = useApp();
  const [unsubscribeDatabaseSnapshot, setDatabaseSnapshot] = useState(() => { });
  const [title, setTitle] = useState('Zen Flowchart');
  const { activateTeams } = useActivateTeam();

  useEffect(() => {
    window.process = process;
    window.process.env = process.env;
  }, []);

  useEffect(() => {
    const handleClickLink = (event: any) => {
      const e = event.target as HTMLInputElement
      if(e && e.getAttribute('data-slate-string')){
        console.log('handleClickLink');
        event?.preventDefault();
        const href = e?.closest('a')?.getAttribute('href');
        if (href) {
          (window as any).open(href, '_blank').focus();
        }
      }
    }
    document.addEventListener('click',function(event){
      handleClickLink(event)
    });
    return () => {
      document.removeEventListener('click', handleClickLink, true);
    }
  }, []);

  useEffect(() => {
    setTeams((activateTeams || []).map(team => ({ id: team.id })));
  }, [activateTeams]);

  // useEffect(() => {
  //   let unsubscribe: { (): void; (): void } | null = null;
  //   let pathName = location.pathname.split('/')[1]
  //   if (pathName !== 'zen-sound' && pathName !== 'zen-sounds') {
  //     auth.onAuthStateChanged(async userAuth => {
  //       if (userAuth) {
  //         unsubscribe = db
  //           .collection(USER_COLLECTION)
  //           .doc(userAuth.uid)
  //           .onSnapshot(async snapshot => {
  //             if (snapshot.exists) {
  //               // updateUser(snapshot.data());
  //             } else {
  //               const user = getUser();
  //               if (user?.email) {
  //                 await auth.signOut();
  //                 deleteUser();
  //                 history.push(SIGN_IN_URL);
  //               }
  //             }
  //           });
  //         setDatabaseSnapshot(() => unsubscribe);
  //       } else {
  //         await auth.signOut();
  //         deleteUser();
  //         history.push(SIGN_IN_URL);
  //       }
  //     });
  //     return () => {
  //       if (unsubscribe) {
  //         unsubscribe();
  //       }
  //     };
  //   }
  // }, [history]);

  useEffect(() => {
    setTitle(getPageName(location.pathname));
  }, [location.pathname])

  // useEffect(() => {
  //   const handleTasks = async () => {
  //     if (!isConnectedConvergence) {
  //       // Only get token when authentication
  //       if (getUser()) {
  //         const authService = new AuthService();
  //         await authService.getConvergenceToken();
  //       }
  //       await convergenceStore.connect(CONVERGENCE_URL);
  //       setIsConnectedConvergence(true);
  //       console.log('connected');
  //     }
  //   }

  //   handleTasks();
  // }, [convergenceStore, isConnectedConvergence, setIsConnectedConvergence, location.pathname]);

  useEffect(() => {
    ReactPixel.init(FACEBOOK_PIXEL_ID);
    ReactGA.initialize([
      { trackingId: NATIVE_GA_MEASUREMENT_ID },
      { trackingId: GA_MEASUREMENT_ID },
    ]);
    TagManager.initialize({
      gtmId: TAG_MANAGER_ID
    });
  }, []);

  useEffect(() => {
    const user = getUser();
    if (user?.email) {
      Sentry.setUser({ email: user.email });
    } else {
      Sentry.configureScope(scope => scope.setUser(null));
    }
  }, [location.pathname]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  return (
    <Switch>
      {routes.map(({ component: Component, path, layout, title: defaultTitle, ...rest }) => (
        <Route key={path} path={path} {...rest}>
          <Elements stripe={stripePromise}>
            <HelmetProvider>
              <Helmet
                title={`${HOME_TITLE} - ${defaultTitle || title}`}
              />
              <ThemeProvider value={theme as any}>
                <DatabaseSnapshotContext.Provider
                  value={{
                    unsubscribeDatabaseSnapshot,
                    setDatabaseSnapshot
                  }}
                >
                  <LayoutHandler layout={layout}>
                    <Component />
                  </LayoutHandler>
                </DatabaseSnapshotContext.Provider>
              </ThemeProvider>
            </HelmetProvider>
          </Elements>
        </Route>
      ))}
    </Switch>
  );
};

export default App;
