import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Button, majorScale, TextInput } from 'evergreen-ui';
import {UserService} from "../../services/user";
import React from "react";

export const RedeemCodeForm = ({ onError, onSuccess }: { onError: Function, onSuccess: Function }) => {
  const location = useLocation();
  const userService = new UserService();
  const locationQuery = new URLSearchParams(location.search);

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    isSubmitting,
    setSubmitting,
    resetForm
  } = useFormik({
    initialValues: {
      email: locationQuery.get('email') || '',
      password: '',
      code: '',
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email()
        .required(),
      code: Yup
        .string()
        .required(),
    }),
    onSubmit: async ({ email, code }) => {
      onError("");
      onSuccess(false);
      try {
        await userService.upgradeToPro({email, code})
          .then(() => {
            onSuccess(true)
          });
      } catch (e: any) {
        onError(e.message);
      } finally {
        resetForm();
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="mt-45" onSubmit={handleSubmit}>
      <TextInput
        type="email"
        placeholder="Email Address"
        className="w-100"
        isInvalid={!!(touched.email && errors.email)}
        {...getFieldProps('email')}
      />
      <TextInput
        placeholder="Your Redeem Code"
        className="mt-3 w-100"
        isInvalid={!!(touched.code && errors.code)}
        {...getFieldProps('code')}
      />
      <Button
        appearance="primary"
        type="submit"
        className="w-100 mt-3"
        height={majorScale(5)}
        isLoading={isSubmitting}
      >
        Redeem
      </Button>
    </form>
  )
}
