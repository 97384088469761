import {Pane} from 'evergreen-ui';
import { Control } from '../../Control/Control';
import { Box } from '../../Box/Box';
import React from "react";
import _ from "lodash"
import { ZwfConstants } from '../../../../utils/ZwfConstants';
import {changeOrientation} from "../../../../utils/Actions";

const OrientationPanel = ({editorUi, graphState, dispatchGraphState}) => {

  const onOrientationChange = (orientation) => {
    dispatchGraphState({zwfFrameOrientation: orientation});
    changeOrientation({editorUi, graphState, dispatchGraphState, orientation});
  }

  return (
    <Box title="Orientation" isDivider>
      <Pane className="mt-3">
        <Control
          value={_.get(graphState, ZwfConstants.STYLE_FRAME_ORIENTATION)}
          options={[
            { label: 'Portrait', value: ZwfConstants.FRAME_ORIENTATION_PORTRAIT },
            { label: 'Landscape', value: ZwfConstants.FRAME_ORIENTATION_LANDSCAPE },
          ]}
          onChange={val => onOrientationChange(val)}
        />
      </Pane>
    </Box>
  )
}

export default OrientationPanel;
