import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { withReact } from 'slate-react';
import { Node, createEditor, Transforms, Editor } from 'slate';
import { CursorData } from './custom-types';
import * as Y from 'yjs';
import {
  SyncElement,
  toSharedType,
  useCursors,
  withCursor,
  withYjs,
} from 'slate-yjs';
// import { withHistory } from 'slate-history';
import randomColor from 'randomcolor';
import _ from 'lodash';
import { Pane } from 'evergreen-ui';
import {
  createAlignPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createCodeBlockPlugin,
  createCodePlugin,
  createHeadingPlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
  createPlateUI,
  createPlugins,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  Plate,
  withPlate,
  // createFontBackgroundColorPlugin,
  // createFontFamilyPlugin,
  // createFontColorPlugin,
  // createFontSizePlugin,
  createFontWeightPlugin,
  createKbdPlugin,
  createNodeIdPlugin,
  createIndentPlugin,
  createAutoformatPlugin,
  createResetNodePlugin,
  createSoftBreakPlugin,
  createExitBreakPlugin,
  createNormalizeTypesPlugin,
  createTrailingBlockPlugin,
  createSelectOnBackspacePlugin,
  createComboboxPlugin,
  // createMentionPlugin,
  createJuicePlugin,
  createFindReplacePlugin,
  createHorizontalRulePlugin,
  createDeserializeMdPlugin,
  // createPlateEditor,
} from '@udecode/plate';
import { WebsocketProvider } from 'y-websocket';

import styles from './NoteEditor.module.scss';
import { Leaf } from './Leaf';
import Toolbar from './Toolbar/Toolbar';
import { NoteService } from 'services/note';
import { initialValue, initSelection } from './note.config';
import { getUser } from 'utils/store';
import NoteMenubar from './NoteMenubar/NoteMenubar';
import useStores from 'hooks/useStores';
import { NoteListType, NotePermissonType } from 'schemas';
import clsx from 'clsx';
import NoteTrashMenubar from './NoteMenubar/NoteTrashMenubar';
import NoteShareMenubar from './NoteMenubar/NoteShareMenubar';
import { isHaveNotebookPermission, isHaveNotePermission } from 'services/user';
import TagMenubar from './Tag/TagMenubar';
import { withStyledPlaceHolders } from './config/components/withStyledPlaceHolders';
import { CONFIG } from './config/config';
import { NOTE_SERVER } from 'utils/constants';
import { Spinner } from 'components/Spinner/Spinner';
import { getUserName } from 'utils/helpers';
// import { createLinkPlugin } from 'libs/plate-link/src';

const noteService = new NoteService();

export type NoteEditorProps = {
  type?: NoteListType;
  isHideMenu?: boolean;
  noteId: string;
};

export default function NoteEditor({
  isHideMenu = false,
  noteId,
}: NoteEditorProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [isSynced, setIsSynced] = useState(false);
  const { noteStore } = useStores();
  const editorRef = useRef() as any;
  const yjsEditorRef = useRef() as any;
  const username = getUserName();
  console.log(username);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'hex',
        alpha: 1,
      }),
    [],
  );
  const cursorData: CursorData = {
    color: color,
    name: username,
  };

  let components = createPlateUI();
  components = withStyledPlaceHolders(components);

  const plugins = useMemo(() => {
    const search = noteStore.search;
    return createPlugins(
      [
        createParagraphPlugin(),
        createBlockquotePlugin(),
        createTodoListPlugin(),
        createHeadingPlugin(),
        // createImagePlugin(),
        createHorizontalRulePlugin(),
        // createLineHeightPlugin(CONFIG.lineHeight),
        createLinkPlugin(),
        createListPlugin(),
        createTablePlugin(),
        // createMediaEmbedPlugin(),
        // createExcalidrawPlugin(),
        createCodeBlockPlugin(),
        createAlignPlugin(CONFIG.align),
        createBoldPlugin(),
        createCodePlugin(),
        createItalicPlugin(),
        // createHighlightPlugin(),
        createUnderlinePlugin(),
        // createStrikethroughPlugin(),
        // createSubscriptPlugin(),
        // createSuperscriptPlugin(),
        // createFontBackgroundColorPlugin(),
        // createFontFamilyPlugin(),
        // createFontColorPlugin(),
        // createFontSizePlugin(),
        createFontWeightPlugin(),
        createKbdPlugin(),
        createNodeIdPlugin(),
        createIndentPlugin(CONFIG.indent),
        createAutoformatPlugin(CONFIG.autoformat),
        createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        createTrailingBlockPlugin(CONFIG.trailingBlock),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),
        createComboboxPlugin(),
        // createMentionPlugin(),
        createJuicePlugin(),
        createDeserializeMdPlugin(),

        createFindReplacePlugin({ options: { search } }),
      ],
      {
        components,
      },
    );
  }, [noteStore.search]);

  const [sharedType, provider] = useMemo(() => {
    const doc = new Y.Doc();
    const sharedType = doc.getArray<SyncElement>('content');
    const provider = new WebsocketProvider(NOTE_SERVER, noteId, doc, {
      connect: false,
    });
    return [sharedType, provider];
  }, [noteId]);

  const [slateEditor] = useState(withReact(createEditor() as any));
  if (!editorRef.current && !yjsEditorRef.current) {
    yjsEditorRef.current = withCursor(
      withYjs(slateEditor, sharedType),
      provider.awareness,
    );
    editorRef.current = withPlate(yjsEditorRef.current, { plugins });
  }
  const editor = editorRef.current;
  const yjsEditor = yjsEditorRef.current;

  const { decorate } = useCursors(yjsEditor);
  const renderLeaf = useCallback(props => <Leaf {...props} />, [decorate]);

  useEffect(() => {
    provider.awareness.setLocalState({
      alphaColor: `${cursorData.color}66`,
      color: `${cursorData.color}88`,
      name: cursorData.name,
    });

    // Super hacky way to provide a initial value from the client, if
    // you plan to use y-websocket in prod you probably should provide the
    // initial state from the server.
    provider.on('sync', (isSynced: boolean) => {
      if (isSynced) {
        setIsSynced(true);
        setTimeout(() => {
          if (Editor.hasPath(editor, initSelection.anchor.path)) {
            Transforms.select(slateEditor, initSelection);
          }
        }, 400);
      }

      const noteData = noteStore.note;
      if (isSynced && sharedType.length === 0) {
        if (noteId === noteData?.id) {
          toSharedType(sharedType, noteData?.content || initialValue);
        }
      }
    });

    provider.connect();

    return () => {
      provider.disconnect();
    };
  }, [provider]);

  const serialize = (nodes: any) => {
    return nodes.map((n: any) => Node.string(n)).join(' ');
  };

  const updateNote = async (data: any, noteData: any, noteId: string) => {
    try {
      const compare = (value1: any, value2: any) => {
        if (_.isEqual(_.omit(value1, 'id'), _.omit(value2, 'id'))) {
          return true;
        }
      };
      if (
        !_.isEmpty(data) &&
        !_.isEqualWith(data, noteData?.content, compare)
      ) {
        setIsSaving(true);
        const title = data[0]?.children[0]?.text;
        const text = serialize(data);
        await noteService.updateOne(noteId, {
          content: data,
          text: text,
          title: title || '',
        });
        setIsSaving(false);
      }
    } catch {}
  };
  const updateNoteDebounced = useRef(_.debounce(updateNote, 1500));

  const onChange = (data: any) => {
    const noteData = noteStore.note;
    if (typeof editor != 'undefined' && noteData) {
      updateNoteDebounced.current(data, noteData, noteId);
    }
  };

  const isReadOnly = useMemo(() => {
    const noteData = noteStore.note;
    if (noteData?.isDeleted) {
      return true;
    } else {
      let isEditable = false;
      if (noteStore.notebook?.id) {
        isEditable = isHaveNotebookPermission(noteStore.notebook, NotePermissonType.EditNote);
        if (!isEditable) {
          isEditable = isHaveNotePermission(noteData, NotePermissonType.EditNote);
        }
      } else {
        isEditable = isHaveNotePermission(noteData, NotePermissonType.EditNote);
      }
      return !isEditable;
    }
  }, [noteStore.note]);

  const EditorMenu = useCallback(() => {
    const noteData = noteStore.note;
    const user = getUser();
    if (noteData?.isDeleted) {
      return <NoteTrashMenubar />;
    } else if (user?.uid === noteData.user) {
      return <NoteMenubar isSaving={isSaving} />;
    } else {
      return <NoteShareMenubar isSaving={isSaving} isEditable={!isReadOnly} />;
    }
  }, [isSaving, noteStore.note, isReadOnly]);

  if (!noteId || !isSynced || typeof editor === 'undefined') {
    return <Spinner />;
  }

  return (
    <>
      {!isHideMenu && (
        <Pane className={styles.noteMenu}>
          <Pane className={styles.tagContainer}>
            {!isReadOnly && <TagMenubar />}
          </Pane>
          <Pane className={styles.actionContainer}>
            <EditorMenu />
          </Pane>
        </Pane>
      )}

      <div className={clsx(styles.noteEditor, isReadOnly ? 'readOnly' : '')}>
        <Plate
          id={noteId}
          editor={editor}
          onChange={onChange}
          // initialValue={initialValue}
          editableProps={{
            autoFocus: true,
            spellCheck: false,
            placeholder: isReadOnly ? '' : 'Write something...',
            readOnly: isReadOnly || isHideMenu,
            decorate: decorate,
            renderLeaf: renderLeaf,
          }}
        >
          {!isHideMenu && <Toolbar readOnly={isReadOnly} />}
        </Plate>
      </div>
    </>
  );
}
