import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, majorScale, Pane, Paragraph } from 'evergreen-ui';
import { ResetService, ResetType } from 'services/reset';
import { SIGN_IN_URL } from 'utils/constants';
import CardAuth from 'components/CardAuth/CardAuth';
import { Spinner } from 'components/Spinner/Spinner';

const resetService = new ResetService();

const ResetEmail = () => {
  const [loading, setLoading] = useState(true);
  const { token } = useParams<{ token: string }>();
  const history = useHistory();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await resetService.validateResetToken(ResetType.ResetEmail, token);
      } catch (e) {
        history.push(SIGN_IN_URL);
      } finally {
        setLoading(false);
      }
    }
    verifyToken();
  }, [history, token]);

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <CardAuth title="Email confirmed!">
          <Paragraph className="text-center mt-5 mb-5" style={{ color: '#474D66' }}>
            You have successfully changed<br/>
            your account's email address.
          </Paragraph>
          <Pane textAlign="center">
            <Link to="/sign-in" className="text-decoration-none">
              <Button
                className="text-decoration-none"
                appearance="primary"
                height={majorScale(5)}
              >
                Return Home
              </Button>
            </Link>
          </Pane>
        </CardAuth>
      )}
    </>
  );
}

export default ResetEmail;
