import React, {useEffect, useState} from 'react';
import {Button, Dialog, Heading, TextInput} from 'evergreen-ui';
import clsx from 'clsx';

import Combobox from 'components/Elements/Combobox';
import styles from './ShareFolderModal.module.scss';
import {Role} from 'schemas/role';
import {capitalize} from 'utils/string';
import {FolderService, useShareFolder} from "../../services/folder";
import {FolderSchema} from "../../schemas";
import {useActivateTeam} from "../../services/team/team.helper";

interface Props {
  isShown: boolean;
  setIsShown: Function;
  folder: FolderSchema;
}

const folderService = new FolderService();

export default function ShareFolderModal({ isShown, setIsShown, folder }: Props) {
  const [inviteList, setEmailList] = useState('');
  const [currentFolder, setCurrentFolder] = useState(folder);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({ label: 'Editor', value: 'editor'} as any);

  const { shareUsers, getShareFolder, shareTeams} = useShareFolder();
  const { activateTeams } = useActivateTeam();

  const roleList: any[] = [];
  const inviteRoleList: any[] = [];
  Object.entries(Role).forEach(([label, value]) => {
    roleList.push({
      label,
      value
    })
    if (value !== 'owner') {
      inviteRoleList.push({
        label,
        value
      })
    }
  });

  const inviteRoles = [{ label: 'Viewer', value: 'viewer' }, { label: 'Commenter', value: 'commenter' }, { label: 'Editor', value: 'editor' }];
  const userRoles = [
    { label: 'Viewer', value: 'viewer' },
    { label: 'Commenter', value: 'commenter' },
    { label: 'Editor', value: 'editor' },
    { label: 'Remove', value: 'remove' },
  ];

  const teamRoles = [
    { label: 'None', value: 'none' },
    { label: 'Can View', value: 'viewer' },
    { label: 'Can Comment', value: 'commenter' },
    { label: 'Can edit', value: 'editor' },
  ];

  useEffect(() => {
    if (folder && folder.id) {
      setCurrentFolder(folder);
      getShareFolder(folder.id);
    }
  }, [folder]);

  const handleInvite = async () => {
    try {
      let emailList = inviteList.split(',');
      emailList = emailList.map(item => item.trim().toLowerCase());
      if (inviteList && emailList && selected) {
        setIsLoading(true);
        await folderService.addUserRole(currentFolder.id, emailList, selected.value);
      }
    } finally {
      setIsLoading(false);
      setEmailList('');
    }
  };

  const handleChangeInviteRole = async (email?: string, newRole?: string) => {
    if (email && newRole) {
      await folderService.updateUserRole(currentFolder.id, email, newRole);
    }
  }

  const initialTeamRole = (teamId: string) => {
    const shareRole = shareTeams.find(t => t.id === teamId);
    const noneRole = teamRoles.find(role => role.value === "none");

    if(!shareRole) return noneRole;

    return teamRoles.find(role => role.value === shareRole.role);
  }

  const handleChangeTeamRole = async (teamId: string, newRole: string) => {
    if (teamId && newRole) {
      await folderService.updateTeamRole(currentFolder.id, teamId, newRole);
    }
  }

  return (
    <Dialog
      isShown={isShown}
      title="Share Folder"
      onCloseComplete={() => setIsShown(false)}
      preventBodyScrolling
      confirmLabel="Save"
      containerProps={{
        className: clsx(styles.modal),
      }}
      contentContainerProps={{
        className: styles.content,
      }}
      hasFooter={false}
    >
      <div>
        <TextInput
          width="263px"
          required
          name="name"
          value={inviteList}
          onChange={(e: any) => setEmailList(e.target.value)}
          placeholder="Email, comma separated."
        />

        <Combobox
          initialSelectedItem={{ label: selected?.label || '' }}
          items={inviteRoles}
          itemToString={(item) => (item ? item.label : '')}
          onChange={(selected: any) => setSelected(selected)}
          buttonProps={{ className: 'roleListIcon' }}
        />

        <Button
          appearance="primary"
          className={clsx(styles.inviteBtn)}
          onClick={handleInvite}
          isLoading={isLoading}
        >
          Invite
        </Button>
      </div>

      <hr />

      <div>
        <Heading is="h4" className={clsx(styles.permissionHeading)}>
          Permissions
        </Heading>

        <div className={styles.sharedList}>
          {activateTeams?.map((team) => (
            <div key={team.id} className={clsx(styles.sharedItem, styles.ownerItem)}>
              <div className={styles.shareTarget}>
                {team.name}
              </div>
              <div className={styles.shareAction}>
                <Combobox
                  selectedItem={initialTeamRole(team.id)}
                  items={teamRoles}
                  itemToString={(item) => (item ? item.label : '')}
                  buttonProps={{ className: styles.roleListBtn }}
                  onChange={(selected: any) => handleChangeTeamRole(team.id, selected.value)}
                />
              </div>
            </div>
          ))
          }
          {shareUsers?.map((shareUser) => (
            <div key={shareUser.id} className={clsx(styles.sharedItem, styles.ownerItem)}>
              <div className={styles.shareTarget}>
                {shareUser?.firstName && shareUser?.lastName
                  ? `${shareUser.firstName} ${shareUser.lastName}`
                  : shareUser?.email}
              </div>
              <div className={styles.shareAction}>
                <Combobox
                  initialSelectedItem={{ label: capitalize(shareUser.role?.type) || 'Owner' }}
                  disabled={(shareUser.role?.type || 'Owner') === 'Owner'}
                  items={userRoles}
                  itemToString={(item) => (item ? item.label : '')}
                  onChange={(selected: any) => handleChangeInviteRole(shareUser.email, selected.value)}
                  buttonProps={{ className: styles.roleListBtn }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
}
