import {
  MenuIcon,
  SquareIcon,
  WidgetButtonIcon,
  FontIcon,
  PageLayoutIcon,
  ClipboardIcon,
  MediaIcon,
  MobilePhoneIcon,
  CleanIcon
} from 'evergreen-ui';

export const options = [
  { value: 'all_categories', label: 'All Categories', icon: MenuIcon },
  { value: 'shapes', label: 'Shapes', icon: SquareIcon },
  { value: 'button_controls', label: 'Buttons & Controls', icon: WidgetButtonIcon },
  { value: 'text', label: 'Text', icon: FontIcon },
  { value: 'layout', label: 'Layout', icon: PageLayoutIcon },
  { value: 'forms', label: 'Forms', icon: ClipboardIcon },
  { value: 'content', label: 'Content', icon: MediaIcon },
  { value: 'mobile', label: 'Mobile', icon: MobilePhoneIcon },
  { value: 'other', label: 'Other', icon: CleanIcon },
];

export const zfcOptions = [
  { value: 'all_categories', label: 'All Categories', icon: MenuIcon },
  { value: 'flow_chart', label: 'Flowchart', icon: CleanIcon },
  { value: 'general', label: 'General', icon: SquareIcon },
];
