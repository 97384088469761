import './SelectStatus/SelectStatus.scss';
import { useContext, useState } from 'react';
import { sort } from 'fast-sort';
import {
  Button,
  CrossIcon,
  Pane,
  Text,
  toaster
} from 'evergreen-ui';
import clsx from 'clsx';
import { isHavePermission } from 'services/user';
import useStores from 'hooks/useStores';
import { PermissonType } from 'schemas/role';
import styles from './CommentBar.module.scss';
import editorStyles from '../../EditorApp.module.scss';
import { EditorContext } from '../../../../context/EditorContext';
import { CommentService } from '../../../../services/document/comment.service';
import { AddComment } from './AddComment/AddComment';
import { CommentItemContainer } from './CommentItem/CommentItemContainer';
import { getUser } from '../../../../utils/store';

export const CommentBar = () => {
  const { graphStore } = useStores();
  const [editing, setEditing] = useState(false);
  const { documentId, comments, toggleCommentBar } = useContext(EditorContext);
  const commentService = new CommentService(documentId);

  const handleToggle = () => setEditing(!editing);

  const handleConfirm = async input => {
    try {
      await commentService.addComment(input);
      handleToggle();
    } catch (e) {
      toaster.danger(e.message);
    }
  }

  return (
    <>
      <Pane className={styles.header}>
        <Button
          className={clsx(
            editorStyles.closeContainer,
            styles.close
          )}
          onClick={toggleCommentBar}
        >
          <CrossIcon />
        </Button>
        <Text>
          Comments
        </Text>
        <span/>
      </Pane>
      <Pane className={styles.contentContainer}>
        {getUser() && isHavePermission(
          getUser(),
          graphStore.document,
          PermissonType.CommentDoc
        ) && (
          <>
            <Pane className={styles.preContent}>
              <AddComment
                isEditing={editing}
                onConfirm={handleConfirm}
                onCancel={handleToggle}
              />
            </Pane>
            <hr className={styles.divider}/>
          </>
        )}
        <Pane className={styles.content}>
          {sort(comments)
            .desc(u => u.createdAt)
            .filter(item => !item.parent)
            .map(item => (
              <CommentItemContainer
                key={item.id}
                comment={item}
              />
            ))}
        </Pane>
      </Pane>
    </>
  )
}
