import { useState } from 'react';
import { toaster } from 'evergreen-ui';
import { NoteService } from 'services/note';
import { Dialog } from 'components/Dialog';
import { useHistory, useLocation } from 'react-router-dom';

import useStores from 'hooks/useStores';
import { getUser } from 'utils/store';

interface Props {
  isShown: boolean;
  setIsShown: Function;
}

const noteService = new NoteService();

const RemoveNoteModal = ({ isShown, setIsShown }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { noteStore } = useStores();
  const location = useLocation();

  const handleRemoveShare = async () => {
    try {
      setIsLoading(true);
      const user = getUser() || {};
      if (noteStore.note?.id && user.email) {
        await noteService.updateUserRole(noteStore.note?.id, user.email, 'remove');
        history.push(location.pathname);
      }
    } catch (e: any) {
      toaster.danger(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      width={400}
      isShown={isShown}
      title="Remove Note"
      intent="danger"
      onCloseComplete={() => setIsShown(false)}
      confirmLabel="Delete"
      onConfirm={handleRemoveShare}
      isConfirmLoading={isLoading}
    >
      Are you sure to remove this note from your account? This action cannot be undone.
    </Dialog>
  );
}

export default RemoveNoteModal;
