import _ from "lodash";

export const getAssignees = (assignees: any, user: any) => {
  return  {
    ...assignees,
    [user.uid]: {
      id: user.uid,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      addedAt: (new Date()).getTime()
    }
  }
}

export const getOrdinalProjects = (dragProjectId: any, dropProjectId: any, projects: any[]) => {
  const ownerProjects = projects || [];

  const insertProjects = [...ownerProjects];
  const indexOfDragProject = insertProjects.findIndex((prj: any) => prj.id === dragProjectId);

  const canNotFindIndex = indexOfDragProject < 0;
  if(canNotFindIndex) return;

  const dragProjects = insertProjects.splice(indexOfDragProject, 1);
  const dragProject = _.get(dragProjects, 0);

  const indexOfDropProject = insertProjects.findIndex((prj: any) => prj.id === dropProjectId) + 1;
  insertProjects.splice(indexOfDropProject, 0, dragProject);

  // Update ordinal after drop project
  insertProjects.forEach((prj, index) => prj.ordinal = index + 1);

  return insertProjects;
}
