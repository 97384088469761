/* eslint-disable no-undef */
import React, {useEffect, useReducer, useState} from 'react';
import './PopupMenu.scss';
import {
  AddColumnLeftIcon,
  AddColumnRightIcon,
  AddRowBottomIcon,
  AddRowTopIcon,
  AlignmentLeftIcon,
  AlignmentVerticalCenterIcon,
  AlignmentRightIcon,
  AlignmentTopIcon,
  AlignmentHorizontalCenterIcon,
  AlignmentBottomIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardIcon,
  CutIcon,
  DoubleChevronDownIcon,
  DoubleChevronUpIcon,
  DuplicateIcon,
  ExcludeRowIcon,
  GroupObjectsIcon,
  LockIcon, majorScale,
  Menu,
  Pane,
  Position,
  RemoveColumnIcon,
  Text,
  TrashIcon,
  UnlockIcon
} from 'evergreen-ui';
import _ from 'lodash';
import ActionStore from 'store/ActionStore';
import { ActionState } from './Binding';
import {stylesToMap} from '../../utils/GraphUtils';
import TooltipCustom from './Tooltip';
import useGraphState from '../../hooks/useGraphState';

const PopupMenu = ({ editorUi }) => {
  const updateState = (state, updateState) => {
    const resetGraphState = _.get(updateState, "resetGraphState");
    if(resetGraphState) return updateState;
    return {...state, ...updateState};
  }
  const graph = editorUi.current.editor.graph;
  const format = editorUi.current.format;
  const [graphState, dispatchGraphState] = useReducer(updateState, {});
  const [actionState, dispatchActionState] = useReducer(ActionStore, {});
  useGraphState(editorUi.current, graphState, dispatchGraphState);

  // eslint-disable-next-line
  const [typeComponent, setTypeComponent] = useState("");

  const evt = mxPopupMenu.prototype.evtShowPopupMenu;
  const cell = mxPopupMenu.prototype.cellShowPopupMenu;
  const checkSelectionEmpty = graph.isSelectionEmpty();
  const checkGroupEvt = graph.getSelectionCount();
  const checkPassHere = graph.cellEditor.isContentEditing() ||
    (((!mxClient.IS_FF && navigator.clipboard != null) || !mxClipboard.isEmpty()) &&
      graph.isEnabled() && !graph.isCellLocked(graph.getDefaultParent()));
  const checkGroupUngroup = !graph.getModel().isEdge(cell)
    && !graph.isSwimlane(cell)
    && graph.getModel().getChildCount(cell) > 0;
  const checkLockUnLock = graph.isCellMovable(graph.getSelectionCell());

  useEffect(() => {
    let actions = null;
    try {
      actions = editorUi.current.actions;
    }
    catch (err) {
      console.log(err);
      return;
    }

    Object.keys(ActionState).forEach(key => {
      const action = actions.get(key);
      if (!action) return;
      const initState = {};
      initState[key] = action;
      dispatchActionState({ updateProperty: initState });

      const stateChanged = () => {
        const updateState = {};
        updateState[key] = action;
        dispatchActionState({ updateProperty: updateState });
      }

      action.addListener("stateChanged", stateChanged);
    });

    return () => {
      Object.keys(ActionState).forEach(key => {
        const action = actions.get(key);
        if (!action) return;

        const stateChanged = () => {
          const updateState = {};
          updateState[key] = action;
          dispatchActionState({ updateProperty: updateState });
        }

        action.removeListener("stateChanged", stateChanged);
      });
    };
  }, [])

  useEffect(() => {
    const selectionShape = editorUi.current.editor.graph.getSelectionCell();
    if(selectionShape){
      setTypeComponent(_.get(stylesToMap(selectionShape.style), ZwfConstants.STYLE_TYPE))
    }
  }, [actionState])

  const getAction = (key) => {
    return actionState[key] || {};
  }

  const handleChangeAlign = alignment => {
    graph.alignCells(alignment);
  }

  const handleClickAction = (key) => {
    getAction(key).funct(evt);
    mxEvent.consume(evt);
  }

  const handleInsertColumn = before => {
    const selectionState = format.getSelectionState();
    try {
      graph.insertTableColumn(selectionState.vertices[0], before);
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleDeleteColumn = () => {
    const selectionState = format.getSelectionState();
    try {
      graph.deleteTableColumn(selectionState.vertices[0]);
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleInsertRow = before => {
    const selectionState = format.getSelectionState();
    try {
      graph.insertTableRow(selectionState.vertices[0], before);
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleDeleteRow = () => {
    const selectionState = format.getSelectionState();
    try {
      graph.deleteTableRow(selectionState.vertices[0]);
    }
    catch (e) {
      console.log(e);
    }
  }

  const renderGroupAndUngroup = () => {
    const isSelectOneComponent = checkGroupEvt === 1;
    const isGrouping = isSelectOneComponent && checkGroupUngroup;
    if (isGrouping)
      return <Menu.Item
        className="menuPopupItem"
        onPointerDown={() => handleClickAction('ungroup')}
        icon={<GroupObjectsIcon className="menuIcon" />}
      >Ungroup</Menu.Item>

    return <Menu.Item
      className="menuPopupItem"
      onPointerDown={() => handleClickAction('group')}
      icon={<GroupObjectsIcon className="menuIcon" />}
      disabled={isSelectOneComponent}
    >
      <Text>Group</Text>
    </Menu.Item>;
  }

  const renderLockUnlock = () => {
    let lockUnLock;
    if (checkLockUnLock) {
      lockUnLock = <Menu.Item
        className="menuPopupItem"
        onPointerDown={() => handleClickAction('lockUnlock')}
        icon={<LockIcon className="menuIcon" />}>Lock</Menu.Item>
    }
    return lockUnLock;
  }

  const selectionState = format.getSelectionState();
  const selectionCell = selectionState.vertices[0];

  return (
    <div>
      {checkSelectionEmpty ? (
        <Pane>
          <Menu>
            <Menu.Group>
              <Menu.Item
                className="menuPopupItem"
                disabled={!checkPassHere}
                onPointerDown={() => handleClickAction('pasteHere')}
                icon={<ClipboardIcon className="menuIcon" />}>Paste Here</Menu.Item>

              {/*to ensure that at least one item available*/}
              <Menu.Item height={"0px"}/>
            </Menu.Group>
          </Menu>
        </Pane>
      ) : (
        <Pane>
          <Menu>
            {
              !checkLockUnLock ? (
                <Menu.Group>
                  <Menu.Item
                    className="menuPopupItem"
                    onPointerDown={() => handleClickAction('copy')}
                    icon={<DuplicateIcon className="menuIcon" />}>Copy</Menu.Item>
                  <Menu.Item
                    className="menuPopupItem"
                    onPointerDown={() => handleClickAction('lockUnlock')}
                    icon={<UnlockIcon className="menuIcon" />}>Unlock</Menu.Item>
                </Menu.Group>
              ) : (
                <>
                  {graph.isTableCell(selectionCell) && (
                    <>
                      <Menu.Group>
                        <Pane display={"flex"} marginLeft={majorScale(1)}>
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleInsertColumn(true)}>
                                <Pane className="table-icon"><AddColumnLeftIcon/></Pane>
                              </Pane>
                            )}
                            text="Insert Column Left"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleInsertColumn(false)}>
                                <Pane className="table-icon"><AddColumnRightIcon/></Pane>
                              </Pane>
                            )}
                            text="Insert Column Right"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={handleDeleteColumn}>
                                <Pane className="table-icon"><RemoveColumnIcon/></Pane>
                              </Pane>
                            )}
                            text="Remove Column"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleInsertRow(true)}>
                                <Pane className="table-icon"><AddRowTopIcon/></Pane>
                              </Pane>
                            )}
                            text="Insert Row Above"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleInsertRow(false)}>
                                <Pane className="table-icon"><AddRowBottomIcon/></Pane>
                              </Pane>
                            )}
                            text="Insert Row Below"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={handleDeleteRow}>
                                <Pane className="table-icon"><ExcludeRowIcon/></Pane>
                              </Pane>
                            )}
                            text="Remove Row"
                            position={Position.TOP}
                          />
                        </Pane>
                      </Menu.Group>
                      <Menu.Divider />
                    </>
                  )}
                  {(graph.getSelectionCells() || []).length > 1 && (
                    <>
                      <Menu.Group>
                        <Pane display={"flex"} marginLeft={majorScale(1)}>
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleChangeAlign('left')}>
                                <Pane className="table-icon"><AlignmentLeftIcon/></Pane>
                              </Pane>
                            )}
                            text="Align Left"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleChangeAlign('center')}>
                                <Pane className="table-icon"><AlignmentVerticalCenterIcon/></Pane>
                              </Pane>
                            )}
                            text="Align Middle Horizontally"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleChangeAlign('right')}>
                                <Pane className="table-icon"><AlignmentRightIcon/></Pane>
                              </Pane>
                            )}
                            text="Align Right"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleChangeAlign('top')}>
                                <Pane className="table-icon"><AlignmentTopIcon/></Pane>
                              </Pane>
                            )}
                            text="Align Top"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleChangeAlign('middle')}>
                                <Pane className="table-icon"><AlignmentHorizontalCenterIcon/></Pane>
                              </Pane>
                            )}
                            text="Align Middle Vertically"
                            position={Position.TOP}
                          />
                          <TooltipCustom
                            activator={(
                              <Pane className={"table-btn"} onPointerDown={() => handleChangeAlign('bottom')}>
                                <Pane className="table-icon"><AlignmentBottomIcon/></Pane>
                              </Pane>
                            )}
                            text="Align Bottom"
                            position={Position.TOP}
                          />
                        </Pane>
                      </Menu.Group>
                      <Menu.Divider />
                    </>
                  )}
                  <Menu.Group>
                    <Menu.Item
                      className="menuPopupItem"
                      onPointerDown={() => handleClickAction('copy')}
                      icon={<DuplicateIcon className="menuIcon" />}>Copy</Menu.Item>
                    <Menu.Item
                      className="menuPopupItem"
                      onPointerDown={() => handleClickAction('cut')}
                      icon={<CutIcon className="menuIcon" />}>Cut</Menu.Item>
                    <Menu.Item
                      className="menuPopupItemTrash"
                      onPointerDown={() => handleClickAction('delete')}
                      icon={<TrashIcon className="menuIconTrash" />} intent="danger">Delete</Menu.Item>
                  </Menu.Group>

                  <Menu.Divider />

                  <Menu.Group>
                    <Menu.Item
                      className="menuPopupItem"
                      onPointerDown={() => handleClickAction('toFront')}
                      icon={<DoubleChevronUpIcon className="menuIcon" />}>Bring to Front</Menu.Item>
                    <Menu.Item
                      className="menuPopupItem"
                      onPointerDown={() => handleClickAction('toFrontForward')}
                      icon={<ChevronUpIcon className="menuIcon" />}>Bring Forward</Menu.Item>
                    <Menu.Item
                      className="menuPopupItem"
                      onPointerDown={() => handleClickAction('toBackForward')}
                      icon={<ChevronDownIcon className="menuIcon" />}>Send Backward</Menu.Item>
                    <Menu.Item
                      className="menuPopupItem"
                      onPointerDown={() => handleClickAction('toBack')}
                      icon={<DoubleChevronDownIcon className="menuIcon" />}>Send to Back</Menu.Item>
                  </Menu.Group>

                  <Menu.Divider />

                  {renderGroupAndUngroup()}
                  {renderLockUnlock()}
                </>
              )
            }
          </Menu>
        </Pane>
      )}
    </div>
  )
}

PopupMenu.propTypes = {

}

export default PopupMenu

