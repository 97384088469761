import firebase from 'firebase';
import { functions } from 'utils/firebase';

/**
 * List of available firebase functions
 */
enum AdminFunction {
  CreateCustomToken = 'createCustomToken'
}

/**
 * Stripe Subscription Params
 */
interface AdminTokenParams {
  email: string,
}

/**
 * Stripe service
 */
export class AdminService {
  /**
   * Firebase functions
   * @private
   */
  private readonly functions: firebase.functions.Functions;

  /**
   * Initialize publish and secret key
   */
  constructor() {
    this.functions = functions
  }

  /**
   * Create Stripe Customer Functions
   *
   */
  async createCustomToken(params: AdminTokenParams): Promise<firebase.functions.HttpsCallableResult> {
    const callable = this.functions.httpsCallable(AdminFunction.CreateCustomToken);
    return callable(params);
  }
}
