import { Pane, Button, PlusIcon, TextInput } from "evergreen-ui";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import QuoteList from "./primatives/quote-list";
import styles from "./Kanban.module.scss";
import useComponentVisible from "../../common/useComponentVisible";

type Props = {
  title: string;
  index: number;
  quotes: boolean;
  isCombineEnabled: boolean;
  isScrollable: boolean;
}

export default function Column({ title, quotes, index, isCombineEnabled, isScrollable }: Props) {
  const [refAddCard, isVisibleAddCard, setIsVisibleAddCard] = useComponentVisible(false);

  return (
    <Draggable draggableId={title} index={index}>
      {(provided: any, snapshot: any) => (
        <Pane className={styles.containerColumn} ref={provided.innerRef} {...provided.draggableProps}>
          <Pane className={styles.headerColumn}>
            <Pane
              className={styles.title}
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
            >
              {title}
            </Pane>
          </Pane>
          <QuoteList
            listId={title}
            listType="QUOTE"
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
          />
          <Pane className={styles.bottomColumn} ref={refAddCard}>
            {
              isVisibleAddCard
                ?
                <Pane className={styles.formAnother}>
                  <Pane className={styles.addAnother}>
                    <TextInput name="text-input-name" className={styles.inputAddCard} placeholder="Enter card title..." />
                    <Button marginTop={8} className={styles.btnColor}>
                      Add card
                    </Button>
                  </Pane>
                </Pane>
                :
                <Pane className={styles.bottomAddCard} onClick={() => setIsVisibleAddCard(true)}>
                  <Button
                    className={styles.addCardBtn}
                    iconBefore={PlusIcon}
                    appearance="minimal"
                  >
                    Add a card
                  </Button>
                </Pane>
            }
          </Pane>
        </Pane>
      )}
    </Draggable>
  );
}
