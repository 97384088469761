import {
  Button,
  majorScale,
  ShareIcon,
  Pane,
  RefreshIcon,
  TickIcon,
  Text,
  InfoSignIcon,
  TrashIcon,
  Tooltip,
} from 'evergreen-ui';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './NoteMenubar.module.scss';
import NoteInfoModal from '../Modal/NoteInfoModal';
import useStores from 'hooks/useStores';
import { NoteService } from 'services/note';
import ShareNoteModal from '../Modal/ShareNoteModal';

const noteService = new NoteService();

export default function NoteMenubar({
  isSaving,
}: {
  isSaving: boolean;
}) {
  const [isShowShare, setShowShare] = useState(false);
  const [isShowInfo, setShowInfo] = useState(false);
  const location = useLocation();

  const { noteStore } = useStores();
  const history = useHistory();

  const handleDeleteNote = async () => {
    if (noteStore.note?.id) {
      await noteService.softDelete(noteStore.note?.id);
      history.push(location.pathname);
    }
  }
  
  return (
    <>
      <Pane className={styles.noteMenubar}>
        <Button
          height={majorScale(3)}
          width="6.4375rem"
          className="border-lighten-gray mr-2"
        >
          <Pane display="flex" justifyContent="center">
            {isSaving ? (
              <RefreshIcon
                size={12}
                className="mr-2 mt-1"
                color="#8F95B2"
                fontSize="12"
              />
            ) : (
              <TickIcon
                size={12}
                className="mr-2 mt-1"
                color="#8F95B2"
                fontSize="12"
              />
            )}
            <Text fontSize="12">{isSaving ? 'Saving...' : 'Saved'}</Text>
          </Pane>
        </Button>

        <Tooltip
          content={
            <div className={styles.toolTipLabel}>Move to Trash</div>
          }
        >
          <Button
            height={majorScale(3)}
            width={majorScale(4)}
            className="border-lighten-gray mr-2"
            onClick={handleDeleteNote}
          >
            <Pane display="flex" justifyContent="center">
              <TrashIcon size={12} color="#D14343" />
            </Pane>
          </Button>
        </Tooltip>

        <Tooltip
          content={
            <div className={styles.toolTipLabel}>Note Information</div>
          }
        >
          <Button
            height={majorScale(3)}
            width={majorScale(4)}
            className="border-lighten-gray mr-2"
            onClick={() => setShowInfo(true)}
          >
            <Pane display="flex" justifyContent="center">
              <InfoSignIcon size={12} color="#8F95B2" />
            </Pane>
          </Button>
        </Tooltip>

        <Button
          height={majorScale(3)}
          onClick={() => setShowShare(true)}
          appearance="primary"
        >
          <ShareIcon size={12} className="mr-2" />
          Share
        </Button>
      </Pane>

      <NoteInfoModal
        isShown={isShowInfo}
        setIsShown={setShowInfo}
      />
      <ShareNoteModal
        isShown={isShowShare}
        setIsShown={setShowShare}
      />
    </>
  );
}
