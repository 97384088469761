import React, { useState } from 'react';
import {Alert, Link, Pane} from 'evergreen-ui';
import CardAuth from 'components/CardAuth/CardAuth';
import { RedeemCodeForm } from './RedeemCodeForm';

const RedeemCode = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  return (
    <>
      {error && (
        <Alert
          intent="danger"
          title={error}
        />
      )}
      {success && (
        <Alert
          intent="success"
          title={(
            <Pane>
              Redeemed Successfully. <Link href={"/sign-in"} textDecoration={"underline"} fontWeight={500}>Log In</Link> now
            </Pane>
          )}
        />
      )}
      <CardAuth
        title="Redeem your code"
        subtitle="Redeem Zen Flowchart PRO"
      >
        <RedeemCodeForm
          onSuccess={(flag: boolean) => setSuccess(flag)}
          onError={(message: string) => setError(message)}
        />
      </CardAuth>
    </>
  );
}

export default RedeemCode;
