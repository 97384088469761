/* eslint-disable */
import mx from '../mx';
import { mxUtils, mxConstants } from '../util';
import { mxRectangle } from './mxRectangle';

const mxLabel = mx.mxLabel;

/**
 * Function: getImageBounds
 *
 * Generic background painting implementation.
 */
 mxLabel.prototype.getImageBounds = function(x, y, w, h)
 {
   var align = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_ALIGN, mxConstants.ALIGN_LEFT);
   var valign = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_VERTICAL_ALIGN, mxConstants.ALIGN_MIDDLE);
   var width = mxUtils.getNumber(this.style, mxConstants.STYLE_IMAGE_WIDTH, mxConstants.DEFAULT_IMAGESIZE);
   var height = mxUtils.getNumber(this.style, mxConstants.STYLE_IMAGE_HEIGHT, mxConstants.DEFAULT_IMAGESIZE);
   var imageSpacingRight = mxUtils.getNumber(this.style, mxConstants.STYLE_IMAGE_SPACING_RIGHT, mxConstants.DEFAULT_IMAGE_SPACING);
   var spacing = mxUtils.getNumber(this.style, mxConstants.STYLE_SPACING, this.spacing) + 5;

   if (align == mxConstants.ALIGN_CENTER)
   {
     x += ((w - width) / 2) - imageSpacingRight;
    }
   else if (align == mxConstants.ALIGN_RIGHT)
   {
     x += w - width - spacing;
   }
   else // default is left
   {
     x += spacing;
   }

   if (valign == mxConstants.ALIGN_TOP)
   {
     y += spacing;
   }
   else if (valign == mxConstants.ALIGN_BOTTOM)
   {
     y += h - height - spacing;
   }
   else // default is middle
   {
     y += (h - height) / 2;
   }

   return new mxRectangle(x, y, width, height);
 };

 /**
 * Function: getIndicatorBounds
 *
 * Generic background painting implementation.
 */
mxLabel.prototype.getIndicatorBounds = function(x, y, w, h)
{
	var align = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_ALIGN, mxConstants.ALIGN_LEFT);
	var valign = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_VERTICAL_ALIGN, mxConstants.ALIGN_MIDDLE);
	var width = mxUtils.getNumber(this.style, mxConstants.STYLE_INDICATOR_WIDTH, this.indicatorSize);
	var height = mxUtils.getNumber(this.style, mxConstants.STYLE_INDICATOR_HEIGHT, this.indicatorSize);
	var spacing = this.spacing + 5;

	if (align == mxConstants.ALIGN_RIGHT)
	{
		x += w - width - spacing;
	}
	else if (align == mxConstants.ALIGN_CENTER)
	{
		x += (w - width) / 2;
	}
	else // default is left
	{
		x += spacing;
	}

	if (valign == mxConstants.ALIGN_BOTTOM)
	{
		y += h - height - spacing;
	}
	else if (valign == mxConstants.ALIGN_TOP)
	{
		y += spacing;
	}
	else // default is middle
	{
		y += (h - height) / 2;
	}

	return new mxRectangle(x, y, width, height);
};

export { mxLabel };
