import { useContext, useState, useEffect, useRef } from 'react';
import { EmojiButton } from '@joeattardi/emoji-button';
import { capitalize } from 'utils/string';
import { EditorContext } from 'context/EditorContext';
import { DocumentService } from 'services/document';
import {
  Button,
  CaretDownIcon,
  majorScale,
  LinkIcon,
  Menu,
  PlusIcon,
  HeartIcon,
  minorScale,
  MinusIcon,
  Pane,
  Popover,
  Position,
  RedoIcon,
  HelpIcon,
  SmallPlusIcon,
  Text,
  TrashIcon,
  UndoIcon,
  NewLinkIcon,
  ApplicationIcon,
} from "evergreen-ui";
import "./Toolbar.scss"

const documentService = new DocumentService();

const picker = new EmojiButton();
const ToolbarMindMap = () => {
  const [currentZoom, setCurrentZoom] = useState(100);
  const [isSelectedTopic, setSelectedTopic] = useState(false);
  const [loadingTheme, setLoadingTheme] = useState(false);
  const [undoStep, setUndoStep] = useState(0);
  const [redoStep, setRedoStep] = useState(0);
  const emojiButtonRef = useRef(null);
  const {
    documentId,
    designer,
    setTipModal,
    setLinkModal,
    setUndoQueue,
    setRedoQueue,
    setNoteModal
  } = useContext(EditorContext);
  let closeZoom = () => {};
  let closeTheme = () => {};

  const zoomRatios = [1.2, 1.0500000000000005, 0.9500000000000004, 0.8, 0.5499999999999998, 0.30000000000000004];
  const themes = ['minimal', 'rainbow', 'contrast'];

  useEffect(() => {
    if (designer) {
      designer.addEvent('modelUpdate', function (event) {
        setUndoStep(event.undoSteps);
        setRedoStep(event.redoSteps);
        setUndoQueue(event.undoQueue);
        setRedoQueue(event.redoQueue);
      });
      designer.addEvent('onblur', handleSelected);
      designer.addEvent('onfocus', handleSelected);
      picker.on('emoji', selection => {
        designer.addIconType(encodeURIComponent(selection.emoji))
      });
      document
        .getElementById('workspaceContainer')
        .addEventListener('click', function(event) {
          closeZoom();
          closeTheme();
      })
    }
  }, [designer]);

  const handleSelected = () => {
    const topics = designer.getModel().filterSelectedTopics();
    const subTopics = designer.getModel().filterSelectedRelationships();
    setSelectedTopic(topics.length > 0 || subTopics.length > 0);
  }

  const handleClickTopic = () => {
    designer.createSiblingForSelectedNode();
  };

  const handleClickSubTopic = () => {
    designer.createChildForSelectedNode();
  };

  const handleClickConnection = (event) => {
    designer.showRelPivot(event);
  }

  const handleClickAddLink = () => {
    setLinkModal(true);
  };

  const handleClickAddNote = () => {
    setNoteModal(true);
  };

  const handleClickTrash = () => {
    designer.deleteSelectedEntities();
  };

  const handleClickRedo = () => {
    designer.redo();
  };

  const handleClickUndo = () => {
    designer.undo();
  };

  const handleClickZoomOut = () => {
    designer.zoomOut();
    setCurrentZoom(designer.getZoomVisualValue());
  };

  const handleClickZoomTo = scale => {
    designer.setZoom(scale);
    setCurrentZoom(designer.getZoomVisualValue());
  };

  const handleClickZoomIn = () => {
    designer.zoomIn();
    setCurrentZoom(designer.getZoomVisualValue());
  }

  const handleClickAddIcon = () => {
    picker.togglePicker(emojiButtonRef.current);
  }

  const convertToRatio = scale => {
    return designer ? designer.convertScaleToVisualValue(scale) : scale;
  }

  const handleChangeTheme = async theme => {
    setLoadingTheme(true);
    await documentService.updateOne(documentId, { theme });
    localStorage.setItem('theme', theme);
    setLoadingTheme(false);
    window.location.reload();
  }

  return (
    <Pane
      className="zmmToolbar"
      display="flex"
      justifyContent="end"
      width="100%"
      paddingY={majorScale(1)}
      paddingX={majorScale(2)}
      boxShadow="0px 4px 8px rgb(16 24 64 / 8%)"
    >
      <Button
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        marginRight={majorScale(2)}
        className="green-hover border-lighten-gray"
        disabled={!isSelectedTopic}
        onClick={handleClickTopic}
      >
        <PlusIcon size={12} className="mr-2" color="#52BD94"/>Topic
      </Button>
      <Button
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        marginRight={majorScale(2)}
        className="green-hover border-lighten-gray"
        disabled={!isSelectedTopic}
        onClick={handleClickSubTopic}
      >
        <PlusIcon size={12} className="mr-2" color="#52BD94"/>Subtopic
      </Button>
      <Button
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        marginRight={majorScale(2)}
        className="green-hover border-lighten-gray"
        disabled={!isSelectedTopic}
        onClick={handleClickConnection}
      >
        <NewLinkIcon size={12} className="mr-2" color="#3366FF"/>Connection
      </Button>
      <Button
        ref={emojiButtonRef}
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        marginRight={majorScale(2)}
        className="green-hover border-lighten-gray"
        onClick={handleClickAddIcon}
        disabled={!isSelectedTopic}
      >
        <HeartIcon size={12} className="mr-2" color="#ED55C2"/>Icon
      </Button>
      <Button
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        marginRight={majorScale(2)}
        className="green-hover border-lighten-gray"
        disabled={!isSelectedTopic}
        onClick={handleClickAddNote}
      >
        <ApplicationIcon size={12} className="mr-2" color="#52BD94"/>Note
      </Button>
      <Button
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        marginRight={majorScale(2)}
        className="green-hover border-lighten-gray"
        disabled={!isSelectedTopic}
        onClick={handleClickAddLink}
      >
        <LinkIcon size={12} className="mr-2" color="#3366FF"/>Link
      </Button>
      <Button
        height={majorScale(4)}
        width={majorScale(4)}
        paddingX={"auto"}
        marginRight={majorScale(2)}
        className="border-lighten-gray"
        disabled={!isSelectedTopic}
        onClick={handleClickTrash}
      >
        <TrashIcon size={12} color="#D14343"/>
      </Button>
      <Button
        height={majorScale(4)}
        width={majorScale(4)}
        paddingX={"auto"}
        marginRight="0.25rem"
        className="border-lighten-gray"
        disabled={undoStep < 1}
        onClick={handleClickUndo}
      >
        <UndoIcon size={12}/>
      </Button>
      <Button
        height={majorScale(4)}
        width={majorScale(4)}
        paddingX={"auto"}
        marginRight="2rem"
        className="border-lighten-gray"
        disabled={redoStep < 1}
        onClick={handleClickRedo}
      >
        <RedoIcon size={12}/>
      </Button>
      <Button
        height={majorScale(4)}
        width={majorScale(4)}
        paddingX={"auto"}
        className="border-lighten-gray"
        onClick={handleClickZoomOut}
      >
        <MinusIcon size={12} color="#474D66"/>
      </Button>
      <Popover
        position={Position.BOTTOM_LEFT}
        minWidth={150}
        content={
          ({close}) => {
            closeZoom = close;
            return (
              <Pane onClick={close}>
                <Menu>
                  {zoomRatios
                    .map((ratio, index) => (
                      <Pane key={index}>
                        <Menu.Divider/>
                        <Menu.Item
                          paddingX={0}
                          display={"flex"}
                          alignItems={"center"}
                          onClick={() => handleClickZoomTo(ratio)}
                        >
                          <Text fontSize={12}>{convertToRatio(ratio)}%</Text>
                        </Menu.Item>
                      </Pane>
                    ))
                  }
                </Menu>
              </Pane>
            )
          }
        }
      >
        <Button
          height={majorScale(4)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginX={minorScale(1)}
          minWidth={90}
          className="border-lighten-gray"
        >
          {currentZoom}%<CaretDownIcon size={12} color="#474D66" className="ml-2"/>
        </Button>
      </Popover>
      <Button
        height={majorScale(4)}
        width={majorScale(4)}
        paddingX={"auto"}
        marginRight="1rem"
        className="border-lighten-gray"
        onClick={handleClickZoomIn}
      >
        <SmallPlusIcon size={16} color="#474D66"/>
      </Button>
      <Popover
        position={Position.BOTTOM_LEFT}
        minWidth={150}
        content={
          ({close}) => {
            closeTheme = close;
            return (
              <Pane onClick={close}>
                <Menu>
                  {themes
                    .map((theme, index) => (
                      <Pane key={index}>
                        <Menu.Divider/>
                        <Menu.Item
                          paddingX={0}
                          display={"flex"}
                          alignItems={"center"}
                          onClick={() => handleChangeTheme(theme)}
                        >
                          <Text fontSize={12}>{capitalize(theme)}</Text>
                        </Menu.Item>
                      </Pane>
                    ))
                  }
                </Menu>
              </Pane>
            )
          }
        }
      >
        <Button
          height={majorScale(4)}
          paddingX={majorScale(2)}
          paddingY={majorScale(1)}
          marginRight="1rem"
          minWidth={90}
          className="border-lighten-gray"
          isLoading={loadingTheme}
        >
          Theme<CaretDownIcon size={12} color="#8F95B2" className="ml-2"/>
        </Button>
      </Popover>
      <Button
        height={majorScale(4)}
        paddingX={majorScale(2)}
        paddingY={majorScale(1)}
        className="border-lighten-gray"
        onClick={() => setTipModal(true)}
      >
        <HelpIcon size={12} color="#8F95B2" className="mr-2" /> Tips
      </Button>
    </Pane>
  )
};

export default ToolbarMindMap;
