import { CSSProperties, FC } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { DocumentDragPreview } from './DocumentPreview';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  isSnapToGrid: boolean,
  clientOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset || !clientOffset) {
    return {
      display: 'none',
    };
  }
  let { x, y } = clientOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export interface CustomDragLayerProps {
  snapToGrid: boolean;
}

export const CustomDragLayer: FC<CustomDragLayerProps> = (props) => {
  const { itemType, isDragging, item, initialOffset, currentOffset, clientOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      clientOffset: monitor.getClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  function renderItem() {
    switch (itemType) {
      case 'DOCUMENT':
        return <DocumentDragPreview title={item.doc.name} />;
      case 'FOLDER':
        return <DocumentDragPreview title={item.folder.name} />;
      case 'PROJECT':
        return <DocumentDragPreview title={item.project.name} />;
      case 'TASK':
        return <DocumentDragPreview title={item.task.title} />;
      case 'RESULT':
        return <DocumentDragPreview title={item.result.title || "New Key Result"} />;
      case 'OBJECTIVE':
        return <DocumentDragPreview title={item.objective.title || "New Objective"} />;
      case 'PAGE':
        return <DocumentDragPreview title={item.page.name || "New OKR Page"} />;
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(
          initialOffset,
          currentOffset,
          props.snapToGrid,
          clientOffset
        )}
      >
        {renderItem()}
      </div>
    </div>
  );
};
